import React from "react";
import {
    Container,
    Button,
    withStyles,
    createStyles,
    WithStyles,
    Theme,
    TextField,
    Select,
    MenuItem,
    Typography,
} from "@material-ui/core";
import MaterialTable from "@material-table/core";
import StarRatings from "react-star-ratings";

import { AvaliacaoService } from "../../../../../service";
import { RhAvaliacaoModal, StatusBullet } from "../../../../../commons";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    RH_AVALIACOES_DATA_MENTORIA,
    RH_AVALIACOES_HORARIO_MENTORIA,
    RH_AVALIACOES_MENTORIA_ACONTECEU,
    RH_AVALIACOES_SEM_AVALIACOES,
    RH_AVALIACOES_SOURCE,
    RH_AVALIACOES_AVALIACOES,
    RH_AVALIACOES_DESRICAO,
    RH_AVALIACOES_SIM,
    RH_AVALIACOES_NAO,
    RH_AVALIACOES_MENTORADO,
    RH_AVALIACOES_MENTORIAS_AVALIADAS,
    RH_AVALIACOES_MENTOR,
    RH_AVALIACOES_BUSCAR,
    RH_AVALIACOES_CATEGORIA,
    RH_AVALIACOES_VISUALIZAR,
    RH_AVALICAOES_DE
} from "../../../../../messages";
import { MenuRh } from "../../../../../components";

const pageStyle = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            padding: 0,

            '@media only screen and (max-width: 414px)': {
                width: '90vw'
            }
        },
        titleDiv: {
            flex: 1,
            display: "flex",
            justifyContent: "center",
        },
        title: {
            fontFamily: "infraRegular",
            lineHeight: "150%",
            fontSize: "1.625rem",
        },
        dashboardTitle: {
            fontFamily: 'InfraRegular',
            fontStyle: 'normal',
            fontWeight: 600,

            '@media only screen and (max-width: 414px)': {
                fontSize: '1rem'
            }
        },
        button: {
            width: "4.813rem",
            height: "2.75rem",
        },
        link: {
            textDecoration: "none",
        },
        downloadDiv: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
        },
        downloadButton: {
            margin: 4,
        },
        search: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingTop: "1rem",
            marginBottom: "1rem",

            '@media only screen and (max-width: 720px)': {
                display: 'block',
            }
        },
        searchField: {
            marginRight: "1rem",
            backgroundColor: "#FFFFFF",

            '@media only screen and (max-width: 414px)': {
                margin: '.5vh 0',
                width: '90vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                margin: '.5vh 0',
                width: '85vw',
            }
        },
        status: {
            marginLeft: theme.spacing(1),
        },
        searchFieldLabel: {
            '@media only screen and (max-width: 720px)': {
                fontSize: '.8rem'
            }
        },
        searchButton: {
            color: 'white',
            backgroundColor: "#003B4A",

            '&:hover': {
                backgroundColor: '#0a3039',
            },
            
            '@media only screen and (max-width: 414px)': {
                marginTop: '.9vh',
                width: '90vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                marginBottom: '5vh',
                marginTop: '.9vh',
                width: '85vw',
            }
        }
    });

/**
 * Estilização utilizada na tabela de mentorias avaliadas.
 */
const tableStyle = {
    container: {
        marginTop: "1rem",
        marginBottom: "50px",
        minHeight: 400,
        fontFamily: "infraRegular",
        height: "auto",
        backgroundColor: "#F0F1F3",
        paddingBottom: "1rem",
        paddingTop: "1rem",
    },
    image: {
        width: "10vh",
        height: "10vh",
        borderRadius: "100%",
    },
    headerStyle: {
        backgroundColor: "#CFD4DA",
        fontFamily: "infraMedium",
    },
    title: {
        fontFamily: "infraMedium",
    },
    actionButton: {
        color: "#003B4A",
    },
    table: {
        backgroundColor: "#CFD4DA",
    },
    searchField: {
        backgroundColor: "#F0F1F3",
    },
    filter: {
        backgroundColor: "#F0F1F3",
        marginLeft: "1rem",
        paddingLeft: "1rem",
        paddingTop: "0.6rem",
    },
    dashboardTitle: {
        fontFamily: 'InfraRegular',
        fontStyle: 'normal',
        fontWeight: 600,
    },
};

/**
 * Estilização utilizada de forma geral em todo o container da tabela.
 */
const GlobalCss = withStyles((theme) => ({
    "@global": {
        ".MuiTableRow-root": {
            "&:nth-of-type(even)": {
                backgroundColor: "#FFFFFF",
            },
            "&:nth-of-type(odd)": {
                backgroundColor: "#F8F8F8",
            },
        },
    },
}))(() => null);

/**
 * Classe que descreve o modelo das avaliações com suas propriedades e
 * que implementa interfaces para a tradução de mensagens.
 */
class RhAvaliacoesDashboardProps implements WithTranslation, WithStyles {
    msg?: any;
    //avaliacaoService: AvaliacaoService = new AvaliacaoService();
    t: any;
    i18n: any;
    tReady: any;
    classes: any;
}

/**
 * Interface utilizada na implementação das queries com parâmetros
 * relativos a aspectos da busca, como número de paǵinas e o tamanho
 * de cada página.
 */
interface IQuery {
    page: number;
    pageSize: number;
    search?: ISearch;
}

/**
 * Inteface utilizada na implementação de buscas de informações relativas
 * ao preenchimento do conteúdo da tabela, como nome de mentor e nome de
 * mentorado.
 */
interface ISearch {
    nomeMentorado: string;
    nomeMentor: string;
    mentoriaConfirmada?: boolean;
}

/**
 * Interface utilizada pelo componente abaixo e que descreve
 * seu métodos e propriedades.
 */
interface RhAvaliacoesDashboardState {
    dataTable: Array<any>;
    modalOpen: boolean;
    descricao: string;
    loading: boolean;
    query: IQuery;
    search: ISearch;
}

const avaliacaoService = new AvaliacaoService();

/**
 * Componente para renderizar a tabela de avaliações dadas 
 * por mentorados para mentorias já realizadas.
 * 
 * Apenas acessível para o papel de ADMIN.
 */
class RhAvaliacoesDashBoardComponent extends React.Component<
    RhAvaliacoesDashboardProps,
    RhAvaliacoesDashboardState
> {
    referencia = React.createRef<any>();

    constructor(props: any) {
        super(props);

        this.state = {
            dataTable: [],
            modalOpen: false,
            descricao: "",
            loading: false,
            query: {
                page: 0,
                pageSize: 5,
            },
            search: {
                nomeMentorado: "",
                nomeMentor: "",
            },
        };
    }

    /**
     * Apresenta o modal de Avaliação com a respectiva descrição
     * 
     * @param data Os dados utilizados para a descrição do modal.
     */
    handleModalOpen(data) {
        this.setState({ modalOpen: true, descricao: data });
    }

    /**
     * Fecha o modal de Avaliação
     */
    handleModalClose() {
        this.setState({ modalOpen: false });
    }

    /**
     * Realiza uma bifurcação entre receber todas as avaliações
     * ou as avaliações com filtragem.
     * 
     * @param query Dados da query a ser realizada como página e o tamanho da query.
     * @returns Promise com os resultados da busca.
     */
    handleData(query): Promise<any> {
        this.setState({ loading: true });
        return this.state.query.search
            ? this.handleDataSearch(query)
            : this.handleDataPagination(query);
    }

    /**
     * Lida com a busca de dados de avaliação.
     * 
     * @param query Dados da query a ser realizada como página e o tamanho da query.
     * @returns Promise com os resultados da busca.
     */
    handleDataSearch(query): Promise<any> {
        return new Promise((resolve, reject) => {
            avaliacaoService
                .dashboardSearch(
                    query.page,
                    query.querySize,
                    this.state.query.search
                )
                .then((response) => response.data)
                .then((result) => {
                    resolve({
                        data: result.conteudo,
                        page: result.numeroPaginaAtual,
                        totalCount: result.totalElementos,
                    });

                    let newData = this.state.dataTable;
                    newData.push(result.conteudo);
                    this.setState({ loading: false, dataTable: newData });
                });
        });
    }

    /**
     * Lida com a paginação dos dados.
     * 
     * @param query Dados da query a ser realizada como número da página atual
     *              e o tamanho de elementos.
     * @returns Promise com os resultados da busca.
     */
    handleDataPagination(query): Promise<any> {
        return new Promise((resolve, reject) => {
            avaliacaoService
                .getPagination(query.page, query.pageSize)
                .then((response) => response.data)
                .then((result) => {
                    resolve({
                        data: result.conteudo,
                        page: result.numeroPaginaAtual,
                        totalCount: result.totalElementos,
                    });

                    let newData = this.state.dataTable;
                    newData.push(result.conteudo);
                    this.setState({ loading: false, dataTable: newData });
                });
        });
    }

    /**
     * Atualiza o state de pesquisa com os novos valores de pesquisa. 
     * 
     * @param e O evento que dispara a mudança de state e que contém
     *          as informações de nome e valor para a atualização do state.
     */
    handleChange(e: React.ChangeEvent<any>) {
        const { name, value } = e.target;
        let newSearch = this.state.search;
        newSearch[name] = value;

        this.setState({ search: newSearch });
    }

    /**
     * Realiza a pesquisa e atualiza a tabela com as novas avaliações filtradas
     */
    handleSearch() {
        let newQuery = this.state.query;
        newQuery.search = this.state.search;
        this.setState({ query: newQuery });
        this.handleData(newQuery);
        this.referencia.current.onQueryChange();
    }

    render() {
        const { t, classes } = this.props;

        return (
            <>
                {/* Sub header */}
                <Container maxWidth="lg" fixed className={classes.root}>
                    <MenuRh />
                    <div className={classes.titleDiv}>
                        <Typography variant="h5" align="left" className={classes.dashboardTitle}>{t(RH_AVALIACOES_MENTORIAS_AVALIADAS, 'Mentorias Avaliadas')}</Typography>
                    </div>
                </Container>

                {/* Body */}
                <Container maxWidth="lg" fixed style={tableStyle.container}>
                    <div className={classes.search}>
                        {/* FILTROS */}
                        <TextField
                            id="nomeMentorado"
                            name="nomeMentorado"
                            label={t(RH_AVALIACOES_MENTORADO, "Mentorado")}
                            inputProps={{ maxLength: 60 }}
                            variant="outlined"
                            className={classes.searchField}
                            onChange={(e) => this.handleChange(e)}
                            InputLabelProps={{
                                className: classes.searchFieldLabel
                            }}
                        />
                        <TextField
                            id="nomeMentor"
                            name="nomeMentor"
                            label={t(RH_AVALIACOES_MENTOR, "Mentor")}
                            variant="outlined"
                            className={classes.searchField}
                            onChange={(e) => this.handleChange(e)}
                            InputLabelProps={{
                                className: classes.searchFieldLabel
                            }}
                        />
                        <Select
                            id="mentoriaConfirmada"
                            name="mentoriaConfirmada"
                            className={classes.searchField}
                            onChange={(e) => this.handleChange(e)}
                            displayEmpty
                            variant="outlined"
                            defaultValue=""
                        >
                            <MenuItem value="">{t(RH_AVALIACOES_MENTORIA_ACONTECEU, "Realizada")}</MenuItem>
                            <MenuItem value="true">{t(RH_AVALIACOES_SIM, "Sim")}</MenuItem>
                            <MenuItem value="false">{t(RH_AVALIACOES_NAO, "Não")}</MenuItem>
                        </Select>
                        <Button
                            className={classes.searchButton}
                            variant="contained"
                            onClick={() => this.handleSearch()}
                        >
                            {t(RH_AVALIACOES_BUSCAR, 'Buscar')}
                        </Button>
                    </div>
                    <GlobalCss />
                    <MaterialTable
                        title={t(RH_AVALIACOES_AVALIACOES, "Avaliações")}
                        style={{ backgroundColor: "#CFD4DA" }}
                        tableRef={this.referencia}
                        localization={{
                            body: {
                                emptyDataSourceMessage: t(
                                    RH_AVALIACOES_SEM_AVALIACOES,
                                    "Sem avaliações para exibir"
                                ),
                            },
                            pagination: {
                                labelDisplayedRows: `{from}-{to} ${t(RH_AVALICAOES_DE, 'de')} {count}`,
                                labelRowsSelect: "itens",
                            },
                            toolbar: {
                                searchTooltip: t(RH_AVALIACOES_BUSCAR, "Buscar"),
                                searchPlaceholder: t(RH_AVALIACOES_BUSCAR, "Buscar"),
                            },
                        }}
                        columns={[
                            {
                                title: t(RH_AVALIACOES_MENTORADO, 'Mentorado'),
                                field: "mentoria.mentorado",
                            },
                            {
                                title: t(RH_AVALIACOES_CATEGORIA, "Categoria"),
                                field: "mentoria.categoria",
                            },
                            {
                                title: t(RH_AVALIACOES_MENTOR, "Mentor"),
                                field: "mentoria.mentor",
                            },
                            {
                                title: t(
                                    RH_AVALIACOES_HORARIO_MENTORIA,
                                    "Horário"
                                ),
                                field: "mentoria.horarioInicio",
                            },
                            {
                                title: t(RH_AVALIACOES_DATA_MENTORIA, "Data"),
                                field: "mentoria.dataMentoria",
                            },
                            {
                                align: "center",
                                title: "Nota",
                                field: "nota",
                                render: (rowData) => (
                                    <StarRatings
                                        rating={rowData.nota}
                                        starRatedColor="#FFB400"
                                        starDimension="1.2rem"
                                        starSpacing="0.125rem"
                                    />
                                ),
                            },
                            {
                                align: "center",
                                title: t(RH_AVALIACOES_DESRICAO, "Descrição"),
                                field: "descricao",
                                render: (rowData) => (
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            style={{ alignSelf: "left" }}
                                            color="primary"
                                            onClick={() =>
                                                this.handleModalOpen(
                                                    rowData.descricao
                                                )
                                            }
                                        >
                                            {" "}
                                            {t(RH_AVALIACOES_VISUALIZAR, 'Visualizar')}{" "}
                                        </Button>
                                    </div>
                                ),
                            },
                            {
                                title: t(
                                    RH_AVALIACOES_MENTORIA_ACONTECEU,
                                    "Realizada"
                                ),
                                field: "mentoriaConfirmada",
                                render: (rowData) => (
                                    <>
                                        {" "}
                                        {rowData.mentoriaConfirmada === true ? (
                                            <>
                                                {" "}
                                                {t(
                                                    RH_AVALIACOES_SIM,
                                                    "Sim"
                                                )}{" "}
                                                <StatusBullet
                                                    className={classes.status}
                                                    color="success"
                                                    size="sm"
                                                />{" "}
                                            </>
                                        ) : (
                                            <>
                                                {" "}
                                                {t(
                                                    RH_AVALIACOES_NAO,
                                                    "Não"
                                                )}{" "}
                                                <StatusBullet
                                                    className={classes.status}
                                                    color="danger"
                                                    size="sm"
                                                />{" "}
                                            </>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        data={(query) => this.handleData(query)}
                        isLoading={this.state.loading}
                        options={{
                            paginationType: "normal",
                            pageSize: this.state.query.pageSize,
                            toolbar: false,
                            search: false,
                            filtering: false,
                            sorting: false,
                            headerStyle: tableStyle.headerStyle,
                            searchFieldStyle: tableStyle.searchField,
                            showTitle: false,
                        }}
                        onRowsPerPageChange={(pageSize: number) => {
                            let newQuery = this.state.query;
                            newQuery.pageSize = pageSize;
                            this.setState({ query: newQuery });
                        }}
                        onPageChange={(page: number) => {
                            let newQuery = this.state.query;
                            newQuery.page = page;
                            this.setState({ query: newQuery });
                        }}
                    />

                    {this.showDescricao()}
                </Container>
            </>
        );
    }

    /**
     * Renderiza o modal de avaliações.
     * 
     * @returns O modal de avaliações.
     */
    showDescricao() {
        return (
            <RhAvaliacaoModal
                isOpen={this.state.modalOpen}
                handleClose={() => this.handleModalClose()}
                subtitle={this.state.descricao}
            />
        );
    }
}

export default withTranslation(RH_AVALIACOES_SOURCE)(
    withStyles(pageStyle)(RhAvaliacoesDashBoardComponent)
);
