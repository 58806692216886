import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StatusBullet, SpinnerSplendis, AvaliacaoModal } from '../../commons';
import { Button, Typography, CardMedia } from '@material-ui/core';

import { capitalize, mapStatusMentoria, limitString } from '../../utils/FormUtils';
import { Mentoria, MentoriaAvaliacao } from '../../models';
import { MentoriaFuturaMentor } from '../../models/MentoriaFuturaMentor';
import { useTranslation } from 'react-i18next';
import {
  MENTORIA_TABLE_SOURCE,
  MENTORIA_TABLE_HEADERS_DATA_HORA,
  MENTORIA_TABLE_HEADERS_NOME_MENTOR,
  MENTORIA_TABLE_HEADERS_STATUS_MENTORIA,
  MENTORIA_TABLE_HEADERS_REAGENDAR_MENTORIA,
  MENTORIA_TABLE_HEADERS_CANCELAR_MENTORIA,
  MENTORIA_TABLE_NAO_HA_MENTORIAS,
  MENTORIA_TABLE_HEADERS_NOME_MENTORADO,
  MENTORIA_TABLE_BODY_HORAS_AS,
} from '../../messages';

import DateRangeIcon from '@material-ui/icons/DateRange';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import StarRatings from "react-star-ratings";
import { getComparator, HeadCell, Order, stableSort } from '../../utils/TableUtils';
import { EnhancedTableHead } from './EnhancedTableHead';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: '100%',
      '@media (min-width: 1366px)': {
        minWidth: '860px',
      },
    },

    table: {
      minWidth: 650,
    },

    mentoriaNormal: {},

    mentoriaDestaque: {
      backgroundColor: theme.palette.warning.light,
      fontWeight: 700
    },

    status: {
      marginLeft: theme.spacing(1)
    },

    rowTableCell: {
      whiteSpace: 'pre-line',
      fontSize: "0.875rem",
      fontFamily: "infraRegular",
      fontWeight: 400,
      color: "#636466",

      '@media only screen and (max-width: 414px)': {
        fontSize: '.6rem',
      },

      '@media only screen and (min-width: 415px) and (max-width: 720px)': {
        fontSize: '.7rem',
      },

      '@media (min-width: 1024px)': {
        margin: 4,
        padding: '12px 5px 12px 5px',
      },

      '@media (max-width: 1024px)': {
        margin: 5,
        padding: 3,
      }
    },

    actionBtn: {
      padding: 0,
      '@media (max-width: 1024px)': {
        fontSize: '0.6rem'
      }
    },

    actionBtnReschedule: {
      color: '#006277',
      padding: 0,
      '@media (max-width: 1024px)': {
        fontSize: '0.6rem'
      }
    },

    avaliacaoModal: {
      width: '90vw',
      maxWidth: 490,
      height: 'auto',
      textAlign: 'center'
    },

    avaliacaoText: {
      textOverflow: 'ellipsis',
      overflowWrap: 'anywhere',
    },

    noItemsTextContainer: {
      width: 850,
      margin: '2em auto',
      color: "#636466",

      '@media only screen and (max-width: 720px)': {
        margin: '0 auto',
        padding: '3vh 0',
        width: 'auto',
      }
    },

    spinner: {
      width: '100%',
      marginTop: '2em',
      marginBottom: '2em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    info: {
      display: "flex",
      alignItems: "center",
      width: "15rem"
    },

    infoContainer: {
      display: "flex",
      justifyContent: "center"
    },

    media: {
      display: "block",
      height: "2.6rem",
      width: "2.6rem",
      borderRadius: "50%",
      marginRight: "1rem"
    },

    pointerCursor: {
      cursor: "pointer"
    }
  })
);

/**
 * Estilização aplicada ao container da tabela.
 */
const GlobalCss = withStyles((theme) => ({
  "@global": {
    ".MuiTableCell-head": {
      backgroundColor: "#D3CAB7",
      color: "#636466",
    },
    ".MuiTableRow-root": {
      "&:nth-of-type(odd)": {
        backgroundColor: "#F8F8F8",
      },
    }
  },
}))(() => null);

/**
 * Interface utilizada pelo componente MentoriaTable e que descreve
 * seu métodos e propriedades.
 */
interface MentoriaTableProps {
  enableFirstOnFocus?: boolean
  cancelable?: boolean
  reschedulable?: boolean
  cancelarMentoriaCallback?: Function
  reagendarMentoriaCallback?: Function
  avaliarMentoriaCallback?: Function
  asRole?: string
  mentorias?: Mentoria[] | MentoriaFuturaMentor[] | any
  cellPadding?: any
}

/**
 * Componente responsável pela apresentação das tabelas de mentorias, tanto para mentor, como mentorado.
 */
const MentoriaTable: React.FC<MentoriaTableProps> = React.memo(({ cancelable, reschedulable, cancelarMentoriaCallback, reagendarMentoriaCallback, avaliarMentoriaCallback, asRole, mentorias }) => {
  const classes = useStyles();
  const { t } = useTranslation([MENTORIA_TABLE_SOURCE]);
  const [openAvaliacao, setOpenAvaliacao] = React.useState(false);
  const [actualRow, setActualRow] = React.useState<MentoriaAvaliacao>(new MentoriaAvaliacao());
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('Mentor');


  /**
   * Associa um tipo de informação para cada status de mentoria.
   */
  const statusColors = {
    'AGENDADA': 'success',
    'AGUARDANDO_AVALIACAO': 'warning',
    'CANCELADA': 'danger',
    'CONCLUIDA': 'info',
    'REAGENDADA': 'warning',
    'NAO_REALIZADA': 'danger'
  }

  /**
   * Renderização do botão para realizar o cancelamento de uma mentoria.
   * 
   * @param actionCallback Função que é disparada ao clicar no botão de cancelar mentoria.
   * @param isDisabled Propriedade que desabilita o botão de cancelar mentoria.
   * @returns O botão de cancelar mentoria.
   */
  const cancelButton = (actionCallback, isDisabled = false) => {
    return <Button className={classes.actionBtn} onClick={actionCallback} color="secondary" disabled={isDisabled}><DeleteOutlineIcon /></Button>

  }

  /**
   * Renderização do botão para realizar o reagendamento de uma mentoria.
   * 
   * @param actionCallback Função que é disparada ao clicar no botão de reagendar mentoria.
   * @param isDisabled Propriedade que desabilita o botão de reagendar mentoria.
   * @returns O botão de reagendamento de mentoria.
   */
  const rescheduleButton = (actionCallback, isDisabled = false) => {
    return <Button className={classes.actionBtnReschedule} onClick={actionCallback} color="primary" disabled={isDisabled}><DateRangeIcon /></Button>
  }

  /**
   * Valida o status da mentoria a fim de habilitar ou não os botões de reagendar ou cancelar mentoria.
   * 
   * @param mentoria A mentoria a ser validada.
   * @returns True ou False dependendo do status da mentoria.
   */
  const isButtonDisabled = (mentoria) => {
    return mentoria.statusMentoria === "CANCELADA"
      || mentoria.statusMentoria === "CONCLUIDA"
      || mentoria.statusMentoria === "NAO_REALIZADA"
      || mentoria.statusMentoria === "AGUARDANDO_AVALIACAO"
  }

  /**
   * Apresentação do componente spinner enquanto o carregamento de mentorias na tabela é realizado.
   * 
   * @returns O loading spinner.
   */
  const loadingComponent = () => {
    return !mentorias ?
      <div className={classes.spinner}>
        <SpinnerSplendis />
      </div>
      :
      null;
  }

  /**
   * Renderiza a avaliação da mentoria.
   * 
   * @param row A linha escolhida na tabela.
   */
  const renderAvaliacao = (row) => {
    setActualRow(row);
    setOpenAvaliacao(true);
  }

  /**
   * Configura o state para o fechamento do modal de avaliação de mentoria.
   */
  const closeAvaliacao = () => {
    setOpenAvaliacao(false);
  }

  /**
   * Abre um modal com a visualização da avaliação do mentor.
   * 
   * @param avaliacao A avaliação do mentor.
   */
  const viewAvaliacao = (row = actualRow) => {
    return <AvaliacaoModal
      isOpen={openAvaliacao}
      handleClose={closeAvaliacao}
      //@ts-ignore
      emailMentorado={row.emailMentorado}
      //@ts-ignore
      emailMentor={row.emailMentor}
      //@ts-ignore
      categoria={row.categoria}
      avaliacao={row.avaliacaoDTO}
      role={asRole}
    />
  }

  /**
   * Lida com as ordernações por campos na tabela.
   * 
   * @param event O evento que dispara a ordenação da tabela de mentorias.
   * @param property A propriedade utilizada na ordenação(i.e. ASC = ascendente ou DESC = descendente).
   */
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
   * Renderiza as células do head da tabela de mentorias com base no papel do usuário logado.
   * 
   * @param role O papel do usuário logado na aplicação(eg. Mentor ou Mentorado).
   * @returns As células do head da tabela de mentorias.
   */
  const handleHeadCells = (role): HeadCell[] => {
    const headCells: HeadCell[] = [
      { id: 'data', numeric: false, disablePadding: false, label: t(MENTORIA_TABLE_HEADERS_DATA_HORA, 'Data e Hora') },
      { id: 'status', numeric: false, disablePadding: false, label: t(MENTORIA_TABLE_HEADERS_STATUS_MENTORIA, 'Status') },
    ];

    if (role === "ROLE_MENTOR") {
      headCells.unshift({ id: 'mentorado', numeric: false, disablePadding: true, label: t(MENTORIA_TABLE_HEADERS_NOME_MENTORADO, 'Mentorado') });
    } else {
      headCells.unshift({ id: 'mentor', numeric: false, disablePadding: true, label: t(MENTORIA_TABLE_HEADERS_NOME_MENTOR, 'Mentor') });
    }

    if (reschedulable && cancelable) {
      headCells.push({ id: 'reagendar/cancelar', numeric: false, disablePadding: false, label: t(MENTORIA_TABLE_HEADERS_REAGENDAR_MENTORIA, 'Reagendar/') + t(MENTORIA_TABLE_HEADERS_CANCELAR_MENTORIA, 'Cancelar') })
    } else if (cancelable) {
      headCells.push({ id: 'cancelar', numeric: false, disablePadding: false, label: t(MENTORIA_TABLE_HEADERS_CANCELAR_MENTORIA, 'Cancelar') })
    }

    return headCells;
  }

  /**
   * Renderiza a visualização do status da mentoria para cada linha da tabela de mentorias,
   * juntamente com a avaliação, dependendo do status da mentoria, 
   * 
   * @param status O status da mentoria na tabela de mentorias.
   * @param row A linha na tabela de mentorias.
   * @returns A avaliação da mentoria ou não, dependendo do status da mentoria.
   */
  const switchStatusView = (status: string, row) => {
    if (status === "CONCLUIDA" || status === "NAO_REALIZADA") {
      return (
        <React.Fragment>
          <StarRatings
            rating={row.avaliacaoDTO.nota ? row.avaliacaoDTO.nota : 0}
            starRatedColor="#FFB400"
            starDimension="1.375rem"
            starSpacing="0.125rem"
          />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {capitalize(mapStatusMentoria(status, t))}
          <StatusBullet className={classes.status}
            color={statusColors[status]}
            size="sm" />
        </React.Fragment>
      )
    }
  }

  /**
   * Realiza a chamada para a realização da avaliação de mentoria dependendo
   * do status da mentoria escolhida na tabela de mentorias do mentor.
   * 
   * @param row A linha da tabela de mentorias.
   */
  const onTableRowMentorClick = (row) => {
    if (row.status === "CONCLUIDA" || row.status === "NAO_REALIZADA") {
      renderAvaliacao(row);
    }
  }

  /**
   * Realiza a chamada para a realização da avaliação de mentoria dependendo
   * do status da mentoria escolhida na tabela de mentorias do mentorado.
   * 
   * @param row A linha da tabela de mentorias.
   */
  const onTableRowMentoradoClick = (row) => {
    if (row.status === "AGUARDANDO_AVALIACAO" && avaliarMentoriaCallback) {
      avaliarMentoriaCallback(row);
    } else if (row.status === "CONCLUIDA") {
      renderAvaliacao(row);
    }
  }

  /**
   * Renderiza a tabela de mentorias com base no papel do usuário(mentor ou mentorado).
   * 
   * @param role O papel do usuário(mentor ou mentorado).
   * @param row A linha da tabela de mentorias.
   * @returns As linhas da tabela de mentorias.
   */
  const switchRowColumnsByUserRoles = (role, row) => {
    switch (role) {
      case "ROLE_MENTOR":
        const statusMentoriasPassadas = (row.status === "CONCLUIDA" || row.status === "NAO_REALIZADA");
        return (
          <TableRow
            hover className={statusMentoriasPassadas ? classes.pointerCursor : undefined}
            onClick={() => onTableRowMentorClick(row)}>
            <TableCell className={classes.rowTableCell} align="center" style={{ whiteSpace: 'nowrap' }}>
              <div className={classes.infoContainer}>
                <div className={classes.info}>
                  <CardMedia className={classes.media} image={row.fotoMentorado} title={row.mentorado} />
                  {limitString(row.mentorado, 22)}
                </div>
              </div>
            </TableCell>
            <TableCell className={classes.rowTableCell} align="center">
              {row.dataMentoria || row.data} {t(MENTORIA_TABLE_BODY_HORAS_AS, 'às')} {row.horarioInicio || row.hora}
            </TableCell>
            <TableCell className={classes.rowTableCell} align="center" style={{ whiteSpace: 'nowrap' }}>
              {switchStatusView(row.status, row)}
            </TableCell>


            {/* REAGENDAR E CANCELAR */}
            {(reschedulable && cancelable) ?
              <TableCell className={classes.rowTableCell} align="center">
                {rescheduleButton(() => {
                  if (reagendarMentoriaCallback) {
                    reagendarMentoriaCallback(row)
                  }
                  isButtonDisabled(row)
                })}
                {cancelButton(() => {
                  if (cancelarMentoriaCallback) {
                    cancelarMentoriaCallback(row, role)
                  }
                  isButtonDisabled(row)
                })}
              </TableCell>
              : null}
          </TableRow>)
      case "ROLE_MENTORADO":
        const statusMentorias = (row.status === "CONCLUIDA" || row.status === "AGUARDANDO_AVALIACAO");
        return (
          <TableRow
            hover className={statusMentorias ? classes.pointerCursor : undefined}
            onClick={() => onTableRowMentoradoClick(row)}>
            <TableCell className={classes.rowTableCell} align="center">
              <div className={classes.infoContainer}>
                <div className={classes.info}>
                  <CardMedia className={classes.media} image={row.fotoMentor} title={row.mentor} />
                  {limitString(row.mentor, 22)}
                </div>
              </div>
            </TableCell>
            <TableCell className={classes.rowTableCell} align="center">
              {row.data} {t(MENTORIA_TABLE_BODY_HORAS_AS, 'às')} {row.hora}
            </TableCell>
            <TableCell className={classes.rowTableCell} align="center" >{capitalize(mapStatusMentoria(row.status, t))}<StatusBullet className={classes.status}
              color={statusColors[row.status]}
              size="sm" /></TableCell>
            {/* REAGENDAR E CANCELAR */}
            {(reschedulable && cancelable) ?
              <TableCell className={classes.rowTableCell} align="center">
                {rescheduleButton(() => {
                  if (reagendarMentoriaCallback) {
                    reagendarMentoriaCallback(row)
                  }
                  isButtonDisabled(row)
                })}
                {cancelButton(() => {
                  if (cancelarMentoriaCallback) {
                    cancelarMentoriaCallback(row, role)
                  }
                  isButtonDisabled(row)
                })}</TableCell>
              : null}
          </TableRow>)
    }
  }

  return (
    <TableContainer className={classes.container} component={Paper}>
      <GlobalCss />
      <Table stickyHeader={true} className={classes.table} aria-label="simple table">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          headCells={handleHeadCells(asRole)}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {mentorias && stableSort(mentorias, getComparator(order, orderBy)).map((row, index) => (
            <>
              {switchRowColumnsByUserRoles(asRole, row)}
            </>
          ))
          }
        </TableBody>
      </Table>
      {loadingComponent()}
      {viewAvaliacao()}
      {mentorias && mentorias.length === 0 ?
        <div className={classes.noItemsTextContainer}>
          <Typography align="center" variant="subtitle1">{t(MENTORIA_TABLE_NAO_HA_MENTORIAS, "Não há mentorias aqui.")}</Typography>
        </div>
        :
        null}
    </TableContainer>
  );
})

export default MentoriaTable;