import { Colaborador } from "./Colaborador";

/**
 * Classe que define o modelo para o mentorado com todas as suas propriedades e métodos.
 */
export class Mentorado extends Colaborador{
    public id: number = 0

    constructor() {
        super();
        this.status = 'ATIVO'
    }
}