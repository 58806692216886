
import * as Yup from 'yup';
import { formatFromMaterialUIDate } from '../../../../../../utils/FormUtils';
import {
    DATE_RANGE_VALIDATIONS_POSTERIOR_A,
    DATE_RANGE_VALIDATIONS_POSTERIOR_DATA_INICIO,
    DATE_RANGE_VALIDATIONS_CAMPO_OBRIGATORIO
} from '../../../../../../messages';

/**
 * Schema para validar os campos de dataInicial e dataFinal
 * 
 * @param minDate A data mínima a ser considerada no processo de validação das datas.
 * @returns Objeto com a verificação e validação dos campos dataInicial e dataFinal.
 */
export const DataFilterSchema = (t, minDate) => Yup.object().shape({
    dataInicial: Yup.date().min(minDate, t(DATE_RANGE_VALIDATIONS_POSTERIOR_A, 'Deve ser posterior a ') + formatFromMaterialUIDate(minDate)).required(t(DATE_RANGE_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")),
    dataFinal: Yup.date().min(Yup.ref('dataInicial'), t(DATE_RANGE_VALIDATIONS_POSTERIOR_DATA_INICIO, 'Deve ser posterior à data de início')).required(t(DATE_RANGE_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório"))
});

