import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, CardActionArea, Typography, useMediaQuery } from '@material-ui/core';

const useStyles2 = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			height: 60,
			margin: '.4rem 0'
		},
		rootDisabled: {
			display: 'flex',
			opacity: 0.3,
			height: 60,
			margin: '.4rem 0'
		},
		cardAction: {
			height: '100%',

			'@media only screen and (max-width: 414px)': {
				justifyContent: 'flex-start',
				paddingLeft: 10
			}
		},
		cardActionSelected: {
			backgroundColor: '#FF6C00',
			color: '#FFF',
			height: '100%',

			'@media only screen and (max-width: 414px)': {
				justifyContent: 'flex-start',
				paddingLeft: 10
			}
		},
		cardMedia: {
			margin: '.2rem 0 0 2rem',
			maxWidth: 60,
			width: '2.5rem',

			'@media only screen and (max-width: 414px)': {
				margin: '1rem 0 0 .5rem',
				width: '1.5rem',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				margin: '.35rem 0 0 1rem',
			}
		},
		cardContent: {
			display: 'flex',
			margin: '-3rem 0 0 5rem',

			'@media only screen and (max-width: 414px)': {
				margin: '-2.8rem 0 0 2rem',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				margin: '-3.2rem 0 0 3.5rem',
			},

			'& > h6': {
				fontFamily: 'InfraRegular',
				fontSize: '24px',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '36px',

				'@media only screen and (max-width: 414px)': {
					fontSize: '20px'
				}
			},

			'@media only screen and (max-width: 455px)': { 
				width: 'auto',
			}
		}
	})
);

/**
 * Gera o card do idioma.
 * 
 * @param props { imagem, selected, onClick, disabled }
 * @returns O card do idioma.
 */
export default function IdiomaCard(props) {

	const classes = useStyles2();

	const isMobile = useMediaQuery('(max-width: 455px)');

	const { imagem, selected, onClick, disabled } = props;
	const { id, nome } = props.idioma;

	return (
		<Card className={disabled ? classes.rootDisabled : classes.root}>
			<CardActionArea
				className={selected ? classes.cardActionSelected : classes.cardAction}
				onClick={(e) => onClick(e, id)}
				disabled={disabled}
			>

				<CardMedia
					component="img"
					className={classes.cardMedia}
					alt={nome}
					src={imagem}
					title={nome} />
				<CardContent className={classes.cardContent}>
					{
						isMobile ?
							<Typography variant="subtitle1">
								{nome}
							</Typography>
							:
							<Typography variant="h6">
								{nome}
							</Typography>
					}
				</CardContent>

			</CardActionArea>
		</Card>
	);
}