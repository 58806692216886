import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados ao usuário.
 */
export default class UserService extends HttpClientService{
    constructor(){
        super('users')
    }
    
    /**
     * Obtém o usuário e todas as suas informações.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getUser(): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(res => {
            return res.data;
        })
    }

    /**
     * Realiza a mudança de senha do usuário.
     * 
     * @param params Os dados de senha do usuário.
     * @returns Promise com o resultado da requisição.
     */
    changePassword(params): Promise<any>{
        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            method: AxiosMethodsName.put,
            data: params,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Realiza a recuperação da senha do usuário.
     * 
     * @param email O email do usuário.
     * @returns Promise com o resultado da requisição.
     */
    requestPasswordEmail(email): Promise<any>{

        const params = new URLSearchParams();
        params.append('email', email);

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/resetPassword`,
            method: AxiosMethodsName.post,
            data: email,
            params: params,
            handleCredentials: false
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Realiza o reset da senha do usuário.
     * 
     * @param data Os dados da senha do usuário.
     * @returns Promise com o resultado da requisição.
     */
    requestPasswordReset(data) : Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/changePassword`,
            method: AxiosMethodsName.put,
            data: data,
            handleCredentials: false
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Configura o papel do usuário para o papel de administrador.
     * 
     * @param email O email do usuário.
     * @returns Promise com o resultado da requisição.
     */
    setAdminRole(email: string): Promise<any> {
        const params = new URLSearchParams();
        params.append('email', email);

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/set-admin`,
            method: AxiosMethodsName.put,
            params: params,
            handleCredentials: true
        };

        return super.executeMethod(configRequest);
    }
}