import {
  HttpClientService,
  AxiosMethodsName,
  MentoringConfigRequest,
} from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados ao login.
 */
export default class LoginService extends HttpClientService {
  constructor() {
    super("login");
  }

  /**
   * Realiza o login o usuário na aplicação.
   *
   * @param nome O nome de usuário.
   * @param senha A senha do usuário.
   * @returns Promise com o resultado da operação de login.
   */
  login(nome: any, senha: any): Promise<any> {
    const params = new URLSearchParams();
    params.append("username", nome);
    params.append("password", senha);
    params.append("grant_type", "password");
    params.append("client_id", "reactweb001");
    params.append("client_secret", "clientereac14578999912");

    const apiOauth2 = this.getConfiguration().outh2Url;
    const configRequest: MentoringConfigRequest = {
      url: apiOauth2 + "/token",
      method: AxiosMethodsName.post,
      params: params,
      handleCredentials: false,
      headers: {
        Authorization: "Basic cmVhY3R3ZWIwMDE6MTEyMw==",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return this.executeMethod(configRequest);
  }
}
