import React, { useContext } from 'react';

import {
    Card,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Tooltip
} from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import { SpinnerSplendis } from '../../../../../../commons';
import { Colaborador } from '../../../../../../models';
import { limitString } from '../../../../../../utils/FormUtils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    HOME_MENTORADO_SOURCE,
    HOME_MENTORADO_HISTORICO_MENTORIAS_BOTAO,
    HOME_MENTORADO_CARD_EDITAR_MENTORADO,
    HOME_MENTORADO_AGENDAR_MENTORIA_BOTAO
} from '../../../../../../messages';
import { UserContext } from '../../../../../../service';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '18.2rem',
            height: '28rem',
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            border: '1px solid #D3CAB7',

            '@media only screen and (max-width: 414px)': {
                height: 'auto',
                margin: '5vh auto',
                width: '100%',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                height: '30rem',
                margin: '0 auto',
                width: '50%',
                marginTop: 20,
                marginBottom: '2vh'
            }
        },
        name: {
            fontFamily: 'nexaRegular',
            fontSize: '1.5rem',
            lineHeight: '2.9rem',
            marginBottom: '0.5rem',
            paddingBottom: 0,
            height: '2.2rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#636466',

            '@media only screen and (max-width: 1024px)': {
                marginBottom: '0.7rem',
            }
        },
        cargo: {
            fontFamily: 'infraLight',
            fontWeight: 300,
            color: '#636466',
            paddingTop: 0,
            marginTop: 0,
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
        },
        email: {
            fontFamily: 'infraLight',
            fontWeight: 300,
            color: '#1F1F1F',
            paddingTop: 0,
            marginTop: 0,
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
        },
        mediaDiv: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1.1rem',
            marginBottom: 0,
        },
        media: {
            display: 'block',
            height: '7.6rem',
            width: '7.6rem',
            borderRadius: '50%',
        },
        mediaEdit: {
            backgroundColor: '#003B4A',
            border: '1px solid #FFF',
            borderRadius: '50%',
            color: '#FFF',
            display: 'flex',
            float: 'right',
            height: '1.2rem',
            margin: '.5rem .5rem',
            padding: '.5rem .5rem',
            width: '1.2rem',

            '&:hover': {
                cursor: 'pointer',
                opacity: '.7'
            },
        },
        descriptionContainer: {
            maxHeight: '100%',
            width: '100%',
            marginTop: '1rem'
        },
        cardContent: {
            padding: 10,
            height: '13vh',

            '@media only screen and (max-width: 1024px)': {
                height: '9vh',
            }
        },
        button: {
            width: '14rem',
            height: '2.75rem',
            margin: '0 auto',
            fontFamily: 'infraMedium',
            marginBottom: '1rem',
            color: '#FFF',
            backgroundColor: '#003B4A',

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                margin: '2vh 1vw',
                width: '12rem',
            }
        },
        divider: {
            width: '14rem',
            marginBottom: '0.8rem',

            '@media only screen and (max-width: 414px)': {
				marginTop: '8vh',
			},

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
				marginTop: '20vh',
			},
        },
        spinnerContainer: {
            height: 350,
            width: 350,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

/**
 * Interface utilizada pelo componente MentoradoDashboardCard e que descreve
 * seu métodos e propriedades.
 */
interface MentoradoDashboardCardProps {
    colaborador?: Colaborador;
    style?: any;
    id: any;
}

/**
 * O card com informações e botões de ação no perfil do mentorado.
 * 
 * @param {MentoradoDashboardCardProps}
 * @returns O card com informações e botões de ação no perfil do mentorado.
 */
const MentoradoDashboardCard: React.FC<MentoradoDashboardCardProps> = ({ colaborador, style, id }) => {
    const classes = useStyles();

    /**
     * Hook e função utilizados na tradução de textos.
     */
    const { t } = useTranslation([HOME_MENTORADO_SOURCE]);

    const history = useHistory();

    /**
     * Função que realiza a troca de papeis do usuário logado.
     */
    const { switchRole } = useContext(UserContext);

    /**
     * Renderiza um loading spinner.
     * 
     * @returns O loading spinner.
     */
    const spinnerCentralizado = () => {
        return (
            <div className={classes.spinnerContainer}>
                <SpinnerSplendis />
            </div>
        )
    }

    /**
     * Renderiza todo o conteúdo do card do mentorado.
     * 
     * @returns O conteúdo do card do mentorado.
     */
    const content = () => {
        return (
            <>
                <div>
                    <Tooltip arrow title={t(HOME_MENTORADO_CARD_EDITAR_MENTORADO, 'Editar Mentorado') || ''}>
                        <EditIcon className={classes.mediaEdit} onClick={() => id ?
                            history.push('/mentoring/mentor/edicao') :
                            history.push('/mentoring/mentorado/edicao')} />
                    </Tooltip>
                </div>
                <div className={classes.mediaDiv}>
                    <CardMedia className={classes.media} image={colaborador?.foto} title={colaborador?.nome} />
                </div>
                <CardContent className={classes.cardContent}>
                    <div>
                        <Typography gutterBottom className={classes.name} variant='h5' component='h3' noWrap={false}>
                            {limitString(colaborador?.nome || '', 22)}
                        </Typography>
                        <Typography gutterBottom className={classes.cargo} variant='body2'
                            component='p' noWrap={true}>
                            {colaborador?.cargo}
                        </Typography>
                        <Typography gutterBottom className={classes.email} variant='body2'
                            component="p" noWrap={true}>
                            {colaborador?.email}
                        </Typography>

                    </div>
                </CardContent>
                <div>
                    <hr className={classes.divider} />

                    <Button
                        className={classes.button}
                        onClick={() => {
                            history.push("/mentoring/historico-mentorias");
                            switchRole('ROLE_MENTORADO');
                        }}
                        size="medium"
                        variant="contained"
                    >
                        {t(HOME_MENTORADO_HISTORICO_MENTORIAS_BOTAO, "Histórico de mentorias")}
                    </Button>

                    <Button
                        className={classes.button}
                        onClick={() => history.push('/mentoring/agendar')}
                        size="medium"
                        variant="contained"
                        
                    >
                        {t(HOME_MENTORADO_AGENDAR_MENTORIA_BOTAO, "Agendar mentoria")}
                    </Button>
                </div>
            </>
        )
    }

    return (
        <Card style={style ? style : {}} className={classes.root}>
            { colaborador ? content() : spinnerCentralizado()}
        </Card>
    );
}

export default MentoradoDashboardCard;