import React from "react";
import {
  Container,
  Button,
  withStyles,
  createStyles,
  MenuItem,
  TextField,
  Theme,
  Select,
  Typography,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import MaterialTable from "@material-table/core";

import RhMentoriaDashBoardProps from "./RhMentoriaDashBoardProps";
import {
  Mentorado,
  Mentor,
  Categoria,
  DisponibilidadeMentor,
} from "../../../../../models";
import { alertContext, MenuRh } from "../../../../../components";
import SpinnerSplendis from "../../../../../commons/spinner-splendis/SpinnerSplendis";
import {
  allStatusMentoria,
  formatDateToMaterialUIDate,
  limitString,
  mapStatusMentoria,
} from "../../../../../utils/FormUtils";
import { withTranslation } from "react-i18next";
import {
  RH_GERENCIAR_MENTORIAS,
  RH_MENTORIAS_EMAIL_MENTORADO,
  RH_MENTORIAS_MENTORADO,
  RH_MENTORIAS_SOURCE,
  RH_MENTORIAS_MENTOR,
  RH_MENTORIAS_SEM_MENTORIAS,
  RH_MENTORIAS_EDITAR,
  RH_MENTORIAS_BUSCAR,
  RH_MENTORIAS_CATEGORIA,
  RH_MENTORIAS_HORARIO,
  RH_MENTORIAS_DATA,
  RH_MENTORIAS_SUCESSO_ATUALIZACAO,
  RH_MENTORIAS_ERRO_ATUALIZACAO,
  RH_MENTORIAS_DE,
  STATUS_MENTORIA_AGENDADA,
  STATUS_MENTORIA_CONCLUIDA,
  STATUS_MENTORIA_AGUARDANDO_AVALIACAO,
  STATUS_MENTORIA_NAO_REALIZADA,
  STATUS_MENTORIA_REAGENDADA,
  STATUS_MENTORIA_CANCELADA,
} from "../../../../../messages";

const pageStyle = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2.5rem",
      marginBottom: "2.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      padding: 0,

      "@media only screen and (max-width: 414px)": {
        width: "90vw",
      },
    },
    titleDiv: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    title: {
      fontFamily: "infraRegular",
      lineHeight: "150%",
      fontSize: "1.625rem",
    },
    button: {
      width: "4.813rem",
      height: "2.75rem",
    },
    link: {
      textDecoration: "none",
    },
    downloadDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: "1rem",
    },
    downloadButton: {
      color: "white",
      backgroundColor: "#003B4A",

      '&:hover': {
        backgroundColor: '#0a3039',
      },
      margin: 4,
    },
    search: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      paddingTop: "1rem",
      marginBottom: "1rem",

      "@media only screen and (max-width: 720px)": {
        display: "block",
      },
    },
    searchField: {
      marginRight: "1rem",
      backgroundColor: "#FFFFFF",

      "@media only screen and (max-width: 414px)": {
        margin: ".5vh 0",
        width: "90vw",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        margin: ".5vh 0",
        width: "85vw",
      },
    },
    status: {
      marginLeft: theme.spacing(1),
    },
    dashboardTitle: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      fontWeight: 600,

      "@media only screen and (max-width: 414px)": {
        fontSize: "1rem",
      },
    },
    searchFieldLabel: {
      "@media only screen and (max-width: 720px)": {
        fontSize: ".8rem",
      },
    },
    searchButton: {
      color: "white",
      backgroundColor: "#003B4A",

      '&:hover': {
        backgroundColor: '#0a3039',
      },

      "@media only screen and (max-width: 414px)": {
        marginBottom: "5vh",
        marginTop: ".9vh",
        width: "90vw",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        marginBottom: "5vh",
        marginTop: ".9vh",
        width: "85vw",
      },
    },
  });

/**
 * Estilização utilizada na tabela de mentorias.
 */
const tableStyle = {
  container: {
    marginTop: "1rem",
    marginBottom: "50px",
    minHeight: 400,
    fontFamily: "infraRegular",
    height: "auto",
    backgroundColor: "#F0F1F3",
    paddingBottom: "1rem",
  },
  image: {
    width: "10vh",
    height: "10vh",
    borderRadius: "100%",
  },
  headerStyle: {
    backgroundColor: "#CFD4DA",
    fontFamily: "infraMedium",
  },
  title: {
    fontFamily: "infraMedium",
  },
  actionButton: {
    color: "#003B4A",
  },
  table: {
    backgroundColor: "#CFD4DA",
  },
  searchField: {
    backgroundColor: "#F0F1F3",
  },
  filter: {
    backgroundColor: "#F0F1F3",
    marginLeft: "1rem",
    paddingLeft: "1rem",
    paddingTop: "0.6rem",
  },
};

/**
 * Estilização utilizada de forma geral no container da tabela de mentorias.
 */
const GlobalCss = withStyles((theme) => ({
  "@global": {
    ".MuiTableRow-root": {
      "&:nth-of-type(even)": {
        backgroundColor: "#FFFFFF",
      },
      "&:nth-of-type(odd)": {
        backgroundColor: "#F8F8F8",
      },
    },
  },
}))(() => null);

/**
 * Interface utilizada na implementação das queries com parâmetros
 * relativos a aspectos da busca, como número de paǵinas e o tamanho
 * de cada página.
 */
interface IQuery {
  page: number;
  pageSize: number;
  search?: ISearch;
}

/**
 * Inteface utilizada na implementação de buscas de informações relativas
 * ao preenchimento do conteúdo da tabela, como nome do mentorado, email
 * do mentorado, nome do mentor e status da mentoria.
 */
interface ISearch {
  nomeMentorado: string;
  emailMentorado: string;
  nomeMentor: string;
  statusMentoria: string;
}

/**
 * Interface utilizada pelo componente abaixo e que descreve
 * seu métodos e propriedades.
 */
export interface MentoriaDashBoardState {
  id?: number;
  nome?: string;
  email?: string;
  categoria?: Categoria;
  mentor?: Mentor;
  mentorado?: Mentorado;
  disponibilidade?: DisponibilidadeMentor;
  statusMentoria?: string;
  dataTable: Array<any>;
  loading: boolean;
  downloading: boolean;
  query: IQuery;
  search: ISearch;
}

/**
 * Componente para renderizar a tabela de mentorias e editar as mesmas.
 *
 * Apenas acessível para o papel de ADMIN.
 */
class RhMentoriaDashBoardComponent extends React.Component<
  RhMentoriaDashBoardProps,
  MentoriaDashBoardState
> {
  referencia = React.createRef<any>();

  constructor(props: any) {
    super(props);
    this.state = {
      dataTable: [],
      loading: true,
      downloading: false,
      query: {
        page: 0,
        pageSize: 5,
      },
      search: {
        nomeMentorado: "",
        emailMentorado: "",
        nomeMentor: "",
        statusMentoria: "",
      },
    };
  }

  /**
   * Realiza uma bifurcação entre receber todos as mentorias
   * ou as mentorias filtradas.
   *
   * @param query Dados da query a ser realizada como página e o tamanho da query.
   * @returns Promise com os resultados da busca.
   */
  handleData(query): Promise<any> {
    this.setState({ loading: true });
    return this.state.query.search
      ? this.handleDataSearch(query)
      : this.handleDataPagination(query);
  }

  /**
   * Lida com a busca de dados das mentorias.
   *
   * @param queryParam Dados da query a ser realizada como página e o tamanho da query.
   * @returns Promise com os resultados da busca.
   */
  handleDataSearch(query): Promise<any> {
    return new Promise((resolve) => {
      this.props.mentoriaService
        .dashboardSearch(query.page, query.pageSize, this.state.query.search)
        .then((response) => response.data)
        .then((result) => {
          resolve({
            data: result.conteudo,
            page: result.numeroPaginaAtual,
            totalCount: result.totalElementos,
          });

          let newData = this.state.dataTable;
          newData.push(result.conteudo);
          this.setState({ loading: false, dataTable: newData });
        });
    });
  }

  /**
   * Lida com a paginação dos dados.
   *
   * @param query Dados da query a ser realizada como número da página atual
   *              e o tamanho de elementos.
   * @returns Promise com os resultados da busca.
   */
  handleDataPagination(query): Promise<any> {
    return new Promise((resolve) => {
      this.props.mentoriaService
        .getMentoriasPagination(query.page, query.pageSize)
        .then((response) => response.data)
        .then((result) => {
          resolve({
            data: result.conteudo,
            page: result.numeroPaginaAtual,
            totalCount: result.totalElementos,
          });

          let newData = this.state.dataTable;
          newData.push(result.conteudo);
          this.setState({ loading: false, dataTable: newData });
        });
    });
  }

  /**
   * Atualiza o state de pesquisa com os novos valores de pesquisa.
   *
   * @param e O evento que dispara a atualização do state de pesquisa,
   *        com os atributos nome e valor.
   */
  handleChange(e: React.ChangeEvent<any>) {
    const { name, value } = e.target;
    let newSearch = this.state.search;
    newSearch[name] = value;

    this.setState({ search: newSearch });
  }

  /**
   * Realiza a pesquisa e atualiza a tabela com as novas avaliações filtradas
   */
  handleSearch() {
    let newQuery = this.state.query;
    newQuery.search = this.state.search;
    this.setState({ query: newQuery });
    this.handleData(newQuery);
    this.referencia.current.onQueryChange();
  }

  render() {
    //@ts-ignore
    const { t, classes } = this.props;

    return (
      <>
        {/* Sub header */}
        <Container maxWidth="lg" fixed className={classes.root}>
          <MenuRh />
          <div className={classes.titleDiv}>
            <Typography
              variant="h5"
              align="left"
              className={classes.dashboardTitle}
            >
              {t(RH_GERENCIAR_MENTORIAS, "Gerenciar Mentorias")}
            </Typography>
          </div>
        </Container>

        {/* Body */}
        <Container maxWidth="lg" fixed style={tableStyle.container}>
          <div className={classes.search}>
            {/* FILTROS */}
            <TextField
              id="nomeMentorado"
              name="nomeMentorado"
              label={t(RH_MENTORIAS_MENTORADO, "Mentorado")}
              inputProps={{ maxLength: 60 }}
              variant="outlined"
              className={classes.searchField}
              onChange={(e) => this.handleChange(e)}
              InputLabelProps={{
                className: classes.searchFieldLabel,
              }}
            />
            <TextField
              id="emailMentorado"
              name="emailMentorado"
              label={t(RH_MENTORIAS_EMAIL_MENTORADO, "Email Mentorado")}
              variant="outlined"
              className={classes.searchField}
              onChange={(e) => this.handleChange(e)}
              InputLabelProps={{
                className: classes.searchFieldLabel,
              }}
            />
            <TextField
              id="nomeMentor"
              name="nomeMentor"
              label={t(RH_MENTORIAS_MENTOR, "Mentor")}
              variant="outlined"
              className={classes.searchField}
              onChange={(e) => this.handleChange(e)}
              InputLabelProps={{
                className: classes.searchFieldLabel,
              }}
            />
            <Select
              id="statusMentoria"
              name="statusMentoria"
              className={classes.searchField}
              onChange={(e) => this.handleChange(e)}
              displayEmpty
              variant="outlined"
              defaultValue=""
            >
              <MenuItem value="" disabled>
                Status
              </MenuItem>
              {allStatusMentoria().map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    {mapStatusMentoria(status, t)}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              className={classes.searchButton}
              variant="contained"
              color="primary"
              onClick={() => this.handleSearch()}
            >
              {t(RH_MENTORIAS_BUSCAR, "Buscar")}
            </Button>
          </div>
          <GlobalCss />
          <alertContext.Consumer>
            {({ showAlert }) => {
              return (
                <MaterialTable
                  title=""
                  localization={{
                    header: {
                      actions: "",
                    },
                    body: {
                      emptyDataSourceMessage: t(
                        RH_MENTORIAS_SEM_MENTORIAS,
                        "Sem mentorias para exibir"
                      ),
                      editTooltip: t(RH_MENTORIAS_EDITAR, "Editar"),
                    },
                    pagination: {
                      labelDisplayedRows: `{from}-{to} ${t(
                        RH_MENTORIAS_DE,
                        "de"
                      )} {count}`,
                      labelRowsSelect: "itens",
                    },
                    toolbar: {
                      searchTooltip: t(RH_MENTORIAS_BUSCAR, "Buscar"),
                      searchPlaceholder: t(RH_MENTORIAS_BUSCAR, "Buscar"),
                    },
                  }}
                  style={tableStyle.table}
                  tableRef={this.referencia}
                  columns={[
                    {
                      title: "id",
                      field: "id",
                      editable: "never",
                      hidden: true,
                    },
                    {
                      title: t(RH_MENTORIAS_MENTORADO, "Mentorado"),
                      field: "nomeMentorado",
                      editable: "never",
                      render: (rowData) =>
                        limitString(rowData.nomeMentorado, 30),
                    },
                    {
                      title: t(
                        RH_MENTORIAS_EMAIL_MENTORADO,
                        "Email do mentorado"
                      ),
                      field: "emailMentorado",
                      editable: "never",
                    },
                    {
                      title: t(RH_MENTORIAS_CATEGORIA, "Categoria"),
                      field: "categoria",
                      editable: "never",
                    },
                    {
                      title: t(RH_MENTORIAS_MENTOR, "Mentor"),
                      field: "nomeMentor",
                      editable: "never",
                      render: (rowData) => limitString(rowData.nomeMentor, 30),
                    },
                    {
                      title: t(RH_MENTORIAS_HORARIO, "Horário"),
                      field: "hora",
                      editable: "never",
                    },
                    {
                      title: t(RH_MENTORIAS_DATA, "Data"),
                      field: "data",
                      editable: "never",
                      render: (rowData) =>
                        formatDateToMaterialUIDate(rowData.data),
                    },
                    {
                      title: "Status",
                      field: "status",
                      lookup: {
                        CANCELADA: t(STATUS_MENTORIA_CANCELADA, "Cancelada"),
                        AGENDADA: t(STATUS_MENTORIA_AGENDADA, "Agendada"),
                        AGUARDANDO_AVALIACAO: t(
                          STATUS_MENTORIA_AGUARDANDO_AVALIACAO,
                          "Aguardando avaliação"
                        ),
                        NAO_REALIZADA: t(
                          STATUS_MENTORIA_NAO_REALIZADA,
                          "Não realizada"
                        ),
                        CONCLUIDA: t(STATUS_MENTORIA_CONCLUIDA, "Concluída"),
                        REAGENDADA: t(STATUS_MENTORIA_REAGENDADA, "Reagendada"),
                      },
                    },
                  ]}
                  data={(query) => this.handleData(query)}
                  isLoading={this.state.loading}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise<void>((resolve) => {
                        setTimeout(() => {
                          resolve();

                          if (oldData) {
                            this.setState((prevState) => {
                              const data = [...prevState.dataTable];
                              data[data.indexOf(oldData)] = newData;

                              this.props.mentoriaService
                                .editarPagination(newData.id, newData)
                                .then(() => {
                                  showAlert(
                                    200,
                                    t(
                                      RH_MENTORIAS_SUCESSO_ATUALIZACAO,
                                      "Atualização efetuada com sucesso!"
                                    )
                                  );
                                  //this.getMentorias()
                                  this.referencia.current.onQueryChange();
                                })
                                .catch(() => {
                                  showAlert(
                                    400,
                                    t(
                                      RH_MENTORIAS_ERRO_ATUALIZACAO,
                                      "Erro na atualização da mentoria!"
                                    )
                                  );
                                });
                              return { ...prevState, data };
                            });
                          }
                        }, 600);
                      }),
                  }}
                  options={{
                    paginationType: "normal",
                    pageSize: this.state.query.pageSize,
                    toolbar: false,
                    search: false,
                    filtering: false,
                    sorting: false,
                    headerStyle: tableStyle.headerStyle,
                    actionsColumnIndex: -1,
                    actionsCellStyle: tableStyle.actionButton,
                    searchFieldStyle: tableStyle.searchField,
                    showTitle: false,
                  }}
                  onRowsPerPageChange={(pageSize: number) => {
                    let newQuery = this.state.query;
                    newQuery.pageSize = pageSize;
                    this.setState({ query: newQuery });
                  }}
                  onPageChange={(page: number) => {
                    let newQuery = this.state.query;
                    newQuery.page = page;
                    this.setState({ query: newQuery });
                  }}
                />
              );
            }}
          </alertContext.Consumer>
          <div className={classes.downloadDiv}>
            {/* DOWNLOAD DE MENTORIAS: */}
            {this.state.downloading ? (
              <SpinnerSplendis />
            ) : (
              <Button
                className={classes.downloadButton}
                variant="contained"
                onClick={() => {
                  this.setState({ downloading: true });

                  this.props.relatorioExcelService
                    .getRelatorioMentorias()
                    .then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");

                      link.href = url;
                      link.setAttribute("download", "mentorias.xlsx");
                      document.body.appendChild(link);

                      link.click();
                      this.setState({ downloading: false });
                    });
                }}
              >
                {" "}
                Download XSLX <GetAppIcon />{" "}
              </Button>
            )}
          </div>
        </Container>
      </>
    );
  }

  /**
   * Realiza o fetch de mentorias.
   */
  getMentorias() {
    this.setState({ loading: true });
    this.props.mentoriaService.getMentorias().then((dataTable) => {
      this.setState({ dataTable: dataTable, loading: false });
    });
  }

  /**
   * Exclui uma mentoria com base no seu id.
   *
   * @param id O id da mentoria.
   *
   * Obs.: Método não está totalmente implementado.
   */
  delete(id: any) {
    this.props.mentoriaService.delete(id).then(() => {});
  }
}

export default withTranslation(RH_MENTORIAS_SOURCE)(
  withStyles(pageStyle)(RhMentoriaDashBoardComponent)
);
