import React from "react";
import { ResponsiveBar } from "@nivo/bar";

/**
 * Interface utilizada pelo componente BarChart e que descreve
 * seu métodos e propriedades.
 */
interface BarChartProps {
  data?: any;
  legends?: any;
  margins?: any;
  scheme?: any;
  rotateLabelLeft?: number;
  rotateLabelBottom?: number;
  layout?: any;
  enableGridY?: boolean;
  labelTextColor?: string;
  enableLegends?: boolean;
}

/**
 * Componente para renderizar gráfico de barras.
 *
 * @param {BarChartProps}
 * @returns O componente ResponsiveBar que renderiza gráficos de barras.
 */
const BarChart: React.FC<BarChartProps> = ({
  data,
  legends,
  margins,
  scheme,
  rotateLabelBottom,
  rotateLabelLeft,
  layout,
  enableGridY,
  labelTextColor,
  enableLegends = true,
}) => {
  return (
    <ResponsiveBar
      data={data}
      indexBy="id"
      margin={margins}
      padding={0.45}
      layout={layout ? layout : "horizontal"}
      colors={{ scheme: scheme ? scheme : "nivo" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: rotateLabelBottom ? rotateLabelBottom : 0,
        legend: legends.axisBottom,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={
        enableLegends
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: rotateLabelLeft ? 25 : 0,
              legend: legends.axisLeft,
              legendPosition: "middle",
              legendOffset: -40,
            }
          : null
      }
      enableGridY={enableGridY ? enableGridY : false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={labelTextColor ? labelTextColor : "#000000"}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default BarChart;
