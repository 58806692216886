import axios, { Method, AxiosRequestConfig } from 'axios';
import { ChavesLocalStorage, profile } from '../config/index'
import SessionStorageService from './SessionStorageService';
import { IRequest } from '../core/models/request.interface';

/**
 * Classe que define os métodos a serem utilizados nas requisições HTTP.
 */
export class AxiosMethodsName {
    static get: Method = 'get'
    static post: Method = 'post'
    static put: Method = 'put'
    static delete: Method = 'delete'
}

/**
 * Inteface utilizada pelo Mentoring para gerenciar credenciais.
 */
export interface MentoringConfigRequest extends AxiosRequestConfig {
    handleCredentials?: boolean
}

/**
 * Verifica se o gerenciador de requisições está habilitado.
 * 
 * @param config Configurações da requisição.
 * @returns True caso o a propriedade handleCredentials exista, False caso contrário.
 */
const isHandlerEnabled = (config: any) => {
    return !config.hasOwnProperty('handleCredentials') || config.handleCredentials === false ?
        false : true
}

/**
 * Gerencia as requisições.
 * 
 * @param request A requisição a ser feita.
 * @returns A requisição.
 */
const requestHandler = (request: any) => {

    request.headers['Content-Type'] = 'application/json'

    if (isHandlerEnabled(request)) {
        request.headers.authorization = 'Bearer ' + SessionStorageService.getStorage(ChavesLocalStorage.session_key)
    }
    return request
}

/**
 * Intercepta a requisição antes da mesma ser enviada ao handler.
 */
axios.interceptors.request.use(
    request => requestHandler(request)
)

/**
 * Classe que provê os vários métodos HTTP, o quais são
 * utilizados por outros services e componentes.
 */
export class HttpClientService {

    static apiRestUrl = profile().apiRestUrl
    resource: string | undefined

    constructor(resource?: string) {
        this.resource = resource
    }

    /**
     * Obtém o resource(e.g. url).
     * 
     * @returns O resource(e.g. url).
     */
    getResource() {
        return this.resource;
    }

    /**
     * Configura o resource
     * 
     * @param url A url a ser configurada para a variável resource.
     */
    setResource(url: string) {
        this.resource = url
    }

    /**
     * Obtém a url base da aplicação.
     * 
     * @returns A url base da aplicação.
     */
    getURL(): string {
        return HttpClientService.apiRestUrl + '/' + this.resource
    }

    /**
     * Obtém a url base juntamente com um id específico.
     * 
     * @param id O id a ser concatenado a url.
     * @returns A url base juntamente com um id específico.
     */
    getURLId(id: any): string {
        return this.getURL() + '/' + id
    }

    /**
     * Método genérico para a obtenção de resources.
     * 
     * @returns Promise com o resultado da requisição.
     */
    get<T>(): Promise<IRequest<T>> {
        return axios({
            url: this.getURL(),
            method: AxiosMethodsName.get
        })
    }

    /**
     * Obtém a url base juntamente com um id específico.
     * 
     * @param id O id a ser concatenado a url.
     * @returns Promise com o resultado da requisição.
     */
    getById(id: any): Promise<any> {
        return axios({
            url: this.getURLId(id),
            method: AxiosMethodsName.get
        })
    }

    /**
     * Envia dados ao servidor.
     * 
     * @param data Os dados a serem enviados na requisição.
     * @returns Promise com o resultado da requisição.
     */
    post(data: any): Promise<any> {
        return axios({
            url: this.getURL(),
            method: AxiosMethodsName.post,
            data: data,
            maxContentLength: 5000000,
        })
    }

    /**
     * Altera um recurso no servidor.
     * 
     * @param data Os dados a serem enviados e salvos.
     * @param id O id do recurso a ser modificado.
     * @returns Promise com o resultado da requisição.
     */
    put(data: any, id: number) {
        return axios({
            url: this.getURLId(id),
            method: AxiosMethodsName.put,
            data: data
        })
    }

    /**
     * Deleta recursos com base no id fornecido.
     * 
     * @param id O id específico do recurso.
     * @returns Promise com o resultado da requisição.
     */
    delete(id: any): Promise<any> {
        return axios({
            url: this.getURLId(id),
            method: AxiosMethodsName.delete,
        })
    }

    /**
     * Obtém os headers de autorização.
     * 
     * @param hasAuthorization Condição que verifica se há autorização.
     * @returns Headers de autorização da requisição.
     */
    getAuthHeaders(hasAuthorization: boolean = false): any {
        let headers: any = {
            'Content-Type': 'application/json',
        }
        if (hasAuthorization)
            headers.authorization = 'Bearer ' + SessionStorageService.getStorage(ChavesLocalStorage.session_key)

        return headers;
    }

    /**
     * Método que executa as requisições axios.
     * 
     * @param config Configurações da requisição(e.g. url, method, baseURL, headers, params etc.)
     * @returns Promise com o resultado da requisição.
     */
    executeMethod(config: AxiosRequestConfig): Promise<any> {
        return axios(config)
    }

    /**
     * Valida os tokens de autenticação.
     * 
     * @returns Promise com o resultado da requisição.
     */
    static async validarToken(): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: HttpClientService.apiRestUrl + '/auth/validar-token',
            method: AxiosMethodsName.get,
            handleCredentials: true
        }
        const result = await axios(configRequest)
        //console.log('Verificação de token')
        //Promise.resolve(result.data.isTokenValid)
        return Promise.resolve(result.data.isTokenValid);
    }

    /**
     * Obtém o perfil utilizado no processo de deploy e execução da aplicação.
     * 
     * @returns O perfil utilizado no processo de deploy e execução da aplicação.
     */
    getConfiguration() {
        return profile();
    }

}