import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createTheme, createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { List, Divider, Avatar, Typography, IconButton, Backdrop, Badge, Popover } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';

import { SpinnerSplendis } from '../../../commons';
import { ColaboradorService, MentorService } from '../../../service';
import {
	PERFIL_MENU_SOURCE,
	PERFIL_MENU_ADMINISTRADOR,
	PERFIL_MENU_HOME,
	// PERFIL_MENU_SEJA_MENTOR,
	PERFIL_MENU_FAQ,
	PERFIL_MENU_SAIR,
	PERFIL_MENU_SAUDACAO,
	PERFIL_MENU_NOTIFICACAO_TITULO,
	PERFIL_MENU_NOTIFICACAO_SUBTITULO_P1,
	PERFIL_MENU_NOTIFICACAO_SUBTITULO_P2,
	PERFIL_MENU_NOTIFICACAO_SUBTITULO_P3,
	PERFIL_MENU_NOTIFICACAO_RODAPE,
	PERFIL_MENU_SEM_NOTIFICACAO,
} from '../../../messages';
import { diaAtualFormatted, getHour, limitString } from '../../../utils/FormUtils';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#FFF',
		},
		secondary: {
			main: '#003B4A',
		}
	},
});

export const styles = {
	mobile: {
		button: {
			fontSize: '0.65rem',
			height: 42,
			width: 42,
			padding: '4px 6px'
		},

		menu: {
			fontSize: '0.7rem',
			marginLeft: -48,
			marginTop: 55,
		},

		menuItem: {
			fontSize: '0.7rem',
			minHeight: 30,
		},

		avatar: {
			marginTop: '1vh',
		}
	},

	desktop: {
		button: {},

		menu: {
			marginLeft: '-4.5rem',
			marginTop: '3em',
		},

		menuItem: {},

		avatar: {
			display: 'inline-block',
			margin: 4
		}
	},
}

const useStyles = makeStyles(() =>
	createStyles({
		toggleLanguages: {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: '.5rem',
		},
		muiThemeContainer: {
			marginRight: '4px',
		},
		navlinks: {
			display: 'flex',
			marginRight: '2rem',
		},
		userPicture: {
			marginRight: '.7rem',
			alignSelf: 'center',
			borderColor: 'white',
			borderStyle: 'solid',
			borderWidth: '2px',
		},
		username: {
			fontFamily: 'NexaRegular',
			fontSize: '.9rem',
			lineHeight: '16.41px',
			alignSelf: 'center',
			color: '#fff',

			'@media only screen and (max-width: 414px)': {
				display: 'none',
			}
		},
		expandButton: {
			alignSelf: 'center',
		},
		notification: {
			alignSelf: 'center',
			width: '1.5rem',

			'@media only screen and (max-width: 414px)': {
				marginLeft: '2vw',
				marginRight: '-3vw',
				marginTop: '1vh',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				marginLeft: '1vw',
				marginRight: '-1vw',
				marginTop: '6vh',
			},

			'&:hover': {
				cursor: 'pointer',
			}
		},
		badge: {
			backgroundColor: '#FF6C00',
			marginRight: '.4rem',
			marginTop: '1.6rem',
			width: '.5rem',

			'@media only screen and (max-width: 414px)': {
				marginLeft: '1vw',
				marginRight: '-1vw',
				marginTop: '6vh',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				marginRight: '.1vw',
				marginTop: '7.8vh',
			},
		},
		popoverText: {
			minHeight: '13rem',
			maxHeight: '13rem',
			textAlign: 'center',
			textJustify: 'inter-word',
			width: '12rem',
		},
		popoverTitle: {
			color: '#373A3C',
			fontFamily: 'InfraRegular',
			fontStyle: 'normal',
			fontSize: '.85rem',
			fontWeight: 400,
			lineHeight: '22.98px',
			marginBottom: '.5rem',
			marginTop: '.6rem',
		},
		popoverSubtitle: {
			color: '#7B7B7B',
			fontFamily: 'InfraRegular',
			fontStyle: 'normal',
			fontSize: '.7rem',
			fontWeight: 400,
			lineHeight: '24px',
		},
		popoverLink: {
			color: '#003B4A',
			fontFamily: 'InfraRegular',
			fontStyle: 'normal',
			fontSize: '.7rem',
			fontWeight: 700,
			lineHeight: '24px',
			textDecoration: 'none',

			'&:hover': {
				cursor: 'pointer',
			}
		},
		popoverFooter: {
			color: '#7B7B7B',
			fontFamily: 'InfraRegular',
			fontStyle: 'normal',
			fontSize: '10px',
			fontWeight: 400,
			lineHeight: '14.36px',
			marginBottom: '.5rem',
			marginTop: '.5rem',
		},
		popoverNoNotification: {
			paddingTop: '4rem'
		}
	})
);

/**
 * Obtém as dimsensões da tela, em pixels.
 *
 * @returns A largura interior e a altura interior da tela, em pixels.
 */
function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

/**
 * Componente responsável pela renderização do menu do perfil do usuário e também
 * do alerta de mentorias pendentes.
 *
 * @param props Propriedades passadas a este componente para fornecer melhor controle
 * 				sobre o seu funcionamento e comportamento.
 * @returns O menu do perfil do usuário juntamente com o alerta de mentorias pendentes.
 */
export default function PerfisMenu(props) {
	const styleClasses = useStyles();

	// Hooks utilizados para a tradução dos textos do componente.
	const { i18n } = useTranslation();
	const { t } = useTranslation([PERFIL_MENU_SOURCE]);

	const { actualRole, roles, logout, switchRole, userObject, loading, mentoriasPendentes, session } = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [popoverAnchor, setPopoverAnchor] = useState(null);
	const [dimensions, setDimensions] = useState(getWindowDimensions());
	const [openMenu, setOpenMenu] = useState(false);
	const [isMentor, setIsMentor] = useState(false);
	const [loadingMenu, setLoadingMenu] = useState(false);

	const history = useHistory();

	const { width } = dimensions;
	const classes = styles;

	/**
	 * Redimensiona a tela ao renderizar o componente.
	 */
	useEffect(() => {
		function handleResize() {
			setDimensions(getWindowDimensions());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	/**
	 * Obtém informações do mentor, desde que o id do mentor exista na seção.
	 */
	useEffect(() => {
		if (session.mentor.id) {
			setLoadingMenu(true);
            const mentorServce: MentorService = new MentorService();
			mentorServce.getInformacaoMentorEdicaoById(session.mentor.id)
				.then(res => {
					setIsMentor(res.data.statusMentor);
					setLoadingMenu(false);
				});
		}
	}, [session.mentor.id]);

	/**
	 * Obtém informações do colaborador, desde que o id do colaborador exista na seção.
	 */
	useEffect(() => {
		if (session.colaborador.id) {
            const colaboradorService: ColaboradorService = new ColaboradorService();
			colaboradorService.getColaboradorById(session.colaborador.id)
				.then(res => setIsMentor(res.data));
		}
	}, [session.colaborador.id]);

	/**
	 * Verifica se o tamanho da tela é adequado para dispositivos móveis.
	 *
	 * @returns True caso o tamanho da tela seja compatível com dispositivos
	 * 			móveis, False caso contrário.
	 */
	const isMobile = (): boolean => {
		return width < 1024;
	}

	/**
	 * Mapeia a classe CSS acima de acordo com o tamanho da tela.
	 *
	 * @returns A classe CSS própria para dispositivos móveis, caso
	 * 			a tela seja do tamanho para dispositivos móveis, caso
	 * 			contrário retorna a classe CSS própria para desktop.
	 */
	const mapStyles = () => {
		if (isMobile()) {
			return classes.mobile;
		}
		return classes.desktop;
	}

	/**
	 * Lida com o clique o usuário para abrir o menu de opções do perfil.
	 *
	 * @param event O evento que dispara a abertura do menu de opções do perfil.
	 */
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
		setOpenMenu(!openMenu);
	};

	/**
	 * Lida com o fechamento do menu de opções do perfil.
	 */
	const handleClose = (): void => {
		setAnchorEl(null);
		setOpenMenu(false);
	};

	/**
	 * Desloga o usuário e redireciona-o para a tela de login.
	 */
	const logoutAndRedirect = (): void => {
		logout();
		history.push('/');
	};

	/**
	 * Faz a mudança de papel entre os perfis, redirecionando para o caminho adequado.
	 *
	 * @param role O papael do usuário(i.e. Mentorado, Mentor e/ou Administrador).
	 * @param path O caminho para redirecionamento.
	 */
	const closeAndChangeRoleAndRedirect = (role: string, path: string): void => {
		handleClose();
		switchRole(role);
		history.push(path);
	}

	/**
	 * Lida com a abertura do popover para visualização de avaliação de mentorias pendentes.
	 *
	 * @param event O evento que dispara a visualização do popover.
	 */
	const handleOpenPopover = (event) => {
		setPopoverAnchor(event.currentTarget);
		setOpenMenu(!openMenu);
	}

	/**
	 * Lida com o fechamento do popover de visualização de avaliação de mentorias pendentes.
	 */
	const handleClosePopover = () => {
		setPopoverAnchor(null);
		setOpenMenu(false);
	}

	/**
	 * Verifica qual o papel atual do usuário logado na aplicação.
	 *
	 * @param compare O papel do usuário(e.g. ROLE_MENTOR).
	 * @returns True ou False caso o papel do usuário atual seja igual
	 * 			ao papel passado através das props, ou não.
	 */
	const checkActualRole = (compare): boolean => {
		return actualRole === compare;
	}

	/**
	 * Obtém o nome do usuário logado.
	 *
	 * @returns 'Administrador', caso o usuário tenha apenas papel de administrador,
	 * 			ou o nome cadastrado do usuário caso contrário.
	 */
	const getUsername = () => {
		if (userObject.colaborador === '') {
			return 'Administrador'
		}

		return limitString(userObject.colaborador.nome || '', 30);
	}

	/**
	 * Muda a disposição dos menus conforme o tamanho da tela.
	 *
	 * @returns Os menus conforme o tamanho da tela.
	 */
	const switchMenuDevice = () => {
		if (isMobile()) {
			return (
				<>
					<IconButton
						color='primary'
						aria-controls='simple-menu'
						aria-haspopup='true'
						onClick={handleClick}
					>
						{loading
							? <SpinnerSplendis size='2rem' color='secondary' />
							: <Avatar style={mapStyles().avatar} src={userObject.colaborador.foto} />
						}
					</IconButton>
					<IconButton
						color='primary'
						aria-controls='simple-popover'
						onClick={handleOpenPopover}
						style={{ paddingLeft: 0 }}
					>
						<Badge classes={{ dot: styleClasses.badge }} invisible={mentoriasPendentes.length === 0 || mentoriasPendentes.length === undefined} variant='dot'>
							<NotificationsOutlinedIcon className={styleClasses.notification} />
						</Badge>
					</IconButton>
					<Popover
						id='simple-popover'
						open={Boolean(popoverAnchor)}
						anchorEl={popoverAnchor}
						onClose={handleClosePopover}
						marginThreshold={30}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						{mentoriasPendentes.length > 0
							? <div className={styleClasses.popoverText}>
								<Typography className={styleClasses.popoverTitle}>
									{t(PERFIL_MENU_NOTIFICACAO_TITULO, 'Você ainda tem mentorias pendentes de avaliação!')}
								</Typography>
								<Typography className={styleClasses.popoverSubtitle}>
									{t(PERFIL_MENU_NOTIFICACAO_SUBTITULO_P1, 'Avaliar uma mentoria realizada é muito importante, portanto, não deixe para depois, ')}
								</Typography>
								<Typography
									className={styleClasses.popoverLink}
									onClick={() => {
										history.push('/mentoring/mentorias-pendentes-avaliacao');
										switchRole('ROLE_MENTORADO');
									}}
								>
									{t(PERFIL_MENU_NOTIFICACAO_SUBTITULO_P2, 'clique aqui')}
								</Typography>
								<Typography className={styleClasses.popoverSubtitle}>
									{t(PERFIL_MENU_NOTIFICACAO_SUBTITULO_P3, ' e avalie já')}
								</Typography>
								<Typography className={styleClasses.popoverFooter}>
									{t(PERFIL_MENU_NOTIFICACAO_RODAPE, 'enviado em ')}{diaAtualFormatted() + ' - ' + getHour()}
								</Typography>
							</div>
							: <div className={styleClasses.popoverText}>
								<Typography className={styleClasses.popoverNoNotification}>
									{t(PERFIL_MENU_SEM_NOTIFICACAO, 'Não há novas notificações!')}
								</Typography>
							</div>
						}
					</Popover>
					<Backdrop open={openMenu} onClick={handleClose}></Backdrop>
				</>
			);
		} else {
			return (
				<nav className={styleClasses.navlinks}>
					{loading
						? <SpinnerSplendis size='1.7rem' color='primary' style={{ padding: '1.5rem .5rem 0 0' }} />
						: <Avatar className={styleClasses.userPicture} src={userObject.colaborador.foto} />
					}
					<Typography className={styleClasses.username}>
						{t(PERFIL_MENU_SAUDACAO, 'Olá ') + getUsername()}
					</Typography>
					<IconButton
						color='primary'
						aria-controls='simple-menu'
						aria-haspopup='true'
						onClick={handleClick}
						className={styleClasses.expandButton}
					>
						<ExpandMore />
					</IconButton>
					<IconButton
						color='primary'
						aria-controls='simple-popover'
						onClick={handleOpenPopover}
						style={{ paddingLeft: 0 }}
					>
						<Badge
							classes={{ dot: styleClasses.badge }}
							invisible={mentoriasPendentes.length === 0 || mentoriasPendentes.length === undefined}
							variant='dot'
						>
							<NotificationsOutlinedIcon className={styleClasses.notification} />
						</Badge>
					</IconButton>
					<Popover
						id='simple-popover'
						open={Boolean(popoverAnchor)}
						anchorEl={popoverAnchor}
						onClose={handleClosePopover}
						marginThreshold={80}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						{mentoriasPendentes.length > 0
							? <div className={styleClasses.popoverText}>
								<Typography className={styleClasses.popoverTitle}>
									{t(PERFIL_MENU_NOTIFICACAO_TITULO, 'Você ainda tem mentorias pendentes de avaliação!')}
								</Typography>
								<Typography className={styleClasses.popoverSubtitle}>
									{t(PERFIL_MENU_NOTIFICACAO_SUBTITULO_P1, 'Avaliar uma mentoria realizada é muito importante, portanto, não deixe para depois, ')}
								</Typography>
								<Typography
									className={styleClasses.popoverLink}
									onClick={() => {
										history.push('/mentoring/mentorias-pendentes-avaliacao');
										switchRole('ROLE_MENTORADO');
									}}
								>
									{t(PERFIL_MENU_NOTIFICACAO_SUBTITULO_P2, 'clique aqui')}
								</Typography>
								<Typography className={styleClasses.popoverSubtitle}>
									{t(PERFIL_MENU_NOTIFICACAO_SUBTITULO_P3, ' e avalie já')}
								</Typography>
								<Typography className={styleClasses.popoverFooter}>
									{t(PERFIL_MENU_NOTIFICACAO_RODAPE, 'enviado em ')}{diaAtualFormatted() + ' - ' + getHour()}
								</Typography>
							</div>
							: <div className={styleClasses.popoverText}>
								<Typography className={styleClasses.popoverNoNotification}>
									{t(PERFIL_MENU_SEM_NOTIFICACAO, 'Não há novas notificações!')}
								</Typography>
							</div>
						}
					</Popover>
					<Backdrop open={openMenu} onClick={handleClose}></Backdrop>
				</nav>
			);
		}
	}

	/**
	 * Permite a troca de idiomas.
	 *
	 * @param lang O idioma escolhido dentre as opções disponíveis na aplicação.
	 */
	const closeAndChangeLanguage = (lang) => {
		handleClose();
		i18n.changeLanguage(lang);
	}

	/**
	 * Permite escolher qual idioma se deseja visualizar as informações de toda a aplicação.
	 *
	 * @returns Submenu com as opções de idiomas disponíveis.
	 */
	const switchLanguagesMenu = () => {
		return <div className={styleClasses.toggleLanguages}>
			<MenuItem onClick={() => closeAndChangeLanguage('pt')}>PT</MenuItem>
			<MenuItem onClick={() => closeAndChangeLanguage('es')}>ES</MenuItem>
			<MenuItem onClick={() => closeAndChangeLanguage('en')}>EN</MenuItem>
		</div>
	}

	/**
	 * Mapeia e renderiza as opções no menu de acordo com o perfil do usuário logado.
	 *
	 * @param role O papel do usuário logado.
	 * @returns A(s) opção(ões) no menu de acordo com o perfil do usuário logado.
	 */
	const mapRoleItems = (role) => {
		switch (role) {
			case 'ROLE_ADMIN':
				return (
					<MenuItem
						key={role}
						style={mapStyles().menuItem}
						selected={checkActualRole('ROLE_ADMIN')}
						onClick={() => closeAndChangeRoleAndRedirect('ROLE_ADMIN', '/mentoring/home')}>
						{t(PERFIL_MENU_ADMINISTRADOR, 'Administrador')}
					</MenuItem>
				);

			case 'ROLE_MENTOR':
				return (
					<>
						<MenuItem style={mapStyles().menuItem}
							selected={checkActualRole('ROLE_MENTOR')}
							onClick={() => closeAndChangeRoleAndRedirect('ROLE_MENTOR', '/mentoring/home')}>
							{t(PERFIL_MENU_HOME, 'Home')}
						</MenuItem>
					</>
				);

			case 'ROLE_MENTORADO':
				return (
					<>
						{!roles.includes('ROLE_MENTOR') &&
							<MenuItem style={mapStyles().menuItem}
								onClick={() => closeAndChangeRoleAndRedirect('ROLE_MENTORADO', '/mentoring/home')}>
								{t(PERFIL_MENU_HOME, 'Home')}
							</MenuItem>
						}
						{/*{!isMentor &&
							<MenuItem style={mapStyles().menuItem}
									  onClick={() => closeAndChangeRoleAndRedirect('ROLE_MENTORADO', '/mentoring/mentor')}>
								{t(PERFIL_MENU_SEJA_MENTOR, 'Seja um mentor!')}
							</MenuItem>
						}*/}
					</>
				);
		}
	}

	/**
	 * Obtém as opções de papel do usuário.
	 *
	 * @returns Array com as opções de papel do usuário.
	 */
	const menuItemsByRoles = () => {
		return (
			roles.slice(0).reverse().map((role) => {
				return mapRoleItems(role);
			})
		)
	}

	return (
		<div className={styleClasses.muiThemeContainer}>
			<MuiThemeProvider theme={theme}>
				{switchMenuDevice()}
				<Menu
					style={mapStyles().menu}
					elevation={10}
					id='simple-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{loadingMenu
						? <SpinnerSplendis size='1.7rem' color='secondary' style={{ padding: '1.5rem 4rem' }} />
						: <List>
							{switchLanguagesMenu()}
							<Divider />
							{menuItemsByRoles()}
							{!checkActualRole('ROLE_ADMIN') &&
								<MenuItem style={mapStyles().menuItem} onClick={() => history.push('/mentoring/faq')}>
									{t(PERFIL_MENU_FAQ, 'FAQ')}
								</MenuItem>
							}
							<MenuItem style={mapStyles().menuItem} onClick={logoutAndRedirect}>
								{t(PERFIL_MENU_SAIR, 'Sair')}
							</MenuItem>
						</List>
					}
				</Menu>
			</MuiThemeProvider>
		</div>
	);
}
