import React, { useEffect, useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Typography, Snackbar } from "@material-ui/core";

import { MentoriaService, UserContext } from "../../../../service";
import { Mentoria } from "../../../../models";
import { AvaliacaoComponent, MentoriaTable } from "../../../../components";
import { SpinnerSplendis } from "../../../../commons";
import { alertContext } from "../../../../components";

import Alert from "@material-ui/lab/Alert";
import { getWindowDimensions } from "../../../../utils/FormUtils";
import { useTranslation } from "react-i18next";
import CancelarMentoriaModal from "./modals/CancelarMentoriaModal";
import ModalCancelamento from "../../../../commons/modals/ModalCancelamento";
import ReagendarMentoriaModal from "./modals/ReagendarMentoriaModal";
import ModalSucesso from "../../../../commons/modals/ModalSucesso";
import {
    GERENCIAMENTO_MENTORIAS_SOURCE,
    GERENCIAMENTO_MENTORIAS_AVALIACAO_SUBTITULO,
    GERENCIAMENTO_MENTORIAS_AVALIACAO_TITULO,
    GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_SUBTITULO,
    GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_TITULO,
    GERENCIAMENTO_MENTORIAS_CANCELAMENTO_SUCESSO,
    GERENCIAMENTO_MENTORIAS_CANCELAMENTO_ERRO,
    GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_SUCESSO,
    GERENCIAMENTO_MENTORIAS_MENTOR_TITULO,
    GERENCIAMENTO_MENTORIAS_MENTORADO_TITULO,
    GERENCIAMENTO_MENTORIAS_CANCELAMENTO_MODAL_SUBTITULO,
    GERENCIAMENTO_MENTORIAS_CANCELAMENTO_MODAL_TITULO,
    GERENCIAMENTO_MENTORIAS_MENTOR_SUBTITULO,
    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_TITULO,
    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_SUBTITULO,
    GERENCIAMENTO_MENTORIAS_MENTOR_HISTORICO_COMENTARIOS,
    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_AVALIAR,
    GERENCIAMENTO_MENTORIAS_INFO_MESSAGE
} from "../../../../messages";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: "90vh",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
        },

        content: {
            height: "90%",
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
        },

        titleContainer: {
            height: "5%",
            width: "80%",
            textAlign: "left",
        },

        subtitleContainer: {
            display: "flex",
            alignContent: "flex-start",
            height: "25%",
            maxHeight: 100,
            flexDirection: "column",
            textAlign: "left",
            "@media (max-width: 1024px)": {
                fontSize: "0.7rem",
                maxHeight: 200,
                height: "auto",
            },
        },

        title: {
            fontWeight: 600,
            "@media (max-width: 1024px)": {
                fontSize: "1rem",
            },
        },

        subtitle: {
            "@media (max-width: 1024px)": {
                fontSize: "0.8rem",
            },
        },

        reagendar: {
            height: "auto",
            maxWidth: "560",
            "@media (max-width: 1024px)": {
                maxWidth: "100%",
            },
        },

        reagendarInputs: {
            height: 130,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "@media (max-width: 1024px)": {
                alignItems: "initial",
                marginTop: 10,
            },
        },

        reagendarFields: {
            "@media (max-width: 1024px)": {
                padding: "12px 7px",
            },
        },

        // BTNS:
        confirmarReagendamento: {
            "@media (max-width: 1024px)": {
                fontSize: "0.7rem",
                margin: 4,
            },
        },

        // NOVO E ANTIGO:
        reviewHorarios: {
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            "@media (max-width: 1024px)": {
                border: "solid",
                borderWidth: "thin",
                padding: "5px",
                borderRadius: "5%",
                borderColor: "silver",
            },
        },

        // BOTOES DE CANCELAMENTO:
        cancelarBtns: {
            "@media (max-width: 1024px)": {
                margin: 4,
                fontSize: "0.7rem",
                padding: "6px 10px",
            },
        },

        alertContainer: {
            height: 50,
            display: "flex",
            alignItems: "center",
            textAlign: "left",
        },

        actionButtonOuterContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },

        actionButtonInnerContainer: {
            width: "60%",
            maxWidth: 300,
            display: "flex",
            justifyContent: "space-around",
        },

        closeButtonContainer: {
            width: "auto",
            display: "flex",
            justifyContent: "space-around",
        },

        rescheduleAlert: {
            marginBottom: 16,
        },

        forwardArrow: {
            display: "flex",
            alignItems: "center",
        },

        alertInfoMessage: {
            opacity: 0.9,
        },

        mentorRole: {
            height: "100%",
        },

        notMentorRole: {
            height: "50%",
        },

        futureMentoringTable: {
            height: "62%",
        },

        futureMentoringSpinner: {
            alignSelf: "center",
        },

        mentoringHistory: {
            height: "50%",
        },

        mentoringHistoryTable: {
            height: "57%"
        },

        mentoringHistorySpinner: {
            alignSelf: "center",
        },

        spanDiv: {
            textAlign: "end"
        },

        spanText: {
            color: "#AAABAC"
        }
    })
);

/**
 * Componente que faz todo o gerenciamento de mentorias(e.g. agendamento,
 * cancelamento, exibe modal de confirmação, update de histórico de mentorias etc.)
 * 
 * @param props Propriedades e funções passadas pelo o componente que faz a chamada
 *              a este(e.g. papel do usuário, funções para obtenção de mentorias etc.)
 * @returns O componente de gerenciamento de mentorias com todas as suas funções,
 *          tabelas e elementos.
 */
export default function GerenciamentoMentorias(props) {
    const classes = useStyles();

    // A sessão atual do usuário logado
    const { session } = useContext(UserContext);

    // Hook e função utilizados para a tradução de textos. 
    const { t } = useTranslation([GERENCIAMENTO_MENTORIAS_SOURCE]);

    const mentoriaService = new MentoriaService();

    const {
        entityId,
        role,
        getMentorias,
        getHistoricoMentorias,
        showHistorico,
    } = props;

    const { showAlert } = useContext(alertContext);

    const [mentoriasFuturas, setMentoriasFuturas] = React.useState<Mentoria[]>([]);

    // States de Cancelamento
    const [mentoriaACancelar, setMentoriaACancelar] = React.useState<any>();
    const [
        isCancelamentoMentoriaModalOpen,
        setIsCancelamentoMentoriaModalOpen,
    ] = React.useState<boolean>(false);
    const [
        isSucessoCancelamentoModalOpen,
        setSucessoCancelamentoModalOpen,
    ] = React.useState<boolean>(false);

    // States de Reagendamento
    const [mentoriaAReagendar, setMentoriaAReagendar] = React.useState<any>(
        new Mentoria()
    );
    const [
        isReagendarMentoriaModalOpen,
        setIsReagendarMentoriaModalOpen,
    ] = React.useState<boolean>(false);
    const [
        isSucessoReagendarMentoriaModalOpen,
        setIsSucessoReagendarMentoriaModalOpen,
    ] = React.useState<boolean>(false);

    // States de avaliação
    const [openModalAvaliar, setOpenModalAvaliar] = React.useState<boolean>(false);
    const [openModalAvaliacaoSucesso, setOpenModalAvaliacaoSucesso] = React.useState<boolean>(false);
    const [mentoriaParaAvaliar, setMentoriaParaAvaliar] = React.useState<any>();

    const [
        isMentoriasFuturasLoading,
        setMentoriasFuturasLoading,
    ] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [dimensions, setDimensions] = React.useState(getWindowDimensions());

    const [isHistoricoLoading, setIsHistoricoLoading] = React.useState<boolean>(
        false
    );
    const [historico, setHistorico] = React.useState<Mentoria[]>([]);

    /**
     * Obtém as dimensões da tela e configura o state de dimensão assim que o
     * componente é renderizado.
     */
    useEffect(() => {
        function handleResize() {
            setDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    /**
     * Obtém as mentorias e configura o state de mentorias futuras.
     */
    useEffect(() => {
        setShowMessage(true);
        setMentoriasFuturasLoading(true);
        getMentorias(entityId)
            .then((res) => {
                setMentoriasFuturas(res.data);
            })
            .catch(() => {
                setMentoriasFuturas([]);
            })
            .then(() => setMentoriasFuturasLoading(false));
    }, [entityId, getMentorias]);

    /**
     * Atualiza o histórico de mentorias dependendo do valor da
     * prop showHistorico.
     */
    useEffect(() => {
        if (showHistorico) {
            updateHistorico();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, getHistoricoMentorias, showHistorico]);

    /**
     * Verifica se a largura da tela está no tamanho de telas para
     * dispositivos móveis. 
     * 
     * @returns True ou False caso a largura da tela esteja no tamanho
     *          de telas para dispositivos móveis ou não.
     */
    const isMobile = () => {
        return dimensions.width < 1024;
    };

    /**
     * Realiza o cancelamento de uma mentoria.
     * 
     * @param id O id da mentoria a ser cancelada.
     */
    const cancelarMentoria = (id) => {
        mentoriaService
            .cancelar(id)
            .then(() => {
                closeCancelarMentoriaModal();
                updateMentoriasFuturas();
                openSucessoCancelamentoMentoriaModal();
                showAlert(200, t(GERENCIAMENTO_MENTORIAS_CANCELAMENTO_SUCESSO, "Cancelamento bem-sucedido!"));
            })
            .catch(() =>
                showAlert(
                    400,
                    t(GERENCIAMENTO_MENTORIAS_CANCELAMENTO_ERRO, "Não foi possível cancelar a mentoria. Por favor, tente novamente.")
                )
            );
    };

    /**
     * Realiza o reagendamento de uma mentoria.
     * 
     * @param id O id da mentoria a ser reagendada.
     * @param mentoria A mentoria a ser reagendada.
     * @returns Promise com o resultado do reagendamento.
     */
    const reagendarMentoria = (id, mentoria) => {
        return mentoriaService.reagendar(id, mentoria).then((res) => {
            closeReagendarMentoriaModal();
            openSucessoReagendarMentoriaModal();
            updateMentoriasFuturas();
            showAlert(200, t(GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_SUCESSO, "Reagendamento bem-sucedido!"));
            return res;
        });
    };

    /**
     * Atualiza o state de mentorias futuras.
     */
    const updateMentoriasFuturas = () => {
        setMentoriasFuturasLoading(true);
        getMentorias(entityId)
            .then((res) => {
                setMentoriasFuturas(res.data);
            })
            .then(() => setMentoriasFuturasLoading(false));
    };

    /**
     * Atualiza o histórico de mentorias.
     */
    const updateHistorico = () => {
        setIsHistoricoLoading(true);
        getHistoricoMentorias(entityId)
            .then((res) => {
                setHistorico(res.data);
            })
            .catch(() => {
                setHistorico([]);
            })
            .then(() => setIsHistoricoLoading(false));
    }

    /**
     * Configura o título da página de acordo com o papel do usuário.
     * 
     * @param role O papel do usuário.
     * @returns O títiulo de acordo com o papel do usuário.
     */
    const titleByRole = (role) => {
        switch (role) {
            case "ROLE_MENTOR":
                return t(GERENCIAMENTO_MENTORIAS_MENTOR_TITULO, "Suas Mentorias Como Mentor");
            case "ROLE_MENTORADO":
                return t(GERENCIAMENTO_MENTORIAS_MENTORADO_TITULO, "Suas Mentorias Como Mentorado");
        }
    };

    /**
     * Abre o modal de cancelamento de mentoria e configura o state
     * de cancelamento de mentoria.
     * 
     * @param mentoria A mentoria a ser cancelada.
     */
    const openCancelarMentoriaModal = (mentoria) => {
        setMentoriaACancelar(mentoria);
        setIsCancelamentoMentoriaModalOpen(true);
    };

    /**
     * Fecha o modal de cancelamento de mentoria e configura o state
     * de cancelamento de mentoria.
     */
    const closeCancelarMentoriaModal = () => {
        setMentoriaACancelar(null);
        setIsCancelamentoMentoriaModalOpen(false);
    };

    /**
     * Abre o modal de sucesso de cancelamento de mentoria
     * configurando o state do modal.
     */
    const openSucessoCancelamentoMentoriaModal = () => {
        setSucessoCancelamentoModalOpen(true);
    };

    /**
     * Fecha o modal de sucesso de cancelamento de mentoria
     * configurando o state do modal.
     */
    const closeSucessoCancelamentoMentoriaModal = () => {
        setSucessoCancelamentoModalOpen(false);
    };

    /**
     * Modal que permite o cancelamento de mentoria.
     * 
     * @param mentoria A mentoria a ser cancelada.
     * @param role O papel do usuário.
     * @returns O modal de cancelamento de mentoria.
     */
    const cancelarMentoriaModal = (mentoria, role) => {
        if (mentoria) {
            return (
                <CancelarMentoriaModal
                    mentoria={mentoria}
                    isOpen={isCancelamentoMentoriaModalOpen}
                    handleClose={closeCancelarMentoriaModal}
                    cancelarMentoriaCallback={(idMentoria) =>
                        cancelarMentoria(idMentoria)
                    }
                    role={role}
                />
            );
        }
    };

    /**
     * Modal de sucesso de cancelamento de mentoria.
     * 
     * @returns O modal de sucesso de cancelamento de mentoria.
     */
    const sucessoCancelamentoMentoriaModal = () => {
        return (
            <ModalCancelamento
                isOpen={isSucessoCancelamentoModalOpen}
                handleClose={closeSucessoCancelamentoMentoriaModal}
                onConfirm={closeSucessoCancelamentoMentoriaModal}
                title={t(GERENCIAMENTO_MENTORIAS_CANCELAMENTO_MODAL_TITULO, "Mentoria cancelada")}
                subtitle={
                    t(GERENCIAMENTO_MENTORIAS_CANCELAMENTO_MODAL_SUBTITULO, "Foi enviado para você e para o mentor, um email de cancelamento deste evento!")
                }
            />
        );
    };

    /**
     * Abre o modal de reagendamento de mentoria e configura o state
     * de mentoria a reagendar.
     * 
     * @param mentoria A mentoria a ser reagendada.
     */
    const openReagendarMentoriaModal = (mentoria) => {
        setMentoriaAReagendar(mentoria);
        setIsReagendarMentoriaModalOpen(true);
    };

    /**
     * Fecha o modal de reagendamento de mentoria e configura o state
     * de mentoria a reagendar.
     */
    const closeReagendarMentoriaModal = () => {
        setIsReagendarMentoriaModalOpen(false);
    };

    /**
     * Abre o modal de sucesso de reagendamento de mentoria configurando
     * o state de sucesso de reagendamento de mentoria.
     */
    const openSucessoReagendarMentoriaModal = () => {
        setIsSucessoReagendarMentoriaModalOpen(true);
    };

    /**
     * Fecha o modal de sucesso de reagendamento de mentoria configurando
     * o state de sucesso de reagendamento de mentoria.
     */
    const closeSucessoReagendarMentoriaModal = () => {
        setIsSucessoReagendarMentoriaModalOpen(false);
    };

    /**
     * Modal que permite o reagendamento de mentoria.
     * 
     * @param mentoria A mentoria a ser reagendada.
     * @returns O modal de reagendamento de mentoria.
     */
    const reagendarMentoriaModal = (mentoria) => {
        if (mentoria) {
            return (
                <ReagendarMentoriaModal
                    isOpen={isReagendarMentoriaModalOpen}
                    handleClose={closeReagendarMentoriaModal}
                    mentoria={mentoria}
                    role={role}
                    reagendarMentoriaCallback={(id, mentoria) => {
                        reagendarMentoria(id, mentoria);
                    }}
                />
            );
        }
    };

    /**
     * Modal de sucesso de reagendamento de mentoria.
     * 
     * @returns O modal de sucesso de reagendamento de mentoria.
     */
    const sucessoReagendamentoMentoriaModal = () => {
        return (
            <ModalSucesso
                isOpen={isSucessoReagendarMentoriaModalOpen}
                handleClose={closeSucessoReagendarMentoriaModal}
                title={t(GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_TITULO, "Mentoria Reagendada com Sucesso!")}
                subtitle={t(GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_SUBTITULO, "Os envolvidos já foram notificados sobre o reagendamento.")}
            />
        );
    };

    /**
     * Abre o modal de avaliação de mentoria e configura
     * o state de mentoria para avaliar.
     * 
     * @param mentoria A mentoria a ser avaliada.
     */
    const openAvaliacaoModal = (mentoria) => {
        setMentoriaParaAvaliar(mentoria);
        setOpenModalAvaliar(true);
    }

    /**
     * Fecha o modal de avaliação de mentoria e configura
     * o state de mentoria para avaliar.
     */
    const closeAvaliacaoModal = () => {
        setOpenModalAvaliar(false);
    }

    /**
     * Abre o modal de sucesso de avaliação de mentoria
     * configurando o state de sucesso de avaliação.
     */
    const openAvaliacaoSucessoModal = () => {
        setOpenModalAvaliacaoSucesso(true);
    }

    /**
     * Fecha o modal de sucesso de avaliação de mentoria
     * configurando o state de sucesso de avaliação.
     */
    const closeAvaliacaoSucessoModal = () => {
        setOpenModalAvaliacaoSucesso(false);

        //Ao fechar modal de sucesso, atualiza lista de mentorias pendentes
        updateHistorico();
        setOpenModalAvaliar(false);
    }

    /**
     * Gerencia o processo final de avaliação de mentoria, fechando o modal
     * de avaliação e abrindo o modal de sucesso de avaliação.
     */
    const handleAvaliacaoSucesso = () => {
        closeAvaliacaoModal();
        openAvaliacaoSucessoModal();
    }

    /**
     * Modal de sucesso de avaliação de mentoria.
     * 
     * @returns O modal de sucesso de avaliação de mentoria.
     */
    const sucessoAvaliarMentoriaModal = () => {
        return <ModalSucesso
            isOpen={openModalAvaliacaoSucesso}
            handleClose={closeAvaliacaoSucessoModal}
            title={t(GERENCIAMENTO_MENTORIAS_AVALIACAO_TITULO, "Obrigado pela contribuição!")}
            subtitle={t(GERENCIAMENTO_MENTORIAS_AVALIACAO_SUBTITULO, "Sua avaliação foi enviada para o RH.")} />
    }

    /**
     * Modal de avaliação de mentoria.
     * 
     * @param mentoria A mentoria a ser avaliada.
     * @returns O modal de avaliação de mentoria.
     */
    const avaliacaoModal = (mentoria) => {
        if (mentoria) {
            const newMentoriaPendente = { ...mentoria, mentoriaId: mentoria.id, mentoradoId: session.mentorado.id };

            return <AvaliacaoComponent
                isOpen={openModalAvaliar}
                handleClose={closeAvaliacaoModal}
                mentoria={newMentoriaPendente}
                submitted={handleAvaliacaoSucesso} />
        }
    }

    /**
     * Função responsável por renderizar todos os modais deste componente.
     * 
     * @returns Um fragment que renderiza todos os modais deste componente.
     */
    const modais = () => {
        return (
            <React.Fragment>
                {reagendarMentoriaModal(mentoriaAReagendar)}
                {sucessoReagendamentoMentoriaModal()}
                {cancelarMentoriaModal(mentoriaACancelar, role)}
                {sucessoCancelamentoMentoriaModal()}
                {avaliacaoModal(mentoriaParaAvaliar)}
                {sucessoAvaliarMentoriaModal()}
            </React.Fragment>
        );
    };

    /**
     * Gerencia a exibição de mensagens de alerta ao usuário.
     * 
     * @param message A mensagem a ser exibida ao usuário.
     * @returns Um Snackbar com a mensagem ao usuário.
     */
    const handleInfoMessage = (message) => {
        return (
            <Snackbar
                open={showMessage}
                onClose={() => setShowMessage(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    className={classes.alertInfoMessage}
                    onClose={() => setShowMessage(false)}
                    severity="info"
                >
                    {message}
                </Alert>
            </Snackbar>
        );
    };

    return (
        <Container className={classes.root}>
            {modais()}
            <div className={classes.content}>
                <div
                    className={
                        role === "ROLE_MENTOR"
                            ? classes.mentorRole
                            : classes.notMentorRole
                    }
                >
                    <div className={classes.subtitleContainer}>
                        <Typography
                            className={classes.title}
                            variant="h5"
                            align="left"
                        >
                            {titleByRole(role)}
                        </Typography>
                        <Typography
                            className={classes.subtitle}
                            variant="subtitle2"
                            gutterBottom
                        >
                            {t(
                                GERENCIAMENTO_MENTORIAS_MENTOR_SUBTITULO,
                                "Aqui você pode gerenciar suas mentorias. Sua próxima mentoria é a que se encontra destacada"
                            )}
                        </Typography>
                    </div>
                    <div className={classes.futureMentoringTable}>
                        {isMentoriasFuturasLoading ? (
                            <div className={classes.futureMentoringSpinner}>
                                <SpinnerSplendis />
                            </div>
                        ) : (
                            <MentoriaTable
                                asRole={role}
                                mentorias={mentoriasFuturas}
                                cancelable
                                cancelarMentoriaCallback={(mentoria) =>
                                    openCancelarMentoriaModal(mentoria)
                                }
                                reschedulable
                                reagendarMentoriaCallback={
                                    openReagendarMentoriaModal
                                }
                                enableFirstOnFocus
                            />
                        )}
                    </div>
                </div>
                {showHistorico ? (
                    <div className={classes.mentoringHistory}>
                        <div className={classes.subtitleContainer}>
                            <Typography
                                className={classes.title}
                                variant="h5"
                                align="left"
                            >
                                {t(
                                    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_TITULO,
                                    "Seu Histórico de Mentorias"
                                )}
                            </Typography>
                            <Typography
                                className={classes.subtitle}
                                variant="subtitle2"
                                gutterBottom
                            >
                                {t(
                                    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_SUBTITULO,
                                    "Aqui você encontra todas as suas mentorias"
                                )}
                            </Typography>
                        </div>
                        <div className={classes.mentoringHistoryTable}>
                            {isHistoricoLoading ? (
                                <div
                                    className={classes.mentoringHistorySpinner}
                                >
                                    <SpinnerSplendis />
                                </div>
                            ) : (
                                <div>
                                    <MentoriaTable
                                        asRole={role}
                                        mentorias={historico}
                                        avaliarMentoriaCallback={(mentoria) => {
                                            openAvaliacaoModal(mentoria)
                                        }}
                                        cellPadding={isMobile() ? 8 : "auto"}
                                    />
                                    <div className={classes.spanDiv}>
                                        {
                                            role === "ROLE_MENTOR" ?
                                                <span className={classes.spanText}>{t(GERENCIAMENTO_MENTORIAS_MENTOR_HISTORICO_COMENTARIOS, "Clique na mentoria para ver os comentários")}</span>
                                                :
                                                <span className={classes.spanText}>{t(GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_AVALIAR, "Clique na mentoria para avaliar")}</span>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
            {isMobile() &&
                handleInfoMessage(
                    t(GERENCIAMENTO_MENTORIAS_INFO_MESSAGE, "Você pode rolar a tabela horizontalmente e verticalmente para visualizar os dados")
                )}
        </Container>
    );
}
