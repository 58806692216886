import React from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';

import brazil from '../../assets/flags/brasil.png';

const useStyles = makeStyles({
  imageIcon: {
    height: '100%',
    width: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  }
});

/**
 * Componente responsável por renderizar a bandeira do Brasil,
 * a qual simboliza a utilização do idioma Português.
 */
const BrazilFlagIcon = () => {
  const classes = useStyles();

  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img alt="brazil" className={classes.imageIcon} src={brazil} />
    </Icon>
  );
}

export default BrazilFlagIcon;
