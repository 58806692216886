import { AxiosMethodsName, HttpClientService, MentoringConfigRequest } from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados a email.
 */
export default class EmailService extends HttpClientService {
    constructor() {
        super('email');
    }

    /**
     * Envia email para o usuário e a equipe do Mentoring.
     * 
     * @param nome O nome do usuário.
     * @param email O email do contato para o qual será enviado.
     * @param assunto O assunto do email.
     * @param mensagem A mensagem contida no email.
     * @returns Promise com o resultado do processo de envio de email.
     */
    enviarEmailContato(nome: string, email: string, assunto: string, mensagem: string): Promise<any> {
        const data = {nome, subject: assunto, email, mensagem};

        const configRequest: MentoringConfigRequest = {
            url: this.getURL() + "/contato",
            method: AxiosMethodsName.post,
            data: data,
            handleCredentials: false
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Envia email de recomendação ao compartilhar a aplicação Mentoring.
     * 
     * @param nome O nome do usuário.
     * @param email O email do usuário que recomenda.
     * @param emailDestinatario O email do contato para o qual será enviado.
     * @param assunto O assunto do email.
     * @param mensagem A mensagem contida no email.
     * @param mentorEmail O email do mentor.
     * @returns Promise com o resultado do processo.
     */
    enviarEmailRecomendacao(nome: string, email: string, emailDestinatario: string, assunto: string, mensagem: string, mentorEmail: string): Promise<any> {
        const data = {nome, subject: assunto, email, emailDestinatario, mensagem, mentorEmail};

        const configRequest: MentoringConfigRequest = {
            url: this.getURL() + "/recomendacao",
            method: AxiosMethodsName.post,
            data: data,
            handleCredentials: false
        }

        return super.executeMethod(configRequest);
    }
}