export const modalStyle = {
    modal: {
        height: 'auto',
        textAlign: 'center',
        display: 'block',
        width: 'auto',
    },
    imageStyle: {
        '@media (maxWidth: 1024px)': {
            width: '100%', 
            height: '100%',
            zoom: '40%'
        }
    },
    cropStyle: {
        '@media (maxWidth: 1024px)': {
            zoom: '10%'
        }
    }
}

