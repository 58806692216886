import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    alertMinimal: {
      opacity: '0.9',
    }
  })
);

/**
 * Interface utilizada pelo componente AlertComponent e que descreve
 * seu métodos e propriedades.
 */
interface AlertComponentProps {
  data: {
    httpStatus: number
    mensagem: string
  }
}

/**
 * Interface utilizada pelo componente AlertComponent com propriedades
 * para customização da mensagem de alerta.
 */
interface AlertMinimalProps {
  severity: any
  children: React.ReactNode
}

/**
 * Componentes responsáveis pela apresentação de mensagens de forma personalizada.
 * e.g: Mensagem de sucesso ao realizar o reagendamento de uma mentoria.
 * 
 * @param {AlertMinimalProps} 
 * @returns O compoente de mensagens.
 */
const AlertComponent: React.FC<AlertComponentProps> = ({ data: { httpStatus, mensagem } }) => {

  /**
   * Determina o tipo de mensagem que será exibida.
   * 
   * @returns Sucesso caso o status HTTP seja menor que 300, erro caso contrário.
   */
  const severity = () => {
    if (httpStatus < 300) {
      return "success";
    }
    return "error";
  }

  return (
    <Alert severity={severity()}>
      {mensagem ? mensagem : "Erro inesperado"}
    </Alert>
  );
}

const AlertMinimal: React.FC<AlertMinimalProps> = ({ severity, children }) => {
  const classes = useStyles();

  return (
    <Alert
      className={classes.alertMinimal}
      severity={severity}
    >
      { children}
    </Alert>);
}

export { AlertComponent, AlertMinimal };