import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Typography } from "@material-ui/core";
import { EventAvailable } from "@material-ui/icons";

import { mapWeekDays } from "../../../../../../../../../../utils/FormUtils";
import { SpinnerSplendis } from "../../../../../../../../../../commons";
import DataButton from "../data-button/DataButton";
import {
  AGENDAMENTO_MENTORIA_SOURCE,
  AGENDAMENTO_MENTORIA_AS,
  NEW_MESSAGES_SOURCE,
//   INPUT_INTERVALO,
//   INPUT_SEMANA,
//   INPUT_2_SEMANAS,
//   INPUT_3_SEMANAS,
//   INPUT_4_SEMANAS,
  DISPONIBILIDADE_SEMANAL,
  DISPONIBILIDADE_QUINZENAL,
  DISPONIBILIDADE_3SEMANAS,
  DISPONIBILIDADE_MENSAL,
} from "../../../../../../../../../../messages";

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(207, 212, 218, 0.32)",
    marginTop: "2vh",
    width: "100%",
    maxWidth: "100%",
    //display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "65vh",
    overflowY: "auto",

    "@media only screen and (max-width: 414px)": {
      display: "block",
      height: "60vh",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      display: "block",
      height: "80vh",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "2vh",

    "@media only screen and (max-width: 414px)": {
      width: "100%",
      alignItems: "center",
      overflowX: "clip",
      marginBottom: "2vh",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      width: "100%",
      alignItems: "center",
      overflowX: "clip",
      marginBottom: "2vh",
    },
  },
  horariosContainer: {
    marginLeft: "-3%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 25%)",
    justifyContent: "center",
    width: "90%",

    "@media only screen and (max-width: 414px)": {
      display: "block",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      display: "block",
    },
  },
  horarioItem: {
    "@media only screen and (max-width: 414px)": {
      marginRight: "4px",
    },
  },
  expoButton: {
    padding: "0 6px 0 6px",
  },
  horariosHeader: {
    fontFamily: "InfraRegular",
    fontStyle: "normal",
    width: "90%",
    backgroundColor: "#FF6C00",
    color: "#fff",
    height: "5rem",
    fontSize: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    textAlign: "start",
    marginBottom: ".625rem",
    borderRadius: "5px",

    "@media only screen and (max-width: 414px)": {
      fontSize: ".8rem",
      marginTop: "1rem",
      paddingLeft: ".8rem",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      fontSize: "1rem",
    },
  },
  horariosIcon: {
    fontSize: "2rem",
    backgroundColor: "#fff",
    color: '#636466',
    borderRadius: "50%",
    width: "2.5rem",
    height: "2.5rem",

    padding: "2px",

    "@media only screen and (max-width: 414px)": {
      width: "1.7rem",
      height: "1.7rem",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      width: "2rem",
      height: "2rem",
    },
  },
  diaSemana: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "90%",

  },
});

/**
 * Interface Disponibilidade com os atributos diaSemana e horario.
 */
export interface Disponibilidade {
  diaSemana: string;
  horario: string;
  intervaloSemanas: number;
}

/**
 * Verifica se uma determinada disponibilidade(i.e. dia e horário) está presente
 * no array de todas as disponibilidades.
 *
 * @param d1 A disponibilidade a ser verificada.
 * @param disponibilidades Array com todas as disponibilidades.
 * @returns True caso a disponibilidade esteja no array de disponibilidades, False caso contrário.
 */
const containsDisponibilidade = (
  d1: Disponibilidade,
  disponibilidades: Disponibilidade[]
) => {
  let isEqual = (disp) => {
    return disp.diaSemana === d1.diaSemana && disp.horario === d1.horario;
  };

  for (let i = 0; i < disponibilidades.length; i++) {
    if (isEqual(disponibilidades[i])) {
      return true;
    }
  }

  return false;
};

/**
 * Componente que renderiza um card com o horários disponíveis e também
 * os horários que já foram escolhidos.
 *
 * @param props Propriedades e métodos necessários para o funcionamento
 *              adequado do componente.
 * @returns Lista de horários disponíveis e também horários que já foram
 * 			escolhidos.
 */
export default function HorariosCard(props) {
  const classes = useStyles();

  /**
   * Hook e função utilizados para a tradução de textos.
   */
  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);
  const { t: t2 } = useTranslation([NEW_MESSAGES_SOURCE]);

  const { horarios, isLoading, defaultValue } = props;
 
  const [disponibilidades, setDisponibilidades] = React.useState<
    Disponibilidade[]
  >([]);

  /**
   * Configura o state de disponiblidades sempre que o array de horários muda.
   */
  useEffect(() => {
    let dias = horarios.map((horario) => {
      return {
        diaSemana: horario.dia,
        horario: horario.hora,
        intervaloSemanas: horario.intervaloSemanas,
      };
    });

    let diasArray: Disponibilidade[] = [];

    dias.forEach((dia) =>
      containsDisponibilidade(dia, diasArray) ? null : diasArray.push(dia)
    );

    setDisponibilidades(diasArray);
  }, [horarios]);

  /**
   * Gerencia o processo de seleção de um horário da lista.
   *
   * @param e O evento que dispara a mudança no state de horários.
   * @param data Os dados do horário.
   */
  const handleChange = (e, data) => {
    props.handleChange(e, data);
  };
  const recorrencia = {
    1: "Disponibilidade Semanal",
    2: "Disponibilidade Quinzenal - a cada 2 semanas",
    3: "Disponibilidade - a cada 3 semanas",
    4: "Disponibilidade Mensal - a cada 4 semanas",
  };
  const tradu = {
    1: DISPONIBILIDADE_SEMANAL,
    2: DISPONIBILIDADE_QUINZENAL,
    3: DISPONIBILIDADE_3SEMANAS,
    4: DISPONIBILIDADE_MENSAL,
  };
  /**
   * Renderiza a lista de horários.
   */
  const disps = disponibilidades.map((disp) => {
    
    return (
      <div key={disp.diaSemana} className={classes.wrapper}>
        <Typography className={classes.horariosHeader}>
          <EventAvailable className={classes.horariosIcon} />
          <span className={classes.diaSemana}>
            {t(
              `agendamento-mentoria:step_horarios.dias_da_semana.${disp.diaSemana}`,
              mapWeekDays(disp.diaSemana, t)
            )}{" "}
            {t(AGENDAMENTO_MENTORIA_AS, "às")} {disp.horario.substring(0, 5)}
            <span style={{ fontSize: "0.9rem" }}>
              {t2(
                tradu[disp.intervaloSemanas],
                recorrencia[disp.intervaloSemanas]
              )}
            </span>
          </span>
        </Typography>

        <div className={classes.horariosContainer}>
          {horarios
            .filter(
              (horario) =>
                horario.dia === disp.diaSemana && horario.hora === disp.horario
            )
            .map((horario) => {
              return (
                <div className={classes.horarioItem} key={horario.data}>
                  <DataButton
                    disponibilidade={horario}
                    selected={horario.data === defaultValue}
                    onClick={handleChange}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  });

  return (
    <div className={classes.root}>
      {isLoading ? <SpinnerSplendis /> : disps}
    </div>
  );
}
