import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";

import { SpinnerSplendis } from "../../../../../../commons";

import { useTranslation } from "react-i18next";
import { Mentor } from "../../../../../../models";
import { searchForMentorById } from "../../../../../../utils/FormUtils";
import {
  AGENDAMENTO_MENTORIA_SOURCE,
  AGENDAMENTO_MENTORIA_STEP_MENTORES_SEM_MENTORES,
} from "../../../../../../messages";
import MentorCard from "../../../../../../components/mentor-card/MentorCard";

const useStyle = makeStyles({
  root: {
    width: "70%",
    backgroundColor: "transparent",
    display: "flex",
    alignSelf: "center",
    margin: 0,
  },
  tile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mentoresContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    overflowX: "hidden",
    overflowY: "auto",

    "@media only screen and (max-width: 414px)": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    "@media only screen and (max-width: 455px)": {
      fontSize: "0.8rem",
    },
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    backgroundColor: "transparent",
  },
  contentWrapper: {
    "@media only screen and (max-width: 455px)": {
      maxWidth: "90vw",
    },
    "@media only screen and (min-width: 456px) and (max-width: 1024px)": {
      maxWidth: "90vw",
    },
  },
});

/**
 * Componente que renderiza uma lista de mentores.
 *
 * @param props Propriedades que ajudam no processo de obtenção da lista de mentores.
 * @returns Uma lista de mentores.
 */
export default function MentorList(props) {
  const classes = useStyle();
  const { searchQuery } = props;

  /**
   * Hook utlizado para tradução de textos.
   */
  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

  const { defaultValue, isDisabled, getMentores, itensPorPagina, idioma } =
    props;

  const [mentores, setMentores] = useState<Array<Mentor>>([]);
  const [mentorSelected, setMentorSelected] = useState(defaultValue);
  const [loading, setLoading] = useState<boolean>(false);

  let MENTORES_POR_PAGINA = itensPorPagina ? itensPorPagina : 9;
  const [mentorPage, setMentorPage] = useState<number>(0);
  const [gotAllMentores, setGotAllMentores] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [userType, setUserType] = useState<any>({
    typing: false,
    typingTimeout: 0,
  });

  /**
   * Desabilita ou não a lista dependendo se algum mentor foi selecionado ou não.
   */
  useEffect(() => {
    isDisabled(isNotValid());
  });

  /**
   * Configura vários states ao renderizar o componente.
   */
  useEffect(() => {
    setGotAllMentores(false);
    setLoading(true);

    if (userType.typingTimeout) {
      clearTimeout(userType.typingTimeout);
    }
    setUserType({
      ...userType,
      typing: false,
      typingTimeout: setTimeout(function () {
        if (idioma) {
          getMentoresPaginated(
            0,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            (MENTORES_POR_PAGINA = 1000000),
            setLoading,
            searchQuery
          );
          setGotAllMentores(true);
        } else {
          getMentoresPaginated(0, MENTORES_POR_PAGINA, setLoading, searchQuery);
        }
      }, 1000),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  /**
   * Obtém os mentores de forma paginada.
   *
   * @param page A página atual a ser renderizada.
   * @param mentoresPerPage O número de mentores por página.
   * @param loadingCallback Função de callback.
   * @param rest Array com parâmetros para obtenção dos mentores.
   */
  const getMentoresPaginated = (
    page,
    mentoresPerPage,
    loadingCallback,
    ...rest
  ) => {
    loadingCallback(true);
    getMentores(page, mentoresPerPage, ...rest)
      .then((res) => {
        let newMentores = res.data;

        if (newMentores.length === 0) {
          setGotAllMentores(true);
        }

        if (page === 0 && mentores.length > 0) {
          setMentores([...newMentores]);
        } else {
          setMentores([...mentores, ...newMentores]);
        }

        if (idioma && searchQuery) {
          let searchedMentorsArray: Mentor[] = [];

          newMentores.forEach((mentor) => {
            if (mentor.nome.includes(searchQuery)) {
              searchedMentorsArray.push(mentor);
            }
          });

          setMentores([...searchedMentorsArray]);
        }

        setMentorPage(page + 1);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
      })
      .then(() => {
        loadingCallback(false);
      });
  };

  /**
   * Gerencia o processo de obter mais mentores até atingir o final da lista.
   */
  const handleReachBottom = () => {
    getMentoresPaginated(
      mentorPage,
      MENTORES_POR_PAGINA,
      setLoadingMore,
      searchQuery
    );
  };

  /**
   * Verifica se algum mentor foi selecionado.
   *
   * @returns True caso nenhum mentor tenha sido selecionado, False caso contrário.
   */
  const isNotValid = () => {
    return String(mentorSelected) === "0";
  };

  /**
   * Gerencia o processo de seleção de um mentor da lista.
   *
   * @param e O evento que dispara a mudança no state
   *          de mentor selecionado.
   * @param id O id do mentor selecionado.
   */
  const handleChange = (e, id) => {
    let mentor = searchForMentorById(mentores, id);

    props.handleChange(e, id, mentor);
    setMentorSelected(id);
    props.isDisabled(isNotValid);
  };

  /**
   * Verifica se a lista chegou ao final.
   *
   * @param e O evento que dispara a checagem se a lista chegou ao final.
   * @returns True caso a lista tenha chegado ao final, False caso contrário.
   */
  const touchedBottom = (e) => {
    return (
      e.target.scrollHeight <=
      e.target.offsetHeight + Math.ceil(e.target.scrollTop)
    );
  };

  /**
   * Gerencia o processo de scroll da lista de mentores.
   *
   * @param e O evento que dispara a verificação se a lista chegou ao final.
   */
  const handleScroll = (e) => {
    if (touchedBottom(e) && !gotAllMentores) {
      handleReachBottom();
    }
  };

  /**
   * Gera e obtém a lista de mentores disponíveis.
   */
  const mentoresList =
    mentores.length === 0 ? (
      <Typography variant="h6" component="h6" style={{ alignSelf: "center" }}>
        {t(
          AGENDAMENTO_MENTORIA_STEP_MENTORES_SEM_MENTORES,
          "Não há mentores disponíveis"
        )}
      </Typography>
    ) : (
      <div className={classes.mentoresContainer} onScroll={handleScroll}>
        {mentores.map((mentor) => {
          let selected = false;
          if (mentor.id === mentorSelected) {
            selected = true;
          }
          return (
            <div key={mentor.id} className={classes.title}>
              <MentorCard
                key={mentor.id}
                mentor={mentor}
                showButton={true}
                handleButton={handleChange}
                selected={selected}
              />
            </div>
          );
        })}
        {loadingMore ? (
          <div style={{ gridColumn: "1 / -1" }}>
            <SpinnerSplendis />
          </div>
        ) : null}
      </div>
    );

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.content}>
        {loading ? (
          <SpinnerSplendis />
        ) : (
          <React.Fragment>{mentoresList}</React.Fragment>
        )}
      </div>
    </div>
  );
}
