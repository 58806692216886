import { Route, Redirect } from 'react-router-dom';
import React from 'react';

/**
 * Cria rota privada acessível ao usuário logado.
 * 
 * @param {*} {Component, authenticatedFunction} 
 * @returns Rota privada acessível ao usuário logado.
 */
export const PrivateRoute = ({ component: Component, authenticatedFunction, ...props }) => {
    // @ts-ignore
    return (
      <Route
        {...props}
        render={innerProps =>
          authenticatedFunction.apply(this) === true?
              <Component {...props} />
               :<Redirect to="/login" />
        }
      />
    );
  };

  export default PrivateRoute;