import React from "react";
import {
    Box,
    Button,
    createStyles,
    FormLabel,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { SpinnerSplendis } from "../../commons";
import { UserContext } from "../../service";
import { Formik } from "formik";
import EmailService from "../../service/EmailService";
import { alertContext } from "../exception-handler/ExceptionHandler";
import {
    FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO,
    FORM_CONTATO_NOME,
    FORM_CONTATO_EMAIL_ENVIADO,
    FORM_CONTATO_MENSAGEM,
    FORM_CONTATO_ASSUNTO,
    FORM_CONTATO_SOURCE,
    FORM_CONTATO_RECOMENDACAO,
    FORM_CONTATO_TEXTO_MENSAGEM,
    FORM_CONTATO_VALIDATIONS_EMAIL_INVALIDO,
    FORM_CONTATO_EMAIL_NAO_ENVIADO,
    FORM_CONTATO_SEU_EMAIL,
    FORM_CONTATO_EMAIL_DESTINATARIO,
    FORM_CONTATO_ENVIAR
} from "../../messages";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "auto",
            width: "20rem",

            "@media only screen and (max-width: 455px)": {
                width: "80vw",
            },
        },
        title: {
            textAlign: "center",
        },
        form: {
            marginBottom: "0.6rem",
            "& > legend": {
                marginTop: "1rem",
                marginBottom: "0.5rem",
            },
            "& > textarea": {
                width: "96%",
                resize: "none",
                padding: "0.5rem 0.3rem",
                fontFamily: "inherit",
            },
        },
        button: {
            width: "100%",
        },
        spinner: {
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
        },
        errorField: {
            borderColor: "#F55043",
        },
        errorText: {
            color: "#F55043",
            fontSize: "0.8rem",
        },
    })
);

/**
 * Interface utilizada pelo componente ShareEmailForm e que descreve
 * seu métodos e propriedades.
 */
interface ShareEmailFormProps {
    mentor: any
    closeModal: Function
}

/**
 * Componente responsável pelo compartilhamento da plataforma Mentoring por e-mail.
 * 
 * @param {ShareEmailFormProps} 
 * @returns O componente de compartilhamento do Mentoring via email.
 */
const ShareEmailForm: React.FC<ShareEmailFormProps> = ({ mentor, closeModal }) => {
    const classes = useStyle();

    // Hook utilizado para tradução dos textos no componente.
    const { t } = useTranslation([FORM_CONTATO_SOURCE]);
    
    const { userObject } = React.useContext(UserContext);
    const { showAlert } = React.useContext(alertContext);

    const emailService = new EmailService();

    const initialState = {
        nome: userObject.colaborador.nome || "",
        email: userObject.colaborador.email || "",
        emailDestinatario: "",
        assunto: t(FORM_CONTATO_RECOMENDACAO, "Recomendação"),
        mensagem:
            t(FORM_CONTATO_TEXTO_MENSAGEM, "Ei, Stefaniner! Não fique de fora dessa. Cadastra-se no Mentoring, agende uma sessão e viva também essa experiência."),
    };

    const [loading, setLoading] = React.useState(false);

    /**
     * Função que verfica e valida os campos do formulário,
     * para o envio do e-mail.
     * 
     * @param values Os diferentes valores obtidos através dos campos do formulário de email.
     * @returns Objeto com os diferentes tipos de erros, cada um associado ao seu respectivo
     *          campo no formulário de email.
     */
    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors["email"] = t(
                FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO,
                "Campo Obrigatório"
            );
        } else if (!regex.test(values.email)) {
            errors["email"] = t(FORM_CONTATO_VALIDATIONS_EMAIL_INVALIDO, "Email Inválido");
        }

        if (!values.emailDestinatario) {
            errors["emailDestinatario"] = t(
                FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO,
                "Campo Obrigatório"
            );
        } else if (!regex.test(values.emailDestinatario)) {
            errors["emailDestinatario"] = t(FORM_CONTATO_VALIDATIONS_EMAIL_INVALIDO, "Email Inválido");
        }

        if (!values.nome) {
            errors["nome"] = t(
                FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO,
                "Campo Obrigatório"
            );
        }

        if (!values.assunto) {
            errors["assunto"] = t(
                FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO,
                "Campo Obrigatório"
            );
        }

        if (!values.mensagem) {
            errors["mensagem"] = t(
                FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO,
                "Campo Obrigatório"
            );
        }

        return errors;
    };

    /**
     * Lida com o envio das informações para o backend, para que seja realizado o envio do e-mail.
     * 
     * @param values Os diversos valores obtidos dos campos do formulário de email. 
     */
    const submitForm = (values) => {
        setLoading(true);

        const mentorEmail = mentor ? mentor.email : userObject.colaborador.email;
        
        emailService
            .enviarEmailRecomendacao(
                values.nome,
                values.email,
                values.emailDestinatario,
                values.assunto,
                values.mensagem,
                mentorEmail
            )
            .then((res) => {
                setLoading(false);
                showAlert(
                    202,
                    t(FORM_CONTATO_EMAIL_ENVIADO, "Email enviado com sucesso.")
                );
                closeModal();
            })
            .catch(() => {
                setLoading(false);
                showAlert(
                    400,
                    t(FORM_CONTATO_EMAIL_NAO_ENVIADO, "Não foi possível enviar email. Por favor, tente novamente.")
                )
            });
    };

    return (
        <Formik
            initialValues={initialState}
            validate={validate}
            onSubmit={submitForm}
        >
            {(formik) => {
                const {
                    // values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                    isValid,
                    dirty,
                } = formik;
                return (
                    <Box
                        component="fieldset"
                        mb={0}
                        className={classes.root}
                        borderColor="transparent"
                    >
                        <Typography className={classes.title} variant="h6">
                            {t(FORM_CONTATO_RECOMENDACAO, "Recomendação")}
                        </Typography>

                        <form
                            id="contatoForm"
                            className={classes.form}
                            onSubmit={handleSubmit}
                        >
                            <FormLabel component="legend">
                                {t(FORM_CONTATO_NOME, "Nome:")}
                            </FormLabel>
                            <textarea
                                defaultValue={userObject.colaborador.nome}
                                name="nome"
                                id="nome"
                                onChange={(e) => handleChange(e)}
                                onBlur={handleBlur}
                                rows={1}
                                required
                                form="contatoForm"
                                className={
                                    errors.nome && touched.nome
                                        ? classes.errorField
                                        : undefined
                                }
                            />
                            {errors.nome && touched.nome && (
                                <span className={classes.errorText}>
                                    {errors.nome}
                                </span>
                            )}
                            <br />

                            <FormLabel component="legend">{t(FORM_CONTATO_SEU_EMAIL, 'Seu Email:')}</FormLabel>
                            <textarea
                                defaultValue={userObject.colaborador.email}
                                name="email"
                                id="email"
                                onChange={(e) => handleChange(e)}
                                onBlur={handleBlur}
                                rows={1}
                                required
                                form="contatoForm"
                                className={
                                    errors.email && touched.email
                                        ? classes.errorField
                                        : undefined
                                }
                            />
                            {errors.email && touched.email && (
                                <span className={classes.errorText}>
                                    {errors.email}
                                </span>
                            )}
                            <br />

                            <FormLabel component="legend">{t(FORM_CONTATO_EMAIL_DESTINATARIO, 'Email Destinatário:')}</FormLabel>
                            <textarea
                                defaultValue=""
                                name="emailDestinatario"
                                id="emailDestinatario"
                                onChange={(e) => handleChange(e)}
                                onBlur={handleBlur}
                                rows={1}
                                required
                                form="contatoForm"
                                className={
                                    errors.emailDestinatario && touched.emailDestinatario
                                        ? classes.errorField
                                        : undefined
                                }
                            />
                            {errors.emailDestinatario && touched.emailDestinatario && (
                                <span className={classes.errorText}>
                                    {errors.emailDestinatario}
                                </span>
                            )}
                            <br />

                            <FormLabel component="legend">
                                {t(FORM_CONTATO_ASSUNTO, "Assunto:")}
                            </FormLabel>
                            <textarea
                                defaultValue={initialState.assunto}
                                name="assunto"
                                id="assunto"
                                onChange={(e) => handleChange(e)}
                                onBlur={handleBlur}
                                rows={1}
                                required
                                form="contatoForm"
                                className={
                                    errors.assunto && touched.assunto
                                        ? classes.errorField
                                        : undefined
                                }
                            />
                            {errors.assunto && touched.assunto && (
                                <span className={classes.errorText}>
                                    {errors.assunto}
                                </span>
                            )}
                            <br />

                            <FormLabel component="legend">
                                {t(FORM_CONTATO_MENSAGEM, "Mensagem:")}
                            </FormLabel>
                            <textarea
                                defaultValue={initialState.mensagem}
                                name="mensagem"
                                id="mensagem"
                                onChange={(e) => handleChange(e)}
                                onBlur={handleBlur}
                                rows={5}
                                required
                                form="contatoForm"
                                className={
                                    errors.mensagem && touched.mensagem
                                        ? classes.errorField
                                        : undefined
                                }
                            />
                            {errors.mensagem && touched.mensagem && (
                                <span className={classes.errorText}>
                                    {errors.mensagem}
                                </span>
                            )}
                            <br />
                            <br />
                            {loading ? (
                                <div className={classes.spinner}>
                                    <SpinnerSplendis />
                                </div>
                            ) : (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    disabled={!(dirty && isValid)}
                                >
                                    {t(FORM_CONTATO_ENVIAR, 'Enviar')}
                                </Button>
                            )}
                        </form>
                    </Box>
                );
            }}
        </Formik>
    );
};

export default ShareEmailForm;
