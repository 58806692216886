/**
 * Service com métodos que auxiliam nos processos relacionados a local storage.
 */
export default class LocalStorageService {

    /**
     * Configura armazenamento local.
     * 
     * @param key A chave do item a ser criado.
     * @param value O valor a ser atribuído a chave.
     * @returns O objeto com os valores armazenados localmente.
     */
    static setStorage(key: string, value: string) {
        return localStorage.setItem(key, value);
    }

    /**
     * Obtém um objeto armazenado localmente.
     * 
     * @param key A chave para recuperar o objeto.
     * @returns O objeto com os valores armazenados localmente.
     */
    static getStorage(key: string) {
        return localStorage.getItem(key);
    }

    /**
     * Limpa todas as chaves do armazenamento local.
     */
    static clearStorage() {
        localStorage.clear()
    }

    /**
     * Remove um determinado objeto do armazenamento local.
     * 
     * @param key A chave do objeto a ser removido.
     */
    static removeStorage(key: string) {
        localStorage.removeItem(key);
    }
}