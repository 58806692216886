import React from "react";
import {
  Container,
  Button,
  withStyles,
  createStyles,
  Theme,
  TextField,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MaterialTable from "@material-table/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";

import RhMentorDashBoardProps from "./RhMentorDashBoardProps";
import { Idioma, Mentor } from "../../../../../models";
import {
  alertContext,
  AtualizacaoMentorModal,
  MenuRh,
} from "../../../../../components";
import SpinnerSplendis from "../../../../../commons/spinner-splendis/SpinnerSplendis";
import { withTranslation } from "react-i18next";
import {
  RH_MENTORES_DESCRICAO,
  RH_MENTORES_NOME,
  RH_MENTORES_SOURCE,
  RH_MENTORES_SEM_MENTORES,
  RH_MENTORES_AGUARDANDO_APROVACAO,
  RH_MENTORES_APROVADO,
  RH_MENTORES_INATIVO,
  RH_MENTORES_RELATORIO_GERAL,
  RH_MENTORES_RELATORIO_MENTORES,
  RH_GERENCIAR_MENTORES,
  RH_MENTORES_SEM_NOME,
  RH_MENTORES_EMAIL,
  RH_MENTORES_BUSCAR,
  RH_MENTORES_EDITAR,
  RH_MENTORES_RECUSADO,
  RH_MENTORES_AUSENTE,
  RH_MENTORES_DE,
} from "../../../../../messages";
import {
  allStatusMentor,
  mapStatusMentor,
} from "../../../../../utils/FormUtils";

const pageStyle = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2.5rem",
      marginBottom: "2.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      padding: 0,

      "@media only screen and (max-width: 414px)": {
        width: "90vw",
      },
    },
    titleDiv: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    title: {
      fontFamily: "infraRegular",
      lineHeight: "150%",
      fontSize: "1.625rem",
    },
    button: {
      width: "4.813rem",
      height: "2.75rem",
    },
    link: {
      textDecoration: "none",
    },
    downloadDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1rem",
    },
    search: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      paddingTop: "1rem",
      marginBottom: "1rem",

      "@media only screen and (max-width: 720px)": {
        display: "block",
      },
    },
    searchField: {
      marginRight: "1rem",
      backgroundColor: "#FFFFFF",

      "@media only screen and (max-width: 414px)": {
        margin: ".5vh 0",
        width: "90vw",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        margin: ".5vh 0",
        width: "85vw",
      },
    },
    downloadButton: {
      margin: 4,
    },
    status: {
      marginLeft: theme.spacing(1),
    },
    dashboardTitle: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      fontWeight: 600,

      "@media only screen and (max-width: 414px)": {
        fontSize: "1rem",
      },
    },
    searchFieldLabel: {
      "@media only screen and (max-width: 720px)": {
        fontSize: ".8rem",
      },
    },
    searchButton: {
      color: "white",
      backgroundColor: "#003B4A",

      '&:hover': {
        backgroundColor: '#0a3039',
      },

      "@media only screen and (max-width: 414px)": {
        marginTop: ".9vh",
        width: "90vw",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        marginBottom: "5vh",
        marginTop: ".9vh",
        width: "85vw",
      },
    },
    generalReportButton: {
      color: "#fff",
      backgroundColor: '#0a3039',
        '&:hover': {
          backgroundColor: '#0a3039',
        },
    }
  });

/**
 * Estilização utilizada na tabela de mentores.
 */
const tableStyle = {
  container: {
    marginTop: "1rem",
    marginBottom: "50px",
    minHeight: 400,
    fontFamily: "infraRegular",
    height: "auto",
    backgroundColor: "#F0F1F3",
    paddingBottom: "1rem",
  },
  image: {
    width: "10vh",
    height: "10vh",
    borderRadius: "100%",
  },
  headerStyle: {
    backgroundColor: "#CFD4DA",
    fontFamily: "infraMedium",
  },
  title: {
    fontFamily: "infraMedium",
  },
  actionButton: {
    color: "#003B4A",
  },
  table: {
    backgroundColor: "#CFD4DA",
  },
  searchField: {
    backgroundColor: "#F0F1F3",
  },
  filter: {
    backgroundColor: "#F0F1F3",
    marginLeft: "1rem",
    paddingLeft: "1rem",
    paddingTop: "0.6rem",
  },
};

/**
 * Estilização utilizada de forma geral no container da tabela de mentores.
 */
const GlobalCss = withStyles((theme) => ({
  "@global": {
    ".MuiTableRow-root": {
      "&:nth-of-type(even)": {
        backgroundColor: "#FFFFFF",
      },
      "&:nth-of-type(odd)": {
        backgroundColor: "#F8F8F8",
      },
    },
  },
}))(() => null);

/**
 * Interface utilizada na implementação das queries com parâmetros
 * relativos a aspectos da busca, como número de paǵinas e o tamanho
 * de cada página.
 */
interface IQuery {
  page: number;
  pageSize: number;
  search?: ISearch;
}

/**
 * Inteface utilizada na implementação de buscas de informações relativas
 * ao preenchimento do conteúdo da tabela, como nome, email e status do mentor.
 */
interface ISearch {
  nome: string;
  email: string;
  statusMentor: string;
}

/**
 * Interface utilizada pelo componente abaixo e que descreve
 * seu métodos e propriedades.
 */
export interface MentorDashBoardState {
  nome?: string;
  descricao?: string;
  telefone?: string;
  email?: string;
  idiomas?: Array<Idioma>;
  ano_Admissao?: string;
  statusMentor?: string;
  dataTable: Array<Mentor>;
  editModalIsOpen: boolean;
  mentorBeingEditted: any; // Mentor
  foto?: string;
  loading: boolean;
  query: IQuery;
  relatorioGeralDownloading: boolean;
  relatorioMentoresDownloading: boolean;
  search: ISearch;
}

/**
 * Componente para renderizar a tabela de mentores e editar os mesmos
 *
 * Apenas acessível para o papel de ADMIN.
 */
class RhMentorDashBoardComponent extends React.Component<
  RhMentorDashBoardProps,
  MentorDashBoardState
> {
  referencia = React.createRef<any>();

  constructor(props: any) {
    super(props);

    this.state = {
      dataTable: [],
      editModalIsOpen: false,
      mentorBeingEditted: new Mentor(),
      loading: true,
      relatorioGeralDownloading: false,
      relatorioMentoresDownloading: false,
      query: {
        page: 0,
        pageSize: 5,
      },
      search: {
        nome: "",
        email: "",
        statusMentor: "",
      },
    };
  }

  /**
   * Apresenta modal para edição do Mentor.
   *
   * @param mentor O mentor(suas informações) a ser editado.
   */
  openEditModal(mentor) {
    this.setState({ mentorBeingEditted: mentor, editModalIsOpen: true });
  }

  /**
   * Fecha modal para edição da Categoria.
   */
  closeEditModal() {
    this.setState({ editModalIsOpen: false, mentorBeingEditted: new Mentor() });
  }

  /**
   * Realiza a atualização do mentor passando os novos valores para o backend.
   *
   * @param mentor O mentor(suas informações) a ser atualizado.
   * @returns Promise com os resultados da busca.
   */
  handleMentorUpdate(mentor) {
    return this.props.mentorService.editar(mentor.id, mentor).then((res) => {
      this.updateTable();
      return res;
    });
  }

  /**
   * Formata a string para não passar por alterações ao renderizar,
   * seguindo o tamanho de caracteres possíveis de apresentar.
   *
   * @param string A string a ser formatada.
   * @param size O tamanho final desejado da string.
   * @returns A string formatada.
   */
  limitString(string, size, t) {
    if (!string) {
      return t(RH_MENTORES_SEM_NOME, "Sem nome");
    }
    if (string.length > size) {
      return string.substring(0, 25) + "...";
    }
    return string;
  }

  /**
   * Renderiza o modal de edição do mentor.
   *
   * @param showAlert O componente utilizado na exibição de mensagens de alerta.
   * @returns O modal de atualização do mentor.
   */
  editModal(showAlert) {
    return (
      <AtualizacaoMentorModal
        updateMentor={(mentor) => this.handleMentorUpdate(mentor)}
        isOpen={this.state.editModalIsOpen}
        handleClose={() => this.closeEditModal()}
        mentor={JSON.parse(JSON.stringify(this.state.mentorBeingEditted))}
        alertCallback={showAlert}
      />
    );
  }

  /**
   * Realiza uma bifurcação entre receber todos os mentores
   * ou os mentores filtrados.
   *
   * @param query Dados da query a ser realizada como página e o tamanho da query.
   * @returns Promise com os resultados da busca.
   */
  handleData(query): Promise<any> {
    if (this.state.query.search) {
      return new Promise((resolve, reject) =>
        this.props.mentorService
          .dashboardSearch(query.page, query.pageSize, this.state.query.search)
          .then((response) => response.data)
          .then((result) => {
            resolve({
              data: result.conteudo,
              page: result.numeroPaginaAtual,
              totalCount: result.totalElementos,
            });

            this.setState({ loading: false, dataTable: result.conteudo });
          })
      );
    } else {
      return new Promise((resolve, reject) => {
        this.props.mentorService
          .getPagination(query.page, query.pageSize)
          .then((response) => response.data)
          .then((result) => {
            resolve({
              data: result.conteudo,
              page: result.numeroPaginaAtual,
              totalCount: result.totalElementos,
            });

            let newData = this.state.dataTable;
            newData.push(result.conteudo);
            this.setState({ loading: false, dataTable: newData });
          });
      });
    }
  }

  /**
   * Atualiza o state de pesquisa com os novos valores de pesquisa.
   *
   * @param e O evento que dispara a mudança de state e que contém
   *         as informações de nome e valor para a atualização do state.
   */
  handleChange(e: React.ChangeEvent<any>) {
    const { name, value } = e.target;
    let newSearch = this.state.search;
    newSearch[name] = value;

    this.setState({ search: newSearch });
  }

  /**
   * Realiza a pesquisa e atualiza a tabela com os novos mentores filtrados.
   */
  handleSearch() {
    let newQuery = this.state.query;
    newQuery.search = this.state.search;
    this.setState({ query: newQuery });
    this.handleData(newQuery);
    this.referencia.current.onQueryChange();
  }

  render() {
    //@ts-ignore
    const { t, classes } = this.props;

    return (
      <>
        <Container maxWidth="lg" fixed className={classes.root}>
          <MenuRh />
          <div className={classes.titleDiv}>
            <Typography
              variant="h5"
              align="left"
              className={classes.dashboardTitle}
            >
              {t(RH_GERENCIAR_MENTORES, "Gerenciar Mentores")}
            </Typography>
          </div>
        </Container>
        <Container maxWidth="lg" fixed style={tableStyle.container}>
          <div className={classes.search}>
            {/* FILTROS */}
            <TextField
              id="nome"
              name="nome"
              label={t(RH_MENTORES_NOME, "Nome")}
              inputProps={{ maxLength: 60 }}
              variant="outlined"
              className={classes.searchField}
              onChange={(e) => this.handleChange(e)}
              InputLabelProps={{
                className: classes.searchFieldLabel,
              }}
            />
            <TextField
              id="email"
              name="email"
              label={t(RH_MENTORES_EMAIL, "E-mail")}
              variant="outlined"
              className={classes.searchField}
              onChange={(e) => this.handleChange(e)}
              InputLabelProps={{
                className: classes.searchFieldLabel,
              }}
            />
            <Select
              id="statusMentor"
              name="statusMentor"
              className={classes.searchField}
              onChange={(e) => this.handleChange(e)}
              displayEmpty
              variant="outlined"
              defaultValue=""
            >
              <MenuItem value="">Status</MenuItem>
              {allStatusMentor().map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    {mapStatusMentor(status, t)}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={() => this.handleSearch()}
            >
              {t(RH_MENTORES_BUSCAR, "Buscar")}
            </Button>
          </div>
          <GlobalCss />
          <alertContext.Consumer>
            {({ showAlert }) => {
              return (
                <React.Fragment>
                  {this.editModal(showAlert)}

                  <MaterialTable
                    title="Mentores"
                    tableRef={this.referencia}
                    localization={{
                      header: {
                        actions: t(RH_MENTORES_EDITAR, "Editar"),
                      },
                      body: {
                        emptyDataSourceMessage: t(
                          RH_MENTORES_SEM_MENTORES,
                          "Sem mentores para exibir"
                        ),
                        editTooltip: t(RH_MENTORES_EDITAR, "Editar"),
                      },
                      pagination: {
                        labelDisplayedRows: `{from}-{to} ${t(
                          RH_MENTORES_DE,
                          "de"
                        )} {count}`,
                        labelRowsSelect: "itens",
                      },
                      toolbar: {
                        searchTooltip: t(RH_MENTORES_BUSCAR, "Buscar"),
                        searchPlaceholder: t(RH_MENTORES_BUSCAR, "Buscar"),
                      },
                    }}
                    style={tableStyle.table}
                    columns={[
                      {
                        title: "",
                        field: "foto",
                        editable: "never",
                        render: (rowData) => (
                          <div
                            style={{
                              width: "10vh",
                              height: "10vh",
                              borderRadius: "100%",
                              backgroundImage: `url(${rowData.foto})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          />
                        ),
                      },
                      {
                        title: t(RH_MENTORES_NOME, "Nome"),
                        field: "nome",
                        render: (data) => (
                          <p>{this.limitString(data.nome, 25, t)}</p>
                        ),
                        editable: "never",
                      },
                      {
                        title: t(RH_MENTORES_DESCRICAO, "Descrição"),
                        field: "descricao",
                        render: (data) => (
                          <p>{data.descricao?.substr(0, 45) + "..."}</p>
                        ),
                      },
                      { title: "Email", field: "email", editable: "never" },
                      {
                        title: "Status",
                        field: "statusMentor",
                        lookup: {
                          APROVADO: t(RH_MENTORES_APROVADO, "Aprovado"),
                          AGUARDANDO_APROVACAO: t(
                            RH_MENTORES_AGUARDANDO_APROVACAO,
                            "Aguardando Aprovação"
                          ),
                          RECUSADO: t(RH_MENTORES_RECUSADO, "Recusado"),
                          INATIVO: t(RH_MENTORES_INATIVO, "Inativo"),
                          AUSENTE: t(RH_MENTORES_AUSENTE, "Ausente"),
                        },
                      },
                      {
                        title: "",
                        align: "right",
                        render: (rowData) => (
                          <Button
                            color="primary"
                            onClick={() => this.openEditModal(rowData)}
                          >
                            <EditIcon />
                          </Button>
                        ),
                      },
                    ]}
                    data={(query) => this.handleData(query)}
                    isLoading={this.state.loading}
                    options={{
                      paginationType: "normal",
                      pageSize: this.state.query.pageSize,
                      toolbar: false,
                      search: false,
                      filtering: false,
                      sorting: false,
                      headerStyle: tableStyle.headerStyle,
                      searchFieldStyle: tableStyle.searchField,
                      showTitle: false,
                    }}
                    onRowsPerPageChange={(pageSize: number) => {
                      let newQuery = this.state.query;
                      newQuery.pageSize = pageSize;
                      this.setState({ query: newQuery });
                    }}
                    onPageChange={(page: number) => {
                      let newQuery = this.state.query;
                      newQuery.page = page;
                      this.setState({ query: newQuery });
                    }}
                  />
                </React.Fragment>
              );
            }}
          </alertContext.Consumer>

          <div className={classes.downloadDiv}>
            {/* DOWNLOAD DO RELATÓRIO GERAL: */}
            {this.state.relatorioGeralDownloading ? (
              <SpinnerSplendis />
            ) : (
              <Button
                style={{ margin: 4, color: "white" }}
                className={classes.generalReportButton}
                variant="contained"
                onClick={() => {
                  this.setState({ relatorioGeralDownloading: true });

                  this.props.relatorioExcelService
                    .getRelatorioGeral()
                    .then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");

                      link.href = url;
                      link.setAttribute("download", "relatorio-geral.xlsx");
                      document.body.appendChild(link);

                      link.click();
                      this.setState({ relatorioGeralDownloading: false });
                    });
                }}
              >
                {" "}
                {t(RH_MENTORES_RELATORIO_GERAL, "Relatório Geral")}{" "}
                <GetAppIcon />{" "}
              </Button>
            )}

            {/* DOWNLOAD DE MENTORES: */}
            {this.state.relatorioMentoresDownloading ? (
              <SpinnerSplendis />
            ) : (
              <Button
                style={{ margin: 4, color: '#0a3039' }}
                variant="outlined"
                onClick={() => {
                  this.setState({ relatorioMentoresDownloading: true });

                  this.props.relatorioExcelService
                    .getRelatorioMentores()
                    .then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");

                      link.href = url;
                      link.setAttribute("download", "mentores.xlsx");
                      document.body.appendChild(link);

                      link.click();
                      this.setState({ relatorioMentoresDownloading: false });
                    });
                }}
              >
                {" "}
                {t(RH_MENTORES_RELATORIO_MENTORES, "Relatório Mentores")}{" "}
                <GetAppIcon />{" "}
              </Button>
            )}
          </div>
        </Container>
      </>
    );
  }

  /**
   * Realiza atualização da tabela utilizando da referência salva.
   */
  updateTable() {
    if (this.referencia) {
      this.referencia.current.onQueryChange();
    } else {
      setTimeout(this.updateTable, 250);
    }
  }

  /**
   * Realiza o fetch de mentores.
   */
  getMentores() {
    this.setState({ loading: true });

    this.props.mentorService
      .getPagination(this.state.query.page, this.state.query.pageSize)
      .then((dataTable) => {
        this.setState({ dataTable: dataTable, loading: false });
      });
  }

  /**
   * Exclui um mentor com base no seu id.
   *
   * @param id O id do mentor.
   *
   * Obs.: Método não está totalmente implementado.
   */
  delete(id: any) {
    this.props.mentorService.delete(id).then(() => {});
  }
}

export default withTranslation(RH_MENTORES_SOURCE)(
  withStyles(pageStyle)(RhMentorDashBoardComponent)
);
