import React from 'react';
import { Paper, Grid, Button, withStyles, Theme, createStyles, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';

import { FileUpload, SpinnerSplendis } from '../../../../commons';
import { categoriaSchema } from './validations';
import CategoriasProps from './CategoriasProps';
import { withTranslation } from 'react-i18next';
import {
    CATEGORIAS_SOURCE,
    CATEGORIAS_TITULO,
    CATEGORIAS_FORM_IMAGEM,
    CATEGORIAS_FORM_NOME_PT,
    CATEGORIAS_FORM_DESCRICAO_PT,
    CATEGORIAS_FORM_NOME_EN,
    CATEGORIAS_FORM_DESCRICAO_EN,
    CATEGORIAS_FORM_NOME_ES,
    CATEGORIAS_FORM_DESCRICAO_ES,
    CATEGORIAS_PORTUGUES,
    CATEGORIAS_INGLES,
    CATEGORIAS_ESPANHOL,
    CATEGORIAS_FORM_APLICAR
} from '../../../../messages';
import { MenuRh } from '../../../../components';

const pageStyle = (theme: Theme) => createStyles({
    root: {
        marginTop: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        padding: 0,
        position: 'relative'
    },
    titleDiv: {
        flex: 1,
        display: "flex",
        justifyContent: "center",

        '@media only screen and (max-width: 414px)': {
            marginLeft: '7vw'
        }
    },
    title: {
        fontFamily: "infraRegular",
        lineHeight: "150%",
        fontSize: "1.625rem",
    },
    button: {
        width: "4.813rem",
        height: "2.75rem",
    },
    link: {
        textDecoration: "none",
    },
    downloadDiv: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    downloadButton: {
        margin: 4,
    },
    advancedSearch: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    status: {
        marginLeft: theme.spacing(1),
    },
    container: {
        marginTop: '50px',
        marginBottom: '50px',
        minHeight: 400,
        backgroundRepeat: 'no-repeat',
        width: '40rem',

        '@media only screen and (max-width: 414px)': {
            width: '100vw'
        }
    },
    backButton: {
        bottom: '50%',
        color: '#003B4A',
        display: 'flex',
        fontFamily: 'InfraRegular',
        fontSize: '.8rem',
        fontStyle: 'normal',
        fontWeight: 500,
        left: '5vh',
        margin: '2vh 2vh',
        position: 'absolute',

        '@media (max-width: 992px)': {
            display: 'none'
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '3vh'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 60,
        margin: '0 auto',
        textAlign: 'center',
    },
    dashboardTitle: {
        fontFamily: 'InfraRegular',
        fontStyle: 'normal',
        fontWeight: 600,

        '@media only screen and (max-width: 414px)': {
            fontSize: '1rem'
        }
    },
    menuButton: {
        position: 'relative',
        left: '2rem'
    },
    forms: {
        '& > div': {
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    descriptionInput: {
        marginLeft: '.5rem',
        width: '20rem'
    },
    titleLanguage: {
        fontFamily: 'InfraRegular',
        fontWeight: 500,
        fontSize: '1.375rem',
        marginTop: '0.6rem',
        marginBottom: 0,
        display: 'flex',

        '@media only screen and (max-width: 414px)': {
            fontSize: '1rem'
        }
    },
    nameField: {
        '@media only screen and (max-width: 414px)': {
            marginRight: '1vw',
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            marginRight: '1vw',
        }
    },
    fieldLabel: {
        fontFamily: 'InfraRegular',
        fontSize: '.9rem',

        '@media only screen and (max-width: 414px)': {
            fontFamily: 'InfraRegular',
            fontSize: '.6rem',
        }
    }
});

/**
 * Classe que descreve o modelo para o state da categoria.
 */
export class CategoriaState {
    isReady?: boolean = false
    language: string = 'Pt'
    nomePt: any = ''
    descricaoPt: any = ''
    nomeEs: any = ''
    descricaoEs: any = ''
    nomeEn: any = ''
    descricaoEn: any = ''
    imagem: any = ''     // BASE64
    response: any = ''
    status: any = 'ATIVO'
    loading: boolean = false
    nomeReady: boolean = false
    descricaoReady: boolean = false
}

/**
 * Componente para criação de novas categorias, 
 * apenas acessível para o papel de ADMIN.
 */
class CategoriasComponent extends React.Component<CategoriasProps, CategoriaState> {

    constructor(props: any) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = new CategoriaState();
        this.imageCallback = this.imageCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Cria uma nova categoria assim que o componente é renderizado.
     */
    componentDidMount() {
        this.setState(new CategoriaState())
    }

    /**
     * Ao fazer envio da imagem da categoria, essa função é chamada para salvar a imagem no state do componente
     * 
     * @param file A imagem da categoria sendo cadastrada.
     */
    imageCallback = (file: any) => {
        this.setState({ imagem: file });
    }

    /**
     * Para habilitar o botão de criação verifica se os campos de nome em português,
     * descrição em português e imagem estão preenchidos.
     * 
     * @returns {boolean} True ou False caso os campos acima descritos estejam preenchidos ou não.
     */
    testReady(): boolean {
        if (this.state.nomePt !== '' && this.state.descricaoPt !== '' && this.state.imagem) {
            return true
        }
        return false;
    }

    /**
     * Atualiza o state com novos valores a partir do nome e valor vindo do evento.
     * 
     * @param e O evento que dispara a mudança de state e que contém o atributos nome e valor.
     */
    handleChange = (e) => {
        const { name, value } = e.target;

        //@ts-ignore
        this.setState({ [name]: value });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <>
                {/* Sub header */}
                <Container maxWidth="lg" fixed className={classes.root}>
                    <div className={classes.menuButton}>
                        <MenuRh />
                    </div>
                    <div className={classes.titleDiv}>
                        <Typography variant="h5" align="left" className={classes.dashboardTitle}>{t(CATEGORIAS_TITULO, 'Cadastrar Categorias')}</Typography>
                    </div>
                </Container>

                {/* Body */}
                <Container fixed maxWidth="sm" className={classes.container}>
                    <form onSubmit={this.handleSubmit} autoComplete="off" >
                        <Paper>
                            <Formik
                                validateOnMount={false}
                                initialValues={this.state}
                                validationSchema={categoriaSchema(t)}
                                onSubmit={this.handleSubmit}
                            >
                                {({ isValid }) => {
                                    return (
                                    <div style={{ padding: 10 }}>
                                        <FileUpload {...{ callback: this.imageCallback }} customLabel={t(CATEGORIAS_FORM_IMAGEM, "imagem *")} />

                                        <div className={classes.forms}>
                                            {/* Forms para nomes e descrições das categorias */}

                                            <Typography variant="h6" className={classes.titleLanguage}>{t(CATEGORIAS_PORTUGUES, "Português")}</Typography>
                                            <div>
                                                <FormikTextField
                                                    value={this.state.nomePt}
                                                    name="nomePt"
                                                    onChange={e => this.handleChange(e)}
                                                    label={t(CATEGORIAS_FORM_NOME_PT, "Nome Português")}
                                                    margin="normal"
                                                    variant="outlined"
                                                    className={classes.nameField}
                                                    InputLabelProps={{
                                                        className: classes.fieldLabel
                                                    }}
                                                />
                                                <FormikTextField
                                                    value={this.state.descricaoPt}
                                                    name="descricaoPt"
                                                    onChange={e => this.handleChange(e)}
                                                    label={t(CATEGORIAS_FORM_DESCRICAO_PT, "Descrição Português")}
                                                    margin="normal"
                                                    className={classes.descriptionInput}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        className: classes.fieldLabel
                                                    }}
                                                />
                                            </div>

                                            <Typography variant="h6" className={classes.titleLanguage}>{t(CATEGORIAS_INGLES, "Inglês")}</Typography>
                                            <div>
                                                <FormikTextField
                                                    value={this.state.nomeEn}
                                                    name="nomeEn"
                                                    onChange={e => this.handleChange(e)}
                                                    label={t(CATEGORIAS_FORM_NOME_EN, "Nome Inglês")}
                                                    margin="normal"
                                                    variant="outlined"
                                                    className={classes.nameField}
                                                    InputLabelProps={{
                                                        className: classes.fieldLabel
                                                    }}
                                                />
                                                <FormikTextField
                                                    value={this.state.descricaoEn}
                                                    name="descricaoEn"
                                                    onChange={e => this.handleChange(e)}
                                                    label={t(CATEGORIAS_FORM_DESCRICAO_EN, "Descrição Inglês")}
                                                    margin="normal"
                                                    className={classes.descriptionInput}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        className: classes.fieldLabel
                                                    }}
                                                />
                                            </div>

                                            <Typography variant="h6" className={classes.titleLanguage}>{t(CATEGORIAS_ESPANHOL, "Espanhol")}</Typography>
                                            <div>
                                                <FormikTextField
                                                    value={this.state.nomeEs}
                                                    name="nomeEs"
                                                    onChange={e => this.handleChange(e)}
                                                    label={t(CATEGORIAS_FORM_NOME_ES, "Nome Espanhol")}
                                                    margin="normal"
                                                    variant="outlined"
                                                    className={classes.nameField}
                                                    InputLabelProps={{
                                                        className: classes.fieldLabel
                                                    }}
                                                />
                                                <FormikTextField
                                                    value={this.state.descricaoEs}
                                                    name="descricaoEs"
                                                    onChange={e => this.handleChange(e)}
                                                    label={t(CATEGORIAS_FORM_DESCRICAO_ES, "Descrição Espanhol")}
                                                    margin="normal"
                                                    className={classes.descriptionInput}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        className: classes.fieldLabel
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                                            {
                                                this.state.loading ? <SpinnerSplendis />
                                                    :
                                                    <Button type="submit" variant="contained" disabled={!this.testReady()} style={{ width: '100%', color:'white', backgroundColor: '#003B4A'}}>{t(CATEGORIAS_FORM_APLICAR, 'APLICAR')}</Button>
                                            }

                                        </Grid>
                                    </div>
                                    )
                                }}
                            </Formik>
                        </Paper>
                    </form>

                </Container>
            </>
        );

    }

    /**
     * Função para lidar com a chamada da função do service e lidar com o retorno,
     * passando os campos preenchidos para criação da entidade categoria no backend
     * 
     * @param event O evento que dispara a submissão da atualização dos states.
     */
    handleSubmit(event: any) {
        // event.preventDefault();
        this.setState({ loading: true });
        this.setState({ status: 'ATIVO' });

        this.props.categoriaService.salvar(
            this.state.nomePt, this.state.descricaoPt, this.state.nomeEs, this.state.descricaoEs,
            this.state.nomeEn, this.state.descricaoEn, this.state.imagem, this.state.status
        )
            .then((res) => {
                this.setState({ response: res });
                this.setState({ loading: false });

                setTimeout(() => { window.location.href = '#/mentoring/categoriadashboard' }, 2000);
            }
            ).catch((err) => {
                this.setState({ loading: false });
                setTimeout(() => window.location.reload, 2000)
            });
    }

}

export default withTranslation(CATEGORIAS_SOURCE)(
    withStyles(pageStyle)(CategoriasComponent));