/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";

import {
	Stepper,
	Step,
	StepLabel,
	Button,
	Typography,
	StepConnector,
} from "@material-ui/core";

import { AccessTime, CollectionsBookmarkOutlined, FlagOutlined, FolderOpen, PeopleAlt } from '@material-ui/icons';

import {
	CategoriaService,
	MentorService,
	DisponibilidadeService,
	IdiomaService,
} from "../../../../service";

import { Disponibilidade, Mentor } from "../../../../models";
import { SpinnerSplendis } from "../../../../commons";
import { ConfirmacaoModal } from "../../../../components";
import {
	ShowTranslationWarning,
} from "../../../../utils/FormUtils";

import {
	DisponibilidadeComponent as DisponibilidadeStep,
	CategoriasComponent as CategoriasStep,
	RevisaoComponent as RevisaoStep,
	DescricaoComponent,
	TermoDeUsoMentor,
	IdiomasComponent,
} from "./components";

import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
	SE_TORNAR_MENTOR_SOURCE,
	SE_TORNAR_MENTOR_TITULO,
	SE_TORNAR_MENTOR_INDICADOR_STEP_DIAS,
	SE_TORNAR_MENTOR_INDICADOR_STEP_DISPONIBILIDADE,
	SE_TORNAR_MENTOR_INDICADOR_STEP_SOBRE_VOCE,
	SE_TORNAR_MENTOR_INDICADOR_STEP_CATEGORIAS,
	SE_TORNAR_MENTOR_INDICADOR_STEP_IDIOMAS,
	SE_TORNAR_MENTOR_INDICADOR_STEP_FINALIZAR,
	SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_TITULO,
	SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_BOTAO_VOLTAR_PARA_HOME,
	SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_CONTEUDO,
	SE_TORNAR_MENTOR_TERMOS_DE_USO_TITULO,
	SE_TORNAR_MENTOR_TERMOS_DE_USO_BOTAO,
	SE_TORNAR_MENTOR_BOTAO_VOLTAR,
	SE_TORNAR_MENTOR_BOTAO_PROXIMO
} from "../../../../messages";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: 'transparent',
			height: '100%',
			margin: 'auto',
			width: '90%',

			'@media (max-width: 1024px)': {
				width: '100%',
			},
		},
		headSteps: {
			width: '100%',
			backgroundColor: 'transparent',
			opacity: '80',
			padding: 0,
			paddingTop: '24',
			marginTop: '3vh',

			'@media only screen and (max-width: 1024px)': {
				padding: 0,
				paddingTop: 0,
				overflowWrap: 'anywhere',
			},
		},
		title: {
			color: '#030624',
			fontFamily: 'InfraRegular',
			fontSize: '1.3rem',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '39px',
			marginBottom: '.2rem',
			marginTop: '1rem',

			'@media only screen and (max-width: 720px)': {
				fontSize: '.9rem',
				fontWeight: 500,
			}
		},
		backButtonContent: {
			position: 'absolute',
			bottom: '23vh',
			left: '1.5%',
			width: '10vw',

			'@media only screen and (max-width: 414px)': {
				bottom: 0,
				left: '25%'
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				bottom: '-70vh',
				left: '30%',
			},
		},
		backButton: {
			position: 'sticky',
			bottom: '50%',
			left: '5vh',
			height: '6.5rem',
			width: '6.5rem',
			color: '#003B4A',
			borderRadius: '50%',
			border: 'solid #003B4A 6px',
			marginBottom: '9vh',
			display: 'flex',
			overflowZ: 'none',

			'@media only screen and (max-width: 720px)': {
				display: 'none'
			},
		},
		backButtonMobile: {
			display: 'none',

			'@media only screen and (max-width: 720px)': {
				bottom: '-12vh',
				color: '#003B4A',
				display: 'block',
				left: '-5vw',
				position: 'absolute',
			},
		},
		backButtonDisabled: {
			position: 'sticky',
			bottom: '50%',
			left: '5vh',
			height: '6.5rem',
			width: '6.5rem',
			color: '#003B4A',
			borderRadius: '50%',
			border: 'solid 6px',
			marginBottom: '9vh',
			display: 'flex',
			overflowZ: 'none',

			'@media only screen and (max-width: 720px)': {
				display: 'none'
			},
		},
		backButtonMobileDisabled: {
			display: 'none',

			'@media only screen and (max-width: 720px)': {
				bottom: '-12vh',
				display: 'block',
				left: '-5vw',
				position: 'absolute',
			},
		},
		backButtonMobileLast: {
			display: 'none',

			'@media only screen and (max-width: 414px)': {
				bottom: '-35vh',
				color: '#003B4A',
				display: 'block',
				left: '11vw',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				bottom: '-13vh',
				color: '#003B4A',
				display: 'block',
				left: '12vw',
			}
		},
		forwardButtonContent: {
			position: 'absolute',
			bottom: '23vh',
			right: '1.5%',
			width: '10vw',

			'@media only screen and (max-width: 414px)': {
				bottom: 0,
				right: 0,
				left: '50%',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				bottom: '-70vh',
				left: '55%',
			},
		},
		forwardButtonActive: {
			position: 'sticky',
			bottom: '50%',
			right: '5vh',
			height: '6.5rem',
			width: '6.5rem',
			color: '#003B4A',
			borderRadius: '50%',
			border: 'solid #003B4A 6px',
			marginBottom: '9vh',
			overflowZ: 'none',

			'@media only screen and (max-width: 720px)': {
				display: 'none'
			},
		},
		forwardButtonMobileActive: {
			display: 'none',

			'@media only screen and (max-width: 720px)': {
				color: '#003B4A',
				display: 'block',
				bottom: '-12vh',
				position: 'absolute',
			},
		},
		forwardButtonDisabled: {
			position: 'sticky',
			bottom: '50%',
			right: '5vh',
			height: '6.5rem',
			width: '6.5rem',
			borderRadius: '50%',
			border: 'solid 6px',
			marginBottom: '9vh',
			overflowZ: 'none',

			'@media only screen and (max-width: 720px)': {
				display: 'none'
			}
		},
		forwardButtonMobileDisabled: {
			display: 'none',

			'@media only screen and (max-width: 720px)': {
				display: 'block',
				bottom: '-12vh',
				position: 'absolute',
			}
		},
		noForwardButton: {
			display: 'none',
		},
		content: {
			display: "flex",
			justifyContent: "center",
			height: "0vh",
		},
		form: {
			width: "100%",
			marginRight: theme.spacing(20),
			marginTop: theme.spacing(1),
			marginLeft: theme.spacing(20),
		},
		stepLabel: {
			fontFamily: 'InfraRegular',
			fontSize: '.6rem',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '17.23px',
			textTransform: 'uppercase',

			'@media only screen and (max-width: 414px)': {
				fontSize: '.5rem',
			},

			'@media only screen and (min-width: 415px) and (max-width: 1024px)': {
				fontSize: '.7rem',
			},
		},
		confirmationButton: {
			backgroundColor: '#003B4A',
			color: '#FFF',
			fontFamily: 'InfraRegular',
			fontSize: '.9rem',
			fontStyle: 'normal',
			fontWeight: 500,
			marginBottom: '2rem',
			marginTop: '40.5rem',
			width: '69.3%',

			'&:hover': {
				backgroundColor: '#006FD7',
			}
		},
		congratulationsModal: {
			fontFamily: 'InfraRegular',
			fontStyle: 'normal',
		},
		modalMobileSmall: {
			width: '80vw'
		},
		modalMobileBig: {
			width: '50vw'
		}
	})
);

/**
 * Estiliza os conectores do passo a passo do processo de agendar mentoria.
 */
const ConnectorStyle = withStyles({
	alternativeLabel: {
		top: 22,

		'@media only screen and (max-width: 414px)': {
			top: 15
		}
	},
	active: {
		'& $line': {
			backgroundColor: '#003B4A',
		},
	},
	completed: {
		'& $line': {
			backgroundColor: '#003B4A',
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);

/**
 * Estiliza os ícones do passo a passo do processo de agendar mentoria.
 */
const useStepIconStyles = makeStyles({
	root: {
		backgroundColor: '#FFF',
		zIndex: 1,
		color: '#003B4A',
		width: 40,
		height: 40,
		display: 'flex',
		border: '2px solid #003B4A',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',

		'@media only screen and (max-width: 414px)': {
			padding: 13,
			width: 10,
			height: 10,
		}
	},
	active: {
		backgroundColor: '#FFF',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		backgroundColor: '#FFF',
	}
});

/**
 * Obtém as dimensões da tela.
 * 
 * @returns As dimensões da tela.
 */
function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

/**
 * Interface utilizada pelo componente HorizontalLabelPositionBelowStepper
 * e que descreve seu métodos e propriedades.
 */
interface MentorStepperProps {
	userEmail: string;
}

/**
 * Componente que gerencia o passo a passo do processo
 * para se tornar mentor
 * .
 * @param {MentorStepperProps}
 * @returns O gerenciamento do processo para se tornar mentor(botões de avançar
 *          e voltar, modais etc.)
 */
const HorizontalLabelPositionBelowStepper: React.FC<MentorStepperProps> = (props) => {

	/**
	 * Hook e função utilizados para tradução de textos.
	 */
	const { t } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);

	const classes = useStyles();

	const { userEmail } = props;

	const categoriaService: CategoriaService = new CategoriaService();
	const disponibilidadeService: DisponibilidadeService = new DisponibilidadeService();
	const mentorService: MentorService = new MentorService();
	const idiomaService: IdiomaService = new IdiomaService();

	const [activeStep, setActiveStep] = React.useState<number>(0);

	const [formObject, setFormObject] = React.useState<Mentor>(new Mentor());
	const [categoriasChecked, setCategoriasChecked] = React.useState<boolean[]>([]);

	const [idiomasChecked, setIdiomasChecked] = React.useState<boolean[]>([]);

	const [ButtonDisabledStatus, setButtonDisabledStatus] = React.useState<boolean>(true);
	const [loadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);
	const [modalInfoIsOpen, setModalInfoIsOpen] = React.useState<boolean>(false);
	const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = React.useState<boolean>(false);

	const [dimensions, setDimensions] = React.useState(getWindowDimensions());

	/**
	 * Verifica se a largura da tela é compatível com a largura de
	 * telas para dispositivos movéis.
	 * 
	 * @returns True caso a largura seja do tamanho para dispositivos
	 * 			movéis, False caso contrário. 
	 */
	const isMobile = () => {
		return dimensions.width < 1024;
	};

	/**
	 * Obtém os passos do processo de se tornar mentor.
	 * 
	 * @returns Array com os passos do processo de se tornar mentor.
	 */
	const getSteps = () => {
		let disp = isMobile()
			? t(SE_TORNAR_MENTOR_INDICADOR_STEP_DIAS, "Dias")
			: t(SE_TORNAR_MENTOR_INDICADOR_STEP_DISPONIBILIDADE, "Disponibilidade");
		let sobre_voce = t(
			SE_TORNAR_MENTOR_INDICADOR_STEP_SOBRE_VOCE,
			"Sobre você"
		);
		let idiomas = t(SE_TORNAR_MENTOR_INDICADOR_STEP_IDIOMAS, "Idiomas");
		let categorias = t(
			SE_TORNAR_MENTOR_INDICADOR_STEP_CATEGORIAS,
			"Categorias"
		);
		let finalizar = t(SE_TORNAR_MENTOR_INDICADOR_STEP_FINALIZAR, "Finalizar");

		return [sobre_voce, idiomas, categorias, disp, finalizar];
	};

	/**
	 * Cria a variável steps com o array de passos.
	 */
	const steps = getSteps();

	/**
	 * Carrega as categorias, idiomas e disponibilidades ao montar o componente.
	 */
	useEffect(() => {
		let newFormObj = formObject;
		//let newCategorias = categorias;

		categoriaService.getCategorias().then((categorias) => {
			newFormObj.categorias = categorias;
			setCategoriasChecked(categorias.map((cat) => false));
		});

		idiomaService.getIdiomas().then((res) => {
			let idiomas = res.data;
			newFormObj.idiomas = idiomas;
			setIdiomasChecked(idiomas.map((idioma) => false));
		});

		disponibilidadeService.getDisponibilidades().then((res) => {
			newFormObj.disponibilidades = createAvailableWeekDays(res.data);
		});

		setFormObject(newFormObj);
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Obtém as dimensões da tela e faz o redimensionamento da mesma.
	 */
	useEffect(() => {
		function handleResize() {
			setDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	/**
	 * Função que cria um array com os dias da semana útil, apenas.
	 * 
	 * @param weekDays Todos os dias de uma semana.
	 * @returns Um array contendo apenas os dias da semana útil.
	 */
	const createAvailableWeekDays = (weekDays: string[]) => {
		let availableDays: Disponibilidade[] = [];

		if (weekDays.every(day => typeof day === 'string')) {
			weekDays.forEach((day, index) => {
				if (index < 5) {
					availableDays.push(new Disponibilidade());
					availableDays[index].dia = day;
				}
			});
		}

		return availableDays;
	}

	/**
	 * Recebe o atributo que altera o objeto que gerencia o state do form,
	 * e o data para atribuir ao respectivo atributo do objeto que gerencia
	 * o form.
	 * 
	 * @param event O evento que dispara a mudança dos states e do form.
	 * @param atributo O atributo que está sendo alterado.
	 * @param data Os dados referentes ao atributo.
	 */
	const handleChange = (
		event: any,
		atributo: any = event.target.id,
		data: any = event.target.value
	) => {
		formObject[atributo] = data;

		if (atributo === "categorias")
			setCategoriasChecked(data.map((cat) => (cat.checked ? true : false)));

		if (atributo === "idiomas")
			setIdiomasChecked(data.map((idioma) => (idioma.checked ? true : false)));

		setFormObject(formObject);
	};

	/**
	 * Gerencia o processo que salva o form ao final do processo de se tornar mentor e
		   * habilita a abertura do modal de confirmação.
	 */
	const handleSubmit = () => {
		setLoadingSubmit(true);

		let oldFormObj = formObject;
		oldFormObj.disponibilidades = oldFormObj.disponibilidades.filter(disponibilidade => disponibilidade.checked);

		mentorService
			.salvar(
				oldFormObj.nome,
				oldFormObj.descricao,
				userEmail,
				oldFormObj.foto,
				oldFormObj.idiomas?.filter((idioma) => idioma.checked),
				oldFormObj.categorias?.filter((cat) => cat.checked),
				oldFormObj.disponibilidades?.filter((dia) => dia.checked),
				oldFormObj.timeZone
			)
			.then((res) => {
				setButtonDisabledStatus(true);
				if (res.request.status === 200) {
					setModalConfirmacaoIsOpen(true);
					setButtonDisabledStatus(false);
				} else {
					setTimeout(() => setButtonDisabledStatus(false), 3000);
				}
			})
			.then(() => setLoadingSubmit(false));
	};

	/**
	 * Gerencia o estado do botão para decidir se renderiza um próximo passo
	 * no processo de se tornar mentor ou se abre o modal ao final do
	 * processo.
	 */
	const handleButton = () => {
		activeStep === steps.length - 1 ? handleModalInfoOpen() : handleNext();
	};

	/**
	 * Função que atualiza o usuário com as informações para se tornar mentor.
	 * 
	 * @param mentor As informações de mentor.
	 */
	const handleUpdatedMentor = (mentor: Mentor) => {
		if (modalConfirmacaoIsOpen) {
			setFormObject(mentor);
		}
	}

	/**
	 * Renderiza os modais de termos de uso e de confirmação
	 * de cadastro.
	 * 
	 * @returns Os modais de termos de uso e de confirmação
	 * 			de cadastro.
	 */
	const modais = () => {
		return (
			<React.Fragment>
				<ConfirmacaoModal
					title={t(SE_TORNAR_MENTOR_TERMOS_DE_USO_TITULO, "Termos de Uso")}
					buttonText={t(SE_TORNAR_MENTOR_TERMOS_DE_USO_BOTAO, "Aceitar Termos e Continuar")}
					isOpen={modalInfoIsOpen}
					handleClose={handleModalInfoClose}
					buttonCallback={() => {
						handleModalInfoClose();
						handleSubmit();
					}}
				>
					<TermoDeUsoMentor />
				</ConfirmacaoModal>
				<ConfirmacaoModal
					buttonText={t(
						SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_BOTAO_VOLTAR_PARA_HOME,
						"Voltar para Home"
					)}
					style={window.screen.width <= 414 ? classes.modalMobileSmall : (window.screen.width > 414 && window.screen.width <= 720 ? classes.modalMobileBig : { height: "auto" })}
					isOpen={modalConfirmacaoIsOpen}
					buttonCallback={() => {
						window.location.href = "#/mentoring/home";
					}}
					disableClose
					modalProps={{ disableBackdropClick: true }}
					forceButtonEnabled
				>
					<Typography align="center" variant="h4" className={classes.congratulationsModal}>
						{t(
							SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_TITULO,
							"Obrigada por se Cadastrar!"
						)}
					</Typography>
					<p className={classes.congratulationsModal}>
						{t(
							SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_CONTEUDO,
							"Seus dados foram recebidos com sucesso."
						)}
					</p>
				</ConfirmacaoModal>
			</React.Fragment>
		);
	};

	/**
	 * Renderiza os passos de cada etapa do processo
	 * de se tornar mentor.
	 * 
	 * @param stepIndex O índice do passo na lista de passos.
	 * @returns O passo da etapa do proceso de se tornar mentor.
	 */
	const getStepContent = (stepIndex: number) => {
		switch (stepIndex) {
			case 0:
				return (
					<DescricaoComponent
						handleChange={handleChange}
						values={formObject}
						isDisabled={setButtonDisabledStatus}
					/>
				);
			case 1:
				return (
					<IdiomasComponent
						atributoForm={"idiomas"}
						idiomas={formObject.idiomas}
						setIdiomas={handleChange}
						isDisabled={setButtonDisabledStatus}
					/>
				);
			case 2:
				return (
					<CategoriasStep
						atributoForm={"categorias"}
						categorias={formObject.categorias}
						setCategorias={handleChange}
						isDisabled={setButtonDisabledStatus}
					/>
				);
			case 3:
				return (
					<DisponibilidadeStep
						atributoForm={"disponibilidades"}
						diasDisponiveis={formObject.disponibilidades}
						setDisponibilidade={handleChange}
						isDisabled={setButtonDisabledStatus}
					/>

				);
			default: 
				return (
					<RevisaoStep
						descricao={formObject.descricao || ''}
						categorias={formObject.categorias.filter(categoria => categoria.checked)}
						idiomas={formObject.idiomas.filter(idioma => idioma.checked)}
						disponibilidades={formObject.disponibilidades.filter(disponibilidade => disponibilidade.checked)}
						updateMentor={(handleUpdatedMentor)}
						handleConfirmation={handleModalInfoOpen}
					/>
				);
		}
	};

	/**
	 * Configura o state de passo ativo e verifica se há um próximo passo
		   * no processo de se tornar mentor.
	 */
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	/**
	 * Configura o state de passo ativo e verifica se há um passo anterior
		   * no processo de se tornar mentor.
	 */
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	/**
	 * Reinicia todo o processo de se tornar mentor e configurando o state
	 * do passo ativo para zero.
	 */
	const handleReset = () => {
		setActiveStep(0);
	};

	/**
	 * Abre o modal dos termos de uso ao final do processo de se tornar mentor.
	 */
	const handleModalInfoOpen = () => {
		setModalInfoIsOpen(true);
	};
	const handleModalConfirmacao = () => {
		setModalConfirmacaoIsOpen(true);
	};
	/**
	 * Fecha o modal dos termos de uso ao final do processo de se tornar mentor.
	 */
	const handleModalInfoClose = () => {
		setModalInfoIsOpen(false);
	};

	/**
	 * Renderiza os ícones relativos a cada passo no processo de se tornar mentor.
	 * 
	 * @param props Propriedades necessárias para a efetiva utilização dos ícones.
	 * @returns O ícone relativo a cada passo no processo de se tornar mentor.
	 */
	const StepIcons = (props) => {
		const classes = useStepIconStyles();
		const { active, completed } = props;

		const icons = {
			1: <PeopleAlt />,
			2: <CollectionsBookmarkOutlined />,
			3: <FolderOpen />,
			4: <AccessTime />,
			5: <FlagOutlined />
		};

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed,
				})}
			>
				{icons[String(props.icon)]}
			</div>
		);
	}

	/**
	 * Propriedades dos ícones do passo a passo de se tornar mentor.
	 */
	StepIcons.propTypes = {
		/**
		 * Whether this step is active.
		 */
		active: PropTypes.bool,
		/**
		 * Mark the step as completed. Is passed to child components.
		 */
		completed: PropTypes.bool,
		/**
		 * The label displayed in the step icon.
		 */
		icon: PropTypes.node,
	};

	return (
		<span>
			{/*TEMPORÁRIO*/}
			<ShowTranslationWarning />

			{modais()}

			<div className={classes.root}>

				<Stepper
					className={classes.headSteps}
					activeStep={activeStep}
					alternativeLabel
					connector={<ConnectorStyle />}>

					{steps.map((label) => (
						<Step key={label}>
							<StepLabel StepIconComponent={StepIcons}>
								<p className={classes.stepLabel}>{label}</p>
							</StepLabel>
						</Step>
					))}
				</Stepper>

				<Typography className={classes.title}>{t(SE_TORNAR_MENTOR_TITULO, 'Seja um mentor!')}</Typography>

				<div>
					{activeStep === steps.length ? (
						<div>
							<Typography>All steps completed</Typography>
							<Button onClick={handleReset}>Limpar</Button>
						</div>
					) : (
						<div>
							<div className={classes.content}>
								{getStepContent(activeStep)}
							</div>

							<div className={classes.backButtonContent}>
								<Button
									disabled={activeStep === 0}
									onClick={handleBack}
									className={activeStep === 0 ? classes.backButtonDisabled : classes.backButton}
									size='large'
								>
									<ArrowBackIosSharpIcon fontSize='large' />
								</Button>
								{activeStep === steps.length - 1 ?
									<Button
										disabled={activeStep === 0}
										onClick={handleBack}
										className={classes.backButtonMobileLast}
										size='large'
									>
										<Typography>{t(SE_TORNAR_MENTOR_BOTAO_VOLTAR, 'Voltar')}</Typography>
									</Button> :
									<Button
										disabled={activeStep === 0}
										onClick={handleBack}
										className={activeStep === 0 ? classes.backButtonMobileDisabled : classes.backButtonMobile}
										size='large'
									>
										<Typography>{t(SE_TORNAR_MENTOR_BOTAO_VOLTAR, 'Voltar')}</Typography>
									</Button>}
							</div>

							{loadingSubmit && activeStep === steps.length - 1 ? (
								<SpinnerSplendis />
							) : (
								activeStep < steps.length - 1 && <div className={classes.forwardButtonContent}>
									<Button
										disabled={ButtonDisabledStatus}
										onClick={handleButton}
										className={ButtonDisabledStatus ? classes.forwardButtonDisabled : classes.forwardButtonActive}
										size='large'
									>
										<ArrowForwardIosSharpIcon fontSize='large' />
									</Button>
									<Button
										disabled={ButtonDisabledStatus}
										onClick={handleButton}
										className={ButtonDisabledStatus ? classes.forwardButtonMobileDisabled : classes.forwardButtonMobileActive}
										size='large'
									>
										<Typography>{t(SE_TORNAR_MENTOR_BOTAO_PROXIMO, 'Próximo')}</Typography>
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</span>
	);
}

export default HorizontalLabelPositionBelowStepper;
