import { Mentor, Mentorado, Colaborador } from ".";

/**
 * Classe que define o modelo para um user object com todas as suas propriedades e métodos.
 */
export class UserObject {
    public mentor: Mentor;
    public mentorado: Mentorado;
    public colaborador: Colaborador;
    

    constructor(){
        this.mentor = new Mentor();
        this.colaborador = new Colaborador();
        this.mentorado = new Mentorado();
    }
}