import React from "react";
import { useTranslation } from "react-i18next";
import { ModalSucesso } from "../../../../../../../commons";
import {
  AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_CONTEUDO,
  AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_TITULO,
  AGENDAMENTO_MENTORIA_SOURCE
} from "../../../../../../../messages";

/**
 * Interface utilizada pelo componente AgendamentoMentoriaConfirmacaoPosTermosDeUsoModal
 * e que descreve seu métodos e propriedades.
 */
interface AgendamentoMentoriaConfirmacaoPosTermosDeUsoModalProps {
  open: boolean;
  onButtonClick: () => void;
}

/**
 * Renderiza o modal de sucesso após a exibição dos termos de uso.
 * 
 * @param {AgendamentoMentoriaConfirmacaoPosTermosDeUsoModalProps}
 * @returns O modal de sucesso após a exibição dos termos de uso.
 */
const AgendamentoMentoriaConfirmacaoPosTermosDeUsoModal = (
  props: AgendamentoMentoriaConfirmacaoPosTermosDeUsoModalProps
) => {
  const { open, onButtonClick } = props;

  /**
   * Hook e função utilizados na tradução dos textos do modal.
   */
  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

  return (
    <ModalSucesso
      isOpen={open}
      handleClose={onButtonClick}
      title={t(AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_TITULO, 'Mentoria Agendada'
      )}
      subtitle={t(
        AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_CONTEUDO,
        'Mentoria agendada com sucesso. Parabéns pela iniciativa!'
      )}
    />
  );
};

export default AgendamentoMentoriaConfirmacaoPosTermosDeUsoModal;
