import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { CustomModal, SpinnerSplendis } from "..";
import { ColaboradorService } from "../../service";
import { NewMentoriaCard, ShareMentoring } from "../../components";
import StarRatings from "react-star-ratings";
import { Avaliacao, Mentorado } from "../../models";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "17rem",

            '@media only screen and (max-width: 414px)': {
                width: '70vw'
            }
        },
        confirmarContainer: {
            fontFamily: "infraRegular",
        },
        button: {
            fontFamily: "Roboto",
            width: "16rem",
            marginLeft: "0.65rem",
            marginTop: "0.5rem",
        },
        statusContainer: {
            textAlign: "initial",
            marginLeft: "1rem",
        },
        status: {
            marginLeft: theme.spacing(1),
        },
        buttonConfirmar: {
            fontFamily: "Roboto",
            width: "100%",
            marginTop: "1rem",
            "& span": {
                height: "2rem",
            },
        },
        avaliacaoDescricao: {
            fontFamily: "infraRegular",
            height: "7rem",
            overflow: "auto",
            wordBreak: "break-word",

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                marginLeft: '-11vw'
            }
        },
        sharingDivider: {
            '@media only screen and (max-width: 414px)': {
                width: '72vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '42vw',
            }
        }
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: '40.3rem',
        textAlign: '-webkit-center',
        width: '21rem',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: '40.3rem',
        marginTop: '34vh',
        textAlign: 'center',
        width: '73vw',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: '40.3rem',
        marginTop: '140vh',
        textAlign: 'center',
        width: '42vw',
    },
};

/**
 * Interface utilizada pelo componente AvaliacaoModal e que descreve
 * seu métodos e propriedades.
 */
interface AvaliacaoModalProps {
    isOpen: boolean;
    handleClose: Function;
    emailMentorado?: string;
    emailMentor?: string;
    avaliacao: Avaliacao;
    categoria: string;
    role?: string;
}

/**
 * Componente para realização da avaliação pelo mentorado, para uma mentoria já realizada.
 * 
 * @param {AvaliacaoModalProps} 
 * @returns O modal de avaliação com todas suas informações pertinentes.
 */
const AvaliacaoModal: React.FC<AvaliacaoModalProps> = ({
    isOpen,
    handleClose,
    emailMentorado,
    emailMentor,
    avaliacao,
    categoria,
    role = "ROLE_MENTOR"
}) => {
    const classes = useStyles();

    const colaboradorService: ColaboradorService = new ColaboradorService();

    const [mentorado, setMentorado] = React.useState<Mentorado>(new Mentorado());
    const [mentor, setMentor] = React.useState<any>();
    const [loading, setLoading] = React.useState(true);

    /**
     * Realiza o fetch do colaborador ao receber um novo email.
     */
    React.useEffect(() => {
        getColaborador();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailMentorado, emailMentor]);

    /**
     * Função responsável por realizar o fetch do colaborador,
     * dependendo do papel do usuário preenche o mentor ou mentorado
     * para apresentar no card de mentoria.
     */
    const getColaborador = () => {
        if (emailMentorado || emailMentor) {
            colaboradorService.getByEmail(emailMentorado || emailMentor).then((res) => {
                if (role === "ROLE_MENTORADO") {
                    setMentor(res.data);
                } else {
                    setMentorado(res.data);
                }
                setLoading(false);
            });
        }
    }

    return (
        <CustomModal
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            <>
                {loading ? (
                    <SpinnerSplendis />
                ) : (
                    <div className={classes.container}>
                        <div className={classes.statusContainer}>
                            {
                                avaliacao &&
                                // Componente para apresentar as notas como estrelas
                                <StarRatings
                                    rating={avaliacao ? avaliacao.nota : 0}
                                    starRatedColor="#FFB400"
                                    starDimension="1.375rem"
                                    starSpacing="0.125rem"
                                />
                            }
                        </div>

                        <NewMentoriaCard
                            mentorado={mentorado}
                            mentor={mentor}
                            showCategoria={true}
                            categoria={categoria}
                            role={role}
                            style={{ height: role === "ROLE_MENTORADO" ? "16rem" : "auto" }}
                            isModal={true}
                        />

                        <p className={classes.avaliacaoDescricao}>
                            {avaliacao?.descricao}
                        </p>

                        <div className={classes.sharingDivider}>
                            <hr />
                        </div>

                        <ShareMentoring mentor={mentor} />
                    </div>
                )}
            </>
        </CustomModal>
    );
};

export default AvaliacaoModal;
