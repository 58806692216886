import React from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';

import spain from '../../assets/flags/espanha.png';

const useStyles = makeStyles({
  imageIcon: {
    height: '100%',
    width: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  }
});

/**
 * Componente responsável por renderizar a bandeira da Espanha,
 * a qual simboliza a utilização do idioma Espanhol.
 */
const SpainFlagIcon = () => {
  const classes = useStyles();

  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img alt="spain" className={classes.imageIcon} src={spain} />
    </Icon>
  );
}

export default SpainFlagIcon;
