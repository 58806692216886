import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { Theme, createStyles, Typography } from "@material-ui/core";

import { SpinnerSplendis } from "../../../../../../../../commons";
import { HorariosCard } from "./components";
import {
  AGENDAMENTO_MENTORIA_SOURCE,
  AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_1,
  AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_2,
  AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_3,
  AGENDAMENTO_MENTORIA_STEP_HORARIOS_SEM_HORARIOS_DISPONIVEIS_1,
  AGENDAMENTO_MENTORIA_STEP_HORARIOS_SEM_HORARIOS_DISPONIVEIS_2,
  AGENDAMENTO_MENTORIA,
} from "../../../../../../../../messages";

const gridListStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      letterSpacing: 0,
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      margin: "0 auto",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
      maxWidth: "90%",
    },
    container: {
      display: "flex",
      justifyContent: "center",
    },
    title: {
      color: "black",
      fontWeight: 20,
      fontSize: "3vh",
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    formLabel: {
      "@media only screen and (max-width: 455px)": {
        fontSize: "0.8rem",
      },
    },
    horariosStepContainer: {
      //top: "-10vh",
		  //position: "relative",
      display: "flex",
      flexDirection: "column",
      maxWidth: "100%",
      width: "70%",
      marginTop: '1rem',
      top: '29vh',
      position: 'absolute',

      '@media only screen and (max-width: 455px)': {
        maxWidth: '90vw'
      },
      '@media only screen and (min-width: 456px) and (max-width: 1024px)': {
        maxWidth: '90vw'
      }
    },
    panelHeader: {
      marginBottom: "12.5vh",
      marginTop: "2.3vh",
    },
    panelSubheader: {
      color: "#000",
      fontFamily: "InfraRegular",
      fontSize: ".8rem",
      fontStyle: "normal",
      fontWeight: 400,
      paddingBottom: '3.5rem',
    },
  })
);

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: "5vh",
    height: "5vh",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 25,
      height: 25,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

/**
 * Interface Disponibilidade com os atributos diaSemana e horario.
 */
export interface Disponibilidade {
  diaSemana: string;
  horario: string;
  intervaloSemanas: number;
}

/**
 * Renderiza radio button com suas estilizações.
 *
 * @param props Propriedades passadas a função para estilizaçãod do radio button.
 * @returns O radio button.
 */
export function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

/**
 * Enum com os dias do final de semana para faciliar a filtragem
 * dos dias úteis.
 */
enum FinalDeSemana {
  Sabado = "SABADO",
  Domingo = "DOMINGO",
}

/**
 * Componente que renderiza card com os horários com as disponibilidades
 * para a agendamento de mentoria.
 *
 * @param props Propriedades necessárias para a disponibilização das
 * 				disponibilidades para o agendamento de mentoria.
 * @returns A disponibilidade de dias e horários.
 */
export default function HorariosStepMentorado(props) {
  const classes = gridListStyle();

  /**
   * Hook e função utilizados para a tradução de textos.
   */
  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

  const { isLoading, isDisabled, defaultValue, horarios, mentor } = props;

  const [horarioSelected, setHorarioSelected] = React.useState(defaultValue);

  /**
   * Desabilita ou não a lista dependendo se alguma disponibilidade foi selecionada ou não.
   */
  useEffect(() => {
    isDisabled(isNotValid);
  });

  /**
   * Verifica se algum horário foi selecionado.
   *
   * @returns True caso nenhum horário tenha sido selecionado, False caso contrário.
   */
  const isNotValid = () => {
    return horarioSelected === "";
  };

  /**
   * Gerencia o processo de seleção de uma disponibilidade da lista.
   *
   * @param e O evento que dispara a mudança no state
   *            de disponibilidade selecionada.
   * @param data Os dados da disponibilidade.
   */
  const handleChange = (e, data) => {
    props.handleChange("disponibilidade", e, data);
    setHorarioSelected(data);
  };

  /**
   * Função que gerencia a lista com os dias da semana, removendo os finais de semana.
   *
   * @param horarios Array com todos os dias e horários.
   * @returns Um array com os dias e horários, mas sem os finais de semana.
   */
  const handleSchedule = (
    horarios: [{ dia: string; data: string; hora: string }]
  ) => {
    return horarios.filter(
      (horario) =>
        horario.dia !== FinalDeSemana.Sabado &&
        horario.dia !== FinalDeSemana.Domingo
    );
  };

  return (
    <div className={classes.horariosStepContainer}>
      <Typography style={{ color: "#030624", fontSize: "26px", textAlign: "center" }}>{t(AGENDAMENTO_MENTORIA, "Agendar Mentoria")}</Typography>
      <Typography className={classes.panelSubheader}>
        <strong>{mentor.nome}</strong>
        {t(AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_1, " disponibilizou os ")}
        <strong>
          {t(AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_2, "dias e horários ")}
        </strong>
        {t(
          AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_3,
          "abaixo. Selecione o melhor para você!"
        )}
      </Typography>
      <div>
        {isLoading ? (
          <SpinnerSplendis />
        ) : handleSchedule(horarios).length > 0 ? (
          <HorariosCard
            defaultValue={horarioSelected}
            horarios={horarios}
            isLoading={isLoading}
            handleChange={handleChange}
          />
        ) : (
          <p style={{ marginTop: "15%" }}>
            {t(
              AGENDAMENTO_MENTORIA_STEP_HORARIOS_SEM_HORARIOS_DISPONIVEIS_1,
              "Que pena, todos os horários de "
            )}
            <strong>{mentor.nome}</strong>
            {t(
              AGENDAMENTO_MENTORIA_STEP_HORARIOS_SEM_HORARIOS_DISPONIVEIS_2,
              " foram reservados para os próximos 2 meses. Retorne na semana que vem, novos horários serão disponibilizados."
            )}
          </p>
        )}
      </div>
    </div>
  );
}
