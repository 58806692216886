import React from 'react'
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { CustomModal } from '..'
import CancelarSVG from '../../assets/svgs/cancelar.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    EXTRAS_SOURCE,
    EXTRAS_MODAL_SUCESSO_BOTAO_INICIO
} from '../../messages';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        imageDiv: {
            marginBottom: '1rem'
        },
        image:{
            width: '10.8rem',
            height: '9.7rem'
        },
        title: {
            fontWeight: 500,
            fontFamily: 'infraRegular',
            fontStyle: 'normal',
            fontSize: '1.4rem',
            lineHeight: '1.25rem',
            marginBottom: '0.75rem'
        },
        subtitle:{
            fontFamily: 'infraLight',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '0.875rem',
            lineHeight: '140%',
            marginBottom: '1.5rem'
        },
        button: {
            fontFamily: 'Roboto',
            width: '100%',
            fontWeight: 500,
            color:'white',
            backgroundColor: "#003B4A",
            
            '&:hover': {
				backgroundColor: '#0a3039',
			},
        }
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: 'auto',
        marginBottom: '4rem',
        textAlign: 'center',
        width: '20rem',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: 'auto',
        textAlign: 'center',
        width: '70vw',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: 'auto',
        marginTop: '50vh',
        textAlign: 'center',
        width: '60vw',
    },
};

/**
 * Interface utilizada pelo componente ModalCancelamento e que descreve
 * seu métodos e propriedades.
 */
interface ModalCancelamentoProps {
    isOpen: boolean
    handleClose: Function
    onConfirm?: Function
    style?: any 
    disableClose?: Function 
    title?: string 
    subtitle?: string 
    modalProps?: any 
}

/**
 * Componente para ser apresentado na confirmação de algum cancelamento ou fechamento.
 * e.g.: Cancelamento de uma mentoria.
 * 
 * @param {ModalCancelamentoProps} 
 * @returns O modal de cancelamento com todas suas informações pertinentes.
 */
const ModalCancelamento: React.FC<ModalCancelamentoProps> = ({ isOpen, handleClose, onConfirm, children, style, disableClose, title, subtitle, ...modalProps }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation([EXTRAS_SOURCE]);

    /**
     * Verifica que o usuário confirmou o cancelamento, fazendo o redirecionamento para a home após a ação.
     */
    const handleConfirm = () => {
        if(onConfirm) {
            onConfirm();
        }
    
        history.push("/mentoring/home");
    }

    return (
        <CustomModal
            isOpen={isOpen}
            handleClose={handleClose}
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            disableClose={disableClose}
            {...modalProps}
        >
            <div className={classes.imageDiv}>
                <img src={CancelarSVG} className={classes.image} alt="canceladaSucesso"/>
            </div>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.subtitle}>
                {subtitle}
            </div>
            <div>
                <Button className={classes.button} onClick={handleConfirm} variant="contained">
                    {t(EXTRAS_MODAL_SUCESSO_BOTAO_INICIO, 'Início')}
                </Button>
            </div>
        </CustomModal>
    )
}

export default ModalCancelamento;
