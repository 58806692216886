import React, { useContext, useEffect, useState } from "react";
import { Box, Button, createStyles, FormLabel, makeStyles, Theme, Typography } from "@material-ui/core";
import { SpinnerSplendis } from "../../commons";
import { UserContext } from "../../service";
import { Formik } from "formik";
import EmailService from "../../service/EmailService";
import { alertContext } from "../exception-handler/ExceptionHandler";
import {
    FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO,
    FORM_CONTATO_VALIDATIONS_EMAIL_INVALIDO,
    FORM_CONTATO_TITULO,
    FORM_CONTATO_NOME,
    FORM_CONTATO_EMAIL_ENVIADO,
    FORM_CONTATO_MENSAGEM,
    FORM_CONTATO_ASSUNTO,
    FORM_CONTATO_EMAIL_NAO_ENVIADO,
    FORM_CONTATO_EMAIL,
    FORM_CONTATO_ENVIAR
} from "../../messages";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 'auto',
            width: '20rem',

            '@media only screen and (max-width: 455px)': {
                width: '80vw'
            }
        },
        title: {
            textAlign: 'center'
        },
        form: {
            marginBottom: '0.6rem',
            "& > legend": {
                marginTop: '1rem',
                marginBottom: '0.5rem'
            },
            "& > textarea": {
                width: '96%',
                resize: 'none',
                padding: '0.5rem 0.3rem',
                fontFamily: 'inherit'
            }
        },
        button: {
            width: '100%',
            color: '#fff',
            backgroundColor: "#003B4A",

            '&:hover': {
				backgroundColor: '#0a3039',
			}
        },
        spinner: {
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center'
        },
        errorField: {
            borderColor: '#F55043'
        },
        errorText: {
            color: '#F55043',
            fontSize: '0.8rem'
        }
    })
);

/**
 * Interface utilizada pelo componente FormContato e que descreve
 * seu métodos e propriedades.
 */
interface FormContatoProps {
    closePopover: Function
    translation: Function
}

/**
 * Componente responsável pela apresentação e envio do formulário de contato.
 * 
 * @param {FormContatoProps} 
 * @returns O formulário de contato com todos os seus campos e botões.
 */
const FormContato: React.FC<FormContatoProps> = ({ closePopover, translation }) => {
    const classes = useStyle();

    const { 
        userObject, 
        role, 
        // logout 
    } = useContext(UserContext);
    const { showAlert } = useContext(alertContext);

    const emailService = new EmailService();

    const initialState = { nome: userObject.colaborador.nome || '', email: userObject.colaborador.email || '', assunto: '', mensagem: '' };
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (role === 'PUBLIC') {
            initialState.nome = '';
            initialState.email = '';
        }
    });

    /**
     * Função que verfica e valida os campos do formulário.
     * @param values Os diferentes valores dos campos do formulário.
     * @returns Erros, caso algum seja detectado.
     */
    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors['email'] = translation(FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório");
        } else if (!regex.test(values.email)) {
            errors['email'] = translation(FORM_CONTATO_VALIDATIONS_EMAIL_INVALIDO, "Email Inválido");
        }

        if (!values.nome) {
            errors['nome'] = translation(FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório");
        }

        if (!values.assunto) {
            errors['assunto'] = translation(FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório");
        }

        if (!values.mensagem) {
            errors['mensagem'] = translation(FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório");
        }

        return errors;
    };

    /**
     * Função responsável por passar as informações para o backend, o qual faz o envio do email
     * para o usuário e a equipe do Mentoring.
     * 
     * @param values Os diferentes valores dos campos do formulário.
     */
    const submitForm = (values) => {
        setLoading(true);

        emailService.enviarEmailContato(values.nome, values.email, values.assunto, values.mensagem)
            .then(res => {
                setLoading(false);
                closePopover();
                showAlert(202, translation(FORM_CONTATO_EMAIL_ENVIADO, "Email enviado com sucesso."))
            })
            .catch(() => showAlert(400, translation(FORM_CONTATO_EMAIL_NAO_ENVIADO, "Não foi possível enviar email. Por favor, tente novamente.")))

    }

    return (
        <Formik
            initialValues={initialState}
            validate={validate}
            onSubmit={submitForm}
        >
            {(formik) => {
                const {
                    // values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                    isValid,
                    dirty
                } = formik;
                return (
                    <Box component="fieldset" mb={0} className={classes.root} borderColor="transparent">
                        <Typography className={classes.title} variant="h6">{translation(FORM_CONTATO_TITULO, "Contato")}</Typography>

                        <form id="contatoForm" className={classes.form} onSubmit={handleSubmit}>
                            <FormLabel component="legend">{translation(FORM_CONTATO_NOME, "Nome:")}</FormLabel>
                            <textarea
                                defaultValue={userObject.colaborador.nome}
                                name="nome"
                                id="nome"
                                onChange={e => handleChange(e)}
                                onBlur={handleBlur}
                                rows={1}
                                required
                                form="contatoForm"
                                className={(errors.nome && touched.nome) ? classes.errorField : undefined}
                                disabled={userObject.colaborador.nome ? true : false}
                            />
                            {errors.nome && touched.nome && (
                                <span className={classes.errorText}>{errors.nome}</span>
                            )}
                            <br />

                            <FormLabel component="legend">{translation(FORM_CONTATO_EMAIL, "Email:")}</FormLabel>
                            <textarea
                                defaultValue={userObject.colaborador.email}
                                name="email"
                                id="email"
                                onChange={e => handleChange(e)}
                                onBlur={handleBlur}
                                rows={1}
                                required
                                form="contatoForm"
                                className={(errors.email && touched.email) ? classes.errorField : undefined}
                                disabled={userObject.colaborador.nome ? true : false}
                            />
                            {errors.email && touched.email && (
                                <span className={classes.errorText}>{errors.email}</span>
                            )}
                            <br />

                            <FormLabel component="legend">{translation(FORM_CONTATO_ASSUNTO, "Assunto:")}</FormLabel>
                            <textarea
                                defaultValue=""
                                name="assunto"
                                id="assunto"
                                onChange={e => handleChange(e)}
                                onBlur={handleBlur}
                                rows={1}
                                required
                                form="contatoForm"
                                className={(errors.assunto && touched.assunto) ? classes.errorField : undefined}
                            />
                            {errors.assunto && touched.assunto && (
                                <span className={classes.errorText}>{errors.assunto}</span>
                            )}
                            <br />

                            <FormLabel component="legend">{translation(FORM_CONTATO_MENSAGEM, "Mensagem:")}</FormLabel>
                            <textarea
                                defaultValue=""
                                name="mensagem"
                                id="mensagem"
                                onChange={e => handleChange(e)}
                                onBlur={handleBlur}
                                rows={5}
                                required
                                form="contatoForm"
                                className={(errors.mensagem && touched.mensagem) ? classes.errorField : undefined}
                            />
                            {errors.mensagem && touched.mensagem && (
                                <span className={classes.errorText}>{errors.mensagem}</span>
                            )}
                            <br />
                            <br />
                            {
                                loading ?
                                    <div className={classes.spinner}>
                                        <SpinnerSplendis />
                                    </div>
                                    :
                                    <Button type="submit" color="primary" variant="contained" className={classes.button}
                                        disabled={!(dirty && isValid)}>{translation(FORM_CONTATO_ENVIAR, "Enviar")}</Button>
                            }
                        </form>
                    </Box>
                );
            }}
        </Formik>
    );
}

export default FormContato;