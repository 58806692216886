import React, { useContext } from 'react';
import AppBarComponent from './components/app-bar/AppBarComponent';
import { useLocation, Redirect } from 'react-router-dom';

import { routesFilteredByRoles } from '../../routes';
import { alertContext, BotaoGlobalContato } from '../../components';
import { SessionStorageService, UserContext } from '../../service';
import { useTranslation } from 'react-i18next';
import { 
	PRIVATE_APP_SOURCE,
	PRIVATE_APP_TITULO_CADASTRO_MENTORIA,
	PRIVATE_APP_TITULO_CADASTRO_MENTOR,
	PRIVATE_APP_TITULO_GERENCIAR_MENTORES,
	PRIVATE_APP_TITULO_GERENCIAR_MENTORIAS,
	PRIVATE_APP_TITULO_GERENCIAR_CATEGORIAS,
	PRIVATE_APP_TITULO_CADASTRO_CATEGORIAS,
	PRIVATE_APP_LOGIN_NAO_EFETUADO
} from '../../messages';

/**
 * Renderiza a appbar de toda a aplicação.
 * 
 * @returns A appbar da aplicação.
 */
export default function PrivateApp() {

	// Hook utilizado no processo de tradução da aplicação.
	const { t } = useTranslation([PRIVATE_APP_SOURCE]);

	/**
	 * Renderiza vários títulos de partes da aplicação com base na rota acessada.
	 * 
	 * @param location A localização conforme a rota da parte da aplicação acessada.
	 * @returns O título da parte específica da aplicação.
	 */
	function getTitulo(location) {
		switch (location) {
			case "/mentoring/mentorado":
				return "Mentoring - " + t(PRIVATE_APP_TITULO_CADASTRO_MENTORIA, "Cadastro de Mentoria");

			case "/mentoring/mentor":
				return "Mentoring - " + t(PRIVATE_APP_TITULO_CADASTRO_MENTOR, "Cadastro de Mentor");

			case "/mentoring/home":
				return "Mentoring - Home";

			case "/mentoring/mentordashboard":
				return "Mentoring - " + t(PRIVATE_APP_TITULO_GERENCIAR_MENTORES, "Gerenciar Mentores");

			case "/mentoring/mentoriadashboard":
				return "Mentoring - " + t(PRIVATE_APP_TITULO_GERENCIAR_MENTORIAS, "Gerenciar Mentorias");

			case "/mentoring/categoriadashboard":
				return "Mentoring - " + t(PRIVATE_APP_TITULO_GERENCIAR_CATEGORIAS, "Gerenciar Categorias");

			case "/mentoring/categorias":
				
				return "Mentoring - " + t(PRIVATE_APP_TITULO_CADASTRO_CATEGORIAS, "Cadastro de Categorias");

			default:
				return "Mentoring"
		}
	}

	const {session, role } = useContext(UserContext);
	const { showAlert } = useContext(alertContext);

	/**
	 * Redireciona a aplicação para a tela de login caso algum erro ocorra.
	 * 
	 * @returns O redirecionamento para a tela de login.
	 */
	const redirectToLoginAndCleanCache = () => {
		SessionStorageService.clearStorage();
		showAlert(400, t(PRIVATE_APP_LOGIN_NAO_EFETUADO, 'Não conseguimos completar seu login. Por favor, tente novamente.'))
		return <Redirect to="/login"/>
	}

	const location = useLocation().pathname;

	return (
		<React.Fragment>
			{	
				session.roles? <React.Fragment>
					<AppBarComponent titulo={getTitulo(location)}/>
					{routesFilteredByRoles(role, session)}
					<BotaoGlobalContato />
				</React.Fragment>
				:
				redirectToLoginAndCleanCache()
			}
			{ /*<MentoringRoutes></MentoringRoutes>*/}
		</React.Fragment>
	);
}
