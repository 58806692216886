/**
 * Service com métodos que auxiliam nos processos relacionados ao armazenamento de sessão.
 */
export default class SessionStorageService {

    /**
     * Configura/armazena a sessão do usuário.
     * 
     * @param key A chave do item a ser criado.
     * @param value O valor a ser atribuído a chave.
     * @returns O objeto com os valores armazenados.
     */
    static setStorge(key: string, value: string) {
        return sessionStorage.setItem(key, value);
    }

    /**
     * Obtém um objeto armazenado.
     * 
     * @param key A chave para recuperar o objeto.
     * @returns O objeto com os valores armazenados
     */
    static getStorage(key: string) {
        return sessionStorage.getItem(key);
    }

    /**
     * Limpa todas as chaves do armazenamento.
     */
    static clearStorage() {
        sessionStorage.clear()
    }

}