import { WithTranslation } from "react-i18next";
import { MentorService, RelatorioExcelService } from "../../../../../service";

/**
 * Props que RhMentorDashBoard espera receber.
 */
export default class RhMentorDashBoardProps implements WithTranslation{
    msg:any = ''
    mentorService: MentorService = new MentorService()
    relatorioExcelService: RelatorioExcelService = new RelatorioExcelService()
    tReady: any
    // Funções de tradução
    t: any
    i18n: any
}