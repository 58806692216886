import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Paper,
  Typography,
} from "@material-ui/core";

import { AvaliacaoComponent } from "../../../../../../components";
import { ModalSucesso } from "../../../../../../commons";
import { limitString } from "../../../../../../utils/FormUtils";
import { MentoriaPendente } from "../../../../../../models/MentoriaPendente";
import {
  GERENCIAMENTO_MENTORIAS_AVALIACAO_SUBTITULO,
  GERENCIAMENTO_MENTORIAS_AVALIACAO_TITULO,
  GERENCIAMENTO_MENTORIAS_SOURCE,
} from "../../../../../../messages";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      boxShadow: "2px 3px #ccc",
      display: "inline-block",
      height: 90,
      marginLeft: 5,
      marginRight: 5,
      width: 300,
    },
    actionArea: {
      display: "flex",
      height: "100%",
      width: "100%",
    },
    wrapper: {
      display: "flex",
      height: "100%",
      justifyContent: "space-between",
      width: "100%",
    },
    mediaWrapper: {
      height: 90,
      width: "40%",
    },
    media: {
      border: "1px solid #FFF",
      borderRadius: "50%",
      height: "5rem",
      width: "5rem",
    },
    contentWrapper: {
      height: "100%",
      padding: 0,
      textAlign: "left",
      width: "60%",
    },
    content: {
      height: "100%",
      margin: "0 auto",
      width: "90%",
    },
    nomeMentorContainer: {
      whiteSpace: "break-spaces",
    },
    category: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      fontWeight: 700,
    },
    dateAndHour: {
      fontFamily: "InfraRegular",
      fontSize: ".65rem",
      fontStyle: "normal",
      fontWeight: 400,
    },
    mentor: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
    },
  })
);

/**
 * Interface com as propriedades que são necessárias no componente
 * MentoriaCard abaixo.
 *
 */
interface MentoriaCardProps {
  mentoria: MentoriaPendente;
  onAvaliacaoSucessoCallback: Function;
  style?: any;
}

/**
 * Componente que exibe as informações relativas a uma mentoria (e.g.: nome da mentoria,
 * foto do mentor, dia e horário da mentoria)
 *
 * @param mentoria A mentoria a ser exibida com suas informações.
 *
 * @param onAvaliacaoSucessoCallback Função a ser retornada após a avaliação ser realizada com sucesso.
 */
const MentoriaCard: React.FC<MentoriaCardProps> = ({
  mentoria,
  onAvaliacaoSucessoCallback,
}) => {
  const classes = useStyles();

  /**
   * Hook e função utilizados na tradução de textos.
   */
  const { t } = useTranslation([GERENCIAMENTO_MENTORIAS_SOURCE]);

  const [openModalAvaliacao, setOpenModalAvaliacao] =
    React.useState<boolean>(false);
  const [openModalAvaliacaoSucesso, setOpenModalAvaliacaoSucesso] =
    React.useState<boolean>(false);

  /**
   * Abre o modal de avaliação de mentoria configurando
   * o state de abertura de modal de avaliação.
   */
  const openAvaliacaoModal = () => {
    setOpenModalAvaliacao(true);
  };

  /**
   * Fecha o modal de avaliação de mentoria configurando
   * o state de abertura de modal de avaliação.
   */
  const closeAvaliacaoModal = () => {
    setOpenModalAvaliacao(false);
  };

  /**
   * Abre o modal de sucesso de avaliação de mentoria configurando
   * o state de abertura de modal de sucesso de avaliação.
   */
  const openAvaliacaoSucessoModal = () => {
    setOpenModalAvaliacaoSucesso(true);
  };

  /**
   * Fecha o modal de sucesso de avaliação de mentoria configurando
   * o state de abertura de modal de sucesso de avaliação.
   */
  const closeAvaliacaoSucessoModal = () => {
    setOpenModalAvaliacaoSucesso(false);

    //Ao fechar modal de sucesso, atualiza lista de mentorias pendentes
    onAvaliacaoSucessoCallback();
  };

  /**
   * Gerencia os modais de avaliação e sucesso de avaliação.
   */
  const handleAvaliacaoSucesso = () => {
    closeAvaliacaoModal();
    openAvaliacaoSucessoModal();
  };

  /**
   * Renderiza o modal de mensagem de sucesso de avaliação.
   *
   * @returns O modal de mensagem de sucesso de avaliação.
   */
  const modalMensagemSucesso = () => {
    return (
      <ModalSucesso
        isOpen={openModalAvaliacaoSucesso}
        handleClose={closeAvaliacaoSucessoModal}
        title={t(
          GERENCIAMENTO_MENTORIAS_AVALIACAO_TITULO,
          "Obrigado pela contribuição!"
        )}
        subtitle={t(
          GERENCIAMENTO_MENTORIAS_AVALIACAO_SUBTITULO,
          "Sua avaliação foi enviada para o RH."
        )}
      />
    );
  };

  /**
   * Renderiza o modal de avaliação de mentoria.
   *
   * @returns O modal de avaliação de mentoria.
   */
  const avaliacaoModal = () => {
    return (
      <AvaliacaoComponent
        isOpen={openModalAvaliacao}
        handleClose={closeAvaliacaoModal}
        mentoria={mentoria}
        submitted={handleAvaliacaoSucesso}
      />
    );
  };

  /**
   * Renderiza os modais do componente.
   *
   * @returns Um fragment que renderiza os modais do componente.
   */
  const modais = () => {
    return (
      <React.Fragment>
        {avaliacaoModal()}
        {modalMensagemSucesso()}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {modais()}
      <Card className={classes.root} component={Paper}>
        <CardActionArea
          onClick={() => openAvaliacaoModal()}
          className={classes.actionArea}
        >
          <div className={classes.mediaWrapper}>
            <CardMedia className={classes.media} image={mentoria.foto} />
          </div>
          <CardContent className={classes.contentWrapper}>
            <div className={classes.content}>
              <div>
                <Typography
                  className={classes.category}
                  variant="overline"
                  align="center"
                >
                  {limitString(mentoria.categoria, 18)}
                </Typography>
              </div>
              <div>
                <Typography
                  className={classes.dateAndHour}
                  variant="caption"
                  align="left"
                >
                  {mentoria.hora} - {mentoria.data}
                </Typography>
              </div>
              <div className={classes.nomeMentorContainer}>
                <Typography
                  className={classes.mentor}
                  variant="subtitle2"
                  align="left"
                >
                  {limitString(mentoria.mentor, 20)}
                </Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
};

export default MentoriaCard;
