import React from "react";
import { Button, createStyles, ListItem, ListItemText, makeStyles, Popover } from "@material-ui/core";
import authProvider from "../../security/authProvider";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    MENU_DESLIZANTE_SOURCE,
    MENU_DESLIZANTE_MENU,
    MENU_DESLIZANTE_OPCAO_CADASTRO_DE_CATEGORIAS,
    MENU_DESLIZANTE_OPCAO_GERENCIAR_CATEGORIAS,
    MENU_DESLIZANTE_OPCAO_GERENCIAR_COLABORADORES,
    MENU_DESLIZANTE_OPCAO_GERENCIAR_MENTORES,
    MENU_DESLIZANTE_OPCAO_GERENCIAR_MENTORIAS,
    MENU_DESLIZANTE_OPCAO_VISUALIZAR_AVALIACOES
} from "../../messages";

const useStyles = makeStyles(() =>
    createStyles({
        menuButton: {
            width: '4.813rem',
            height: '2.75rem',

            '@media only screen and (max-width: 414px)': {
                fontSize: '.7rem',
                width: '4rem',
                height: '2rem',
            }
        }
    })
)

/**
 * Componente reponsável por apresentar o menu lateral nas telas de ADMIN,
 * assim como redirecionar para outras telas.
 * Segue o padrão de dropdown de abrir e fechar.
 */
const MenuRh = () => {
    const classes = useStyles();

    const [popoverAnchor, setPopoverAnchor] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);

    // Hook que realiza a tradução dos textos do menu.
    const { t } = useTranslation([MENU_DESLIZANTE_SOURCE])

    /**
     * Lida com a abertura do menu de opções no canto superior direito.
     * 
     * @param event O evento que dispara a abertura do popover do menu.
     */
    const handleOpenPopover = (event) => {
        setPopoverAnchor(event.currentTarget);
        setOpenMenu(!openMenu);
    };

    /**
     * Lida com o fechamento do menu de opções no canto superior direito.
     */
    const handleClosePopover = () => {
        setPopoverAnchor(null);
        setOpenMenu(false);
    };

    return (
        <div>
            <Button
                color="primary"
                variant="outlined"
                className={classes.menuButton}
                aria-controls="simple-popover"
                onClick={handleOpenPopover}
            >
                {t(MENU_DESLIZANTE_MENU, 'Menu')}
            </Button>
            <Popover
                id="simple-popover"
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={handleClosePopover}
                marginThreshold={80}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}

            >
                <React.Fragment>
                    <ListItem
                        button
                        component={NavLink}
                        to="/mentoring/home"
                        activeClassName="Mui-selected"
                        exact
                        disabled={!authProvider.checkAuth()}
                    >
                        <ListItemText primary={"Home"} />
                    </ListItem>

                    <ListItem
                        button
                        component={NavLink}
                        to="/mentoring/categorias"
                        activeClassName="Mui-selected"
                        exact
                        disabled={!authProvider.checkAuth()}
                    >
                        <ListItemText primary={t(MENU_DESLIZANTE_OPCAO_CADASTRO_DE_CATEGORIAS, "Cadastrar Categorias")} />
                    </ListItem>

                    <ListItem
                        button
                        component={NavLink}
                        to="/mentoring/categoriadashboard"
                        disabled={!authProvider.checkAuth()}
                    >
                        <ListItemText primary={t(MENU_DESLIZANTE_OPCAO_GERENCIAR_CATEGORIAS, "Gerenciar Categorias")} />
                    </ListItem>

                    <ListItem
                        button
                        component={NavLink}
                        to="/mentoring/mentordashboard"
                        disabled={!authProvider.checkAuth()}
                    >
                        <ListItemText primary={t(MENU_DESLIZANTE_OPCAO_GERENCIAR_MENTORES, "Gerenciar Mentores")} />
                    </ListItem>

                    <ListItem
                        button
                        component={NavLink}
                        to="/mentoring/mentoriadashboard"
                        disabled={!authProvider.checkAuth()}
                    >
                        <ListItemText primary={t(MENU_DESLIZANTE_OPCAO_GERENCIAR_MENTORIAS, "Gerenciar Mentorias")} />
                    </ListItem>

                    <ListItem
                        button
                        component={NavLink}
                        to="/mentoring/avaliacoesdashboard"
                        disabled={!authProvider.checkAuth()}
                    >
                        <ListItemText primary={t(MENU_DESLIZANTE_OPCAO_VISUALIZAR_AVALIACOES, "Visualizar avaliações")} />
                    </ListItem>

                    <ListItem
                        button
                        component={NavLink}
                        to="/mentoring/colaboradoresdashboard"
                        disabled={!authProvider.checkAuth()}
                    >
                        <ListItemText primary={t(MENU_DESLIZANTE_OPCAO_GERENCIAR_COLABORADORES, "Gerenciar colaboradores")} />
                    </ListItem>
                </React.Fragment>
            </Popover>
        </div>
    );
};

export default MenuRh;
