import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LoginBackground from "../../../images/BGPT.png";
import BGEN from "../../../images/BGEN.png";
import BGES from "../../../images/BGES.png";

export const Background = {
    pt: LoginBackground,
    en:BGEN,
    es:BGES
};