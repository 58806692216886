import React from 'react';

import ErrorIcon from '@material-ui/icons/Error';
import { Container, Paper } from '@material-ui/core';

import {
	NOT_FOUND_SOURCE,
	NOT_FOUND_NETWORK_ERROR,
	NOT_FOUND_SERVER_OFFLINE,
} from '../../../messages';
import { useTranslation } from 'react-i18next';

/**
 * Renderiza a exibição de mensagens de erro da aplicação. 
 * 
 * @param props Propriedades necessárias para a correta exibição
 * 				das mensagens de erro.
 * @returns As mensagens de erro.
 */
export default function NotFoundComponent(props) {

	const { t } = useTranslation([NOT_FOUND_SOURCE]);

	return (
		<Container>
			<Paper style={{ marginTop: '20vh' }}>
				<ErrorIcon color="secondary" style={{ width: '20vh', height: '20vh' }}>
				</ErrorIcon>
				<h1>{!props.response ? t(NOT_FOUND_NETWORK_ERROR, 'Network Error') : props.response}</h1>
				<h2>{!props.msg ? t(NOT_FOUND_SERVER_OFFLINE, 'O servidor está offline') : props.msg}</h2>
			</Paper>
		</Container>
	)

}
