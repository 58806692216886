import React from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
        opacity: '0.9',
    }
  }),
);

/**
 * Componente que gera um alerta de informações.
 * 
 * @param props Propriedades que configuram o formato, cor etc do alerta de informações.
 * 
 * @returns O alerta com informações.
 */
const InfoMessage = (props) => {
    const classes = useStyles();

    const { open, handleClose, severity } = props;

    return <Snackbar
        open={ open }
        onClose={ handleClose }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert className={classes.alert} onClose={ handleClose } severity={severity}>
            { props.children }
        </Alert>
    </Snackbar>
}

export default InfoMessage