import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";
import { Mentoria } from "../models/Mentoria";
import { Disponibilidade } from "../models/Disponibilidade";
import SessionStorageService from "./SessionStorageService";

/**
 * Service com métodos que auxiliam nos processos relacionados às mentorias.
 */
export default class MentoriaService extends HttpClientService{
    constructor(){
        super('mentoria');
    }

    /**
     * Cadastra/salva uma mentoria na base de dados.
     * 
     * @param mentoria A mentoria a ser salva.
     * @returns Promise com o resultado da requisição.
     */
    salvar( mentoria: Mentoria ):Promise<any>{  
        var data: any;

        let userEmail = SessionStorageService.getStorage('usuario');
        mentoria.mentorado.email = userEmail? userEmail : '';

        data = {
            //nome:  mentoria.nome, 
            //email: mentoria.email, 
            mentorId: mentoria.mentor.id, 
            disponibilidade: mentoria.disponibilidade,
            mentorado: mentoria.mentorado,
            categoriaId: mentoria.categoria.id,
        }

        return super.post(data).then(res => {
            return res;
        }).catch((err) => {
            return Promise.reject(err);
        })
    }

    /**
     * Obtém todas as mentorias.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getMentorias(): Promise<Array<Mentoria>> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(res => {
            return res.data;
        });
    }

    /**
     * Remove uma mentoria da base de dados.
     * 
     * @param id O id da mentoria.
     * @returns Promise com o resultado da requisição.
     */
    delete(id: any): Promise<any> {
        return super.delete(id).then(() => {
        });
    }
    
    /**
     * Edita/altera uma mentoria.
     * 
     * @param id O id da mentoria a ser editada.
     * @param mentoria A mentoria a ser editada.
     * @returns Promise com o resultado da requisição.
     */
    editar( id, mentoria: any ): Promise<any> {
        //var data: any;
        
        /*data = {id: id, 
            nome:  mentoria.nome, 
            email: mentoria.email,
            mentor: mentoria.mentor,
            mentorado: mentoria.mentorado,
            disponibilidade: mentoria.disponibilidade,
            status: mentoria.status,
            categoria: mentoria.categoria}*/

        const configRequest: MentoringConfigRequest = {
            url: this.getURL()+'/'+ id,
            method: AxiosMethodsName.put,
            data: mentoria,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);

        /*return super.put(mentoria, id).then(res => {
            return res;
        });*/
    }

    /**
     * Realiza um reagendamento de mentoria.
     * 
     * @param id O id da mentoria a ser reagendada.
     * @param mentoria A mentoria a ser reagendada.
     * @returns Promise com o resultado da requisição.
     */
    reagendar ( id, mentoria ): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: this.getURL()+'/'+ id + '/reagendar',
            method: AxiosMethodsName.put,
            data: mentoria,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Cancela um agendamento de uma mentoria.
     * 
     * @param id O id da mentoria a ser cancelada.
     * @returns Promise com o resultado da requisição.
     */
    cancelar( id ): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/${id}/cancelar`,
            method: AxiosMethodsName.put,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);

        /*return super.put(mentoria, id).then(res => {
            return res;
        });*/
    }

    /**
     * Obtém todas as disponibilidades de forma geral.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getDisponibilidades(): Promise<Array<Disponibilidade>> {
        return super.get<Array<Disponibilidade>>().then(res => {
            return res.data;
        });
    }

    /**
     * Obtém todas as disponibilidades de um mentor específico.
     * 
     * @param mentorId O id do mentor.
     * @param timeZone O timezone do mentor.
     * @returns Promise com o resultado da requisição.
     */
    getDisponibilidadesMentor(mentorId, timeZone): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.formatAvalaibilityURL(this.getURL())}/${mentorId}/gerar-disponibilidades`,
            method: AxiosMethodsName.get,
            params: {
                timeZone: `${timeZone}`
            },
            handleCredentials: true
        }
    
        return super.executeMethod(configRequest);
    }

    /**
     * Obtém todas as mentorias que já ocorreram de um determiando mentor.
     * 
     * @param mentorId O id do mentor.
     * @returns Promise com o resultado da requisição.
     */
    getMentoriasPassadasMentor(mentorId?: number): Promise<any>{
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/mentor/${mentorId}/passadas`,
            method: AxiosMethodsName.get,
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém todas as mentorias que irão ocorrer de um determinado mentor.
     * 
     * @param mentorId O id do mentor.
     * @returns Promise com o resultado da requisição.
     */
    getMentoriasFuturasMentor(mentorId?: number): Promise<any>{
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/mentor/${mentorId}/futuras`,
            method: AxiosMethodsName.get,
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém todas as mentorias que irão ocorrer de um determinado mentorado.
     * 
     * @param mentoradoId O id do mentorado.
     * @returns Promise com o resultado da requisição.
     */
    getMentoriasFuturasMentorado(mentoradoId?: number): Promise<any>{
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/mentorado/${mentoradoId}/futuras`,
            method: AxiosMethodsName.get,
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém todas as mentorias que já ocorreram de um determinado mentorado.
     * 
     * @param mentoradoId O id do mentorado.
     * @returns Promise com o resultado da requisição.
     */
    getMentoriasPassadasMentorado(mentoradoId?: number): Promise<any>{
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/mentorado/${mentoradoId}/passadas`,
            method: AxiosMethodsName.get,
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém todas as mentorias que ainda não foram avaliadas por um determinado mentorado.
     * 
     * @param mentoradoId O id do mentorado.
     * @returns Promise com o resultado da requisição.
     */
    getMentoriasPendentesAvaliacaoMentorado(mentoradoId?: number): Promise<any>{
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/mentorado/${mentoradoId}/pendentes`,
            method: AxiosMethodsName.get
        }

        return super.executeMethod(configRequest);
    }
    
    /**
     * Retorna uma página e os dados da paginação do dashboard de mentorias.
     * 
     * @param page A página atual.
     * @param pageSize O número de itens por página.
     * @returns Promise com o resultado da requisição.
     */
    getMentoriasPagination(page, pageSize): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/dashboard?numeroPagina=${page}&itensPorPagina=${pageSize}`,
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Edita/altera os critérios de paginação no perfil do RH.
     * 
     * @param id O id da mentoria.
     * @param mentoria A mentoria a ser obtida.
     * @returns Promise com o resultado da requisição.
     */
    editarPagination(id, mentoria): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/${id}/edicao-rh`,
            method: AxiosMethodsName.put,
            handleCredentials: true,
            data: mentoria
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Realiza buscas nos dashboards do perfil de RH.
     * 
     * @param page A paǵina atual.
     * @param pageSize O número de itens por página.
     * @param search O termo da busca a ser realizada.
     * @returns Promise com o resultado da requisição.
     */
    dashboardSearch(page: number, pageSize: number, search?: any){
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/dashboard`,
            method: AxiosMethodsName.get,
            handleCredentials: true,
            params: {
                numeroPagina: page,
                itensPorPagina: pageSize,
                ...search
            }
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Reestrutura a url de disponibilidade.
     * 
     * @param url A url a ser formatada.
     * @returns A nova url editada.
     */
    formatAvalaibilityURL = (url: string) => {
        return url.split('/').slice(0, 4).reduce((formattedURL, currentParam) => {
            return formattedURL + currentParam + '/';
        }, '') + 'disponibilidade';
    }
}
