// @ts-nocheck
import React, { useEffect, useState } from "react";
import Compress from "compress.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomModal, FileUpload, SpinnerSplendis } from "../../commons";
import { Mentor, Categoria, Idioma } from "../../models";
import {
  FormControl,
  TextField,
  MenuItem,
  Button,
  Typography,
  makeStyles,
  createStyles,
  withStyles,
  capitalize,
  Grid,
} from "@material-ui/core";
import {
  allStatusMentor,
  countriesListEnglish,
  countriesListPortuguese,
  countriesListSpanish,
  estadosBrasilToSelectField,
  mapStatusMentor,
  mapWeekDayToOrdinal,
} from "../../utils/FormUtils";
import { CategoriaService, IdiomaService } from "../../service";
import DisplayDisponibilidade from "./components/display-disponibilidades/DisplayDisponibilidades";
import { useTranslation } from "react-i18next";
import { FormikSelectField } from "formik-material-fields";
import {
  ATUALIZACAO_MENTOR_MODAL_ATUALIZAR,
  ATUALIZACAO_MENTOR_MODAL_CARGO,
  ATUALIZACAO_MENTOR_MODAL_CATEGORIAS,
  ATUALIZACAO_MENTOR_MODAL_DESCRICAO,
  ATUALIZACAO_MENTOR_MODAL_IDIOMAS,
  ATUALIZACAO_MENTOR_MODAL_NOME,
  ATUALIZACAO_MENTOR_MODAL_SOURCE,
  ATUALIZACAO_MENTOR_MODAL_TIMEZONE,
  ATUALIZACAO_MENTOR_MODAL_ALERT_SUCESSO,
  ATUALIZACAO_MENTOR_MODAL_MIN_IDIOMAS,
  ATUALIZACAO_MENTOR_MODAL_MAX_IDIOMAS,
  ATUALIZACAO_MENTOR_MODAL_DISPONIBILIDADE,
  ATUALIZACAO_MENTOR_CONFIRMACAO_TITULO,
  ATUALIZACAO_MENTOR_CONFIRMACAO_SUBTITULO,
  ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_NAO,
  ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_SIM,
  ATUALIZACAO_MENTOR_CAMPO_PAIS_PLACEHOLDER,
  ATUALIZACAO_MENTOR_CAMPO_UF_PLACEHOLDER,
  ATUALIZACAO_MENTOR_MODAL_EMAIL,
} from "../../messages";
import { Form, Formik } from "formik";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (max-width: 1024px)": {
        maxWidth: "100%",
        maxHeight: "100%",
        display: "inline-block",
      },
    },

    root: {
      height: "70vh",
      overflowX: "hidden",
      overflowY: "scroll",
      padding: "15px",
    },

    descricao: {
      display: "flex",
      "@media (max-width: 1024px)": {
        maxWidth: "100%",
      },
    },

    confirmarText: {
      alignItems: "center",
      display: "flex",
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      height: 50,
      marginLeft: ".4rem",
      marginTop: ".5rem",
      textAlign: "left",
    },

    confirmarBtns: {
      backgroundColor: "#003B4A",
      fontFamily: "InfraRegular",
      fontSize: ".8rem",
      fontStyle: "normal",
      height: "5vh",
      marginTop: "1rem",

      "&:hover": {
        opacity: ".8",
      },

      "&:disabled": {
        width: "auto",
      },

      "@media (max-width: 1024px)": {
        fontSize: "x-small",
        margin: 10,
      },
    },

    confirmarBtnsUpdate: {
      backgroundColor: "#003B4A",
      fontFamily: "InfraRegular",
      fontSize: ".8rem",
      fontStyle: "normal",
      height: "5vh",
      marginTop: "1rem",

      "&:hover": {
        backgroundColor: '#0a3039',
      },

      "&:disabled": {
        width: "auto",
      },

      "@media (max-width: 1024px)": {
        fontSize: "x-small",
        margin: 10,
      },
    },

    changeStatusDiv: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: "25vw",

      "@media (max-width: 1024px)": {
        margin: 5,
        overflow: "auto",
      },
    },

    changeStatusSelect: {
      paddingTop: "8px",
      paddingRight: "15px",

      "& > div.MuiSelect-outlined": {
        backgroundColor: "transparent",
      },
    },

    spacing: {
      padding: "6px",
    },

    firstFieldsContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: "6px",
    },

    updateButton: {
      color: "white",
      backgroundColor: "#003B4A",

      '&:hover': {
        backgroundColor: '#0a3039',
      },
    },

    actionButtonsInnerContainer: {
      display: "flex",
      justifyContent: "space-around",
      margin: ".5rem .5rem",
      maxWidth: "300px",
    },

    actionButtonsOuterContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },

    updateStatusPhotoContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      marginTop: "-.8rem",
    },

    updatePhoto: {
      display: "block",
      justifyContent: "center",
      margin: "0 auto",
    },

    updateSpinnerContainer: {
      position: "relative",
      textAlign: "center",
      zIndex: 100,
    },

    updateSpinner: {
      zIndex: 100,
    },

    firstFields: {
      width: "49%",
    },

    updateActionContainer: {
      width: "90%",
      display: "block",
      margin: "1rem auto",
    },

    errorMessageContainer: {
      left: "50%",
      marginLeft: "-10.5rem",
      position: "absolute",
    },

    timezoneField: {
      display: "flex",
    },

    timezoneFieldSelect: {
      "& > div.MuiSelect-outlined": {
        backgroundColor: "transparent",
      },
    },

    confirmationModalQuestion: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
    },

    confirmationModalSubtitle: {
      fontFamily: "InfraRegular",
      fontSize: ".9rem",
      fontStyle: "normal",
    },

    countryStateContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "5px",
      padding: "2px 5px",
    },

    countryField: {
      float: "left",
      marginTop: ".6rem",
      width: "70%",

      "& > div": {
        height: "2.7rem",
      },
    },

    stateField: {
      float: "right",
      marginTop: ".6rem",
      width: "25%",

      "& > div": {
        height: "2.7rem",
      },
    },
  })
);

// PASSA COMO PROPS PRO CUSTOM MODAL
const paperStyle = {
  paper: {
    height: "80vh",
    "@media (max-width: 1024px)": {
      heigth: "auto",
      maxWidth: "100%",
    },
  },
  closeDiv: {
    "@media (max-width: 1024px)": {
      justifyContent: "flex-start",
    },
  },
};

// PARA ALTERAR O TEXTFIELD
const GlobalCss = withStyles({
  "@global": {
    ".MuiOutlinedInput-input": {
      "@media (max-width: 1024px)": {
        padding: "18px 7px",
        fontSize: "0.7rem",
      },
    },
  },
})(() => null);

/**
 * Interface utilizada pelo componente AtualizacaoMentorModal e que descreve
 * seu métodos e propriedades.
 */
interface AtualizacaoMentorModalProps {
  mentor: any;
  isOpen: boolean;
  handleClose: () => void;
  alertCallback: Function;
  updateMentor: Function;
  mentorMode?: any;
}

/**
 * Componente para edição da entidade Mentor.
 *
 * @param {AtualizacaoMentorModal}
 * @returns
 */
const AtualizacaoMentorModal: React.FC<AtualizacaoMentorModalProps> = ({
  mentor,
  isOpen,
  handleClose,
  alertCallback,
  updateMentor,
  mentorMode
}) => {
  // Biblioteca externa para receber países e fusos horários
  const ct = require("countries-and-timezones");

  // Biblioteca externa para realizar a compressão de arquivos
  const compress = new Compress();

  // Hook de estilização
  const classes = useStyles();

  // Hook de tradução
  const { t, i18n } = useTranslation([ATUALIZACAO_MENTOR_MODAL_SOURCE]);

  const categoriaService = new CategoriaService();
  const idiomaService = new IdiomaService();

  // Objeto para possuir os novos dados do mentor
  const [formObject, setFormObject] = useState<Mentor>(new Mentor());
  const [isConfirmacaoModalOpen, setIsConfirmacaoModalOpen] = useState<boolean>(false);
  const [nomeMentor, setNomeMentor] = useState("");
  const [cargoMentor, setCargoMentor] = useState("");
  const [descricaoMentor, setDescricaoMentor] = useState("");
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [idiomas, setIdiomas] = useState<Idioma[]>([]);
  const [numberOfSelectedCategorias, setNumberOfSelectedCategorias] = useState<number>(formObject.categorias.length);
  const [numberOfSelectedIdiomas, setNumberOfSelectedIdiomas] = useState<number>(mentor.idiomas.length);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [, setEncodedPhoto] = useState<string>(mentor.foto);
  const [disponibilidadesValidas, setDisponibilidadesValidas] = React.useState<boolean>(true);
  const [duplicateDisponibilidadesError, setDuplicateDisponibilidadesError] = React.useState<boolean>(false);

  const MAX_NUMBER_OF_DISPONIBILIDADES = 2;

  /**
   * Ao abrir o modal atualiza os states com os dados vindos do mentor como props.
   */
  useEffect(() => {
    setFormObject(mentor);
    setNomeMentor(mentor.nome);
    setCargoMentor(mentor.cargo);
    setDescricaoMentor(mentor.descricao);
    setNumberOfSelectedCategorias(mentor.categorias.length);
    setNumberOfSelectedIdiomas(mentor.idiomas.length);
    checkDuplicidadeDisponibilidades(mentor.disponibilidades);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  /**
   * Obtém todas as categorias e configura o array 'categorias'.
   */
  useEffect(() => {
    categoriaService.getCategorias().then((categorias) => {
      setCategorias(categorias);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Obtém todos os idiomas da aplicação e configura o array 'idiomas'.
   */
  useEffect(() => {
    idiomaService.getIdiomas().then((res) => {
      setIdiomas(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Função que faz a compressão da foto de perfil do mentor.
   *
   * @param photo A foto de perfil do mentor.
   * @returns A foto de perfil do mentor comprimida.
   */
  async function compressImage(photo: string) {
    const base64 = await fetch(photo);
    let blob = await base64.blob();

    let compressedFile = await compress.compress([blob], {
      size: 1,
      quality: 1,
      maxWidth: 300,
      maxHeight: 300,
      resize: true,
    });

    setEncodedPhoto("data:image/png;base64, " + compressedFile[0].data);
    return "data:image/png;base64," + compressedFile[0].data;
  }

  /**
   * Lida com a atualização dos states a partir do nome e valor
   * vindos do event e.
   *
   * @param e Evento que dispara a mudança dos states.
   */
  const handleChange = (e: React.ChangeEvent<any>) => {
    let oldFormObject = formObject;
    oldFormObject[e.target.name] = e.target.value;

    if (e.target.name === "nome") {
      setNomeMentor(e.target.value);
    }

    if (e.target.name === "cargo") {
      setCargoMentor(e.target.value);
    }

    if (e.target.name === "descricao") {
      setDescricaoMentor(e.target.value);
    }

    if (e.target.name === "pais") {
      searchCountry(e.target.value);
    }

    setFormObject(oldFormObject);
  };

  /**
   * Busca o país com base no código de três letras do mesmo(e.g. BRA).
   *
   * @param countryCode O código de três letras do país.
   */
  const searchCountry = (countryCode: string) => {
    countriesListEnglish().forEach((country) => {
      if (country.code === countryCode) {
        searchTimezone(country.name);
      }
    });
  };

  /**
   * Busca o timezone de um determinado país.
   *
   * @param countryName O nome do país o qual se deseja buscar o seu timezone.
   */
  const searchTimezone = (countryName: string) => {
    const countries: { id: string; name: string; timezones: Array<string> } =
      ct.getAllCountries();

    Object.values(countries).forEach((country) => {
      if (country["name"] === countryName) {
        getTimezoneInfo(country["timezones"][country["timezones"].length - 1]);
      }
    });
  };

  /**
   * Obtém informações relativas a um timezone específico.
   *
   * @param timezone O timezone o qual será buscada as informações.
   */
  const getTimezoneInfo = (timezone: string) => {
    const timezones: {
      aliasOf: string;
      country: string;
      dstOffset: number;
      dstOffsetStr: string;
      name: string;
      utcOffset: number;
      utcOffsetStr: string;
    } = ct.getAllTimezones();

    Object.values(timezones).forEach((timezoneInfo) => {
      if (timezoneInfo["name"] === timezone) {
        formObject.timeZone = timezone;
        setFormObject(formObject);
      }
    });
  };

  /**
   * Obtém todos os timezones disponíveis na aplicação.
   *
   * @returns Um array de objetos contendo todos os timezones disponíveis.
   */
  const getAllTimezones = () => {
    const timezones: {
      aliasOf: string;
      country: string;
      dstOffset: number;
      dstOffsetStr: string;
      name: string;
      utcOffset: number;
      utcOffsetStr: string;
    } = ct.getAllTimezones();

    return Object.values(timezones).map((timezone) => {
      return {
        value: timezone["name"],
        label: `(GMT${timezone["utcOffsetStr"]}) ${timezone["name"]}`,
      };
    });
  };

  /**
   * Lida com a atualização de categorias do mentor.
   *
   * @param e Evento que dispara a atualização das categorias e que contém informações
   *          relativas a estas.
   * @param values O array contendo as categorias associadas ao mentor.
   */
  const handleCategoriasChange = (
    e: React.ChangeEvent<{}>,
    values: Categoria[]
  ) => {
    e.persist();
    let oldFormObject = formObject;
    oldFormObject.categorias = values;
    setNumberOfSelectedCategorias(values.length);
    setFormObject(oldFormObject);
  };

  /**
   * Lida com a atualização de idiomas do mentor.
   *
   * @param event Evento que dispara a atualização dos idiomas e que contém informações
   *              relativas a estes.
   * @param values O array contendo os idiomas associados ao mentor.
   */
  const handleIdiomasChange = (
    event: React.ChangeEvent<{}>,
    values: Idioma[]
  ) => {
    event.persist();
    let oldFormObject = formObject;
    oldFormObject.idiomas = values;
    setNumberOfSelectedIdiomas(values.length);
    setFormObject(oldFormObject);
  };

  /**
   * Lida com os dias e horários de disponibilidade do mentor.
   *
   * @param newDisponibilidades O array com as novas disponibilidades do mentor.
   */
  const handleDisponibilidadesChange = (newDisponibilidades) => {
    let oldFormObject = formObject;
    oldFormObject.disponibilidades = newDisponibilidades;
    checkDuplicidadeDisponibilidades(newDisponibilidades);
    setFormObject(oldFormObject);
  };

  /**
   * Verifica se há duas disponibilidades iguais.
   *
   * @param disponibilidades O array de disponibilidades do mentor.
   */
  const checkDuplicidadeDisponibilidades = (disponibilidades) => {
    let isDuplicate = false;
    if (disponibilidades.length > 1) {
      if (disponibilidades[0].dia === disponibilidades[1].dia) {
        isDuplicate = true;
      }
    }
    setDuplicateDisponibilidadesError(isDuplicate);
  };

  /**
   * Permite a disponibilização do modal de confirmação de alteração das
   * informações do mentor.
   */
  const handleAtualizarClick = () => {
    handleOpenConfirmacaoModal();
  };

  /**
   * Realiza a atualização do mentor passando os novos valores do mentor ao backend.
   */
  const handleSubmit = () => {
    formObject.disponibilidades?.map(
      (disponibilidade, index) =>
        (formObject.disponibilidades[index]["ordinal"] = mapWeekDayToOrdinal(
          disponibilidade.dia
        ))
    );

    setUpdateLoading(true);
    updateMentor(formObject)
      .then((res) =>
        alertCallback(
          200,
          t(
            ATUALIZACAO_MENTOR_MODAL_ALERT_SUCESSO,
            "Atualização realizada com sucesso!"
          )
        )
      )
      .then(() => {
        setUpdateLoading(false);
        closeAllModais();
      });
  };

  /**
   * Realiza o fechamento de todos os modais, inclusive o próprio
   * modal de atualização das informações do mentor.
   */
  const closeAllModais = () => {
    handleCloseConfirmacaoModal();
    handleClose();
  };

  /**
   * Função responsável por configurar e atualizar a foto do perfil do mentor.
   *
   * @param file A foto a ser salva.
   */
  const fileCallback = (file: string) => {
    let oldFormObject = formObject;
    oldFormObject.foto = file;
    setFormObject(oldFormObject);
  };

  /**
   * Obtém todas as categorias cadastradas do mentor.
   *
   * @returns O array de categorias atualmente cadastradas do mentor.
   */
  const getCategoriasDoMentor = (): Categoria[] => {
    return formObject.categorias;
  };

  /**
   * Apresenta, dentre todos os idiomas da aplicação, os idiomas que
   * o mentor possui conhecimento.
   *
   * @returns Os idiomas que o mentor possui conhecimento.
   */
  const filtraIdiomasMentor = (): Idioma[] => {
    return idiomas.filter((idioma) => {
      return formObject.idiomas.filter((selected) => selected.sigla === idioma.sigla).length > 0;
    });
  };

  /**
   * Apresenta o modal de confirmação da atualização.
   */
  const handleOpenConfirmacaoModal = () => {
    setIsConfirmacaoModalOpen(true);
  };

  /**
   * Fecha o modal de confirmação da atualização.
   */
  const handleCloseConfirmacaoModal = () => {
    setIsConfirmacaoModalOpen(false);
  };

  /**
   * Valida o form com as informações do mentor.
   */
  const validaForm = (): boolean => {
    let categoriasValidas = numberOfSelectedCategorias > 0;
    let idiomasValidos = numberOfSelectedIdiomas > 0;
    let nomeValido = nomeMentor.length > 0;
    let cargoValido = cargoMentor.length > 0;
    let descricaoValida = descricaoMentor.length > 0;

    return (
      nomeValido &&
      cargoValido &&
      descricaoValida &&
      categoriasValidas &&
      disponibilidadesValidas &&
      idiomasValidos &&
      !duplicateDisponibilidadesError
    );
  };

  /**
   * Redenriza o modal de confirmação da atualização das informações.
   *
   * @returns O modal de confirmação de atualização.
   */
  const confirmacaoModal = () => {
    return (
      <CustomModal
        style={{ height: "auto", maxWidth: 560 }}
        isOpen={isConfirmacaoModalOpen}
        handleClose={handleCloseConfirmacaoModal}
      >
        <div>
          <Typography
            className={classes.confirmationModalQuestion}
            variant="h4"
          >
            {t(
              ATUALIZACAO_MENTOR_CONFIRMACAO_TITULO,
              "Você tem certeza disso?"
            )}
          </Typography>
        </div>
        <div className={classes.confirmarText}>
          <Typography
            className={classes.confirmationModalSubtitle}
            variant="body1"
          >
            {t(
              ATUALIZACAO_MENTOR_CONFIRMACAO_SUBTITULO,
              "Você poderá atualizar novamente os dados deste mentor posteriormente."
            )}
          </Typography>
        </div>
        <div className={classes.updateSpinnerContainer}>
          {updateLoading ? (
            <span className={classes.updateSpinner}>
              <SpinnerSplendis size={35} />
            </span>
          ) : null}
        </div>
        <div className={classes.actionButtonsOuterContainer}>
          <div className={classes.actionButtonsInnerContainer}>
            <Button
              className={classes.confirmarBtns}
              style={{ backgroundColor: "#FFF", marginRight: "1rem" }}
              variant="outlined"
              color="primary"
              onClick={handleCloseConfirmacaoModal}
            >
              {t(ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_NAO, "Não")}
            </Button>
            {agreeButtonWithIntegratedLoading()}
          </div>
        </div>
      </CustomModal>
    );
  };

  /**
   * Renderiza no form o campo para atualização do status do mentor.
   *
   * @returns O campo para atualização do status do mentor.
   */
  const changeStatusMentor = () => {
    return (
      <FormControl>
        <TextField
          id="statusMentor"
          name="statusMentor"
          label={t("Status")}
          onChange={handleChange}
          defaultValue={formObject.statusMentor}
          variant="outlined"
          select
          InputProps={{
            className: classes.changeStatusSelect,
          }}
        >
          {allStatusMentor().map((status) => {
            return (
              <MenuItem key={status} value={status}>
                {mapStatusMentor(status, t)}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  };

  /**
   * Renderiza o botão de confirmação de atualização das informaçãoes do mentor.
   *
   * @returns O botão para confirmação de atualização.
   */
  const agreeButtonWithIntegratedLoading = () => {
    return (
      <Button
        className={classes.confirmarBtnsUpdate}
        style={{ color: 'white' }}
        variant="contained"
        onClick={() =>
          compressImage(formObject.foto!).then((photo) => {
            formObject.foto = photo;
            handleSubmit();
          })
        }
        disabled={updateLoading}
      >
        {t(ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_SIM, "Sim, quero atualizar")}
      </Button>
    );
  };

  /**
   * Renderiza o form com todas as informações do mentor.
   *
   * @returns O form com todas as informações do mentor.
   */
  const atualizacaoMentorForm = () => {
    return (
      <React.Fragment>
        {confirmacaoModal()}
        <GlobalCss />
        <Formik
          enableReinitialize
          validateOnMount={false}
          initialValues={formObject}
          onSubmit={() => {}}
        >
          {({ isValid }) => {
            return (
              <Form autoComplete="on" className={classes.root}>
                <div className={classes.updateStatusPhotoContainer}>
                  <div className={classes.changeStatusDiv}>
                    {mentorMode ? null : changeStatusMentor()}
                  </div>
                  <div className={classes.updatePhoto}>
                    <span style={{ textAlign: "center" }}>
                      <FileUpload
                        {...{ callback: fileCallback }}
                        profilePhoto={formObject.foto}
                      />
                    </span>
                  </div>
                </div>

                <div className={classes.firstFieldsContainer}>
                  <div className={classes.firstFields}>
                    <TextField
                      id="nome"
                      name="nome"
                      label={t(ATUALIZACAO_MENTOR_MODAL_NOME, "Nome")}
                      inputProps={{ maxLength: 60 }}
                      onChange={handleChange}
                      defaultValue={formObject.nome}
                      variant="outlined"
                      fullWidth
                    />
                  </div>

                  <div className={classes.firstFields}>
                    <TextField
                      id="email"
                      name="email"
                      label={t(ATUALIZACAO_MENTOR_MODAL_EMAIL, "E-mail")}
                      onChange={handleChange}
                      defaultValue={formObject.email}
                      variant="outlined"
                      disabled
                      fullWidth
                    />
                  </div>
                </div>

                <div className={classes.firstFieldsContainer}>
                  <div className={classes.firstFields}>
                    <TextField
                      id="cargo"
                      name="cargo"
                      label={t(ATUALIZACAO_MENTOR_MODAL_CARGO, "Cargo")}
                      inputProps={{ maxLength: 45 }}
                      onChange={handleChange}
                      defaultValue={formObject.cargo}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className={classes.firstFields}>
                    <FormikSelectField
                      id="timezone"
                      name="timeZone"
                      onChange={(e) => handleChange(e)}
                      defaultValue={formObject.timeZone}
                      value={formObject.timeZone}
                      options={getAllTimezones().map((timezone) => {
                        return {
                          value: timezone.value,
                          label: timezone.label,
                        };
                      })}
                      inputProps={{
                        value: formObject.timeZone,
                      }}
                      InputLabelProps={{
                        style: {
                          fontFamily: "InfraRegular",
                        },
                      }}
                      InputProps={{
                        style: {
                          fontFamily: "InfraRegular",
                          fontSize: ".85rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "24px",
                        },
                      }}
                      variant="outlined"
                      label={t(
                        ATUALIZACAO_MENTOR_MODAL_TIMEZONE,
                        "Fuso horário"
                      )}
                      className={classes.timezoneField}
                    />
                  </div>
                </div>

                <div className={classes.countryStateContainer}>
                  <FormikSelectField
                    className={classes.countryField}
                    defaultValue={formObject.pais}
                    value={formObject.pais}
                    name="pais"
                    label={t(ATUALIZACAO_MENTOR_CAMPO_PAIS_PLACEHOLDER, "País")}
                    options={
                      i18n.language === "pt"
                        ? countriesListPortuguese().map((country) => {
                            return {
                              value: country.code,
                              label: country.name,
                            };
                          })
                        : i18n.language === "es"
                        ? countriesListSpanish().map((country) => {
                            return {
                              value: country.code,
                              label: country.name,
                            };
                          })
                        : countriesListEnglish().map((country) => {
                            return {
                              value: country.code,
                              label: country.name,
                            };
                          })
                    }
                    InputLabelProps={{
                      style: {
                        color: "#1F1F1F",
                        fontFamily: "InfraRegular",
                        fontSize: ".8rem",
                        fontStyle: "normal",
                        fontWeight: 400,
                      },
                    }}
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                    fullWidth
                  />
                  {formObject.pais === "BRA" ? (
                    <FormikSelectField
                      className={classes.stateField}
                      value={formObject.uf}
                      name="uf"
                      label={t(ATUALIZACAO_MENTOR_CAMPO_UF_PLACEHOLDER, "UF")}
                      margin="normal"
                      variant="outlined"
                      options={estadosBrasilToSelectField().map((uf) => {
                        return { label: uf, value: uf };
                      })}
                      InputLabelProps={{
                        style: {
                          color: "#1F1F1F",
                          fontFamily: "InfraRegular",
                          fontSize: ".8rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                        },
                      }}
                      onChange={(e) => handleChange(e)}
                      fullWidth
                    />
                  ) : null}
                </div>

                <div className={classes.spacing}>
                  <TextField
                    defaultValue={formObject.descricao}
                    id="descricao"
                    name="descricao"
                    label={t(ATUALIZACAO_MENTOR_MODAL_DESCRICAO, "Descrição")}
                    onChange={handleChange}
                    className={classes.descricao}
                    inputProps={{ maxLength: 500 }}
                    placeholder={t(
                      ATUALIZACAO_MENTOR_MODAL_DESCRICAO,
                      "Insira a nova descrição"
                    )}
                    variant="outlined"
                  />
                </div>

                {/* CATEGORIAS: */}
                <div className={classes.spacing}>
                  <Autocomplete
                    multiple
                    id="tags-standard-category"
                    options={categorias}
                    getOptionLabel={(categoria) => categoria["nome" + capitalize(i18n.language)] }
                    onChange={(e, values) => handleCategoriasChange(e, values)}
                    defaultValue={getCategoriasDoMentor()}
                    value={getCategoriasDoMentor()}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={t(ATUALIZACAO_MENTOR_MODAL_CATEGORIAS, "Categorias")}
                        placeholder={t(ATUALIZACAO_MENTOR_MODAL_CATEGORIAS, "Categorias")}
                        onKeyPress={(event) => { event.preventDefault(); }}
                        InputLabelProps={{
                          style: {
                            fontFamily: "InfraRegular",
                          },
                        }}
                      />
                    )}
                    ChipProps={{
                      style: {
                        fontFamily: "InfraRegular",
                        fontSize: ".85rem",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "24px",
                      },
                    }}
                  />
                </div>

                {/* IDIOMAS: */}
                <div className={classes.spacing}>
                  <Autocomplete
                    multiple
                    id="tags-standard-languages"
                    options={idiomas}
                    getOptionLabel={(idioma) => idioma.nome}
                    onChange={(e, values) => handleIdiomasChange(e, values)}
                    defaultValue={filtraIdiomasMentor()}
                    noOptionsText={t(ATUALIZACAO_MENTOR_MODAL_MAX_IDIOMAS, "Máximo de 3 idiomas atingido")}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={
                          numberOfSelectedIdiomas > 0
                            ? t(ATUALIZACAO_MENTOR_MODAL_IDIOMAS, "Idiomas")
                            : t(ATUALIZACAO_MENTOR_MODAL_MIN_IDIOMAS, "Selecione pelo menos 1 idioma")
                        }
                        placeholder={t(ATUALIZACAO_MENTOR_MODAL_IDIOMAS, "Idiomas")}
                        onKeyPress={(event) => { event.preventDefault(); }}
                      />
                    )}
                  />
                </div>

                {/* DISPONIBILIDADES: */}

                <div>
                  <DisplayDisponibilidade
                    disponibilidades={formObject.disponibilidades}
                    handleDisponibilidadeChange={handleDisponibilidadesChange}
                    dadosValidos={setDisponibilidadesValidas}
                    maxDisponibilidades={MAX_NUMBER_OF_DISPONIBILIDADES}
                  />
                </div>

                <div className={classes.updateActionContainer}>
                  <div className={classes.errorMessageContainer}>
                    {duplicateDisponibilidadesError ? (
                      <Typography variant="subtitle2" color="error">
                        {t(
                          ATUALIZACAO_MENTOR_MODAL_DISPONIBILIDADE,
                          "*As disponibilidades devem ser em dias diferentes"
                        )}
                      </Typography>
                    ) : null}
                  </div>
                </div>
                <Grid container justify="center" style={{ marginTop: "10px" }}>
                  <Button
                    onClick={handleAtualizarClick}
                    className={classes.updateButton}
                    variant="contained"
                    disabled={!validaForm()}
                    style={{ width: "95%", color: "white" }}
                  >
                    {t(ATUALIZACAO_MENTOR_MODAL_ATUALIZAR, "Atualizar")}
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };

  return (
    <CustomModal
      className={classes.modal}
      style={paperStyle}
      isOpen={isOpen}
      handleClose={handleClose}
      closeleft="true"
    >
      {atualizacaoMentorForm()}
    </CustomModal>
  );
};

export default AtualizacaoMentorModal;
