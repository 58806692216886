import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Paper } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";

import { Pergunta } from "../../../../models";
import SpinnerSplendis from "../../../../commons/spinner-splendis/SpinnerSplendis";
import { FAQ_SOURCE, FAQ_TITLE, FAQ_FOOTER } from "../../../../messages";
import questionspt from "./questions-pt.json";
import questionses from "./questions-es.json";
import questionsen from "./questions-en.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "80%",
      margin: "0 auto",
      "@media (max-width: 1024px)": {
        width: "90%",
      },
    },
    questions: {
      height: "55vh",
      overflow: "auto",
      "@media (max-width: 1024px)": {
        height: "45vh",
      },
    },
    title: {
      fontFamily: "InfraRegular",
      marginTop: "2vw",
    },
    subtitle: {
      fontFamily: "InfraRegular",
      marginBottom: 10,

      "@media (max-width: 1024px)": {
        fontSize: "1rem",
      },
    },
    email: {
      fontFamily: "InfraRegular",
      textDecoration: "underline",
    },
    emailDiv: {
      display: "inline-flex",
    },
    icon: {
      marginLeft: 10,
    },
    text: {
      fontFamily: "InfraRegular",
      textAlign: "initial",
      whiteSpace: "pre-line",
    },
    heading: {
      fontFamily: "InfraRegular",
      fontSize: theme.typography.pxToRem(15),

      textAlign: "initial",
    },
    accordion: {
      margin: "1vh",
    },
    accordionDetails: {
      border: "1px solid rgba(0, 0, 0, .125)",
      backgroundColor: "#edf9fa",
    },
    contact: {
      marginTop: "5vh",
      marginBottom: "2vh",
      margin: "0 auto",
      width: "fit-content",
    },
    contactPaper: {
      padding: "5px 5px 5px",
      boxShadow: "1px 1px 8px black",
    },
  })
);

/**
 * Componente que renderiza todos os accordions com as perguntas da FAQ.
 *
 * @returns As questões da FAQ.
 */
export default function FaqScreen() {
  const classes = useStyles();

  // Hook e função utilizados na tradução dos textos.
  const { t } = useTranslation([FAQ_SOURCE]);

  const [questions, setQuestions] = React.useState<Array<Pergunta>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Hook e função utilizados para verificação do idioma do usuário.
  const { i18n } = useTranslation([]);

  /**
   * Verifica o idioma do usuário assim que o componente é renderizado.
   */
  useEffect(() => {
    setLoading(true);
    if (i18n.language === "pt") {
      setQuestions(questionspt);
    } else if (i18n.language === "es") {
      setQuestions(questionses);
    } else {
      setQuestions(questionsen);
    }
    setLoading(false);
  }, [i18n.language]);

  /**
   * Renderiza as questões da FAQ em formato de accordions.
   */
  const mapQuestions = questions.map((question) => {
    return (
      <Accordion key={question.pergunta} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {question.pergunta}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography variant="body1" className={classes.text}>
            {question.resposta}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        <b>{t(FAQ_TITLE, "FAQ")}</b>
      </Typography>

      <div className={classes.questions}>
        {loading ? <SpinnerSplendis /> : mapQuestions}
      </div>

      <div className={classes.contact}>
        <Paper className={classes.contactPaper}>
          <Typography variant="h5" className={classes.subtitle}>
            {t(FAQ_FOOTER, "Para outras informações, fale conosco em:")}
          </Typography>

          <div className={classes.emailDiv}>
            <Typography variant="body1" className={classes.email}>
            mentoring@belgobekaert.com.br
            </Typography>
            <MailIcon className={classes.icon} />
          </div>
        </Paper>
      </div>
    </div>
  );
}
