import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, createStyles, CardActionArea, Card, Typography } from '@material-ui/core';
import { formatDateToMaterialUIDate } from '../../../../../../../../../../utils/FormUtils';

const gridListStyle = makeStyles((theme: Theme) =>
	createStyles({
       root:{
           marginTop: 5,
           borderRadius: '50px',
       },
       rootSelected: {
           backgroundColor: '#FF6C00',
           color: '#fff',
           marginTop: 5,
           borderRadius: '50px',
       },
       cardAction: {
           padding: 10
       }
	}),
);

/**
 * Gera um card clicável para data das disponibilidades.
 * 
 * @param props Propriedades e métodos necessários para o funcionamento
 *              adequado do componente.
 * @returns Um card clicável para data das disponibilidades.
 */
export default function DataButton(props) {
    const classes = gridListStyle();
    
    const { disponibilidade, selected, onClick } = props;

	return (
        <Card className={selected? classes.rootSelected:classes.root}>
            <CardActionArea className={classes.cardAction} onClick={(e) => onClick(e, disponibilidade.data)} >
                <Typography variant="button" display="block">{ formatDateToMaterialUIDate(disponibilidade.data) }</Typography>
            </CardActionArea>
        </Card>
	);
};
