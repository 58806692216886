import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../../../commons";
import AlterarSenhaSVG from "../../../../assets/svgs/alterar-senha.svg"
import { useTranslation } from "react-i18next";
import {
    TROCAR_SENHA_SOURCE,
    TROCAR_SENHA_MODAL_TITULO,
    TROCAR_SENHA_MODAL_SUBTITULO,
    TROCAR_SENHA_MODAL_BOTAO_ALTERAR_SENHA,
    TROCAR_SENHA_MODAL_BOTAO_VOLTAR_HOME
} from "../../../../messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "17rem",
        },
        confirmarContainer: {
            fontFamily: "infraRegular",
        },
        button: {
            fontFamily: "Roboto",
            width: "16rem",
            marginLeft: "0.65rem",
            marginTop: "0.5rem",
        },
        statusContainer: {
            textAlign: "initial",
            marginLeft: "1rem",
        },
        status: {
            marginLeft: theme.spacing(1),
        },
        buttonConfirmar: {
            fontFamily: "Roboto",
            width: "100%",
            marginTop: "1rem",
            "& span": {
                height: "2rem",
            },
        },
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: 'auto',
        textAlign: '-webkit-center',
        width: '28rem',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: 'auto',
        textAlign: 'center',
        width: '90vw',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: 'auto',
        marginTop: '50vh',
        textAlign: 'center',
        width: '60vw',
    },
};

/**
 * Interface utilizada pelo componente PasswordChangeModal e que descreve
 * seu métodos e propriedades.
 */
interface PasswordChangeModalProps {
    isOpen: boolean;
    handleClose: any;
    handleCallback?: any;
}

/**
 * Modal para a mudança de senha.
 * 
 * @param {PasswordChangeModalProps}
 * @returns Modal para mudança de senha.
 */
const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
    isOpen,
    handleClose,
    handleCallback,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const { t } = useTranslation([TROCAR_SENHA_SOURCE]);

    return (
        <CustomModal
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            <div className={classes.confirmarContainer}>
                <img src={AlterarSenhaSVG} alt="pessoa-selecionando" />
                <h3>{t(TROCAR_SENHA_MODAL_TITULO, 'Senha')}</h3>
                <p>{t(TROCAR_SENHA_MODAL_SUBTITULO, 'Tem certeza que deseja alterar sua senha?')}</p>

                <div>
                    <Button
                        className={classes.buttonConfirmar}
                        variant="contained"
                        color="primary"
                        onClick={() => handleCallback()}
                    >
                        {t(TROCAR_SENHA_MODAL_BOTAO_ALTERAR_SENHA, 'Alterar Senha')}
                    </Button>
                    <Button
                        className={classes.buttonConfirmar}
                        variant="outlined"
                        color="primary"
                        onClick={() => history.push("/mentoring/home")}
                    >
                        {t(TROCAR_SENHA_MODAL_BOTAO_VOLTAR_HOME, 'Retornar para a Home')}
                    </Button>
                </div>
            </div>
        </CustomModal>
    );
};

export default PasswordChangeModal;
