//Mensagem de aviso sobre tradução em andamento
export const MSG_AVISO_TRADUCAO_EM_ANDAMENTO =
  "La traducción al español aun está en curso. El texto puede contener algunos errores.";
export const MSG_AVISO_AGENDAMENTO_INDISPONIVEL =
  "O sistema está com o serviço de agendamento e e-mail temporariamente indisponível. Voltaremos em breve.";

/* EXTRAS */
export const EXTRAS_SOURCE = "extras";
export const EXTRAS_MODAL_SUCESSO_BOTAO_INICIO = "botao.inicio";
export const EXTRAS_MODAL_AVALIACAO = "avalicao";

//Utilizar Extras para mensagens traduzidas pontuais gerais, como textos de modais de aviso,
//notificações, etc.

/* MENU DESLIZANTE */
export const MENU_DESLIZANTE_SOURCE = "menu-deslizante";
export const MENU_DESLIZANTE_MENU = "menu";
export const MENU_DESLIZANTE_OPCAO_HOME = "options.home";
export const MENU_DESLIZANTE_OPCAO_CADASTRO_DE_CATEGORIAS =
  "options.cadastro_categorias";
export const MENU_DESLIZANTE_OPCAO_GERENCIAR_CATEGORIAS =
  "options.gerenciar_categorias";
export const MENU_DESLIZANTE_OPCAO_GERENCIAR_MENTORES =
  "options.gerenciar_mentores";
export const MENU_DESLIZANTE_OPCAO_GERENCIAR_MENTORIAS =
  "options.gerenciar_mentorias";
export const MENU_DESLIZANTE_OPCAO_FAQ = "options.faq";
export const MENU_DESLIZANTE_OPCAO_VISUALIZAR_AVALIACOES =
  "options.gerenciar_avaliacoes";
export const MENU_DESLIZANTE_OPCAO_TROCAR_SENHA = "options.trocar_senha";
export const MENU_DESLIZANTE_OPCAO_SEJA_UM_MENTOR = "options.seja_um_mentor";
export const MENU_DESLIZANTE_OPCAO_AGENDAR_MENTORIA =
  "options.agendar_mentoria";
export const MENU_DESLIZANTE_OPCAO_GERENCIAR_COLABORADORES =
  "options.gerenciar_colaboradores";

/* LOGIN */
export const LOGIN_SOURCE = "login";
export const LOGIN_AVISO_STEFANINI_AD = "aviso.stefanini_ad";
export const LOGIN_LABEL_BOTAO_LOGIN = "login";
export const LOGIN_EMAIL_PLACEHOLDER = "email_placeholder";
export const LOGIN_SENHA_PLACEHOLDER = "senha_placeholder";
export const LOGIN_ESQUECEU_A_SENHA = "esqueceu_a_senha";
export const LOGIN_CADASTRAR = "cadastrar";
export const LOGIN_USUARIO_OU_SENHA_INCORRETOS =
  "erro.usuario_ou_senha_incorretos";
export const LOGIN_SUBTITULO = "subtitulo";
export const LOGIN_MENSAGEM_INICIAL = "mensagem_inicial";
export const LOGIN_MENSAGEM_DOWNLOAD_MANUAL_PARTE_1 =
  "mensagem_download_manual_parte_1";
export const LOGIN_MENSAGEM_DOWNLOAD_MANUAL_PARTE_2 =
  "mensagem_download_manual_parte_2";

/* FORM DE COLABORADOR */
export const FORM_COLABORADOR_SOURCE = "colaborador-form";
export const FORM_COLABORADOR_CAMPO_EMAIL_TOOLTIP = "fields.email_tooltip";
export const FORM_COLABORADOR_CAMPO_SENHA_PLACEHOLDER =
  "fields.senha_placeholder";
export const FORM_COLABORADOR_CAMPO_CONFIRMAR_SENHA_PLACEHOLDER =
  "fields.confirmar_senha_placeholder";
export const FORM_COLABORADOR_CAMPO_TELEFONE_PLACEHOLDER =
  "fields.telefone_placeholder";
export const FORM_COLABORADOR_CAMPO_NOME_PLACEHOLDER =
  "fields.nome_placeholder";
export const FORM_COLABORADOR_CAMPO_EMAIL_PLACEHOLDER =
  "fields.email_placeholder";
export const FORM_COLABORADOR_CAMPO_TIMEZONE_PLACEHOLDER =
  "fields.timezone_placeholder";
export const FORM_COLABORADOR_CAMPO_UF_PLACEHOLDER = "fields.uf_placeholder";
export const FORM_COLABORADOR_CAMPO_CARGO_PLACEHOLDER =
  "fields.cargo_placeholder";
export const FORM_COLABORADOR_BOTAO_ENVIE_SUA_FOTO =
  "fields.botao.envie_sua_foto";
export const FORM_COLABORADOR_BOTAO_FOTO_INSERIDA =
  "fields.botao.foto_inserida";
export const FORM_COLABORADOR_BOTAO_APLICAR = "fields.botao.aplicar";
export const FORM_COLABORADOR_BOTAO_ATUALIZAR = "fields.botao.atualizar";
export const FORM_COLABORADOR_BOTAO_VOLTAR = "botao.voltar";
export const FORM_COLABORADOR_FORM_LABEL = "form_label";
export const VALIDATIONS_CAMPO_OBRIGATORIO = "validations.campo_obrigatorio";
export const VALIDATIONS_TELEFONE_INVALIDO = "validations.telefone_invalido";
export const VALIDATIONS_EMAIL_INVALIDO = "validations.email_invalido";
export const VALIDATIONS_SENHAS_DIFERENTES = "validations.senhas_diferentes";
export const VALIDATIONS_NOME_CURTO = "validations.nome_muito_curto";
export const VALIDATIONS_SENHA_CURTA = "validations.senha_muito_curta";
export const VALIDATIONS_SENHA_TAMANHO_MAXIMO =
  "validations.senha_tamanho_maximo";
export const VALIDATIONS_DOMINIO_INVALIDO = "validations.dominio_invalido";
export const VALIDATIONS_NOME_LONGO = "validations.nome_longo";
export const VALIDATIONS_CARGO_INVALIDO = "validations.cargo_invalido";
export const FORM_COLABORADOR_UPLOAD_FOTO = "fields.upload_foto_subtitulo";
export const FORM_COLABORADOR_CAMPO_PAIS_PLACEHOLDER =
  "fields.pais_placeholder";
export const FORM_COLABORADOR_MODAL_TITULO = "modal.titulo";
export const FORM_COLABORADOR_MODAL_BOTAO_CONFIRMAR = "modal.botal_confirmar";
export const FORM_COLABORADOR_BOTAO_MUDAR_SENHA = "fields.botao.mudar_senha";
export const FORM_COLABORADOR_MENSAGEM_INFORMATIVA = "mensagem";
export const FORM_COLABORADOR_CADASTRO_BEM_SUCEDIDO =
  "mensagem_sucesso.cadastro";
export const FORM_COLABORADOR_ATUALIZACAO_BEM_SUCEDIDA =
  "mensagem_sucesso.atualizacao";
export const FORM_COLABORADOR_REDIRECIONAMENTO_HOME =
  "mensagem_sucesso.redirecionamento";

/* RECUPERAR SENHA */
export const RECUPERAR_SENHA_SOURCE = "recuperar-senha";
export const RECUPERAR_SENHA_TITULO = "titulo";
export const RECUPERAR_SENHA_SUBTITULO = "subtitulo";
export const RECUPERAR_SENHA_CAMPO_EMAIL_LABEL = "fields.email_placeholder";
export const RECUPERAR_SENHA_BOTAO_CONFIRMAR = "botao.confirmar";
export const RECUPERAR_SENHA_BOTAO_VOLTAR = "botao.voltar";
export const RECUPERAR_SENHA_MENSAGEM_SUCESSO = "mensagem_sucesso";

/* TROCAR SENHA */
export const TROCAR_SENHA_SOURCE = "trocar-senha";
/* FIXME: faltando mensagem no public/locales/trocar-senha.json */
export const TROCAR_SENHA_TITULO = "titulo";
export const TROCAR_SENHA_SUBTITULO = "subtitulo";
/* FIXME: faltando mensagem no public/locales/trocar-senha.json */
export const TROCAR_SENHA_SENHA_ATUAL_PLACEHOLDER = "placeholders.senha_atual";
/* FIXME: faltando mensagem no public/locales/trocar-senha.json */
export const TROCAR_SENHA_NOVA_SENHA_PLACEHOLDER = "placeholders.nova_senha";
/* FIXME: faltando mensagem no public/locales/trocar-senha.json */
export const TROCAR_SENHA_CONFIRMAR_NOVA_SENHA_PLACEHOLDER =
  "placeholders.confirmar_nova_senha";
/* FIXME: faltando mensagem no public/locales/trocar-senha.json */
export const TROCAR_SENHA_BOTAO_CONFIRMAR = "botao.confirmar";
/* FIXME: faltando mensagem no public/locales/trocar-senha.json */
export const TROCAR_SENHA_ERRO_SENHA_ATUAL_INCORRETA =
  "erro.senha_atual_incorreta";
/* FIXME: faltando mensagem no public/locales/trocar-senha.json */
export const TROCAR_SENHA_ERRO_CAMPOS_DE_NOVA_SENHA_DIFERENTES =
  "erro.senhas_diferentes";
export const TROCAR_SENHA_MENSAGEM_SUCESSO = "mensagem_sucesso";
export const TROCAR_SENHA_BOTAO_VOLTAR = "botao.voltar";

export const TROCAR_SENHA_MODAL_TITULO = "modal.titulo";
export const TROCAR_SENHA_MODAL_SUBTITULO = "modal.subtitulo";
export const TROCAR_SENHA_MODAL_BOTAO_ALTERAR_SENHA =
  "modal.botao.alterar_senha";
export const TROCAR_SENHA_MODAL_BOTAO_VOLTAR_HOME = "modal.botao.voltar_home";

/* HOME */
export const HOME_SOURCE = "home";
export const HOME_SECAO_1_TITULO = "section_1.titulo";
export const HOME_SECAO_1_SUBTITULO_MAIOR = "section_1.subtitulo_maior";
export const HOME_SECAO_1_SUBTITULO_MENOR = "section_1.subtitulo_menor";
export const HOME_SECAO_1_TITULO_IMAGEM = "section_1.titulo_imagem";
export const HOME_SECAO_1_BOTAO_MENTOR_POCKET = "section_1.botao_mentor.pocket";
export const HOME_SECAO_1_BOTAO_MENTOR_COMPLETO =
  "section_1.botao_mentor.completo";
export const HOME_SECAO_1_BOTAO_MENTORADO_POCKET =
  "section_1.botao_mentorado.pocket";
export const HOME_SECAO_1_BOTAO_MENTORADO_COMPLETO =
  "section_1.botao_mentorado.completo";
export const HOME_SECAO_2_TITULO = "section_2.titulo";
export const HOME_SECAO_2_TEXTO_1 = "section_2.texto_1";
export const HOME_SECAO_2_TEXTO_2 = "section_2.texto_2";
export const HOME_SECAO_2_BOTAO_SEJA_UM_MENTOR = "section_2.botao_seja_mentor";
export const HOME_SECAO_3_TITULO = "section_3.titulo";
export const HOME_SECAO_3_TEXTO_1 = "section_3.texto_1";
export const HOME_SECAO_3_TEXTO_2 = "section_3.texto_2";
export const HOME_SECAO_3_BOTAO_AGENDAR_MENTORIA =
  "section_3.botao_agendar_mentoria";

/* HOME DO MENTOR */
export const HOME_MENTOR_SOURCE = "home-mentor";

/* FIXME: faltando mensagem no public/locales/home-mentor.json */
export const HOME_MENTOR_MENTORIAS_FUTURAS_TITULO = "mentorias_futuras.titulo";
/* FIXME: faltando mensagem no public/locales/home-mentor.json */
export const HOME_MENTOR_MENTORIAS_FUTURAS_SUBTITULO =
  "mentorias_futuras.subtitulo";
/* FIXME: faltando mensagem no public/locales/home-mentor.json */
export const HOME_MENTOR_HISTORICO_MENTORIAS_TITULO =
  "historico_mentorias.titulo";
/* FIXME: faltando mensagem no public/locales/home-mentor.json */
export const HOME_MENTOR_HISTORICO_MENTORIAS_SUBTITULO =
  "historico_mentorias.subtitulo";
export const HOME_MENTOR_HISTORICO_MENTORIAS_COMENTARIOS =
  "historico_mentorias.comentarios";
/* FIXME: faltando mensagem no public/locales/home-mentor.json */
export const HOME_MENTOR_CARD_MEDIA_AVALIACAO_LABEL =
  "mentor_card.media_avaliacao_label";
// export const HOME_MENTOR_CARD_BOTAO_EDITAR_LABEL = "mentor_card.botao.editar";
export const HOME_MENTOR_HISTORICO_MENTORIAS_BOTAO =
  "historico_mentorias.botao";
export const HOME_MENTOR_CANCELAMENTO_MENTORIA_TITULO =
  "cancelamento_mentoria.titulo";
export const HOME_MENTOR_CANCELAMENTO_MENTORIA_MENSAGEM =
  "cancelamento_mentoria.mensagem";
export const HOME_MENTOR_REAGENDAMENTO_TITULO = "reagendamento.sucesso.titulo";
export const HOME_MENTOR_REAGENDAMENTO_SUBTITULO =
  "reagendamento.sucesso.subtitulo";
export const HOME_MENTOR_CANCELAMENTO_SUCESSO = "cancelamento_mentoria.sucesso";
export const HOME_MENTOR_CANCELAMENTO_ERRO = "cancelamento_mentoria.erro";
export const HOME_MENTOR_REAGENDAMENTO_ALERTA_SUCESSO =
  "reagendamento.alerta_sucesso";
export const HOME_MENTOR_CARD_EDITAR_MENTOR = "mentor_card.botao.editar";
export const HOME_MENTOR_AGENDA_TITULO = "agenda.titulo";

/* HOME DO MENTORADO */
export const HOME_MENTORADO_SOURCE = "home-mentorado";
export const HOME_MENTORADO_MENTORIAS_FUTURAS_TITULO =
  "mentorias_futuras.titulo";
export const HOME_MENTORADO_MENTORIAS_FUTURAS_SUBTITULO =
  "mentorias_futuras.subtitulo";
export const HOME_MENTORADO_HISTORICO_MENTORIAS_BOTAO =
  "mentorado_card.botao.historico_mentorias";
export const HOME_MENTORADO_AGENDAR_MENTORIA_BOTAO =
  "mentorado_card.botao.agendar_mentoria";
export const HOME_MENTORADO_CARD_EDITAR_MENTORADO =
  "mentorado_card.botao.editar";
export const HOME_MENTORADO_MODAL_REAGENDAMENTO_SUCESSO_TITULO =
  "reagendar_mentoria.modal_reagendamento.sucesso.titulo";
export const HOME_MENTORADO_MODAL_REAGENDAMENTO_SUCESSO_SUBTITULO =
  "reagendar_mentoria.modal_reagendamento.sucesso.subtitulo";
export const HOME_MENTORADO_REAGENDAR_MENTORIA_SUCESSO =
  "reagendar_mentoria.mensagem_sucesso";
export const HOME_MENTORADO_MODAL_CANCELAMENTO_MENTORIA_SUCESSO_TITULO =
  "cancelar_mentoria.modal_cancelamento.sucesso.titulo";
export const HOME_MENTORADO_MODAL_CANCELAMENTO_MENTORIA_SUCESSO_SUBTITULO =
  "cancelar_mentoria.modal_cancelamento.sucesso.subtitulo";
export const HOME_MENTORADO_CANCELAR_MENTORIA_SUCESSO =
  "cancelar_mentoria.mensagem_sucesso";
export const HOME_MENTORADO_CANCELAR_MENTORIA_FALHA =
  "cancelar_mentoria.mensagem_falha";
export const HOME_MENTORADO_AGENDA_TITULO = "agenda.titulo";

/* MENTORIAS PENDENTES DE AVALIAÇÃO */
export const MENTORIAS_PENDENTES_AVALICAO_SOURCE =
  "mentorias-pendentes-avaliacao";
export const MENTORIAS_PENDENTES_AVALICAO_TITULO =
  "mentorias_pendentes_avaliacao.titulo";
export const MENTORIAS_PENDENTES_AVALICAO_SUBTITULO =
  "mentorias_pendentes_avaliacao.subtitulo";
export const MENTORIAS_PENDENTES_AVALICAO_SEM_MENTORIAS =
  "mentorias_pendentes_avaliacao.sem_mentorias";
export const MENTORIAS_PENDENTES_AVALICAO_BOTAO_VOLTAR =
  "mentorias_pendentes_avaliacao.botao.voltar";
export const MENTORIAS_PENDENTES_AVALIACAO_INFO_MESSAGE =
  "mentorias_pendentes_avaliacao.info_message";

/* AGENDAMENTO DE MENTORIA */
export const AGENDAMENTO_MENTORIA_SOURCE = "agendamento-mentoria";
export const AGENDAMENTO_MENTORIA_INDICADOR_STEP_CATEGORIA =
  "indicador_steps.categorias";
export const AGENDAMENTO_MENTORIA_INDICADOR_STEP_MENTORES =
  "indicador_steps.mentores";
export const AGENDAMENTO_MENTORIA_INDICADOR_STEP_HORARIOS =
  "indicador_steps.horarios";
export const AGENDAMENTO_MENTORIA_INDICADOR_STEP_REVISAR =
  "indicador_steps.revisar";
export const AGENDAMENTO_MENTORIA_INDICADOR_STEP_IDIOMAS =
  "indicador_steps.idiomas";

export const AGENDAMENTO_MENTORIA_STEP_CATEGORIA_INFO_LABEL =
  "step_categorias.info_label";
export const AGENDAMENTO_MENTORIA_STEP_CATEGORIA_SEM_CATEGORIA =
  "step_categorias.sem_categorias";
export const AGENDAMENTO_MENTORIA_STEP_CATEGORIA_POR_CATEGORIA_TITULO =
  "step_categorias.por_categoria.titulo";
export const AGENDAMENTO_MENTORIA_STEP_CATEGORIA_TITULO =
  "step_categorias.titulo";

export const AGENDAMENTO_MENTORIA_STEP_MENTORES_INFO_LABEL =
  "step_mentores.info_label";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_SEM_MENTORES =
  "step_mentores.sem_mentores";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONADO =
  "step_mentores.botao.selecionado";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONAR =
  "step_mentores.botao.selecionar";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_VEJA_MAIS =
  "step_mentores.veja_mais";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_AGENDAR_MENTORIAS = "titulo_steps.titulo";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_TITULO_1 =
  "step_mentores.titulo1";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_TITULO_2 =
  "step_mentores.titulo2";
export const AGENDAMENTO_MENTORIA_STEP_MENTORES_BUSCAR_MENTOR =
  "step_mentores.buscar_mentor";

export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_INFO_LABEL =
  "step_horarios.info_label";
export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_SEM_HORARIOS_DISPONIVEIS =
  "step_horarios.sem_horarios_disponiveis";
export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_SEM_HORARIOS_DISPONIVEIS_1 =
  "step_horarios.sem_horarios_disponiveis_1";
export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_SEM_HORARIOS_DISPONIVEIS_2 =
  "step_horarios.sem_horarios_disponiveis_2";
export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_1 =
  "step_horarios.titulo1";
export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_2 =
  "step_horarios.titulo2";
export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_TITULO_3 =
  "step_horarios.titulo3";
export const AGENDAMENTO_MENTORIA_STEP_HORARIOS_DIAS_SEMANA =
  "step_horarios.dias_da_semana";
export const AGENDAMENTO_MENTORIA_AS = "step_horarios.as";

export const AGENDAMENTO_MENTORIA_STEP_REVISAO_INFO_LABEL =
  "step_revisao.info_label";
export const AGENDAMENTO_MENTORIA_STEP_REVISAO_CAMPO_CATEGORIA =
  "step_revisao.fields.categoria";
export const AGENDAMENTO_MENTORIA_STEP_REVISAO_CAMPO_MENTOR =
  "step_revisao.fields.mentor";
export const AGENDAMENTO_MENTORIA_STEP_REVISAO_CAMPO_HORARIO =
  "step_revisao.fields.horario";
export const AGENDAMENTO_MENTORIA_STEP_REVISAO_DIA_DA_SEMANA_SOURCE =
  "step_revisao.dias_da_semana";
export const AGENDAMENTO_MENTORIA_STEP_REVISAO_BOTAO_EDITAR =
  "step_revisao.botao.editar";

export const AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_TITULO =
  "modais.confirmacao.titulo";
export const AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_1 =
  "modais.confirmacao.conteudo_1";
export const AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_2 =
  "modais.confirmacao.conteudo_2";
export const AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_3 =
  "modais.confirmacao.conteudo_3";
export const AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_BOTAO_CONTINUAR =
  "modais.confirmacao.botao.continuar";

export const AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_TITULO =
  "modais.pos_confirmacao.titulo";
export const AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_CONTEUDO =
  "modais.pos_confirmacao.conteudo";
export const AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_BOTAO_VOLTAR_PARA_HOME =
  "modais.pos_confirmacao.botao.voltar_para_home";

export const AGENDAMENTO_MENTORIA = "titulo_steps.titulo";
export const AGENDAMENTO_MENTORIA_BOTAO_VOLTAR = "botao.voltar";
export const AGENDAMENTO_MENTORIA_BOTAO_APLICAR = "botao.aplicar";
export const AGENDAMENTO_MENTORIA_BOTAO_PROXIMO = "botao.proximo";
export const AGENDAMENTO_MENTORIA_NEW_MENTOR_MODAL_BOTAO_FECHAR =
  "botao.fechar";
export const AGENDAMENTO_MENTORIA_BOTAO_CONFIRMAR = "botao.confirmar";

/* SE TORNAR MENTOR */
export const SE_TORNAR_MENTOR_SOURCE = "se-tornar-mentor";
export const SE_TORNAR_MENTOR_TITULO = "titulo";

export const SE_TORNAR_MENTOR_INDICADOR_STEP_DIAS = "indicador_steps.dias";
export const SE_TORNAR_MENTOR_INDICADOR_STEP_DISPONIBILIDADE =
  "indicador_steps.disponibilidade";
export const SE_TORNAR_MENTOR_INDICADOR_STEP_SOBRE_VOCE =
  "indicador_steps.sobre_voce";
export const SE_TORNAR_MENTOR_INDICADOR_STEP_CATEGORIAS =
  "indicador_steps.categorias";
export const SE_TORNAR_MENTOR_INDICADOR_STEP_FINALIZAR =
  "indicador_steps.finalizar";
export const SE_TORNAR_MENTOR_INDICADOR_STEP_IDIOMAS =
  "indicador_steps.idiomas";

export const SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_SUBTITULO =
  "step_sobre_voce.subtitulo";
export const SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_TEXTAREA_PLACEHOLDER =
  "step_sobre_voce.textarea_placeholder";
export const SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_CONTADOR_CARACTERES =
  "step_sobre_voce.contador_caracteres.caracteres";
export const SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_DESCRICAO_CURTA =
  "step_sobre_voce.contador_caracteres.descricao_curta";

export const SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_PARTE_1 =
  "step_idiomas.subtitulo_parte_1";
export const SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_PARTE_2 =
  "step_idiomas.subtitulo_parte_2";
export const SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_PARTE_1 =
  "step_idiomas.subtitulo_agendamento_parte_1";
export const SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_PARTE_2 =
  "step_idiomas.subtitulo_agendamento_parte_2";
export const SE_TORNAR_MENTOR_STEP_IDIOMAS_SEM_IDIOMAS =
  "step_idiomas.sem_idiomas";
export const SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_IDIOMAS =
  "step_idiomas.idiomas";

export const SE_TORNAR_MENTOR_STEP_CATEGORIAS_SUBTITULO_PARTE_1 =
  "step_categorias.subtitulo_parte_1";
export const SE_TORNAR_MENTOR_STEP_CATEGORIAS_SUBTITULO_PARTE_2 =
  "step_categorias.subtitulo_parte_2";
export const SE_TORNAR_MENTOR_STEP_CATEGORIAS_SEM_CATEGORIAS =
  "step_categorias.sem_categorias";
export const SE_TORNAR_MENTOR_STEP_CATEGORIAS_LIVRES =
  "step_categorias.categorias_livres";

export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBTITULO =
  "step_disponibilidade.subtitulo";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_DISPONIBILIDADE =
  "step_disponibilidade.disponibilidade";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SEM_DIAS_DISPONIVEIS =
  "step_disponibilidade.sem_dias_disponiveis";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_TITULO =
  "step_disponibilidade.dias.titulo";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_SUBTITULO =
  "step_disponibilidade.dias.subtitulo";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_DIAS_SEMANA =
  "step_disponibilidade.dias_da_semana";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_TITULO =
  "step_disponibilidade.horarios.titulo";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_SUBTITULO =
  "step_disponibilidade.horarios.subtitulo";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_DISPONIBILIDADE =
  "step_disponibilidade.horarios.disponibilidade";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_HORARIO =
  "step_disponibilidade.horarios.horario";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_CANCELAR =
  "step_disponibilidade.horarios.cancelar";
export const SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_CONFIRMAR =
  "step_disponibilidade.horarios.confirmar";

export const SE_TORNAR_MENTOR_STEP_FINALIZAR_SUBTITULO =
  "step_finalizar.subtitulo";
export const SE_TORNAR_MENTOR_STEP_FINALIZAR_CATEGORIAS =
  "step_finalizar.categorias";
export const SE_TORNAR_MENTOR_STEP_FINALIZAR_IDIOMAS = "step_finalizar.idiomas";
export const SE_TORNAR_MENTOR_STEP_FINALIZAR_DISPONIBILIDADE =
  "step_finalizar.disponibilidade";
export const SE_TORNAR_MENTOR_STEP_FINALIZAR_CONFIRMAR =
  "step_finalizar.confirmar";
export const SE_TORNAR_MENTOR_STEP_FINALIZAR_DIAS =
  "step_finalizar.dias_da_semana";
export const SE_TORNAR_MENTOR_STEP_FINALIZAR_MARCADOR_HORARIO =
  "step_finalizar.marcador_horario";

export const SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_TITULO =
  "modais.confirmacao.titulo";
export const SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_CONTEUDO =
  "modais.confirmacao.conteudo";
export const SE_TORNAR_MENTOR_MODAL_CONFIRMACAO_BOTAO_VOLTAR_PARA_HOME =
  "modais.confirmacao.botao.voltar_home";

export const SE_TORNAR_MENTOR_BOTAO_VOLTAR = "botao.voltar";
export const SE_TORNAR_MENTOR_BOTAO_APLICAR = "botao.aplicar";
export const SE_TORNAR_MENTOR_BOTAO_PROXIMO = "botao.proximo";

export const SE_TORNAR_MENTOR_TERMOS_DE_USO_TITULO = "confirmacao_modal.titulo";
export const SE_TORNAR_MENTOR_TERMOS_DE_USO_BOTAO = "confirmacao_modal.botao";

/* RH */
export const RH_SOURCE = "admin";
export const RH_DASHBOARD_TITULO = "dashboard.titulo";
export const RH_DASHBOARD_SUBTITULO = "dashboard.subtitulo";
export const RH_DASHBOARD_GRAFICOS_CATEGORIAS_MAIS_BUSCADAS_TITULO =
  "dashboard.graficos.titulos.categorias_mais_buscadas";
export const RH_DASHBOARD_GRAFICOS_CATEGORIAS_MAIS_PROCURADAS_SUBTITULO =
  "dashboard.graficos.subtitulos.categorias_mais_buscadas";
export const RH_DASHBOARD_GRAFICOS_MENTORADOS_MAIS_ATIVOS_TITULO =
  "dashboard.graficos.titulos.mentorados_mais_ativos";
export const RH_DASHBOARD_GRAFICOS_MENTORADOS_MAIS_ATIVOS_SUBTITULO =
  "dashboard.graficos.subtitulos.mentorados_mais_ativos";
export const RH_DASHBOARD_GRAFICOS_MENTORES_MAIS_BUSCADOS_TITULO =
  "dashboard.graficos.titulos.mentores_mais_buscados";
export const RH_DASHBOARD_GRAFICOS_MENTORES_MAIS_BUSCADOS_SUBTITULO =
  "dashboard.graficos.subtitulos.mentores_mais_buscados";
export const RH_DASHBOARD_GRAFICOS_ESTADOS_MAIS_ENGAJADOS_TITULO =
  "dashboard.graficos.titulos.estados_mais_engajados";
export const RH_DASHBOARD_GRAFICOS_ESTADOS_MAIS_ENGAJADOS_SUBTITULO =
  "dashboard.graficos.subtitulos.estados_mais_engajados";
export const RH_DASHBOARD_GRAFICOS_PAISES_MAIS_ENGAJADOS_TITULO =
  "dashboard.graficos.titulos.paises_mais_engajados";
export const RH_DASHBOARD_GRAFICOS_PAISES_MAIS_ATIVOS_SUBTITULO =
  "dashboard.graficos.subtitulos.paises_mais_ativos";
export const RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_TITULO =
  "dashboard.graficos.titulos.status_mentorias";
export const RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_SUBTITULO =
  "dashboard.graficos.subtitulos.status_mentorias";
export const RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_MES_TITULO =
  "dashboard.graficos.titulos.mentorias_por_mes";
export const RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_MES_SUBTITULO =
  "dashboard.graficos.subtitulos.mentorias_por_mes";
export const RH_DASHBOARD_GRAFICOS_AVALIACOES_MENTORIAS_TITULO =
  "dashboard.graficos.titulos.avaliacoes_mentorias";
export const RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS =
  "dashboard.graficos.legenda.paises.numero_mentorias";
export const RH_DASHBOARD_GRAFICOS_AVALICOES_MENTORIAS_SUBTITULO =
  "dashboard.graficos.subtitulos.avaliacoes_mentorias";
export const RH_DASHBOARD_GRAFICOS_AVALICOES_MENTORIAS_NAO_ACONTECERAM =
  "dashboard.graficos.subtitulos.mentorias_nao_aconteceram";
export const RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_SEMANA_TITULO =
  "dashboard.graficos.titulos.mentorias_por_semana";
export const RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_SEMANA_SUBTITULO =
  "dashboard.graficos.subtitulos.mentorias_por_semana";
export const RH_DASHBOARD_GRAFICOS_LEGENDA_NOTAS_MENTORIAS =
  "dashboard.graficos.legenda.notas_mentorias";
export const RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_LEGENDA =
  "dashboard.graficos.legenda.total_mentorias";
export const RH_FILTROS_LABEL = "label.filtros";
export const RH_DATA_INICIAL_LABEL = "label.data_inicial";
export const RH_DATA_FINAL_LABEL = "label.data_final";
export const RH_ATUALIZAR_LABEL = "label.atualizar";
export const RH_UF_LABEL = "label.uf";
export const RH_IMAGEM_LABEL = "label.imagem";
export const RH_STATUS_MENTOR_APROVADO = "status_mentor.aprovado";
export const RH_STATUS_MENTOR_ESPERANDO_APROVACAO =
  "status_mentor.esperando_aprovacao";
export const RH_BOTAO_EDITAR = "botao.editar";
export const RH_BOTAO_BUSCAR = "botao.buscar";
export const RH_BOTAO_VISUALIZAR_AVALIACOES = "botao.visualizar_avaliacoes";

/* FORMULÁRIO DE AVALIAÇÃO */
export const AVALIACAO_FORM_SOURCE = "avaliacao-form";
/* FIXME: faltando mensagem no public/locales/avaliacao-form.json */
export const AVALIACAO_FORM_MENTORIA_ACONTECEU_PERGUNTA_TITULO =
  "titulos.mentoria_aconteceu";
/* FIXME: faltando mensagem no public/locales/avaliacao-form.json */
export const AVALIACAO_FORM_AVALIACAO_RATING_EXPERIENCIA_MENTORIA_TITULO =
  "titulos.rating_experiencia_mentoria";
/* FIXME: faltando mensagem no public/locales/avaliacao-form.json */
export const AVALIACAO_FORM_AVALIACAO_ESCRITA_EXPERIENCIA_MENTORIA_LABEL =
  "titulos.escrita_experiencia_mentoria";
/* FIXME: faltando mensagem no public/locales/avaliacao-form.json */
export const AVALIACAO_FORM_AVALIACAO_ESCRITA_EXPERIENCIA_MENTORIA_PLACEHOLDER =
  "placeholders.escrita_experiencia_mentoria";
/* FIXME: faltando mensagem no public/locales/avaliacao-form.json */
export const AVALIACAO_FORM_CARACTERES_LABEL = "labels.caracteres";
/* FIXME: faltando mensagem no public/locales/avaliacao-form.json */
export const AVALIACAO_FORM_BOTAO_ENVIAR_LABEL = "botao.enviar";

/* MENTORIA TABLE */
export const MENTORIA_TABLE_SOURCE = "mentoria-table";
export const MENTORIA_TABLE_HEADERS_DATA = "headers.data";
export const MENTORIA_TABLE_HEADERS_HORARIO = "headers.horario";
export const MENTORIA_TABLE_HEADERS_DATA_HORA = "headers.data_hora";
export const MENTORIA_TABLE_HEADERS_CATEGORIA = "headers.categoria";
export const MENTORIA_TABLE_HEADERS_NOME_MENTOR = "headers.nome_mentor";
export const MENTORIA_TABLE_HEADERS_EMAIL_MENTOR = "headers.email_mentor";
export const MENTORIA_TABLE_HEADERS_NOME_MENTORADO = "headers.nome_mentorado";
export const MENTORIA_TABLE_HEADERS_EMAIL_MENTORADO = "headers.email_mentorado";
export const MENTORIA_TABLE_HEADERS_STATUS_MENTORIA = "headers.status_mentoria";
export const MENTORIA_TABLE_HEADERS_REAGENDAR_MENTORIA =
  "headers.reagendar_mentoria";
export const MENTORIA_TABLE_HEADERS_CANCELAR_MENTORIA =
  "headers.cancelar_mentoria";
export const MENTORIA_TABLE_BOTAO_VISUALIZAR_AVALIACAO =
  "botao.visualizar_avaliacao";
export const MENTORIA_TABLE_NAO_HA_MENTORIAS = "nao_ha_mentorias";
export const MENTORIA_TABLE_VISUALIZA_AVALIACAO_TITULO =
  "visualiza_avaliacao.titulo";
export const MENTORIA_TABLE_VISUALIZA_AVALIACAO_SUBTITULO1 =
  "visualiza_avaliacao.subtitulo1";
export const MENTORIA_TABLE_VISUALIZA_AVALIACAO_SUBTITULO2 =
  "visualiza_avaliacao.subtitulo2";
export const MENTORIA_TABLE_BODY_HORAS_AS = "body.horas_as";

/* GERENCIAMENTO PERFIS */
export const GERENCIAMENTO_PERFIS = "gerenciamento-perfis";
export const GERENCIAMENTO_PERFIS_COMO_MENTOR = "como_mentor";
export const GERENCIAMENTO_PERFIS_COMO_MENTORADO = "como_mentorado";

/* PERFIS MENU */
export const PERFIL_MENU_SOURCE = "perfis-menu";
export const PERFIL_MENU_ADMINISTRADOR = "perfil_menu.administrador";
export const PERFIL_MENU_HOME = "perfil_menu.home";
export const PERFIL_MENU_SEJA_MENTOR = "perfil_menu.seja_mentor";
export const PERFIL_MENU_FAQ = "perfil_menu.faq";
export const PERFIL_MENU_SAIR = "sair";
export const PERFIL_MENU_SAUDACAO = "nav_links.ola";
export const PERFIL_MENU_NOTIFICACAO_TITULO = "nav_links.notificacao.titulo";
export const PERFIL_MENU_NOTIFICACAO_SUBTITULO_P1 =
  "nav_links.notificacao.subtitulo_p1";
export const PERFIL_MENU_NOTIFICACAO_SUBTITULO_P2 =
  "nav_links.notificacao.subtitulo_p2";
export const PERFIL_MENU_NOTIFICACAO_SUBTITULO_P3 =
  "nav_links.notificacao.subtitulo_p3";
export const PERFIL_MENU_NOTIFICACAO_RODAPE = "nav_links.notificacao.rodape";
export const PERFIL_MENU_SEM_NOTIFICACAO = "nav_links.sem_notificacao";

/* ATUALIZACAO MENTOR MODAL */
export const ATUALIZACAO_MENTOR_MODAL_SOURCE = "atualizacao-mentor";
export const ATUALIZACAO_MENTOR_MODAL_FOTO = "form.foto";
export const ATUALIZACAO_MENTOR_MODAL_NOME = "form.nome";
export const ATUALIZACAO_MENTOR_MODAL_CARGO = "form.cargo";
export const ATUALIZACAO_MENTOR_MODAL_DESCRICAO = "form.descricao";
export const ATUALIZACAO_MENTOR_MODAL_CATEGORIAS = "form.categorias";
export const ATUALIZACAO_MENTOR_MODAL_MAX_CATEGORIAS = "form.max_categorias";
export const ATUALIZACAO_MENTOR_MODAL_IDIOMAS = "form.idiomas";
export const ATUALIZACAO_MENTOR_MODAL_MIN_IDIOMAS = "form.min_idiomas";
export const ATUALIZACAO_MENTOR_MODAL_MAX_IDIOMAS = "form.max_idiomas";
export const ATUALIZACAO_MENTOR_MODAL_DIA = "form.dia";
export const ATUALIZACAO_MENTOR_MODAL_HORARIO = "form.horario";
export const ATUALIZACAO_MENTOR_MODAL_DISPONIBILIDADE = "form.disponibilidade";
export const ATUALIZACAO_MENTOR_MODAL_ATUALIZAR = "botao.atualizar";
export const ATUALIZACAO_MENTOR_MODAL_VOLTAR = "botao.voltar";
export const ATUALIZACAO_MENTOR_MODAL_TELEFONE = "form.telefone";
export const ATUALIZACAO_MENTOR_MODAL_TIMEZONE = "form.timezone";
export const ATUALIZACAO_MENTOR_MODAL_ALERT_SUCESSO = "alert_sucesso";
export const ATUALIZACAO_MENTOR_CONFIRMACAO_TITULO = "confirmacao.titulo";
export const ATUALIZACAO_MENTOR_CONFIRMACAO_SUBTITULO = "confirmacao.subtitulo";
export const ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_NAO = "confirmacao.botao.nao";
export const ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_SIM = "confirmacao.botao.sim";
export const ATUALIZACAO_MENTOR_CAMPO_PAIS_PLACEHOLDER = "form.pais";
export const ATUALIZACAO_MENTOR_CAMPO_UF_PLACEHOLDER = "form.uf";
export const ATUALIZACAO_MENTOR_MODAL_EMAIL = "form.email";
export const ATUALIZACAO_MENTOR_MODAL_ALTERAR_SENHA = "botao.alterar_senha";

/* DAY OF WEEK AND TIME ROW */
export const DAY_OF_WEEK_AND_TIME_ROW_SOURCE = "dayofweektime-row";
export const DAY_OF_WEEK_AND_TIME_ROW_DIA = "form.dia";
export const DAY_OF_WEEK_AND_TIME_ROW_HORARIO = "form.horario";
export const DAY_OF_WEEK_AND_TIME_CANCEL = "form.cancel";
export const DAY_OF_WEEK_AND_TIME_CONFIRM = "form.confirm";

/* AVALIACAO MENTORIA */
export const AVALIACAO_MENTORIA_SOURCE = "avaliacao-mentoria";
export const AVALIACAO_MENTORIA_QUESTOES_ACONTECEU = "questoes.aconteceu";
export const AVALIACAO_MENTORIA_QUESTOES_AVALIA = "questoes.avalia";
export const AVALIACAO_MENTORIA_COMENTARIOS_TITULO = "comentarios.titulo";
export const AVALIACAO_MENTORIA_COMENTARIOS_SUBTITULO = "comentarios.subtitulo";
export const AVALIACAO_MENTORIA_DIFICULDADES_TITULO = "dificuldades.titulo";
export const AVALIACAO_MENTORIA_DIFICULDADES_SUBTITULO =
  "dificuldades.subtitulo";
export const AVALIACAO_MENTORIA_ENVIAR = "enviar";
export const AVALIACAO_MENTORIA_CARACTERES = "caracteres";
export const AVALIACAO_MENTORIA_SIM = "sim";
export const AVALIACAO_MENTORIA_NAO = "nao";
export const AVALIACAO_MENTORIA_AGUARDANDO_AVALIACAO = "aguardando-avaliacao";
export const AVALIACAO_MENTORIA_AVALIACAO = "avaliacao";

/* TERMO DE USO */
export const TERMO_DE_USO_SOURCE = "termo-de-uso";

export const TERMO_DE_USO_PAGRAFO1_OLA = "paragrafo1.ola";
export const TERMO_DE_USO_PAGRAFO1_REGISTRO = "paragrafo1.registro";
export const TERMO_DE_USO_PAGRAFO1_ORIENTACOES = "paragrafo1.orientacoes";
export const TERMO_DE_USO_PAGRAFO1_LEIA = "paragrafo1.leia_atentamente";

export const TERMO_DE_USO_PAGRAFO2_PROGRAMA = "paragrafo2.programa";
export const TERMO_DE_USO_PAGRAFO2_PROCESSO = "paragrafo2.processo";

export const TERMO_DE_USO_PAGRAFO3_MENTOR = "paragrafo3.mentor";
export const TERMO_DE_USO_PAGRAFO3_TEXTO = "paragrafo3.texto";

export const TERMO_DE_USO_PAGRAFO4_TITULO = "paragrafo4.titulo";
export const TERMO_DE_USO_PAGRAFO4_CLAUSULA1 = "paragrafo4.clausula1";
export const TERMO_DE_USO_PAGRAFO4_CLAUSULA2 = "paragrafo4.clausula2";
export const TERMO_DE_USO_PAGRAFO4_CLAUSULA3 = "paragrafo4.clausula3";
export const TERMO_DE_USO_PAGRAFO4_CLAUSULA4 = "paragrafo4.clausula4";
export const TERMO_DE_USO_PAGRAFO4_CLAUSULA5 = "paragrafo4.clausula5";

export const TERMO_DE_USO_PAGRAFO5_TITULO = "paragrafo5.titulo";
export const TERMO_DE_USO_PAGRAFO5_TEXTO = "paragrafo5.texto";

/* FORM CONTATO */
export const FORM_CONTATO_SOURCE = "contato-form";
export const FORM_CONTATO_VALIDATIONS_CAMPO_OBRIGATORIO =
  "validations.campo_obrigatorio";
export const FORM_CONTATO_VALIDATIONS_EMAIL_INVALIDO =
  "validations.email_invalido";
export const FORM_CONTATO_EMAIL_NAO_ENVIADO = "validations.email_nao_enviado";
export const FORM_CONTATO_TITULO = "titulo";
export const FORM_CONTATO_RECOMENDACAO = "recomendacao";
export const FORM_CONTATO_NOME = "nome";
export const FORM_CONTATO_ASSUNTO = "assunto";
export const FORM_CONTATO_MENSAGEM = "mensagem";
export const FORM_CONTATO_EMAIL_ENVIADO = "email_enviado";
export const FORM_CONTATO_EMAIL = "email";
export const FORM_CONTATO_ENVIAR = "enviar";
export const FORM_CONTATO_TEXTO_MENSAGEM = "texto_mensagem";
export const FORM_CONTATO_SEU_EMAIL = "seu_email";
export const FORM_CONTATO_EMAIL_DESTINATARIO = "email_destinatario";

/* CATEGORIAS */
export const CATEGORIAS_SOURCE = "categorias";
export const CATEGORIAS_TITULO = "titulo";
export const CATEGORIAS_FORM_NOME_PT = "form.nomePt";
export const CATEGORIAS_FORM_DESCRICAO_PT = "form.descricaoPt";
export const CATEGORIAS_FORM_NOME_EN = "form.nomeEn";
export const CATEGORIAS_FORM_DESCRICAO_EN = "form.descricaoEn";
export const CATEGORIAS_FORM_NOME_ES = "form.nomeEs";
export const CATEGORIAS_FORM_DESCRICAO_ES = "form.descricaoEs";
export const CATEGORIAS_FORM_IMAGEM = "form.imagem";
export const CATEGORIAS_VALIDATIONS_CAMPO_OBRIGATORIO =
  "validations.campo_obrigatorio";
export const CATEGORIAS_VALIDATIONS_NOME_CURTO = "validations.nome_curto";
export const CATEGORIAS_VALIDATIONS_NOME_LONGO = "validations.nome_longo";
export const CATEGORIAS_VALIDATIONS_DESCRICAO_CURTA =
  "validations.descricao_curta";
export const CATEGORIAS_VALIDATIONS_DESCRICAO_LONGA =
  "validations.descricao_longa";
export const CATEGORIAS_BOTAO_VOLTAR = "botao.voltar";
export const CATEGORIAS_CADASTRO = "cadastro_categorias";
export const CATEGORIAS_PORTUGUES = "form.portugues";
export const CATEGORIAS_INGLES = "form.ingles";
export const CATEGORIAS_ESPANHOL = "form.espanhol";
export const CATEGORIAS_ALERT_SUCESSO = "alert_sucesso";
export const CATEGORIAS_FORM_APLICAR = "botao.aplicar";

/* PRIVATE APP */
export const PRIVATE_APP_SOURCE = "private-app";
export const PRIVATE_APP_TITULO_CADASTRO_MENTORIA = "titulo.cadastro_mentoria";
export const PRIVATE_APP_TITULO_CADASTRO_MENTOR = "titulo.cadastro_mentor";
export const PRIVATE_APP_TITULO_GERENCIAR_MENTORES =
  "titulo.gerenciar_mentores";
export const PRIVATE_APP_TITULO_GERENCIAR_MENTORIAS =
  "titulo.gerenciar_mentorias";
export const PRIVATE_APP_TITULO_GERENCIAR_CATEGORIAS =
  "titulo.gerenciar_categorias";
export const PRIVATE_APP_TITULO_CADASTRO_CATEGORIAS =
  "titulo.cadastro_categorias";
export const PRIVATE_APP_LOGIN_NAO_EFETUADO = "login_nao_efetuado";

/* RH */

/* RH - AVALIACOES */
export const RH_AVALIACOES_SOURCE = "rh-avaliacoes";
export const RH_AVALIACOES_AVALIACOES = "avaliacoes";
export const RH_AVALIACOES_VISUALIZAR = "visualizar_avaliacoes";
export const RH_AVALIACOES_DESRICAO = "descricao";
export const RH_AVALIACOES_DATA_MENTORIA = "data_mentoria";
export const RH_AVALIACOES_HORARIO_MENTORIA = "horario_mentoria";
export const RH_AVALIACOES_MENTORIA_ACONTECEU = "mentoria_aconteceu";
export const RH_AVALIACOES_SEM_AVALIACOES = "sem_avaliacoes";
export const RH_AVALIACOES_MENTORADO = "mentorado";
export const RH_AVALIACOES_MENTORIAS_AVALIADAS = "mentorias_avaliadas";
export const RH_AVALIACOES_SIM = "sim";
export const RH_AVALIACOES_NAO = "nao";
export const RH_AVALIACOES_MENTOR = "mentor";
export const RH_AVALIACOES_BUSCAR = "buscar";
export const RH_AVALIACOES_CATEGORIA = "categoria";
export const RH_AVALICAOES_DE = "de";

/* RH - CATEGORIAS */
export const RH_CATEGORIAS_SOURCE = "rh-categorias";
export const RH_CATEGORIAS_CATEGORIAS = "categorias";
export const RH_CATEGORIAS_NOME = "nome";
export const RH_CATEGORIAS_IMAGEM = "imagem";
export const RH_CATEGORIAS_DESCRICAO = "descricao";
export const RH_AVALIACOES_SEM_CATEGORIAS = "sem_categorias";
export const RH_AVALIACOES_GERENCIAR_CATEGORIAS = "gerenciar_categorias";
export const RH_CATEGORIAS_EDITAR = "editar";
export const RH_CATEGORIAS_BUSCAR = "buscar";
export const RH_CATEGORIAS_DE = "de";

/* RH - COLABORADORES */
export const RH_COLABORADORES_SOURCE = "rh-colaboradores";
export const RH_COLABORADORES_NOME = "nome";
export const RH_COLABORADORES_TELEFONE = "telefone";
export const RH_COLABORADORES_SEM_COLABORADORES = "sem_colaboradores";
export const RH_COLABORADORES_AGUARDANDO_APROVACAO = "aguardando_aprovacao";
export const RH_COLABORADORES_APROVADO = "aprovado";
export const RH_COLABORADORES_INATIVO = "inativo";
export const RH_COLABORADORES_GERENCIAR = "gerenciar_colaboradores";
export const RH_COLABORADORES_SEM_NOME = "sem_nome";
export const RH_COLABORADORES_EMAIL = "email";
export const RH_COLABORADORES_EDITAR = "editar";
export const RH_COLABORADORES_BUSCAR = "buscar";
export const RH_COLABORADORES_CARGO = "cargo";
export const RH_COLABORADORES_DE = "de";

/* RH - MENTORES  */
export const RH_MENTORES_SOURCE = "rh-mentores";
export const RH_MENTORES_NOME = "nome";
export const RH_MENTORES_IMAGEM = "imagem";
export const RH_MENTORES_DESCRICAO = "descricao";
export const RH_MENTORES_TELEFONE = "telefone";
export const RH_MENTORES_RELATORIO_GERAL = "relatorio_geral";
export const RH_MENTORES_RELATORIO_MENTORES = "relatorio_mentores";
export const RH_MENTORES_SEM_MENTORES = "sem_mentores";
export const RH_MENTORES_AGUARDANDO_APROVACAO = "aguardando_aprovacao";
export const RH_MENTORES_APROVADO = "aprovado";
export const RH_MENTORES_RECUSADO = "recusado";
export const RH_MENTORES_INATIVO = "inativo";
export const RH_MENTORES_AUSENTE = "ausente";
export const RH_GERENCIAR_MENTORES = "gerenciar_mentores";
export const RH_MENTORES_SEM_NOME = "sem_nome";
export const RH_MENTORES_EMAIL = "email";
export const RH_MENTORES_BUSCAR = "buscar";
export const RH_MENTORES_EDITAR = "editar";
export const RH_MENTORES_DE = "de";

/* RH - MENTORIAS */
export const RH_MENTORIAS_SOURCE = "rh-mentorias";
export const RH_MENTORIAS_MENTORADO = "mentorado";
export const RH_MENTORIAS_EMAIL_MENTORADO = "email_mentorado";
export const RH_MENTORIAS_SEM_MENTORIAS = "sem_mentorias";
export const RH_GERENCIAR_MENTORIAS = "gerenciar_mentorias";
export const RH_MENTORIAS_DATA = "data";
export const RH_MENTORIAS_HORARIO = "horario";
export const RH_MENTORIAS_AGENDADA = "agendada";
export const RH_MENTORIAS_CONCLUIDA = "concluida";
export const RH_MENTORIAS_AGUARDANDO_APROVACAO = "aguardando_aprovacao";
export const RH_MENTORIAS_NAO_REALIZADA = "nao_realizada";
export const RH_MENTORIAS_REAGENDADA = "reagendada";
export const RH_MENTORIAS_CANCELADA = "cancelada";
export const RH_MENTORIAS_MENTOR = "mentor";
export const RH_MENTORIAS_EDITAR = "editar";
export const RH_MENTORIAS_BUSCAR = "buscar";
export const RH_MENTORIAS_CATEGORIA = "categoria";
export const RH_MENTORIAS_SUCESSO_ATUALIZACAO = "sucesso_atualizacao";
export const RH_MENTORIAS_ERRO_ATUALIZACAO = "erro_atualizacao";
export const RH_MENTORIAS_DE = "de";

/* CANCELAR MENTORIA */
export const CANCELAR_MENTORIA = "cancelar-mentoria";
export const CANCELAR_MENTORIA_TITULO = "titulo";
export const CANCELAR_MENTORIA_PERGUNTA = "pergunta";
export const CANCELAR_MENTORIA_CANCELAR = "cancelar";
export const CANCELAR_MENTORIA_RETORNAR = "retornar";
export const CANCELAR_MENTORIA_BOTAO = "cancelar_mentoria_botao";

/* FAQ */
export const FAQ_SOURCE = "faq";
export const FAQ_TITLE = "titulo";
export const FAQ_SUBTITLE = "subtitulo";
export const FAQ_FOOTER = "rodape";
export const FAQ_CONTATO = "contato";

/* STATUS MENTORIA */
export const STATUS_MENTORIA_SOURCE = "status-mentoria";
export const STATUS_MENTORIA_AGENDADA = "agendada";
export const STATUS_MENTORIA_CONCLUIDA = "concluida";
export const STATUS_MENTORIA_AGUARDANDO_AVALIACAO = "aguardandoAvaliacao";
export const STATUS_MENTORIA_NAO_REALIZADA = "naoRealizada";
export const STATUS_MENTORIA_REAGENDADA = "reagendada";
export const STATUS_MENTORIA_CANCELADA = "cancelada";

/* GERENCIAMENTO MENTORIAS */
export const GERENCIAMENTO_MENTORIAS_SOURCE = "gerenciamento-mentorias";
export const GERENCIAMENTO_MENTORIAS_MENTOR_TITULO = "mentor.titulo";
export const GERENCIAMENTO_MENTORIAS_MENTOR_SUBTITULO = "mentor.subtitulo";
export const GERENCIAMENTO_MENTORIAS_MENTOR_HISTORICO_COMENTARIOS =
  "mentor.historico.comentarios";
export const GERENCIAMENTO_MENTORIAS_MENTORADO_TITULO = "mentorado.titulo";
export const GERENCIAMENTO_MENTORIAS_MENTORADO_SUBTITULO =
  "mentorado.subtitulo";
export const GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_TITULO =
  "mentorado.historico.titulo";
export const GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_SUBTITULO =
  "mentorado.historico.subtitulo";
export const GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_AVALIAR =
  "mentorado.historico.avaliar";
export const GERENCIAMENTO_MENTORIAS_VOLTAR = "voltar";
export const GERENCIAMENTO_MENTORIAS_AVALIACAO_TITULO =
  "avaliacao.sucesso.titulo";
export const GERENCIAMENTO_MENTORIAS_AVALIACAO_SUBTITULO =
  "avaliacao.sucesso.subtitulo";
export const GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_TITULO =
  "reagendamento.sucesso.titulo";
export const GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_SUBTITULO =
  "reagendamento.sucesso.subtitulo";
export const GERENCIAMENTO_MENTORIAS_CANCELAMENTO_SUCESSO =
  "cancelamento.sucesso.titulo";
export const GERENCIAMENTO_MENTORIAS_CANCELAMENTO_ERRO =
  "cancelamento.erro.titulo";
export const GERENCIAMENTO_MENTORIAS_REAGENDAMENTO_SUCESSO =
  "reagendamento.sucesso.titulo";
export const GERENCIAMENTO_MENTORIAS_CANCELAMENTO_MODAL_TITULO =
  "cancelamento.modal.titulo";
export const GERENCIAMENTO_MENTORIAS_CANCELAMENTO_MODAL_SUBTITULO =
  "cancelamento.modal.subtitulo";
export const GERENCIAMENTO_MENTORIAS_INFO_MESSAGE = "info_message";

/* MODAL DE MENTORIA */
export const MENTORIA_MODAL_SOURCE = "mentoria-modal";
export const MENTORIA_MODAL_BOTAO_REAGENDAR = "botao-reagendar";
export const MENTORIA_MODAL_BOTAO_CANCELAR = "botao-cancelar";
export const MENTORIA_MODAL_BOTAO_ATUALIZAR = "botao-atualizar";
export const MENTORIA_MODAL_DATA = "data";
export const MENTORIA_MODAL_HORARIO = "horario";
export const MENTORIA_MODAL_TITULO = "titulo";
export const MENTORIA_MODAL_DATA_MARCADA = "data_marcada";
export const MENTORIA_MODAL_HORARIO_MARCADO = "horario_marcado";
export const MENTORIA_MODAL_AVISO_MENTORADO_REAGENDAR_MENTORIA_TXT_1 = "aviso-mentorado-reagendar-mentoria-txt-1";
export const MENTORIA_MODAL_AVISO_MENTORADO_REAGENDAR_MENTORIA_TXT_2 = "aviso-mentorado-reagendar-mentoria-txt-2";
export const MENTORIA_MODAL_AVISO_MENTOR_REAGENDAR_MENTORIA_TXT_1 = "aviso-mentor-reagendar-mentoria-txt-1";
export const MENTORIA_MODAL_AVISO_MENTOR_REAGENDAR_MENTORIA_TXT_2 = "aviso-mentor-reagendar-mentoria-txt-2";
export const MENTORIA_MODAL_ATENCAO = "atencao";
export const MENTORIA_MODAL_BOTAL_AVANCAR = "botao-avancar";

/* EXCEPTION HANDLER */
export const EXCEPTION_HANDLER_SOURCE = "exception-handler";
export const EXCEPTION_HANDLER_ERRO_SESSAO_EXPIRADA = "erro.sessao_expirada";
export const EXCEPTION_HANDLER_ERRO_USUARIO_SENHA_INCORRETA =
  "erro.usuario_senha_incorreta";
export const EXCEPTION_HANDLER_ERRO_INESPERADO = "erro.inesperado";

/* DATE RANGE VALIDATIONS */
export const DATE_RANGE_SOURCE = "date-range";
export const DATE_RANGE_VALIDATIONS_POSTERIOR_A = "validations.posterior_a";
export const DATE_RANGE_VALIDATIONS_POSTERIOR_DATA_INICIO =
  "validations.posterior_data_inicio";
export const DATE_RANGE_VALIDATIONS_CAMPO_OBRIGATORIO =
  "validations.campo_obrigatorio";

/* BIFURCARODR DE AGENDAMENTO DE MENTORIA */
export const BIFURCARDOR_AGENDAMENTO_SOURCE = "bifurcador-agendamento";
export const BIFURCADOR_AGENDAMENTO_AGENDAR_MENTORIAS = "bifurcador_agendamento.agendar_mentor";
export const BIFURCADOR_AGENDAMENTO_POR_MENTOR =
  "bifurcador_agendamento.por_mentor";
export const BIFURCADOR_AGENDAMENTO_POR_CATEGORIA =
  "bifurcador_agendamento.por_categoria";
export const BIFURCADOR_AGENDAMENTO_POR_IDIOMA =
  "bifurcador_agendamento.por_idioma";

/* MODAL DE NOVO MENTOR */
export const NEW_MENTOR_MODAL_SOURCE = "new-mentor-modal";
export const NEW_MENTOR_MODAL_BOTAO_FECHAR = "new_mentor_modal.botao.fechar";

/* SEARCH CARD */
export const SEARCH_CARD_SOURCE = "search-card";
export const SEARCH_CARD_MENTOR = "search_card.mentor";
export const SEARCH_CARD_CATEGORIA = "search_card.categoria";

/* NOT FOUND */
export const NOT_FOUND_SOURCE = "not-found";
export const NOT_FOUND_NETWORK_ERROR = "not_found.network_error";
export const NOT_FOUND_SERVER_OFFLINE = "not_found.server_offline";

/* SHARE MENTORING */
export const SHARING_MENTORING_SOURCE = "sharing-mentoring";
export const SHARING_MENTORING_SHARE = "sharing_mentoring.share";

/*new messages */
export const NEW_MESSAGES_SOURCE = "novas-traducoes";
export const INPUT_INTERVALO = "label.ocorre_a_cada";
export const INPUT_DATA_INICIAL = "label.data_inicial";
export const INPUT_SEMANA = "label.1_semana";
export const INPUT_2_SEMANAS = "label.2_semanas";
export const INPUT_3_SEMANAS = "label.3_semanas";
export const INPUT_4_SEMANAS = "label.4_semanas";

export const DISPONIBILIDADE_SEMANAL = "disponibilidade.1_semana";
export const DISPONIBILIDADE_QUINZENAL = "disponibilidade.2_semanas";
export const DISPONIBILIDADE_3SEMANAS = "disponibilidade.3_semanas";
export const DISPONIBILIDADE_MENSAL = "disponibilidade.4_semanas";
