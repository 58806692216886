import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados ao colaborador.
 */
export default class ColaboradorService extends HttpClientService {
    constructor() {
        super('colaboradores')
    }

    /**
     * Obtém informações do colaborador através do email.
     * 
     * @param email O email do colaborador.
     * @returns Promise com o resultado do processo.
     */
    getByEmail(email: any): Promise<any>{
        
        const configRequest: MentoringConfigRequest ={
            url: `${this.getURL()}/${email}`,
            method: AxiosMethodsName.get,
            handleCredentials: false,
        }
        return super.executeMethod(configRequest).then((res) => {
            return res;
        })
    }

    /**
     * Obtém informações do colaborador através do id.
     * 
     * @param id O id do colaborador.
     * @returns Promise com o resultado do processo.
     */
    getColaboradorById(id: number): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/${id}/is-mentor`,
            method: AxiosMethodsName.get,
            handleCredentials: false,
        }

        return super.executeMethod(configRequest).then(res => res);
    }

    /**
     * Método de paginação do dashboard de Colaborador para o RH
     * 
     * @param page número da pagina
     * @param itensPerPage itens por página
     */
    getPagination(page: number, itensPerPage: number): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            params: {
                numeroPagina: page,
                itensPorPagina: itensPerPage,
            },
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Método de pesquisa com paginação do dashboard de Colaborador para o RH
     * 
     * @param page número da pagina
     * @param itensPerPage itens por página
     * @param search parâmetro de busca
     */
    dashboardSearch(page: number, itensPerPage: number, search?: any): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            params: {
                numeroPagina: page,
                itensPorPagina: itensPerPage,
                ...search
            },
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }
}