import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";
import { Mentor } from "../models/Mentor";
import { CancelTokenSource } from "axios";

/**
 * Service com métodos que auxiliam nos processos relacionados ao mentor.
 */
export default class MentorService extends HttpClientService {
    // SE FOR PRECISAR DE CANCELTOKEN:
    // searchToken: CancelTokenSource = Axios.CancelToken.source();
    constructor() {
        super('mentores')

    }

    /**
     * Salva/cadastra um mentor na base de dados.
     * 
     * @param nome O nome do mentor.
     * @param descricao A descricao referente ao mentor.
     * @param email O email do mentor.
     * @param foto A foto do perfil do mentor.
     * @param idiomas Os idiomas que o mentor se sente
     *                confortável em oferecer mentorias.
     * @param categorias As categorias que o mentor se sente
     *                   confortável em oferecer mentorias.
     * @param disponibilidades As disponibilidades do mentor.
     * @param timeZone O timezone do mentor.
     * @returns Promise com o resultado da requisição.
     */
    salvar(nome: any, descricao: any, email: any, foto: any, idiomas: any, categorias: any, disponibilidades: any, timeZone: any): Promise<any> {
        var data: any;
        data = {
            nome: nome, descricao: descricao, email: email,
            foto: foto, idiomas: idiomas, categorias: categorias,
            disponibilidades: disponibilidades, timeZone: timeZone
        }

        return super.post(data).then(
            (data) => { return data }
        ).catch(
            (err) => { return err }
        );
    }

    /**
     * Obtém todos os mentores cadastrados na base de dados.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getMentores(): Promise<Array<Mentor>> {
        return super.get<Array<Mentor>>().then(res => {
            return res.data;
        });
    }

    /**
     * Remove um mentor específico da base de dados.
     * 
     * @param id O id do mentor.
     * @returns Promise com o resultado da requisição.
     */
    delete(id: any): Promise<any> {
        return super.delete(id).then(() => {
        });
    }

    /**
     * Edita/altera um mentor específico na base de dados.
     * 
     * @param id O id do mentor a ser editado.
     * @param mentor O mentor a ser editado.
     * @returns Promise com o resultado da requisição.
     */
    editar(id: any, mentor: Mentor): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURLId(id),
            method: AxiosMethodsName.put,
            data: mentor,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(() => {
        });
    }

    /**
     * Edita/altera um mentor específico na base de dados.
     * 
     * @param id O id do mentor a ser editado.
     * @param mentor O mentor a ser editado.
     * @returns Promise com o resultado da requisição.
     */
    editarViaRoleMentor(id: any, mentor: Mentor): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURLId(id) + '/edicao-mentor',
            method: AxiosMethodsName.put,
            data: mentor,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(() => {
        });
    }

    /**
     * Obtém todos os mentores que possuem uma determinada categoria.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getMentoresPorCategoria(): Promise<Array<Mentor>> {
        return super.get<Array<Mentor>>().then(res => {
            return res.data;
        });
    }

    /**
     * Obtém um mentor específico através do seu id.
     * 
     * @param id O id do mentor.
     * @returns Promise com o resultado da requisição.
     */
    getById(id: any): Promise<any> {
        return super.getById(id).then(res => {
            return res.data;
        });
    }

    /**
     * Obtém as informações de um mentor específico através do seu id.
     * 
     * @param id O id do mentor.
     * @returns Promise com o resultado da requisição.
     */
    getInformacaoMentorEdicaoById(id: any): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURLId(id) + '/edicao-mentor',
            method: AxiosMethodsName.get
        }

        return super.executeMethod(configRequest).then(res => {
            return res;
        })
    }

    /**
     * Obtém a média de avaliações de um mentor.
     * 
     * @param id O id do mentor.
     * @returns Promise com o resultado da requisição.
     */
    getMediaAvaliacoes(id: any): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURLId(id) + '/media-avaliacoes',
            method: AxiosMethodsName.get
        }

        return super.executeMethod(configRequest).then(res => {
            return res;
        })
    }

    /**
     * Método de paginação do dashboard de mentor para o perfil de RH.
     * 
     * @param page O número da página atual.
     * @param itensPerPage O número de itens por página.
     * @returns Promise com o resultado da requisição.
     */
    getPagination(page: Number, itensPerPage: Number): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/dashboard?numeroPagina=${page}&itensPorPagina=${itensPerPage}`,
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Realiza busca por nome nos dashboards do perfil de RH.
     * 
     * @param page A página atual.
     * @param pageSize O número de elementos por página.
     * @param search O parâmetro/termo utilizado na busca.
     * @returns Promise com o resultado da requisição.
     */
    dashboardSearch(page: number, pageSize: number, search?: any): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL() + '/dashboard',
            params: {
                numeroPagina: page,
                itensPorPagina: pageSize,
                ...search
            },
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém/encontra todos os mentores que possuem um determinado nome.
     * 
     * @param name O nome do mentor.
     * @param cancelToken O token de cancelamento.
     * @returns Promise com o resultado da requisição.
     */
    findByName(name: string, cancelToken: CancelTokenSource): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL() + '/findByName',
            params: {
                'nome': name
            },
            method: AxiosMethodsName.get,
            handleCredentials: true,
            cancelToken: cancelToken.token
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém/encontra, de forma paginada, todos os mentores que possuem um determinado nome.
     * 
     * @param name O nome do mentor.
     * @param numeroPagina O número da página atual.
     * @param itensPorPagina O número de itens por página.
     * @returns Promise com o resultado da requisição.
     */
    findByNamePaginated(name: string, numeroPagina: number, itensPorPagina: number): Promise<any> {


        // this.searchToken.cancel("Operation canceled due to new request.");


        // this.searchToken = Axios.CancelToken.source();

        const configRequest: MentoringConfigRequest = {
            url: this.getURL() + '/findByName',
            params: {
                'nome': name,
                'numeroPagina': numeroPagina,
                'itensPorPagina': itensPorPagina
            },
            // cancelToken: this.searchToken.token,
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém/encontra todos os mentores que possuem um determinado nome
     * e uma determinada categoria.
     * 
     * @param nome O nome do mentor.
     * @param categoriaId O id da categoria.
     * @param numeroPagina O número da página atual.
     * @param itensPorPagina O número de itens por página.
     * @returns Promise com o resultado da requisição.
     */
    getMentorsByCategoryAndName(nome, categoriaId, numeroPagina, itensPorPagina): Promise<any[]> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/findByNameAndCategoria`,
            method: AxiosMethodsName.get,
            params: {
                nome,
                categoriaId,
                numeroPagina,
                itensPorPagina,
            },
            handleCredentials: true
        }
        return super.executeMethod(configRequest);
    }

    /**
     * Obtém as categorias que um mentor específico possui.
     * 
     * @param mentorId O id do mentor.
     * @returns Promise com o resultado da requisição.
     */
    getCategoriasByMentor(mentorId): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/${mentorId}/categorias`,
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(
            (res) => { return res }
        ).catch(err => { return err });
    }

    /**
     * Obtém todos os mentores que possuem um determinado nome.
     * 
     * @param name O nome do mentor.
     * @param cancelToken O token de cancelamento.
     * @returns Promise com o resultado da requisição.
     */
    getByName(name: string, cancelToken: CancelTokenSource): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL() + '/nome',
            params: {
                'nome': name
            },
            method: AxiosMethodsName.get,
            handleCredentials: true,
            cancelToken: cancelToken.token
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém/encontra todos os mentores que possuem/falam um determinado idioma.
     * 
     * @param nome O nome da query a ser realizada.
     * @param idioma O idioma que os mentores falam.
     * @param numeroPagina O número da página atual.
     * @param itensPorPagina O número de itens por página.
     * @returns Promise com o resultado da requisição.
     */
     findByIdioma(nome, idioma, numeroPagina, itensPorPagina): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL() + '/findByIdioma',
            params: {
                nome,
                idioma,
                numeroPagina,
                itensPorPagina,
            },
            method: AxiosMethodsName.get,
            handleCredentials: true,
        }

        return super.executeMethod(configRequest);
    }
}