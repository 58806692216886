import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { MentoriaService, RelatorioExcelService } from "../../../../../service";

/**
 * Props que RhMentorDashBoard espera receber.
 */
export default class RhMentoriaDashBoardProps implements WithTranslation, WithStyles {
    msg:any = ''
    mentoriaService: MentoriaService = new MentoriaService()
    relatorioExcelService: RelatorioExcelService = new RelatorioExcelService()
    t: any
    i18n: any
    tReady: any
    classes: any
}