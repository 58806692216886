import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { List, Container, Typography } from '@material-ui/core';

import { CategoriaCard } from './components';
import { Categoria } from '../../../../../../../../models';
import { SpinnerSplendis } from '../../../../../../../../commons';
import {
	AGENDAMENTO_MENTORIA,
	AGENDAMENTO_MENTORIA_SOURCE,
	AGENDAMENTO_MENTORIA_STEP_CATEGORIA_SEM_CATEGORIA,
	AGENDAMENTO_MENTORIA_STEP_CATEGORIA_TITULO
} from '../../../../../../../../messages';

const useStyles = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		borderRadius: '50%',
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		height: 25,
		width: 25,

		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			background: 'rgba(206,217,224,.5)',
			boxShadow: 'none',
		},
	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',

		'&:before': {
			backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
			content: '""',
			display: 'block',
			height: 25,
			width: 25,
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
	list: {
		alignSelf: "center",
		height: "80%",
		maxHeight: '20rem',
		overflowY: "auto",
		width: "58vw",

		'@media only screen and (max-width: 414px)': {
			paddingTop: '1rem',
			width: '70vw'
		},

		'@media only screen and (min-width: 415px) and (max-width: 720px)': {
			paddingTop: '1rem',
			width: '100%'
		}
	},
	container: {
		alignContent: 'center',
		backgroundColor: 'rgba(207, 212, 218, 0.32)',
		display: 'flex',
		flexDirection: 'column',
		height: '50vh',
		margin: '.75rem auto 1rem auto',
		padding: '0 1rem',
		width: "58vw",

		'@media only screen and (max-width: 414px)': {
			height: '40vh',
			width: '72vw'
		},

		'@media only screen and (min-width: 415px) and (max-width: 720px)': {
			height: '70vh'
		}
	},
	title: {
		'@media only screen and (max-width: 455px)': {
			fontSize: '0.8rem',
		}
	},
	panelHeader: {
		marginBottom: '15vh',
		marginTop: '2.3vh',
	},
	panelSubheader: {
		color: '#000',
		fontFamily: 'InfraRegular',
		fontSize: '.8rem',
		fontStyle: 'normal',
		fontWeight: 400,
		paddingBottom: '3.5rem',
		//marginBottom: '2vh',
		//marginTop: '.75rem',
	},
	wrapper: {
		marginTop: '1rem',
		top: '29vh',
		position: 'absolute',

		'@media only screen and (max-width: 455px)': {
			maxWidth: '90vw'
		},
		'@media only screen and (min-width: 456px) and (max-width: 1024px)': {
			maxWidth: '90vw'
		}
	},
});

/**
 * Componente que gerencia todos os aspectos referntes a escolha
 * da categoria no processo de agendamento de mentoria.
 * 
 * @param props Propriedades necessárias no processo de escolher
 *              uma categoria no agendamento.
 * @returns Um container com informações necessárias a escolha de
 *          categorias e as categorias a serem escolhidas.
 */
export default function CategoriaStepMentorado(props) {
	const classes = useStyles();

	/**
	 * Hook e função utilizados na tradução de textos do componente.
	 */
	const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

	const { isLoading, isDisabled, defaultValue, categorias, mentor } = props;

	const [categoriaSelected, setCategoriaSelected] = React.useState(String(defaultValue.id));
	const [query] = React.useState("");

	/**
	 * Desabilita ou não a lista dependendo se alguma categoria foi selecionada ou não.
	 */
	useEffect(() => {
		isDisabled(isNotValid);
	})

	/**
	 * Verifica se alguma categoria foi selecionada.
	 * 
	 * @returns True caso nenhuma categoria tenha sido selecionada, False caso contrário.
	 */
	const isNotValid = () => {
		return categoriaSelected === "0";
	}

	/**
	 * Gerencia o processo de seleção de uma categoria da lista.
	 * 
	 * @param e O evento que dispara a mudança no state
   *            de categoria selecionada.
	 * @param id O id da categoria selecionada.
	 */
	const handleChange = (e, id) => {

		props.handleChange("categoria", e, id);
		setCategoriaSelected(String(id));
	}

	/**
	 * Gera e obtém a lista de categorias disponíveis.
	 */
	const renderCategorias = categorias.length === 0 ?
		<h3>{t(AGENDAMENTO_MENTORIA_STEP_CATEGORIA_SEM_CATEGORIA, 'Não há categorias cadastradas')}</h3>
		:
		categorias.map((item: Categoria, i) => {
			return (
				<div key={item.id}>
					<CategoriaCard key={i} categoria={item} selected={String(item.id) === categoriaSelected} onClick={handleChange} />
				</div>
			)
		});

	return (
		<div className={classes.wrapper}>
			<Typography className={classes.panelSubheader}>
				<Typography style={{ color: "#030624", fontSize: "26px", textAlign: "center" }}>{t(AGENDAMENTO_MENTORIA, "Agendar Mentoria")}</Typography>
				{t(AGENDAMENTO_MENTORIA_STEP_CATEGORIA_TITULO, 'Escolha a categoria que mais se adequa ao tema que deseja abordar com ')}
				<strong>{mentor.nome}</strong>
			</Typography>
			<div className={classes.container}>
				<List className={classes.list}>
					{isLoading ? <SpinnerSplendis /> : (query ? query : renderCategorias)}
				</List>
			</div>
		</div>
	);
}