import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";
import { Categoria } from "../models/Categoria";

/**
 * Service com métodos que auxiliam nos processos relacionados a categoria.
 */
export default class CategoriaService extends HttpClientService {
    constructor() {
        super('categorias')
    }

    /**
     * Salva uma categoria cadastrada no perfil de RH.
     * 
     * @param nomePt O nome da categoria em Português.
     * @param descricaoPt A descrição da categoria em Português.
     * @param nomeEs O nome da categoria em Espanhol.
     * @param descricaoEs A descrição da categoria em Espanhol.
     * @param nomeEn O nome da categoria em Inglês.
     * @param descricaoEn A descrição da categoria em Inglês.
     * @param imagem A imagem da categoria.
     * @param status O status da categoria.
     * @returns Promise com o resultado do processo.
     */
    salvar(nomePt: any, descricaoPt: any, nomeEs: any, descricaoEs: any,
        nomeEn: any, descricaoEn: any, imagem: any, status: any): Promise<any> {

        var data: any;
        data = { nomePt: nomePt, descricaoPt: descricaoPt,  nomeEs: nomeEs, descricaoEs: descricaoEs,
            nomeEn: nomeEn, descricaoEn: descricaoEn,  imagem: imagem, status: status}

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            method: AxiosMethodsName.post,
            data: data,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then((response) => {
            return response;
        }).catch(
            (err) => { return err }
        );
    }

    /**
     * Obtém as categorias cadastradas.
     * 
     * @returns Promise com o resultado da busca.
     */
    getCategorias(): Promise<Array<Categoria>> {
        return super.get<Array<Categoria>>().then(res => {
            return res.data;
        }).catch(err => { 
            return Promise.reject(err);
        });
    }

    /**
     * Obtém as categorias no perfil de RH.
     * 
     * @returns Promise com o resultado da busca.
     */
    getCategoriasRH(): Promise<Array<Categoria>> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/rh`,
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(res => {
            return res.data;
        }).catch( (err) => { return err });
    }

    /**
     * Exclui uma categoria.
     * 
     * @param id O id da categoria.
     * @returns Promise com o resultado do processo de exclusão.
     */
    delete(id: any): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURLId(id),
            method: AxiosMethodsName.delete,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(res => {
            return res;
        }).catch( (err) => { return err });
    }

    /**
     * Edita uma categoria.
     * 
     * @param id O id da categoria.
     * @param data Os dados relativos àquela categoria.
     * @returns Promise com o resultado de edição.
     */
    editar(id: any, data:any): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURLId(id),
            method: AxiosMethodsName.put,
            data: data,
            handleCredentials: true
        }

        return super.executeMethod(configRequest).then(res => {
            return res;
        }).catch( (err) => { return err })
    }

    /**
     * Retorna os mentores que possuem determinado categoria.
     * 
     * @param categoriaId O id da categoria.
     */
    getMentoresByCategoria(categoriaId, numeroPagina, itensPorPagina) : Promise<any[]> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/${categoriaId}/mentor`,
            method: AxiosMethodsName.get,
            params: {
                numeroPagina,
                itensPorPagina,
            },
            handleCredentials: true
        }
        return super.executeMethod(configRequest);
    }
}