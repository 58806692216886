import React, { useContext, useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  makeStyles,
  StepConnector,
  withStyles,
  Typography,
} from "@material-ui/core";
import BackSVG from '../../../../../assets/svgs/navigate_back.svg';
import NextSVG from '../../../../../assets/svgs/navigate_next.svg';

import {
  CategoriaStepMentorado,
  AgendamentoMentoriaTermosDeUsoModal,
  AgendamentoMentoriaConfirmacaoPosTermosDeUsoModal,
} from "./components";
import MentorStepMentorado from '../por-mentor/components/steps/mentor/MentorStepMentorado';
import HorariosStepMentorado from '../por-mentor/components/steps/horarios/HorariosStepMentorado';
import RevisarStepMentorado from '../por-mentor/components/steps/revisar/RevisarStepMentorado';
import {
  CategoriaService,
  DisponibilidadeService,
  MentoriaService,
  UserContext
} from "../../../../../service";
import {
  Mentoria,
  DisponibilidadeMentor,
  Categoria,
  Mentor,
} from "../../../../../models";
import { SpinnerSplendis } from "../../../../../commons";
import {
  formatDateToMaterialUIDate,
  searchByData,
  searchById,
  ShowTranslationWarning,
} from "../../../../../utils/FormUtils";

import { useTranslation } from "react-i18next";
import {
  AgendamentoMentoriaHandleChange,
  MentoriaAttribute,
} from "../../../../../types";
import {
  AGENDAMENTO_MENTORIA_SOURCE,
  AGENDAMENTO_MENTORIA_INDICADOR_STEP_CATEGORIA,
  AGENDAMENTO_MENTORIA_INDICADOR_STEP_MENTORES,
  AGENDAMENTO_MENTORIA_INDICADOR_STEP_HORARIOS,
  AGENDAMENTO_MENTORIA_INDICADOR_STEP_REVISAR,
  AGENDAMENTO_MENTORIA_BOTAO_APLICAR,
  AGENDAMENTO_MENTORIA_BOTAO_VOLTAR,
  AGENDAMENTO_MENTORIA_BOTAO_PROXIMO
} from "../../../../../messages";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AccessTime, FlagOutlined, FolderOpen, PeopleAlt } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    width: '90%',
    margin: 'auto',
    backgroundColor: 'transparent',

    '@media only screen and (max-width: 455px)': {
      width: '100%'
    }
  },
  headSteps: {
    width: '100%',
    opacity: 0.8,
    padding: 0,
    paddingTop: '24px',
    marginTop: '3vh',

    '@media only screen and (max-width: 455px)': {
      paddingTop: 0,
      padding: 0,
      marginTop: '1vh'
    },
    '@media only screen and (min-width: 456px) and (max-width: 1280px)': {
      paddingTop: 0,
      padding: 0,
      marginTop: '1vh'
    }
  },
  backButton: {
    /* position: 'sticky',
    bottom: '50%',
    left: '5vh',
    height: '6.5rem',
    width: '6.5rem',
    color: '#003B4A',
    borderRadius: '50%',
    border: 'solid #003B4A 6px',
    marginBottom: '2vh',
    display: 'flex',
    overflowZ: 'none', */

    '@media only screen and (max-width: 720px)': {
      display: 'none'
    },
  },
  backButtonMobile: {
    display: 'none',

    '@media only screen and (max-width: 414px)': {
      bottom: '-75vh',
      color: '#003B4A',
      display: 'block',
      left: '18vw',
    },

    '@media only screen and (min-width: 415px) and (max-width: 720px)': {
      bottom: '-170vh',
      color: '#003B4A',
      display: 'block',
      left: '30vw',
    }
  },
  backButtonDisabled: {
   /*  position: 'sticky',
    bottom: '50%',
    left: '5vh',
    height: '6.5rem',
    width: '6.5rem',
    color: '#003B4A',
    borderRadius: '50%',
    border: 'solid 6px',
    marginBottom: '2vh',
    display: 'flex',
    overflowZ: 'none', */

    '@media only screen and (max-width: 720px)': {
      display: 'none'
    },
  },
  backButtonMobileDisabled: {
    display: 'none',

    '@media only screen and (max-width: 414px)': {
      bottom: '-75vh',
      display: 'block',
      left: '18vw',
    },

    '@media only screen and (min-width: 415px) and (max-width: 720px)': {
      bottom: '-170vh',
      display: 'block',
      left: '30vw',
    }
  },
  forwardButton: {
    bottom: '50%',
    right: '5vh',
    overflowY: 'hidden',

    '@media only screen and (max-width: 720px)': {
      display: 'none'
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: '50vh',
    maxHeight: '60vh',
    marginTop: '8rem',

    '@media only screen and (max-width: 414px)': {
      height: '55vh',
      marginTop: '12vh',
      maxHeight: '55vh'
    },
    '@media only screen and (min-width: 415px) and (max-width: 720px)': {
      height: 'auto'
    }
  },
  forwardButtonContent: {
    position: 'absolute',
    bottom: '23vh',
    right: '1.5%',
    width: '10vw'
  },
  forwardButtonActive: {
    /* position: 'sticky',
    bottom: '50%',
    right: '5vh',
    height: '6.5rem',
    width: '6.5rem',
    color: '#003B4A',
    borderRadius: '50%',
    border: 'solid #003B4A 6px',
    margin: '2vh',
    overflowZ: 'none', */

    '@media only screen and (max-width: 720px)': {
      display: 'none'
    },
  },
  forwardButtonMobileActive: {
    display: 'none',

    '@media only screen and (max-width: 414px)': {
      bottom: '-75vh',
      color: '#003B4A',
      display: 'block',
      right: '40vw',
    },

    '@media only screen and (min-width: 415px) and (max-width: 720px)': {
      bottom: '-170vh',
      color: '#003B4A',
      display: 'block',
      right: '35vw',
    }
  },
  forwardButtonDisabled: {
    /* position: 'sticky',
    bottom: '50%',
    right: '5vh',
    height: '6.5rem',
    width: '6.5rem',
    borderRadius: '50%',
    border: 'solid 6px',
    margin: '2vh',
    overflowZ: 'none', */

    '@media only screen and (max-width: 720px)': {
      display: 'none'
    },
  },
  forwardButtonMobileDisabled: {
    display: 'none',

    '@media only screen and (max-width: 414px)': {
      bottom: '-75vh',
      display: 'block',
      right: '40vw'
    },

    '@media only screen and (min-width: 415px) and (max-width: 720px)': {
      bottom: '-170vh',
      display: 'block',
      right: '35vw',
    }
  },
  backButtonContent: {
    position: 'absolute',
    bottom: '23vh',
    left: '1.5%',
    width: '10vw'
  },
  stepLabel: {
    color: '#1F1F1F',
    fontFamily: 'InfraRegular',
    fontSize: '.6rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '17.23px',
    textTransform: 'uppercase',

    '@media only screen and (max-width: 414px)': {
      marginTop: '-1vh',
      fontSize: '.5rem',
    },

    '@media only screen and (min-width: 415px) and (max-width: 1024px)': {
      fontSize: '.7rem',
    },
  },
  spanComponent: {
    display: 'flex',
    height: '130px'
  }
});

/**
 * Estiliza os conectores do passo a passo do processo de agendar mentoria.
 */
const ConnectorStyle = withStyles({
  alternativeLabel: {
    top: 22,

    '@media only screen and (max-width: 414px)': {
      top: 15
    }
  },
  active: {
    '& $line': {
      backgroundColor: '#FF6C00',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#FF6C00',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

/**
 * Estiliza os ícones do passo a passo do processo de agendar mentoria.
 */
const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#CFD4DA',
    width: 40,
    height: 40,
    display: 'flex',
    border: '2px solid #CFD4DA',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',

    '@media only screen and (max-width: 414px)': {
      padding: 13,
      width: 10,
      height: 10,
    }
  },
  active: {
    color: '#FF6C00',
    border: '2px solid #FF6C00',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    color: '#FF6C00',
    border: '2px solid #FF6C00',
  }
});

/**
 * Interface utilizada pelo componente MentoradoStepper e que descreve
 * seu métodos e propriedades.
 */
interface MentoradoStepperProps {
  idMentorado: number;
  handleNextStep?: Function;
}

/**
 * Enum utilizado no mapeamento de cada passo
 * do processo de agendar mentoria.
 */
enum StepsAgendamentoMentoria {
  STEP_CATEGORIA = 0,
  STEP_MENTOR = 1,
  STEP_HORARIOS = 2,
  STEP_REVISAO = 3
}

/**
 * Componente que gerencia o passo a passo do processo
 * de agendar mentoria.
 *
 * @param props Propriedades necessárias para a realização do
 *              processo de agendar mentoria.
 * @returns O gerenciamento do processo de agendar mentoria(botões de avançar
 *          e voltar, modais etc.)
 */
export default function MentoradoStepper(props: MentoradoStepperProps) {
  const classes = useStyles();

  // Hook e função utilizados para a tradução dos textos.
  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

  const { idMentorado, handleNextStep } = props;

  const categoriaService: CategoriaService = new CategoriaService();
  const mentoriaService: MentoriaService = new MentoriaService();
  const disponibilidadeService: DisponibilidadeService = new DisponibilidadeService();

  //States de listagem de opções sob demanda
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [disponibilidades, setDisponibilidades] = useState<DisponibilidadeMentor[]>([]);

  //State que guarda as informações da mentoria cadastrada pelo usuário(categoria, mentor, etc.);
  const [formObject, setFormObject] = React.useState<Mentoria>(new Mentoria());

  //State que define o período de carregamento de informações. Geralmente associado a um Spinner.
  const [loading, setLoading] = React.useState<boolean>(false);

  //State que guarda o Step ativo do Stepper. Requisições sob demanda(como a busca de disponibilidades) estão associadas a ela.
  const [activeStep, setActiveStep] = React.useState<number>(0);

  //States que controlam a abertura dos modais de confirmação do agendamento da mentoria(Termos de Uso, etc.).
  const [modalTermosDeUsoIsOpen, setModalTermosDeUsoIsOpen] = React.useState<boolean>(false);
  const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = React.useState<boolean>(false);

  //State que controla a disponibilidade do botão de "Pŕoximo", se ele está disponível para o clique ou não(validação).
  const [ButtonDisabledStatus, setButtonDisabledStatus] = React.useState<boolean>(true);

  const mentoradoTimeZone = useContext(UserContext).userObject.colaborador.timeZone;

  /**
   * Carrega todas as categorias disponíveis.
   */
  const loadCategorias = () => {
    setLoading(true);

    categoriaService
      .getCategorias()
      .then((categorias) => {
        setCategorias(categorias);
        setLoading(false);
      })
      .then(() => {
        setLoading(false);
      });

  };

  /**
   * Carrega todas as disponibilidades do mentor escolhido.
   */
  const loadDisponibilidades = () => {

    if (activeStep === StepsAgendamentoMentoria.STEP_HORARIOS) {
      setLoading(true);

      disponibilidadeService
        .getDisponibilidadesMentor(formObject.mentor.id, mentoradoTimeZone)
        .then((res) => {
          setLoading(false);
          setDisponibilidades(res.data);
        })
        .then(() => {
          setLoading(false);
        });

    }
  };

  /**
   * Effects populando arrays de informação do stepper.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadCategorias, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadDisponibilidades, [activeStep]);

  /**
   * Obtém os passos do processo de agendamento de mentoria.
   *
   * @returns Array com os passos do processo de agendamento de mentoria.
   */
  const getSteps = () => {
    return [
      t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_CATEGORIA, "Categorias"),
      t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_MENTORES, "Mentores"),
      t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_HORARIOS, "Horários"),
      t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_REVISAR, "Revisar"),
    ];
  };

  /**
   * Cria a variável steps com o array de passos.
   */
  const steps = getSteps();

  /**
   * Realiza uma busca por um objeto de acordo com o atributo e um id, e
   * retorna toda a informação sobre a estrutura.
   *
   * @param atributo Categoria ou horário.
   * @param id O id recebido da escolha da bifurcação.
   */
  const search = (atributo: MentoriaAttribute, id: any) => {
    const { DISPONIBILIDADE, CATEGORIA } = MentoriaAttribute;

    switch (atributo) {

      case DISPONIBILIDADE:
        return searchByData(disponibilidades, id);

      case CATEGORIA:
        return searchById(categorias, id);

      default:
        return "Not Found";
    }

  };

  /**
   * Limpa dinamicamente o form de acordo com o atributo a ser alterado. Como algumas
   * informações dependem de outras, caso uma seja alterada, outra deve ser limpa.
   *
   * @param attribute O atributo a ser alterado.
   * @param formObject O form no qual será realizado o processo de limpeza dos campos.
   * @param id O id referente ao atributo.
   * @returns O form modificado.
   */
  const cleanFormObjectDinamicallyByAttribute = (
    attribute: MentoriaAttribute,
    formObject: Mentoria,
    id?: string
  ) => {
    const { MENTOR, DISPONIBILIDADE, CATEGORIA } = MentoriaAttribute;

    switch (attribute) {

      case CATEGORIA:
        formObject[MENTOR] = new Mentor();
        formObject[DISPONIBILIDADE] = new DisponibilidadeMentor();
        setDisponibilidades([]);

        let newValue = search(CATEGORIA, String(id));
        if (formObject.categoria !== newValue) {
          formObject.mentor = new Mentor();
        }
        break;

      case MENTOR:
        formObject[DISPONIBILIDADE] = new DisponibilidadeMentor();
        setDisponibilidades([]);
        break;
    }

    return formObject;
  };

  /**
   * Realiza busca através do id e do atributo nos arrays de Categoria e Disponibilidade, e
   * retorna o respectivo item.
   *
   * @param attribute O atributo a ser buscado.
   * @param event O evento que dispara a busca.
   * @param id O id do atributo a ser buscado.
   * @param value O valor relativo ao atributo.
   * @returns O item relativo a categoria ou disponibilidade.
   */
  const searchForValueByAttributeAndIdentifiers = (
    attribute: MentoriaAttribute,
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLButtonElement>,
    id: string,
    value: any = null
  ) => {
    const { MENTOR, DISPONIBILIDADE, CATEGORIA } = MentoriaAttribute;

    switch (attribute) {

      case MENTOR:
        return value;

      case DISPONIBILIDADE:
        return search(DISPONIBILIDADE, id);

      case CATEGORIA:
        return search(CATEGORIA, id);

      default:
        return event.target.value;

    }
  };

  /**
   * Obtém cada um dos passos do processo de agendamento de mentoria.
   *
   * @param stepIndex O índice no passo do processo de agendamento de mentoria.
   * @returns Cada passo do processo de agendamento de mentoria de acordo com o índice.
   */
  const getStepContent = (stepIndex: number) => {
    const {
      STEP_CATEGORIA,
      STEP_MENTOR,
      STEP_HORARIOS,
      STEP_REVISAO } = StepsAgendamentoMentoria;

    switch (stepIndex) {
      case STEP_CATEGORIA:
        return (
          <CategoriaStepMentorado
            defaultValue={formObject.categoria}
            categorias={categorias ? categorias : []}
            handleChange={handleChange}
            isDisabled={setButtonDisabledStatus}
            isLoading={loading}
          />
        );

      case STEP_MENTOR:
        return (
          <MentorStepMentorado
            categoria={formObject.categoria}
            defaultValue={formObject.mentor?.id}
            handleChange={handleChange}
            isDisabled={setButtonDisabledStatus}
          />
        );

      case STEP_HORARIOS:
        return (
          <HorariosStepMentorado
            defaultValue={formObject.disponibilidade?.data}
            horarios={disponibilidades}
            handleChange={handleChange}
            isDisabled={setButtonDisabledStatus}
            isLoading={loading}
            mentor={formObject.mentor}
          />
        );

      case STEP_REVISAO:
        return (
          <RevisarStepMentorado
            categoria={formObject.categoria}
            mentor={formObject.mentor}
            disponibilidade={formObject.disponibilidade}
            handleStepEdit={handleStepEdit}
            handleConfirmation={handleModalOpen}
          />
        );
    }
  };

  /**
   * Gerencia atualizações no form e no processo de agendamento de mentoria.
   *
   * @param atributo O atributo que está sendo alterado no form.
   * @param event O evento que dipara a mudança no atributo do form.
   * @param id O id do atributo.
   * @param value O valor do atributo.
   */
  const handleChange: AgendamentoMentoriaHandleChange = (
    atributo,
    event,
    id = event.target.value,
    value = null
  ) => {

    let cleanedFormObject = cleanFormObjectDinamicallyByAttribute(
      atributo,
      formObject,
      id
    );

    let newValue = searchForValueByAttributeAndIdentifiers(
      atributo,
      event,
      id,
      value
    );

    cleanedFormObject[atributo] = newValue;

    setFormObject(cleanedFormObject);
  };

  /**
   * Gerencia o processo que salva o form ao final do agendamento e
   * habilita a abertura do modal de confirmação.
   */
  const handleSubmit = () => {

    setLoading(true);

    formObject.mentorado.id = idMentorado;

    if (formObject.disponibilidade) {
      formObject.disponibilidade.data = formatDateToMaterialUIDate(formObject.disponibilidade?.data);
    }

    mentoriaService
      .salvar(formObject)
      .then(() => {

        setModalConfirmacaoIsOpen(true);

      })
      .then(() => setLoading(false));

  };

  /**
   * Abre o modal dos termos de uso ao final do processo de agendamento de mentoria.
   */
  const handleModalOpen = () => {
    setModalTermosDeUsoIsOpen(true);
  };

  /**
   * Fecha o modal dos termos de uso ao final do processo de agendamento de mentoria.
   */
  const handleModalClose = () => {
    setModalTermosDeUsoIsOpen(false);
  };

  /**
   * Configura o state de passo ativo.
   *
   * @param step O passo do processo de agendamento de mentoria.
   */
  const handleStepEdit = (step: number) => {
    setActiveStep(step);
  };

  /**
   * Configura o state de passo ativo e verifica se há um próximo passo
   * no processo de agendamento.
   */
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (handleNextStep) {
      handleNextStep(true);
    }
  };

  /**
   * Configura o state de passo ativo e verifica se há um passo anterior
   * no processo de agendamento.
   */
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    if (handleNextStep) {
      if (activeStep === 1) {
        handleNextStep(false);
      }
    }
  };

  /**
   * Gerencia o estado do botão para decidir se renderiza um próximo passo
   * no processo de agendamento de mentoria ou se abre o modal ao final do
   * processo.
   */
  const handleButton = () => {
    activeStep === steps.length - 1 ? handleModalOpen() : handleNext();
  };

  /**
   * Renderiza os modais de termo de uso e de confirmação após os termos de uso
   * ao final do processo de agendamento de mentoria.
   *
   * @returns Os modais de termo de uso e de confirmação após os termos de uso.
   */
  const renderModais = () => {
    return (
      <React.Fragment>

        <AgendamentoMentoriaTermosDeUsoModal
          open={modalTermosDeUsoIsOpen}
          handleClose={handleModalClose}
          onSubmit={() => {
            handleModalClose();
            handleSubmit();
          }}
        />

        <AgendamentoMentoriaConfirmacaoPosTermosDeUsoModal
          open={modalConfirmacaoIsOpen}
          onButtonClick={() => {
            window.location.href = "#/mentoring/home";
          }}
        />

      </React.Fragment>
    );
  };

  /**
   * Renderiza o botão de próximo passo no processo de agendamento de mentoria.
   *
   * @returns O botão de próximo passo no processo de agendamento de mentoria.
   */
  const renderNextButtonDynamically = () => {
    return loading && activeStep === steps.length - 1 ? (
      <SpinnerSplendis />
    ) : (
      activeStep < steps.length - 1 && <div className={classes.forwardButtonContent}>
        <Button
          disabled={ButtonDisabledStatus}
          onClick={handleButton}
          size='large'
        >
          <div>
              <img src={NextSVG} alt="Próximo" />
              <Typography style={{ color: "#FF6C00" }} >{t(AGENDAMENTO_MENTORIA_BOTAO_PROXIMO, 'Próximo')}</Typography>
          </div>
        </Button>
        <Button
          disabled={ButtonDisabledStatus}
          onClick={handleButton}
          className={ButtonDisabledStatus ? classes.forwardButtonMobileDisabled : classes.forwardButtonMobileActive}
          size='large'
        >
          <Typography>{t(AGENDAMENTO_MENTORIA_BOTAO_PROXIMO, 'Próximo')}</Typography>
        </Button>
        {activeStep === steps.length - 1 ? t(AGENDAMENTO_MENTORIA_BOTAO_APLICAR, 'Aplicar') : ''}
      </div>
    );
  };

  /**
   * Renderiza os ícones relativos a cada passo no processo de agendamento de mentoria.
   *
   * @param props Propriedades necessárias para a efetiva utilização dos ícones.
   * @returns O ícone relativo a cada passo no processo de agendamento de mentoria.
   */
  const StepIcons = (props) => {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <FolderOpen />,
      2: <PeopleAlt />,
      3: <AccessTime />,
      4: <FlagOutlined />
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  /**
   * Propriedades dos ícones do passo a passo do agendamento de mentoria.
   */
  StepIcons.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  /**
   * Renderiza os passos do processo de agendamento de mentoria, estilizando os
   * ícones de cada passo de acordo com o andamento do processo agendamento.
   *
   * @returns Os passos do processo de agendamento com os ícones estilizados de
   *          acordo com o andmento do processo de agendamento.
   */
  const StepperTimeline = () => {
    return (
      <Stepper
        style={{ backgroundColor: "transparent", padding: 0, opacity: "0.8" }}
        className={classes.headSteps}
        activeStep={activeStep}
        alternativeLabel
        connector={<ConnectorStyle />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcons}>
              <p className={classes.stepLabel}>{label}</p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  return (
    <span className={classes.spanComponent}>
      {/*TEMPORÁRIO*/}
      <ShowTranslationWarning />

      {renderModais()}

      <div className={classes.root}>

        <StepperTimeline />

        <div>

          <div className={classes.content}>{getStepContent(activeStep)}</div>

          <div className={classes.backButtonContent}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              size='large'
            >
              <div>
                  <img src={BackSVG} alt='Voltar' />
                  <Typography style={{ color: "#D3CAB7" }} >{t(AGENDAMENTO_MENTORIA_BOTAO_VOLTAR, 'Voltar')}</Typography>
              </div>
            </Button>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={activeStep === 0 ? classes.backButtonMobileDisabled : classes.backButtonMobile}
              size='large'
            >
              <Typography>{t(AGENDAMENTO_MENTORIA_BOTAO_VOLTAR, 'Voltar')}</Typography>
            </Button>
          </div>

          {renderNextButtonDynamically()}

        </div>

      </div>
    </span>
  );
}
