import { WithTranslation } from "react-i18next"
import { WithStyles } from "@material-ui/core"
import CategoriaService from "../../../../../service/CategoriaService"

/**
 * Props que RhCategoriasDashBoard espera receber.
 */
export default class RhCategoriasDashBoardProps implements WithTranslation, WithStyles  {
    msg:any = ''
    categoriaService: CategoriaService = new CategoriaService()
    tReady: any
    // Funções de tradução
    t: any
    i18n: any
    // Função de estilização
    classes:any
}