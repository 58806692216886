import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import {
  AGENDAMENTO_MENTORIA_SOURCE,
  AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONADO,
  AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONAR,
  AGENDAMENTO_MENTORIA_STEP_MENTORES_VEJA_MAIS,
} from "../../messages";

import BrazilFlag from "../../assets/flags/brasil.png";
import SpainFlag from "../../assets/flags/espanha.png";
import UsaFlag from '../../assets/flags/eua.png';
import GerFlag from '../../assets/flags/germany.png';
import PolFlag from '../../assets/flags/poland.png';
import ChiFlag from '../../assets/flags/china.png';
import RomFlag from '../../assets/flags/romania.png';
import FraFlag from '../../assets/flags/france.png';

import { Mentor } from "../../models";
import { SpinnerSplendis } from "../../commons";

export const iconesIdiomas  = {
    pt: BrazilFlag,
    es: SpainFlag,
    en: UsaFlag,
    de:GerFlag,
    fr:FraFlag,
    cn:ChiFlag,
    pl:PolFlag,
    ro:RomFlag,
};

const useStyles = makeStyles({
  root: {
    width: "16rem",
    margin: 5,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "52vh",
    paddingBottom: "1.2rem",

    "@media only screen and (max-width: 414px)": {
      height: "60vh",
      width: "70vw",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      height: "110vh",
      width: "41vw",
    },
  },
  name: {
    color: "#636466",
    fontFamily: "nexaRegular",
    fontSize: "1.3rem",
    lineHeight: "2.9rem",
    paddingBottom: 0,
    height: "2.8rem",
  },
  cargo: {
    fontFamily: "infraLight",
    fontWeight: 300,
    color: "#636466",
    paddingTop: 0,
    marginTop: "-2vh",
    fontSize: "0.775rem",
    lineHeight: "1.25rem",
  },
  mediaDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1.8rem",
    marginBottom: 0,
  },
  media: {
    display: "block",
    height: "5.5rem",
    width: "5.5rem",
    borderRadius: "50%",
  },
  descriptionContainer: {
    maxHeight: "100%",
    width: "100%",
  },
  cardContent: {
    padding: 10,
    height: "9vh",

    "@media only screen and (max-width: 414px)": {
      height: "13vh",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      height: "17vh",
    },
  },
  description: {
    color: "#1F1F1F",
    fontSize: "calc(12px + 0.2vw)",
    textAlign: "center",
    wordBreak: "break-word",
    overflow: "auto",
    textOverflow: "ellipsis",
    fontFamily: "infraRegular",
    fontWeight: 400,
    height: "4rem",
  },
  buttonContained: {
    width: "14rem",
    height: "2.75rem",
    margin: "0 auto",
    fontFamily: "infraMedium",
    marginBottom: "1rem",
    backgroundColor: "#003B4A",
    color: "#fff",

    '&:hover': {
      backgroundColor: "#003B4A",
      color: "#fff",
    },

    "@media only screen and (max-width: 414px)": {
      width: "65vw",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      width: "38vw",
    },
  },
  buttonOutlined: {
    width: "14rem",
    height: "2.75rem",
    margin: "0 auto",
    fontFamily: "infraMedium",
    marginBottom: "1rem",
    borderColor: "#003B4A",
    color: "#003B4A",

    "@media only screen and (max-width: 414px)": {
      width: "65vw",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      width: "38vw",
    },
  },
  languagens: {
    position: "absolute",
    display: "flex",
    top: 0,
    right: 0,
  },
  flagsDiv: {
    margin: "0.3rem",
  },
  flags: {
    width: "1.1rem",
    height: "1.1rem",
    margin: "0.24rem",
  },
  divider: {
    width: "14rem",
    marginBottom: "1rem",
    marginTop: "4rem",

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      marginTop: "30vh",
    },
  },
  seeMore: {
    border: "none",
    backgroundColor: "white",
    color: "#003B4A",
  },
});

/**
 * Interface utilizada pelo componente MentorCard e que descreve
 * seu métodos e propriedades.
 */
export interface MentorCardProps {
  mentor?: Mentor;
  showButton?: boolean;
  handleButton?: Function;
  selected?: boolean;
  style?: any;
}

/**
 * Componente responsável por apresentar as informações do mentor, foto, cargo, descrição, idiomas
 * e selecionar o mesmo em caso de agendamento de mentoria.
 *
 * @param {MentorCardProps}
 * @returns O card com as informações do mentor.
 */
const MentorCard: React.FC<MentorCardProps> = ({
  mentor,
  showButton = false,
  handleButton,
  selected,
  style,
}) => {
  const classes = useStyles();

  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

  const [textLimit, setTextLimit] = React.useState<number>(70);
  const [loading, setLoading] = React.useState(true);

  /**
   * Configura o loading para não aparecer sempre que o card é carregado.
   */
  React.useEffect(() => {
    if (mentor) {
      setLoading(false);
    }
  }, [mentor]);

  /**
   * Possibilita a visualização, de forma integral, do texto da descrição do mentor
   * que está truncado.
   *
   * @param text O texto da descrição do mentor.
   * @param characterLimit O limite de caracteres a ser exibido.
   * @returns O botão para que o usuário possa expandir o texto da descrição do mentor.
   */
  const showLimitedText = (
    text: string | undefined,
    characterLimit: number
  ) => {
    if (!text) {
      return "";
    }
    if (text.length <= characterLimit) {
      return text;
    }
    return (
      <span>
        {text.slice(0, characterLimit - 1) + "..."}
        <button
          className={classes.seeMore}
          onClick={() => setTextLimit(text.length)}
        >
          {t(AGENDAMENTO_MENTORIA_STEP_MENTORES_VEJA_MAIS, "veja mais")}
        </button>
      </span>
    );
  };

  /**
   * Exibe, no card do mentor, os idiomas disponíveis na aplicação, assim como os idiomas que o mentor domina.
   *
   * @returns As bandeirinhas com os idiomas disponíveis na aplicação.
   */
  const Languages = () =>{
   
      return(
        <>
        {mentor?.idiomas.map((idioma) => (
          
          <img key={idioma.id} src={iconesIdiomas[idioma.sigla]} alt="language" className={classes.flags} />
        ))}
        </>
      )
    
    
  };

  /**
   * Renderiza o botão de seleção do mentor, exibindo as forma SELECIONAR ou SELECIONADO.
   *
   * @returns O botão de seleção do mentor.
   */
  const renderButton = () => {
    return (
      <>
        <hr className={classes.divider} />

        <Button
          className={selected ? classes.buttonContained : classes.buttonOutlined}
          onClick={(e) => {
            handleButton && handleButton(e, mentor?.id);
          }}
          size="medium"
          variant={selected ? "contained" : "outlined"}
        >
          {selected
            ? t(
                AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONADO,
                "Selecionado"
              )
            : t(
                AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONAR,
                "Selecionar"
              )}
        </Button>
      </>
    );
  };

  return (
    <Card style={style ? style : {}} className={classes.root}>
      {loading ? (
        <SpinnerSplendis />
      ) : (
        <>
          <div className={classes.mediaDiv}>
            <CardMedia
              className={classes.media}
              image={mentor?.foto}
              title={mentor?.nome}
            />
          </div>
          <CardContent className={classes.cardContent}>
            <div>
              <Typography
                gutterBottom
                className={classes.name}
                variant="h5"
                component="h3"
                noWrap={true}
              >
                {mentor?.nome}
              </Typography>
              <Typography
                gutterBottom
                className={classes.cargo}
                variant="body2"
                component="p"
                noWrap={true}
              >
                {mentor?.cargo}
              </Typography>
            </div>
            <div className={classes.languagens}>
              <div className={classes.flagsDiv}>
                {mentor?.idiomas&&(
                  <Languages/>
                )}
                
              </div>
            </div>
            <div className={classes.descriptionContainer}>
              <Typography
                className={classes.description}
                variant="body2"
                component="p"
              >
                {showLimitedText(mentor?.descricao, textLimit)}
              </Typography>
            </div>
          </CardContent>
          {showButton && renderButton()}
        </>
      )}
    </Card>
  );
};

export default MentorCard;
