import { formatToMaterialUIDate } from "./FormUtils";
// @ts-nocheck
/**
 * Interface do header da célula de uma tabelal
 */
export interface HeadCell {
  disablePadding: boolean;
  id: keyof any;
  label: string;
  numeric: boolean;
}

/**
 * Comparador que facilita o processo de ordenação da tabela.
 *
 * @param a O primeiro elemento da comparação para ordenação.
 * @param b O segundo elemento da comparação para ordenação.
 * @param orderBy A coluna pela qual a tabela deve ser ordenada.
 * @returns 1 se o elemento b for maior que o elemento a, -1 caso
 *          o elemento a for maior que o elemento b, 0 caso sejam
 *          iguais.
 */
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === "data") {
    //@ts-ignore
    if (
      // @ts-ignore
      new Date(formatToMaterialUIDate(a[orderBy])) <
      // @ts-ignore
      new Date(formatToMaterialUIDate(b[orderBy]))
    ) {
      return -1;
    }
    //@ts-ignore
    if (
      // @ts-ignore
      new Date(formatToMaterialUIDate(a[orderBy])) >
      // @ts-ignore
      new Date(formatToMaterialUIDate(b[orderBy]))
    ) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }

  return 0;
}

/**
 * Os tipos de ordenação de elementos de uma tabela.
 */
export type Order = "asc" | "desc";

/**
 * Obtém o tipo de ordenação de uma tabela.
 *
 * @param order O tipo de ordenação(e.g. desc ou asc).
 * @param orderBy A coluna pela qual a tabela deve ser ordenada.
 * @returns O comparador dependendo do tipo da ordenação desejada.
 */
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Ordena tabelas.
 *
 * @param array Array com os dados da tabela.
 * @param comparator O comparador utilizado para realizar a ordenação.
 * @returns A tabela de forma ordenada.
 */
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
