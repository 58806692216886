import React from 'react';
import { makeStyles, createStyles, Theme, Button, Grid, Select, MenuItem } from '@material-ui/core';
import { getDateISO } from '../../../../../../utils/FormUtils';

import DateRange from '../date-range/DateRange';
import SelectUf from '../uf-filter/SelectUf';
import { useTranslation } from 'react-i18next';
import { RH_BOTAO_BUSCAR } from '../../../../../../messages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            width: '94%',
            margin: '0 auto',
        },
        submitButton: {
            backgroundColor: '#003B4A',
            color: '#FFF',
            fontFamily: 'InfraRegular',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            height: '42px',
            paddingTop: '2px',
            width: '130px',

            '& > span': {
                paddingTop: '.3rem'
            },

            '&:hover': {
                backgroundColor: '#003B4A',
                opacity: '0.8',
            },

            '@media only screen and (max-width: 414px)': {
                width: '90px'
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '100px'
            }
        },
        filters: {
            height: 'auto',
            maxWidth: 'auto',
            display: 'flex',
            marginBottom: '2rem',
            justifyContent: 'space-between',
            marginTop: '5vh',
            flexWrap: 'wrap',

            '@media only screen and (max-width: 414px)': {
                justifyContent: 'space-evenly',
                marginTop: '3vh',
            },
        },
        dataRangeField: {

        },
        ufField: {
            marginRight: '2rem',

            '@media only screen and (max-width: 414px)': {
                marginRight: 0,
            }
        },
        select: {
            backgroundColor: '#FFF',
            height: '44px',
            width: '8vw',

            '@media only screen and (max-width: 414px)': {
                width: '28vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '20vw',
            }
        }
    })
);

/**
 * Interface utilizada pelo componente DashboardFilter e que descreve
 * seu métodos e propriedades.
 */
interface DashboardFilterProps {
    handleSubmit: (obj) => void
}

/**
 * Componente para apresentar e lidar com filtros na tela home do ADMIN,
 * apresenta os filtros de data inicial, data final, uf e região.
 * 
 * @param {DashboardFilterProps}
 * @returns Os filtros para datas inicial e final, uf e região.
 */
const DashboardFilter: React.FC<DashboardFilterProps> = ({ handleSubmit }) => {
    const classes = useStyles();

    // Hook utilizado no processo de tradução dos textos.
    const { t } = useTranslation(["admin"]);

    let initialDataRangeValues = {
        dataInicial: '2019-01-01',
        dataFinal: getDateISO(new Date())
    }

    const [dataRange, setDataRange] = React.useState<any>(initialDataRangeValues);
    const [uf, setUf] = React.useState<any>('');
    const [regiao, setRegiao] = React.useState<any>('BRASIL')

    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = React.useState<boolean>(false);

    /**
     * Lida com as mudanças dos intervalos de datas.
     * 
     * @param e O evento que dispara a mudança e que contém os valores das datas.
     */
    const handleDataRangeChange = (e) => {
        // Conferir se e.target.value vem com o formato correto de data.
        dataRange[e.target.name] = e.target.value;
        setDataRange(dataRange);
    }

    /**
     * Lida com as mudanças de escolha de estado.
     * 
     * @param e O evento que dispara a mudança que contém o valor da UF.
     */
    const handleUfChange = (e) => {
        setUf(e.target.value);
    }

    /**
     * Lida com a mudança do state do botão da mudança de datas.
     * 
     * @param state O state que determina se o botão está habilitado ou não.
     */
    const handleButtonState = (state) => {
        // Caso sejam acrescentados outros campos, conferir logicamente as validações aqui.
        setIsSubmitButtonEnabled(state);
    }

    /**
     * Chama a função do componente que o implementou,
     * retornando o objeto com os valores para realizar a filtragem.
     */
    const handleButtonSubmit = () => {
        let obj = {
            dataInicial: dataRange.dataInicial,
            dataFinal: dataRange.dataFinal,
            uf: uf,
            regiao: regiao
        }
        handleSubmit(obj);
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12} className={classes.filters}>
                    <div className={classes.dataRangeField}>
                        <DateRange handleChange={handleDataRangeChange} handleIsValid={handleButtonState} values={dataRange} />
                    </div>
                </Grid>
                <Grid item md={6} xs={12} className={classes.filters}>
                    {
                        regiao === "BRASIL" &&
                        (
                            <div className={classes.ufField}>
                                <SelectUf value={uf} handleChange={handleUfChange} />
                            </div>
                        )
                    }
                    <div className={classes.ufField}>
                        <Select
                            displayEmpty
                            value={regiao}
                            onChange={e => setRegiao(e.target.value)}
                            className={classes.select}
                            variant='outlined'
                        >
                            <MenuItem value="BRASIL">Brasil</MenuItem>
                            <MenuItem value="LATAM">Latam</MenuItem>
                        </Select>
                    </div>
                    <div>
                        <Button
                            className={classes.submitButton}
                            variant="outlined"
                            disabled={!isSubmitButtonEnabled}
                            onClick={handleButtonSubmit}
                        >
                            {t(RH_BOTAO_BUSCAR, "Buscar")}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardFilter;