import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, CardActionArea, Typography, useMediaQuery, capitalize } from '@material-ui/core';

import './CategoriaCard.css';
import { useTranslation } from 'react-i18next';
import { limitString } from '../../../../../../../../../../utils/FormUtils';

const useStyles2 = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			height: 60,
			margin: '.4rem 0',
		},
		rootDisabled: {
			display: 'flex',
			opacity: 0.3,
			height: 60,
			margin: '.4rem 0',
		},
		cardAction: {
			height: '100%',

			'@media only screen and (max-width: 414px)': {
				justifyContent: 'flex-start',
				paddingLeft: 10
			}
		},
		cardActionSelected: {
			backgroundColor: '#FF6C00',
			color: '#FFF',
			height: '100%',

			'@media only screen and (max-width: 414px)': {
				justifyContent: 'flex-start',
				paddingLeft: 10
			}
		},
		cardMedia: {
			border: '0px solid #FFF',
			borderRadius: '50%',
			margin: '.3rem 0 0 2rem',
			maxWidth: 60,
			width: '2.5rem',

			'@media only screen and (max-width: 414px)': {
				margin: '1rem 0 0 .5rem',
				width: '1.5rem',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				margin: '.5rem 0 0 1rem',
			}
		},
		cardContent: {
			display: 'flex',
			margin: '-3rem 0 0 5rem',

			'@media only screen and (max-width: 414px)': {
				margin: '-2.8rem 0 0 2rem',
				wordBreak: 'break-all'
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				margin: '-3.2rem 0 0 3.5rem',
				wordBreak: 'break-all'
			},

			'& > h6': {
				fontFamily: 'InfraRegular',
				fontSize: '24px',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '36px',

				'@media only screen and (max-width: 414px)': {
					fontSize: '20px'
				}
			},

			'@media only screen and (max-width: 455px)': {
				width: 'auto',
			}
		}
	})
);

/**
 * Card para cada categoria renderizada.
 * 
 * @param props Propriedades necessárias ao card da categoria.
 * @returns O card de categoria.
 */
export default function CategoriaCard(props) {

	const classes = useStyles2();

	/**
	 * Hook e função utilizados na tradução dos textos do card.
	 */
	const { i18n } = useTranslation();

	const isMobilePortrait = useMediaQuery('(max-width: 414px)');
	const isMobileLandscape = useMediaQuery('(max-width: 720px)');

	const { selected, onClick, disabled } = props;
	const { id, imagem } = props.categoria;
	const nome = props.categoria["nome" + capitalize(i18n.language)];


	return (
		<Card className={disabled ? classes.rootDisabled : classes.root}>

			<CardActionArea
				className={selected ? classes.cardActionSelected : classes.cardAction}
				onClick={(e) => onClick(e, id)}
				disabled={disabled}>

				<CardMedia
					component="img"
					className={classes.cardMedia}
					alt={nome}
					image={imagem}
					title={nome} />

				<CardContent className={classes.cardContent}>

					{isMobilePortrait ?
						<Typography variant="subtitle1">
							{limitString(nome, 10)}
						</Typography>
						:
						(isMobileLandscape ?
							<Typography variant="subtitle1">
								{limitString(nome, 18)}
							</Typography> :
							<Typography variant="h6">
								{nome}
							</Typography>)
					}
				</CardContent>

			</CardActionArea>
		</Card>
	);
}