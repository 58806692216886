import React from 'react';
import { ResponsivePie } from '@nivo/pie'

/**
 * Componente para renderizar gráfico de pizza.
 * 
 * @param props Propriedades passadas ao gráfico para customização do mesmo.
 * @returns O gráfico de pizza com seus ajustes e suas propriedades.
 */
export default function PieChart(props) {

    const { data, scheme } = props;

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 40, right: 40, bottom: 80, left: 40 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabel='id'
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#5e5d5d"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#FFFFFF"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    translateY: 56,
                    translateX: 10,
                    justify: false,
                    itemWidth: 89,
                    itemHeight: 18,
                    itemTextColor: '#5e5d5d',
                    symbolSize: 10,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    );
};