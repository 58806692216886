import React, { useContext, useEffect } from "react";
import MaskedInput from "react-text-mask";
import { DisponibilidadeMentor } from "../models/DisponibilidadeMentor";
import { Mentor } from "../models/Mentor";
import { Categoria } from "../models/Categoria";
import { Disponibilidade } from "../models/Disponibilidade";

import BrazilFlag from "../assets/flags/brasil.png";
import SpainFlag from "../assets/flags/espanha.png";
import { alertContext } from "../components";
import i18n from "../i18n";
import {
  STATUS_MENTORIA_AGENDADA,
  STATUS_MENTORIA_CONCLUIDA,
  STATUS_MENTORIA_AGUARDANDO_AVALIACAO,
  STATUS_MENTORIA_NAO_REALIZADA,
  STATUS_MENTORIA_REAGENDADA,
  STATUS_MENTORIA_CANCELADA,
  RH_MENTORES_AGUARDANDO_APROVACAO,
  RH_MENTORES_APROVADO,
  RH_MENTORES_RECUSADO,
  RH_MENTORES_INATIVO,
  RH_MENTORES_AUSENTE,
} from "../messages";
import {
  MSG_AVISO_TRADUCAO_EM_ANDAMENTO,
  MSG_AVISO_AGENDAMENTO_INDISPONIVEL,
} from "../messages";

/**
 * Realiza a validação do campo telefone.
 *
 * @param telefone O valor do telefone.
 * @returns True caso o valor do telefone tenha o tamanho correto, False caso contrário.
 */
export const validaTelefone = (telefone: string) => {
  let tel = formataTelefone(telefone);
  if (tel.length < 11) {
    return false;
  }
  return true;
};

/**
 * Expõe um array com todos os domínios de email permitidos na aplicação.
 */
export const emailDomains = [
  "@stefanini.com",
  "@belgo.com.br", 
  "@arcelormittal.com.br", 
  "@bekaert.com",
];

/**
 * Formata o valor do campo telefone.
 *
 * @param telefone O valor do telefone a ser formatado.
 * @returns O novo valor do telefone formatado.
 */
export const formataTelefone = (telefone: string) => {
  if (!telefone) {
    return "";
  }
  let newTelefone = "";
  for (let i = 0; i < telefone.length; i++) {
    if (
      telefone[i] === "(" ||
      telefone[i] === ")" ||
      telefone[i] === "-" ||
      telefone[i] === " " ||
      telefone[i] === "_"
    ) {
    } else {
      newTelefone = newTelefone + telefone[i];
    }
  }
  return newTelefone;
};

/**
 * Expõe a data atual no formato dd/mm/yyyy.
 *
 * @returns A data atual no formato dd/mm/yyyy.
 */
export const diaAtual = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  return "Data atual: " + dd + " / " + mm + " / " + yyyy;
};

/**
 * Expõe a hora da data atual no formato HH:MM.
 *
 * @returns A hora da data atual no formato HH:MM.
 */
export const getHour = () => {
  const date = new Date();

  const formattedHour = date.getHours() + ":" + date.getMinutes();

  return formattedHour;
};

/**
 * Expõe a data atual formatada no formato dd/mm/yyyy.
 *
 * @returns A data atual formatada no formato dd/mm/yyyy.
 */
export const diaAtualFormatted = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  return dd + "/" + mm + "/" + yyyy;
};

/**
 * Realiza busca de data no array de disponibilidades do mentor.
 *
 * @param field O array de disponibilidades do mentor.
 * @param data A data a ser buscada.
 * @returns A data, caso encontrada no array de disponibilidades do mentor,
 *          Not Found caso contrário.
 */
export const searchByData = (field: DisponibilidadeMentor[], data: string) => {
  for (let i = 0; i < field.length; i++) {
    if (field[i].data === data) {
      return field[i];
    }
  }
  return "Not Found";
};

/**
 * Busca um mentor, uma categoria ou uma disponibilidade com base no id.
 *
 * @param field O array de mentores ou categorias ou disponibilidades.
 * @param id O id do elemento que está sendo pesquisado.
 * @returns Um mentor ou uma categoria ou uma disponibilidade caso encontrados,
 *          Not Found caso contrário.
 */
export const searchById = (
  field: Mentor[] | Categoria[] | Disponibilidade[],
  id: string
) => {
  for (let i = 0; i < field.length; i++) {
    if (field[i]?.id === id) {
      return field[i];
    }
  }
  return "Not Found";
};

/**
 * Busca um mentor específico com base no id.
 *
 * @param field O array de mentores.
 * @param id O id do mentor a ser buscado.
 * @returns O mentor, caso o mesmo esteja no array, Not Found caso contrário.
 */
export const searchForMentorById = (field: Mentor[], id: number) => {
  for (let i = 0; i < field.length; i++) {
    if (field[i].id === id) {
      return field[i];
    }
  }
  return "Not Found";
};

/**
 * Expõe um array com os dias da semana útil.
 *
 * @returns Um array com os dias da semana útil.
 */
export const daysOfWeek = () => {
  return ["SEGUNDA", "TERCA", "QUARTA", "QUINTA", "SEXTA"];
};

/**
 * Mapeia os dias da semana em formato string para números inteiros.
 *
 * @param weekDay O dia da semana em formato string.
 * @returns Um número inteiro correspondente ao dia da semana pesquisado.
 */
export const mapWeekDayToOrdinal = (weekDay: string) => {
  switch (weekDay) {
    case "SEGUNDA":
      return 2;
    case "TERCA":
      return 3;
    case "QUARTA":
      return 4;
    case "QUINTA":
      return 5;
    case "SEXTA":
      return 6;
  }
};

/**
 * Mapeia os dias da semana em formato string para string em formato habitual.
 *
 * @param day O dia da semana em formato string.
 * @returns Um dia da semana em formato habitual.
 */
export const mapWeekDays = (day: string | undefined, t?) => {
  const dayToTranslate = `form.dias_da_semana.${day}`;
  switch (day) {
    case "SEGUNDA":
      return t(dayToTranslate, "Segunda-Feira");
    case "TERCA":
      return t(dayToTranslate, "Terça-Feira");
    case "QUARTA":
      return t(dayToTranslate, "Quarta-Feira");
    case "QUINTA":
      return t(dayToTranslate, "Quinta-Feira");
    case "SEXTA":
      return t(dayToTranslate, "Sexta-Feira");

    case "MONDAY":
      return t(dayToTranslate, "Segunda-Feira");
    case "TUESDAY":
      return t(dayToTranslate, "Terça-Feira");
    case "WEDNESDAY":
      return t(dayToTranslate, "Quarta-Feira");
    case "THURSDAY":
      return t(dayToTranslate, "Quinta-Feira");
    case "FRIDAY":
      return t(dayToTranslate, "Sexta-Feira");

    case "LUNES":
      return t(dayToTranslate, "Segunda-Feira");
    case "MARTES":
      return t(dayToTranslate, "Terça-Feira");
    case "MIÉRCOLES":
      return t(dayToTranslate, "Quarta-Feira");
    case "JUEVES":
      return t(dayToTranslate, "Quinta-Feira");
    case "VIERNES":
      return t(dayToTranslate, "Sexta-Feira");
  }
};
export const mapWeekDaysEn = (day: string | undefined, t?) => {
  const dayToTranslate = `form.dias_da_semana.${day}`;
  switch (day) {
    case "MONDAY":
      return t(dayToTranslate, "Segunda-Feira");
    case "TUESDAY":
      return t(dayToTranslate, "Terça-Feira");
    case "WEDNESDAY":
      return t(dayToTranslate, "Quarta-Feira");
    case "THURSDAY":
      return t(dayToTranslate, "Quinta-Feira");
    case "FRIDAY":
      return t(dayToTranslate, "Sexta-Feira");
  }
};
/**
 * Torna a primeira letra maiúscula de uma palavra.
 *
 * @param word A palavra a qual se pretende tornar a primeira letra maiúscula.
 * @returns A palavra formatada com a primeira letra maiúscula.
 */
export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);
};

/**
 * Expõe um array com todos os estados do Brasil.
 *
 * @returns Um array com todos os estados do Brasil.
 */
export const estadosBrasilToSelectField = () => {
  return [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
};

/**
 * Expõe um array de países escritos em Inglês.
 * Cada elemento do array é um objeto com o nome
 * do país e seu código de três letras.
 *
 * @returns Um array de objetos com o nome dos países
 *          e seus códigos de três letras.
 */
export const countriesListEnglish = () => {
  return [
    { name: "Afghanistan", code: "AFG" },
    { name: "Åland Islands", code: "ALA" },
    { name: "Albania", code: "ALB" },
    { name: "Algeria", code: "DZA" },
    { name: "American Samoa", code: "ASM" },
    { name: "Andorra", code: "AND" },
    { name: "Angola", code: "AGO" },
    { name: "Anguilla", code: "AIA" },
    { name: "Antarctica", code: "ATA" },
    { name: "Antigua and Barbuda", code: "ATG" },
    { name: "Argentina", code: "ARG" },
    { name: "Armenia", code: "ARM" },
    { name: "Aruba", code: "ABW" },
    { name: "Australia", code: "AUS" },
    { name: "Austria", code: "AUT" },
    { name: "Azerbaijan", code: "AZE" },
    { name: "Bahamas", code: "BHS" },
    { name: "Bahrain", code: "BHR" },
    { name: "Bangladesh", code: "BGD" },
    { name: "Barbados", code: "BRB" },
    { name: "Belarus", code: "BLR" },
    { name: "Belgium", code: "BEL" },
    { name: "Belize", code: "BLZ" },
    { name: "Benin", code: "BEN" },
    { name: "Bermuda", code: "BMU" },
    { name: "Bhutan", code: "BTN" },
    { name: "Bolivia", code: "BOL" },
    { name: "Bonaire, Sint Eustatius and Saba", code: "BES" },
    { name: "Bosnia and Herzegovina", code: "BIH" },
    { name: "Botswana", code: "BWA" },
    { name: "Bouvet Island", code: "BVT" },
    { name: "Brazil", code: "BRA" },
    { name: "British Indian Ocean Territory", code: "IOT" },
    { name: "Brunei Darussalam", code: "BRN" },
    { name: "Bulgaria", code: "BGR" },
    { name: "Burkina Faso", code: "BFA" },
    { name: "Burundi", code: "BDI" },
    { name: "Cambodia", code: "KHM" },
    { name: "Cameroon", code: "CMR" },
    { name: "Canada", code: "CAN" },
    { name: "Cape Verde", code: "CPV" },
    { name: "Cayman Islands", code: "CYM" },
    { name: "Central African Republic", code: "CAF" },
    { name: "Chad", code: "TCD" },
    { name: "Chile", code: "CHL" },
    { name: "China", code: "CHN" },
    { name: "Christmas Island", code: "CXR" },
    { name: "Cocos (Keeling) Islands", code: "CCK" },
    { name: "Colombia", code: "COL" },
    { name: "Comoros", code: "COM" },
    { name: "Congo", code: "COG" },
    { name: "Congo, Democratic Republic of the", code: "COD" },
    { name: "Cook Islands", code: "COK" },
    { name: "Costa Rica", code: "CRI" },
    { name: "Cote D'Ivoire", code: "CIV" },
    { name: "Croatia", code: "HRV" },
    { name: "Cuba", code: "CUB" },
    { name: "Curaçao", code: "CUW" },
    { name: "Cyprus", code: "CYP" },
    { name: "Czechia", code: "CZE" },
    { name: "Denmark", code: "DNK" },
    { name: "Djibouti", code: "DJI" },
    { name: "Dominica", code: "DMA" },
    { name: "Dominican Republic", code: "DOM" },
    { name: "Ecuador", code: "ECU" },
    { name: "Egypt", code: "EGY" },
    { name: "El Salvador", code: "SLV" },
    { name: "Equatorial Guinea", code: "GNQ" },
    { name: "Eritrea", code: "ERI" },
    { name: "Estonia", code: "EST" },
    { name: "Eswatini", code: "SWZ" },
    { name: "Ethiopia", code: "ETH" },
    { name: "Falkland Islands (Malvinas)", code: "FLK" },
    { name: "Faroe Islands", code: "FRO" },
    { name: "Fiji", code: "FJI" },
    { name: "Finland", code: "FIN" },
    { name: "France", code: "FRA" },
    { name: "French Guiana", code: "GUF" },
    { name: "French Polynesia", code: "PYF" },
    { name: "French Southern Territories", code: "ATF" },
    { name: "Gabon", code: "GAB" },
    { name: "Gambia", code: "GMB" },
    { name: "Georgia", code: "GEO" },
    { name: "Germany", code: "DEU" },
    { name: "Ghana", code: "GHA" },
    { name: "Gibraltar", code: "GIB" },
    { name: "Greece", code: "GRC" },
    { name: "Greenland", code: "GRL" },
    { name: "Grenada", code: "GRD" },
    { name: "Guadeloupe", code: "GLP" },
    { name: "Guam", code: "GUM" },
    { name: "Guatemala", code: "GTM" },
    { name: "Guernsey", code: "GGY" },
    { name: "Guinea", code: "GIN" },
    { name: "Guinea-Bissau", code: "GNB" },
    { name: "Guyana", code: "GUY" },
    { name: "Haiti", code: "HTI" },
    { name: "Heard Island and Mcdonald Islands", code: "HMD" },
    { name: "Holy See (Vatican City State)", code: "VAT" },
    { name: "Honduras", code: "HND" },
    { name: "Hong Kong", code: "HKG" },
    { name: "Hungary", code: "HUN" },
    { name: "Iceland", code: "ISL" },
    { name: "India", code: "IND" },
    { name: "Indonesia", code: "IDN" },
    { name: "Iran, Isamic Republic Of", code: "IRN" },
    { name: "Iraq", code: "IRQ" },
    { name: "Ireland", code: "IRL" },
    { name: "Isle of Man", code: "IMN" },
    { name: "Israel", code: "ISR" },
    { name: "Italy", code: "ITA" },
    { name: "Jamaica", code: "JAM" },
    { name: "Japan", code: "JPN" },
    { name: "Jersey", code: "JEY" },
    { name: "Jordan", code: "JOR" },
    { name: "Kazakhstan", code: "KAZ" },
    { name: "Kenya", code: "KEN" },
    { name: "Kiribati", code: "KIR" },
    { name: "Korea, Deocratic People'S Republic of", code: "PRK" },
    { name: "Korea, Reublic of", code: "KOR" },
    { name: "Kuwait", code: "KWT" },
    { name: "Kyrgyzstan", code: "KGZ" },
    { name: "Lao People'S Democratic Republic", code: "LAO" },
    { name: "Latvia", code: "LVA" },
    { name: "Lebanon", code: "LBN" },
    { name: "Lesotho", code: "LSO" },
    { name: "Liberia", code: "LBR" },
    { name: "Libyan Arab Jamahiriya", code: "LBY" },
    { name: "Liechtenstein", code: "LIE" },
    { name: "Lithuania", code: "LTU" },
    { name: "Luxembourg", code: "LUX" },
    { name: "Macao", code: "MAC" },
    { name: "Madagascar", code: "MDG" },
    { name: "Malawi", code: "MWI" },
    { name: "Malaysia", code: "MYS" },
    { name: "Maldives", code: "MDV" },
    { name: "Mali", code: "MLI" },
    { name: "Malta", code: "MLT" },
    { name: "Marshall Islands", code: "MHL" },
    { name: "Martinique", code: "MTQ" },
    { name: "Mauritania", code: "MRT" },
    { name: "Mauritius", code: "MUS" },
    { name: "Mayotte", code: "MYT" },
    { name: "Mexico", code: "MEX" },
    { name: "Micronesia, Federated States of", code: "FSM" },
    { name: "Moldova, Reublic of", code: "MDA" },
    { name: "Monaco", code: "MCO" },
    { name: "Mongolia", code: "MNG" },
    { name: "Montenegro", code: "MNE" },
    { name: "Montserrat", code: "MSR" },
    { name: "Morocco", code: "MAR" },
    { name: "Mozambique", code: "MOZ" },
    { name: "Myanmar", code: "MMR" },
    { name: "Namibia", code: "NAM" },
    { name: "Nauru", code: "NRU" },
    { name: "Nepal", code: "NPL" },
    { name: "Netherlands", code: "NLD" },
    { name: "Netherlands Antilles", code: "ANT" },
    { name: "New Caledonia", code: "NCL" },
    { name: "New Zealand", code: "NZL" },
    { name: "Nicaragua", code: "NIC" },
    { name: "Niger", code: "NER" },
    { name: "Nigeria", code: "NGA" },
    { name: "Niue", code: "NIU" },
    { name: "Norfolk Island", code: "NFK" },
    { name: "North Macedonia", code: "MKD" },
    { name: "Northern Mariana Islands", code: "MNP" },
    { name: "Norway", code: "NOR" },
    { name: "Oman", code: "OMN" },
    { name: "Pakistan", code: "PAK" },
    { name: "Palau", code: "PLW" },
    { name: "Palestine, State of", code: "PSE" },
    { name: "Panama", code: "PAN" },
    { name: "Papua New Guinea", code: "PNG" },
    { name: "Paraguay", code: "PRY" },
    { name: "Peru", code: "PER" },
    { name: "Philippines", code: "PHL" },
    { name: "Pitcairn", code: "PCN" },
    { name: "Poland", code: "POL" },
    { name: "Portugal", code: "PRT" },
    { name: "Puerto Rico", code: "PRI" },
    { name: "Qatar", code: "QAT" },
    { name: "Reunion", code: "REU" },
    { name: "Romania", code: "ROU" },
    { name: "Russian Federation", code: "RUS" },
    { name: "Rwanda", code: "RWA" },
    { name: "Saint Barthélemy", code: "BLM" },
    { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SHN" },
    { name: "Saint Kitts and Nevis", code: "KNA" },
    { name: "Saint Lucia", code: "LCA" },
    { name: "Saint Martin (French part)", code: "MAF" },
    { name: "Saint Pierre and Miquelon", code: "SPM" },
    { name: "Saint Vincent and the Grenadines", code: "VCT" },
    { name: "Samoa", code: "WSM" },
    { name: "San Marino", code: "SMR" },
    { name: "Sao Tome and Principe", code: "STP" },
    { name: "Saudi Arabia", code: "SAU" },
    { name: "Senegal", code: "SEN" },
    { name: "Serbia", code: "SRB" },
    { name: "Seychelles", code: "SYC" },
    { name: "Sierra Leone", code: "SLE" },
    { name: "Singapore", code: "SGP" },
    { name: "Sint Maarten (Dutch part)", code: "SXM" },
    { name: "Slovakia", code: "SVK" },
    { name: "Slovenia", code: "SVN" },
    { name: "Solomon Islands", code: "SLB" },
    { name: "Somalia", code: "SOM" },
    { name: "South Africa", code: "ZAF" },
    { name: "South Georgia and the South Sandwich Islands", code: "SGS" },
    { name: "South Sudan", code: "SSD" },
    { name: "Spain", code: "ESP" },
    { name: "Sri Lanka", code: "LKA" },
    { name: "Sudan", code: "SDN" },
    { name: "Suriname", code: "SUR" },
    { name: "Svalbard and Jan Mayen", code: "SJM" },
    { name: "Sweden", code: "SWE" },
    { name: "Switzerland", code: "CHE" },
    { name: "Syrian Arab Republic", code: "SYR" },
    { name: "Taiwan, Province of China", code: "TWN" },
    { name: "Tajikistan", code: "TJK" },
    { name: "Tanzania, Unted Republic of", code: "TZA" },
    { name: "Thailand", code: "THA" },
    { name: "Timor-Leste", code: "TLS" },
    { name: "Togo", code: "TGO" },
    { name: "Tokelau", code: "TKL" },
    { name: "Tonga", code: "TON" },
    { name: "Trinidad and Tobago", code: "TTO" },
    { name: "Tunisia", code: "TUN" },
    { name: "Turkey", code: "TUR" },
    { name: "Turkmenistan", code: "TKM" },
    { name: "Turks and Caicos Islands", code: "TCA" },
    { name: "Tuvalu", code: "TUV" },
    { name: "Uganda", code: "UGA" },
    { name: "Ukraine", code: "UKR" },
    { name: "United Arab Emirates", code: "ARE" },
    {
      name: "United Kingdom of Great Britain and Northern Ireland",
      code: "GBR",
    },
    { name: "United States of America", code: "USA" },
    { name: "United States Minor Outlying Islands", code: "UMI" },
    { name: "Uruguay", code: "URY" },
    { name: "Uzbekistan", code: "UZB" },
    { name: "Vanuatu", code: "VUT" },
    { name: "Venezuela", code: "VEN" },
    { name: "Viet Nam", code: "VNM" },
    { name: "Virgin Islands (Brtish)", code: "VGB" },
    { name: "Virgin Islands (U.S.)", code: "VIR" },
    { name: "Wallis and Futuna", code: "WLF" },
    { name: "Western Sahara", code: "ESH" },
    { name: "Yemen", code: "YEM" },
    { name: "Zambia", code: "ZMB" },
    { name: "Zimbabwe", code: "ZWE" },
  ];
};

/**
 * Expõe um array de países escritos em Português.
 * Cada elemento do array é um objeto com o nome
 * do país e seu código de três letras.
 *
 * @returns Um array de objetos com o nome dos países
 *          e seus códigos de três letras.
 */
export const countriesListPortuguese = () => {
  return [
    { name: "Afeganistão", code: "AFG" },
    { name: "África do Sul", code: "ZAF" },
    { name: "Albânia", code: "ALB" },
    { name: "Alemanha", code: "DEU" },
    { name: "Andorra", code: "AND" },
    { name: "Angola", code: "AGO" },
    { name: "Anguilla", code: "AIA" },
    { name: "Antártida", code: "ATA" },
    { name: "Antígua e Barbuda", code: "ATG" },
    { name: "Arábia Saudita", code: "SAU" },
    { name: "Argentina", code: "ARG" },
    { name: "Argélia", code: "DZA" },
    { name: "Armênia", code: "ARM" },
    { name: "Aruba", code: "ABW" },
    { name: "Austrália", code: "AUS" },
    { name: "Azerbaijão", code: "AZE" },
    { name: "Áustria", code: "AUT" },
    { name: "Bahamas", code: "BHS" },
    { name: "Bahrein", code: "BHR" },
    { name: "Bangladesh", code: "BGD" },
    { name: "Barbados", code: "BRB" },
    { name: "Belize", code: "BLZ" },
    { name: "Benin", code: "BEN" },
    { name: "Bermuda", code: "BMU" },
    { name: "Bélgica", code: "BEL" },
    { name: "Bielorrússia", code: "BLR" },
    { name: "Bolívia", code: "BOL" },
    { name: "Bonaire", code: "BES" },
    { name: "Botswana", code: "BWA" },
    { name: "Bósnia e Herzegovina", code: "BIH" },
    { name: "Brasil", code: "BRA" },
    { name: "Brunei", code: "BRN" },
    { name: "Bulgária", code: "BGR" },
    { name: "Burkina Faso", code: "BFA" },
    { name: "Burundi", code: "BDI" },
    { name: "Butão", code: "BTN" },
    { name: "Cabo Verde", code: "CPV" },
    { name: "Camarões", code: "CMR" },
    { name: "Camboja", code: "KHM" },
    { name: "Canadá", code: "CAN" },
    { name: "Catar", code: "QAT" },
    { name: "Cazaquistão", code: "KAZ" },
    { name: "Chade", code: "TCD" },
    { name: "Chéquia", code: "CZE" },
    { name: "Chile", code: "CHL" },
    { name: "China", code: "CHN" },
    { name: "Chipre", code: "CYP" },
    { name: "Colômbia", code: "COL" },
    { name: "Comores", code: "COM" },
    { name: "Congo", code: "COG" },
    { name: "Coreia do Norte", code: "PRK" },
    { name: "Coreia do Sul", code: "KOR" },
    { name: "Costa do Marfim", code: "CIV" },
    { name: "Costa Rica", code: "CRI" },
    { name: "Croácia", code: "HRV" },
    { name: "Cuba", code: "CUB" },
    { name: "Curaçao", code: "CUW" },
    { name: "Dinamarca", code: "DNK" },
    { name: "Djibuti", code: "DJI" },
    { name: "Dominica", code: "DMA" },
    { name: "Egito", code: "EGY" },
    { name: "El Salvador", code: "SLV" },
    { name: "Emirados Árabes Unidos", code: "ARE" },
    { name: "Equador", code: "ECU" },
    { name: "Eritreia", code: "ERI" },
    { name: "Eslováquia", code: "SVK" },
    { name: "Eslovênia", code: "SVN" },
    { name: "Espanha", code: "ESP" },
    { name: "Estados Unidos da América", code: "USA" },
    { name: "Estônia", code: "EST" },
    { name: "Esvalbarde", code: "SJM" },
    { name: "Etiópia", code: "ETH" },
    { name: "Fiji", code: "FJI" },
    { name: "Filipinas", code: "PHL" },
    { name: "Finlândia", code: "FIN" },
    { name: "França", code: "FRA" },
    { name: "Gabão", code: "GAB" },
    { name: "Gana", code: "GHA" },
    { name: "Gâmbia", code: "GMB" },
    { name: "Geórgia", code: "GEO" },
    { name: "Gibraltar", code: "GIB" },
    { name: "Granada", code: "GRD" },
    { name: "Grécia", code: "GRC" },
    { name: "Groelândia", code: "GRL" },
    { name: "Guadalupe", code: "GLP" },
    { name: "Guatemala", code: "GTM" },
    { name: "Guernsey", code: "GGY" },
    { name: "Guiana", code: "GUY" },
    { name: "Guiana Francesa", code: "GUF" },
    { name: "Guão", code: "GUM" },
    { name: "Guiné", code: "GIN" },
    { name: "Guiné-Bissau", code: "GNB" },
    { name: "Guiné Equatorial", code: "GNQ" },
    { name: "Haiti", code: "HTI" },
    { name: "Honduras", code: "HND" },
    { name: "Hong Kong", code: "HKG" },
    { name: "Hungria", code: "HUN" },
    { name: "Iêmen", code: "YEM" },
    { name: "Ilha Bouvet", code: "BVT" },
    { name: "Ilha Christmas", code: "CXR" },
    { name: "Ilha de Man", code: "IMN" },
    { name: "Ilha Norfolk", code: "NFK" },
    { name: "Ilhas Aland", code: "ALA" },
    { name: "Ilhas Cayman", code: "CYM" },
    { name: "Ilhas Cocos (Keeling)", code: "CCK" },
    { name: "Ilhas Cook", code: "COK" },
    { name: "Ilhas Faroé", code: "FRO" },
    { name: "Ilhas Geórgia do Sul e Sandwich do Sul", code: "SGS" },
    { name: "Ilhas Heard e McDonald", code: "HMD" },
    { name: "Ilhas Malvinas", code: "FLK" },
    { name: "Ilhas Marianas do Norte", code: "MNP" },
    { name: "Ilhas Marshall", code: "MHL" },
    { name: "Ilhas Menores Distantes dos Estados Unidos", code: "UMI" },
    { name: "Ilhas Picárnia", code: "PCN" },
    { name: "Ilhas Salomão", code: "SLB" },
    { name: "Ilhas Turcas e Caicos", code: "TCA" },
    { name: "Ilhas Virgens Inglesas", code: "VGB" },
    { name: "Ilhas Virgens (USA)", code: "VIR" },
    { name: "Índia", code: "IND" },
    { name: "Indonésia", code: "IDN" },
    { name: "Iran", code: "IRN" },
    { name: "Iraque", code: "IRQ" },
    { name: "Irlanda", code: "IRL" },
    { name: "Islândia", code: "ISL" },
    { name: "Israel", code: "ISR" },
    { name: "Itália", code: "ITA" },
    { name: "Jamaica", code: "JAM" },
    { name: "Japão", code: "JPN" },
    { name: "Jersey", code: "JEY" },
    { name: "Jordânia", code: "JOR" },
    { name: "Kiribati", code: "KIR" },
    { name: "Kuwait", code: "KWT" },
    { name: "Laos", code: "LAO" },
    { name: "Lesoto", code: "LSO" },
    { name: "Letônia", code: "LVA" },
    { name: "Líbano", code: "LBN" },
    { name: "Libéria", code: "LBR" },
    { name: "Líbia", code: "LBY" },
    { name: "Liechtenstein", code: "LIE" },
    { name: "Lituânia", code: "LTU" },
    { name: "Luxemburgo", code: "LUX" },
    { name: "Macau", code: "MAC" },
    { name: "Macedônia do Norte", code: "MKD" },
    { name: "Madagascar", code: "MDG" },
    { name: "Malawi", code: "MWI" },
    { name: "Malásia", code: "MYS" },
    { name: "Maldivas", code: "MDV" },
    { name: "Mali", code: "MLI" },
    { name: "Malta", code: "MLT" },
    { name: "Marrocos", code: "MAR" },
    { name: "Martinica", code: "MTQ" },
    { name: "Mauritânia", code: "MRT" },
    { name: "Maurício", code: "MUS" },
    { name: "Mayotte", code: "MYT" },
    { name: "México", code: "MEX" },
    { name: "Micronésia", code: "FSM" },
    { name: "Moldávia", code: "MDA" },
    { name: "Mônaco", code: "MCO" },
    { name: "Mongólia", code: "MNG" },
    { name: "Montenegro", code: "MNE" },
    { name: "Montserrat", code: "MSR" },
    { name: "Moçambique", code: "MOZ" },
    { name: "Myanmar", code: "MMR" },
    { name: "Namíbia", code: "NAM" },
    { name: "Nauru", code: "NRU" },
    { name: "Nepal", code: "NPL" },
    { name: "Nicarágua", code: "NIC" },
    { name: "Niger", code: "NER" },
    { name: "Nigéria", code: "NGA" },
    { name: "Niue", code: "NIU" },
    { name: "Noruega", code: "NOR" },
    { name: "Nova Caledônia", code: "NCL" },
    { name: "Nova Zelândia", code: "NZL" },
    { name: "Omã", code: "OMN" },
    { name: "Países Baixos (Holanda)", code: "NLD" },
    { name: "Palau", code: "PLW" },
    { name: "Palestina", code: "PSE" },
    { name: "Panamá", code: "PAN" },
    { name: "Papua-Nova Guiné", code: "PNG" },
    { name: "Paquistão", code: "PAK" },
    { name: "Paraguai", code: "PRY" },
    { name: "Peru", code: "PER" },
    { name: "Polinésia Francesa", code: "PYF" },
    { name: "Polônia", code: "POL" },
    { name: "Porto Rico", code: "PRI" },
    { name: "Portugal", code: "PRT" },
    { name: "Quênia", code: "KEN" },
    { name: "Quirguistão", code: "KGZ" },
    { name: "Reino Unido", code: "GBR" },
    { name: "República Centro-Africana", code: "CAF" },
    { name: "República da Serra Leoa", code: "SLE" },
    { name: "República Democrática do Congo", code: "COD" },
    { name: "República Dominicana", code: "DOM" },
    { name: "Reunião", code: "REU" },
    { name: "Romênia", code: "ROU" },
    { name: "Ruanda", code: "RWA" },
    { name: "Rússia", code: "RUS" },
    { name: "Saara Ocidental", code: "ESH" },
    { name: "Samoa", code: "WSM" },
    { name: "Samoa Americana", code: "ASM" },
    { name: "Santa Lúcia", code: "LCA" },
    { name: "Santa Helena", code: "SHN" },
    { name: "San Marino", code: "SMR" },
    { name: "São Bartolomeu", code: "BLM" },
    { name: "São Cristóvão", code: "KNA" },
    { name: "São Martinho (França)", code: "MAF" },
    { name: "São Martinho (Países Baixos)", code: "SXM" },
    { name: "São Pedro e Miquelon", code: "SPM" },
    { name: "São Tomé e Príncipe", code: "STP" },
    { name: "São Vicente e Granadinas", code: "VCT" },
    { name: "Seicheles", code: "SYC" },
    { name: "Senegal", code: "SEN" },
    { name: "Sérvia", code: "SRB" },
    { name: "Singapura", code: "SGP" },
    { name: "Síria", code: "SYR" },
    { name: "Somália", code: "SOM" },
    { name: "Sri Lanka", code: "LKA" },
    { name: "Suazilândia", code: "SWZ" },
    { name: "Sudão", code: "SDN" },
    { name: "Sudão do Sul", code: "SSD" },
    { name: "Suécia", code: "SWE" },
    { name: "Suíça", code: "CHE" },
    { name: "Suriname", code: "SUR" },
    { name: "Tailândia", code: "THA" },
    { name: "Taiwan", code: "TWN" },
    { name: "Tajiquistão", code: "TJK" },
    { name: "Tanzânia", code: "TZA" },
    { name: "Terras Austrais e Antárticas Francesas", code: "ATF" },
    { name: "Território Britânico do Oceano Índico", code: "IOT" },
    { name: "Timor-Leste", code: "TLS" },
    { name: "Togo", code: "TGO" },
    { name: "Tonga", code: "TON" },
    { name: "Toquelau", code: "TKL" },
    { name: "Trinidad e Tobago", code: "TTO" },
    { name: "Tunísia", code: "TUN" },
    { name: "Turcomenistão", code: "TKM" },
    { name: "Turquia", code: "TUR" },
    { name: "Tuvalu", code: "TUV" },
    { name: "Ucrânia", code: "UKR" },
    { name: "Uganda", code: "UGA" },
    { name: "Uruguai", code: "URY" },
    { name: "Uzbequistão", code: "UZB" },
    { name: "Vanuatu", code: "VUT" },
    { name: "Vaticano", code: "VAT" },
    { name: "Venezuela", code: "VEN" },
    { name: "Vietnã", code: "VNM" },
    { name: "Wallis e Futuna", code: "WLF" },
    { name: "Zâmbia", code: "ZMB" },
    { name: "Zimbábue", code: "ZWE" },
  ];
};

/**
 * Expõe um array de países escritos em Espanhol.
 * Cada elemento do array é um objeto com o nome
 * do país e seu código de três letras.
 *
 * @returns Um array de objetos com o nome dos países
 *          e seus códigos de três letras.
 */
export const countriesListSpanish = () => {
  return [
    { name: "Afganistan", code: "AFG" },
    { name: "Albania", code: "ALB" },
    { name: "Alemania", code: "DEU" },
    { name: "Andorra", code: "AND" },
    { name: "Angola", code: "AGO" },
    { name: "Anguila", code: "AIA" },
    { name: "Antartida", code: "ATA" },
    { name: "Antigua y Barbuda", code: "ATG" },
    { name: "Arabia Saudi", code: "SAU" },
    { name: "Argelia", code: "DZA" },
    { name: "Argentina", code: "ARG" },
    { name: "Armenia", code: "ARM" },
    { name: "Aruba", code: "ABW" },
    { name: "Australia", code: "AUS" },
    { name: "Austria", code: "AUT" },
    { name: "Azerbaiyan", code: "AZE" },
    { name: "Bahamas", code: "BHS" },
    { name: "Bahrain", code: "BHR" },
    { name: "Bangladesh", code: "BGD" },
    { name: "Barbados", code: "BRB" },
    { name: "Belgica", code: "BEL" },
    { name: "Belice", code: "BLZ" },
    { name: "Benin", code: "BEN" },
    { name: "Bermudas", code: "BMU" },
    { name: "Bielorrusia", code: "BLR" },
    { name: "Birmania Myanmar", code: "MMR" },
    { name: "Bolivia", code: "BOL" },
    { name: "Bonaire", code: "BES" },
    { name: "Bosnia y Herzegovina", code: "BIH" },
    { name: "Botswana", code: "BWA" },
    { name: "Brasil", code: "BRA" },
    { name: "Brunei", code: "BRN" },
    { name: "Bulgaria", code: "BGR" },
    { name: "Burkina Faso", code: "BFA" },
    { name: "Burundi", code: "BDI" },
    { name: "Butan", code: "BTN" },
    { name: "Cabo Verde", code: "CPV" },
    { name: "Camboya", code: "KHM" },
    { name: "Camerun", code: "CMR" },
    { name: "Canada", code: "CAN" },
    { name: "Chad", code: "TCD" },
    { name: "Chequia", code: "CZE" },
    { name: "Chile", code: "CHL" },
    { name: "China", code: "CHN" },
    { name: "Chipre", code: "CYP" },
    { name: "Colombia", code: "COL" },
    { name: "Comores", code: "COM" },
    { name: "Congo", code: "COG" },
    { name: "Corea del Norte", code: "PRK" },
    { name: "Corea del Sur", code: "KOR" },
    { name: "Costa de Marfil", code: "CIV" },
    { name: "Costa Rica", code: "CRI" },
    { name: "Croacia", code: "HRV" },
    { name: "Cuba", code: "CUB" },
    { name: "Curacao", code: "CUW" },
    { name: "Dinamarca", code: "DNK" },
    { name: "Djibouti", code: "DJI" },
    { name: "Dominica", code: "DMA" },
    { name: "Ecuador", code: "ECU" },
    { name: "Egipto", code: "EGY" },
    { name: "El Salvador", code: "SLV" },
    { name: "El Vaticano", code: "VAT" },
    { name: "Emiratos Arabes Unidos", code: "ARE" },
    { name: "Eritrea", code: "ERI" },
    { name: "Eslovaquia", code: "SVK" },
    { name: "Eslovenia", code: "SVN" },
    { name: "España", code: "ESP" },
    { name: "Estados Unidos", code: "USA" },
    { name: "Estonia", code: "EST" },
    { name: "Esvalbarde", code: "SJM" },
    { name: "Etiopia", code: "ETH" },
    { name: "Fiji", code: "FJI" },
    { name: "Filipinas", code: "PHL" },
    { name: "Finlandia", code: "FIN" },
    { name: "Francia", code: "FRA" },
    { name: "Gabon", code: "GAB" },
    { name: "Gambia", code: "GMB" },
    { name: "Georgia", code: "GEO" },
    { name: "Georgia del sur y las islas Sandwich del sur", code: "SGS" },
    { name: "Ghana", code: "GHA" },
    { name: "Gibraltar", code: "GIB" },
    { name: "Granada", code: "GRD" },
    { name: "Grecia", code: "GRC" },
    { name: "Groelandia", code: "GRL" },
    { name: "Guadalupe", code: "GLP" },
    { name: "Guam", code: "GUM" },
    { name: "Guatemala", code: "GTM" },
    { name: "Guernsey", code: "GGY" },
    { name: "Guinea", code: "GIN" },
    { name: "Guinea Bissau", code: "GNB" },
    { name: "Guinea Ecuatorial", code: "GNQ" },
    { name: "Guyana", code: "GUY" },
    { name: "Guyana Francesa", code: "GUF" },
    { name: "Haiti", code: "HTI" },
    { name: "Honduras", code: "HND" },
    { name: "Hong Kong", code: "HKG" },
    { name: "Hungria", code: "HUN" },
    { name: "India", code: "IND" },
    { name: "Indonesia", code: "IDN" },
    { name: "Irán", code: "IRN" },
    { name: "Irak", code: "IRQ" },
    { name: "Irlanda", code: "IRL" },
    { name: "Islandia", code: "ISL" },
    { name: "Isla Bouvet", code: "BVT" },
    { name: "Isla de Navidad", code: "CXR" },
    { name: "Isla de Man", code: "IMN" },
    { name: "Isla Norfolk", code: "NFK" },
    { name: "Islas Aland", code: "ALA" },
    { name: "Islas Caimán", code: "CYM" },
    { name: "Islas Cocos (Keeling)", code: "CCK" },
    { name: "Islas Cook", code: "COK" },
    { name: "Islas Feroe", code: "FRO" },
    { name: "Islas Heard y McDonald", code: "HMD" },
    { name: "Islas Malvinas", code: "FLK" },
    { name: "Islas Marianas del Norte", code: "MNP" },
    { name: "Islas Marshall", code: "MHL" },
    { name: "Islas menores alejadas de los Estados Unidos", code: "UMI" },
    { name: "Islas Picarnia", code: "PCN" },
    { name: "Islas Salomón", code: "SLB" },
    { name: "Islas Turcas y Caicos", code: "TCA" },
    { name: "Islas Vírgenes Inglesas", code: "VGB" },
    { name: "Islas Vírgenes (Estados Unidos)", code: "VIR" },
    { name: "Israel", code: "ISR" },
    { name: "Italia", code: "ITA" },
    { name: "Jamaica", code: "JAM" },
    { name: "Japón", code: "JPN" },
    { name: "Jersey", code: "JEY" },
    { name: "Jordania", code: "JOR" },
    { name: "Kazajstan", code: "KAZ" },
    { name: "Kenia", code: "KEN" },
    { name: "Kirguistán", code: "KGZ" },
    { name: "Kiribati", code: "KIR" },
    { name: "Kuwait", code: "KWT" },
    { name: "Laos", code: "LAO" },
    { name: "Lesoto", code: "LSO" },
    { name: "Letonia", code: "LVA" },
    { name: "Libano", code: "LBN" },
    { name: "Liberia", code: "LBR" },
    { name: "Libia", code: "LBY" },
    { name: "Liechtenstein", code: "LIE" },
    { name: "Lituania", code: "LTU" },
    { name: "Luxemburgo", code: "LUX" },
    { name: "Macao", code: "MAC" },
    { name: "Macedonia del Norte", code: "MKD" },
    { name: "Madagascar", code: "MDG" },
    { name: "Malasia", code: "MYS" },
    { name: "Malawi", code: "MWI" },
    { name: "Maldivas", code: "MDV" },
    { name: "Mali", code: "MLI" },
    { name: "Malta", code: "MLT" },
    { name: "Marruecos", code: "MAR" },
    { name: "Martinica", code: "MTQ" },
    { name: "Mauricio", code: "MUS" },
    { name: "Mauritania", code: "MRT" },
    { name: "Mayotte", code: "MYT" },
    { name: "Mexico", code: "MEX" },
    { name: "Micronesia", code: "FSM" },
    { name: "Moldavia", code: "MDA" },
    { name: "Monaco", code: "MCO" },
    { name: "Mongolia", code: "MNG" },
    { name: "Montenegro", code: "MNE" },
    { name: "Montserrat", code: "MSR" },
    { name: "Mozambique", code: "MOZ" },
    { name: "Namibia", code: "NAM" },
    { name: "Nauru", code: "NRU" },
    { name: "Nepal", code: "NPL" },
    { name: "Nicaragua", code: "NIC" },
    { name: "Niger", code: "NER" },
    { name: "Nigeria", code: "NGA" },
    { name: "Niue", code: "NIU" },
    { name: "Noruega", code: "NOR" },
    { name: "Nueva Caledonia", code: "NCL" },
    { name: "Nueva Zelanda", code: "NZL" },
    { name: "Oman", code: "OMN" },
    { name: "Paises Bajos", code: "NLD" },
    { name: "Pakistán", code: "PAK" },
    { name: "Palau", code: "PLW" },
    { name: "Palestina", code: "PSE" },
    { name: "Panama", code: "PAN" },
    { name: "Papua Nueva Guinea", code: "PNG" },
    { name: "Paraguay", code: "PRY" },
    { name: "Peru", code: "PER" },
    { name: "Polinesia Francesa", code: "PYF" },
    { name: "Polonia", code: "POL" },
    { name: "Portugal", code: "PRT" },
    { name: "Puerto Rico", code: "PRI" },
    { name: "Qatar", code: "QAT" },
    { name: "Reino Unido", code: "GBR" },
    { name: "Republica Centroafricana", code: "CAF" },
    { name: "Republica Democratica del Congo", code: "COD" },
    { name: "Republica Dominicana", code: "DOM" },
    { name: "Reunión", code: "REU" },
    { name: "Ruanda", code: "RWA" },
    { name: "Rumania", code: "ROU" },
    { name: "Rusia", code: "RUS" },
    { name: "Sahara Occidental", code: "ESH" },
    { name: "Samoa", code: "WSM" },
    { name: "Samoa Americana", code: "ASM" },
    { name: "San Bartolomé", code: "BLM" },
    { name: "San Cristobal y Nevis", code: "KNA" },
    { name: "San Marino", code: "SMR" },
    { name: "San Martín (Francia)", code: "MAF" },
    { name: "San Martín (Paises Bajos)", code: "SXM" },
    { name: "San Pedro y Miquelón", code: "SPM" },
    { name: "San Tome y Principe", code: "STP" },
    { name: "San Vicente y las Granadinas", code: "VCT" },
    { name: "Santa Elena", code: "SHN" },
    { name: "Santa Lucia", code: "LCA" },
    { name: "Senegal", code: "SEN" },
    { name: "Serbia", code: "SRB" },
    { name: "Seychelles", code: "SYC" },
    { name: "Sierra Leona", code: "SLE" },
    { name: "Singapur", code: "SGP" },
    { name: "Siria", code: "SYR" },
    { name: "Somalia", code: "SOM" },
    { name: "Sri Lanka", code: "LKA" },
    { name: "Swazilandia", code: "SWZ" },
    { name: "Sudafrica", code: "ZAF" },
    { name: "Sudán", code: "SDN" },
    { name: "Sudán del Sur", code: "SSD" },
    { name: "Suecia", code: "SWE" },
    { name: "Suiza", code: "CHE" },
    { name: "Surinam", code: "SUR" },
    { name: "Tailandia", code: "THA" },
    { name: "Taiwan", code: "TWN" },
    { name: "Tanzania", code: "TZA" },
    { name: "Tayikistan", code: "TJK" },
    { name: "Territorio Británico del Océano Índico", code: "IOT" },
    { name: "Tierras Australes y Antárticas Francesas", code: "ATF" },
    { name: "Timor-Leste", code: "TLS" },
    { name: "Togo", code: "TGO" },
    { name: "Tokelau", code: "TKL" },
    { name: "Tonga", code: "TON" },
    { name: "Trinidad y Tobago", code: "TTO" },
    { name: "Túnez", code: "TUN" },
    { name: "Turkmekistán", code: "TKM" },
    { name: "Turquia", code: "TUR" },
    { name: "Tuvalu", code: "TUV" },
    { name: "Ucrania", code: "UKR" },
    { name: "Uganda", code: "UGA" },
    { name: "Uruguay", code: "URY" },
    { name: "Uzbekistan", code: "UZB" },
    { name: "Vanuatu", code: "VUT" },
    { name: "Venezuela", code: "VEN" },
    { name: "Vietnam", code: "VNM" },
    { name: "Wallis y Futuna", code: "WLF" },
    { name: "Yemen", code: "YEM" },
    { name: "Zambia", code: "ZMB" },
    { name: "Zimbabue", code: "ZWE" },
  ];
};

/**
 * Fornece um array de todos os status que um mentor pode ter.
 *
 * @returns Um array de todos os status que um mentor pode ter.
 */
export const allStatusMentor = () => {
  return ["APROVADO", "AGUARDANDO_APROVACAO", "RECUSADO", "INATIVO", "AUSENTE"];
};

/**
 * Mapeia a string de status do mentor para a mensagem de status, a qual é traduzida de
 * acordo com o idioma utilizado na aplicação.
 *
 * @param status O status do mentor.
 * @param t A função que traduz o texto do status.
 * @returns O status do mentor com tradução.
 */
export const mapStatusMentor = (status: string | undefined, t) => {
  switch (status) {
    case "APROVADO":
      return t(RH_MENTORES_APROVADO, "Aprovado");
    case "AGUARDANDO_APROVACAO":
      return t(RH_MENTORES_AGUARDANDO_APROVACAO, "Aguardando Aprovação");
    case "RECUSADO":
      return t(RH_MENTORES_RECUSADO, "Recusado");
    case "INATIVO":
      return t(RH_MENTORES_INATIVO, "Inativo");
    case "AUSENTE":
      return t(RH_MENTORES_AUSENTE, "Ausente");
    default:
      return "";
  }
};

/**
 * Fornece um array de todos os status que uma mentoria pode apresentar.
 *
 * @returns Um array de todos os status que uma mentoria pode apresentar.
 */
export const allStatusMentoria = () => {
  return [
    "AGENDADA",
    "CONCLUIDA",
    "AGUARDANDO_AVALIACAO",
    "NAO_REALIZADA",
    "REAGENDADA",
    "CANCELADA",
  ];
};

/**
 * Mapeia a string de status de mentoria para a mensagem de status, a qual é traduzida de
 * acordo com o idioma utilizado na aplicação.
 *
 * @param status O status da mentoria.
 * @param t A função que traduz o texto do status.
 * @returns O status da mentoria com tradução.
 */
export const mapStatusMentoria = (status: string | undefined, t) => {
  switch (status) {
    case "AGENDADA":
      return t(STATUS_MENTORIA_AGENDADA, "Agendada");

    case "CONCLUIDA":
      return t(STATUS_MENTORIA_CONCLUIDA, "Concluída");

    case "AGUARDANDO_AVALIACAO":
      return t(STATUS_MENTORIA_AGUARDANDO_AVALIACAO, "Aguardando avaliação");

    case "NAO_REALIZADA":
      return t(STATUS_MENTORIA_NAO_REALIZADA, "Não realizada");

    case "REAGENDADA":
      return t(STATUS_MENTORIA_REAGENDADA, "Reagendada");

    case "CANCELADA":
      return t(STATUS_MENTORIA_CANCELADA, "Cancelada");

    default:
      return "Status nulo";
  }
};

/**
 * Mapeia mensagem de redirecionamento de acorod com a rota.
 *
 * @param path A rota para a qual está sendo redirecionada a aplicação.
 * @returns A mensagem de redirecionamento de acordo com a rota.
 */
export const mapMessageAboutPathRedirect = (path: string) => {
  switch (path) {
    case "/mentoring/mentorado":
      return "Redirecionando-o para Agendamento de Mentoria...";
    case "/mentoring/mentor":
      return "Redirecionando-o para Se Tornar um Mentor...";
    default:
      return "Redirecionando-o para Home...";
  }
};

/**
 * Máscara aplicada ao campo telefone dos forms.
 *
 * @param properties As propriedades dos dados de entrada.
 * @returns O componente que aplica a máscara a entrada.
 */
export const TextMaskCustom = (properties) => {
  const { inputRef, ...other } = properties;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholder="Telefone *"
    />
  );
};

/**
 * Realiza uma limitação no tamanho de exibição de uma string.
 *
 * @param string A string a ter seu tamanho limitado.
 * @param size O valor que delimita quantos caracteres a string deve apresentar.
 * @returns A string formatada com o novo tamanho.
 */
export const limitString = (string: string, size: number) => {
  if (!string) {
    return "Sem nome";
  }
  if (string.length > size) {
    return string.substring(0, size) + "...";
  }
  return string;
};

/**
 * Formata uma string de data para um objeto Date.
 *
 * @param day A data que será formatada.
 * @returns A data em formato de objeto Date.
 */
export const createDateWithDay = (day: string) => {
  let pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
  let dt = new Date(day.replace(pattern, "$3-$2-$1"));
  return dt;
};

/**
 * Formata uma string de data para o formato ano-mês-dia.
 *
 * @param day A data que será formatada.
 * @returns A data no formato ano-mês-dia.
 */
export const formatToMaterialUIDate = (day: string) => {
  if (!day) {
    return "";
  }

  let str = day.split("/");

  let dia = str[0];
  let mes = str[1];
  let ano = str[2];

  return `${ano}-${mes}-${dia}`;
};

/**
 * Formata uma data para o formato ano/mês/dia.
 *
 * @param date A data a ser formatada.
 * @returns A data no formato ano/mês/dia.
 */
export const formatDateToMaterialUIDate = (date) => {
  if (!date) {
    return "";
  }

  let fullDate;
  if (date.includes("/")) {
    fullDate = date.split("/");
  } else if (date.includes("-")) {
    fullDate = date.split("-");
  }

  let dia = fullDate[0];
  let mes = fullDate[1];
  let ano = fullDate[2];

  return `${ano}/${mes}/${dia}`;
};

/**
 * Formata uma string de data para o formato dia/mês/ano.
 *
 * @param day A data que será formatada.
 * @returns A data no formato dia/mês/ano.
 */
export const formatFromMaterialUIDate = (day: string) => {
  if (!day) {
    return "";
  }

  let fullDate;
  if (day.includes("/")) {
    fullDate = day.split("/");
  } else if (day.includes("-")) {
    fullDate = day.split("-");
  }

  let dia = fullDate[2];
  let mes = fullDate[1];
  let ano = fullDate[0];

  return `${dia}/${mes}/${ano}`;
};

/**
 * Formata uma data para o formato ano/mês/dia.
 *
 * @param date A data a ser formatada.
 * @returns A data formatada no formato ano/mês/dia.
 */
export const formatMaterialUIDate = (date) => {
  if (!date) {
    return "";
  }

  let fullDate;
  if (date.includes("/")) {
    fullDate = date.split("/");

    let dia = fullDate[0];
    let mes = fullDate[1];
    let ano = fullDate[2];

    return `${dia}/${mes}/${ano}`;
  } else if (date.includes("-")) {
    fullDate = date.split("-");

    let dia = fullDate[0];
    let mes = fullDate[1];
    let ano = fullDate[2];

    return `${ano}/${mes}/${dia}`;
  }
};

/**
 * Formata uma data para o formato ISO.
 *
 * @param yourDate A data a ser formatada.
 * @returns A data formatda no formato ISO.
 */
export const getDateISO = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);
  //return yourDate.toISOString().split("T")[0].replace(/-/g, "/"); 
  return yourDate.toISOString().split("T")[0];
};

/**
 * Obtém a data atual no formato ano-mês-dia.
 *
 * @returns A data atual no formato ano-mês-dia.
 */
export const getTodayFormattedInMaterialUIDate = () => {
  let today = new Date();

  return `${today.getFullYear()}-${minTwoDigits(
    today.getMonth()
  )}-${minTwoDigits(today.getDate())}`;
};

/**
 * Formata um número para conter dois dígitos.
 *
 * @param n O número a ser formatado.
 * @returns Um valor com dois dígitos.
 */
const minTwoDigits = (n) => {
  return (n < 10 ? "0" : "") + n;
};

/**
 * Obtém a data do próximo dia.
 *
 * @param date A data atual.
 * @returns A data do próximo dia.
 */
export const tomorrow = (date) => {
  date.setDate(date.getDate() + 1);
  return date;
};

/**
 * Obtém uma data 30 dias após a data atual.
 *
 * @param date A data atual.
 * @returns A data 30 dias após a data atual.
 */
export const thirtyDaysAfter = (date) => {
  let thirtyDaysAfter = date;
  thirtyDaysAfter.setDate(thirtyDaysAfter.getDate() + 30);
  return thirtyDaysAfter;
};

/**
 * Obtém as dimensões da tela(altura e largura).
 *
 * @returns As dimensões da tela(altura e largura).
 */
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 * Obtém a bandeira do país com base na sigla do idioma.
 *
 * @param sigla A sigla do idioma.
 * @returns A bandeira correspondente a sigla do idioma.
 */
export const getContryFlagBySigla = (sigla: string) => {
  const countryFlags = [
    {
      sigla: "pt",
      imagem: BrazilFlag,
    },
    {
      sigla: "es",
      imagem: SpainFlag,
    },
  ];

  const countryFlag = countryFlags.filter((country) => sigla === country.sigla);

  return countryFlag[0];
};

/**
 * Exibe uma mensagem de alerta sobre a utilização do idioma Espanhol na aplicação,
 * visto que podem haver textos ainda não totalmente traduzidos para este idioma.
 *
 * @returns A mensagem de alerta sobre a utilização do idioma Espanhol.
 */
export const ShowTranslationWarning = () => {
  const { showInfoMessage } = useContext(alertContext);

  useEffect(() => {
    if (i18n.language === "es")
      showInfoMessage("warning", MSG_AVISO_TRADUCAO_EM_ANDAMENTO);
  }, [showInfoMessage]);

  return <span></span>;
};

/**
 * Converte uma data para um array com um objeto contendo id e data.
 *
 * @param name O nome do período(e.g. semana, mês).
 * @param from O objeto data.
 * @returns Um array com um objeto contendo id e data.
 */
export const convertToNivoLineChartDataFormat = (name, from) => {
  return [
    {
      id: name,
      data: from.map((item) => {
        return {
          x: item.id,
          y: item.value,
        };
      }),
    },
  ];
};

/**
 * Exibe mensagem informando a indisponibilidade do agendamento de mentoria.
 *
 * @returns Mensagem informando a indisponibilidade do agendamento de mentoria.
 */
export const ShowAgendamentoIndisponivelWarning = () => {
  const { showInfoMessage } = useContext(alertContext);

  useEffect(() => {
    showInfoMessage("warning", MSG_AVISO_AGENDAMENTO_INDISPONIVEL);
  }, [showInfoMessage]);

  return <span></span>;
};
