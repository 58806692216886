import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { convertToNivoLineChartDataFormat } from '../../../../../../utils/FormUtils';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

/**
 * Interface utilizada pelo componente MyResponsiveLine e que descreve
 * seu métodos e propriedades.
 */
interface MyResponsiveLineProps {
    data?: any
    legends?: any
    margins?: any
    scheme?: any
    layout?: any
    rotateLabelLeft?: number
    rotateLabelBottom?: number
    enableGridY?: boolean
    labelTextColor?: string
}

/**
 * Componente responsável por renderizar gráfico de linha.
 * 
 * @param {MyResponsiveLineProps} 
 * @returns Gráfico de linha com todos os seus ajustes e suas propriedades.
 */
const MyResponsiveLine: React.FC<MyResponsiveLineProps> = ({ data, legends, margins, scheme, rotateLabelLeft, rotateLabelBottom, enableGridY, layout, labelTextColor }) => {

    return (
        <ResponsiveLine
            data={convertToNivoLineChartDataFormat('semana', data)}
            margin={margins}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            colors={{ scheme: scheme ? scheme : 'nivo' }}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: rotateLabelBottom ? rotateLabelBottom : 0,
                legend: legends.axisBottom,
                legendOffset: 32,
                legendPosition: 'middle'
            }}
            axisLeft={enableGridY ? {
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: rotateLabelLeft ? rotateLabelLeft : 0,
                legend: legends.axisLeft,
                legendOffset: -40,
                legendPosition: 'middle'
            } : null}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            enablePointLabel={true}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}

        />
    );
}

export default MyResponsiveLine;