import SessionStorageService from '../service/SessionStorageService';
import { ChavesLocalStorage } from '../config';
import { isUndefined } from 'util';

/**
 * Objeto com funções que gerenciam o aspectos de login, logout e verificação
 * de sessão do usuário.
 */
const authProvider = {
    login: (username:string ) => {        
        SessionStorageService.setStorge(ChavesLocalStorage.usuario, username)
        return Promise.resolve();
    },
    logout: () => {        
        SessionStorageService.clearStorage()
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth:()=>{ 
        return !isUndefined(SessionStorageService.getStorage(ChavesLocalStorage.session_key)) 
        && SessionStorageService.getStorage(ChavesLocalStorage.session_key) !== null;        
    },
    getPermissions: () => Promise.reject('Unknown method'),
};

export default authProvider;
