import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Link,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import { Snackbar } from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
// TODO Remove before publish to production
import { SpinnerSplendis } from "../../../commons";
import {
  LocalStorageService,
  LoginService,
  UserContext,
} from "../../../service";
import authProvider from "../../../security/authProvider";
import { useTranslation } from "react-i18next";
import {
  LOGIN_SOURCE,
  // LOGIN_AVISO_STEFANINI_AD, // TODO Remove before publish to production
  LOGIN_LABEL_BOTAO_LOGIN,
  LOGIN_EMAIL_PLACEHOLDER,
  LOGIN_SENHA_PLACEHOLDER,
  LOGIN_ESQUECEU_A_SENHA,
  LOGIN_CADASTRAR,
  LOGIN_USUARIO_OU_SENHA_INCORRETOS,
  LOGIN_MENSAGEM_INICIAL,
  LOGIN_MENSAGEM_DOWNLOAD_MANUAL_PARTE_1,
  LOGIN_MENSAGEM_DOWNLOAD_MANUAL_PARTE_2,

} from "../../../messages";

import LoginBackground from "../../../images/bg-img.jpg";
import {Background} from './BackgroundComponent'
import BelgoArcelorMittalMentoringLogo from "../../../images/footerbelgosemcopy.png";
import BelgoArcelorMittalLogo from "../../../images/belgo-arcelor-mittal-logo.png";
import MentoringLogo from "../../../images/mentoring-logo.png";
import logoNovo from "../../../images/logoBelgoArames.png";
import informacoes from "../../../images/button secundary.png";

import { BotaoGlobalContatoLogin, TrocaIdiomas } from "../../../components";
import UserManualService from "../../../service/UserManualService";
import { Height } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundPosition:'bottom',
    backgroundSize:'contain',
    backgroundRepeat:'no-repeat',
    height: "100vh",
    maxWidth: "100%",
    maxHeight:"100vh",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  alertMessage: {
    fontFamily: "InfraLight",
    fontSize: ".9rem",
    fontStyle: "normal",
    fontWeight: 300,
    margin: "0 auto",

    "@media only screen and (max-width: 414px)": {
      fontSize: ".6rem",
    },

    "@media only screen and (max-width: 650px)": {
      width: "500px",
    },
  },
  BelgoArcelorMittalMentoringLogo: {
    backgroundImage: `url(${BelgoArcelorMittalMentoringLogo})`,

backgroundSize:'contain',
    height: "13.5vh",
    margin: "0 auto",
    marginTop:'10px',
    width: "312px",
    backgroundRepeat:'no-repeat',


    "@media only screen and (max-width: 414px)": {
      display: "none",
    },

    "@media only screen and (max-width: 650px)": {
      height: "15vh",
    },
  },
  Mentoring:{
    color:'#FF4C00',
    fontSize:'2em',
    marginTop:'1%',
    backgroundRepeat:'no-repeat',
    backgroundImage: `url(${logoNovo})`,
  },


  BelgoArcelorMittalLogo: {
    display: "none",

    "@media only screen and (max-width: 414px)": {
      backgroundImage: `url(${BelgoArcelorMittalLogo})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "9rem",
      display: "block",
      height: "4.5rem",
      margin: "2rem auto 0 auto",
    },
  },
  mentoringLogo: {
    display: "none",

    "@media only screen and (max-width: 414px)": {
      backgroundImage: `url(${MentoringLogo})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "6.5rem",
      display: "block",
      height: "2.3rem",
      margin: ".5rem auto 0 auto",
    },
  },
  subheading: {
    color: "white",
    fontSize: "30px",
    left: "970px",
    position: "absolute",
    top: "150px",
  },
  headingText: {
    color: "#636466",
    fontFamily: "InfraLight",
    fontSize: "1.08rem",
    fontStyle: "normal",
    fontWeight: 325,
    height: "80px",
    lineHeight: "25.85px",
    margin: "0 auto 0 auto",
    textAlign: "center",
    width: "800px",

    "@media only screen and (max-width: 414px)": {
      marginTop: "1rem",
      fontSize: ".7rem",
      lineHeight: "20px",
      width: "320px",
    },

    "@media only screen and (min-width: 600px) and (max-width: 992px)": {
      marginBottom: "3rem",
      width: "550px",
    },
  },
  loginContainer: {
    overflowY: "hidden",
    "@media only screen and (max-width: 1366px)": {
      overflowY: "scroll",
      height: "310px"
    }
  },
  loginForm: {
    background: "#F0F0F0",
    height: "330px",
    margin: "0.5rem auto 0 auto",
    width: "480px",

    "@media only screen and (max-width: 414px)": {
      height: "375px",
      marginBottom: "10vh",
      marginTop: "2rem",
      width: "350px",
    },

    "@media only screen and (min-width: 415px) and (max-width: 992px)": {
      height: "400px",
      marginBottom: "15vh",
    },
  },
  loginLabel: {
    color: "#636466",
    fontFamily: "NexaRegular",
    fontSize: "24px",
    lineHeight: "46px",
    padding: "1rem 62% 0 0",
    marginLeft: "-40px",

    "@media only screen and (max-width: 414px)": {
      fontSize: "1.2rem",
      marginLeft: "0px",
      paddingRight: "220px",
    },
  },
  formField: {
    background: "#FFFFFF",
    borderRadius: "4px",
    padding: "0 auto",
    width: "400px",

    "@media only screen and (max-width: 414px)": {
      width: "270px",
    },
  },
  formFieldPlaceholder: {
    fontFamily: "InfraRegular",
    fontStyle: "normal",
    fontWeight: 100,
    lineHeight: "5px",

  },
  submit: {
    background: "#ED1C24",
    color: "white",
    fontFamily: "InfraRegular",
    fontSize: ".85rem",
    fontStyle: "normal",
    fontWeight: 300,
    margin: theme.spacing(3, 0, 2),
    padding: "0 auto",
    width: "400px",

    "&:hover": {
      background: "#b21212",
    },

    "@media only screen and (max-width: 414px)": {
      fontSize: ".7rem",
      width: "270px",
    },
  },
  grid: {
    margin: "0 auto",
    width: "27rem",

    "@media only screen and (max-width: 414px)": {
      width: "10rem",
    },
  },
  gridItem: {
    "@media only screen and (max-width: 414px)": {
      display: "block",
      margin: "0 auto",
      width: "100%",
    },
  },
  register: {
    justifyContent: "center",
    bottom: "0%",
    color: "#003B4A",
    display: "flex",
    flex: "1 0 0",
    fontFamily: "InfraRegular",
    fontSize: ".7rem",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "0.0357143em",
    lineHeight: "20px",
    padding: "1rem 0 0 0",
    textTransform: "uppercase",
    width: "100%",

    "&:hover": {
      color: "#03232b",
      textDecoration: "none",
    },

    "@media only screen and (max-width: 414px)": {
      display: "block",
      marginTop: "1rem",
      padding: 0,
    },
  },
  forgotPassword: {
    justifyContent: "center",
    bottom: "0%",
    color: "#003B4A",
    display: "flex",
    flex: "1 0 0",
    fontFamily: "InfraRegular",
    fontSize: ".7rem",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "0.0357143em",
    lineHeight: "20px",
    padding: "1rem 0 0 0",
    textTransform: "uppercase",
    width: "100%",

    "&:hover": {
      color: "#03232b",
      textDecoration: "none",
    },

    "@media only screen and (max-width: 414px)": {
      display: "block",
      marginLeft: "-5px",
      marginRight: "auto",
      marginTop: "1rem",
      padding: 0,
      width: "170px",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      width: "34vw",
    },
  },
  footer: {

    position: "sticky",

    "@media only screen and (max-width: 414px)": {
      marginTop: "5rem",
    },
  },
  copyright: {

   position: 'fixed',
   left:0,
   right: '10rem',
   textAlign: 'right',
   bottom:'3.8rem',

    color: "#fff",
    fontFamily: "InfraRegular",
    fontSize: ".8rem",

    "@media only screen and (max-width: 414px)": {
      fontSize: ".7rem",
    },
    "@media only screen and (width: 1024px)": {
      bottom:'16.5px',

    },
    "@media only screen and (width: 1440px)": {
      bottom:'25.5px',

    },
  },
  error: {
    color: "red",
  },
  userManualWrapper: {
    display:"flex",
    justifyContent:'center',
    marginTop: "3vh",


    "@media only screen and (max-width: 414px)": {
      marginTop: "-8vh",
    },

    "@media only screen and (min-width: 415px) and (max-width: 720px)": {
      marginTop: "-10vh",
    },
  },
  userManualText: {
    color: "#003B4A",
    fontFamily: "InfraRegular",
    fontSize: ".8rem",
    fontStyle: "normal",
    fontWeight: 600,
    width:'25%',
   border:'2px solid #003B4A',
   padding:"10px",
   borderRadius:'2px',
   cursor: "pointer",

    "@media only screen and (max-width: 414px)": {
      fontSize: ".65rem",
    },
  },
  userManualLink: {
    color: "#009FD7",
    textDecoration: "underline",

    "&:hover": {
      cursor: "pointer",
    },
  },
  imgLogin:{
    backgroundImage: `url(${LoginBackground})`,
    backgroundSize:'100%',
    height: "200px",
    margin: "0 auto",
    width: "100%",
    backgroundRepeat: 'no-repeat'
  },
}));

/**
 * Interface utilizada pelo componente LoginComponent e que descreve
 * seu métodos e propriedades.
 */
interface LoginProps {
  loginService: LoginService;
  history: Array<any>;
}

/**
 * Tela de login da aplicação.
 *
 * @param {LoginProps}
 * @returns A tela de login.
 */
const Login: React.FC<LoginProps> = ({ history, loginService }) => {
  const classes = useStyles();

  const userManualService: UserManualService = new UserManualService();

  /**
   * Hook e funções utilizados para tradução de textos.
   */
  const { t, i18n } = useTranslation([LOGIN_SOURCE]);
  //console.log(i18n.language)
  const [loginState, setLoginState] = React.useState({
    nome: undefined,
    senha: undefined,
    isLogged: false,
    gotError: false,
  });

  const [loading, setLoading] = React.useState(false);

  // const [showMessage, setShowMessage] = React.useState<boolean>(false); // TODO Remove before publish to production

  const { login } = useContext(UserContext);

  /**
   * Verifica se o usuário está logado assim que o componente é renderizado.
   */
  useEffect(() => {
    if (authProvider.checkAuth()) {
      setLoginState({ ...loginState, isLogged: true });
    }
    // setShowMessage(true); // TODO Remove before publish to production
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Verifica se há erros no proesso de login.
   *
   * @returns Mensagem de erro caso o usuário e/ou senha estejam
   * 			incorretos, null caso contrário.
   */
  const handleError = () => {
    return loginState.gotError ? (
      <Typography variant="caption" className={classes.error} gutterBottom>
        {t(LOGIN_USUARIO_OU_SENHA_INCORRETOS, "Usuário ou senha incorretos")}
      </Typography>
    ) : null;
  };

  /**
   * Redireciona para link em cache caso exista, senão redireciona para default.
   *
   * @param defaultLink O link padrão para o qual o usuário será redirecionado
   * 					  caso não haja link em cache.
   * @returns O link em cache caso exista; o link padrão passado como parâmetro
   * 			caso contrário.
   */
  const checkCachedLinkAndRedirect = (defaultLink) => {
    let cachedLink = LocalStorageService.getStorage("redirectTo");
    LocalStorageService.clearStorage();
    return cachedLink !== null ? cachedLink : defaultLink;
  };

  /**
   * Gerencia o processo de login, configurando o state de login.
   *
   * @param event O evento com os dados do login.
   * @param field O campo específico do processo de login.
   * @param data O dado do campo específico do processo de login.
   */
  const handleChange = (
    event,
    field = event?.target.id,
    data = event?.target.value
  ) => {
    setLoginState({ ...loginState, [field]: data });
  };

  /**
   * Mensagem informativa exibida no topo da tela de login.
   *
   * @param message A mensagem a ser exibida.
   * @returns Snackbar com a mensagem informativa.
   */
  // TODO Remove before publish to production
  /* const handleInfoMessage = (message: string) => {
    return (
      <Snackbar
        open={showMessage}
        onClose={() => setShowMessage(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          className={classes.alertMessage}
          style={{ opacity: 0.9 }}
          onClose={() => setShowMessage(false)}
          severity="info"
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }; */

  /**
   * Gerencia a submissão das informações de login.
   *
   * @param event O evento que dispara a submissão das informações de login.
   * @param login As informações de login.
   */
  const handleSubmit = (event: any, login: any) => {
    setLoading(true);
    event.preventDefault();

    loginService
      .login(loginState.nome, loginState.senha)
      .then((response) => {
        login(response, "" + loginState.nome).then(() => {
          setLoading(false);
          setLoginState({ ...loginState, isLogged: true });
        });
      })
      .catch(() => {
        setTimeout(
          () => setLoginState({ ...loginState, gotError: false }),
          4000
        );
        setLoading(false);
        handleError();
      });
  };

  /**
   * Função que gera o link para download do manual do usuário.
   */
  const downloadUserManual = () => {
    userManualService.getUserManual(i18n.language).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "mentoring" + i18n.language.toUpperCase() + ".pdf"
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  return loginState.isLogged ? (
    <Redirect to={checkCachedLinkAndRedirect("/mentoring/home")} />
  ) : (
    <Container  component="main" maxWidth="xl" className={classes.pageContainer} style={{backgroundImage:`url(${Background[i18n.language]})`}}>
      <TrocaIdiomas />


      <img src={logoNovo} alt="" style={{marginTop:'2%', marginBottom: '15px'}} />


      <Typography className={classes.headingText}>
        {t(
          LOGIN_MENSAGEM_INICIAL,
          "Essa é uma oportunidade para conhecer pessoas, desenvolver habilidades e compartilhar experiências. Saiba que este processo envolve muito aprendizado e contamos com você para que essa jornada seja leve, produtiva e inspiradora."
        )}
      </Typography>
      <div className={classes.loginContainer}>
        <div className={classes.loginForm}>
          {/* {handleInfoMessage(
            t(
              LOGIN_AVISO_STEFANINI_AD,
              "O Stefanini Mentoring não está sincronizado com o Stefanini AD. Caso ainda não tenha se cadastrado, por favor se cadastre."
            )
          )} */}

          <div>
            <Typography className={classes.loginLabel}>
              {t(LOGIN_LABEL_BOTAO_LOGIN, "Login")}
            </Typography>

            <form
              autoComplete="off"
              noValidate
              onSubmit={(e) => handleSubmit(e, login)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="nome"
                label={t(LOGIN_EMAIL_PLACEHOLDER, "E-mail Corporativo")}
                name="email"
                autoComplete="off"
                autoFocus
                onChange={(event) => handleChange(event)}
                className={classes.formField}
                inputProps={{
                  style: {
                    padding: "10px 15px",
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "InfraRegular",
                    fontStyle: "normal",
                    fontWeight: 400,
                    height: "45px"
                  },
                }}
                InputLabelProps={{
                  classes: { root: classes.formFieldPlaceholder },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                autoComplete="off"
                label={t(LOGIN_SENHA_PLACEHOLDER, "Senha")}
                type="password"
                id="senha"
                onChange={(event) => handleChange(event)}
                className={classes.formField}
                InputProps={{
                  style: {
                    height: "45px",
                  },
                }}
                InputLabelProps={{
                  classes: { root: classes.formFieldPlaceholder },
                }}
              />

              {handleError()}
              {loading ? (
                <SpinnerSplendis style={{ marginTop: "1.85rem" }} />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  className={classes.submit}
                >
                  {t(LOGIN_LABEL_BOTAO_LOGIN, "Login")}
                </Button>
              )}

              <Grid container className={classes.grid}>
                <Grid item xs className={classes.gridItem}>
                  <Link
                    href="#/recuperar-senha"
                    className={classes.forgotPassword}
                  >
                    {t(LOGIN_ESQUECEU_A_SENHA, "Esqueci a senha")}
                  </Link>
                </Grid>

                <Grid item xs className={classes.gridItem}>
                  <Link
                    href="#/cadastro"
                    className={classes.register}
                  >
                    {t(LOGIN_CADASTRAR, "Cadastrar")}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
        {i18n.language === 'en'|| i18n.language === 'pt' ?
        <div className={classes.userManualWrapper} onClick={downloadUserManual}>
        <Typography className={classes.userManualText}>
            {t(LOGIN_MENSAGEM_DOWNLOAD_MANUAL_PARTE_1, "CLIQUE PARA MAIS INFORMAÇÕES")}

          </Typography>
        </div>:"" }
      </div>


      <footer className={classes.copyright}>
          {"Copyright © "}
          <Link color="inherit" href="#"> {/* TODO Set Link in environment variables */}
            Mentoring
          </Link>{" "}
          {new Date().getFullYear()}. Powered by Stefanini
      </footer>
      <BotaoGlobalContatoLogin />
    </Container>
  );
};

export default Login;
