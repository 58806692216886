import SessionStorageService from "./SessionStorageService";
import React, { useEffect } from "react";
import { ChavesLocalStorage } from "../config";
import UserService from "./UserService";
import { rolePriority } from "../utils/AuthUtils";
import { UserObject } from "../models/UserObject";

/**
 * Classe que determina os atributos do objeto de sessão.
 */
export class SessionObject {
  roles?: [];
  mentor?: {
    id: any;
  };
  mentorado?: {
    id: any;
  };
  colaborador?: {
    id: any;
  };
}

/**
 * Valores padrões iniciais para o contexto da sessão.
 */
const userContextDefaultValue = {
  role: "",
  roles: [],
  mentor: {
    id: null,
  },
  mentorado: {
    id: null,
  },
  colaborador: {
    id: null,
  },
};

/**
 * Verifica se o usuário está logado.
 *
 * @returns True caso o usuário esteja logado, False caso contrário.
 */
const isLogged = () => {
  return (
    SessionStorageService.getStorage("role") !== null &&
    SessionStorageService.getStorage("user") !== null
  );
};

/**
 * Cria um contexto com os valores-padrão definidos acima.
 */
const UserContext = React.createContext<any>(userContextDefaultValue);

/**
 * Cria uma sessão padrão.
 */
const defaultSessionValue =
  SessionStorageService.getStorage("user") !== null
    ? JSON.parse(SessionStorageService.getStorage("user") || "")
    : new SessionObject();

/**
 * Gerencia a sessão do usuário.
 *
 * @param props Propriedades relativas a sessão do usuário.
 * @returns O contexto do usuário.
 */
const UserSessionHandler = (props) => {
  const [role, setRole] = React.useState<string>("PUBLIC");
  const [session, setSession] =
    React.useState<SessionObject>(defaultSessionValue);
  // MENTOR, MENTORADO E COLABORADOR PARA FICAREM SALVOS:
  const [userObject, setUserObject] = React.useState<UserObject>(
    new UserObject()
  );

  const userService = new UserService();

  /**
   * Configura o papel e a sessão do usuário, caso o mesmo esteja logado, assim que
   * o componente é renderizado.
   */
  useEffect(() => {
    if (isLogged()) {
      setRole(SessionStorageService.getStorage("role") || "");
      setSession(JSON.parse(SessionStorageService.getStorage("user") || ""));
    }
  }, []);

  /**
   * Armazena a sessão do login do usuário com o Service de armazenamento de sessão.
   *
   * @param response O response com access-token.
   * @param nome O nomde do usuário.
   * @returns As informações da sessão do usuário.
   */
  const login = async (response, nome) => {
    SessionStorageService.setStorge("usuario", "" + nome);
    SessionStorageService.setStorge(
      ChavesLocalStorage.session_key,
      response.data["access_token"]
    );

    return getUserInfo();
  };

  /**
   * Obtém as informações do usuário logado.
   *
   * @returns As informações do usuário logado.
   */
  const getUserInfo = () => {
    let sessionObject: SessionObject = new SessionObject();

    return userService.getUser().then((user) => {
      setRole(rolePriority(user.roles));

      sessionObject = {
        roles: user.roles,
        colaborador: {
          id: user.idColaborador,
        },
        mentor: {
          id: user.idMentor,
        },
        mentorado: {
          id: user.idMentorado,
        },
      };

      SessionStorageService.setStorge("role", rolePriority(user.roles));
      SessionStorageService.setStorge("user", JSON.stringify(sessionObject));
      setSession(sessionObject);
    });
  };

  /**
   * Desloga o usuário e limpa o armazenamento da sessão.
   */
  const logout = () => {
    setUserObject(new UserObject());
    setRole("PUBLIC");
    setSession(defaultSessionValue);
    SessionStorageService.clearStorage();
  };

  /**
   * Permite a troca de papeis dos usuários logados(e.g. de mentorado para mentor).
   *
   * @param newRole O novo papel do usuário.
   */
  const switchRole = (newRole: string) => {
    setRole(newRole);
    SessionStorageService.setStorge("role", newRole);
  };

  /**
   * Valores passados ao contexto do usuário.
   */
  const values = {
    role: role,
    session: session,
    login: login,
    logout: logout,
    switchRole: switchRole,
    userObject: userObject,
    setUserObject: setUserObject,
  };

  return (
    <React.Fragment>
      <UserContext.Provider value={values}>
        {props.children}
      </UserContext.Provider>
    </React.Fragment>
  );
};

export { UserContext, UserSessionHandler };
