import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    CardContent,
    CardMedia,
    Button,
    Typography,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import {
    AGENDAMENTO_MENTORIA_SOURCE,
    AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONADO,
    AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONAR,
} from "../../messages";

import { Mentorado } from '../../models';
import { SpinnerSplendis } from '../../commons';

const useStyles = makeStyles({
    root: {
        width: "16rem",
        margin: 5,
        display: "flex",
        flexDirection: "column",
        position: "relative",

        '@media only screen and (max-width: 414px)': {
            width: '70vw',
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            height: '90vh',
            width: '41vw',
        }
    },
    name: {
        fontFamily: "nexaRegular",
        fontSize: "1.5rem",
        lineHeight: "2.9rem",
        marginBottom: "0.3rem",
        paddingBottom: 0,
        height: "2.2rem"
    },
    cargo: {
        fontFamily: "infraLight",
        fontWeight: 300,
        color: "#373A3C",
        paddingTop: 0,
        marginTop: 0,
        fontSize: "0.875rem",
        lineHeight: "1.25rem"
    },
    mediaDiv: {
        display: "flex",
        justifyContent: "center",
        marginTop: "1.8rem",
        marginBottom: 0
    },
    media: {
        display: "block",
        height: "7.6rem",
        width: "7.6rem",
        borderRadius: "50%"
    },
    descriptionContainer: {
        maxHeight: "100%",
        width: "100%",
        marginTop:"1rem"
    },
    cardContent: {
        padding: 10,
        height: "13vh",
    },
    description: {
        fontSize: "calc(12px + 0.2vw)",
        textAlign: "center",
        wordBreak: "break-word",
        overflow: "auto",
        textOverflow: "ellipsis",
        fontFamily: "infraRegular",
        fontWeight: 400,
        height: "4rem"
    },
    buttonContained: {
        width: "14rem",
        height: "2.75rem",
        margin: "0 auto",
        fontFamily: "infraMedium",
        marginBottom: "1rem",
        backgroundColor: "#003B4A",
        color: "#fff",
    
        '&:hover': {
          backgroundColor: "#003B4A",
          color: "#fff",
        },
    
        "@media only screen and (max-width: 414px)": {
          width: "65vw",
        },
    
        "@media only screen and (min-width: 415px) and (max-width: 720px)": {
          width: "38vw",
        },
      },
      buttonOutlined: {
        width: "14rem",
        height: "2.75rem",
        margin: "0 auto",
        fontFamily: "infraMedium",
        marginBottom: "1rem",
        borderColor: "#003B4A",
    
        "@media only screen and (max-width: 414px)": {
          width: "65vw",
        },
    
        "@media only screen and (min-width: 415px) and (max-width: 720px)": {
          width: "38vw",
        },
      },
    languagens: {
        position: 'absolute',
        display: 'flex',
        top: 0,
        right: 0
    },
    flagsDiv: {
        margin: '0.3rem'
    },
    flags: {
        width: '1.1rem', 
        height: '1.1rem', 
        margin: '0.24rem'
    },
    divider: {
        width: "14rem",
        marginBottom: "1rem",
        marginTop: "2.4rem"
    },
    seeMore: {
        border: "none",
        backgroundColor: "white",
        color: "#003B4A"
    }
});

/**
 * Interface utilizada pelo componente MentoradoCard e que descreve
 * seu métodos e propriedades.
 */
interface MentoradoCardProps {
    mentorado?: Mentorado
    showButton?: boolean
    handleButton?: Function
    selected?: boolean
    style?: any
}

/**
 * Componente reponsável por apresentar as informações mentorado, foto, nome e cargo.
 * 
 * @param {MentoradoCardProps} 
 * @returns O card do mentorado com todas as suas informações.
 */
const MentoradoCard: React.FC<MentoradoCardProps> = ({ mentorado, showButton = false, handleButton, selected, style }) => {
    const classes = useStyles();

    const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);
    const [loading, setLoading] = React.useState(true);

    /**
     * Configura o loading para não aparecer sempre que o card é carregado.
     */
    React.useEffect(() => {
        if(mentorado) {
            setLoading(false);
        }
    }, [mentorado])

    /**
     * Renderiza o botão de seleção do mentorado, exibindo as forma SELECIONAR ou SELECIONADO.
     * 
     * @returns O botão de seleção do mentorado.
     */
    const renderButton = () => {
        return (<>
            <hr className={classes.divider}/>
            
            <Button
                className={selected ? classes.buttonContained : classes.buttonOutlined}
                onClick={(e) => {
                    handleButton && handleButton(e, mentorado?.id);
                }}
                size="medium"
                variant={selected ? "contained" : "outlined"} 
            >
                {selected
                    ? t(
                        AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONADO,
                        "Selecionado"
                    )
                    : t(
                        AGENDAMENTO_MENTORIA_STEP_MENTORES_BOTAO_SELECIONAR,
                        "Selecionar"
                    )}
            </Button>
        </>)
    }

    return (
        <Card style={style ? style : {}} className={classes.root}>
            {
                loading ?
                (<SpinnerSplendis/>) :
                (
                    <>
                        <div className={classes.mediaDiv}>
                            <CardMedia className={classes.media} image={mentorado?.foto} title={mentorado?.nome} />
                        </div>
                        <CardContent className={classes.cardContent}>
                            <div>
                                <Typography gutterBottom className={classes.name} variant="h5" component="h3" noWrap={true}>
                                    {mentorado?.nome}
                                </Typography>
                                <Typography gutterBottom className={classes.cargo} variant="body2"
                                    component="p" noWrap={true}>
                                    {mentorado?.cargo}
                                </Typography>
                            </div>
                        </CardContent>
                        {
                            showButton &&
                            renderButton()
                        }
                    </>
                )
            }
        </Card>
    );
}

export default MentoradoCard;
