/**
 * Classe que define o modelo para a avaliação com todas as suas propriedades.
 */
export default class Avaliacao{
	mentorId?: number;
	mentoriaId?: number;
	mentoradoId?: number;
	descricao: string = '';
	nota: number = 0;
    mentoriaConfirmada: string = '';
}