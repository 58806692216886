import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    CssBaseline,
    TextField,
    Link,
    Typography,
    Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";

import { SessionStorageService, UserContext, UserService } from '../../../../service';
import { SpinnerSplendis } from '../../../../commons';
import { useTranslation } from 'react-i18next';
import {
    TROCAR_SENHA_SOURCE,
    TROCAR_SENHA_ERRO_SENHA_ATUAL_INCORRETA,
    TROCAR_SENHA_ERRO_CAMPOS_DE_NOVA_SENHA_DIFERENTES,
    TROCAR_SENHA_TITULO,
    TROCAR_SENHA_SUBTITULO,
    TROCAR_SENHA_SENHA_ATUAL_PLACEHOLDER,
    TROCAR_SENHA_NOVA_SENHA_PLACEHOLDER,
    TROCAR_SENHA_CONFIRMAR_NOVA_SENHA_PLACEHOLDER,
    TROCAR_SENHA_BOTAO_CONFIRMAR,
    TROCAR_SENHA_BOTAO_VOLTAR,
    TROCAR_SENHA_MENSAGEM_SUCESSO
} from '../../../../messages';

import PasswordChangeModal from './PasswordChangeModal';
import ModalSucesso from '../../../../commons/modals/ModalSucesso';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
    },
    title: {
        fontFamily: 'InfraRegular',
        fontStyle: 'normal',
        fontWeight: 500,
        marginTop: '3rem',
    },
    subtitle: {
        color: 'gray',
        fontFamily: 'InfraRegular',
        fontSize: '.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        margin: '.5rem 0 1rem 0',
        textAlign: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formField: {
        '& > div': {
            height: '2.7rem',
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footer: {
        marginTop: '1rem',

        '@media only screen and (max-width: 414px)': {
            marginBottom: '1rem',
            marginTop: '.5rem',
        }
    },
    copyright: {
        color: 'grey',
        fontFamily: 'InfraRegular',
        fontSize: '.8rem',

        '@media only screen and (max-width: 414px)': {
            fontSize: '.7rem',
        }
    },
    backButton: {
        bottom: '50%',
        color: '#003B4A',
        display: 'flex',
        fontFamily: 'InfraRegular',
        fontSize: '.8rem',
        fontStyle: 'normal',
        fontWeight: 500,
        left: '5vh',
        margin: '2vh 2vh',
        position: 'absolute',

        '@media only screen and (max-width: 414px)': {
            left: '18vh',
            bottom: '-20vh',
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            left: '70vh',
            bottom: '-110vh',
        }
    },
}));

/**
 * Componente que renderiza a tela para troca de senha do usuário.
 * 
 * @returns A tela de troca de senha.
 */
const PasswordChange: React.FC = () => {
    const classes = useStyles();

    const { t } = useTranslation([TROCAR_SENHA_SOURCE]);

    const userService: UserService = new UserService();
    const history = useHistory();

    const [state, setState] = React.useState({
        username: SessionStorageService.getStorage('usuario'),
        password: undefined,
        newPassword: undefined,
        newPassword_confirm: undefined,
        gotError: false
    });
    const [loading, setLoading] = React.useState(false);
    const [newSenha, setNewSenha] = React.useState("");
    const [newSenhaConfirm, setNewSenhaConfirm] = React.useState("");
    const [openModalConfirm, setOpenModalConfirm] = React.useState<boolean>(false);
    const [openModalSucesso, setOpenModalSucesso] = React.useState<boolean>(false);

    const { session } = useContext(UserContext);

    /**
     * Gerencia e verifica se há erro ao inserir a senha atual.
     * 
     * @returns Mensagem de erro caso a senha esteja incorreta, null caso contrário.
     */
    const handleRequestError = () => {
        return state.gotError ?
            <Typography variant="caption" style={{ color: 'red' }} gutterBottom>
                {t(TROCAR_SENHA_ERRO_SENHA_ATUAL_INCORRETA, "Senha atual incorreta")}
            </Typography>
            :
            null;
    }

    /**
     * Gerencia e verifica se há erro na confirmação de senha.
     * 
     * @returns Mensagem de erro caso a confirmação de senha esteja incorreta, null caso contrário.
     */
    const handlePasswordConfirmError = () => {
        return (newSenhaConfirm && newSenha !== newSenhaConfirm) ?
            <Typography variant="caption" style={{ color: 'red' }} gutterBottom>
                {t(TROCAR_SENHA_ERRO_CAMPOS_DE_NOVA_SENHA_DIFERENTES, "Senhas diferentes")}
            </Typography>
            :
            null;
    }

    /**
     * Gerencia o processo de mundaça nos campos de senha.
     * 
     * @param event O evento que dispara o gerenciamento da mudança de senha.
     * @param field O campo específico de senha.
     * @param data O valor da senha, ou seja, os caracteres inseridos.
     */
    const handleChange = (event, field = event?.target.id, data = event?.target.value) => {
        setState({ ...state, [field]: data });

        if (field === 'newPassword') {
            setNewSenha(data);
        }

        if (field === 'newPassword_confirm') {
            setNewSenhaConfirm(data);
        }
    }

    /**
     * Verifica se as senhas(senha atual, nova senha e confirmação de nova senha)
     * são válidas e se a nova senha é igual a confirmação de nova senha.
     * 
     * @returns True caso a nova senha seja igual a confirmação de nova senha,
     *          False caso contrário ou caso as senhas não sejam válidas.
     */
    const isValid = () => {
        if (
            (state.password && state.newPassword && state.newPassword_confirm)
            &&
            (state.newPassword === state.newPassword_confirm)
        ) {
            return true;
        }

        else return false;
    }

    /**
     * Gerencia o envio para o back da nova senha abrindo o modal de confirmação.
     * 
     * @param e O evento que dispara a abertura do modal de confirmação.
     */
    const handleSubmit = (e) => {
        setOpenModalConfirm(true);
    }

    /**
     * Redireciona o usuário para a tela de login.
     */
    const redirectLogin = () => {
        setTimeout(() => history.push('/login'), 5000);
    }

    /**
     * Gerencia todo o processo de mudança de senha.
     */
    const changePassword = () => {
        setLoading(true);

        userService.changePassword(state).then(
            (res) => {
                setOpenModalConfirm(false);
                if (res.status > 300) {
                    setLoading(false);
                } else {
                    setLoading(false);
                    SessionStorageService.clearStorage();
                    setOpenModalSucesso(true);
                }
            }
        ).catch(err => {
            setLoading(false);
            Promise.reject(err);
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <div className={classes.paper}>
                <Button
                    onClick={() => {
                        session.roles.includes('ROLE_MENTOR') ?
                            history.push("/mentoring/mentor/edicao") :
                            history.push("/mentoring/mentorado/edicao")
                    }}
                    className={classes.backButton}
                    startIcon={<ArrowBackIosSharpIcon fontSize="large" />}
                    size="large"
                >
                    {t(TROCAR_SENHA_BOTAO_VOLTAR, "Voltar")}
                </Button>

                <Typography variant="h5" className={classes.title}>
                    {t(TROCAR_SENHA_TITULO, "Atualize sua senha")}
                </Typography>


                <Typography className={classes.subtitle}>
                    {t(TROCAR_SENHA_SUBTITULO, "Uma senha forte ajuda prevenir acesso não autorizado a sua conta")}
                </Typography>

                <form className={classes.form}
                    noValidate
                    onSubmit={(e) => handleSubmit(e)}>

                    <TextField
                        className={classes.formField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        label={t(TROCAR_SENHA_SENHA_ATUAL_PLACEHOLDER, "Senha atual")}
                        name="atual"
                        type="password"
                        autoFocus
                        onChange={(event) => handleChange(event)}
                        InputLabelProps={{
                            style: {
                                color: '#1F1F1F',
                                fontFamily: 'InfraRegular',
                                fontSize: '.8rem',
                                fontStyle: 'normal',
                                fontWeight: 400,
                            }
                        }}
                    />
                    <TextField
                        className={classes.formField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        label={t(TROCAR_SENHA_NOVA_SENHA_PLACEHOLDER, "Nova senha")}
                        type="password"
                        onChange={(event) => handleChange(event)}
                        InputLabelProps={{
                            style: {
                                color: '#1F1F1F',
                                fontFamily: 'InfraRegular',
                                fontSize: '.8rem',
                                fontStyle: 'normal',
                                fontWeight: 400,
                            }
                        }}
                    />
                    <TextField
                        className={classes.formField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="newPassword_confirm"
                        name="newPassword_confirm"
                        label={t(TROCAR_SENHA_CONFIRMAR_NOVA_SENHA_PLACEHOLDER, "Confirmar nova senha")}
                        type="password"
                        onChange={(event) => handleChange(event)}
                        InputLabelProps={{
                            style: {
                                color: '#1F1F1F',
                                fontFamily: 'InfraRegular',
                                fontSize: '.8rem',
                                fontStyle: 'normal',
                                fontWeight: 400,
                            }
                        }}
                    />
                    {handlePasswordConfirmError()}


                    {loading ? <SpinnerSplendis style={{ textAlign: 'center' }} /> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isValid()}
                            className={classes.submit}
                            onClick={(e) => handleSubmit(e)}
                        >
                            {t(TROCAR_SENHA_BOTAO_CONFIRMAR, "Confirmar")}
                        </Button>
                    }
                    {handleRequestError()}

                </form>
            </div>

            <footer className={classes.footer}>
                <Typography className={classes.copyright}>
                    {"Copyright © "}
                    <Link color="inherit" href="https://mentoring.stefanini.io/">
                        Stefanini Mentoring
						</Link>{' '}
                    {new Date().getFullYear()}
                </Typography>
            </footer>

            <PasswordChangeModal
                isOpen={openModalConfirm}
                handleClose={() => setOpenModalConfirm(false)}
                handleCallback={changePassword}
            />

            <ModalSucesso
                isOpen={openModalSucesso}
                handleClose={redirectLogin}
                title={t(TROCAR_SENHA_MENSAGEM_SUCESSO, 'Senha alterada com sucesso!')}
            />
        </Container>
    );
}

export default PasswordChange;