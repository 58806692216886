import { Categoria } from "./Categoria";
import { Mentor } from "./Mentor";
import { Mentorado } from "./Mentorado";
import { DisponibilidadeMentor } from "./DisponibilidadeMentor";

/**
 * Classe que define o modelo para a mentoria com todas as suas propriedades e métodos.
 */
export class Mentoria{
    public id: number = 0;
    public mentorado: Mentorado;
    public categoria: Categoria;
    public mentor: Mentor; 
    public disponibilidade?: DisponibilidadeMentor;
    public statusMentoria?: string = 'AGENDADA';
    public timeZone?: string;
    
    constructor(){
        this.categoria = new Categoria();
        this.mentor = new Mentor();
        this.mentorado = new Mentorado();
        this.disponibilidade = new DisponibilidadeMentor();
    }
    
}