import React, { useContext, useEffect } from "react";
import { Container } from "@material-ui/core";

import { alertContext } from "../../../../../../components";
import { MentorService } from "../../../../../../service";
import { SpinnerSplendis } from "../../../../../../commons";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Mentor } from "../../../../../../models/Mentor";
import FormMentor from "../../../../../../components/mentor-form/FormMentor";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },

    formContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
    },
  })
);

/**
 * Interface utilizada pelo componente EdicaoMentor e que descreve
 * seu métodos e propriedades.
 */
interface EdicaoMentorProps {
  idMentor: any;
}

/**
 * Componente que renderiza uma instância do form para edição de mentor.
 *
 * @param {EdicaoMentorProps}
 * @returns Um container que renderiza uma instância do form para edição de mentor.
 */
const EdicaoMentor: React.FC<EdicaoMentorProps> = ({ idMentor }) => {
  const classes = useStyles();

  const mentorService = new MentorService();

  const [mentor, setMentor] = React.useState<Mentor>(new Mentor());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { showAlert } = useContext(alertContext);

  /**
   * Obtém as informações do mentor para edição.
   */
  useEffect(() => {
    setIsLoading(true);
    mentorService
      .getInformacaoMentorEdicaoById(idMentor)
      .then((res) => {
        setMentor(res.data);
      })
      .then(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Realiza uma chamada ao mentorService para editar as informações
   * do mentor.
   *
   * @param mentor O mentor que terá suas informações editadas.
   */
  const editMentor = async (mentor: Mentor): Promise<void> => {
    await mentorService.editarViaRoleMentor(idMentor, mentor);
  };

  return (
    <Container className={classes.container}>
      <div className={classes.formContainer}>
        {isLoading ? (
          <SpinnerSplendis />
        ) : (
          <FormMentor
            mentor={mentor}
            alertCallback={showAlert}
            updateMentor={editMentor}
          />
        )}
      </div>
    </Container>
  );
};

export default EdicaoMentor;
