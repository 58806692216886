import React, { useEffect } from "react";

import { CustomModal, FileUpload, SpinnerSplendis } from "../../commons";
import { Categoria } from "../../models";
import {
    Button,
    makeStyles,
    createStyles,
    Grid,
    Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { FormikTextField, FormikSelectField } from "formik-material-fields";
import { useTranslation } from "react-i18next";
import { categoriaSchema } from "../../pages/private/admin/cadastro-categoria/validations";
import {
    CATEGORIAS_SOURCE,
    CATEGORIAS_FORM_NOME_PT,
    CATEGORIAS_FORM_DESCRICAO_PT,
    CATEGORIAS_FORM_NOME_EN,
    CATEGORIAS_FORM_DESCRICAO_EN,
    CATEGORIAS_FORM_NOME_ES,
    CATEGORIAS_FORM_DESCRICAO_ES,
    CATEGORIAS_PORTUGUES,
    CATEGORIAS_INGLES,
    CATEGORIAS_ESPANHOL,
    CATEGORIAS_ALERT_SUCESSO,
    CATEGORIAS_FORM_APLICAR
} from "../../messages";

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflowY: 'auto',

            '@media only screen and (max-width: 720px)': {
                display: 'block',
            },
        },

        container: {
            width: "40rem",

            '@media only screen and (max-width: 720px)': {
                width: '90vw'
            },
        },

        descricao: {
            resize: "none",

            "@media (max-width: 1024px)": {
                maxWidth: "100%",
            },
        },

        confirmarText: {
            height: 50,
            display: "flex",
            alignItems: "center",
            textAlign: "left",
        },

        confirmarBtns: {
            "@media (max-width: 1024px)": {
                fontSize: "x-small",
                margin: 10,
            },
        },

        actionButtonsInnerContainer: {
            width: "60%",
            maxWidth: "300px",
            display: "flex",
            justifyContent: "space-around",
        },

        actionButtonsOuterContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },

        updateSpinner: {
            marginLeft: "8px",
        },

        firstFields: {
            width: "49%",
        },

        updatePhoto: {
            display: 'block',
            justifyContent: 'center',
            margin: '0 auto',
        },
        forms: {
            "& > div": {
                display: 'flex',
                justifyContent: 'space-between'
            },

            '@media only screen and (max-width: 414px)': {
                '& > div': {
                    display: 'block'
                }
            }
        },
        categoryName: {
            '@media only screen and (max-width: 414px)': {
                width: '80vw'
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '30vw'
            }
        },
        descriptionInput: {
            width: '26rem',

            '@media only screen and (max-width: 414px)': {
                width: '80vw'
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '55vw'
            }
        },
        title: {
            fontWeight: 500,
            fontSize: '1.375rem',
            marginTop: '0.6rem',
            marginBottom: 0,
        },
        updateStatusPhotoDiv: {
            display: 'grid',
            gridTemplateColumns: '1fr 2.25fr',

            '@media only screen and (max-width: 414px)': {
                display: 'block'
            }
        },
        updateStatusDiv: {
            alignSelf: 'center',

            '@media only screen and (max-width: 414px)': {
                width: '80vw'
            }
        },
        gridButton: {
            justifyContent: 'center',
            marginTop: '10px',

            '@media only screen and (max-width: 414px)': {
                justifyContent: 'flex-start',
            }
        },
        submissionButton: {
            width: '100%',
            color: 'white',
            backgroundColor: "#003B4A",

            '&:hover': {
				backgroundColor: '#0a3039',
			},

            '@media only screen and (max-width: 414px)': {
                width: '80vw'
            }
        }
    })
);

// PASSA COMO PROPS PRO CUSTOM MODAL
const paperStyle = {
    paper: {
        height: "80vh",
        "@media (max-width: 1024px)": {
            heigth: "auto",
            maxWidth: "100%",
        },
    },
    closeDiv: {
        "@media (max-width: 1024px)": {
            justifyContent: "flex-start",
        },
    },
};

/**
 * Interface utilizada pelo componente AtualizacaoCategoriaModal e que descreve
 * seu métodos e propriedades.
 */
interface AtualizacaoCategoriaModalProps {
    categoria: Categoria;
    isOpen?: boolean;
    handleClose: () => void;
    alertCallback: Function;
    updateCategoria: Function;
}

/**
 * Componente responsável pela atualização de uma categoria.
 * Acessível apenas pelo papel de ADMIN.
 * 
 * @param {AtualizacaoCategoriaModalProps} 
 * @returns O modal de atualização de categoria.
 */
const AtualizacaoCategoriaModal: React.FC<AtualizacaoCategoriaModalProps> = ({
    categoria,
    isOpen,
    handleClose,
    alertCallback,
    updateCategoria,
}) => {
    const classes = useStyles();

    const { t } = useTranslation([CATEGORIAS_SOURCE]);

    const [formObject, setFormObject] = React.useState<Categoria>(
        new Categoria()
    );

    const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);

    /**
     * Configura o form de categoria sempre que o modal é aberto.
     */
    useEffect(() => {
        setFormObject(categoria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    /**
     * Função responsável pela atualização dos states a partir do event recebido.
     * 
     * @param e O evento com informações para atualização do form.
     */
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        let oldFormObject = formObject;
        oldFormObject[e.target.name] = e.target.value;
        setFormObject(oldFormObject);
    };

    /**
     * Lida com todo o processo de submissão das informações do form do modal
     * para o servidor.
     */
    const handleSubmit = () => {
        setUpdateLoading(true);
        updateCategoria(formObject)
            .then((res) =>
                alertCallback(200, t(CATEGORIAS_ALERT_SUCESSO, "Atualização realizada com sucesso!"))
            )
            .then(() => {
                setUpdateLoading(false);
                closeAllModais();
            });
    };

    const closeAllModais = () => {
        handleClose();
    };

    /**
     * Função responsável por receber arquivo e atualizar o state.
     * 
     * @param file O arquivvo com a imagem da categoria.
     */
    const fileCallback = (file: string) => {
        let oldFormObject = formObject;
        oldFormObject.imagem = file;
        setFormObject(oldFormObject);
    };

    /**
     * Verifica se os campos obrigatórios estão preenchidos.
     * 
     * @returns {boolean} True ou False caso os campos do form estajam preenchidos ou não.
     */
    const testReady = () => {
        if (
            formObject.nomePt !== "" &&
            formObject.descricaoPt !== "" &&
            formObject.imagem
        ) {
            return true;
        }
        return false;
    };

    /**
     * Função que renderiza o form de atualização de categoria.
     * 
     * @returns O from para atualização de categoria.
     */
    const atualizacaoCategoriaForm = () => {
        return (
            <div className={classes.container}>
                <Formik
                    validateOnMount={true}
                    initialValues={formObject}
                    validationSchema={categoriaSchema(t)}
                    onSubmit={handleSubmit}
                >
                    {({ isValid }) => {
                        return (
                            <Form autoComplete="off" style={{ padding: 10 }}>
                                <div className={classes.updateStatusPhotoDiv}>
                                    <div className={classes.updateStatusDiv}>
                                        <FormikSelectField
                                            value={formObject.status}
                                            name="status"
                                            label="Status"
                                            margin="normal"
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            options={[
                                                { label: 'Ativo', value: 'ATIVO' },
                                                { label: 'Inativo', value: 'REMOVIDO' }
                                            ]}
                                        />
                                    </div>
                                    <div className={classes.updatePhoto}>
                                        <span style={{ textAlign: 'center' }}>
                                            <FileUpload
                                                {...{ callback: fileCallback }}
                                                customLabel={
                                                    formObject.imagem
                                                        ? "Editar imagem"
                                                        : "Inserir imagem"
                                                }
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.forms}>
                                    {/* Forms para nomes e descrições das categorias */}

                                    <Typography variant="h6" className={classes.title}>{t(CATEGORIAS_PORTUGUES, "Português")}</Typography>
                                    <div>
                                        <FormikTextField
                                            value={formObject.nomePt}
                                            name="nomePt"
                                            onChange={handleChange}
                                            label={t(CATEGORIAS_FORM_NOME_PT, "Nome Português")}
                                            margin="normal"
                                            className={classes.categoryName}
                                            variant="outlined"
                                        />
                                        <FormikTextField
                                            value={formObject.descricaoPt}
                                            name="descricaoPt"
                                            onChange={handleChange}
                                            label={t(CATEGORIAS_FORM_DESCRICAO_PT, "Descrição Português")}
                                            margin="normal"
                                            className={classes.descriptionInput}
                                            variant="outlined"
                                        />
                                    </div>

                                    <Typography variant="h6" className={classes.title}>{t(CATEGORIAS_INGLES, "Inglês")}</Typography>
                                    <div>
                                        <FormikTextField
                                            value={formObject.nomeEn}
                                            name="nomeEn"
                                            onChange={handleChange}
                                            label={t(CATEGORIAS_FORM_NOME_EN, "Nome Inglês")}
                                            margin="normal"
                                            className={classes.categoryName}
                                            variant="outlined"
                                        />
                                        <FormikTextField
                                            value={formObject.descricaoEn}
                                            name="descricaoEn"
                                            onChange={handleChange}
                                            label={t(CATEGORIAS_FORM_DESCRICAO_EN, "Descrição Inglês")}
                                            margin="normal"
                                            className={classes.descriptionInput}
                                            variant="outlined"
                                        />
                                    </div>

                                    <Typography variant="h6" className={classes.title}>{t(CATEGORIAS_ESPANHOL, "Espanhol")}</Typography>
                                    <div>
                                        <FormikTextField
                                            value={formObject.nomeEs}
                                            name="nomeEs"
                                            onChange={handleChange}
                                            label={t(CATEGORIAS_FORM_NOME_ES, "Nome Espanhol")}
                                            margin="normal"
                                            className={classes.categoryName}
                                            variant="outlined"
                                        />
                                        <FormikTextField
                                            value={formObject.descricaoEs}
                                            name="descricaoEs"
                                            onChange={handleChange}
                                            label={t(CATEGORIAS_FORM_DESCRICAO_ES, "Descrição Espanhol")}
                                            margin="normal"
                                            className={classes.descriptionInput}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <Grid
                                    container
                                    className={classes.gridButton}
                                >
                                    {updateLoading ? (
                                        <SpinnerSplendis />
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={!testReady()}
                                            className={classes.submissionButton}
                                        >
                                            {t(CATEGORIAS_FORM_APLICAR, 'APLICAR')}
                                        </Button>
                                    )}
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    };

    return (
        <CustomModal
            className={classes.modal}
            style={paperStyle}
            isOpen={isOpen}
            handleClose={handleClose}
            closeleft="true"
        >
            {atualizacaoCategoriaForm()}
        </CustomModal>
    );
};

export default AtualizacaoCategoriaModal;
