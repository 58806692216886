import React from 'react';
import LanguageIcon from '@material-ui/icons/Language';

import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SpainFlagIcon, BrazilFlagIcon, UsaFlagIcon } from '../../commons';

const useStyles = makeStyles(() =>
    createStyles({
        languagesContainer: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '1vw',
            
        },

        languageSelectorButton: {
            color: '#003B4A',
            position: 'absolute',
            right: '10px',
            top: '25px',
            
        },

        languageIcon: {
            cursor: 'pointer',
            fontSize: '30px',
            
        },

        languageLabel: {
            fontFamily: 'InfraRegular',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 500,
            paddingBottom: '.7rem',
        },

        flagContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },

        flagIcon: {
            marginRight: '8px',
        }
    })
);

/**
 * Botão com o menu para a troca de idiomas atrelado a esse.
 * 
 * @returns Botão e menu para a troca de idiomas.
 */
export default function TrocaIdiomas() {
    const classes = useStyles();

    // Hook utilizado para realizar a tradução dos textos do componente.
    const { i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    /**
     * Lida com o clique para a mudança de idioma no menu.
     * 
     * @param e Evento que dispara a escolha dos idiomas no menu.
     */
    const handleClickLanguagesMenu = (e: React.ChangeEvent<any>) => {
        setAnchorEl(e.currentTarget);
    }

    /**
     * Realiza o fechamento do menu de escolha de idiomas.
     */
    const handleClose = () => {
        setAnchorEl(null);
    }

    /**
     * Lida com a escolha do idioma por parte do usuário, realizando a
     * tradução da aplicação de acordo com o idioma escolhido.
     * 
     * @param language O idioma escolhido pelo usuário.
     */
    const handleOption = (language: string) => {
        i18n.changeLanguage(language);
        setAnchorEl(null);
    }

    return (
        <div className={classes.languagesContainer}>
            <Button
                onClick={handleClickLanguagesMenu}
                className={classes.languageSelectorButton} >
                <LanguageIcon className={classes.languageIcon} />
            </Button>

            <Menu
                id="languages-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleOption('es')}>
                    <div className={classes.flagContainer}>
                        
                        <div className={classes.languageLabel}>
                            Español
                        </div>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => handleOption('pt')}>
                    <div className={classes.flagContainer}>
                      
                        <div className={classes.languageLabel}>
                            Português
                        </div>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => handleOption('en')}>
                    <div className={classes.flagContainer}>
                        
                        <div className={classes.languageLabel}>
                            English
                        </div>
                    </div>
                </MenuItem>
            </Menu>
        </div>
    );
}