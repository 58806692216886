import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

/**
 * Componente responsável por apresentar o spinner para loadings.
 * 
 * @param props Propriedades passadas para o componente para customização do mesmo.
 * @returns O componente spinner.
 */
export default function SpinnerSplendis(props) {
  const classes = useStyles();

  const styleProp = props.style;
  const size = props.size;
  
  return (
    <div className={classes.root} style={styleProp}>
      <CircularProgress size={ size } disableShrink color={props.color}/>
    </div>
  );
}