import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-xhr-backend';

i18n
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(LanguageDetector)
  .init({
    debug: false,
 
    fallbackLng: 'pt',
    whitelist: ['es', 'en', 'pt'],
 
    /*interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }*/
  });
 
export default i18n;