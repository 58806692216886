import React from 'react';
import Container from '@material-ui/core/Container';
import MaterialTable from "@material-table/core";

import { Categoria } from '../../../../../models';
import RhCategoriasDashBoardProps from './RhCategoriasDashBoardProps';
import Button from '@material-ui/core/Button';
import AtualizacaoCategoriaModal from '../../../../../components/edicao-categoria-modal/AtualizacaoCategoriaModal';
import { alertContext, MenuRh } from '../../../../../components';
import {
  RH_CATEGORIAS_SOURCE,
  RH_AVALIACOES_GERENCIAR_CATEGORIAS,
  RH_AVALIACOES_SEM_CATEGORIAS,
  RH_CATEGORIAS_NOME,
  RH_CATEGORIAS_DESCRICAO,
  RH_CATEGORIAS_EDITAR,
  RH_CATEGORIAS_BUSCAR,
  RH_CATEGORIAS_DE
} from '../../../../../messages';
import { withTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { capitalize, createStyles, Typography, withStyles } from '@material-ui/core';

const pageStyle = () =>
  createStyles({
    root: {
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      padding: 0,

      '@media only screen and (max-width: 414px)': {
        width: '90vw'
      }
    },
    titleDiv: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center'
    },
    title: {
      fontFamily: 'infraRegular',
      lineHeight: '150%',
      fontSize: '1.625rem',
    },
    button: {
      width: '4.813rem',
      height: '2.75rem'
    },
    link: {
      textDecoration: 'none',
    },
    dashboardTitle: {
      fontFamily: 'InfraRegular',
      fontStyle: 'normal',
      fontWeight: 600,

      '@media only screen and (max-width: 414px)': {
        fontSize: '1rem'
      }
    },
  });

/**
 * Estilização utilizada na tabela de gerenciamento de categorias.
 */
const tableStyle = {
  container: {
    marginTop: '1rem',
    marginBottom: '50px',
    minHeight: 400,
    fontFamily: 'infraRegular',
    height: 'auto',
    backgroundColor: '#F0F1F3',
    paddingBottom: '1rem'
  },
  image: {
    width: '10vh',
    height: '10vh',
    borderRadius: '100%'
  },
  headerStyle: {
    backgroundColor: '#CFD4DA'
  },
  title: {
    fontFamily: "infraMedium"
  },
  searchField: {
    backgroundColor: '#F0F1F3',
  },
};

/**
 * Estilização utilizada de forma geral em todo o container da tabela.
 */
const GlobalCss = withStyles((theme) => ({
  "@global": {
    ".MuiTableRow-root": {
      "&:nth-of-type(even)": {
        backgroundColor: "#FFFFFF",
      },
      "&:nth-of-type(odd)": {
        backgroundColor: "#F8F8F8",
      },
    }
  },
}))(() => null);

/**
 * Interface utilizada pelo componente abaixo e que descreve
 * seu métodos e propriedades.
 */
export interface CategoriasDashBoardState {
  nome?: string;
  descricao?: string;
  imagem?: any;
  dataTable: Array<Categoria>;
  loading: boolean;
  editModalIsOpen: boolean;
  categoriaBeingEditted: Categoria;
}

const defaultImg = 'https://s3-ap-southeast-1.amazonaws.com/blog-edukasyon/wp-content/uploads/2019/03/01100225/edukasyon.ph-logo.png';

/**
 * Componente para renderizar a tabela de Categorias.
 * 
 * Apenas acessível para o papel de ADMIN.
 */
class RhCategoriasDashBoardComponent extends React.Component<RhCategoriasDashBoardProps, CategoriasDashBoardState> {
  table = React.createRef<any>();

  constructor(props: any) {
    super(props);

    this.state = {
      dataTable: [],
      editModalIsOpen: false,
      loading: true,
      categoriaBeingEditted: new Categoria()
    };
  }

  componentDidMount() {
    this.getCategorias();
  }

  /**
   * Renderização do modal para edição da Categoria.
   * 
   * @param showAlert O componente utilizado na exibição de mensagens de alerta. 
   * @returns O modal de atualização de categoria.
   */
  editModal(showAlert) {
    return <AtualizacaoCategoriaModal
      updateCategoria={(categoria) => this.handleCategoriaUpdate(categoria)}
      isOpen={this.state.editModalIsOpen}
      handleClose={() => this.closeEditModal()}
      categoria={JSON.parse(JSON.stringify(this.state.categoriaBeingEditted))}
      alertCallback={showAlert}
    />
  }

  /**
   * Apresenta modal para edição da Categoria.
   * 
   * @param categoria A categoria a ser editada.
   */
  openEditModal(categoria) {
    this.setState({ categoriaBeingEditted: categoria, editModalIsOpen: true });
  }

  /**
   * Fecha modal para edição da Categoria.
   */
  closeEditModal() {
    this.setState({ editModalIsOpen: false });
  }

  /**
   * Realiza a atualização da categoria passando os novos valores para o backend.
   * 
   * @param categoria A categoria a ser atualizada.
   */
  handleCategoriaUpdate(categoria) {
    return this.props.categoriaService.editar(categoria.id, categoria).then((res) => {
      this.updateTable();
      return res;
    });
  }

  /**
   * Atualiza a tabela de categorias disparando a busca por categorias cadastradas.
   */
  updateTable() {
    this.getCategorias();
  }

  render() {
    const { t, i18n, classes } = this.props;

    // Recebe o idioma do usuário.
    const language = capitalize(i18n.language);

    return (
      <>
        {/* Sub header */}
        <Container maxWidth="lg" fixed className={classes.root}>
          <div>
            <MenuRh />
          </div>
          <div className={classes.titleDiv}>
            <Typography variant="h5" align="left" className={classes.dashboardTitle}>{t(RH_AVALIACOES_GERENCIAR_CATEGORIAS, "Gerenciar Categorias")}</Typography>
          </div>
        </Container>

        {/* Body */}
        <Container maxWidth="lg" fixed style={tableStyle.container}>
          {/* {<img src={this.state.imagem} alt='User language' />} */}
          {<div style={{ paddingBottom: '3vh' }}></div>}
          <GlobalCss />
          <alertContext.Consumer>
            {
              ({ showAlert }) => {
                return (
                  <React.Fragment>
                    { this.editModal(showAlert)}
                    <MaterialTable
                      title={""}
                      tableRef={this.table}
                      options={{
                        sorting: true,
                        search: true,
                        headerStyle: tableStyle.headerStyle,
                        searchFieldStyle: tableStyle.searchField,
                      }}
                      style={{ backgroundColor: "#CFD4DA" }}
                      localization={{
                        header: {
                          actions: t(RH_CATEGORIAS_EDITAR, 'Editar')
                        },
                        body: {
                          emptyDataSourceMessage: t(RH_AVALIACOES_SEM_CATEGORIAS, 'Sem categorias para exibir'),
                          editTooltip: t(RH_CATEGORIAS_EDITAR, 'Editar')
                        },
                        pagination: {
                          labelDisplayedRows: `{from}-{to} ${t(RH_CATEGORIAS_DE, 'de')} {count}`,
                          labelRowsSelect: 'itens'
                        },
                        toolbar: {
                          searchTooltip: t(RH_CATEGORIAS_BUSCAR, 'Buscar'),
                          searchPlaceholder: t(RH_CATEGORIAS_BUSCAR, 'Buscar')
                        }
                      }}
                      columns={[
                        {
                          title: '',
                          field: 'url',
                          align: 'center',
                          editable: 'never',
                          filtering: false,
                          render: data => <img alt="#" src={!data.imagem ? defaultImg : data.imagem} style={tableStyle.image} />
                        },
                        { title: t(RH_CATEGORIAS_NOME, "Título da categoria"), field: "nome" + language, headerStyle: tableStyle.title },
                        { title: t(RH_CATEGORIAS_DESCRICAO, "Descrição"), field: "descricao" + language, headerStyle: tableStyle.title },
                        {
                          title: '',
                          align: 'right',
                          render: rowData => <Button color="primary" onClick={() => this.openEditModal(rowData)}><EditIcon /></Button>
                        },
                      ]}

                      data={this.state.dataTable}
                      isLoading={this.state.loading}
                    />
                  </React.Fragment>
                );
              }
            }
          </alertContext.Consumer>
        </Container >
      </>
    );
  }

  /**
   * Realiza o fetch das categorias cadastradas
   */
  getCategorias() {
    this.setState({ loading: true })
    this.props.categoriaService.getCategoriasRH()
      .then((dataTable) => {
        this.setState({ dataTable: dataTable, loading: false })
      });
  }

  /**
   * Exclui uma categoria com base no seu id.
   * 
   * @param id O id da categoria.
   * 
   * Obs.: Método não está totalmente implementado.
   */
  delete(id: any) {
    this.props.categoriaService.delete(id)
      .then(() => {
      });
  }

}

export default withTranslation(RH_CATEGORIAS_SOURCE)(withStyles(pageStyle)(RhCategoriasDashBoardComponent));