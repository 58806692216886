import React, { useEffect, memo } from "react";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import DayOfWeekAndTimeRow from "../day-of-week-and-time-row/DayOfWeekAndTimeRow";
import { Disponibilidade } from "../../../../models";
import { mapWeekDayToOrdinal } from "../../../../utils/FormUtils";

const useStyles = makeStyles(() =>
  createStyles({
    availabilityContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })
);

/**
 * Interface utilizada pelo componente DsiplayDisponibilidade e que descreve
 * seu métodos e propriedades.
 */
interface DisplayDisponibilidadeProps {
  disponibilidades: Array<Disponibilidade>;
  handleDisponibilidadeChange: Function;
  maxDisponibilidades: number;
  dadosValidos: Function;
  handleRecorrenciaChange: Function;
  recorrencia: object;
}

/**
 * Componente responsável pela apresentação e edição de disponibilidade dos dias e horas
 * no modal de edição do mentor.
 *
 * @param {DisplayDisponibilidadeProps}
 */
const DisplayDisponibilidade: React.FC<DisplayDisponibilidadeProps> = ({
  disponibilidades,
  handleDisponibilidadeChange,
  maxDisponibilidades,
  dadosValidos,
}) => {
  const classes = useStyles();

  const [disponibilidadesState, setDisponibilidadesState] = React.useState<
    Disponibilidade[]
  >([]);

  /**
   * Configura disponibilidadeState sempre que o props disponibilidades mudar.
   */
  useEffect(() => {
    setDisponibilidadesState(disponibilidades);
  }, [disponibilidades]);

  /**
   * Lida com a atualização do state disponibilidadesState, a partir do event recebido.
   *
   * @param e O evento que dispara a mudança nas disponibilidades.
   * @param index O índice no array de disponibilidades.
   */
  const handleChange = (e, index) => {
    let newDisponibilidades = [...disponibilidadesState];
    newDisponibilidades[index][e.target.name] = e.target.value;

    if (e.target.name === "dia") {
      newDisponibilidades[index]["ordinal"] = mapWeekDayToOrdinal(
        e.target.value
      );
    }

    //Confere validação no change, de acordo com state
    dadosValidos(validaDisponibilidades());

    setDisponibilidadesState(newDisponibilidades);
    handleDisponibilidadeChange(newDisponibilidades);
  };

  /**
   * Verifica se a disponbilidade recebida como argumento é válida.
   *
   * @param disponibilidade O objeto disponibilidade contento dia e hora.
   * @returns True ou False caso o dia e a hora sejam diferentes de string vazia.
   */
  const validaDisponibilidade = (disponibilidade: Disponibilidade) => {
    return (
      disponibilidade.dia !== "" &&
      disponibilidade.hora !== "" &&
      disponibilidade.dataInicio !== "" &&
      disponibilidade.intervaloSemanas !== 0
    );
  };

  /**
   * Verifica se todas as disponibilidades no array de disponibilidades são válidas.
   *
   * @param disponibilidades O array contendo todas as disponibilidades.
   * @returns True ou False dependendo se todas as disponibilidade sejam válidas ou não.
   */
  const validaDisponibilidades = (disponibilidades = disponibilidadesState) => {
    for (let i = 0; i < disponibilidades.length; i++) {
      if (validaDisponibilidade(disponibilidades[i]) === false) {
        return false;
      }
    }
    return true;
  };

  /**
   * Lida com a remoção dos campos de uma disponibilidade.
   *
   * @param index
   */
  const handleErase = (index) => {
    disponibilidadesState.splice(index, 1);

    let newDisponibilidades = new Array<Disponibilidade>(
      ...disponibilidadesState
    );
    dadosValidos(validaDisponibilidades(newDisponibilidades));
    setDisponibilidadesState(newDisponibilidades);
    handleDisponibilidadeChange(newDisponibilidades);
  };

  /**
   * Lida com a adição dos campos de uma disponibilidade.
   */
  const handleAdd = () => {
    let newDisponibilidades = [...disponibilidadesState, new Disponibilidade()];
    dadosValidos(false);
    setDisponibilidadesState(newDisponibilidades);
    handleDisponibilidadeChange(newDisponibilidades);
  };

  return (
    <div className={classes.availabilityContainer}>
      {disponibilidadesState.map((disponibilidade, index) => {
        return (
          <DayOfWeekAndTimeRow
            key={JSON.stringify(disponibilidade)}
            handleChange={(e) => handleChange(e, index)}
            disponibilidade={disponibilidade}
            handleErase={() => handleErase(index)}
            disableErase={disponibilidadesState.length === 1}
          />
        );
      })}
      {disponibilidadesState.length !== maxDisponibilidades && (
        <Button onClick={handleAdd}>
          <AddIcon />
        </Button>
      )}
    </div>
  );
};

export default memo(DisplayDisponibilidade);
