import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './MessageHandler.css';

/**
 * Componente utilizado pelo ExceptionHandler para exibir notificações.
 * 
 * @param props
 * @returns Um toast para exibição de notificações.
 */
export function NotificationHandler(props) {

    return (
        <ToastContainer
            enableMultiContainer
            containerId={'notify'}
            position="top-right"
            autoClose={5000}
            closeButton={false}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
}

/**
 * Componente utilizado pelo ExceptionHandler para exibir informações.
 * 
 * @param props 
 * @returns Um toast para exibição de informações.
 */
export function InformationHandler(props) {
    
    return (
        <ToastContainer
            limit={1}
            enableMultiContainer
            containerId={'inform'}
            position="top-center"
            autoClose={false}
            closeButton={false}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { NotificationHandler, InformationHandler };