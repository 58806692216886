import React from 'react';
import { PublicRoutes } from '../../routes';

/**
 * Renderiza as rotas públicas da aplicação.
 * 
 * @returns As rotas públicas da aplicação.
 */
export default function PublicApp() {
  
  return ( 
    <React.Fragment>
      <PublicRoutes></PublicRoutes>      
    </React.Fragment>
  );
}
