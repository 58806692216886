import React, { useContext, useState } from "react";
import {
  Paper,
  Button,
  Tooltip,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  ClickAwayListener,
  AppBar,
  Toolbar,
  Divider
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { FormikTextField, FormikSelectField } from "formik-material-fields";
import HelpIcon from "@material-ui/icons/Help";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import {
  CadastroColaboradorSchema,
  AtualizarColaboradorSchema,
} from "./validations";

import { FileUpload, SpinnerSplendis } from "../../commons";

import {
  estadosBrasilToSelectField,
//   mapMessageAboutPathRedirect,
  emailDomains,
  countriesListPortuguese,
  countriesListSpanish,
  countriesListEnglish
} from "../../utils/FormUtils";

import {
  ColaboradorService,
  LoginService,
  UserContext,
  LocalStorageService,
} from "../../service";

import { alertContext } from "../../components";
import { Colaborador } from "../../models";

import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import {
  FORM_COLABORADOR_SOURCE,
  FORM_COLABORADOR_CAMPO_EMAIL_TOOLTIP,
  FORM_COLABORADOR_CAMPO_SENHA_PLACEHOLDER,
  FORM_COLABORADOR_CAMPO_CONFIRMAR_SENHA_PLACEHOLDER,
  FORM_COLABORADOR_CAMPO_NOME_PLACEHOLDER,
  FORM_COLABORADOR_CAMPO_EMAIL_PLACEHOLDER,
  FORM_COLABORADOR_CAMPO_TIMEZONE_PLACEHOLDER,
  FORM_COLABORADOR_CAMPO_UF_PLACEHOLDER,
  FORM_COLABORADOR_CAMPO_CARGO_PLACEHOLDER,
  FORM_COLABORADOR_BOTAO_APLICAR,
  FORM_COLABORADOR_BOTAO_ATUALIZAR,
  FORM_COLABORADOR_BOTAO_VOLTAR,
  FORM_COLABORADOR_CAMPO_PAIS_PLACEHOLDER,
  FORM_COLABORADOR_BOTAO_MUDAR_SENHA,
  FORM_COLABORADOR_MENSAGEM_INFORMATIVA,
  FORM_COLABORADOR_CADASTRO_BEM_SUCEDIDO,
  FORM_COLABORADOR_ATUALIZACAO_BEM_SUCEDIDA,
  FORM_COLABORADOR_REDIRECIONAMENTO_HOME
} from "../../messages";

import BelgoAramesWhite from '../../images/logoBelgoAramesWhite.png';

/**
 * Entidade com as propriedades presentes no objeto colaborador.
 */
export class ColaboradorFormEntity {
  id?: number;
  nome?: string = "";
  email?: string = "";
  cargo?: string = "";
  senha?: string = "";
  confSenha?: string = "";
  timeZone?: string = "";
  uf?: string = "";
  foto?: string = "";
  pais?: string = "";

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100rem',
    },
    paper: {
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: '1rem auto',
      padding: '1%',
      width: '30%',

      '@media only screen and (max-width: 414px)': {
        marginTop: '5vh',
        width: '90%',
      },

      '@media only screen and (min-width: 415px) and (max-width: 720px)': {
        marginTop: '110vh',
        width: '90%',
      },
    },
    appBar: {
      backgroundColor: '#003B4A',
      height: '112px',
      width: '100%',
    },
    appBarTitle: {
      backgroundImage: `url(${BelgoAramesWhite})`,
      backgroundRepeat: 'no-repeat',
      bottom: '71.48%',
      display: 'flex',
      flex: '1 0 0',
      height: '8vh',
      margin: '1.1rem auto 0 5rem',
      width: '430px',

      '@media only screen and (max-width: 414px)': {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        margin: '1.5rem auto 0 auto',
      }
    },
    formContainer: {
      width: '90%',
      margin: 'auto',
    },
    formContainerMobile: {
      '@media only screen and (min-width: 415px) and (max-width: 720px)': {
        marginTop: '-105vh',
      }
    },
    topMessage: {
      color: 'grey',
      fontFamily: 'InfraRegular',
      fontSize: '.8rem',
      fontStyle: 'normal',
      marginBottom: '2vh'
    },
    tooltipCustomStyle: {
      maxWidth: 300,
    },
    emailContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    emailField: {
      width: 'calc(100% - 30px)',
    },
    emailTooltipContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '25px',
    },
    countryStateContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    countryField: {
      float: 'left',
      marginTop: '.6rem',
      width: '70%',

      '& > div': {
        height: '2.7rem',
      }
    },
    stateField: {
      float: 'right',
      marginTop: '.6rem',
      width: '25%',

      '& > div': {
        height: '2.7rem',
      }
    },
    roleTimezoneContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    roleField: {
      marginTop: '1rem',
      width: '48%',

      '& > div': {
        height: '2.7rem'
      }
    },
    timezoneField: {
      marginTop: '1rem',
      width: '48%',

      '& > div': {
        height: '2.7rem',
      }
    },
    passwordField: {
      '& > div': {
        height: '2.7rem',
      }
    },
    actionButton: {
      backgroundColor: '#003B4A',
      color: '#FFF',
      fontFamily: 'InfraRegular',
      fontSize: '.875rem',
      fontStyle: 'normal',
      fontWeight: 500,
      margin: 'auto',
      marginTop: '1rem',
      width: '100%',

      '&:hover': {
        backgroundColor: '#003B4A',
        opacity: 0.8
      },

      '@media only screen and (max-width: 414px)': {
        marginBottom: '1rem',
      },

      '@media only screen and (max-width: 650px)': {
        marginBottom: '1.2rem',
      }
    },
    divider: {
      marginTop: '1rem',
    },
    changePassword: {
      color: '#003B4A',
      fontFamily: 'InfraRegular',
      fontSize: '.875rem',
      fontStyle: 'normal',
      fontWeight: 500,
      margin: 'auto',
      marginBottom: '1rem',
      marginTop: '1.1rem',
      textDecoration: 'none',
      textTransform: 'uppercase',
      width: '90%',

      '&:hover': {
        opacity: '0.8',
      },

      '@media only screen and (max-width: 414px)': {
        marginBottom: '1rem',
      }
    },
    backButton: {
      bottom: '50%',
      color: '#003B4A',
      display: 'flex',
      fontFamily: 'InfraRegular',
      fontSize: '.8rem',
      fontStyle: 'normal',
      fontWeight: 500,
      left: '5vw',
      margin: '2vh 2vw',
      position: 'absolute',

      '@media only screen and (max-width: 414px)': {
        left: '18vh',
        bottom: '-12vh',
      },

      '@media only screen and (min-width: 415px) and (max-width: 720px)': {
        left: '68vh',
        bottom: '-35vh',
      }
    },
    backButtonSignup: {
      bottom: '50%',
      color: '#003B4A',
      display: 'flex',
      fontFamily: 'InfraRegular',
      fontSize: '.8rem',
      fontStyle: 'normal',
      fontWeight: 500,
      left: '5vw',
      margin: '2vh 2vw',
      position: 'absolute',

      '@media only screen and (max-width: 414px)': {
        left: '18vh',
        bottom: '-45vh',
      },

      '@media only screen and (min-width: 415px) and (max-width: 720px)': {
        left: '68vh',
        bottom: '-160vh',
      }
    }
  })
);

/**
 * Função que mapeia o colaborador passado via props com a
 * entidade declarada logo acima.
 *
 * @param colaborador O colaborador passado ao form via props.
 * @returns Um FormEntity mapeado com as propriedades do colaborador passdado via props.
 */
export const mapColaboradorToColaboradorFormEntity = (colaborador: Colaborador) => {
  let colaboradorFormEntity = new ColaboradorFormEntity();
  colaboradorFormEntity.id = colaborador.id;
  colaboradorFormEntity.nome = colaborador.nome;
  colaboradorFormEntity.email = colaborador.email;
  colaboradorFormEntity.cargo = colaborador.cargo;
  colaboradorFormEntity.timeZone = colaborador.timeZone;
  colaboradorFormEntity.pais = colaborador.pais;
  colaboradorFormEntity.uf = colaborador.uf;
  colaboradorFormEntity.foto = colaborador.foto;

  return colaboradorFormEntity;
};

/**
 * Interface utilizada pelo componente FormColaborador e que descreve
 * seu métodos e propriedades.
 */
interface FormColaboradorProps {
  colaborador?: Colaborador
}

/**
 * Componente responsável pela renderização do formulário para criação e edição de um colaborador.
 *
 * @param {FormColaboradorProps}
 * @returns
 */
const FormColaborador: React.FC<FormColaboradorProps> = ({ colaborador }) => {
  // Biblioteca externa para obter os países e seus respectivos fusos horários.
  const ct = require('countries-and-timezones');

  // Hook de estilização
  const classes = useStyles();

  const loginService: LoginService = new LoginService();

  // Hooks de tradução
  const { t, i18n } = useTranslation([FORM_COLABORADOR_SOURCE]);

  const history = useHistory();

  let initialState = colaborador
    ? mapColaboradorToColaboradorFormEntity(colaborador)
    : new ColaboradorFormEntity();

  const [formObject, setFormObject] = useState<ColaboradorFormEntity>(
    initialState
  );

  const [hasPhoto, setHasPhoto] = useState<boolean>(
    formObject.foto ? true : false
  );
  const [enableSubmit, setEnableSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [, setTimezoneLabel] = useState<string>('');
  const [timezoneValue, setTimezoneValue] = useState<string>('');

  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);

  const colaboradorService: ColaboradorService = new ColaboradorService();

  const { login } = useContext(UserContext);
  const { showAlert } = useContext(alertContext);

  /**
   * Função que verifica se a senha inserida pelo usuário é igual a senha
   * inserida novamente pelo mesmo usuário.
   *
   * @returns True ou False caso as senhas sejam iguais ou não.
   */
  const passwordMatch = (): boolean => {
    return formObject.senha === formObject.confSenha;
  };

  /**
   * Função que lida com a atualização dos states, a partir do event recebido.
   *
   * @param e O evento disparado pelos diferentes campos do form.
   * @param name O atributo correspondente ao evento disparado.
   */
  const handleChange = (e, name = e.target.name) => {
    if (name === 'pais') {
      searchCountry(e.target.value);

      if (e.target.value !== 'BRA') {
        formObject['uf'] = '';
      }
    }

    if (name === 'timeZone') {
      setTimezoneValue(e.target.value);
    }

    formObject[name] = e.target.value;
    setFormObject(formObject);
  };

  /**
   * Busca o país com base no código de três letras do mesmo(e.g. BRA).
   *
   * @param countryCode O código de três letras do país.
   */
  const searchCountry = (countryCode: string) => {
    countriesListEnglish().forEach((country) => {
      if (country.code === countryCode) {
        searchTimezone(country.name);
      }
    });
  }

  /**
   * Busca o timezone de um determinado país.
   *
   * @param countryName O nome do país o qual se deseja buscar o seu timezone.
   */
  const searchTimezone = (countryName: string) => {
    const countries: { id: string, name: string, timezones: Array<string> } = ct.getAllCountries();

    Object.values(countries).forEach((country) => {
      if (country['name'] === countryName) {
        getTimezoneInfo(country['timezones'][country['timezones'].length - 1])
      }
    });
  }

  /**
   * Obtém informações relativas a um timezone específico.
   *
   * @param timezone O timezone o qual será buscada as informações.
   */
  const getTimezoneInfo = (timezone: string) => {
    const timezones: {
      aliasOf: string,
      country: string,
      dstOffset: number,
      dstOffsetStr: string,
      name: string,
      utcOffset: number,
      utcOffsetStr: string
    } = ct.getAllTimezones();

    Object.values(timezones).forEach((timezoneInfo) => {
      if (timezoneInfo['name'] === timezone) {
        formObject.timeZone = timezone;
        setFormObject(formObject);
        setTimezoneLabel(`(GMT${timezoneInfo['utcOffsetStr']}) ${timezone}`);
        setTimezoneValue(timezone);
      }
    });
  }

  /**
   * Obtém todos os timezones disponíveis na biblioteca utilizada.
   *
   * @returns Um array de objetos onde cada objeto contém como chave
   *          o nome do timezone, e valor o timezone específico.
   */
  const getAllTimezones = () => {
    const timezones: {
      aliasOf: string,
      country: string,
      dstOffset: number,
      dstOffsetStr: string,
      name: string,
      utcOffset: number,
      utcOffsetStr: string
    } = ct.getAllTimezones();

    return Object.values(timezones).map((timezone) => {
      return {
        value: timezone['name'],
        label: `(GMT${timezone['utcOffsetStr']}) ${timezone['name']}`
      }
    });
  }

  function FilterTimeZone(timezone){
    return timezone.value !== 'WET' && timezone.value !== 'MST' && timezone.value !== 'PST8PDT' && timezone.value !== 'MET' && timezone.value !== 'MST7MDT' && timezone.value !== 'HST' && timezone.value !== 'Etc/UTC' && timezone.value !== 'Etc/GMT+12' && timezone.value !== 'Etc/GMT+11' && timezone.value !== 'Etc/GMT+10' && timezone.value !== 'Etc/GMT+9' && timezone.value !== 'Etc/GMT+8' && timezone.value !== 'Etc/GMT+7' && timezone.value !== 'Etc/GMT+6' && timezone.value !== 'Etc/GMT+5' && timezone.value !== 'Etc/GMT+4' && timezone.value !== 'Etc/GMT+3'&& timezone.value !== 'Etc/GMT+2' && timezone.value !== 'Etc/GMT+1' && timezone.value !== 'Etc/GMT-1'&& timezone.value !== 'Etc/GMT-2' && timezone.value !== 'Etc/GMT-3' && timezone.value !== 'Etc/GMT-4' && timezone.value !== 'Etc/GMT-5' && timezone.value !== 'Etc/GMT-6' && timezone.value !== 'Etc/GMT-7' && timezone.value !== 'Etc/GMT-8' && timezone.value !== 'Etc/GMT-9' && timezone.value !== 'Etc/GMT-10'&& timezone.value !== 'Etc/GMT-11' && timezone.value !== 'Etc/GMT-12'&& timezone.value !== 'Etc/GMT-13'&& timezone.value !== 'Etc/GMT-0'&& timezone.value !== 'EST'&& timezone.value !== 'EET'&& timezone.value !== 'CET' && timezone.value !== 'CST6CDT' && timezone.value !== 'Etc/Zulu' && timezone.value !== 'UTC' && timezone.value !== 'GMT' && timezone.value !== 'Etc/UCT' && timezone.value !== 'Etc/GMT+0' && timezone.value !== 'Etc/GMT' && timezone.value !== 'UCT' && timezone.value !== 'GMT0' && timezone.value !== 'GMT+0' && timezone.value !== 'GMT-0' && timezone.value !== 'Zulu' && timezone.value !== 'Etc/GMT0'
    && timezone.value !== 'Etc/GMT-14' && timezone.value !== 'EST5EDT' && timezone.value !== 'NZ' && timezone.value !== 'NZ-CHAT' && timezone.value !== 'GB' && timezone.value !== 'GB-Eire' && timezone.value !== 'W-SU' && timezone.value !== 'Eire' && timezone.value !== 'Etc/Universal' && timezone.value !== 'Etc/Greenwich' && timezone.value !== 'Eire' && timezone.value !== 'Iran' && timezone.value !== 'ROC' &&  timezone.value !== 'PRC' && timezone.value !== 'ROK' && timezone.value !== 'PRC'
  }
  const timeZoneFilter = getAllTimezones().filter(FilterTimeZone).sort()
  //console.log(timeZoneFilter)

  /**
   * Resgata path de redirecionamento de localStorage, mostra notificação e redireciona.
   *
   * @param path O path de redirecionamento.
   * @param timer O tempo esperado antes do redirecionamento ocorrer.
   */
  const loginAndTimedRedirect = async (path: string, timer: number) => {
    let homePath = "/mentoring/home";
    let localStorageRedirectPath = LocalStorageService.getStorage("redirectTo");
    let redirectPath = localStorageRedirectPath
      ? localStorageRedirectPath
      : homePath;

      // Talvez possa utilizar essa função posteriormente.
    //  let message = mapMessageAboutPathRedirect(redirectPath);

    await loginService
      .login(formObject.email, formObject.senha)
      .then((response) => {
        login(response, formObject.email).then(() => {
          showAlert(200, t(FORM_COLABORADOR_REDIRECIONAMENTO_HOME, 'Redirecionando-o para Home...'));
          setTimeout(() => history.push(redirectPath), timer);
          LocalStorageService.removeStorage("redirectTo");
        });
      });
  };

  /**
   * Gerencia toda a operação para salvar o novo colaborador no sistema.
   */
  const handleSaveNew = () => {
    setIsLoading(true);
    colaboradorService
      .post(formObject)
      .then(() => {
        setSubmitted(true);
        setEnableSubmit(false);
        setIsLoading(false);
        showAlert(200, t(FORM_COLABORADOR_CADASTRO_BEM_SUCEDIDO, "Cadastro bem-sucedido!"));
        //login após cadastro
        loginAndTimedRedirect("mentoring/home", 2000);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  /**
   * Função responsável por enviar as novas informações do colaborador para o backend.
   */
  const handleUpdate = () => {
    setIsLoading(true);

    if (colaborador && colaborador.id) {
      colaboradorService
        .put(formObject, colaborador.id)
        .then(() => {
          setSubmitted(true);
          setEnableSubmit(false);
          setIsLoading(false);
          showAlert(200, t(FORM_COLABORADOR_ATUALIZACAO_BEM_SUCEDIDA, "Atualização bem-sucedida!"));
          setTimeout(() => (window.location.href = "#/mentoring/home"), 2000);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Função responsável pelo recebimento da foto do colaborador.
   *
   * @param file A foto do colaborador.
   */
  const fileCallback = (file: string) => {
    setHasPhoto(file ? true : false);
    let oldFormObject = formObject;
    oldFormObject.foto = file;
    setFormObject(oldFormObject);

  };

  const emailTooltipMessage = () => {
    return (
      <Typography variant="caption">
        {t(
          FORM_COLABORADOR_CAMPO_EMAIL_TOOLTIP,
          "Somente os seguintes domínios são permitidos: "
        )}
        <br />
        {emailDomains.map(domain => (
          <React.Fragment key={domain}>
            {domain} <br />
          </React.Fragment>
        ))}
      </Typography>
    );
  };

  /**
   * Gerencia e controla a inserção e verificação da senha pelo usuário.
   *
   * @returns O componente que realiza a inserção e verificação da senha pelo usuário.
   */
  const renderPassword = () => {
    return (
      <React.Fragment>
        <FormikTextField
        autoComplete="off"
          className={classes.passwordField}
          inputProps={{ type: "password" }}
          value={formObject.senha}
          name="senha"
          onChange={(e) => handleChange(e, e.target.name)}
          label={t(FORM_COLABORADOR_CAMPO_SENHA_PLACEHOLDER, "Senha")}
          InputLabelProps={{
            style: {
              color: '#1F1F1F',
              fontFamily: 'InfraRegular',
              fontSize: '.8rem',
              fontStyle: 'normal',
              fontWeight: 400,
            }
          }}
          margin="normal"
          variant="outlined"
          fullWidth
        />
        <FormikTextField
        autoComplete="off"
          className={classes.passwordField}
          inputProps={{ type: "password" }}
          value={formObject.confSenha}
          name="confSenha"
          onChange={(e) => handleChange(e, e.target.name)}
          label={t(
            FORM_COLABORADOR_CAMPO_CONFIRMAR_SENHA_PLACEHOLDER,
            "Confirmar Senha"
          )}
          InputLabelProps={{
            style: {
              color: '#1F1F1F',
              fontFamily: 'InfraRegular',
              fontSize: '.8rem',
              fontStyle: 'normal',
              fontWeight: 400,
            }
          }}
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </React.Fragment>
    );
  };

  /**
   * Verifica se a o campo da UF está preenchido caso o país
   * escolhido seja o Brasil.
   *
   * @returns False caso o país escolhido seja o Brasil e o campo
   *          da UF não esteja preenchido, True caso contrário.
   */
  const checkCountryAndUfField = () => {
    if (formObject['pais'] === 'BRA' && !formObject['uf']) {
      return false;
    }

    return true;
  }

  return (
    <>
      {!colaborador && <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.appBarTitle}></Typography>
        </Toolbar>
      </AppBar>}

      <Paper className={classes.paper}>

        <Formik

          validateOnMount={colaborador ? false: true}
          validateOnChange={colaborador ? false : true}
          validateOnBlur={colaborador ? false : true}
          initialValues={formObject}
          validationSchema={
            colaborador
              ? AtualizarColaboradorSchema(t)
              : CadastroColaboradorSchema(t, passwordMatch)
          }
          onSubmit={() => { }}
        >
          {({ isValid }) => {
            setEnableSubmit(isValid && hasPhoto && !submitted && checkCountryAndUfField());
            return (
              <Form autoComplete="off" className={colaborador ? classes.formContainer : classes.formContainerMobile}>
                <Typography className={classes.topMessage}>
                  {t(FORM_COLABORADOR_MENSAGEM_INFORMATIVA, 'Os campos abaixo são obrigatórios, inclusive a foto.')}
                </Typography>
                <FileUpload
                  {...{ callback: fileCallback, profilePhoto: initialState.foto, defaultValue:formObject.foto }}
                />
                <FormikTextField
                autoComplete="off"
                  value={formObject.nome}
                  name="nome"
                  onChange={(e) => handleChange(e, e.target.name)}
                  label={t(
                    FORM_COLABORADOR_CAMPO_NOME_PLACEHOLDER,
                    "Nome Completo"
                  )}
                  inputProps={{
                    style: {
                      padding: '.75rem .6rem'
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#1F1F1F',
                      fontFamily: 'InfraRegular',
                      fontSize: '.8rem',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      padding: '.3rem 0 0 0',
                    }
                  }}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
                {/* EMAIL: */}
                <div className={classes.emailContainer}>
                  <div className={classes.emailField}>
                    <FormikTextField
                      value={formObject.email}
                      name="email"
                      autoComplete="off"
                      onChange={(e) => handleChange(e, e.target.name)}
                      label={t(
                        FORM_COLABORADOR_CAMPO_EMAIL_PLACEHOLDER,
                        "E-mail Corporativo"
                      )}
                      inputProps={{
                        style: {
                          padding: '.75rem .6rem'
                        }
                      }}
                      InputLabelProps={{
                        style: {
                          color: '#1F1F1F',
                          fontFamily: 'InfraRegular',
                          fontSize: '.8rem',
                          fontStyle: 'normal',
                          fontWeight: 400,
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      disabled={colaborador ? true : false}
                    />
                  </div>

                  <div
                    className={classes.emailTooltipContainer}
                  >
                    <ClickAwayListener
                      onClickAway={() => setTooltipIsOpen(false)}
                    >
                      <Tooltip
                        open={tooltipIsOpen}
                        classes={{ tooltip: classes.tooltipCustomStyle }}
                        title={emailTooltipMessage()}
                        placement="left-start"
                      >
                        <HelpIcon
                          color="disabled"
                          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                        />
                      </Tooltip>
                    </ClickAwayListener>
                  </div>
                </div>
                <div className={classes.countryStateContainer}>
                  <FormikSelectField
                    className={classes.countryField}
                    value={formObject.pais}
                    name="pais"
                    autoComplete="off"
                    label={t(FORM_COLABORADOR_CAMPO_PAIS_PLACEHOLDER, "País")}
                    options={
                      i18n.language === 'pt' ? countriesListPortuguese().map((country) => {
                        return {
                          value: country.code,
                          label: country.name,
                        }
                      }) : (i18n.language === 'es' ? countriesListSpanish().map((country) => {
                        return {
                          value: country.code,
                          label: country.name,
                        }
                      }) : countriesListEnglish().map((country) => {
                        return {
                          value: country.code,
                          label: country.name,
                        }
                      }))
                    }
                    InputLabelProps={{
                      style: {
                        color: '#1F1F1F',
                        fontFamily: 'InfraRegular',
                        fontSize: '.8rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                      }
                    }}
                    onChange={(e) => handleChange(e, e.target.name)}
                    variant="outlined"
                    fullWidth
                  />
                  {formObject.pais === 'BRA' ?
                    <FormikSelectField
                      className={classes.stateField}
                      value={formObject.uf}
                      name="uf"
                      label={t(FORM_COLABORADOR_CAMPO_UF_PLACEHOLDER, "UF")}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      options={estadosBrasilToSelectField().map((uf) => {
                        return { label: uf, value: uf };
                      })}
                      InputLabelProps={{
                        style: {
                          color: '#1F1F1F',
                          fontFamily: 'InfraRegular',
                          fontSize: '.8rem',
                          fontStyle: 'normal',
                          fontWeight: 400,
                        }
                      }}
                      onChange={(e) => handleChange(e, e.target.name)}
                      fullWidth
                    />
                    : null
                  }
                </div>
                <div className={classes.roleTimezoneContainer}>
                  <FormikTextField
                    className={classes.roleField}
                    value={formObject.cargo}
                    name="cargo"
                    autoComplete="off"
                    onChange={(e) => handleChange(e, e.target.name)}
                    label={t(FORM_COLABORADOR_CAMPO_CARGO_PLACEHOLDER, "Cargo")}
                    InputLabelProps={{
                      style: {
                        color: '#1F1F1F',
                        fontFamily: 'InfraRegular',
                        fontSize: '.8rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <FormikSelectField
                    className={classes.timezoneField}
                    value={formObject.timeZone}
                    name="timeZone"
                    autoComplete="off"
                    label={t(FORM_COLABORADOR_CAMPO_TIMEZONE_PLACEHOLDER, "Fuso horário")}
                    options={timeZoneFilter.map((timezone) => {
                      return {
                        label: timezone.label,
                        value: timezone.value
                      }
                    })}
                    inputProps={{
                      value: timezoneValue || formObject.timeZone,
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#1F1F1F',
                        fontFamily: 'InfraRegular',
                        fontSize: '.8rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                      }
                    }}
                    onChange={(e) => handleChange(e, e.target.name)}
                    variant="outlined"
                  />
                </div>
                {colaborador ? null : renderPassword()}
              </Form>
            );
          }}
        </Formik>
        {isLoading ? (
          <SpinnerSplendis />
        ) : (
          colaborador ?
            <Tooltip arrow title={t('Editar Mentorado') || ''}>
              <Button
                className={classes.actionButton}
                disabled={!enableSubmit}
                variant="contained"
                onClick={handleUpdate}
                size="large"
              >
                {t(FORM_COLABORADOR_BOTAO_ATUALIZAR, "Salvar Alterações")}
              </Button>
            </Tooltip> :
            <Button
              className={classes.actionButton}
              disabled={!enableSubmit}
              variant="contained"
              onClick={handleSaveNew}
              size="large"
            >
              {t(FORM_COLABORADOR_BOTAO_APLICAR, "Aplicar")}
            </Button>
        )}
        {colaborador && <Divider variant='middle' className={classes.divider} />}
        {colaborador &&
          <Link to={{ pathname: '/mentoring/trocar-senha' }} className={classes.changePassword}>
            {t(FORM_COLABORADOR_BOTAO_MUDAR_SENHA, "Alterar a senha")}
          </Link>}
      </Paper>
      <Button
        onClick={() => history.push("/login")}
        className={colaborador ? classes.backButton : classes.backButtonSignup}
        startIcon={<ArrowBackIosSharpIcon fontSize="large" />}
        size="large"
      >
        {t(FORM_COLABORADOR_BOTAO_VOLTAR, "Voltar")}
      </Button>
    </>
  );
}

export default FormColaborador;
