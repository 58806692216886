import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme, createStyles, Button, Typography } from '@material-ui/core';

import NewMentoriaCard from '../../../../../../../../components/new-mentoria-card/NewMentoriaCard';
import {
    AGENDAMENTO_MENTORIA_SOURCE,
    AGENDAMENTO_MENTORIA_STEP_REVISAO_INFO_LABEL,
    AGENDAMENTO_MENTORIA_BOTAO_CONFIRMAR,
    AGENDAMENTO_MENTORIA
} from '../../../../../../../../messages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subtitle: {
            color: '#1F1F1F',
            fontFamily: 'InfraRegular',
            fontSize: '.8rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '21px',
            paddingBottom: '3.1rem',
            //marginBottom: '2vh',

            '@media only screen and (max-width: 455px)': {
                fontSize: '0.8rem',
            }
        },
        container: {
            width: '58vw',
            marginTop: '1rem',
            top: '29vh',
            position: 'absolute',

            '@media only screen and (max-width: 414px)': {
                width: '80vw',
            }
        },
        card: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: 'rgba(207, 212, 218, 0.32)',

            '@media only screen and (max-width: 414px)': {
                display: 'block',
                height: '55vh',
                overflowY: 'scroll',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                display: 'block',
                height: '95vh',
                overflowY: 'scroll',
            },
        },
        panelHeader: {
            marginBottom: '15vh',
            marginTop: '4.6vh',
        },
        confirmationButton: {
            backgroundColor: '#003B4A',
            color: '#FFF',
            fontFamily: 'InfraRegular',
            fontSize: '.9rem',
            fontStyle: 'normal',
            fontWeight: 500,
            marginBottom: '.5rem',
            marginTop: '.5rem',
            width: '58vw',

            '&:hover': {
                backgroundColor: '#003B4A',
            },

            '&:disabled': {
                backgroundColor: '#ccc',
                color: '#FFF',
            },

            '@media only screen and (max-width: 414px)': {
                width: '80vw',
            }
        },
    }),
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: '40vh',
    },
}

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: '50vh',
        marginTop: '2vh'
    }
}

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: '90vh',
        marginTop: '2vh'
    }
}

/**
 * Componente que renderiza a parte de revisão das
 * informações do agendamento de mentoria.
 * 
 * @param props Propriedades e métodos necessários para o
 *              funcionamento adequado do componente.
 * @returns A tela com as informações para a revisão das
 *          informações do agendamento de mentoria.
 */
export default function RevisarStepMentorado(props) {
    const classes = useStyles();

    /**
     * Hook e função utilizados para a tradução de textos.
     */
    const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

    const [disableSubmit, setDisableSubmit] = useState(false);

    const { categoria, mentor, disponibilidade, handleConfirmation } = props;

    /**
     * Gerencia o botão de submit para o agendamento de mentoria.
     */
    const handleClick = (): void => {
        setDisableSubmit(!disableSubmit);
    }

    return (
        <div className={classes.container}>
        <Typography style={{ color: "#030624", fontSize: "26px", textAlign: "center" }}>{t(AGENDAMENTO_MENTORIA, "Agendar Mentoria")}</Typography>
            <p className={classes.subtitle}>
                {t(AGENDAMENTO_MENTORIA_STEP_REVISAO_INFO_LABEL, 'Estamos quase finalizando! Verifique abaixo se os dados de agendamento estão corretos!')}
            </p>
            <div className={classes.card}>
                <NewMentoriaCard
                    style={window.screen.width < 415 ?
                        modalStyleMobilePortrait.container :
                        (window.screen.width >= 415 && window.screen.width <= 720 ?
                            modalStyleMobileLandscape.container :
                            modalStyle.container)}
                    showCategoria={true}
                    showDisponibilidade={true}
                    categoria={categoria}
                    mentor={mentor}
                    disponibilidade={disponibilidade}
                    isModal={false} />
            </div>
            <div>
                <Button
                    disabled={disableSubmit}
                    className={classes.confirmationButton}
                    onClick={() => {
                        handleConfirmation();
                        handleClick();
                    }}
                >
                    {t(AGENDAMENTO_MENTORIA_BOTAO_CONFIRMAR, 'Confirmar')}
                </Button>
            </div>
        </div>
    );
}
