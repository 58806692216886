import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados a disponibilidade.
 */
export default class DisponibilidadeService extends HttpClientService {
    constructor() {
        super('disponibilidade')
    }

    /**
     * Obtém todas as disponibilidades.
     * 
     * @returns Promise com o resultado do processo de busca.
     */
    getDisponibilidades(): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/dias-semana`,
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém todas as disponibilidades do mentor.
     * 
     * @param mentorId O id do mentor.
     * @param timeZone O timezone relacionado ao mentor.
     * @returns Promise com o resultado do processo de busca.
     */
    getDisponibilidadesMentor(mentorId, timeZone): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/${mentorId}/gerar-disponibilidades`,
            method: AxiosMethodsName.get,
            params: {
                timeZone: `${timeZone}`
            },
            handleCredentials: true
        }
    
        
        return super.executeMethod(configRequest);
    }
}