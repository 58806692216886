import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
	Container,
	Typography,
	Link,
	TextField,
	Button,
	AppBar,
	Toolbar
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";

import { SessionStorageService, UserService } from "../../../service";
import { SpinnerSplendis } from "../../../commons";
import { alertContext, BotaoGlobalContato } from "../../../components";
import { useTranslation } from "react-i18next";
import {
	RECUPERAR_SENHA_SOURCE,
	RECUPERAR_SENHA_TITULO,
	RECUPERAR_SENHA_SUBTITULO,
	RECUPERAR_SENHA_CAMPO_EMAIL_LABEL,
	RECUPERAR_SENHA_BOTAO_CONFIRMAR,
	RECUPERAR_SENHA_BOTAO_VOLTAR,
	RECUPERAR_SENHA_MENSAGEM_SUCESSO,
} from "../../../messages";

import BelgoAramesWhite from '../../../images/logoBelgoAramesWhite.png';

const useStyles = makeStyles((theme) => ({
	paper: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(18),
		textAlign: "left",
	},
	appBar: {
		backgroundColor: '#003B4A',
		height: '112px',
		width: '100%',
	},
	appBarTitle: {
		backgroundImage: `url(${BelgoAramesWhite})`,
		backgroundRepeat: 'no-repeat',
		bottom: '71.48%',
		display: 'flex',
		flex: '1 0 0',
		height: '10vh',
		margin: '1.3rem auto 0 5rem',
		width: '430px',

		'@media only screen and (max-width: 414px)': {
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			margin: '1.8rem auto 0 auto',
		}
	},
	title: {
		fontFamily: 'InfraRegular',
		fontStyle: 'normal',
		fontWeight: 500,
	},
	subtitle: {
		textAlign: 'center',
		color: 'grey',
		fontFamily: 'InfraRegular',
		fontSize: '.9rem',
		fontStyle: 'normal',
		fontWeight: 500,
		marginTop: '.5rem',
	},
	form: {
		marginTop: theme.spacing(5),
		width: "100%", // Fix IE 11 issue.
	},
	emailField: {
		'& > div': {
			height: '2.7rem',
		}
	},
	submit: {
		fontFamily: 'InfraRegular',
		fontStyle: 'normal',
		fontWeight: 500,
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#003B4A',
		color: '#fff',

		'&:hover': {
				backgroundColor: '#0a3039',
		},
	},
	footer: {
		marginTop: '1rem',

		'@media only screen and (max-width: 414px)': {
			marginBottom: '1rem',
			marginTop: '5rem',
		}
	},
	copyright: {
		color: 'grey',
		fontFamily: 'InfraRegular',
		fontSize: '.8rem',

		'@media only screen and (max-width: 414px)': {
			fontSize: '.7rem',
		}
	},
}));

const useButtonStyles = makeStyles(() =>
	createStyles({
		backButton: {
			bottom: '50%',
			color: '#003B4A',
			display: 'flex',
			fontFamily: 'InfraRegular',
			fontSize: '.8rem',
			fontStyle: 'normal',
			fontWeight: 500,
			left: '5vw',
			margin: '2vh 2vw',
			position: 'absolute',

			'@media only screen and (max-width: 850px)': {
				display: 'flex',
				margin: '1rem auto',
				paddingBottom: '2rem',
				position: 'static',
			}
		}
	})
);

/**
 * Interface utilizada pelo componente RetrievePassword e que descreve
 * seu métodos e propriedades.
 */
interface RetrievePasswordProps {
	username?: string;
	gotError?: boolean;
}

/**
 * Componente para recuperação de senha do usuário.
 *
 * @returns O form com os campos a serem preenchidos
 * 			para recuperação de senha.
 */
const RetrievePassword: React.FC<RetrievePasswordProps> = () => {
	const classes = useStyles();
	const buttonStyles = useButtonStyles();

	/**
	 * Hook e função utilizados para tradução de textos.
	 */
	const { t } = useTranslation([RECUPERAR_SENHA_SOURCE]);

	const userService: UserService = new UserService();
	const history = useHistory();

	const [state, setState] = React.useState({ username: '', gotError: false });
	const [loading, setLoading] = React.useState(false);

	const { showAlert } = useContext(alertContext);

	/**
	 * Verifica se há erros no processo de recuperar a senha.
	 *
	 * @returns Mensagem de erro caso a senha atual esteja incorreta,
	 * 			null caso contrário.
	 */
	const handleRequestError = () => {
		return state.gotError ? (
			<Typography variant="caption" style={{ color: "red" }} gutterBottom>
				Senha atual incorreta
			</Typography>
		) : null;
	};

	/**
	 * Gerencia o processo de recuperação de senha, configurando o state.
	 *
	 * @param event O evento com os dados do email do usuário.
	 * @param field O campo de email.
	 * @param data O valor do campo de email.
	 */
	const handleChange = (
		event,
		field = event?.target.id,
		data = event?.target.value
	) => {
		setState({ ...state, [field]: data });
	}

	/**
	 * Verifica se o username do usuário existe.
	 *
	 * @returns True caso exista, False caso contrário.
	 */
	const isValid = () => {
		if (state.username) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Gerencia a submissão das informações para recuperação de senha.
	 *
	 * @param event O evento que dispara a submissão das informações
	 * 				para recuperação de senha.
	 */
	const handleSubmit = event => {
		event.preventDefault();

		setLoading(true);

		userService.requestPasswordEmail(state.username).then((res) => {
			showAlert(200, t(RECUPERAR_SENHA_MENSAGEM_SUCESSO, "Solicitação enviada. Verifique seu e-mail."));
			res.status >= 200 ? submitSuccess() : setLoading(false);

		}).catch((err) => {
			setLoading(false);
		});
	}

	/**
	 * Limpa a sessão atual do usuário e o redireciona para
	 * a tela de login após o envio das informações de
	 * recuperação de senha.
	 */
	const submitSuccess = () => {
		setLoading(false);

		SessionStorageService.clearStorage();

		setTimeout(() => history.push("/login"), 1500);
	}

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar>
					<Typography className={classes.appBarTitle}></Typography>
				</Toolbar>
			</AppBar>

			<Container component="main" maxWidth="xs">

				<div className={classes.paper}>

					<Typography component="h1" variant="h5" className={classes.title}>
						{t(RECUPERAR_SENHA_TITULO, "Recuperar senha")}
					</Typography>

					<Typography component="h2" className={classes.subtitle}>
						{t(
							RECUPERAR_SENHA_SUBTITULO,
							"Um email será enviado com um link de recuperação"
						)}
					</Typography>

					<form className={classes.form}
						noValidate
						onSubmit={handleSubmit}>

						<TextField
							className={classes.emailField}
							variant="outlined"
							margin="normal"
							fullWidth
							id="username"
							label={t(RECUPERAR_SENHA_CAMPO_EMAIL_LABEL, "Endereço de email")}
							name="username"
							type="text"
							autoFocus
							onChange={(event) => handleChange(event)}
							InputLabelProps={{
								style: {
									color: '#1F1F1F',
									fontFamily: 'InfraRegular',
									fontSize: '.8rem',
									fontStyle: 'normal',
									fontWeight: 400,
								}
							}}
						/>

						{loading ? (
							<SpinnerSplendis style={{ textAlign: "center", backgroundColor: "#003B4A" }} />
						) : (
							<Button
								type="submit"
								fullWidth
								variant="contained"
								disabled={!isValid()}
								className={classes.submit}
							>
								{t(RECUPERAR_SENHA_BOTAO_CONFIRMAR, "Confirmar")}
							</Button>
						)}
						{handleRequestError()}
					</form>
				</div>

				<Button
					onClick={() => history.push("/login")}
					className={buttonStyles.backButton}
					startIcon={<ArrowBackIosSharpIcon fontSize="large" />}
					size="large"
				>
					{t(RECUPERAR_SENHA_BOTAO_VOLTAR, "Voltar")}
				</Button>

				<footer className={classes.footer}>
					<Typography className={classes.copyright}>
						{"Copyright © "}
						<Link color="inherit" href="#"> {/* TODO Set Link in environment variables */}
							Mentoring
						</Link>{" "}
						{new Date().getFullYear()}. Powered by Stefanini
					</Typography>
				</footer>
				<BotaoGlobalContato />
			</Container>
		</>
	);
}

export default RetrievePassword;
