import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    AGENDAMENTO_MENTORIA_SOURCE,
    AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_1,
    AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_2,
    AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_3
} from "../../../../../../../messages";

const useStyles = makeStyles({
    statements: {
        fontFamily: 'InfraRegular',
        fontStyle: 'normal'
    }
});

/**
 * Exibe os termos de uso ao final do agendamento de mentoria.
 * 
 * @returns Os termos de uso ao final do agendamento de mentoria.
 */
const AgendamentoMentoriaTermosDeUsoText = () => {

    /**
     * Hook e função utilizada na tradução dos textos.
     */
    const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);
    
    const classes = useStyles();

    return (
        <span className={classes.statements}>
            <p style={{ textAlign: "center" }}><span>{t(AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_1, "Olá, colaborador")}</span><span><br /></span></p>
            <p style={{ textAlign: "center" }}><span>{t(AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_2, "Ao clicar em continuar, você receberá um e-mail sobre a mentoria e deverá adicionar o evento em sua agenda com até 24 horas de antecedência.")}</span></p>
            <p style={{ textAlign: "center" }}><span>{t(AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_CONTEUDO_3, "Se organize, confirme e não perca esse momento!")}</span></p>
        </span>
    );
}

export default AgendamentoMentoriaTermosDeUsoText;
