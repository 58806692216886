import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Snackbar,
  GridList,
  Button,
} from "@material-ui/core";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import Alert from "@material-ui/lab/Alert";

import { MentoriaService, UserContext } from "../../../../service";
import { SpinnerSplendis } from "../../../../commons";
import { getWindowDimensions } from "../../../../utils/FormUtils";
import {
  MENTORIAS_PENDENTES_AVALICAO_SOURCE,
  MENTORIAS_PENDENTES_AVALICAO_TITULO,
  MENTORIAS_PENDENTES_AVALICAO_SUBTITULO,
  MENTORIAS_PENDENTES_AVALICAO_SEM_MENTORIAS,
  MENTORIAS_PENDENTES_AVALICAO_BOTAO_VOLTAR,
  MENTORIAS_PENDENTES_AVALIACAO_INFO_MESSAGE,
} from "../../../../messages";
import { MentoriaPendente } from "../../../../models/MentoriaPendente";
import { MentoriaCard } from "../../mentorado/home/components";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "80vh",
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    content: {
      height: "90%",
      width: "100%",
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        width: "58vw",
      },
    },
    avaliacoesItems: {
      height: "100%",
      whiteSpace: "nowrap",
      transform: "translateZ(0)",
    },
    titleContainer: {
      height: "5%",
      width: "80%",
      textAlign: "left",
    },
    subtitleContainer: {
      display: "flex",
      alignContent: "flex-start",
      height: "25%",
      maxHeight: 100,
      flexDirection: "column",
      textAlign: "left",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "0.7rem",
        maxHeight: 200,
        height: "auto",
      },
    },
    title: {
      fontWeight: 600,

      "@media only screen and (max-width: 1024px)": {
        fontSize: "1rem",
      },
    },
    subtitle: {
      "@media only screen and (max-width: 1024px)": {
        fontSize: "0.8rem",
      },
    },
    alertInfoMessage: {
      opacity: 0.9,
    },
    mentoringHistory: {
      height: "50%",
    },
    gridList: {
      display: "flex",
      justifyContent: "flex-start",
      overflowY: "auto",
      height: "20rem",

      "& > div": {
        margin: ".5rem .5rem",
        padding: "1.5rem 1.5rem",
      },
    },
    backButton: {
      bottom: "12%",
      color: "#003B4A",
      display: "flex",
      fontFamily: "InfraRegular",
      fontSize: ".8rem",
      fontStyle: "normal",
      fontWeight: 500,
      left: "45%",
      margin: "2vh 2vh",
      position: "absolute",

      "@media only screen and (max-width: 414px)": {
        bottom: "-5vh",
        left: "30%",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        bottom: "-80vh",
        left: "40%",
      },
    },
  })
);

/**
 * Interface utilizada pelo componente MentoriasPendentesAvaliacao e que descreve
 * seu métodos e propriedades.
 */
interface MentoriasPendentesAvaliacaoProps {
  entityId: number;
  role?: string;
  getMentoriasPendentesAvaliacao: any;
}

/**
 * Componente que exibe as mentorias pendentes de avaliação.
 *
 * @param {MentoriasPendentesAvaliacaoProps}
 * @returns As mentorias pendentes de avaliação.
 */
const MentoriasPendentesAvaliacao: React.FC<
  MentoriasPendentesAvaliacaoProps
> = ({ entityId, getMentoriasPendentesAvaliacao }) => {
  const classes = useStyles();
  const history = useHistory();
  const { session, switchRole } = useContext(UserContext);
  const { t } = useTranslation([MENTORIAS_PENDENTES_AVALICAO_SOURCE]);

  const mentoriaService: MentoriaService = new MentoriaService();

  const [isMentoriasLoading, setIsMentoriasLoading] = useState(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState(getWindowDimensions());
  const [mentoriasPendentes, setMentoriasPendentes] = useState<
    MentoriaPendente[]
  >([]);

  /**
   * Obtém todas as mentorias pendentes de avaliação.
   */
  useEffect(() => {
    setIsMentoriasLoading(true);
    getMentoriasPendentesAvaliacao(entityId).then((result) => {
      setMentoriasPendentes(result.data);
      setIsMentoriasLoading(false);
    });
  }, [entityId, getMentoriasPendentesAvaliacao]);

  /**
   * Obtém as dimensões da tela e configura o state de dimensões.
   */
  useEffect(() => {
    const handleResize = () => setDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * Verifica se a largura da tela está no tamanho de telas para
   * dispositivos móveis.
   *
   * @returns True ou False caso a largura da tela esteja no tamanho
   *          de telas para dispositivos móveis ou não.
   */
  const isMobile = () => {
    return dimensions.width < 1024;
  };

  /**
   * Gerencia a exibição de mensagens de alerta ao usuário.
   *
   * @param message A mensagem a ser exibida ao usuário.
   * @returns Um Snackbar com a mensagem ao usuário.
   */
  const handleInfoMessage = (message) => {
    return (
      <Snackbar
        open={showMessage}
        onClose={() => setShowMessage(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          className={classes.alertInfoMessage}
          onClose={() => setShowMessage(false)}
          severity="info"
        >
          {message}
        </Alert>
      </Snackbar>
    );
  };

  /**
   * Atualiza a lista de mentorias pendentes de avaliação.
   */
  const updateMentoriasPendentes = () => {
    setIsMentoriasLoading(true);

    mentoriaService
      .getMentoriasPendentesAvaliacaoMentorado(entityId)
      .then((res) => {
        setMentoriasPendentes(res.data);
        setIsMentoriasLoading(false);
      });
  };

  /**
   * Renderiza a lista de mentorias pendentes de avaliação
   * em formato de cards para cada uma.
   *
   * @returns A lista de mentorias pendentes de avaliação.
   */
  const mentoriasPendentesCardList = () => {
    return isMentoriasLoading ? (
      <div style={{ alignSelf: "center" }}>
        <SpinnerSplendis />
      </div>
    ) : mentoriasPendentes.length === 0 ? (
      <h3 style={{ textAlign: "center" }}>
        {t(MENTORIAS_PENDENTES_AVALICAO_SEM_MENTORIAS, "Não há mentorias aqui")}
      </h3>
    ) : (
      <div className={classes.avaliacoesItems}>
        <GridList cols={1} className={classes.gridList}>
          {mentoriasPendentes.map((mentoria) => (
            <MentoriaCard
              key={mentoria.mentoriaId}
              mentoria={mentoria}
              onAvaliacaoSucessoCallback={updateMentoriasPendentes}
              style={{ marginBottom: "4rem" }}
            />
          ))}
        </GridList>
      </div>
    );
  };

  return (
    <Container className={classes.root}>
      <div className={classes.content}>
        <div className={classes.mentoringHistory}>
          <div className={classes.subtitleContainer}>
            <Typography className={classes.title} variant="h5" align="left">
              {t(
                MENTORIAS_PENDENTES_AVALICAO_TITULO,
                "Mentorias Pendentes de Avaliação"
              )}
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle2"
              gutterBottom
            >
              {t(
                MENTORIAS_PENDENTES_AVALICAO_SUBTITULO,
                "Clique em uma mentoria para avaliá-la!"
              )}
            </Typography>
          </div>
          <div>
            <div style={{ minHeight: 110 }}>{mentoriasPendentesCardList()}</div>
            <div>
              <Button
                onClick={() => {
                  session.roles.includes("ROLE_MENTOR")
                    ? switchRole("ROLE_MENTOR")
                    : switchRole("ROLE_MENTORADO");
                  history.push("/mentoring/home");
                }}
                className={classes.backButton}
                startIcon={<ArrowBackIosSharpIcon fontSize="large" />}
                size="large"
              >
                {t(MENTORIAS_PENDENTES_AVALICAO_BOTAO_VOLTAR, "Voltar")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isMobile() &&
        handleInfoMessage(
          t(
            MENTORIAS_PENDENTES_AVALIACAO_INFO_MESSAGE,
            "Você pode rolar a tabela horizontalmente e verticalmente para visualizar os dados"
          )
        )}
    </Container>
  );
};

export default MentoriasPendentesAvaliacao;
