import React from "react";
import { createStyles, makeStyles, Popover, Theme } from "@material-ui/core";
import FormContato from "../contato-form/FormContato";
import { useTranslation } from "react-i18next";
import { FORM_CONTATO_SOURCE } from "../../messages";
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

const useStyle = makeStyles((theme: Theme) => 
 createStyles({
    root: {
        background: '#003B4A',
        color: '#fff',
        borderRadius: '50%',
        position: 'fixed',
        zIndex: 1000,
        right: '20px',
        bottom: '50px',
        width: '2.75rem',
        height: '2.75rem',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
        border: 'none',
        outline: 'none',
        cursor: 'pointer'
    },
    icon: {
        width: '1.25rem',
        height: '1.25rem',
    },
    container: {
        width: '2rem'
    },
}));

/**
 * Componente responsável por apresentar o botão para contato/envio de email para equipe do Mentoring,
 * botão posicionado a direita no rodapé da página.
 * 
 */
const BotaoGlobalContato: React.FC = () => {
    const classes  = useStyle();
    
    // Hook utilizado para a tradução dos textos.
    const { t } = useTranslation([FORM_CONTATO_SOURCE]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /**
     * Função que lida com o clique no botão de chat com a aplicação.
     * 
     * @param event O evento de clique no botão de chat com a aplicação.
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Função que lida com o fechamento(encerramento) do chat com a aplicação.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <button id="chatButtonApp" aria-describedby={id} className={classes.root} onClick={handleClick}>
                <ChatBubbleIcon className={classes.icon} />
            </button>
            <Popover 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
            >
                <FormContato closePopover={handleClose} translation={t}/>
            </Popover>
        </>
    );
}

export default BotaoGlobalContato;