import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Button, Typography, StepConnector } from '@material-ui/core';
import BackSVG from '../../../../../assets/svgs/navigate_back.svg';
import NextSVG from '../../../../../assets/svgs/navigate_next.svg';

import {
    CategoriaStepMentorado,
    MentorStepMentorado,
    HorariosStepMentorado,
    RevisarStepMentorado,
} from './components';

import {
    DisponibilidadeService,
    MentoriaService,
    MentorService,
    UserContext
} from '../../../../../service';

import {
    Mentoria,
    DisponibilidadeMentor,
    Categoria,
} from '../../../../../models';

import { SpinnerSplendis } from '../../../../../commons';

import {
    formatDateToMaterialUIDate,
    searchByData,
    searchById
} from '../../../../../utils/FormUtils';

import { useTranslation } from 'react-i18next';
import {
    AGENDAMENTO_MENTORIA_SOURCE,
    AGENDAMENTO_MENTORIA_INDICADOR_STEP_MENTORES,
    AGENDAMENTO_MENTORIA_INDICADOR_STEP_CATEGORIA,
    AGENDAMENTO_MENTORIA_INDICADOR_STEP_HORARIOS,
    AGENDAMENTO_MENTORIA_INDICADOR_STEP_REVISAR,
    AGENDAMENTO_MENTORIA_BOTAO_APLICAR,
    AGENDAMENTO_MENTORIA_BOTAO_PROXIMO,
    AGENDAMENTO_MENTORIA_BOTAO_VOLTAR
} from '../../../../../messages';
import { MentoriaAttribute } from '../../../../../types';
import { AccessTime, FlagOutlined, FolderOpen, PeopleAlt } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AgendamentoMentoriaConfirmacaoPosTermosDeUsoModal, AgendamentoMentoriaTermosDeUsoModal } from '../por-categoria/components';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '90%',
            margin: 'auto',
            backgroundColor: 'transparent',

            '@media only screen and (max-width: 455px)': {
                width: '100%'
            },
        },
        headSteps: {
            width: '100%',
            backgroundColor: 'transparent',
            opacity: '80%',
            padding: '0',
            paddingTop: '24px',
            marginTop: '3vh',

            '@media only screen and (max-width: 455px)': {
                paddingTop: 0,
                padding: 0,
                marginTop: '2vh'
            },
            '@media only screen and (min-width: 456px) and (max-width: 1280px)': {
                paddingTop: 0,
                padding: 0,
                marginTop: '1vh'
            }
        },
        backButton: {
            /* position: 'sticky',
            bottom: '50%',
            left: '5vh',
            height: '6.5rem',
            width: '6.5rem',
            color: '#D3CAB7',
            borderRadius: '50%',
            border: 'solid #D3CAB7 6px',
            marginBottom: '2vh',
            display: 'flex',
            overflowZ: 'none', */

            '@media only screen and (max-width: 720px)': {
                display: 'none'
            },
        },
        backButtonMobile: {
            display: 'none',

            '@media only screen and (max-width: 414px)': {
                bottom: '-60vh',
                color: '#003B4A',
                display: 'block',
                left: '18vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                bottom: '-160vh',
                color: '#003B4A',
                display: 'block',
                left: '30vw',
            }
        },
        backButtonDisabled: {
            /* position: 'sticky',
            bottom: '50%',
            left: '5vh',
            height: '6.5rem',
            width: '6.5rem',
            color: '#D3CAB7',
            borderRadius: '50%',
            border: 'solid 6px',
            marginBottom: '2vh',
            display: 'flex',
            overflowZ: 'none', */

            '@media only screen and (max-width: 720px)': {
                display: 'none'
            },
        },
        backButtonMobileDisabled: {
            display: 'none',

            '@media only screen and (max-width: 414px)': {
                bottom: '-60vh',
                display: 'block',
                left: '18vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                bottom: '-160vh',
                display: 'block',
                left: '30vw',
            }
        },
        forwardButtonActive: {
           /*  position: 'sticky',
            bottom: '50%',
            right: '5vh',
            height: '6.5rem',
            width: '6.5rem',
            color: '#D3CAB7',
            borderRadius: '50%',
            border: 'solid #D3CAB7 6px',
            margin: '2vh',
            overflowZ: 'none', */

            '@media only screen and (max-width: 720px)': {
                display: 'none'
            },
        },
        forwardButtonMobileActive: {
            display: 'none',

            '@media only screen and (max-width: 414px)': {
                bottom: '-60vh',
                color: '#003B4A',
                display: 'block',
                right: '40vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                bottom: '-160vh',
                color: '#003B4A',
                display: 'block',
                right: '35vw',
            }
        },
        forwardButtonDisabled: {
            /* position: 'sticky',
            bottom: '50%',
            right: '5vh',
            height: '6.5rem',
            width: '6.5rem',
            borderRadius: '50%',
            border: 'solid 6px',
            margin: '2vh',
            overflowZ: 'none', */

            '@media only screen and (max-width: 720px)': {
                display: 'none'
            }
        },
        forwardButtonMobileDisabled: {
            display: 'none',

            '@media only screen and (max-width: 414px)': {
                bottom: '-60vh',
                display: 'block',
                right: '40vw'
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                bottom: '-160vh',
                display: 'block',
                right: '35vw',
            }
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            height: '50vh',
            maxHeight: '60vh',
            marginTop: '8rem',

            '@media only screen and (max-width: 414px)': {
                height: '55vh',
                marginTop: '12vh',
                maxHeight: '55vh'
            },
            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                height: 'auto'
            }
        },
        form: {
            width: '100%',
            marginRight: theme.spacing(20),
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(20),
        },
        stepLabel: {
            color: '#1F1F1F',
            fontFamily: 'InfraRegular',
            fontSize: '.6rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '17.23px',
            textTransform: 'uppercase',

            '@media only screen and (max-width: 414px)': {
                marginTop: '-1vh',
                fontSize: '.5rem',
            },

            '@media only screen and (min-width: 415px) and (max-width: 1024px)': {
                fontSize: '.7rem',
            },
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        backButtonContent: {
            position: 'absolute',
            bottom: '23vh',
            left: '1.5%',
            width: '10vw'
        },
        forwardButtonContent: {
            position: 'absolute',
            bottom: '23vh',
            right: '1.5%',
            width: '10vw'
        },
        spanComponent: {
            display: 'flex',
            height: '130px'
        }

    }),
);

/**
 * Estiliza os conectores do passo a passo do processo de agendar mentoria.
 */
const ConnectorStyle = withStyles({
    alternativeLabel: {
        top: 22,

        '@media only screen and (max-width: 414px)': {
            top: 15
        }
    },
    active: {
        '& $line': {
            backgroundColor: '#FF6C00',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#FF6C00',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

/**
 * Estiliza os ícones do passo a passo do processo de agendar mentoria.
 */
const useStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#FFF',
        zIndex: 1,
        color: '#CFD4DA',
        width: 40,
        height: 40,
        display: 'flex',
        border: '2px solid #CFD4DA',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',

        '@media only screen and (max-width: 414px)': {
            padding: 13,
            width: 10,
            height: 10,
        }
    },
    active: {
        color: '#FF6C00',
        border: '2px solid #FF6C00',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        color: '#FF6C00',
        border: '2px solid #FF6C00',
    }
});

/**
 * Componente que gerencia o passo a passo do processo
 * de agendar mentoria.
 *
 * @param props Propriedades necessárias para a realização do
 *              processo de agendar mentoria.
 * @returns O gerenciamento do processo de agendar mentoria(botões de avançar
 *          e voltar, modais etc.)
 */
export default function BuscarMentorStepper(props) {
    const classes = useStyles();

    // Hook e função utilizados para a tradução dos textos.
    const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

    const { idMentorado, handleNextStep } = props;

    const mentoriaService: MentoriaService = new MentoriaService();
    const mentorService: MentorService = new MentorService();
    const disponibilidadeService: DisponibilidadeService = new DisponibilidadeService();

    const [categorias, setCategorias] = useState<Array<Categoria>>([])
    const [disponibilidades, setDisponibilidades] = useState<Array<DisponibilidadeMentor>>([]);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [ButtonDisabledStatus, setButtonDisabledStatus] = React.useState<boolean>(true);
    const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = React.useState<boolean>(false);
    const [modalTermosDeUsoIsOpen, setModalTermosDeUsoIsOpen] = React.useState<boolean>(false);

    const [formObject, setFormObject] = React.useState<Mentoria>(new Mentoria());

    const mentoradoTimeZone = useContext(UserContext).userObject.colaborador.timeZone;

    /**
     * Realiza a busca das categorias por mentor ou das disponibilidades por mentor,
     * dependendo do passo que está ativo no processo de agendamento de mentoria.
     *
     */
    useEffect(() => {
        switch (activeStep) {
            case 1:

                if (categorias.length === 0) {
                    setLoading(true);
                    mentorService.getCategoriasByMentor(formObject.mentor?.id)
                        .then((res) => {
                            setLoading(false);
                            setCategorias(res.data);
                        })
                        .catch(() => {
                            setLoading(false);
                        })
                }

                break;

            case 2:

                if (disponibilidades.length === 0) {
                    setLoading(true);
                    disponibilidadeService.getDisponibilidadesMentor(formObject.mentor?.id, mentoradoTimeZone)
                        .then((res) => {
                            setLoading(false);
                            setDisponibilidades(res.data);
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                }

                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep])

    /**
     * Obtém os passos do processo de agendamento de mentoria.
     *
     * @returns Array com os passos do processo de agendamento de mentoria.
     */
    const getSteps = () => {
        return [
            t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_MENTORES, 'Mentores'),
            t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_CATEGORIA, 'Categorias'),
            t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_HORARIOS, 'Horários'),
            t(AGENDAMENTO_MENTORIA_INDICADOR_STEP_REVISAR, 'Revisar')];
    }

    /**
     * Cria a variável steps com o array de passos.
     */
    const steps = getSteps();

    /**
     * Realiza uma busca por um objeto de acordo com o atributo e um id, e
     * retorna toda a informação sobre a estrutura.
     *
     * @param atributo Categoria, mentor ou horário.
     * @param id O id recebido da escolha da bifurcação.
     */
    const search = (atributo: string, id: any) => {
        const { DISPONIBILIDADE, MENTOR, CATEGORIA } = MentoriaAttribute;
        switch (atributo) {
            case DISPONIBILIDADE:
                return searchByData(disponibilidades, id);

            case MENTOR:
                return null;

            case CATEGORIA:
                return searchById(categorias, id);

            default:
                return 'Not Found';
        }
    }

   /**
    * Gerencia atualizações no form e no processo de agendamento de mentoria.
    *
    * @param atributo O atributo que está sendo alterado no form.
    * @param event O evento que dipara a mudança no atributo do form.
    * @param id O id do atributo.
    * @param value O valor do atributo.
    */
    const handleChange = (atributo, event, id = event.target.value, value = null) => {
        let newValue;

        if (atributo === 'mentor') {
            formObject['categoria'] = new Categoria();
            formObject['disponibilidade'] = new DisponibilidadeMentor();
            setDisponibilidades([]);
            setCategorias([]);
        }

        if (atributo === 'categoria') {
            newValue = search(atributo, id);
        } else if (atributo === 'mentor') {
            newValue = value;
        } else if (atributo === 'disponibilidade') {
            newValue = search(atributo, id);
        } else if (atributo !== 'nome' && atributo !== 'email') {
            newValue = search(atributo, event.target.value);
        } else {
            newValue = event.target.value;
        }

        if (atributo === 'nome' || atributo === 'email') {
            formObject.mentorado[atributo] = newValue;
        } else {
            formObject[atributo] = newValue;
        }
        setFormObject(formObject);

    }

    /**
     * Obtém cada um dos passos do processo de agendamento de mentoria.
     *
     * @param stepIndex O índice no passo do processo de agendamento de mentoria.
     * @returns Cada passo do processo de agendamento de mentoria de acordo com o índice.
     */
    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return (
                    <MentorStepMentorado
                        defaultValue={formObject.mentor?.id}
                        handleChange={handleChange}
                        isDisabled={setButtonDisabledStatus}
                        searchQueryExist
                    />
                );

            case 1:
                return (
                    <CategoriaStepMentorado
                        defaultValue={formObject.categoria}
                        categorias={categorias ? categorias : []}
                        handleChange={handleChange}
                        isDisabled={setButtonDisabledStatus}
                        isLoading={loading}
                        mentor={formObject.mentor}
                    />
                );

            case 2:
                return (
                    <HorariosStepMentorado
                        defaultValue={formObject.disponibilidade?.data}
                        horarios={disponibilidades}
                        handleChange={handleChange}
                        isDisabled={setButtonDisabledStatus}
                        isLoading={loading}
                        mentor={formObject.mentor}
                    />
                );

            case 3:
                return (
                    <RevisarStepMentorado
                        categoria={formObject.categoria}
                        mentor={formObject.mentor}
                        disponibilidade={formObject.disponibilidade}
                        handleStepEdit={handleStepEdit}
                        handleConfirmation={handleModalOpen}
                    />
                );
        }
    }

    /**
     * Configura o state de passo ativo.
     *
     * @param step O passo do processo de agendamento de mentoria.
     */
    const handleStepEdit = (step: number) => {
        setActiveStep(step);
    }

    /**
     * Abre o modal dos termos de uso ao final do processo de agendamento de mentoria.
     */
    const handleModalOpen = () => {
        setModalTermosDeUsoIsOpen(true);
    }

    /**
     * Fecha o modal dos termos de uso ao final do processo de agendamento de mentoria.
     */
    const handleModalClose = () => {
        setModalTermosDeUsoIsOpen(false);
    };

    /**
     * Gerencia o processo que salva o form ao final do agendamento e
     * habilita a abertura do modal de confirmação.
     */
    const handleSubmit = () => {
        setLoading(true);
        formObject.mentorado.id = idMentorado;

        if (formObject.disponibilidade) {
            formObject.disponibilidade.data = formatDateToMaterialUIDate(formObject.disponibilidade?.data);
        }

        mentoriaService.salvar(formObject)
            .then(() => {
                setModalConfirmacaoIsOpen(true);
            })
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }

    /**
     * Configura o state de passo ativo e verifica se há um próximo passo
     * no processo de agendamento.
     */
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        handleNextStep(true);
    };

    /**
     * Configura o state de passo ativo e verifica se há um passo anterior
     * no processo de agendamento.
     */
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }

        if (activeStep === 1) {
            handleNextStep(false);
        }
    };

    /**
     * Reinicia todo o processo de agendamento de mentoria configurando o state
     * do passo ativo para zero.
     */
    const handleReset = () => {
        setActiveStep(0);
    };

    /**
     * Gerencia o estado do botão para decidir se renderiza um próximo passo
     * no processo de agendamento de mentoria ou se abre o modal ao final do
     * processo.
     */
    const handleButton = () => {
        activeStep === steps.length - 1 ? handleModalOpen() : handleNext();
    }

    /**
     * Renderiza os modais de termo de uso e de confirmação após os termos de uso
     * ao final do processo de agendamento de mentoria.
     *
     * @returns Os modais de termo de uso e de confirmação após os termos de uso.
     */
    const renderModais = () => {
        return (
            <React.Fragment>

                <AgendamentoMentoriaTermosDeUsoModal
                    open={modalTermosDeUsoIsOpen}
                    handleClose={handleModalClose}
                    onSubmit={() => {
                        handleModalClose();
                        handleSubmit();
                    }}
                />

                <AgendamentoMentoriaConfirmacaoPosTermosDeUsoModal
                    open={modalConfirmacaoIsOpen}
                    onButtonClick={() => {
                        window.location.href = "#/mentoring/home";
                    }}
                />

            </React.Fragment>
        );
    };

    /**
     * Renderiza os ícones relativos a cada passo no processo de agendamento de mentoria.
     *
     * @param props Propriedades necessárias para a efetiva utilização dos ícones.
     * @returns O ícone relativo a cada passo no processo de agendamento de mentoria.
     */
    const StepIcons = (props) => {
        const classes = useStepIconStyles();
        const { active, completed } = props;

        const icons = {
            1: <PeopleAlt />,
            2: <FolderOpen />,
            3: <AccessTime />,
            4: <FlagOutlined />
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    /**
     * Propriedades dos ícones do passo a passo do agendamento de mentoria.
     */
    StepIcons.propTypes = {
        /**
         * Whether this step is active.
         */
        active: PropTypes.bool,
        /**
         * Mark the step as completed. Is passed to child components.
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    return (
        <span className={classes.spanComponent}>
            {renderModais()}
            <div className={classes.root}>
                <Stepper
                    style={{ backgroundColor: 'transparent', padding: 0, opacity: '0.8' }}
                    className={classes.headSteps}
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<ConnectorStyle />}>

                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={StepIcons}>
                                <p className={classes.stepLabel}>{label}</p>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>Todos os Steps preenchidos</Typography>
                            <Button onClick={handleReset}>Limpar</Button>
                        </div>
                    ) : (
                        <div>
                            <div className={classes.content}>
                                {getStepContent(activeStep)}
                            </div>
                            <div className={classes.backButtonContent}>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    size='large'
                                >
                                    <div>
                                        <img src={BackSVG} alt='Voltar' />
                                        <Typography style={{ color: "#D3CAB7" }} >{t(AGENDAMENTO_MENTORIA_BOTAO_VOLTAR, 'Voltar')}</Typography>
                                    </div>
                                </Button>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={activeStep === 0 ? classes.backButtonMobileDisabled : classes.backButtonMobile}
                                    size='large'
                                >
                                    <Typography>{t(AGENDAMENTO_MENTORIA_BOTAO_VOLTAR, 'Voltar')}</Typography>
                                </Button>
                            </div>

                            {(loading && activeStep === steps.length - 1) ? <SpinnerSplendis />
                                :
                                activeStep < steps.length - 1 && <div className={classes.forwardButtonContent}>
                                    <Button
                                        disabled={ButtonDisabledStatus}
                                        onClick={handleButton}
                                        size='large'
                                    >
                                        <div>
                                            <img src={NextSVG} alt="Próximo" />
                                            <Typography style={{ color: "#FF6C00" }} >{t(AGENDAMENTO_MENTORIA_BOTAO_PROXIMO, 'Próximo')}</Typography>
                                        </div>
                                    </Button>
                                    <Button
                                        disabled={ButtonDisabledStatus}
                                        onClick={handleButton}
                                        className={ButtonDisabledStatus ? classes.forwardButtonMobileDisabled : classes.forwardButtonMobileActive}
                                        size='large'
                                    >
                                        <Typography>{t(AGENDAMENTO_MENTORIA_BOTAO_PROXIMO, 'Próximo')}</Typography>
                                    </Button>
                                    {activeStep === steps.length - 1 ? t(AGENDAMENTO_MENTORIA_BOTAO_APLICAR, 'Aplicar') : ''}
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        </span>
    );
}
