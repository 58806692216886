import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CustomModal } from '..'
import AvaliacaoSVG from '../../assets/svgs/avaliacao.svg';

import {
    EXTRAS_SOURCE,
    EXTRAS_MODAL_AVALIACAO
} from '../../messages';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        imageDiv: {
            marginBottom: '1rem'
        },
        image:{
            width: '10.8rem',
            height: '9.7rem'
        },
        title: {
            fontWeight: 500,
            fontFamily: 'infraRegular',
            fontStyle: 'normal',
            fontSize: '1.4rem',
            lineHeight: '1.25rem',
            marginBottom: '0.75rem'
        },
        subtitle:{
            fontFamily: 'infraLight',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '0.875rem',
            lineHeight: '140%',
            marginBottom: '1.5rem',
            wordBreak: 'break-word'
        },
        button: {
            fontFamily: 'Roboto',
            width: '100%',
            fontWeight: 500
        }
    })
);

/**
 * Interface utilizada pelo componente RhAvaliacaoModal e que descreve
 * seu métodos e propriedades.
 */
interface RhAvaliacaoModalProps {
    isOpen: boolean
    handleClose: Function
    style?: any 
    disableClose?: Function 
    subtitle?: string 
    modalProps?: any 
}

/**
 * Componente para apresentar a descrição da Avaliação dada pelo Mentorado
 * 
 * @param {RhAvaliacaoModalProps} 
 * @returns O modal de avaliação com todas suas informações pertinentes.
 */
const RhAvaliacaoModal: React.FC<RhAvaliacaoModalProps> = ({ isOpen, handleClose, children, style, disableClose, subtitle, ...modalProps }) => {
    // Hook de estilização
    const classes = useStyles();

    // Hook de tradução
    const { t } = useTranslation([EXTRAS_SOURCE]);

    return (
        <CustomModal
            isOpen={isOpen}
            handleClose={handleClose}
            style={{ height: 'auto', textAlign: 'center', width: '20rem', ...style }}
            disableClose={disableClose}
            {...modalProps}
        >
            <div className={classes.imageDiv}>
                <img src={AvaliacaoSVG} className={classes.image} alt="avaliacao"/>
            </div>
            <div className={classes.title}>
                {t(EXTRAS_MODAL_AVALIACAO, "Avaliação")}
            </div>
            <div className={classes.subtitle}>
                <p>{subtitle}</p>
            </div>
        </CustomModal>
    )
}

export default RhAvaliacaoModal;
