import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { CategoriaService } from "../../../../service";

/**
 * Props que o componente CategoriasComponent espera receber.
 */
export default class CategoriasProps implements WithTranslation, WithStyles {
    msg:any = ''
    categoriaService: CategoriaService = new CategoriaService()
    tReady: any
    // Funções de tradução
    t: any
    i18n: any
    // Função de estilização do MaterialUI
    classes: any
}