import React, { useEffect } from 'react';
import { Container, List, makeStyles, Typography } from '@material-ui/core';
import { SpinnerSplendis } from '../../../../../../../commons';
import { CategoriaCard } from '../../../../agendamento-mentoria/por-categoria/components';
import { useTranslation } from 'react-i18next';

import {
    SE_TORNAR_MENTOR_SOURCE,
    SE_TORNAR_MENTOR_STEP_CATEGORIAS_SEM_CATEGORIAS,
    SE_TORNAR_MENTOR_STEP_CATEGORIAS_LIVRES,
} from '../../../../../../../messages';

const useStyles = makeStyles({
    root: {
        height: '100%',
        maxWidth: '80%',
    },
    list: {
        alignSelf: 'center',
        backgroundColor: 'rgba(207, 212, 218, 0.32)',
        overflowY: 'scroll',
        padding: '1rem 1rem',
        margin: '4.5rem auto 0 auto',
        width: '80%',

        '@media only screen and (max-width: 414px)': {
            height: '65%',
            margin: '1rem auto 0 auto',
            width: '80%',
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            margin: '1rem auto 0 auto',
        }
    },
    container: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '50vh',
        width: '100%',

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            height: '65vh'
        }
    },
    subtitle: {
        fontFamily: 'InfraRegular',
        fontSize: '.8rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '21px',

        ['@media (max-width: 1024px)']: { // eslint-disable-line no-useless-computed-key
            padding: 0,
            fontSize: '0.8rem',
            margin: 5,
        }
    }
});

/**
 * Categorias no cadastro de mentor.
 *
 * @param props Propriedades e métodos necessários para a correta
 *              renderização das categorias.
 */
export default function CategoriasComponent(props) {
    const classes = useStyles();

    /**
     * Hook e função utilizados na tradução de textos.
     */
    const { t } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);

    const [categoriasStatus, setCategoriasStatus] = React.useState(props.categorias); // Categorias no form
    const { isLoading, isDisabled, setCategorias } = props;

    /**
     * Desabilita ou não a lista dependendo se alguma categoria foi selecionada ou não.
     */
    useEffect(() => { isDisabled(isNotValid); });

    /**
     * Verifica se alguma categoria foi selecionada.
     *
     * @returns True caso nenhuma categoria tenha sido selecionada, False caso contrário.
     */
    const isNotValid = () => categoriasStatus.filter(cat => { return cat.checked }).length === 0;

    /**
     * Obtém o índice de uma categoria no array categoriasStatus.
     *
     * @param id O id da categoria no array.
     * @returns O índice da categoria no array, ou -1 caso não encontrado.
     */
    const getIndexById = (id: any) => {
        for (let i = 0; i < categoriasStatus.length; i++) {
            if (id === categoriasStatus[i].id) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Gerencia a mudança dos states de categoria e quais
     * categorias foram selecionadas.
     *
     * @param e O evento que disapara a mudança nos states.
     * @param id O id da categoria.
     */
    const handleChange = (e: any, id: any) => {
        let stateAntigo = categoriasStatus;

        let index = getIndexById(id);

        stateAntigo[index].checked = !stateAntigo[index].checked;

        setCategoriasStatus(stateAntigo);

        isDisabled(isNotValid);
        setCategorias(e, props.atributoForm, stateAntigo);
    }

    /**
     * Renderiza as categorias disponíveis
     */
    const categorias = categoriasStatus.length === 0
        ? <h3>{t(SE_TORNAR_MENTOR_STEP_CATEGORIAS_SEM_CATEGORIAS, 'Não há categorias cadastradas')}</h3>
        : categoriasStatus.filter(category => category.status === 'ATIVO').map((cat, index) => {
            return (
                <div key={index}>
                    <CategoriaCard categoria={cat} onClick={handleChange} selected={cat.checked}/>
                </div>
            )
        });

    return (
        <Container className={classes.root}>

            <Typography component="legend" className={classes.subtitle}>
                {t(SE_TORNAR_MENTOR_STEP_CATEGORIAS_LIVRES, 'Escolha as categorias que melhor se adequam aos conteúdos que deseja compartilhar!')}
            </Typography>

            <div className={classes.container}>
                <List className={classes.list}>
                    {isLoading ? <SpinnerSplendis /> : categorias}
                </List>
            </div>
        </Container>);
}
