import React from "react";
import { createStyles, makeStyles, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { HeadCell, Order } from "../../utils/TableUtils";

const useStyles = makeStyles(
  createStyles({
    head: {
      '@media only screen and (max-width: 720px)': {
        fontSize: '.7rem',
      }
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    }
  })
);

/**
 * Interface utilizada pelo componente EnhancedTableHead e que descreve
 * seu métodos e propriedades.
 */
export interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[]
}

/**
 * Componente responsável pela utililzação das tabelas do MaterialUI com funcionalidade adicional de ordenação.
 * 
 * @param props Propriedades passdas pela tabela que utiliza esse componente.
 * @returns O head da tabela com funções de ordenação disponvíveis.
 */
export function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useStyles();
  const { order, orderBy, headCells, onRequestSort } = props;
  
  /**
   * Função que lida com a ordenação dos valores da tabela a partir das funções
   * disponvíveis no head da tabela.
   * 
   * @param property A propriedade utilizada na ordenação(i.e. ASC = ascendente ou DESC = descendente).
   * @returns A ordenação da tabela.
   */
  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            className={classes.head}
            key={index}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}