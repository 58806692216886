import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
    },

    paper: {
    //   width: "75%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 2),
      borderRadius: "4px",
      outline: "none",

      "&:focus": {
        outline: "none",
      },

      "@media only screen and (max-width: 414px)": {
        marginLeft: "1vw",
        marginRight: "1vw",
        marginTop: "5vh",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        marginLeft: "1vw",
        marginRight: "1vw",
        marginTop: "5vh",
      },
    },

    closeDiv: {
      height: "3%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },

    closeButton: {
      cursor: "pointer",
      alignSelf: "center",
      margin: "0 8px 0 0",
      color: "#003B4A",
    },

    closeButtonHeight: {
      height: "80vh",
    },

    disableCloseHeight: {
      height: "95%",
    },

    enableCloseHeight: {
      height: "100%",
    },
  })
);

/**
 * Componente genérico para criação e reutilização de modais.
 *
 * @param props
 */
export default function CustomModal(props) {
  const classes = useStyles();

  const { isOpen, handleClose, children, style, disableClose, ...modalProps } =
    props;

  /**
   * Botão que fecha o modal.
   *
   * @returns O ícone para fechar o modal com base na props disableClose.
   */
  const closeButton = () => {
    return disableClose ? null : (
      <div
        className={classes.closeDiv}
        style={props.closeleft && { justifyContent: "flex-start" }}
      >
        <CloseIcon className={classes.closeButton} onClick={handleClose} />
      </div>
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal"
      aria-describedby="transition-modal"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...modalProps}
    >
      <Fade in={isOpen}>
        <div
          style={style ? style : classes.closeButtonHeight}
          className={classes.paper}
        >
          {closeButton()}
          <div
            className={
              disableClose
                ? classes.disableCloseHeight
                : classes.enableCloseHeight
            }
          >
            {children}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
