import moment from 'moment'
moment.locale('pt-br');


export function getFormat(date){
  return moment(date).format('DD/MM/YYYY')
}

/**
 * @param {date|moment} start The start date
 * @param {date|moment} end The end date
 * @param {string} type The range type. eg: 'days', 'hours' etc
 */
export function getRange(type, ordinal ) { 
 // const mom = moment('22/09/2020', 'DD-MM-YYYY');
 // const {_i} = mom
  
  let range = []

  for (let i = 3; i < 61; i++) {
    
    
    if(moment().add(i,'days').weekday() === ordinal-1){
      range.push(moment().add(i, type).format('YYYY-MM-DD'))
    }
    
  }

 
  return range
}

export function getRange2(type, ordinal, dataInicial ) { 
  

  let range = []
   if(moment(dataInicial).weekday()===ordinal-1){
    range = [dataInicial]
   }
   
   for (let i = 2; i < 61; i++) {
     
     
     if(moment().add(i,'days').weekday() === ordinal-1){
       range.push(moment().add(i, type).format('YYYY-MM-DD'))
     }
     
   }
  
   const findSameDate = [...new Set(range)]
   
   /*function compararDatas(a, b) {
    const d1 = moment(a, "YYYY-MMM-DD");
    const d2 = moment(b, "YYYY-MMM-DD");
    console.log(d1)
    console.log(d2)
    
    if (d1.isAfter(d2)) {
        return 1;
    } else if (d1.isBefore(d2)) {
        return -1;
    }
    return 0;
}

findSameDate.sort(compararDatas)*/

  
   

const sortedArray  = findSameDate.sort((a,b) => new moment(a).format('YYYY-MM-DD') - new moment(b).format('YYYY-MM-DD'))

   
   
   return sortedArray
 }


 









