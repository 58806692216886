import { Colaborador } from "./Colaborador";
import { Categoria } from "./Categoria";
import { Disponibilidade } from "./Disponibilidade";
import { Idioma } from ".";

/**
 * Classe que define o modelo para o mentor com todas as suas propriedades e métodos.
 */
export class Mentor extends Colaborador {
  id?: number = 0;
  descricao?: string = "";
  idiomas: Array<Idioma> = [];
  categorias: Array<Categoria> = [];
  disponibilidades: Array<Disponibilidade> = [];
  statusMentor?: string;
  recorrencia?: string = "";
  getStringId?: any = () => {
    return this.id?.toString();
  };
}
