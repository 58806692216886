import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react"
import { useTranslation } from "react-i18next";
import {
    TERMO_DE_USO_SOURCE,
    TERMO_DE_USO_PAGRAFO1_OLA,
    TERMO_DE_USO_PAGRAFO1_REGISTRO,
    TERMO_DE_USO_PAGRAFO1_ORIENTACOES,
    TERMO_DE_USO_PAGRAFO1_LEIA,
    TERMO_DE_USO_PAGRAFO2_PROGRAMA,
    TERMO_DE_USO_PAGRAFO2_PROCESSO,
    TERMO_DE_USO_PAGRAFO3_MENTOR,
    TERMO_DE_USO_PAGRAFO3_TEXTO,
    TERMO_DE_USO_PAGRAFO4_TITULO,
    TERMO_DE_USO_PAGRAFO4_CLAUSULA1,
    TERMO_DE_USO_PAGRAFO4_CLAUSULA2,
    TERMO_DE_USO_PAGRAFO4_CLAUSULA3,
    TERMO_DE_USO_PAGRAFO4_CLAUSULA4,
    TERMO_DE_USO_PAGRAFO4_CLAUSULA5,
    TERMO_DE_USO_PAGRAFO5_TITULO,
    TERMO_DE_USO_PAGRAFO5_TEXTO
} from "../../../../../../messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'InfraRegular',
			fontStyle: 'normal',
        },
    })
);

/**
 * Os termos de uso apresentados ao final do processo
 * de se tornar mentor.
 * 
 * @returns Os termos de uso ao se tornar mentor.
 */
const TermoDeUsoMentor: React.FC = () => {

    /**
     * Hook e função utilizados na tradução de textos.
     */
    const { t } = useTranslation([TERMO_DE_USO_SOURCE]);

    const classes = useStyles();

    return (
        <span className={classes.root}>
            <p>{t(TERMO_DE_USO_PAGRAFO1_OLA, "Olá Colaborador, ")}</p>
            <p>{t(TERMO_DE_USO_PAGRAFO1_REGISTRO, "Esse cadastro é exclusivo para colaboradores do Grupo que tem o interesse em compartilhar experiências no Programa Mentoring Stefanini.")}</p>
            <p>{t(TERMO_DE_USO_PAGRAFO1_ORIENTACOES, "Antes de finalizar seu cadastro, leia as condições e orientações estabelecidas para o programa.")}</p>
            <p><strong>{t(TERMO_DE_USO_PAGRAFO1_LEIA, "Leia atentamente todas as orientações antes de finalizar seu cadastro.")}</strong></p>
            <p>&nbsp;</p>
            <p><strong>{t(TERMO_DE_USO_PAGRAFO2_PROGRAMA, "O Programa")}</strong></p>
            <p>{t(TERMO_DE_USO_PAGRAFO2_PROCESSO, "É um processo de aprendizado para mentor e mentorado, que envolve o compartilhamento de experiências e desenvolvimento profissional. que empodere, gere networking e inspire.")}</p>
            <p><strong><strong>&nbsp;</strong></strong></p>
            <p><strong>{t(TERMO_DE_USO_PAGRAFO3_MENTOR, "Novo mentor")}</strong></p>
            <p>{t(TERMO_DE_USO_PAGRAFO3_TEXTO, "Todos os cadastros passam por uma curadoria. A confirmação do cadastro e habilitação no Programa Mentoring Stefanini são realizadas através do e-mail corporativo.")}</p>
            <p>&nbsp;</p>
            <p><strong>{t(TERMO_DE_USO_PAGRAFO4_TITULO, "Boas práticas – Mentor")}</strong></p>
            <ol>
                <li>{t(TERMO_DE_USO_PAGRAFO4_CLAUSULA1, "O mentor não substitui o gestor imediato")}</li>
                <p></p>
                <li>{t(TERMO_DE_USO_PAGRAFO4_CLAUSULA2, "Os conselhos e sugestões devem estar em harmonia com o código de conduta e aspectos culturais da companhia, na dúvida procure a equipe de Gente e Cultura da Stefanini")}</li>
                <p></p>
                <li>{t(TERMO_DE_USO_PAGRAFO4_CLAUSULA3, "Evite fazer intervenções com pessoas externas ao programa de mentoria, ou seja, se houver algum conflito exposto no processo de mentoria, o mentor não deve intervir, mas orientar e empoderar o mentorado a agir na situação")}</li>
                <p></p>
                <li>{t(TERMO_DE_USO_PAGRAFO4_CLAUSULA4, "Cumpra a agenda combinada, utilize um tom positivo durante o encontro e divida histórias e experiências, para que o Mentorado encontre um caminho para agir.")}</li>
                <p></p>
                <li>{t(TERMO_DE_USO_PAGRAFO4_CLAUSULA5, "Caso precise cancelar ou reagendar a mentoria, entre em contato com o Mentorado com até 3h de antecedência.")}</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong><span>{t(TERMO_DE_USO_PAGRAFO5_TITULO, "Comunicação")}</span></strong></p>
            <p>{t(TERMO_DE_USO_PAGRAFO5_TEXTO, "Para encaminhar sugestões, feedbacks ou esclarecer dúvidas, entre em contato através do e-mail:")}&nbsp;<a href="mailto:mentoring@belgobekaert.com.br" rel="noopener noreferrer" target="_blank" data-auth="NotApplicable">mentoring@belgobekaert.com.br</a></p>
        </span>);
}

export default TermoDeUsoMentor;