import React, { useEffect } from "react";
import { Typography, makeStyles, createStyles, Grid } from "@material-ui/core";

import { EstatisticaService } from "../../../../service";
import { SpinnerSplendis } from "../../../../commons";
import {
  countriesListEnglish,
  countriesListPortuguese,
  countriesListSpanish,
  diaAtualFormatted,
  formatFromMaterialUIDate,
} from "../../../../utils/FormUtils";

import {
  ChartCard,
  PieChart,
  BarChart,
  DashboardFilter,
  LineChart,
} from "./components";
import { useTranslation } from "react-i18next";
import {
  RH_DASHBOARD_TITULO,
  RH_DASHBOARD_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_TITULO,
  RH_DASHBOARD_GRAFICOS_CATEGORIAS_MAIS_BUSCADAS_TITULO,
  RH_DASHBOARD_GRAFICOS_CATEGORIAS_MAIS_PROCURADAS_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_MENTORES_MAIS_BUSCADOS_TITULO,
  RH_DASHBOARD_GRAFICOS_MENTORES_MAIS_BUSCADOS_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_MENTORADOS_MAIS_ATIVOS_TITULO,
  RH_DASHBOARD_GRAFICOS_MENTORADOS_MAIS_ATIVOS_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_ESTADOS_MAIS_ENGAJADOS_TITULO,
  RH_DASHBOARD_GRAFICOS_ESTADOS_MAIS_ENGAJADOS_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_PAISES_MAIS_ENGAJADOS_TITULO,
  RH_DASHBOARD_GRAFICOS_PAISES_MAIS_ATIVOS_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_AVALIACOES_MENTORIAS_TITULO,
  RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_AVALICOES_MENTORIAS_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_MES_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_MES_TITULO,
  RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_SEMANA_SUBTITULO,
  RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_SEMANA_TITULO,
  RH_DASHBOARD_GRAFICOS_AVALICOES_MENTORIAS_NAO_ACONTECERAM,
  RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
  RH_DASHBOARD_GRAFICOS_LEGENDA_NOTAS_MENTORIAS,
  RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_LEGENDA,
} from "../../../../messages";
import { MenuRh } from "../../../../components";
import i18n from "../../../../i18n";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },

    header: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "3vh",
      '@media only screen and (max-width: 414px)': {
        marginBottom: '1vh',
      },
    },

    container: {
      backgroundColor: "rgba(207, 212, 218, 0.32)",
    },

    content: {
      marginTop: "5vh",
      width: "73%",

      "@media only screen and (max-width: 414px)": {
        width: "90%",
      },
    },

    titleContainer: {
      display: "flex",
      flexDirection: "column",
      height: 60,
      margin: "0 auto",
      textAlign: "center",
    },

    filterWrapper: {
      display: "flex",
      alignItems: "center",

      "@media only screen and (max-width: 414px)": {
        display: "grid",
      },
    },

    dashboardsWrapper: {
      height: "auto",
      maxWidth: "auto",
      margin: "0 auto",
      display: "flex",
      marginBottom: "2rem",
      padding: "0 2rem 2rem 2rem",
    },

    dashboardTitle: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      fontWeight: 600,

      "@media only screen and (max-width: 414px)": {
        fontSize: "1rem",
      },
    },

    dashboardSubtitle: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      fontWeight: 400,

      "@media only screen and (max-width: 414px)": {
        fontSize: ".7rem",
      },
    },
  })
);

/**
 * Componente da home do ADMIN, renderiza os gráficos e filtros sobre mentoria, mentores, mentorados e gráficos
 */
const PainelControleEstatistico = () => {
  // Função de estlização
  const classes = useStyles();

  // Função de tradução
  const { t } = useTranslation(["admin"]);

  const estatisticaService: EstatisticaService = new EstatisticaService();

  // Dashboards data states
  const [statusMentoriaData, setStatusMentoriaData] = React.useState<any>([]);
  const [topCategoriasData, setTopCategoriasData] = React.useState([]);
  const [topMentoresData, setTopMentoresData] = React.useState([]);
  const [topMentoradosData, setTopMentoradosData] = React.useState([]);
  const [topUFsData, setTopUFsData] = React.useState([]);
  const [avaliacoesMentoriasData, setAvaliacoesMentoriasData] = React.useState([]);
  const [totalMentoriasMensalData, setTotalMentoriasMensalData] = React.useState([]);
  const [totalMentoriasSemanalData, setTotalMentoriasSemanalData] = React.useState([]);
  const [topPaisesLatamData, setTopPaisesLatamData] = React.useState([]);
  const [isLatam, setIsLatam] = React.useState(false);

  //Loading dashboards states
  const [statusMentoriaLoading, setStatusMentoriaLoading] = React.useState(false);
  const [topCategoriasLoading, setTopCategoriasLoading] = React.useState(false);
  const [topMentoresLoading, setTopMentoresLoading] = React.useState(false);
  const [topMentoradosLoading, setTopMentoradosLoading] = React.useState(false);
  const [topUFsLoading, setTopUFsLoading] = React.useState(false);
  const [avaliacoesMentoriasLoading, setAvaliacoesMentoriasLoading] = React.useState(false);
  const [totalMentoriasMensalLoading, setTotalMentoriasMensalLoading] = React.useState(false);
  const [totalMentoriasSemanalLoading, setTotalMentoriasSemanalLoading] = React.useState(false);
  const [topPaisesLatamLoading, setTopPaisesLatamLoading] = React.useState(false);

  let initialFilter = {
    dataInicial: "01/01/2019",
    dataFinal: diaAtualFormatted(),
    regiao: "BRASIL",
  };

  /**
   * Obtém as informações de todos os gráficos assim que o componente é renderizado.
   */
  useEffect(() => {
    getAllDashboardsInfo(initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Realiza o fetch inicial dos dados estatísticos e
   * adiciona aos respectivos states.
   *
   * @param filterObject Objeto utilizado para filtrar resultados exibidos nos gráficos.
   */
  const getAllDashboardsInfo = (filterObject) => {
    setStatusMentoriaLoading(true);
    setTopCategoriasLoading(true);
    setTopMentoresLoading(true);
    setTopMentoradosLoading(true);
    setTopUFsLoading(true);
    setAvaliacoesMentoriasLoading(true);
    setTotalMentoriasMensalLoading(true);
    setTotalMentoriasSemanalLoading(true);
    setTopPaisesLatamLoading(true);

    // statusMentoria fetch
    estatisticaService.getStatusMentoriasStats(filterObject)
      .then((res) => { setStatusMentoriaData(res.data); })
      .then(() => { setStatusMentoriaLoading(false); });

    // topCategorias fetch
    estatisticaService.getTopCategoriasStats(filterObject)
      .then((res) => { setTopCategoriasData(res.data); })
      .then(() => { setTopCategoriasLoading(false); });

    // topMentores fetch
    estatisticaService.getTopMentoresStats(filterObject)
      .then((res) => { setTopMentoresData(res.data); })
      .then(() => { setTopMentoresLoading(false); });

    // topMentorados fetch
    estatisticaService.getTopMentoradosStats(filterObject)
      .then((res) => { setTopMentoradosData(res.data); })
      .then(() => { setTopMentoradosLoading(false); });

    // topUFs fetch
    estatisticaService.getTopUFsStats(filterObject)
      .then((res) => { setTopUFsData(res.data); })
      .then(() => { setTopUFsLoading(false); });

    // Avaliações fetch
    estatisticaService.getAvaliacoesStats(filterObject)
      .then((res) => { setAvaliacoesMentoriasData(res.data); })
      .then(() => { setAvaliacoesMentoriasLoading(false); });

    // Total mentorias por mês
    estatisticaService.getTotalMentoriaMensal(filterObject.regiao)
      .then((res) => { setTotalMentoriasMensalData(res.data); })
      .then(() => { setTotalMentoriasMensalLoading(false); });

    // Total mentorias por semana
    estatisticaService.getTotalMentoriaSemanal(filterObject.regiao)
      .then((res) => { setTotalMentoriasSemanalData(res.data); })
      .then(() => { setTotalMentoriasSemanalLoading(false); });

    // Top países latam
    estatisticaService.getTopPaisesLatam(filterObject)
      .then((res) => { setTopPaisesLatamData(res.data); })
      .then(() => { setTopPaisesLatamLoading(false); });
  };

  /**
   * Lida com a filtragem dos dados estatísticos chamando a função de fetch
   * passando os novos valores de filtro.
   *
   * @param filterObject Objeto utilizado para filtrar resultados exibidos nos gráficos.
   */
  const handleFilterSubmit = (filterObject) => {
    let newFilterObject;
    if (filterObject.uf !== "") {
      newFilterObject = {
        dataInicial: formatFromMaterialUIDate(filterObject.dataInicial),
        dataFinal: formatFromMaterialUIDate(filterObject.dataFinal),
        uf: filterObject.uf,
        regiao: filterObject.regiao,
      };
    } else {
      newFilterObject = {
        dataInicial: formatFromMaterialUIDate(filterObject.dataInicial),
        dataFinal: formatFromMaterialUIDate(filterObject.dataFinal),
        regiao: filterObject.regiao,
      };
    }

    setIsLatam(filterObject.regiao === "LATAM");

    getAllDashboardsInfo(newFilterObject);
  };

  /**
   * Obtém o total de mentorias realizadas.
   *
   * @returns A soma de todas as mentorias realizadas.
   */
  const getTotalMentoriasPieChart = () => {
    return statusMentoriaData.reduce((total, item) => total + item.value, 0);
  };

  /**
   * Obtém as 5 categorias melhores ranqueadas
   *
   * @returns Array com as 5 categorias melhores ranqueadas.
   */
  const getTopFiveCategories = () => {
    const topCategoriesSorted = topCategoriasData.sort(compare);

    let topFiveCategories = [];
    for (let i = topCategoriesSorted.length - 1; i > topCategoriesSorted.length - 6 && i > -1; i--) {
      topFiveCategories.push(topCategoriesSorted[i]);
    }

    return topFiveCategories.sort(compare);
  };

  /**
   * Obtém os 5 estados melhores ranqueados.
   *
   * @returns Array com os 5 estados melhores ranqueados.
   */
  const getTopFiveStates = () => {
    const topStatesSorted = topUFsData.sort(compare);

    let topFiveStates = [];
    for (let i = topStatesSorted.length - 1; i > topStatesSorted.length - 6 && i > -1; i--) {
      topFiveStates.push(topStatesSorted[i]);
    }

    return topFiveStates.sort(compare);
  };

  /**
   * Obtém os 5 países da Latam melhores ranqueados.
   *
   * @returns Array com os 5 países da Latam melhores ranqueados.
   */
  const getTopFiveCountriesLatam = () => {
    const topCountriesSorted = topPaisesLatamData.sort(compare);

    let topFiveCountries = [];
    for (let i = topCountriesSorted.length - 1; i > topCountriesSorted.length - 6 && i > -1; i--) {
      topFiveCountries.push(topCountriesSorted[i]);
    }

    return topFiveCountries
      .map((topCountry) => {
        return mapCodeToCountry(topCountry);
      })
      .sort(compare);
  };

  /**
   * Mapeia o código de três letras de um país para o seu nome.
   *
   * @param topCountry O país que terá o seu código mapeado.
   * @returns O país no formato {id: string, value: number} com id sendo o seu nome.
   */
  const mapCodeToCountry = (topCountry: { id: string; value: number }) => {
    let countryFormatted = { id: "", value: 0 };

    const countriesList = i18n.language === "pt"
        ? countriesListPortuguese() : i18n.language === "es"
        ? countriesListSpanish() : countriesListEnglish();

    countriesList.forEach((country) => {
      if (country.code === topCountry.id) {
        countryFormatted.id = country.name;
        countryFormatted.value = topCountry.value;
      }
    });

    return countryFormatted;
  };

  /**
   * Função utilizada para realizar ordenação.
   *
   * @param sourceA O primeiro elemento que está sendo comparado.
   * @param sourceB O segundo elemento que está sendo comparado.
   * @returns -1 se sourceA for menor que sourceB, 1 caso contrário, e 0 caso sejam iguais.
   */
  const compare = (sourceA: { id; value }, sourceB: { id; value }) => {
    if (sourceA.value < sourceB.value) { return -1; } 
    else if (sourceA.value > sourceB.value) { return 1; }
    return 0;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {/* Sub header do admin */}
        <header className={classes.header}>
          <MenuRh />
          <div className={classes.titleContainer}>
            <Typography
              variant="h5"
              align="left"
              className={classes.dashboardTitle}
            >
              {t(RH_DASHBOARD_TITULO, "Mentoring Dashboard")}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.dashboardSubtitle}
            >
              {t(RH_DASHBOARD_SUBTITULO, "Estatísticas disponíveis")}
            </Typography>
          </div>
        </header>
        <div className={classes.container}>
          {/* Filtros */}
          <div className={classes.filterWrapper}>
            <DashboardFilter handleSubmit={handleFilterSubmit} />
          </div>

          {/* Gráficos */}
          <div className={classes.dashboardsWrapper}>
            <Grid container spacing={3}>
              <Grid item container md={6} xs={12}>
                <ChartCard
                  title={t(
                    RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_TITULO,
                    "Status de Mentorias"
                  )}
                  subtitle={t(
                    RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_SUBTITULO,
                    "Status geral"
                  )}
                  postScriptum={
                    t(
                      RH_DASHBOARD_GRAFICOS_STATUS_MENTORIAS_LEGENDA,
                      "Total de Mentorias: "
                    ) + `${getTotalMentoriasPieChart()}`
                  }
                >
                  {statusMentoriaLoading ? (
                    <SpinnerSplendis />
                  ) : (
                    <PieChart scheme={"set1"} data={statusMentoriaData} />
                  )}
                </ChartCard>
              </Grid>
              <Grid item container md={6} xs={12}>
                <ChartCard
                  title={t(
                    RH_DASHBOARD_GRAFICOS_CATEGORIAS_MAIS_BUSCADAS_TITULO,
                    "Top Categorias"
                  )}
                  subtitle={t(
                    RH_DASHBOARD_GRAFICOS_CATEGORIAS_MAIS_PROCURADAS_SUBTITULO,
                    "Categorias Mais Procuradas"
                  )}
                >
                  {topCategoriasLoading ? (
                    <SpinnerSplendis />
                  ) : (
                    <BarChart
                      margins={{ top: 20, right: 10, bottom: 40, left: 210 }}
                      data={getTopFiveCategories()}
                      legends={{
                        axisBottom: t(
                          RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                          "Nº de Mentorias"
                        ),
                        axisLeft: "",
                      }}
                    />
                  )}
                </ChartCard>
              </Grid>
              <Grid item container md={6} xs={12}>
                <ChartCard
                  title={t(
                    RH_DASHBOARD_GRAFICOS_MENTORES_MAIS_BUSCADOS_TITULO,
                    "Top Mentores"
                  )}
                  subtitle={t(
                    RH_DASHBOARD_GRAFICOS_MENTORES_MAIS_BUSCADOS_SUBTITULO,
                    "Mentores Mais Procurados"
                  )}
                >
                  {topMentoresLoading ? (
                    <SpinnerSplendis />
                  ) : (
                    <BarChart
                      scheme={"dark2"}
                      margins={{ top: 20, right: 10, bottom: 40, left: 210 }}
                      data={topMentoresData}
                      legends={{
                        axisBottom: t(
                          RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                          "Nº de Mentorias"
                        ),
                        axisLeft: "",
                      }}
                      labelTextColor={"#FFFFFF"}
                    />
                  )}
                </ChartCard>
              </Grid>
              <Grid item container md={6} xs={12}>
                <ChartCard
                  title={t(
                    RH_DASHBOARD_GRAFICOS_MENTORADOS_MAIS_ATIVOS_TITULO,
                    "Top Mentorados"
                  )}
                  subtitle={t(
                    RH_DASHBOARD_GRAFICOS_MENTORADOS_MAIS_ATIVOS_SUBTITULO,
                    "Mentorados Mais Ativos"
                  )}
                >
                  {topMentoradosLoading ? (
                    <SpinnerSplendis />
                  ) : (
                    <BarChart
                      scheme={"paired"}
                      margins={{ top: 20, right: 10, bottom: 40, left: 210 }}
                      data={topMentoradosData}
                      legends={{
                        axisBottom: t(
                          RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                          "Nº de Mentorias"
                        ),
                        axisLeft: "",
                      }}
                    />
                  )}
                </ChartCard>
              </Grid>
              {isLatam ? (
                <Grid item container md={6} xs={12}>
                  <ChartCard
                    title={t(
                      RH_DASHBOARD_GRAFICOS_PAISES_MAIS_ENGAJADOS_TITULO,
                      "Países mais engajados"
                    )}
                    subtitle={t(
                      RH_DASHBOARD_GRAFICOS_PAISES_MAIS_ATIVOS_SUBTITULO,
                      "Países mais ativos"
                    )}
                  >
                    {topPaisesLatamLoading ? (
                      <SpinnerSplendis />
                    ) : (
                      <BarChart
                        scheme={"category10"}
                        margins={{ top: 20, right: 30, bottom: 50, left: 60 }}
                        data={getTopFiveCountriesLatam()}
                        legends={{
                          axisBottom: t(
                            RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                            "Nº de Mentorias"
                          ),
                          axisLeft: "",
                        }}
                        layout={"vertical"}
                        labelTextColor={"#FFFFFF"}
                      />
                    )}
                  </ChartCard>
                </Grid>
              ) : (
                <Grid item container md={6} xs={12}>
                  <ChartCard
                    title={t(
                      RH_DASHBOARD_GRAFICOS_ESTADOS_MAIS_ENGAJADOS_TITULO,
                      "UFs mais engajadas"
                    )}
                    subtitle={t(
                      RH_DASHBOARD_GRAFICOS_ESTADOS_MAIS_ENGAJADOS_SUBTITULO,
                      "UFs Mais Ativas"
                    )}
                  >
                    {topUFsLoading ? (
                      <SpinnerSplendis />
                    ) : (
                      <BarChart
                        enableLegends={false}
                        scheme={"category10"}
                        margins={{ top: 20, right: 30, bottom: 50, left: 60 }}
                        data={getTopFiveStates()}
                        legends={{
                          axisBottom: t(
                            RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                            "Nº de Mentorias"
                          ),
                          axisLeft: "",
                        }}
                        layout={"vertical"}
                        labelTextColor={"#FFFFFF"}
                      />
                    )}
                  </ChartCard>
                </Grid>
              )}
              <Grid item container md={6} xs={12}>
                <ChartCard
                  title={t(
                    RH_DASHBOARD_GRAFICOS_AVALIACOES_MENTORIAS_TITULO,
                    "Avaliação das Mentorias"
                  )}
                  subtitle={t(
                    RH_DASHBOARD_GRAFICOS_AVALICOES_MENTORIAS_SUBTITULO,
                    "Notas das Mentorias"
                  )}
                  postScriptum={t(
                    RH_DASHBOARD_GRAFICOS_AVALICOES_MENTORIAS_NAO_ACONTECERAM,
                    "*As mentorias que não aconteceram não constam aqui."
                  )}
                >
                  {avaliacoesMentoriasLoading ? (
                    <SpinnerSplendis />
                  ) : (
                    <BarChart
                      enableLegends={false}
                      scheme={"pastel2"}
                      margins={{ top: 20, right: 30, bottom: 50, left: 100 }}
                      data={avaliacoesMentoriasData}
                      legends={{
                        axisBottom: t(
                          RH_DASHBOARD_GRAFICOS_LEGENDA_NOTAS_MENTORIAS,
                          "Notas"
                        ),
                        axisLeft: t(
                          RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                          "Nº de Mentorias"
                        ),
                      }}
                      layout={"vertical"}
                    />
                  )}
                </ChartCard>
              </Grid>
              <Grid item container md={6} xs={12}>
                <ChartCard
                  title={t(
                    RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_MES_TITULO,
                    "Total de mentorias por mês"
                  )}
                  subtitle={t(
                    RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_MES_SUBTITULO,
                    "Total Mensal"
                  )}
                >
                  {totalMentoriasMensalLoading ? (
                    <SpinnerSplendis />
                  ) : (
                    <BarChart
                      enableLegends={false}
                      scheme={"pastel2"}
                      margins={{ top: 20, right: 30, bottom: 50, left: 50 }}
                      data={totalMentoriasMensalData}
                      legends={{
                        axisBottom: "",
                        axisLeft: t(
                          RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                          "Nº de Mentorias"
                        ),
                      }}
                      layout={"vertical"}
                      rotateLabelBottom={45}
                    />
                  )}
                </ChartCard>
              </Grid>
              <Grid item container md={6} xs={12}>
                <ChartCard
                  title={t(
                    RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_SEMANA_TITULO,
                    "Total de mentorias por semana"
                  )}
                  subtitle={t(
                    RH_DASHBOARD_GRAFICOS_MENTORIAS_POR_SEMANA_SUBTITULO,
                    "Total Semanal"
                  )}
                >
                  {totalMentoriasSemanalLoading ? (
                    <SpinnerSplendis />
                  ) : (
                    <LineChart
                      scheme={"category10"}
                      margins={{ top: 20, right: 70, bottom: 80, left: 20 }}
                      data={totalMentoriasSemanalData}
                      legends={{
                        axisBottom: "",
                        axisLeft: t(
                          RH_DASHBOARD_GRAFICOS_LEGENDA_NUMERO_MENTORIAS,
                          "Nº de Mentorias"
                        ),
                      }}
                      layout={"vertical"}
                      labelTextColor={"#FFFFFF"}
                      rotateLabelBottom={50}
                    />
                  )}
                </ChartCard>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainelControleEstatistico;
