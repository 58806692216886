import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados às estatísticas do perfil de RH.
 */
export default class EstatisticaService extends HttpClientService {
    constructor() {
        super('estatisticas')
    }

    /**
     * Obtém os status de todas as mentorias.
     * 
     * @param filterObject O objeto utilizado como parâmetro na busca
     *                     dos status das mentorias.
     * @returns Promise com o resultado do processo.
     */
    getStatusMentoriasStats(filterObject) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/status-mentorias`,
            method: AxiosMethodsName.get,
            params: filterObject,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém as principais categorias para as quais as mentorias são
     * mais realizadas.
     * 
     * @param filterObject O objeto utilizado como parâmetro na busca
     *                     das principais categorias.
     * @returns Promise com o resultado do processo.
     */
    getTopCategoriasStats(filterObject) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/top-categorias`,
            method: AxiosMethodsName.get,
            params: filterObject,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém os principais mentores com os quais as mentorias são
     * mais realizadas.
     * 
     * @param filterObject O objeto utilizado como parâmetro na busca
     *                     dos principais mentores.
     * @returns Promise com o resultado do processo.
     */
    getTopMentoresStats(filterObject) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/top-mentores`,
            method: AxiosMethodsName.get,
            params: filterObject,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém os principais mentorados, os quais mais realizam
     * mentorias.
     * 
     * @param filterObject O objeto utilizado como parâmetro na busca
     *                     dos principais mentorados.
     * @returns Promise com o resultado do processo.
     */
    getTopMentoradosStats(filterObject) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/top-mentorados`,
            method: AxiosMethodsName.get,
            params: filterObject,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém os principais estados brasileiros de onde os usuários
     * mais realizam mentorias.
     * 
     * @param filterObject O objeto utilizado como parâmetro na busca
     *                     dos principais estados brasileiros.
     * @returns Promise com o resultado do processo.
     */
    getTopUFsStats(filterObject) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/top-ufs`,
            method: AxiosMethodsName.get,
            params: filterObject,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém as estatísticas das avaliações das mentorias.
     * 
     * @param filterObject O objeto utilizado como parâmetro na busca
     *                     das avaliações das mentorias.
     * @returns Promise com o resultado do processo.
     */
    getAvaliacoesStats(filterObject) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/avaliacoes`,
            method: AxiosMethodsName.get,
            params: filterObject,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém o total de mentorias realizadas por mês.
     * 
     * @param regiao A região utilizada como parâmetro
     *               na busca do total de mentorias.
     * @returns Promise com o resultado do processo.
     */
    getTotalMentoriaMensal(regiao) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/total-mensal`,
            method: AxiosMethodsName.get,
            params: { regiao },
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém o total de mentorias realizadas por semana.
     * 
     * @param regiao A região utilizada como parâmetro
     *               na busca do total de mentorias.
     * @returns Promise com o resultado do processo.
     */
    getTotalMentoriaSemanal(regiao) {

        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/total-semanal`,
            method: AxiosMethodsName.get,
            params: { regiao },
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Obtém os principais países nos quais as mentorias são
     * mais realizadas.
     * 
     * @param filterObject O objeto utilizado como parâmetro na busca
     *                     das avaliações das mentorias.
     * @returns Promise com o resultado do processo.
     */
    getTopPaisesLatam(filterObject) {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/top-paises-latam`,
            method: AxiosMethodsName.get,
            params: filterObject,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

}
