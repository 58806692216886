import React, { useEffect, useState } from 'react';
import { Button, createStyles, Grid, makeStyles } from '@material-ui/core';
import MentorDashboard from '../mentor/home/MentorDashboard';
import { useTranslation } from 'react-i18next';

import {
    GERENCIAMENTO_PERFIS,
    GERENCIAMENTO_PERFIS_COMO_MENTOR,
    GERENCIAMENTO_PERFIS_COMO_MENTORADO
} from '../../../messages';
import MentoradoDashboard from '../mentorado/home/MentoradoDashboard';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
    createStyles({
        swapperOptions: {
            display: 'sticky',
            margin: '7vh 0 -8vh 23vw',
            width: '90%',

            '@media only screen and (max-width: 414px)': {
                display: 'flex',
                marginBottom: '2vh',
                marginLeft: '7vw',
                marginTop: '4vh',
			},

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                marginBottom: '2vh',
                marginLeft: '6vw',
                marginTop: '4vh',
			},
        },
        swapperOptionsButton: {
            backgroundColor: '#FFF',
            border: '1px solid #003B4A',
            color: '#003B4A',
            fontFamily: 'Roboto',
            fontSize: '.75rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '16.41px',
            marginRight: '.5rem',
            padding: '.8rem .8rem',

            '&:disabled': {
                border: 'none',
            }
        },
        swapperOptionsButtonMentorado: {
            backgroundColor: '#FFF',
            border: '1px solid #003B4A',
            color: '#003B4A',
            fontFamily: 'Roboto',
            fontSize: '.75rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '16.41px',
            marginLeft: '7rem',
            padding: '.8rem .8rem',

            '&:disabled': {
                border: 'none',
            },

            '@media only screen and (max-width: 414px)': {
                fontSize: '.6rem',
                marginLeft: '10rem',
                width: '30vw'
			},
        }
    })
);

/**
 * Interface utilizada pelo componente ProfileSwapper e que descreve
 * seu métodos e propriedades.
 */
interface ProfileSwapperProps {
    idMentor?: any;
    idMentorado?: any;
    session?: any;
}

/**
 * Permite a troca entre os perfis mentor e mentorado na home do usuário.
 * 
 * @param {ProfileSwapperProps}
 * @returns O swapper que permite a troca entre os perfis mentor e mentorado.
 */
const ProfileSwapper: React.FC<ProfileSwapperProps> = ({ idMentor, idMentorado, session }) => {
    const classes = useStyles();

    /**
     * Hook e função utilizados na tradução de textos.
     */
    const { t } = useTranslation([GERENCIAMENTO_PERFIS]);

    const [mentorEnabled, setMentorEnabled] = useState(false);
    const [mentoradoEnabled, setMentoradoEnabled] = useState(false);

    /**
     * Ao renderizar o componente, habilita o perfil do mentor ou
     * mentorado dependendo do papel do usuário logado.
     */
    useEffect(() => {
        if (idMentor) {
            setMentorEnabled(true);
            setMentoradoEnabled(false);
        } else {
            setMentoradoEnabled(true);
        }
    }, [idMentor]);

    /**
     * Gerencia a troca de perfis entre mentor e mentorado.
     */
    const handleToggle = () => {
        setMentorEnabled(!mentorEnabled);
        setMentoradoEnabled(!mentoradoEnabled);
    }

    return (
        <>
            <Grid container>
                <Grid item md={4}></Grid>
                <Grid container item md={8} direction='row'>
                    <Grid item md={8} className={classes.swapperOptions}>
                        {idMentor &&
                            <Button
                                variant='contained'
                                disabled={mentorEnabled}
                                className={classes.swapperOptionsButton}
                                onClick={handleToggle}>
                                {t(GERENCIAMENTO_PERFIS_COMO_MENTOR, 'Como Mentor')}
                            </Button>
                        }
                        <Button
                            variant='contained'
                            disabled={mentoradoEnabled}
                            className={clsx({
                                [classes.swapperOptionsButton]: idMentor,
                                [classes.swapperOptionsButtonMentorado]: !idMentor
                            })}
                            onClick={handleToggle}>
                            {t(GERENCIAMENTO_PERFIS_COMO_MENTORADO, 'Como Mentorado')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={12}>
                    {mentorEnabled && <MentorDashboard idMentor={idMentor} />}
                    {mentoradoEnabled && <MentoradoDashboard idMentorado={idMentorado} idMentor={idMentor || null} />}
                </Grid>
            </Grid>
        </>
    );
}

export default ProfileSwapper;
