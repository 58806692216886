import { Mentoria, Avaliacao } from ".";

/**
 * Classe que define o modelo para a avaliação de mentoria com todas as suas propriedades e métodos.
 */
export class MentoriaAvaliacao extends Mentoria{
    
    public avaliacaoDTO: Avaliacao;
    
    constructor(){
        super();
        this.avaliacaoDTO = new Avaliacao();
    }
    
}