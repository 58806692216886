import { Mentor } from "./Mentor"
import { Disponibilidade } from "./Disponibilidade"

/**
 * Classe que define o modelo para a disponibilidade do mentor com todas as suas propriedades.
 */
export class DisponibilidadeMentor {
    id?:string = ''
    mentor?: Mentor
    disponibilidade?: Disponibilidade
    data?:string = ''
    horario?:string = ''
    diaDaSemana?:string = ''
    ordinal?:string = ''
}

  