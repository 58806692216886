import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados a obtenção do manual do usuário.
 */
export default class UserManualService extends HttpClientService{
    constructor(){
        super('manual');
    }

    /**
     * Obtém o manual do usuário.
     * 
     * @param language O idioma do manual a ser obtido.
     * @returns Promise com o resultado da requisição.
     */
    getUserManual(language: string): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            method: AxiosMethodsName.get,
            responseType: 'blob',
            headers: {
                'Accept-language': language 
            }
        }

        return super.executeMethod(configRequest);
    }

}
