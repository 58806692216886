import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';

import { FormColaborador } from '../../../../components';
import { ColaboradorService } from '../../../../service';
import { Colaborador } from '../../../../models';
import { SpinnerSplendis } from '../../../../commons';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: '85vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },

        formContainer: {
            height: '90%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2vh',

            '@media only screen and (max-width: 414px)': {
                marginTop: '20vh'
            }
        }
    })
);

/**
 * Componente para edição do colaborador.
 * 
 * @param props Propriedades utilizadas na obtenção de dados
 *              para a edição do colaborador.
 * @returns Container com o form do colaborador.
 */
export default function EdicaoColaborador(props) {
    const classes = useStyles();

    const colaboradorService = new ColaboradorService();

    const { emailColaborador } = props;

    const [colaborador, setColaborador] = React.useState<Colaborador>(new Colaborador());
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    /**
     * Obtém os daddos do colaborador.
     */
    useEffect(() => {
        setIsLoading(true);
        colaboradorService.getByEmail(emailColaborador)
            .then(res => {
                setColaborador(res.data);
            })
            .then(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className={classes.container}>
            <div className={classes.formContainer}>
                {isLoading ? <SpinnerSplendis /> : <FormColaborador colaborador={colaborador} />}
            </div>
        </Container>
    );
}
