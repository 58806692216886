import * as Yup from 'yup';
import { 
    VALIDATIONS_CAMPO_OBRIGATORIO,
    VALIDATIONS_EMAIL_INVALIDO,
    VALIDATIONS_SENHAS_DIFERENTES,
    VALIDATIONS_NOME_CURTO,
    VALIDATIONS_SENHA_CURTA,
    VALIDATIONS_SENHA_TAMANHO_MAXIMO,
    VALIDATIONS_DOMINIO_INVALIDO,
    VALIDATIONS_NOME_LONGO,
    VALIDATIONS_CARGO_INVALIDO
} from '../../messages';
import { emailDomains } from '../../utils/FormUtils';

/**
 * Verifica se o domínio do email recebido está no conjunto de emails aceitos.
 */
const getDomains = () => {
    let str = '';
    emailDomains.forEach((element) => { str += (element + '|') });

    return str.slice(0, -1);
}

const domains = getDomains();

const domainRegex = new RegExp([`^[a-zA-Z0-9-_.]{0,20}${domains}(?!.)$`].join(''));

/**
 * Schema de validação para o formulário de colaborador no momento do cadastro.
 */
export const CadastroColaboradorSchema = (t, passwordMatch) => Yup.object().shape({
    email: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).email(t(VALIDATIONS_EMAIL_INVALIDO,"Deve ser um e-mail válido")).matches(domainRegex, t(VALIDATIONS_DOMINIO_INVALIDO, "O email deve pertencer a um domínio corporativo")),
    nome: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).min(5, t(VALIDATIONS_NOME_CURTO, "Nome muito curto")).max(100, t(VALIDATIONS_NOME_LONGO, "Nome muito longo")),
    cargo: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).min(2, t(VALIDATIONS_CARGO_INVALIDO, "Cargo Inválido")).max(45, t(VALIDATIONS_NOME_LONGO, "Nome muito longo")),
    timeZone: Yup.string().when("pais", {is: value => value && value.length > 0, then: Yup.string().notRequired(), otherwise: Yup.string().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório"))}),
    pais: Yup.string().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")),
    uf: Yup.string().when("pais", {is: "BRA", then: Yup.string().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório"))}),
    senha: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).min(6, t(VALIDATIONS_SENHA_CURTA, "Senha muito curta")).max(20, t(VALIDATIONS_SENHA_TAMANHO_MAXIMO, "O máximo é de 20 caracteres")),
    confSenha: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).test('passwords-match', t(VALIDATIONS_SENHAS_DIFERENTES, 'Senhas precisam ser iguais'), passwordMatch),
    
});

/**
 * Schema de validação para o formulário de colaborador no momento da atualização.
 */
export const AtualizarColaboradorSchema = (t) => Yup.object().shape({
    nome: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).min(5, t(VALIDATIONS_NOME_CURTO, "Nome muito curto")).max(45, t(VALIDATIONS_NOME_LONGO, "Nome muito longo")),
    email: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).email(t(VALIDATIONS_EMAIL_INVALIDO, "Deve ser um e-mail válido")),
    cargo: Yup.string().trim().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")).min(2, t(VALIDATIONS_CARGO_INVALIDO, "Cargo Inválido")).max(45, t(VALIDATIONS_NOME_LONGO, "Nome muito longo")),
    timeZone: Yup.string().when("pais", {is: value => value && value.length > 0, then: Yup.string().notRequired(), otherwise: Yup.string().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório"))}),
    pais: Yup.string().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")),
    uf: Yup.string().when("pais", {is: "BRA", then: Yup.string().required(t(VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório"))}),
});
