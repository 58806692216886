import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import { NotificationHandler, InformationHandler } from '../message-handler/MessageHandler';
import { UserContext } from '../../service';

import { toast, Zoom } from 'react-toastify';
import { AlertComponent, AlertMinimal } from './components/AlertComponent';
import {
    EXCEPTION_HANDLER_SOURCE,
    EXCEPTION_HANDLER_ERRO_SESSAO_EXPIRADA,
    EXCEPTION_HANDLER_ERRO_USUARIO_SENHA_INCORRETA,
    EXCEPTION_HANDLER_ERRO_INESPERADO
} from './../../messages';

const alertContext = React.createContext<any>(null);

/**
 * Componente responsável por lidar com as exceções/erros/falhas lançadas pelo backend
 * apresentando para o usuário uma mensagem mais amigável.
 * 
 * @param props 
 * @returns As mensagens de alerta correspondentes a cada situação.
 */
export default function ExceptionHandler(props) {

    const { t, i18n } = useTranslation(EXCEPTION_HANDLER_SOURCE);
    const { logout } = useContext(UserContext);
    const history = useHistory();

    const MSG_ERRO_SESSAO_EXPIRADA = t(EXCEPTION_HANDLER_ERRO_SESSAO_EXPIRADA, 'Erro: Sessão expirada. Por favor, faça o login novamente');
    const MSG_ERRO_USUARIO_SENHA_INCORRETA = t(EXCEPTION_HANDLER_ERRO_USUARIO_SENHA_INCORRETA, 'Erro: Usuário ou senha incorretos');
    const MSG_ERRO_INESPERADO = t(EXCEPTION_HANDLER_ERRO_INESPERADO, 'Erro Inesperado');

    /**
     * Renderiza uma mensagem de alerta ao usuário dependendo do status HTTP.
     * 
     * @param httpStatus O status HTTP passado ao componente de mensagem.
     * @param mensagem A mensagem passada ao componente de mensagem.
     * @returns O componente de mensagem renderizando a mensagem adequada.
     */
    const alertaUsuario = (httpStatus: number, mensagem: string) => {
        return <AlertComponent data={{httpStatus, mensagem}} />
    } 

    /**
     * Axios interceptor que captura várias situações de erro na aplicação.
     */
    axios.interceptors.response.use((response: any): any => {
        return response;
    }, (error) => {

        if (error.response){
            const { status } = error.response;

            if (status === 401){
                toast.error(alertaUsuario(status, MSG_ERRO_SESSAO_EXPIRADA), {containerId: 'notify'});
                logoutAndRedirect();
            } else if (error.response.data.error && error.response.data.error_description === 'Bad credentials') {
                toast.error(alertaUsuario(status, MSG_ERRO_USUARIO_SENHA_INCORRETA), {containerId: 'notify'});
            } else {
                toast.error(alertaUsuario(status, error.response.data?.mensagem), {containerId: 'notify'});
            }
        } else{
            toast.error(alertaUsuario(500, MSG_ERRO_INESPERADO));
            window.location.href = "#/mentoring/offline";
        }
        
        return Promise.reject(error);
    });

    /**
     * Axios interceptor que captura o idioma atual.
     */
    axios.interceptors.request.use( request => {
        //Define idioma atual
        request.headers['Accept-language'] = i18n.language;
        
        return request;
    },
    err => {
        return Promise.reject(err);
    })

    /**
     * Renderiza mensagem de sucesso ou não, dependendo do status HTTP.
     * 
     * @param httpStatus O status HTTP que será utilizado para a renderização da mensagem adequada.
     * @param mensagem A mensagem que será renderizada conforme o status HTTP.
     */
    const showAlert = (httpStatus: number, mensagem: string) => {
        if(httpStatus >= 300){
            toast.error(<AlertComponent data={{httpStatus, mensagem}} />, {containerId: 'notify'});
        } else {
            toast.success(<AlertComponent data={{httpStatus, mensagem}} />, {containerId: 'notify'});
        }
    }

    /**
     * Renderiza mensagem informativas ao usuário.
     * 
     * @param severity Determina que tipo de mensagem será exibida(e.g. mensagem de erro, mensagem informativa etc.)
     * @param message A mensagem que será exibida ao usuário.
     * @returns O componente de mensagem com a severity adequada para cada situação.
     */
    const showInfoMessage = (severity: string, message: string) => {
        toast.clearWaitingQueue({ containerId: 'inform' });
        return toast.warn(
            <AlertMinimal 
            severity={ severity }>
            { message }
            </AlertMinimal>
        , 
            {   
                containerId: 'inform',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                transition: Zoom
            }
        );
    }  

    /**
     * Objeto com os tipos de mensagens que serão passadas para o contexto do compoennte
     * de mensagens.
     */
    const values = {
        showAlert: showAlert,
        showInfoMessage: showInfoMessage
    }

    /**
	 * Desloga o usuário e redireciona-o para a tela de login.
	 */
	const logoutAndRedirect = (): void => {
		logout();
		history.push('/');
	};

    return (
        <React.Fragment>
            
            <NotificationHandler />
            <InformationHandler />

            <alertContext.Provider value={ values }>
                {props.children}
            </alertContext.Provider>

        </React.Fragment>


    );
}

export { alertContext };