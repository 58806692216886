import React, { useEffect, useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

import {
    MentoriaTable,
    alertContext,
    AtualizacaoMentorModal,
} from "../../../../components";

import { Mentoria, Mentor } from "../../../../models";

import {
    MentoriaService,
    MentorService,
    ColaboradorService,
    SessionStorageService,
    UserContext,
} from "../../../../service";

import MentorDashboardCard from "./components/mentor-card/MentorDashboardCard";
import { useTranslation } from "react-i18next";
import { ShowTranslationWarning } from "../../../../utils/FormUtils";

import {
    HOME_MENTOR_SOURCE,
    HOME_MENTOR_AGENDA_TITULO,
    HOME_MENTOR_CANCELAMENTO_MENTORIA_TITULO,
    HOME_MENTOR_CANCELAMENTO_MENTORIA_MENSAGEM,
    HOME_MENTOR_REAGENDAMENTO_SUBTITULO,
    HOME_MENTOR_REAGENDAMENTO_TITULO,
    HOME_MENTOR_CANCELAMENTO_SUCESSO,
    HOME_MENTOR_CANCELAMENTO_ERRO,
    HOME_MENTOR_REAGENDAMENTO_ALERTA_SUCESSO
} from "../../../../messages";
import { MentoriaFuturaMentor } from "../../../../models/MentoriaFuturaMentor";
import CancelarMentoriaModal from "../../geral/gerenciamento-mentorias/modals/CancelarMentoriaModal";
import { ModalCancelamento, ModalSucesso, SpinnerSplendis } from "../../../../commons";
import ReagendarMentoriaModal from "../../geral/gerenciamento-mentorias/modals/ReagendarMentoriaModal";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            height: '80vh',
            margin: '0 auto',

            '@media only screen and (max-width: 1024px)': {
                display: 'block',
                maxWidth: '100%',
            },
        },
        flexContainer: {
            display: 'flex',
            maxHeight: '100%',

            '@media only screen and (max-width: 414px)': {
				display: 'block',
				width: '100%',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				display: 'block',
				marginTop: '12vh',
				width: '100%',
			},
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            height: '90%',
            justifyContent: 'space-between',
            margin: '5rem auto 0 auto',
            width: '90%',

            '@media only screen and (max-width: 414px)': {
				marginRight: 0,
				width: '100%',
			},

			'@media only screen and (max-width: 1024px)': {
				fontSize: '0.6rem',
				width: '100%',
			},
        },
        titleContainer: {
            height: '5%',
            width: '80%',
            textAlign: 'left',
        },
        subtitleContainer: {
            display: 'flex',
            alignContent: 'flex-start',
            textAlign: 'left',
            marginTop: 20,
        },
        title: {
            fontWeight: 600,
            '@media only screen and (max-width: 1024px)': {
                fontSize: '1rem',
            },
        },
        subtitle: {
            '@media only screen and (max-width: 1024px)': {
                fontSize: '0.8rem',
            },
        },
        mentorCard: {
            marginRight: 40,
            '@media only screen and (max-width: 1024px)': {
                width: '100%',
                fontSize: '1rem',
            },
        },

        spanDiv: {
            textAlign: "end"
        },

        spanText: {
            color: "#AAABAC"
        },

        agendaTitle: {
			color: '#030624',
			float: 'left',
			fontFamily: 'InfraRegular',
			fontSize: '26px',
			fontStyle: 'normal',
			lineHeight: '39px',
			margin: '-7vh 0 2vh 0',

            '@media only screen and (max-width: 414px)': {
				fontSize: '20px',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				fontSize: '22px',
			},
		},

        mentorTable: {
			height: '25rem',

			'@media only screen and (max-width: 414px)': {
				width: '90vw',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				display: 'block',
				width: '92vw',
			},
		}
    })
);

/**
 * Obtém as dimensões da tela(largura e altura).
 * 
 * @returns As dimensões da tela(largura e altura).
 */
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

/**
 * Dashboard do mentor com informações sobre o mentor no card principal
 * do mentor, e uma tabela com informações sobre as próximas mentorias.
 * 
 * @param idMentor O id do mentor logado na aplicação. 
 * @returns O dashboard do mentor com um card exibindo informações
 *          do mentor e uma tabela exibindo as próximas mentorias.
 */
export default function MentorDashboard({ idMentor }) {
    const classes = useStyles();

    const { t } = useTranslation([HOME_MENTOR_SOURCE]);

    const { userObject, setUserObject } = useContext(UserContext);

    const mentoriaService: MentoriaService = new MentoriaService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mentorService: MentorService = new MentorService();
    const colaboradorService: ColaboradorService = new ColaboradorService();

    const [, setMentoriasPassadas] = React.useState<
        Mentoria[]
    >();
    const [mentoriasFuturas, setMentoriasFuturas] = React.useState<
        MentoriaFuturaMentor[]
    >();
    const [
        isMentoriasFuturasLoading,
        setMentoriasFuturasLoading,
    ] = React.useState<boolean>(false);
    const [notaMentor, setNotaMentor] = React.useState<string>();
    const [mentor, setMentor] = React.useState<Mentor>();
    const [colaborador, setColaborador] = React.useState();

    const [
        modalAtualizacaoMentorIsOpen,
        setModalAtualizacaoMentorIsOpen,
    ] = React.useState<boolean>(false);

    const [, setDimensions] = React.useState(getWindowDimensions());

    // States de Cancelamento
    const [mentoriaACancelar, setMentoriaACancelar] = React.useState<any>();
    const [
        isCancelamentoMentoriaModalOpen,
        setIsCancelamentoMentoriaModalOpen,
    ] = React.useState<boolean>(false);
    const [
        isSucessoCancelamentoModalOpen,
        setSucessoCancelamentoModalOpen,
    ] = React.useState<boolean>(false);

    // States de Reagendamento
    const [mentoriaAReagendar, setMentoriaAReagendar] = React.useState<any>(
        new Mentoria()
    );
    const [
        isReagendarMentoriaModalOpen,
        setIsReagendarMentoriaModalOpen,
    ] = React.useState<boolean>(false);
    const [
        isSucessoReagendarMentoriaModalOpen,
        setIsSucessoReagendarMentoriaModalOpen,
    ] = React.useState<boolean>(false);

    const { showAlert } = useContext(alertContext);

    /**
     * Realiza a configuração de vários states, e também obtém as dimensões
     * da tela.
     */
    useEffect(() => {
        let emailColaborador = SessionStorageService.getStorage("usuario");
        colaboradorService.getById(emailColaborador).then((res) => {
            setColaborador(res.data);
        });
        mentoriaService.getMentoriasFuturasMentor(idMentor).then((res) => {
            setMentoriasFuturas(res.data);
        });
        mentorService.getMediaAvaliacoes(idMentor).then((res) => {
            setNotaMentor(res.data);
        });
        mentorService.getInformacaoMentorEdicaoById(idMentor).then((res) => {
            setMentor(res.data);
        });
        mentoriaService.getMentoriasPassadasMentor(idMentor).then((res) => {
            setMentoriasPassadas(res.data);
        });

        function handleResize() {
            setDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Atualiza as informações do card do mentor caso as informações do mentor mudem.
     */
    useEffect(() => {
        let emailColaborador = SessionStorageService.getStorage("usuario");
        colaboradorService.getById(emailColaborador).then((res) => {
            setColaborador(res.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mentor]);

    /**
     * Obtém as informações do mentor caso o modal de atualização do mentor seja aberto.
     */
    useEffect(() => {
        if (modalAtualizacaoMentorIsOpen) {
            mentorService
                .getInformacaoMentorEdicaoById(idMentor)
                .then((res) => {
                    setMentor(res.data);
                });
        }
    }, [modalAtualizacaoMentorIsOpen, mentorService, idMentor]);

    /**
     * Modal de atualização das informações do mentor.
     * 
     * @returns O modal de atualização das informações do mentor.
     */
    const atualizacaoMentorModal = () => {
        return (
            <AtualizacaoMentorModal
                updateMentor={editMentor}
                isOpen={modalAtualizacaoMentorIsOpen}
                handleClose={() => setModalAtualizacaoMentorIsOpen(false)}
                mentor={{ ...mentor }}
                alertCallback={showAlert}
                mentorMode
            />
        );
    };

    // TODO: Pode atualizar o state mentor já com o retorno da requisição de edição
    /**
     * Edita as informações do mentor.
     * 
     * @param mentor O mentor que terá suas informações editadas.
     */
    const editMentor = async (mentor: Mentor): Promise<void> => {
        await mentorService.editarViaRoleMentor(idMentor, mentor);
        refreshMentor();
    };

    /**
     * Atualiza as informações do mentor, atualizando as informações dos states.
     */
    const refreshMentor = () => {
        mentorService.getInformacaoMentorEdicaoById(idMentor).then((res) => {
            let colab = userObject.colaborador;
            colab.foto = res.data.foto;
            setUserObject({
                ...userObject,
                colaborador: colab,
            });
            setMentor(res.data);
        });
    };

    /**
     * Atualiza a lista de mentorias futuras do mentor.
     */
    const updateMentoriasFuturas = () => {
        setMentoriasFuturasLoading(true);
        mentoriaService
            .getMentoriasFuturasMentorado(idMentor)
            .then((res) => {
                setMentoriasFuturas(res.data);
            })
            .then(() => setMentoriasFuturasLoading(false));
    };

    /**
     * Cancela uma mentoria do mentor.
     * 
     * @param id O id da mentoria a ser cancelada.
     */
    const cancelarMentoria = (id) => {
        mentoriaService
            .cancelar(id)
            .then(() => {
                closeCancelarMentoriaModal();
                updateMentoriasFuturas();
                openSucessoCancelamentoMentoriaModal();
                showAlert(200, t(HOME_MENTOR_CANCELAMENTO_SUCESSO, "Cancelamento bem-sucedido!"));
            })
            .catch(() => {
                closeCancelarMentoriaModal();
                showAlert(
                    400,
                    t(HOME_MENTOR_CANCELAMENTO_ERRO, "Não foi possível cancelar a mentoria. Por favor, tente novamente.")
                );
            });
    };

    /**
     * Abre o modal de cancelar mentoria, configurando os states apropriados.
     * 
     * @param mentoria A mentoria a ser cancelada.
     */
    const openCancelarMentoriaModal = (mentoria) => {
        setMentoriaACancelar(mentoria);
        setIsCancelamentoMentoriaModalOpen(true);
    };

    /**
     * Fecha o modal de cancelar mentoria, configurando os states apropriados.
     */
    const closeCancelarMentoriaModal = () => {
        setMentoriaACancelar(null);
        setIsCancelamentoMentoriaModalOpen(false);
    };

    /**
     * Abre o modal de sucesso de cancelamento de mentoria, configurando o
     * state apropriado.
     */
    const openSucessoCancelamentoMentoriaModal = () => {
        setSucessoCancelamentoModalOpen(true);
    };

    /**
     * Fecha o modal de sucesso de cancelamento de mentoria, configurando o
     * state apropriado.
     */
    const closeSucessoCancelamentoMentoriaModal = () => {
        setSucessoCancelamentoModalOpen(false);
    };

    /**
     * Modal de cancelamento de mentoria.
     * 
     * @param mentoria A mentoria a ser cancelada.
     * @param role O papel do usuário.
     * @returns O modal de cancelamento de mentoria.
     */
    const cancelarMentoriaModal = (mentoria, role) => {
        if (mentoria) {
            return (
                <CancelarMentoriaModal
                    mentoria={mentoria}
                    isOpen={isCancelamentoMentoriaModalOpen}
                    handleClose={closeCancelarMentoriaModal}
                    cancelarMentoriaCallback={(idMentoria) =>
                        cancelarMentoria(idMentoria)
                    }
                    role={role}
                />
            );
        }
    };

    /**
     * Modal de sucesso de cancelamento de mentoria.
     * 
     * @returns O modal de sucesso de cancelamento de mentoria.
     */
    const sucessoCancelamentoMentoriaModal = () => {
        return (
            <ModalCancelamento
                isOpen={isSucessoCancelamentoModalOpen}
                handleClose={closeSucessoCancelamentoMentoriaModal}
                onConfirm={closeSucessoCancelamentoMentoriaModal}
                title={t(HOME_MENTOR_CANCELAMENTO_MENTORIA_TITULO, "Mentoria cancelada")}
                subtitle={
                    t(HOME_MENTOR_CANCELAMENTO_MENTORIA_MENSAGEM, "Foi enviado para você e o mentor, um email de cancelamento deste evento!")
                }
            />
        );
    };

    /**
     * Realiza o reagendamento de uma mentoria. 
     * 
     * @param id O id da mentoria a ser reagendada.
     * @param mentoria A mentoria a ser reagendada.
     */
    const reagendarMentoria = (id, mentoria) => {
        mentoriaService.reagendar(id, mentoria).then((res) => {
            closeReagendarMentoriaModal();
            openSucessoReagendarMentoriaModal();
            updateMentoriasFuturas();
            showAlert(200, t(HOME_MENTOR_REAGENDAMENTO_ALERTA_SUCESSO, "Reagendamento bem-sucedido!"));
            return res;
        }).catch((err) => closeReagendarMentoriaModal());
    };

    /**
     * Abre o modal de reagendamento de mentoria e configura
     * o state de mentoria a reagendar.
     * 
     * @param mentoria A mentoria a ser reagendada.
     */
    const openReagendarMentoriaModal = (mentoria) => {
        setMentoriaAReagendar(mentoria);
        setIsReagendarMentoriaModalOpen(true);
    };

    /**
     * Fecha o modal de reagendamento de mentoria configurando
     * o state de mentoria a reagendar.
     */
    const closeReagendarMentoriaModal = () => {
        setIsReagendarMentoriaModalOpen(false);
    };

    /**
     * Abre o modal de sucesso de reagendamento de mentoria configurando
     * o state de sucesso de reagendar mentoria.
     */
    const openSucessoReagendarMentoriaModal = () => {
        setIsSucessoReagendarMentoriaModalOpen(true);
    };

    /**
     * Fecha o modal de sucesso de reagendamento de mentoria configurando
     * o state de sucesso de reagendar mentoria.
     */
    const closeSucessoReagendarMentoriaModal = () => {
        setIsSucessoReagendarMentoriaModalOpen(false);
    };

    /**
     * Modal que permite o reagendamento de mentoria.
     * 
     * @param mentoria A mentoria a ser reagendada.
     * @returns O modal de reagendamento de mentoria.
     */
    const reagendarMentoriaModal = (mentoria) => {
        if (mentoria) {
            return (
                <ReagendarMentoriaModal
                    isOpen={isReagendarMentoriaModalOpen}
                    handleClose={closeReagendarMentoriaModal}
                    mentoria={mentoria}
                    role={"ROLE_MENTOR"}
                    reagendarMentoriaCallback={(id, mentoria) => {
                        reagendarMentoria(id, mentoria);
                    }}
                />
            );
        }
    };

    /**
     * Modal de sucesso de reagendamento de mentoria.
     * 
     * @returns O modal de sucesso de reagendamento de mentoria.
     */
    const sucessoReagendamentoMentoriaModal = () => {
        return (
            <ModalSucesso
                isOpen={isSucessoReagendarMentoriaModalOpen}
                handleClose={closeSucessoReagendarMentoriaModal}
                title={t(HOME_MENTOR_REAGENDAMENTO_TITULO, "Mentoria Reagendada com Sucesso!")}
                subtitle={t(HOME_MENTOR_REAGENDAMENTO_SUBTITULO, "Os envolvidos já foram notificados sobre o reagendamento.")}
            />
        );
    };

    /**
     * Renderiza todos os modais no componente.
     * 
     * @returns Um fragment que renderiza todos os modais do componente.
     */
    const modais = () => {
        return (
            <React.Fragment>
                {reagendarMentoriaModal(mentoriaAReagendar)}
                {sucessoReagendamentoMentoriaModal()}
                {cancelarMentoriaModal(mentoriaACancelar, "ROLE_MENTOR")}
                {sucessoCancelamentoMentoriaModal()}
            </React.Fragment>
        );
    };

    return (
        <Container className={classes.root}>
            {/*TEMPORÁRIO*/}
            <ShowTranslationWarning />
            {modais()}

            {mentor && atualizacaoMentorModal()}

            <div id='flex-container' className={classes.flexContainer}>
                <MentorDashboardCard
                    notaMentor={notaMentor}
                    colaborador={colaborador}
                    updateMentor={editMentor}
                    mentor={{ ...mentor }}
                    alertCallback={showAlert}
                />

                <div className={classes.content}>
                    <div style={{ height: '47%' }}>
                        <Typography className={classes.agendaTitle}>{t(HOME_MENTOR_AGENDA_TITULO, 'Agenda')}</Typography>
                        <div className={classes.mentorTable}>
                            {isMentoriasFuturasLoading ? (
                                <div style={{ alignSelf: 'center' }}>
                                    <SpinnerSplendis />
                                </div>
                            ) : (
                                <MentoriaTable
                                    mentorias={mentoriasFuturas}
                                    asRole={'ROLE_MENTOR'}
                                    enableFirstOnFocus
                                    cancelable
                                    cancelarMentoriaCallback={(mentoria) =>
                                        openCancelarMentoriaModal(mentoria)
                                    }
                                    reschedulable
                                    reagendarMentoriaCallback={
                                        openReagendarMentoriaModal
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}
