import React, { useContext, useState } from 'react'
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { CustomModal, SpinnerSplendis } from '..'
import ConfirmacaoSVG from '../../assets/svgs/confirmacao.svg';
import { useHistory } from 'react-router-dom';

import {
    EXTRAS_SOURCE,
    EXTRAS_MODAL_SUCESSO_BOTAO_INICIO
} from '../../messages';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../service';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageDiv: {
            marginBottom: '1rem'
        },
        image: {
            width: '10.8rem',
            height: '9.7rem'
        },
        title: {
            fontWeight: 500,
            fontFamily: 'infraRegular',
            fontStyle: 'normal',
            fontSize: '1.4rem',
            lineHeight: '1.25rem',
            marginBottom: '0.75rem'
        },
        subtitle: {
            fontFamily: 'infraLight',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '0.875rem',
            lineHeight: '140%',
            marginBottom: '1.5rem'
        },
        button: {
            fontFamily: 'Roboto',
            width: '100%',
            fontWeight: 500,
            color: 'white',
            backgroundColor: '#003B4A',
            
            '&:hover': {
				backgroundColor: '#0a3039',
			}
        }
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: 'auto',
        marginBottom: '4rem',
        textAlign: 'center',
        width: '20rem',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: 'auto',
        textAlign: 'center',
        width: '70vw',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: 'auto',
        marginTop: '50vh',
        textAlign: 'center',
        width: '60vw',
    },
};

/**
 * Interface utilizada pelo componente ModalSucesso e que descreve
 * seu métodos e propriedades.
 */
interface ModalSucessoProps {
    isOpen: boolean
    handleClose: Function
    onConfirm?: Function
    style?: any
    disableClose?: Function
    title?: string
    subtitle?: string
    modalProps?: any
}

/**
 * Componente para ser apresentado na confirmação de sucesso.
 * e.g.: Agendamento de uma nova mentoria.
 * 
 * @param {ModalSucessoProps} 
 * @returns O modal de sucesso com todas suas informações pertinentes.
 */
const ModalSucesso: React.FC<ModalSucessoProps> = ({ isOpen, handleClose, onConfirm, children, style, disableClose, title, subtitle, ...modalProps }) => {
    const classes = useStyles();
    const history = useHistory();
    const { session, switchRole, role } = useContext(UserContext);

    const { t } = useTranslation([EXTRAS_SOURCE]);

    const [loading, setLoading] = useState(false);

    /**
     * Verifica que sucesso da operação, fazendo o redirecionamento para a home do perfil correspondente após a ação.
     */
    const handleConfirm = () => {
        setLoading(true);

        setTimeout(() => {
            if (session.roles.includes('ROLE_MENTOR') && role === 'ROLE_MENTORADO') {
                switchRole('ROLE_MENTOR');
            }

            setLoading(false);
            history.push("/mentoring/home");
        }, 5000);

        if (onConfirm) {
            onConfirm();
        }
    }

    return (
        <CustomModal
            isOpen={isOpen}
            handleClose={handleClose}
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            disableClose={disableClose}
            {...modalProps}
        >
            <div className={classes.imageDiv}>
                <img src={ConfirmacaoSVG} className={classes.image} alt="confirmacao" />
            </div>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.subtitle}>
                {subtitle}
            </div>
            {loading
                ? <SpinnerSplendis />
                : <div>
                    <Button className={classes.button} onClick={handleConfirm} variant="contained">
                        {t(EXTRAS_MODAL_SUCESSO_BOTAO_INICIO, 'Início')}
                    </Button>
                </div>
            }
        </CustomModal>
    )
}

export default ModalSucesso;
