/**
 * Chaves para armazenamento local.
 */
export const ChavesLocalStorage = {
  token: "token",
  usuario: "usuario",
  session_key: "session_key",
  auth_token: "auth_token",
};

/**
 * Providencia os diferentes endpoints para os diferentes tipos de ambiente.
 *
 * @returns O endpoint do ambiente de acordo com a escolha.
 */
export const profile = () => {
  switch (process.env.REACT_APP_STAGE?.trim()) {
    case "local":
      return {
        ambiente: "local",
        apiRestUrl:
          "http://localhost:8080/mentoring",
        outh2Url:
          "http://localhost:8008/sso/oauth",
      };
    case "dev":
      return {
        ambiente: "development",
        apiRestUrl:
          "https://ads-belgo-mentoringbelgo-api-backend.develop.stefanini.io/mentoring",
        outh2Url:
          "https://ads-belgo-mentoringbelgo-api-oauthserver.develop.stefanini.io/sso/oauth",
      };
    case "sandbox":
      return {
        ambiente: "sandbox",
        apiRestUrl:
          "https://api-mentoring.sandbox.stefanini.io/api/backend/mentoring",
        outh2Url:
          "https://api-mentoring.sandbox.stefanini.io/api/oauthserver/sso/oauth",
      };
    case "stage":
      return {
        ambiente: "stage",
        apiRestUrl:
          "https://mentoring-api-belgobekaert.stage.stefanini.io/mentoring",
        outh2Url:
          "https://mentoring-oauthserver-belgobekaert.stage.stefanini.io/sso/oauth",
      };
    case "prod":
      return {
        ambiente: "produção",
        apiRestUrl: "https://mentoring-api.belgo.com.br/mentoring",
        outh2Url: "https://mentoring-oauth.belgo.com.br/sso/oauth",
      };
    default:
      return {
        ambiente: "local",
        apiRestUrl: "http://localhost:8080/mentoring",
        outh2Url: "http://localhost:8008/sso/oauth",
      };
  }
};
