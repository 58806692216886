import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Container, Button } from "@material-ui/core";
import { CustomModal } from "../../commons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "50vw",
      height: "95%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      "@media only screen and (max-width: 414px)": {
        width: "80vw",
        maxWidth: "96vw",
      },
    },

    textWrapper: {
      padding: "2%",
      overflow: "hidden",
      overflowY: "auto",
      height: "70%",
      fontFamily: "InfraRegular",
    },

    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },

    actionButton: {
      alignSelf: "flex-end",
      backgroundColor: "#003B4A",
      fontFamily: "InfraRegular",
      fontSize: ".9rem",
      fontStyle: "normal",
      fontWeight: 500,

      "&:hover": {
        backgroundColor: "#0a3039",
      },

      "@media only screen and (max-width: 720px)": {
        fontSize: ".7rem",
      },
    },
  })
);

/**
 * Interface utilizada pelo componente ConfirmacaoModal e que descreve
 * seu métodos e propriedades.
 */
interface ConfirmacaoModalProps {
  isOpen?: boolean;
  handleClose?: Function;
  buttonCallback: () => void;
  forceButtonEnabled?: boolean;
  centralizeTitle?: boolean;
  title?: string;
  buttonText?: string;
  disableClose?: boolean;
  modalProps?: any;
  style?: any;
  children: React.ReactNode;
}

/**
 * Componente responsável pela confirmação de alguma ação.
 *
 * @param {ConfirmacaoModalProps}
 * @returns O modal de confirmação com informações pertinentes a ação.
 */
const ConfirmacaoModal: React.FC<ConfirmacaoModalProps> = ({
  isOpen,
  handleClose,
  buttonCallback,
  forceButtonEnabled,
  centralizeTitle,
  title,
  buttonText,
  disableClose,
  modalProps,
  style,
  children,
}) => {
  const classes = useStyles();

  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);

  /**
   * Hook executado sempre que o modal é lançado(aberto).
   */
  useEffect(() => {
    if (forceButtonEnabled) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  /**
   * Verifica se o scroll do modal chegou até o seu ponto mais inferior.
   *
   * @param e Evento de detecção de scroll do modal.
   * @returns True ou False caso o scroll do modal tenha chegado até a parte inferior ou não.
   */
  const touchedBottom = (e) => {
    return (
      e.target.scrollHeight <=
      e.target.offsetHeight + Math.ceil(e.target.scrollTop)
    );
  };

  /**
   * Lida com o scroll do modal para verificar se atingiu o limite inferior do mesmo.
   *
   * @param e Evento de detecção de scroll do modal.
   */
  const handleScroll = (e) => {
    const bottom = touchedBottom(e);
    if (bottom) {
      setButtonDisabled(false);
    }
  };

  /**
   * Obtém o valor da altura do modal para melhor adequação de exibição.
   *
   * @param title O título do modal para detectar qual altura adotar.
   * @returns A altura do modal.
   */
  const getModalHeight = (title?: string) => {
    let modalHeight: string;

    if (style !== undefined && style.height !== "auto") {
      modalHeight = style.height;
    } else if (title === undefined || title === "Importante") {
      modalHeight = "18rem";
    } else {
      modalHeight = "40rem";
    }

    return modalHeight;
  };

  return (
    <CustomModal
      disableClose={disableClose ? true : false}
      style={{ height: getModalHeight(title) }}
      isOpen={isOpen}
      handleClose={handleClose}
      {...modalProps}
    >
      <Container className={classes.container} onScroll={handleScroll}>
        {title ? (
          <Typography
            variant="h4"
            align={centralizeTitle ? "center" : "inherit"}
          >
            {title}
          </Typography>
        ) : null}
        <div className={classes.textWrapper}>{children}</div>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.actionButton}
            variant="contained"
            color="secondary"
            onClick={buttonCallback}
            disabled={buttonDisabled}
          >
            {buttonText}
          </Button>
        </div>
      </Container>
    </CustomModal>
  );
};

export default ConfirmacaoModal;
