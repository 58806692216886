import React from 'react';
import { estadosBrasilToSelectField } from '../../../../../../utils/FormUtils';
import { Select, MenuItem, makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            backgroundColor: '#FFF',
            height: '44px',
            width: '5vw',

            '@media only screen and (max-width: 414px)': {
                width: '21vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '15vw',
            }
        }
    })
);

/**
 * Interface utilizada pelo componente SelectUf e que descreve
 * seu métodos e propriedades.
 */
interface SelectUfProps {
    value: any
    handleChange: (e) => void
}

/**
 * Componente para selecionar e filtrar UF para estados do Brasil.
 * 
 * @param {SelectUfProps} 
 * @returns O componente para seleção de estados do Brasil.
 */
const SelectUf: React.FC<SelectUfProps> = ({ value, handleChange }) => {

    const classes = useStyles();

    return (
        <Select
            displayEmpty
            value={value}
            onChange={handleChange}
            className={classes.select}
            variant='outlined'
        >
            <MenuItem value="">UF</MenuItem>
            {estadosBrasilToSelectField().map(uf => <MenuItem key={uf} value={uf}>{uf}</MenuItem>)}
        </Select>
    );

}

export default SelectUf;