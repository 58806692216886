import React from 'react'
import { useTranslation } from 'react-i18next'
import { capitalize, Grid, makeStyles, TextField } from '@material-ui/core'
import { Colaborador, Mentor, Mentorado, Mentoria } from '../../models'
import { formatMaterialUIDate } from '../../utils/FormUtils'
import MentorCard from '../mentor-card/MentorCard'
import MentoradoCard from '../mentorado-card/MentoradoCard'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        margin: '.5rem 0',
        width: '70%',

        '@media only screen and (max-width: 720px)': {
            justifyContent: 'center',
            marginTop: '2vh',
            width: '100%',
        }
    },
    rootRate: {
        display: 'flex',
        margin: '.5rem 0',
        width: '90%',
    },
    textField: {
        width: '16rem',
        margin: '2vh 0',

        '@media only screen and (max-width: 414px)': {
            marginLeft: '2vw',
            width: '70vw',
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            marginLeft: '-10vw',
            marginTop: '5vh',
            width: '41vw',
        },

        '& > div': {
            '& > input': {
                backgroundColor: 'white'
            },
            '& > fieldset': {
                borderColor: '#003B4A'
            }
        }
    },
    textFieldNoModal: {
        width: '18vw',
        margin: '2vh 1vw 2vh 3vw',

        '@media only screen and (max-width: 414px)': {
            marginLeft: '1vw',
            width: '70vw',
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            marginLeft: '1vw',
            marginTop: '5vh',
            width: '41vw',
        },

        '& > div': {
            '& > input': {
                backgroundColor: 'white'
            },
            '& > fieldset': {
                borderColor: '#003B4A'
            }
        }
    },
    textfieldBorder: {
        borderWidth: '2px',
        borderColor: '#003B4A !important',
    },
});

/**
 * Interface utilizada pelo componente NewMentoriaCard e que descreve
 * seu métodos e propriedades.
 */
interface NewMentoriaCardProps {
    mentoria?: Mentoria
    categoria?: any
    mentor?: Mentor
    mentorado?: Mentorado
    colaborador?: Colaborador
    disponibilidade?: any
    showCategoria?: boolean
    showDisponibilidade?: boolean
    role?: string
    style?: any
    isModal: boolean
}

/**
 * Componente responsável por apresentar o card de mentor ou mentorado, assim como o status da mentoria, disponibilidade e categoria.
 * 
 * @param {NewMentoriaCardProps} 
 * @returns O componente que renderiza o card de nova mentoria.
 */
const NewMentoriaCard: React.FC<NewMentoriaCardProps> = ({ mentoria, mentorado, categoria, mentor, disponibilidade, showCategoria = false, showDisponibilidade = false, role = "ROLE_MENTORADO", style, isModal }) => {
    const classes = useStyles();

    // Hooks que fazem a tradução dos textos do componente.
    const { t, i18n } = useTranslation(['agendamento-mentoria']);

    /**
     * Renderiza o card do usuário com base no papel que o mesmo posssui(i.e. mentor ou mentorado).
     * 
     * @returns O card do usuário de acordo com o seu papel.
     */
    const renderColaboradorCard = () => {
        switch (role) {
            case "ROLE_MENTORADO":
                return <MentorCard mentor={mentoria ? mentoria.mentor : mentor} style={style} showButton={false} />
            case "ROLE_MENTOR":
                return <MentoradoCard mentorado={mentoria ? mentoria.mentorado : mentorado} style={style} />
        }
    }

    return (
        isModal ? (
            <div>
                {renderColaboradorCard()}
                < div >
                    {
                        showCategoria &&
                        <TextField
                            id="filled-read-only-input-categoria"
                            className={classes.textField}
                            label={t('agendamento-mentoria:step_revisao.fields.categoria', 'Categoria')}
                            defaultValue={categoria['nome' + capitalize(i18n.language)] ? categoria['nome' + capitalize(i18n.language)] : categoria}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    notchedOutline: classes.textfieldBorder
                                }
                            }}
                            inputProps={{
                                style: {
                                    backgroundColor: 'rgba(207, 212, 218, 0.32)',
                                    fontFamily: 'InfraRegular',
                                    fontSize: '.8rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                }
                            }}
                            variant="outlined"
                        />
                    }
                </div >
                <div>
                    {
                        showDisponibilidade &&
                        <TextField
                            id="filled-read-only-input-disponibilidade"
                            className={classes.textField}
                            label={t('agendamento-mentoria:step_revisao.fields.horario', 'Horário')}
                            defaultValue={formatMaterialUIDate(disponibilidade?.data) + ` ${t('agendamento-mentoria:step_revisao.as', 'às')} ` + (disponibilidade?.hora.substring(0, 5))}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    notchedOutline: classes.textfieldBorder
                                }
                            }}
                            inputProps={{
                                style: {
                                    backgroundColor: 'rgba(207, 212, 218, 0.32)',
                                    fontFamily: 'InfraRegular',
                                    fontSize: '.8rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                }
                            }}
                            variant="outlined"
                        />
                    }
                </div>
            </div>
        ) : (
            <Grid container className={showCategoria && showDisponibilidade ? classes.root : classes.rootRate}>
                <Grid item md={6}>
                    {renderColaboradorCard()}
                </Grid>
                <Grid item md={6}>
                    <div>
                        {
                            showCategoria &&
                            <TextField
                                id="filled-read-only-input-categoria"
                                className={classes.textFieldNoModal}
                                label={t('agendamento-mentoria:step_revisao.fields.categoria', 'Categoria')}
                                defaultValue={categoria['nome' + capitalize(i18n.language)] ? categoria['nome' + capitalize(i18n.language)] : categoria}
                                InputProps={{
                                    readOnly: true,
                                    classes: {
                                        notchedOutline: classes.textfieldBorder
                                    }
                                }}
                                inputProps={{
                                    style: {
                                        backgroundColor: 'rgba(207, 212, 218, 0.32)',
                                        fontFamily: 'InfraRegular',
                                        fontSize: '.8rem',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                    }
                                }}
                                variant="outlined"
                            />
                        }
                    </div>
                    <div>
                        {
                            showDisponibilidade &&
                            <TextField
                                id="filled-read-only-input-disponibilidade"
                                className={classes.textFieldNoModal}
                                label={t('agendamento-mentoria:step_revisao.fields.horario', 'Horário')}
                                defaultValue={formatMaterialUIDate(disponibilidade?.data) + ` ${t('agendamento-mentoria:step_revisao.as', 'às')} ` + (disponibilidade?.hora.substring(0, 5))}
                                InputProps={{
                                    readOnly: true,
                                    classes: {
                                        notchedOutline: classes.textfieldBorder
                                    }
                                }}
                                inputProps={{
                                    style: {
                                        backgroundColor: 'rgba(207, 212, 218, 0.32)',
                                        fontFamily: 'InfraRegular',
                                        fontSize: '.8rem',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                    }
                                }}
                                variant="outlined"
                            />
                        }
                    </div>
                </Grid>
            </Grid>
        )
    )
}

export default NewMentoriaCard;
