/**
 * Classe que define o modelo para a categoria com todas as suas propriedades.
 */
export class Categoria {
    id: number = 0;
    nomePt: string = '';
    descricaoPt?: string = '';
    nomeEs: string = '';
    descricaoEs?: string = '';
    nomeEn: string = '';
    descricaoEn?: string = '';
    checked: boolean = false;
    imagem?: any = '';
    status?: string = '';
}