import React from 'react';
import { makeStyles } from '@material-ui/core';
import { BotaoGlobalContato, FormColaborador } from '../../../components';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        margin: '0 0',
        padding: 0,
        width: '100%',
    }
});

/**
 * Renderiza o form de cadastro do colaborador.
 * 
 * @param props Propriedades e métodos necessários
 *              para o funcionmento do componente.
 * @returns O form de cadastro do colaborador.
 */
export default function CadastroColaborador(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>

            <FormColaborador />
            <BotaoGlobalContato />
        </div>

    );
}
