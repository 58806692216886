/* eslint-disable valid-typeof */
import React from "react";
import Axios from "axios";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import {
    CustomModal,
    SpinnerSplendis,
    StatusBullet,
} from "..";
import { ColaboradorService, MentorService } from "../../service";
import { NewMentoriaCard } from "../../components";
import { capitalize, mapStatusMentoria } from "../../utils/FormUtils";
import { useTranslation } from 'react-i18next';
import {
    MENTORIA_MODAL_BOTAO_REAGENDAR,
    MENTORIA_MODAL_BOTAO_CANCELAR,
    MENTORIA_MODAL_SOURCE,
} from '../../messages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "17rem",

            '@media only screen and (max-width: 414px)': {
                width: '70vw',
            }
        },
        confirmarContainer: {
            fontFamily: "infraRegular",
        },
        button: {
            fontFamily: "Roboto",
            width: "16rem",
            margin: '0.5rem auto 1rem auto',
            color: "#003B4A",
            backgroundColor: "#fff",
            border: "1px solid #003B4A",

            "&:hover": {
                backgroundColor: "#003B4A",
                color: "#fff",
            },

            '@media only screen and (max-width: 414px)': {
                marginLeft: '2vw',
                width: '70vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                marginLeft: '-10vw',
                width: '41vw',
            }
        },
        statusContainer: {
            textAlign: "initial",
            marginLeft: "1rem",
        },
        status: {
            marginLeft: theme.spacing(1),
        },
        buttonConfirmar: {
            fontFamily: "Roboto",
            width: "100%",
            marginTop: "1rem",
            "& span": {
                height: "2rem",
            },
        },
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: 'auto',
        width: '21rem',
        textAlign: '-webkit-center',
    },
    card: {
        height: '43vh'
    }
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: 'auto',
        marginTop: '20vh',
        width: '73vw',
        textAlign: 'center',
    },
    card: {
        height: '55vh',
        width: '70vw'
    }
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: 'auto',
        marginTop: '90vh',
        textAlign: 'center',
        width: '43vw',
    },
    card: {
        height: '90vh',
        width: '42vw'
    }
};

/**
 * Interface utilizada pelo componente MentoriaModal e que descreve
 * seu métodos e propriedades.
 */
interface MentoriaModalProps {
    mentoria: any;
    isOpen: boolean;
    handleClose: Function;
    showCancelarButton?: boolean;
    cancelarMentoriaCallback?: any;
    showReagendarButton?: boolean;
    reagendarMentoriaCallback?: any;
    role: string;
}

/**
 * Componente responsável por apresentar o status da mentoria, além de permitir o reagendamento ou cancelamento da mesma.
 * 
 * @param {MentoriaModalProps} 
 * @returns O modal da mentoria com todas suas informações pertinentes.
 */
const MentoriaModal: React.FC<MentoriaModalProps> = ({
    mentoria,
    isOpen,
    handleClose,
    showCancelarButton = false,
    cancelarMentoriaCallback,
    showReagendarButton = false,
    reagendarMentoriaCallback,
    role
}) => {
    const classes = useStyles();
    const mentorService: MentorService = new MentorService();
    const colaboradorService: ColaboradorService = new ColaboradorService();

    const [mentor, setMentor] = React.useState<any>();
    const [mentorado, setMentorado] = React.useState<any>();
    const [loading, setLoading] = React.useState(true);

    const { t } = useTranslation([MENTORIA_MODAL_SOURCE]);

    const statusColors = {
        Agendada: "success",
        "Aguardando avaliação": "warning",
        Cancelada: "danger",
        Concluída: "info",
        Reagendada: "warning",
        "Não realizada": "danger",
    };

    React.useEffect(() => {
        getColaborador();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mentoria]);

    /**
     * Realiza o fetch do colaborador. Dependendo do papel do usuário
     * é feito o fetch de mentor ou mentorado para ser apresentado
     * no card de mentoria. 
     */
    const getColaborador = () => {
        if (role === "ROLE_MENTORADO") {
            getMentor();
        } else {
            getMentorado();
        }
    }

    /**
     * Obtém o mentorado conforme o papel do usuário.
     */
    const getMentorado = () => {
        if (mentoria.emailMentorado) {
            colaboradorService.getByEmail(mentoria.emailMentorado)
                .then((res) => {
                    setMentorado(res.data);
                    setLoading(false);
                });
        }
    };

    /**
     * Obtém o mentor conforme o papel do usuário.
     */
    const getMentor = () => {
        if (mentoria.mentor && mentoria.mentor.id !== 0) {
            mentorService
                .findByName(mentoria.mentor, Axios.CancelToken.source())
                .then((res) => {
                    setMentor(res.data[0]);
                    setLoading(false);
                });
        }
    };

    return (
        <CustomModal
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            <>
                {loading ? (
                    <SpinnerSplendis />
                ) : (
                    <div className={classes.container}>
                        <div className={classes.statusContainer}>
                            {capitalize(mapStatusMentoria(mentoria.status, t))}
                            <StatusBullet
                                className={classes.status}
                                color={
                                    statusColors[
                                    capitalize(
                                        mapStatusMentoria(mentoria.status, t)
                                    )
                                    ]
                                }
                                size="sm"
                            />
                        </div>

                        <NewMentoriaCard
                            mentor={mentor}
                            mentorado={mentorado}
                            showCategoria={true}
                            categoria={mentoria?.categoria}
                            showDisponibilidade={true}
                            disponibilidade={{
                                data: mentoria?.data,
                                hora: mentoria?.hora,
                            }}
                            role={role}
                            style={window.screen.width < 415 ?
                                modalStyleMobilePortrait.card :
                                (window.screen.width >= 415 && window.screen.width <= 720 ?
                                    modalStyleMobileLandscape.card :
                                    modalStyle.card)}
                            isModal={true}
                        />
                        {
                            showReagendarButton &&
                            <div>
                                <Button
                                    className={classes.button}
                                    onClick={() => reagendarMentoriaCallback()}
                                    variant="contained"
                                >
                                    {t(MENTORIA_MODAL_BOTAO_REAGENDAR, 'Reagendar Mentoria')}
                                </Button>
                            </div>
                        }
                        {
                            showCancelarButton &&
                            <div>
                                <Button
                                    className={classes.button}
                                    onClick={() => cancelarMentoriaCallback()}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t(MENTORIA_MODAL_BOTAO_CANCELAR, 'Cancelar Mentoria')}
                                </Button>
                            </div>
                        }
                    </div>
                )}
            </>
        </CustomModal>
    );
};

export default MentoriaModal;
