import React, { useCallback, useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { Avaliacao, Mentor } from "../../models";
import { AvaliacaoService, MentorService } from "../../service";
import { CustomModal, SpinnerSplendis } from "../../commons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
    AVALIACAO_MENTORIA_SOURCE,
    AVALIACAO_MENTORIA_COMENTARIOS_SUBTITULO,
    AVALIACAO_MENTORIA_DIFICULDADES_SUBTITULO,
    AVALIACAO_MENTORIA_ENVIAR,
    AVALIACAO_MENTORIA_NAO,
    AVALIACAO_MENTORIA_QUESTOES_ACONTECEU,
    AVALIACAO_MENTORIA_QUESTOES_AVALIA,
    AVALIACAO_MENTORIA_SIM,
    AVALIACAO_MENTORIA_AGUARDANDO_AVALIACAO,
    AVALIACAO_MENTORIA_AVALIACAO
} from "../../messages";
import { MentoriaPendente } from "../../models/MentoriaPendente";
import { NewMentoriaCard } from "..";
import Axios from "axios";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "17rem",

            '@media only screen and (max-width: 414px)': {
                width: '70vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '42vw',
            }
        },

        statusContainer: {
            fontWeight: 400,
            textAlign: "center",
            marginLeft: "1rem",
            fontSize: "0.875rem",
        },

        title: {
            fontWeight: 500,
            fontSize: "1.375rem",
            marginTop: "0.6rem",
            marginBottom: 0,
        },

        firstQuestion: {
            fontWeight: 300,
            fontSize: "0.875rem",
            marginBottom: "1rem",
            fontFamily: "infraRegular",
            marginTop: "1.375rem",
        },

        secondQuestion: {
            fontWeight: 300,
            fontSize: "0.875rem",
            marginBottom: "0.5rem",
            fontFamily: "infraRegular",
        },

        ratingEnabled: {
            fontWeight: 600,
            fontSize: "1.1rem",
        },

        ratingDisabled: {
            fontSize: "1.1rem",
        },

        descriptionTitle: {
            fontWeight: 300,
            fontSize: "0.875rem",
        },

        description: {
            height: "5rem",
            width: "16rem",

            '@media only screen and (max-width: 414px)': {
                width: '70vw'
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '40vw'
            }
        },

        descriptionForm: {
            paddingLeft: 0,
        },

        firstQuestionButton: {
            color: '#003B4A',
            height: '2.75rem',
            width: '7.7rem',

            '&:first-child': {
                marginRight: '0.5rem',
            },

            '@media only screen and (max-width: 414px)': {
                height: '2.5rem',
                marginLeft: '.2rem',
                width: '5.7rem',

                '&:first-child': {
                    marginRight: '.8rem',
                },
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                height: '2.5rem',
                marginLeft: '.2rem',
                width: '5.7rem',

                '&:first-child': {
                    marginRight: '.8rem',
                },
            }
        },

        secondQuestionButton: {
            backgroundColor: '#003B4A',
            color: '#fff',
            width: '100%',
            height: '2.75rem',

            "&:hover": {
                backgroundColor: "#0a3039",
              },

            '@media only screen and (max-width: 414px)': {
                marginLeft: '1vw',
                width: '72vw'
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                marginLeft: '1vw',
                width: '41vw'
            }
        },
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        fontFamily: 'infraRegular',
        height: 'auto',
        textAlign: '-webkit-center',
        width: '21rem',
    },
    card: {
        height: '19rem'
    }
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        fontFamily: 'infraRegular',
        height: 'auto',
        marginTop: '28vh',
        textAlign: 'center',
        width: '73vw',
    },
    card: {
        height: '19rem'
    }
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        fontFamily: 'infraRegular',
        height: 'auto',
        marginTop: '130vh',
        textAlign: 'center',
        width: '42vw',
    },
    card: {
        height: '19rem'
    }
};

/**
 * Interface utilizada pelo componente AvaliacaoComponent e que descreve
 * seu métodos e propriedades.
 */
interface AvaliacaoComponentProps {
    mentoria: MentoriaPendente;
    submitted: Function;
    isOpen: boolean;
    handleClose: Function;
}

/**
 * Componente para um mentorado realizar a avaliação de uma mentoria que já ocorreu.
 * 
 * @param {AvaliacaoComponentProps} 
 * @returns O componente de avaliação de mentoria.
 */
const AvaliacaoComponent: React.FC<AvaliacaoComponentProps> = ({
    mentoria,
    submitted,
    isOpen,
    handleClose,
}) => {
    const classes = useStyles();

    // Hook utilizado para tradução dos textos.
    const { t } = useTranslation([AVALIACAO_MENTORIA_SOURCE]);

    const [charCounter, setCharCounter] = React.useState(0);
    const [nota, setNota] = React.useState(0);
    const [formObject, setFormObject] = React.useState<Avaliacao>(new Avaliacao());
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [mentoriaConfirmada, setMentoriaConfirmada] = React.useState("");
    const [ratingDisabled, setRatingDisabled] = React.useState<boolean>();
    const [mentor, setMentor] = React.useState<Mentor>();
    const [activeStep, setActiveStep] = React.useState(0);

    const minLength = 10;
    const maxLength = 400;

    const avaliacaoService: AvaliacaoService = new AvaliacaoService();
    const mentorService: MentorService = new MentorService();

    /**
     * Função que realiza a validação dos campos para permitir o envio da avaliação.
     */
    const isValid = useCallback(() => {
        if (
            charCounter >= minLength &&
            charCounter <= maxLength &&
            formObject.descricao &&
            formObject.mentoriaConfirmada !== "" &&
            ((formObject.mentoriaConfirmada === "true" &&
                formObject.nota > 0) ||
                (formObject.mentoriaConfirmada === "false" &&
                    formObject.nota === 0))
        ) {
            return true;
        }
        return false;
    }, [
        charCounter,
        formObject.descricao,
        formObject.mentoriaConfirmada,
        formObject.nota,
    ]);
    

    /**
     * No momento de inicialização do componente, realiza o fetch do mentor responsável pela mentoria
     * e renderiza a primeira pergunta.
     */
    useEffect(() => {
        getMentor();
        setActiveStep(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Ativa ou desativa o botão de enviar com base na contagem de caracteres na descrição da avaliação.
     */
    useEffect(() => {
        setSubmitDisabled(!isValid());
    }, [charCounter, isValid]);

    /**
     * Obtém o mentor que será avaliado.
     */
    const getMentor = () => {
        setLoading(true);

        mentorService
            .getByName(mentoria.mentor, Axios.CancelToken.source())
            .then((res) => res.data[0])
            .then((data) => {
                setMentor(data);
                setLoading(false);
            });
    };

    /**
     * Função responsável por realizar a atualização dos states a partir
     * do nome e valor vindos do event.
     * 
     * @param event O evento disparado pelos diferentes campos da avaliação.
     * @param atributo O nome do campo da avaliação.
     * @param valor O valor do referido campo da avaliação.
     */
    function handleChange(
        event,
        atributo: any = event.target.name,
        valor: any = event.target.value
    ) {
        let oldFormObject = formObject;

        if (atributo === "nota") {
            oldFormObject[atributo] = parseInt(valor);
        } else {
            oldFormObject[atributo] = valor;
        }

        setFormObject(oldFormObject);
        setCharCounter(formObject.descricao.length);
        setMentoriaConfirmada(formObject.mentoriaConfirmada);

        if (formObject.mentoriaConfirmada === "false") {
            setNota(0);
            oldFormObject["nota"] = 0;
            setFormObject(oldFormObject);
            setRatingDisabled(true);
        } else {
            setRatingDisabled(false);
            setNota(formObject.nota);
        }
    }

    /**
     * Função que realiza o envio da atualização para o backend.
     */
    function handleSubmit() {
        setLoading(true);
        setSubmitDisabled(true);

        let newFormObj = formObject;

        newFormObj.mentorId = mentor ? mentor.id : mentoria.mentorId;
        newFormObj.mentoriaId = mentoria.mentoriaId;
        newFormObj.mentoradoId = mentoria.mentoradoId;

        avaliacaoService
            .salvar(newFormObj)
            .then((res) => submitted())
            .catch((err) => {
                setTimeout(() => setSubmitDisabled(!isValid()), 1000);
            })
            .then(() => {
                setLoading(false);
            });
    }

    /**
     * Exibe um label para descrição com base no status da avaliação.
     * 
     * @returns O label da descrição.
     */
    const descricaoLabelStay = (): string => {
        switch (mentoriaConfirmada) {
            case "true":
                return t(
                    AVALIACAO_MENTORIA_COMENTARIOS_SUBTITULO,
                    "Cite o que você mais gostou, quais são suas expectativas..."
                );
            case "false":
                return t(
                    AVALIACAO_MENTORIA_DIFICULDADES_SUBTITULO,
                    "Relate dificuldades de acesso, contato com o mentor..."
                );

            default:
                return t(
                    AVALIACAO_MENTORIA_COMENTARIOS_SUBTITULO,
                    "Cite o que você mais gostou, quais são suas expectativas..."
                );
        }
    };

    /**
     * Função responsável por apresentar as próximas perguntas após a primeira ser respondida.
     * 
     * @param event O evento de confirmação da primeira resposta da avaliação.
     * @param confirmada A confirmação da ocorrência da primeira pergunta(i.e. se a mentoria ocorreu).
     */
    const handleMentoriaConfirmada = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        confirmada: string
    ) => {
        handleChange(event, "mentoriaConfirmada", confirmada);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /**
     * Renderiza(exibe) a primeira pergunta da avaliação.
     * 
     * @returns A primeira pergunta da avaliação, com botões para confirmar ou não a sua ocorrência.
     */
    const renderFirstQuestion = () => {
        return (
            <div>
                <Typography variant="body2" className={classes.firstQuestion}>
                    {t(
                        AVALIACAO_MENTORIA_QUESTOES_ACONTECEU,
                        "A mentoria aconteceu?"
                    )}
                </Typography>

                <div>
                    <Button
                        variant="outlined"
                        size="large"
                        onClick={(e) => handleMentoriaConfirmada(e, "false")}
                        className={classes.firstQuestionButton}
                    >
                        {t(AVALIACAO_MENTORIA_NAO, "Não")}
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={(e) => handleMentoriaConfirmada(e, "true")}
                        className={classes.firstQuestionButton}
                        style={{color:'white', backgroundColor: '#003B4A'}}
                    >
                        {t(AVALIACAO_MENTORIA_SIM, "Sim")}
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Renderiza(exibe) a segunda pergunta da avaliação.
     * 
     * @returns A segunda pergunta juntamente com o campo de descrição e o botão de enviar.
     */
    const renderLastQuestions = () => {
        return (
            <div>
                <Box
                    component="fieldset"
                    mb={0}
                    pb={0}
                    borderColor="transparent"
                >
                    <Typography
                        variant="body2"
                        className={classes.secondQuestion}
                    >
                        {t(
                            AVALIACAO_MENTORIA_QUESTOES_AVALIA,
                            "Como você avalia essa experiência?"
                        )}
                    </Typography>

                    <Rating
                        name="nota"
                        onChange={(event) => {
                            handleChange(event);
                        }}
                        value={nota}
                        disabled={ratingDisabled}
                    />
                </Box>
                <Box
                    component="fieldset"
                    mb={0}
                    pl={0}
                    borderColor="transparent"
                >
                    <form
                        id="descricaoForm"
                        className={classes.descriptionForm}
                        autoComplete="on"
                    >
                        <textarea
                            defaultValue=""
                            name="descricao"
                            onChange={handleChange}
                            className={classes.description}
                            rows={4}
                            style={{ resize: "none" }}
                            maxLength={maxLength}
                            placeholder={descricaoLabelStay()}
                            required
                            form="descricaoForm"
                        ></textarea>
                    </form>
                </Box>
                {loading ? (
                    <SpinnerSplendis />
                ) : (
                    <Button
                        disabled={submitDisabled}
                        color="primary"
                        variant="contained"
                        className={classes.secondQuestionButton}
                        onClick={handleSubmit}
                    >
                        {t(AVALIACAO_MENTORIA_ENVIAR, "Enviar")}
                    </Button>
                )}
            </div>
        );
    };

    /**
     * Função que lida com a renderização das perguntas de avaliação.
     * 
     * @param stepIndex O step que determina qual pergunta renderizar.
     */
    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return renderFirstQuestion();
            case 1:
                return renderLastQuestions();
        }
    };

    /**
     * Lida com o fechamento do modal e o step ativo, o qual determina qual pergunta exibir.
     */
    const handleCloseModal = () => {
        setActiveStep(0);
        handleClose();
        let oldFormObject = formObject;
        oldFormObject["descricao"] = '';
        setFormObject(oldFormObject);
    }

    return (
        <CustomModal
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            isOpen={isOpen}
            handleClose={handleCloseModal}
        >
            <>
                <div className={classes.container}>
                    <div className={classes.statusContainer}>
                        {t(AVALIACAO_MENTORIA_AGUARDANDO_AVALIACAO, 'Aguardando Avaliação')}
                    </div>

                    <NewMentoriaCard mentor={mentor} role={"ROLE_MENTORADO"} style={modalStyle.card} isModal={true} />
                    <Typography variant="h4" className={classes.title}>
                        {t(AVALIACAO_MENTORIA_AVALIACAO, 'Avaliação')}
                    </Typography>

                    {getStepContent(activeStep)}
                </div>
            </>
        </CustomModal>
    );
};

export default AvaliacaoComponent;
