/**
 * Classe que define o modelo para a disponibilidade com todas as suas propriedades.
 */
export class Disponibilidade {
  id?: string;
  dia: string = "";
  hora: string = "";
  ordinal?: number = 0;
  checked?: boolean = false;
  intervaloSemanas: number = 0;
  dataInicio: string = "";
}
