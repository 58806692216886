import React, { useEffect } from 'react';
import { Container, List, makeStyles, Typography } from '@material-ui/core';
import { SpinnerSplendis } from '../../../../../../../commons';
import IdiomaCard from './components/idioma-card/IdiomaCard';

import BrazilFlag from '../../../../../../../assets/flags/brasil.png';
import SpainFlag from '../../../../../../../assets/flags/espanha.png';
import UsaFlag from '../../../../../../../assets/flags/eua.png';
import GerFlag from '../../../../../../../assets/flags/germany.png';
import PolFlag from '../../../../../../../assets/flags/poland.png';
import ChiFlag from '../../../../../../../assets/flags/china.png';
import RomFlag from '../../../../../../../assets/flags/romania.png';
import FraFlag from '../../../../../../../assets/flags/france.png';
import { useTranslation } from 'react-i18next';
import {
    SE_TORNAR_MENTOR_SOURCE,
    SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_PARTE_1,
    SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_PARTE_2,
    SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_PARTE_1,
    SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_PARTE_2,
    SE_TORNAR_MENTOR_STEP_IDIOMAS_SEM_IDIOMAS,
    SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_IDIOMAS
} from '../../../../../../../messages';

const useStyles = makeStyles({
    root: {
        height: '100%',
        maxWidth: '80%',
    },
    
    list: {
        alignSelf: 'center',
        backgroundColor: 'rgba(207, 212, 218, 0.32)',
        padding: '1rem 1rem',
        margin: '4.5rem auto 0 auto',
        width: '80%',

        '@media only screen and (max-width: 414px)': {
            margin: '1rem auto 0 auto',
            width: '80%'
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            margin: '1rem auto 0 auto',
        }
    },
    container: {
        height: '50vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'start',

        '@media only screen and (max-width: 414px)': {
            float: 'left'
        },
    },
    subtitle: {
        fontFamily: 'InfraRegular',
        fontSize: '.8rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '21px',

        '@media (max-width: 1024px)': {
            padding: 0,
            fontSize: '0.8rem',
            margin: 5,
        }
    },
});

/**
 * Seleção de idiomas pelo mentor no processo de se tornar mentor.
 * 
 * @param props {idiomas}
 * @returns O passo de seleção de idiomas.
 */
export default function IdiomasComponent(props) {
    const classes = useStyles();

    /**
     * Hook e função utilizados na tradução de textos.
     */
    const { t } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);

    const [idiomasStatus, setIdiomasStatus] = React.useState(props.idiomas);
    const { isLoading, isDisabled, setIdiomas, style } = props;

    const NUMERO_MAXIMO_IDIOMAS: number = 3;

    const iconesIdiomas = {
        pt: BrazilFlag,
        es: SpainFlag,
        en: UsaFlag,
        de:GerFlag,
        fr:FraFlag,
        cn:ChiFlag,
        pl:PolFlag,
        ro:RomFlag,
        
    };

    /**
     * Verifica, ao carregar o componente, se a seleção de idiomas está correta .
     */
    useEffect(() => {
        isDisabled(isNotValid);
    });

    /**
     * Verifica se o número de idiomas selecionados é maior que o máximo de 3 permitidos,
     * ou se esse número é igual a zero.
     * 
     * @returns True caso o número de idiomas selecionados seja maior que o número máximo
     *          de 3 permitidos ou se é igual a zero; False caso contrário.
     */
    const isNotValid = () => {
        let valor = idiomasStatus.filter(cat => { return cat.checked }).length;
        return valor > NUMERO_MAXIMO_IDIOMAS || valor === 0;
    }

    /**
     * Obtém o índice de um  idioma no array idiomasStatus.
     * 
     * @param id O id do idioma no array.
     * @returns O índice o idioma no array, ou -1 caso contrário.
     */
    const getIndexById = (id: any) => {
        for (let i = 0; i < idiomasStatus.length; i++) {
            if (id === idiomasStatus[i].id) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Obtém o total de idiomas selecionados.
     * 
     * @returns O totla de idiomas selecionados.
     */
    const numeroIdiomasSelecionados = () => {

        let total = 0;

        for (let i = 0; i < idiomasStatus.length; i++) {
            if (idiomasStatus[i].checked) {
                total += 1;
            }
        }
        return total;
    }

    /**
     * Gerencia a mudança dos states de idioma e quais
     * idiomas foram selecionadas.
     * 
     * @param e O evento que disapara a mudança nos states.
     * @param id O id do idioma.
     */
    const handleChange = (e: any, id: any) => {
        let stateAntigo = idiomasStatus;

        let index = getIndexById(id);

        stateAntigo[index].checked = !stateAntigo[index].checked;

        setIdiomasStatus(stateAntigo);

        isDisabled(isNotValid);
        setIdiomas(e, props.atributoForm, stateAntigo);
    }

    /**
     * Ordena os idiomas por ordem alfabética.
     * 
     * @param languageA O primeiro idioma a ser comparado.
     * @param languageB O segundo idioma a ser comparado.
     * @returns -1 caso a ordem alfabética do primeiro idioma seja
     *          menor que a ordem alfabética do segundo idioma, 1
     *          caso a ordem alfabética do primeiro idioma seja maior
     *          que a ordem alfabética do segundo idioma, zero caso
     *          as ordem alfabéticas dos dois idiomas sejam iguais.
     */
    const sortLanguages = (languageA: { nome: string, sigla: string }, languageB: { nome: string, sigla: string }) => {
        const languageOne = languageA.nome.toLowerCase();
        const languageTwo = languageB.nome.toLowerCase();

        if (languageOne < languageTwo) {
            return -1;
        } else if (languageOne > languageTwo) {
            return 1;
        }

        return 0;
    }

    /**
     * Renderiza a lista de idiomas.
     */
    const idiomas = idiomasStatus.length === 0 ?
        <h3>{t(SE_TORNAR_MENTOR_STEP_IDIOMAS_SEM_IDIOMAS, 'Não há idiomas cadastrados')}</h3>
        :
        idiomasStatus.sort(sortLanguages).map((idioma, index) =>

            <div key={index}>
                <IdiomaCard imagem={iconesIdiomas[idioma.sigla]} idioma={idioma} onClick={handleChange} selected={idioma.checked} disabled={(!idioma.checked && numeroIdiomasSelecionados() === NUMERO_MAXIMO_IDIOMAS)} />
            </div>

        );

    return (
        <Container className={classes.root}>
            {style ?
                <Typography style={style.subtitle}>
                    {t(SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_PARTE_1, "Escolha um dos ")}
                    <span><strong>{t(SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_IDIOMAS, 'idiomas')}</strong></span>
                    {t(SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_PARTE_2, " abaixo para filtrar a busca por mentores no próximo passo.")}
                </Typography> :
                <Typography className={classes.subtitle} >
                    {t(SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_PARTE_1, "Escolha em quais ")}
                    <span><strong>{t(SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_AGENDAMENTO_IDIOMAS, 'idiomas')}</strong></span>
                    {t(SE_TORNAR_MENTOR_STEP_IDIOMAS_SUBTITULO_PARTE_2, " você se sente confortável para compartilhar seus conhecimentos!")}
                </Typography>
            }

            <div className={classes.container}>
                <List className={classes.list}>
                    {isLoading ? <SpinnerSplendis /> : idiomas}
                </List>
            </div>

        </Container>);
}