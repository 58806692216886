/**
 * Classe que define o modelo para o colaborador com todas as suas propriedades.
 */
export class Colaborador {
  id?: number;
  email: string = "";
  cargo?: string = "";
  nome?: string = "";
  uf?: string = "";
  status?: string = "";
  foto?: string = "";
  timeZone?: string = "";
  roles?: string[] = [];
  pais: string = "";
}
