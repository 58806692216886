import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

import { alertContext, MentoriaTable } from "../../../../components";
import { MentoriaService, SessionStorageService, ColaboradorService } from "../../../../service";
import { ModalCancelamento, ModalSucesso, SpinnerSplendis } from "../../../../commons";
import { MentoradoDashboardCard } from "./components";
import { ShowTranslationWarning } from "../../../../utils/FormUtils";
import {
	HOME_MENTORADO_SOURCE,
	HOME_MENTORADO_MODAL_REAGENDAMENTO_SUCESSO_TITULO,
	HOME_MENTORADO_MODAL_REAGENDAMENTO_SUCESSO_SUBTITULO,
	HOME_MENTORADO_REAGENDAR_MENTORIA_SUCESSO,
	HOME_MENTORADO_MODAL_CANCELAMENTO_MENTORIA_SUCESSO_TITULO,
	HOME_MENTORADO_MODAL_CANCELAMENTO_MENTORIA_SUCESSO_SUBTITULO,
	HOME_MENTORADO_CANCELAR_MENTORIA_SUCESSO,
	HOME_MENTORADO_CANCELAR_MENTORIA_FALHA,
	HOME_MENTORADO_AGENDA_TITULO
} from "../../../../messages";
import { MentoriaFuturaMentorado } from "../../../../models/MentoriaFuturaMentorado";
import CancelarMentoriaModal from "../../geral/gerenciamento-mentorias/modals/CancelarMentoriaModal";
import ReagendarMentoriaModal from "../../geral/gerenciamento-mentorias/modals/ReagendarMentoriaModal";
import { Mentoria } from "../../../../models";

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			height: '80vh',
			margin: '0 auto',
		},
		flexContainer: {
			display: 'flex',
			maxHeight: '100%',

			'@media only screen and (max-width: 414px)': {
				display: 'block',
				width: '100%',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				display: 'block',
				marginTop: '12vh',
				width: '100%',
			},
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			height: '90%',
			justifyContent: 'space-between',
			margin: '5rem auto 0 auto',
			width: '90%',

			'@media only screen and (max-width: 414px)': {
				marginRight: 0,
				width: '100%',
			},

			'@media only screen and (max-width: 1024px)': {
				fontSize: '0.6rem',
				width: '100%',
			},
		},
		title: {
			fontWeight: 600,
			"@media only screen and (max-width: 1024px)": {
				fontSize: "1rem",
			},
		},
		subtitle: {
			"@media only screen and  (max-width: 1024px)": {
				fontSize: "0.8rem",
			},
		},
		modal: {
			margin: "4rem auto",
			textAlign: "center",
			width: "75rem",
		},
		agendaTitle: {
			color: '#030624',
			float: 'left',
			fontFamily: 'InfraRegular',
			fontSize: '26px',
			fontStyle: 'normal',
			lineHeight: '39px',
			margin: '-7vh 0 2vh 0',

			'@media only screen and (max-width: 414px)': {
				fontSize: '20px',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				fontSize: '22px',
			},
		},
		mentoreeTable: {
			height: '15.1rem',

			'@media only screen and (max-width: 414px)': {
				width: '90vw',
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				display: 'block',
				width: '92vw',
			},
		}
	})
);

/**
 * Interface utilizada pelo componente MentoradoDashboard e que descreve
 * seu métodos e propriedades.
 */
interface MentoradoDashboardProps {
	idMentorado: any;
	idMentor: any;
}

/**
 * 
 * @param {MentoradoDashboardProps}
 * @returns O dashboard do mentorado com o card do mentorado e suas informações,
 * 			e a tabela com as próximas mentorias a serem realizadas.
 */
const MentoradoDashboard: React.FC<MentoradoDashboardProps> = ({ idMentorado, idMentor }) => {
	const classes = useStyles();

	/**
	 * Hook e função utilizados na tradução dos textos.
	 */
	const { t } = useTranslation([HOME_MENTORADO_SOURCE]);
	
	const { showAlert } = useContext(alertContext);

	const mentoriaService: MentoriaService = new MentoriaService();
	const colaboradorService: ColaboradorService = new ColaboradorService();

	const [colaborador, setColaborador] = useState();
	const [mentoriasFuturas, setMentoriasFuturas] = useState<MentoriaFuturaMentorado[]>([]);

	// States de Cancelamento
	const [mentoriaACancelar, setMentoriaACancelar] = React.useState<any>();
	const [isCancelamentoMentoriaModalOpen, setIsCancelamentoMentoriaModalOpen] = React.useState<boolean>(false);
	const [isSucessoCancelamentoModalOpen, setSucessoCancelamentoModalOpen] = React.useState<boolean>(false);

	const [isMentoriasFuturasLoading, setMentoriasFuturasLoading] = useState<boolean>(false);

	// States de Reagendamento
	const [mentoriaAReagendar, setMentoriaAReagendar] = React.useState<any>(new Mentoria());
	const [isReagendarMentoriaModalOpen, setIsReagendarMentoriaModalOpen] = React.useState<boolean>(false);
	const [isSucessoReagendarMentoriaModalOpen, setIsSucessoReagendarMentoriaModalOpen] = React.useState<boolean>(false);
 
	/**
	 * Realiza requisição para buscar mentorias futuras com base no id do usuário logado.
	 */
	useEffect(() => {
		setMentoriasFuturasLoading(true);
		mentoriaService
			.getMentoriasFuturasMentorado(idMentorado)
			.then((res) => {
				setMentoriasFuturas(res.data);
			})
			.then(() => setMentoriasFuturasLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Obtém as informações do colaborador logado e configura
	 * o state de colaborador.
	 */
	useEffect(() => {
		let emailColaborador = SessionStorageService.getStorage("usuario");
		colaboradorService.getById(emailColaborador).then((res) => {
			setColaborador(res.data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Atualiza a lista de mentorias futuras configurando
	 * o state de mentorias futuras.
	 */
	const updateMentoriasFuturas = () => {
		setMentoriasFuturasLoading(true);
		mentoriaService.getMentoriasFuturasMentorado(idMentorado)
			.then((res) => {
				setMentoriasFuturas(res.data);
			})
			.then(() => setMentoriasFuturasLoading(false));
	};

	/**
	 * Cancela uma mentoria, além de realizar chamada para
	 * atualizar a lista de mentorias futuras.
	 * 
	 * @param id O id da mentoria a ser cancelada.
	 */
	const cancelarMentoria = (id) => {
		mentoriaService
			.cancelar(id)
			.then(() => {
				closeCancelarMentoriaModal();
				updateMentoriasFuturas();
				openSucessoCancelamentoMentoriaModal();
				showAlert(200, t(HOME_MENTORADO_CANCELAR_MENTORIA_SUCESSO, 'Cancelamento bem sucedido!'));
			})
			.catch(() =>
				showAlert(
					400,
					t(HOME_MENTORADO_CANCELAR_MENTORIA_FALHA, 'Não foi possível cancelar a mentoria. Por favor, tente novamente')
				)
			);
	};

	/**
	 * Abre o modal de cancelamento de mentoria e configura
	 * o state de mentoria a cancelar e o state que verifica
	 * se o modal de cancelamento está aberto.
	 * 
	 * @param mentoria A mentoria a ser cancelada.
	 */
	const openCancelarMentoriaModal = (mentoria) => {
		setMentoriaACancelar(mentoria);
		setIsCancelamentoMentoriaModalOpen(true);
	};

	/**
	 * Fecha o modal de cancelamento de mentoria e configura
	 * o state de mentoria a cancelar e o state que verifica
	 * se o modal de cancelamento está aberto.
	 */
	const closeCancelarMentoriaModal = () => {
		setMentoriaACancelar(null);
		setIsCancelamentoMentoriaModalOpen(false);
	};

	/**
	 * Abre o modal de sucesso de cancelamento de mentoria configurando
	 * o state do modal de sucesso de cancelamento.
	 */
	const openSucessoCancelamentoMentoriaModal = () => {
		setSucessoCancelamentoModalOpen(true);
	};

	/**
	 * Fecha o modal de sucesso de cancelamento de mentoria configurando
	 * o state do modal de sucesso de cancelamento.
	 */
	const closeSucessoCancelamentoMentoriaModal = () => {
		setSucessoCancelamentoModalOpen(false);
	};

	/**
	 * Renderiza o modal de cancelamento de mentoria.
	 * 
	 * @param mentoria A mentoria a ser cancelada.
	 * @param role O papel do usuário logado.
	 * @returns O modal de cancelamento de mentoria.
	 */
	const cancelarMentoriaModal = (mentoria, role) => {
		if (mentoria) {
			return (
				<CancelarMentoriaModal
					mentoria={mentoria}
					isOpen={isCancelamentoMentoriaModalOpen}
					handleClose={closeCancelarMentoriaModal}
					cancelarMentoriaCallback={(idMentoria) =>
						cancelarMentoria(idMentoria)
					}
					role={role}
				/>
			);
		}
	};

	/**
	 * Renderiza o modal de sucesso de cancelamento de mentoria.
	 * 
	 * @returns O modal de sucesso de cancelamento de mentoria.
	 */
	const sucessoCancelamentoMentoriaModal = () => {
		return (
			<ModalCancelamento
				isOpen={isSucessoCancelamentoModalOpen}
				handleClose={closeSucessoCancelamentoMentoriaModal}
				onConfirm={closeSucessoCancelamentoMentoriaModal}
				title={t(HOME_MENTORADO_MODAL_CANCELAMENTO_MENTORIA_SUCESSO_TITULO, 'Mentoria cancelada')}
				subtitle={t(HOME_MENTORADO_MODAL_CANCELAMENTO_MENTORIA_SUCESSO_SUBTITULO, 'Foi enviado para você e o mentor, um email de cancelamento deste evento!')}
			/>
		);
	};

	/**
	 * Reagenda uma mentoria e também realiza outras atividades
	 * (e.g. realiza chamada para atualização da lista de mentorias
	 * futuras, abre o modal de sucesso de reagendamento de mentoria.)
	 * 
	 * @param id O id da mentoria a ser reagendada.
	 * @param mentoria A mentoria a ser reagendada.
	 * @returns Promise com o resultado do reagendamento.
	 */
	const reagendarMentoria = (id, mentoria) => {
		return mentoriaService.reagendar(id, mentoria).then((res) => {
			closeReagendarMentoriaModal();
			openSucessoReagendarMentoriaModal();
			updateMentoriasFuturas();
			showAlert(200, t(HOME_MENTORADO_REAGENDAR_MENTORIA_SUCESSO, 'Reagendamento bem sucedido!'));
			return res;
		})
			.catch((err) => closeReagendarMentoriaModal())
	};

	/**
	 * Abre o modal de reagendar mentoria e configura o state
	 * de mentoria a reagendar e o state que verifica se o
	 * modal de reagendar mentoria está aberto.
	 * 
	 * @param mentoria 
	 */
	const openReagendarMentoriaModal = (mentoria) => {
		setMentoriaAReagendar(mentoria);
		setIsReagendarMentoriaModalOpen(true);
	};

	/**
	 * Fecha o modal de reagendar mentoria e configura o state
	 * que verifica se o modal de reagendar mentoria está aberto.
	 */
	const closeReagendarMentoriaModal = () => {
		setIsReagendarMentoriaModalOpen(false);
	};

	/**
	 * Abre o modal de sucesso de reagendamento de mentoria.
	 */
	const openSucessoReagendarMentoriaModal = () => {
		setIsSucessoReagendarMentoriaModalOpen(true);
	};

	/**
	 * Fecha o modal de sucesso de reagendamento de mentoria.
	 */
	const closeSucessoReagendarMentoriaModal = () => {
		setIsSucessoReagendarMentoriaModalOpen(false);
	};

	/**
	 * Renderiza o modal de reagendar mentoria.
	 * 
	 * @param mentoria A mentoria a ser reagendada.
	 * @returns O modal de reagendar mentoria.
	 */
	const reagendarMentoriaModal = (mentoria) => {
		if (mentoria) {
			return (
				<ReagendarMentoriaModal
					isOpen={isReagendarMentoriaModalOpen}
					handleClose={closeReagendarMentoriaModal}
					mentoria={mentoria}
					role={'ROLE_MENTORADO'}
					reagendarMentoriaCallback={(id, mentoria) => {
						reagendarMentoria(id, mentoria);
					}}
				/>
			);
		}
	};

	/**
	 * Renderiza o modal de sucesso de reagendamento de mentoria.
	 * 
	 * @returns O modal de sucesso de reagendamento de mentoria.
	 */
	const sucessoReagendamentoMentoriaModal = () => {
		return (
			<ModalSucesso
				isOpen={isSucessoReagendarMentoriaModalOpen}
				handleClose={closeSucessoReagendarMentoriaModal}
				title={t(HOME_MENTORADO_MODAL_REAGENDAMENTO_SUCESSO_TITULO, 'Mentoria Reagendada com Sucesso!')}
				subtitle={t(HOME_MENTORADO_MODAL_REAGENDAMENTO_SUCESSO_SUBTITULO, 'Os envolvidos já foram notificados sobre o reagendamento')}
			/>
		);
	};

	/**
	 * Renderiza as chamadas aos modais do componente.
	 * 
	 * @returns Um fragment que renderiza todos os modais.
	 */
	const modais = () => {
		return (
			<React.Fragment>
				{reagendarMentoriaModal(mentoriaAReagendar)}
				{sucessoReagendamentoMentoriaModal()}
				{cancelarMentoriaModal(mentoriaACancelar, 'ROLE_MENTORADO')}
				{sucessoCancelamentoMentoriaModal()}
			</React.Fragment>
		);
	};

	return (
		<Container className={classes.root}>
			{/*TEMPORÁRIO*/}
			<ShowTranslationWarning />
			{modais()}

			<div id='flex-container' className={classes.flexContainer}>
				<MentoradoDashboardCard colaborador={colaborador} id={idMentor} />
				<div className={classes.content}>
					<div>
						<Typography className={classes.agendaTitle}>{t(HOME_MENTORADO_AGENDA_TITULO, 'Agenda')}</Typography>
						<div className={classes.mentoreeTable}>
							{isMentoriasFuturasLoading ? (
								<div style={{ alignSelf: 'center' }}>
									<SpinnerSplendis />
								</div>
							) : (
								<MentoriaTable
									asRole='ROLE_MENTORADO'
									mentorias={mentoriasFuturas}
									cancelable
									reschedulable
									cancelarMentoriaCallback={(mentoria) =>
										openCancelarMentoriaModal(mentoria)
									}
									reagendarMentoriaCallback={
										openReagendarMentoriaModal
									}
									enableFirstOnFocus
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}

export default MentoradoDashboard;
