import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Checkbox,
  makeStyles,
  Theme,
  createStyles,
  Typography,
} from "@material-ui/core";

import { Disponibilidade } from "../../../../../../../models";
import { mapWeekDays } from "../../../../../../../utils/FormUtils";
import { useTranslation } from "react-i18next";

import {
  SE_TORNAR_MENTOR_SOURCE,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SEM_DIAS_DISPONIVEIS,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_SUBTITULO,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_DIAS_SEMANA,
} from "../../../../../../../messages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      maxWidth: "100%",
    },
    container: {
      alignContent: "center",
      display: "flex",
      flexDirection: "column",
      height: "50vh",
      width: "100%",
    },
    gridContainer: {
      alignItems: "flex-start",
      justifyContent: "space-between",
      width: "100%",
    },
    titulo: {
      fontFamily: "InfraRegular",
      fontSize: ".9rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      marginBottom: "1rem",
    },
    dayLabel: {
      fontFamily: "InfraRegular",
      fontSize: ".9rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",

      "@media (max-width: 1024px)": {
        fontSize: "0.8rem",
      },
    },
  })
);

/**
 * Interface utilizada pelo componente DiasComponent e que descreve
 * seu métodos e propriedades.
 */
interface DiasComponentProps {
  setDisponibilidade: Function;
  atributoForm: string;
  diasDisponiveis: Array<Disponibilidade>;
}

/**
 * Passo de seleção de disponibilidades para mentor.
 *
 * @param props { isLoading, setDisponibilidade, atributoForm, isDisabled, diasDisponiveis}
 */
const DiasComponent: React.FC<DiasComponentProps> = ({
  setDisponibilidade,
  atributoForm,
  diasDisponiveis,
}) => {
  const classes = useStyles();
  const { t } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);

  const [diasStatus, setDiasStatus] = React.useState<Array<Disponibilidade>>(
    []
  );

  /**
   * Ao carregar o compoente, verifica o status de cada dia da semana útil.
   */
  if (
    diasDisponiveis.every((day) => typeof day !== "string") &&
    diasStatus.length === 0
  ) {
    diasDisponiveis.forEach((dia) => {
      if (!diasStatus.includes(dia)) {
        diasStatus.push(dia);
      }
    });
    setDiasStatus(diasStatus);
  }

  /**
   * Verifica o status dos dias da semana útil sempre que o state
   * diasStatus mudar.
   */
  useEffect(() => {
    setDiasStatus([]);
    if (diasDisponiveis.every((day) => typeof day === "string")) {
      diasDisponiveis.forEach((day, index) => {
        if (index < 5) {
          diasStatus.push(new Disponibilidade());
          diasStatus[index].dia = day.dia;
        }
      });
    } else {
      diasDisponiveis.forEach((day) => {
        if (!diasStatus.includes(day)) {
          diasStatus.push(day);
        }
      });
    }
    setDiasStatus(diasStatus);
  }, [diasDisponiveis, diasStatus]);

  /**
   * Gerencia a mudança nos states de disponbilidades e de dias.
   *
   * @param event O evento que dispara a mudança nos states.
   * @param index O índice do dia escolhido no array de dias.
   */
  const handleChange = (event: any, index: any) => {
    let oldDiasStatus = diasStatus;
    oldDiasStatus[index].checked = !oldDiasStatus[index].checked;
    oldDiasStatus[index].ordinal = index + 2;

    if (!oldDiasStatus[index].checked) {
      oldDiasStatus[index].hora = "";
      oldDiasStatus[index].dataInicio = "";
      oldDiasStatus[index].intervaloSemanas = 0;
    }

    setDiasStatus(oldDiasStatus);
    setDisponibilidade(event, atributoForm, oldDiasStatus);
  };

  /**
   * Renderiza as disponibilidades.
   */
  const disponibilidades =
    diasStatus.length === 0 ? (
      <h3>
        {t(
          SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SEM_DIAS_DISPONIVEIS,
          "Não há dias disponíveis"
        )}
      </h3>
    ) : (
      <div>
        <Typography className={classes.titulo}>
          {t(
            SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_SUBTITULO,
            "Selecione até duas opções"
          )}
        </Typography>
        <Grid container direction="row" className={classes.gridContainer}>
          {diasStatus.map((item, index) => {
            return (
              <Grid item key={item.dia}>
                <label key={item.dia}>
                  <Checkbox
                    defaultChecked={item.checked ?? false}
                    onChange={(e) => handleChange(e, index)}
                    value={item.dia}
                    color="primary"
                  />
                </label>

                <span className={classes.dayLabel}>
                  {" "}
                  {t(
                    `${SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_DIAS_SEMANA}.${item.dia}`,
                    mapWeekDays(item.dia, t)
                  )}{" "}
                </span>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );

  return <Container className={classes.root}>{disponibilidades}</Container>;
};

export default DiasComponent;
