import * as Yup from 'yup';
import { formatFromMaterialUIDate, getDateISO } from '../../../../utils/FormUtils';

/**
 * Schema de validação para o reagendamento de mentoria.
 */
export const ReagendamentoSchema = (minDate, maxDate) => Yup.object().shape({
    hora: Yup.string().required("Campo Obrigatório"),
    data: Yup.date().min(minDate, `Deve ser posterior a ${formatFromMaterialUIDate(getDateISO(new Date()))}`).max(maxDate, `Deve ser anterior a ${formatFromMaterialUIDate(maxDate)}`).required("Campo Obrigatório")
});

