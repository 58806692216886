import React, { useEffect, memo } from 'react';
import { FormControl, makeStyles, createStyles, Theme } from '@material-ui/core';
import { FormikTextField } from 'formik-material-fields';
import { Formik } from 'formik';
import { DataFilterSchema } from './validations';
import { getDateISO } from '../../../../../../utils/FormUtils';
import { useTranslation } from 'react-i18next';
import { RH_DATA_FINAL_LABEL, RH_DATA_INICIAL_LABEL, RH_SOURCE } from '../../../../../../messages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            maxWidth: 'auto',
            width: '90%'
        },

        dateField: {
            width: '40%',
        },

        textField: {
            marginRight: '.5vw',
            width: '15.5vw',

            '@media only screen and (max-width: 414px)': {
                width: '40vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '32vw',
            }
        }
    })
);

/**
 * Interface utilizada pelo componente DataRange e que descreve
 * seu métodos e propriedades.
 */
interface DateRangeProps {
    handleIsValid: (valid) => void
    handleChange: (e) => void
    values: any
}

/**
 * Componente para renderizar e validar campos de data.
 * 
 * @param {DataRangeProps}
 * @returns O componente de data com os campos de data inicial e data final.
 */
const DateRange: React.FC<DateRangeProps> = ({ handleIsValid, handleChange, values }) => {
    const classes = useStyles();

    // Hook utilizado para tradução dos textos.
    const { t } = useTranslation([RH_SOURCE]);

    const DATA_MINIMA_BUSCA = getDateISO(new Date('2019-01-01'));

    let formValidation = false;

    /**
     * Lida com a validação das datas.
     * 
     * @param valid A validação das datas obtida através do Formik.
     */
    const handleValidation = (valid) => {
        handleIsValid(valid);
    }

    useEffect(() => {
        return () => handleValidation(formValidation);
    });

    return (
        <FormControl>
            <Formik
                validateOnMount={true}
                initialValues={{
                    dataInicial: values.dataInicial,
                    dataFinal: values.dataFinal
                }}
                validationSchema={DataFilterSchema(t, DATA_MINIMA_BUSCA)}
                onSubmit={() => { }}
            >
                {({ isValid }) => {
                    formValidation = isValid;
                    return (
                        <div className={classes.inputsContainer}>
                            <div className={classes.dateField}>
                                <FormikTextField
                                    id="data-inicio"
                                    name="dataInicial"
                                    type="date"
                                    onChange={handleChange}
                                    label={t(RH_DATA_INICIAL_LABEL, "Data Inicial")}
                                    inputProps={
                                        {
                                            pattern: 'd{4}-d{2}-d{2}',
                                            style: {
                                                backgroundColor: '#FFF',
                                                fontFamily: 'InfraRegular',
                                                fontSize: '.8rem',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                padding: '14px 14px',
                                                '@media only screen and (maxWidth: 414px)': {
                                                    height: '100px',
                                                },
                                            }
                                        }
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textField}
                                />
                            </div>
                            <div className={classes.dateField}>
                                <FormikTextField
                                    id="data-inicio"
                                    name="dataFinal"
                                    type="date"
                                    onChange={handleChange}
                                    label={t(RH_DATA_FINAL_LABEL, "Data Final")}
                                    inputProps={
                                        {
                                            pattern: 'd{4}-d{2}-d{2}',
                                            style: {
                                                backgroundColor: '#FFF',
                                                fontFamily: 'InfraRegular',
                                                fontSize: '.8rem',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                padding: '14px 14px',
                                            }
                                        }
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textField}
                                />
                            </div>
                        </div>
                    )
                }
                }
            </Formik>
        </FormControl>
    );
};

export default memo(DateRange);