import React from 'react';
import './App.css';
import { ApplicationRoutes } from './routes/routes';
import { Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { UserSessionHandler } from './service/SessionHandlerService';
import { ExceptionHandler } from './components';

const mentoringTheme = createTheme({
	palette: {
		primary: {
			main: '##003B4A'
		},
		secondary: {
			main: '#dc004e'
		}
	},
	typography: {
		fontFamily: 'nexaRegular, Arial',
	},
});

/**
 * Componente principal que renderiza toda a aplicação.
 * 
 * @returns O componente principal da aplicação.
 */
const App: React.FC = () => {

	return (
		<div className="App">
			<ThemeProvider theme={mentoringTheme}>
				<ExceptionHandler>
					<UserSessionHandler>
						<Switch>
							<ApplicationRoutes></ApplicationRoutes>
						</Switch>
					</UserSessionHandler>
				</ExceptionHandler>
			</ThemeProvider>
		</div>
	);
}

export default App;