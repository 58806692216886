import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";
import SessionStorageService from "./SessionStorageService";
import { ChavesLocalStorage } from "../config";

/**
 * Service com métodos que auxiliam nos processos relacionados à geração de relatórios excel no perfil do RH.
 */
export default class RelatorioExcelService extends HttpClientService{
    constructor(){
        super('relatorios')
    }

    /**
     * Obtém o relatório de todas as mentorias.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getRelatorioMentorias(): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/mentorias`,
            method: AxiosMethodsName.get,
            responseType: 'blob',
            headers: {
                "Authorization": "Bearer " + SessionStorageService.getStorage(ChavesLocalStorage.session_key)
            }
        }
        
        return super.executeMethod(configRequest);
    }

    /**
     * Obtém o relatório geral da aplicação.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getRelatorioGeral(): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/geral`,
            method: AxiosMethodsName.get,
            responseType: 'blob',
            headers: {
                "Authorization": "Bearer " + SessionStorageService.getStorage(ChavesLocalStorage.session_key)
            }
        }
        
        return super.executeMethod(configRequest);
    }

    /**
     * Obtém o relatório de todos os mentores.
     * 
     * @returns Promise com o resultado da requisição.
     */
    getRelatorioMentores(): Promise<any> {
        const configRequest: MentoringConfigRequest = {
            url: `${this.getURL()}/mentores`,
            method: AxiosMethodsName.get,
            responseType: 'blob',
            headers: {
                "Authorization": "Bearer " + SessionStorageService.getStorage(ChavesLocalStorage.session_key)
            }
        }
        
        return super.executeMethod(configRequest);
    }

}