import React from "react";
import { ConfirmacaoModal } from "../../../../../../../components";
import AgendamentoMentoriaTermosDeUsoText from "../termo-de-uso/AgendamentoMentoriaTermosDeUsoText";
import {
  AGENDAMENTO_MENTORIA_SOURCE,
  AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_TITULO,
  AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_BOTAO_CONTINUAR
} from "../../../../../../../messages";
import { useTranslation } from "react-i18next";

/**
 * Interface utilizada pelo componente AgendamentoMentoriaTermosDeUsoModal
 * e que descreve seu métodos e propriedades.
 */
interface AgendamentoMentoriaTermosDeUsoModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

/**
 * Renderiza o modal de confirmação de agendamento e também os
 * termos de uso do agendamento da mentoria.
 * 
 * @param {AgendamentoMentoriaTermosDeUsoModalProps}
 * @returns O modal de confirmação de agendamento e também os
 * termos de uso do agendamento da mentoria.
 */
const AgendamentoMentoriaTermosDeUsoModal = (
  props: AgendamentoMentoriaTermosDeUsoModalProps
) => {
  const { open, handleClose, onSubmit } = props;

  /**
   * Hook e função utilizados na tradução dos textos do modal.
   */
  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

  return (
    <ConfirmacaoModal
      title={t(AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_TITULO, "Importante")}
      buttonText={t(AGENDAMENTO_MENTORIA_MODAL_TERMOS_DE_USO_BOTAO_CONTINUAR, "Continuar")}
      isOpen={open}
      handleClose={handleClose}
      buttonCallback={onSubmit}
      centralizeTitle
      forceButtonEnabled
      style={{ fontFamily: 'InfraRegular', fontStyle: 'normal' }}
    >
      <AgendamentoMentoriaTermosDeUsoText />
    </ConfirmacaoModal>
  );
};

export default AgendamentoMentoriaTermosDeUsoModal;
