import React from "react";
import { SessionObject, MentoriaService } from "../service";
import { MentorRoutes, MentoradoRoutes, MentoringRoutes } from "./routes";

/**
 * Renderiza as rotas específicas de cada tipo de usuário.
 *
 * @param role O papel do usuário(mentor, mentorado ou admin).
 * @param userSession A sessão atual do usuário.
 * @returns A rota com base no papel do usuário logado.
 */

export function routesFilteredByRoles(
  role: string,
  userSession: SessionObject
) {
  const mentoriaService = new MentoriaService();

  switch (role) {
    case "ROLE_MENTOR":
      return (
        <MentorRoutes session={userSession} mentoriaService={mentoriaService} />
      );
    case "ROLE_MENTORADO":
      return (
        <MentoradoRoutes
          session={userSession}
          mentoriaService={mentoriaService}
        />
      );
    case "ROLE_ADMIN":
      return <MentoringRoutes />;
  }
}
