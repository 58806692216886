import React from 'react'
import { createStyles, Link, makeStyles } from '@material-ui/core';

import EmailIcon from "../../assets/svgs/icon-mail.svg";
import WhatsappIcon from "../../assets/svgs/icon-whatsapp.svg";
import LinkedinIcon from "../../assets/svgs/icon-linkedin.svg";
import { CustomModal } from '../../commons';
import ShareEmailForm from './ShareEmailForm';
import { useTranslation } from 'react-i18next';

import {
    SHARING_MENTORING_SOURCE,
    SHARING_MENTORING_SHARE
} from '../../messages'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginTop: "0.5rem",

            '@media only screen and (max-width: 414px)': {
                width: '72vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                width: '42vw',
            }
        },
        spanDiv: {
            display: "flex",
            marginBottom: "0.5rem",
        },
        span: {
            color: "#373A3C",
            fontFamily: "infraLight"
        },
        iconesDiv: {
            display: "flex",
            justifyContent: "space-evenly",
        }
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: "auto",
        width: "28rem",
        textAlign: "-webkit-center",
    },
};

/**
 * Interface utilizada pelo componente ShareMentoring e que descreve
 * seu métodos e propriedades.
 */
interface ShareMentoringProps {
    mentor: any
}

/**
 * Componente que apresenta e realiza as formas de compartilhamento da plataforma Mentoring.
 * 
 * @param {ShareMentoringProps} 
 * @returns O componente de compartilhamento da plataforma Mentoring.
 */
const ShareMentoring:React.FC<ShareMentoringProps> = ({ mentor }) => {
    const classes = useStyles();
    const { t } = useTranslation([SHARING_MENTORING_SOURCE]);

    const [openEmailModal, setOpenEmailModal] = React.useState(false);

    const url = "https://mentoring.stefanini.io/#/";
    const titulo = "Mentoring Stefanini";

    /**
     * Para algumas redes sociais, a forma de compartilhamento é restrito, 
     * desta forma para ser direcionado para plataforma, basta formatar a url com as informações necessárias.
     */
    const redesSociais = [
        { icone: WhatsappIcon, nome: "whatsapp", link: "https://api.whatsapp.com/send?text=" + titulo + " - " + url },
        { icone: LinkedinIcon, nome: "linkedin", link: "https://www.linkedin.com/sharing/share-offsite/?url=" + url },
    ]

    /**
     * Configura o state para o fechamento do modal de email.
     */
    const closeEmailModal = () => {
        setOpenEmailModal(false);
    }

    /**
     * Modal para compartilhamento através de e-mail.
     * 
     * @returns O modal próprio para o compartilhamento do Mentoring via email.
     */
    const emailModal = () => {
        return (
            <CustomModal
                style={modalStyle.container}
                isOpen={openEmailModal}
                handleClose={() => setOpenEmailModal(false)}
            >
                <ShareEmailForm mentor={mentor} closeModal={closeEmailModal}/>
            </CustomModal>
        )
    }

    return (
        <div className={classes.root}>
            {emailModal()}
            <div className={classes.spanDiv}>
                <span className={classes.span}>{t(SHARING_MENTORING_SHARE, 'Compartilhe')}</span>
            </div>
            <div className={classes.iconesDiv}>
                <Link style={{ cursor: "pointer" }} onClick={() => setOpenEmailModal(true)}>
                    <img src={EmailIcon} alt="email_icon" />
                </Link>
                {
                    redesSociais.map((plataforma) => (
                        <Link href={plataforma.link} target="_blank" key={plataforma.nome}>
                            <img src={plataforma.icone} alt={plataforma.nome} /> 
                        </Link>
                    ))
                }
            </div>

        </div>
    )
}

export default ShareMentoring;
