import React, { useEffect } from 'react';
import { Container, makeStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
    SE_TORNAR_MENTOR_SOURCE,
    SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_SUBTITULO,
    SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_TEXTAREA_PLACEHOLDER,
    SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_CONTADOR_CARACTERES,
    SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_DESCRICAO_CURTA
} from '../../../../../../../messages';
import { Mentor } from '../../../../../../../models';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: '80%',
        },
        subtitle: {
            color: '#1F1F1F',
            fontFamily: 'InfraRegular',
            fontSize: '.8rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '21px',
            marginBottom: '1.5rem',

            '@media only screen and (max-width: 720px)': {
                fontSize: '.7rem',
            }
        },
        mainSection: {
            '@media (max-width: 1024px)': {
                height: '400px'
            }
        },
        description: {
            border: '2px solid #003B4A',
            borderRadius: '4px',
            fontFamily: 'InfraRegular',
            fontSize: '.9rem',
            fontStyle: 'normal',
            fontWeight: 400,
            outline: 'none',
            paddingLeft: '.5rem',
            paddingTop: '.5rem',
            width: '90%',

            '@media (max-width: 1024px)': {
                width: '100%',
                maxHeight: '50%',
                fontSize: '0.8rem'
            }
        },
        invalid: {
            color: 'crimson',
            fontFamily: 'InfraRegular',
            fontSize: '.85rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            marginLeft: '.5rem',

            '@media only screen and (max-width: 720px)': {
                fontSize: '.65rem'
            }
        },
        characterCounter: {
            color: '1F1F1F',
            display: 'flex',
            fontFamily: 'InfraRegular',
            fontSize: '.85rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            marginLeft: '3rem',
            textAlign: 'left',

            '@media only screen and (max-width: 720px)': {
                fontSize: '.7rem',
                marginLeft: 0
            }
        }
    }),
);

/**
 * Interface utilizada pelo componente DescricaoComponent e que descreve
 * seu métodos e propriedades.
 */
interface DescricaoComponentProps {
    handleChange: Function;
    values: Mentor;
    isDisabled: Function;
}

/**
 * Passo de descrição do mentor no processo de se tornar mentor.
 * 
 * @param {DescricaoComponentProps}
 */
const DescricaoComponent: React.FC<DescricaoComponentProps> = ({ handleChange, values, isDisabled }) => {

    /**
     * Hook e função utilizados na tradução de textos.
     */
    const { t } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);

    const [charCounter, setCharCounter] = React.useState(values.descricao?.length || 0);
    const minLength = 10;
    const maxLength = 500;

    const classes = useStyles();

    /**
     * Valida se o número de caracteres da descrição está dentro
     * do limite aceitável(i.e. mínimo de 10 caracteres e máximo
     * de 500 caracteres).
     * 
     * @returns True se o número de caracteres da descrição está
     *          dentro do limite, False caso contrário.
     */
    const isValid = () => {
        if (charCounter >= minLength && charCounter <= maxLength) {
            return true;
        }
        return false;
    }

    /**
     * Valida a descrição do mentor.
     */
    const validate = () => {
        isDisabled(!isValid());
    }

    /**
     * Gerencia a mudança na descrição do mentor.
     * 
     * @param e O evento que dispara a mudança na
     *          descrição do mentor.
     */
    const handleDescriptionChange = (e) => {
        handleChange(e, e.target.name);
        setCharCounter(values.descricao?.length || 0);

        validate();
    }

    /**
     * Valida a descrição ao renderizar o componente.
     */
    useEffect(() => { validate() });

    return (
        <Container maxWidth='sm' className={classes.container}>

            <Typography className={classes.subtitle}>{t(SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_SUBTITULO, 'Aqui você pode se apresentar e disponibilizar dias e horários para mentorias')}</Typography>

            <section className={classes.mainSection}>

                <textarea
                    autoFocus
                    defaultValue={values.descricao}
                    name="descricao"
                    onChange={(e) => handleDescriptionChange(e)}
                    className={classes.description}
                    rows={15}
                    cols={100}
                    maxLength={maxLength}
                    placeholder={t(SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_TEXTAREA_PLACEHOLDER, "Fale um pouco sobre sua expêriencia")}
                    required
                >

                </textarea>
                <section className={classes.characterCounter}>
                    <p>
                        {maxLength - charCounter} {t(SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_CONTADOR_CARACTERES, 'caracteres restantes')}
                    </p>

                    <p className={classes.invalid}>
                        {(charCounter < minLength && charCounter > 0) ? t(SE_TORNAR_MENTOR_STEP_SOBRE_VOCE_DESCRICAO_CURTA, "(Descrição muito curta)") : ""}
                    </p>
                </section>
            </section>

        </Container>)
}

export default DescricaoComponent;
