import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";

/**
 * Service com métodos que auxiliam nos processos relacionados aos idiomas da aplicação.
 */
export default class IdiomaServiceService extends HttpClientService{
    constructor(){
        super('idiomas');
    }

    /**
     * Obtém os idiomas disponvíveis na aplicação
     * 
     * @returns Promise com o resultado da busca.
     */
    getIdiomas(): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            method: AxiosMethodsName.get,
        }

        return super.executeMethod(configRequest);
    }

}
