import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Typography, Box, Link, TextField, Avatar, Button, CssBaseline } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import * as QueryString from 'querystring';

import { SessionStorageService, UserService } from '../../../service';
import { SpinnerSplendis } from '../../../commons';
import { alertContext, BotaoGlobalContato } from '../../../components';
import { useTranslation } from 'react-i18next';
import {
    TROCAR_SENHA_BOTAO_CONFIRMAR,
    TROCAR_SENHA_CONFIRMAR_NOVA_SENHA_PLACEHOLDER,
    TROCAR_SENHA_NOVA_SENHA_PLACEHOLDER,
    TROCAR_SENHA_SOURCE,
    TROCAR_SENHA_TITULO,
    TROCAR_SENHA_MENSAGEM_SUCESSO,
    TROCAR_SENHA_ERRO_SENHA_ATUAL_INCORRETA,
    TROCAR_SENHA_ERRO_CAMPOS_DE_NOVA_SENHA_DIFERENTES
} from '../../../messages';

/**
 * Renderiza o copyright da Stefanini na parte inferior da tela.
 * 
 * @returns O copyright da Stefanini na parte inferior da tela.
 */
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#"> {/* TODO Set Link in environment variables */}
            Mentoring
            </Link>{" "}
          {new Date().getFullYear()}. Powered by Stefanini
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

/**
 * 
 */
class PasswordChangeState {
    token?: string | null | undefined
    newPassword?: string
    newPassword_confirm?: string
    gotError: boolean = false
}

/**
 * 
 * @param props 
 * @returns 
 */
export default function ResetPassword(props) {
    const classes = useStyles();

    const userService: UserService = new UserService();
    const history = useHistory();
    const location = useLocation();
    const { showAlert } = useContext(alertContext);

    /**
     * Hook e função utilizados para tradução de textos.
     */
    const { t } = useTranslation([TROCAR_SENHA_SOURCE]);

    let params = QueryString.parse(location.search);
    params.email = params['?email'];
    let token = params.token;
    let email = params.email;

    const [state, setState] = React.useState(new PasswordChangeState());
    const [loading, setLoading] = React.useState(false);
    const [newSenha, setNewSenha] = React.useState("");
    const [newSenhaConfirm, setNewSenhaConfirm] = React.useState("");

    /**
     * Verifica se há erros no processo de resetar a senha.
     * 
     * @returns Mensagem de erro caso a senha atual esteja incorreta,
     * 			null caso contrário.
     */
    const handleRequestError = () => {
        return state.gotError ?
            <Typography variant="caption" style={{ color: 'red' }} gutterBottom>{t(TROCAR_SENHA_ERRO_SENHA_ATUAL_INCORRETA, 'Senha atual incorreta')}</Typography>
            :
            null;
    }

    /**
     * Verifica se a nova senha e a confirmação da nova senha estão diferentes.
     * 
     * @returns Mensagem de erro caso a senha nova seja diferente da confirmação
     *          da nova senha, null caso contrário.
     */
    const handlePasswordConfirmError = () => {
        return (newSenhaConfirm && newSenha !== newSenhaConfirm) ?
            <Typography variant="caption" style={{ color: 'red' }} gutterBottom>{t(TROCAR_SENHA_ERRO_CAMPOS_DE_NOVA_SENHA_DIFERENTES, 'Senhas diferentes')}</Typography>
            :
            null;
    }

    /**
     * Gerencia o processo de redefinição de senha, configurando o state.
     * 
     * @param event O evento com os dados de senha ou confirmação de nova senha. 
     * @param field O campo de nova senha ou confirmação de nova senha.
     * @param data Os dados dos campos de senha.
     */
    function handleChange(event, field = event?.target.id, data = event?.target.value) {
        setState({ ...state, [field]: data });

        if (field === 'newPassword') {
            setNewSenha(data);
        }

        if (field === 'newPassword_confirm') {
            setNewSenhaConfirm(data);
        }
    }

    /**
     * Verifica se a nova senha e a confirmação de nova senha
     * existem e se são iguais.
     * 
     * @returns True caso as senhas existam e sejam iguais,
     *          False caso contrário.
     */
    function isValid() {
        if (
            (state.newPassword && state.newPassword_confirm)
            &&
            (state.newPassword === state.newPassword_confirm)
        ) return true;

        else return false;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <div className={classes.paper}>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    {t(TROCAR_SENHA_TITULO, "Trocar senha")}
                </Typography>

                <form className={classes.form} noValidate>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        label={t(TROCAR_SENHA_NOVA_SENHA_PLACEHOLDER, "Nova senha")}
                        type="password"
                        onChange={(event) => handleChange(event)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword_confirm"
                        name="newPassword_confirm"
                        label={t(TROCAR_SENHA_CONFIRMAR_NOVA_SENHA_PLACEHOLDER, "Confirmar nova senha")}
                        type="password"
                        onChange={(event) => handleChange(event)}
                    />
                    {handlePasswordConfirmError()}


                    {loading ? <SpinnerSplendis style={{ textAlign: 'center' }} /> :
                        <Button
                            onClick={(e) => handleSubmit(e)}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isValid()}
                            className={classes.submit}
                        >
                            {t(TROCAR_SENHA_BOTAO_CONFIRMAR, "Confirmar")}
                        </Button>
                    }
                    {handleRequestError()}

                </form>
            </div>

            <Box mt={8}>
                <Copyright />
            </Box>
            <BotaoGlobalContato />
        </Container>
    );

    /**
     * 
     * @param event 
     */
    function handleSubmit(event: any) {

        setLoading(true);

        let submit = {
            username: email,
            password: state.newPassword,
            token: token
        };

        userService.requestPasswordReset(submit).then((res) => {
            res.status >= 200 ? submitSuccess() : submitError();

        });
        setLoading(false);
    }

    /**
     * 
     */
    function submitError() {
        setLoading(false);
        SessionStorageService.clearStorage();
        //setTimeout( () => history.push('/login'), 5000);
    }

    /**
     * 
     */
    function submitSuccess() {
        showAlert(200, t(TROCAR_SENHA_MENSAGEM_SUCESSO, "Senha restaurada com sucesso!"));

        SessionStorageService.clearStorage();

        setTimeout(() => history.push('/login'), 1500);
    }
}