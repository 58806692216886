import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  makeStyles,
  Typography,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { mapWeekDays } from "../../../../../../../utils/FormUtils";
import { useTranslation } from "react-i18next";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import {
  SE_TORNAR_MENTOR_SOURCE,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_SUBTITULO,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_DISPONIBILIDADE,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_HORARIO,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_DIAS_SEMANA,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_CANCELAR,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_CONFIRMAR,
  INPUT_2_SEMANAS,
  INPUT_3_SEMANAS,
  INPUT_4_SEMANAS,
  INPUT_SEMANA,
  NEW_MESSAGES_SOURCE,
  INPUT_DATA_INICIAL,
  INPUT_INTERVALO,
} from "../../../../../../../messages";
import { Disponibilidade } from "../../../../../../../models";
import {
  getRange,
  getFormat,
} from "../../../../../../../components/edicao-mentor-modal/components/day-of-week-and-time-row/intervaloFunction";

const useStyles = makeStyles({
  table: {
    margin: "auto",
    maxWidth: "100%",
    backgroundColor: "transparent",

    "@media (max-width: 1024px)": {
      maxWidth: "100%",
    },
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    alignText: "center",
    maxWidth: "100%",
    opacity: "75%",

    "@media (max-width: 1024px)": {
      margin: "0 auto",
      maxWidth: "100%",
    },
  },
  subtitle: {
    fontFamily: "InfraRegular",
    fontSize: ".9rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    marginBottom: "5vh",
    marginTop: "5vh",
  },
  availability: {
    fontFamily: "InfraRegular",
    fontSize: ".9rem",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  hours: {
    fontFamily: "InfraRegular",
    fontSize: ".9rem",
    fontStyle: "normal",
    fontWeight: "bold",
    width: 75,
  },
  hourTableRow: {
    fontFamily: "InfraRegular",
    fontSize: ".9rem",
    fontStyle: "normal",
    fontWeight: 400,
  },
});

/**
 * Interface utilizada pelo componente HorariosComponent e que descreve
 * seu métodos e propriedades.
 */
interface HorariosComponentProps {
  atributoForm: string;
  diasDisponiveis: Disponibilidade[];
  isDisabled: Function;
  setHorarios: Function;
}

/**
 * Passo de seleção de horários para mentor.
 *
 * @param {HorariosComponentProps}
 * @returns O passo de seleção de horários para mentor.
 */
const HorariosComponent: React.FC<HorariosComponentProps> = ({
  atributoForm,
  diasDisponiveis,
  isDisabled,
  setHorarios,
}) => {
  const classes = useStyles();

  /**
   * Hook e função utilizados para tradução de textos.
   */
  const { t } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);
  const { t: t2 } = useTranslation([NEW_MESSAGES_SOURCE]);

  const [horas, setHoras] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [recorrencia, setRecorrencia] = useState<number[]>([]);

  const [, updateState] = useState();
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), []);

  // const { isDisabled, setHorarios } = props;

  /**
   * Verifica se o número de dias e horários selecionados é igual.
   *
   * @returns True caso o número de dias e horários seja diferente,
   *          False caso contrário.
   */
  const isNotValid = () => {
    let isValid =
      diasDisponiveis.filter(
        (dia) =>
          dia.hora && dia.dataInicio && dia.intervaloSemanas && dia.checked
      ).length === diasDisponiveis.filter((dia) => dia.checked).length;
    return !isValid;
  };

  /**
   * Verifica se os dias disponíveis estão válidos.
   */
  useEffect(() => {
    isDisabled(isNotValid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diasDisponiveis]);

  /**
   * Gerencia a mudança na seleção dos horários.
   *
   * @param date O objeto com a data que contém também o horário.
   * @param index O índice do horário no array de dias disponíveis.
   */
  const handleChange = (date: MaterialUiPickersDate, index: number) => {
    let oldDiasDisponiveis = diasDisponiveis;
    const formattedTime = moment(date).format("HH:mm");
    oldDiasDisponiveis[index].hora = formattedTime;

    let oldHoras = horas;

    oldHoras[index] = formattedTime;

    setHoras(oldHoras);

    isDisabled(isNotValid);
    setHorarios(formattedTime, atributoForm, oldDiasDisponiveis);
    forceUpdate();
  };

  const handleChangeIntervalo = (intervalo, index: number) => {
    let oldDiasDisponiveis = diasDisponiveis;
    oldDiasDisponiveis[index].intervaloSemanas = intervalo;

    let oldIntervalo = recorrencia;

    oldIntervalo[index] = intervalo;

    //setRecorrencia(oldIntervalo);
    isDisabled(isNotValid);
    setHorarios(intervalo, atributoForm, oldDiasDisponiveis);
    forceUpdate();
  };

  const handleChangeDataInicio = (data, index: number) => {
    let oldDiasDisponiveis = diasDisponiveis;
    oldDiasDisponiveis[index].dataInicio = data;

    //setRecorrencia(oldDataInicio);
    isDisabled(isNotValid);
    setHorarios(data, atributoForm, oldDiasDisponiveis);
    forceUpdate();
  };
  return (
    <Container>
      <Typography className={classes.subtitle}>
        {t(
          SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_SUBTITULO,
          "Escolha um horário para cada dia"
        )}
      </Typography>

      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ width: "80%" }}>
              <TableCell className={classes.availability}>
                {t(
                  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_DISPONIBILIDADE,
                  "Disponibilidade"
                )}
              </TableCell>
              <TableCell className={classes.hours} align="left">
                {t(
                  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_HORARIO,
                  "Horário"
                )}
              </TableCell>

              <TableCell
                className={classes.availability}
                style={{ width: 120 }}
                align="left"
              >
                {t2(INPUT_INTERVALO, "Ocorre a cada")}
              </TableCell>

              <TableCell
                className={classes.availability}
                style={{ width: 130 }}
                align="left"
              >
                {t2(INPUT_DATA_INICIAL, "Data inicial")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diasDisponiveis.map((row, index) =>
              row.checked ? (
                <TableRow key={mapWeekDays(row.dia, t)}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.hourTableRow}
                  >
                    {t(
                      `${SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_DIAS_SEMANA}.${row.dia}`,
                      mapWeekDays(row.dia, t)
                    )}
                  </TableCell>

                  <TableCell align="right">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        ampm={false}
                        cancelLabel={t(
                          SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_CANCELAR,
                          "Cancelar"
                        )}
                        okLabel={t(
                          SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_CONFIRMAR,
                          "Confirmar"
                        )}
                        value={moment(row.hora || "00:00", "HH:mm") ?? "00:00"}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>

                  <TableCell>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        id="demo-simple-select-standard"
                        name={"intervaloSemanas"}
                        select
                        defaultValue={1}
                        value={row.intervaloSemanas}
                        onChange={(e) =>
                          handleChangeIntervalo(e.target.value, index)
                        }
                      >
                        <MenuItem value={1}>
                          {t2(INPUT_SEMANA, "1 Semana")}
                        </MenuItem>
                        <MenuItem value={2}>
                          {t2(INPUT_2_SEMANAS, "2 Semanas")}
                        </MenuItem>
                        <MenuItem value={3}>
                          {t2(INPUT_3_SEMANAS, "3 Semanas")}
                        </MenuItem>
                        <MenuItem value={4}>
                          {t2(INPUT_4_SEMANAS, "4 Semanas")}
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </TableCell>

                  <TableCell>
                    <TextField
                      style={{ width: "100%" }}
                      select
                      name="dataInicio"
                      defaultValue={getRange("days", row.ordinal)[0]}
                      value={row.dataInicio}
                      onChange={(e) =>
                        handleChangeDataInicio(e.target.value, index)
                      }
                    >
                      {getRange("days", row.ordinal).map((date, index) => (
                        <MenuItem key={index} value={date}>
                          {getFormat(date)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                </TableRow>
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default HorariosComponent;
