import * as Yup from 'yup';
import {
    CATEGORIAS_VALIDATIONS_CAMPO_OBRIGATORIO,
    CATEGORIAS_VALIDATIONS_NOME_CURTO,
    CATEGORIAS_VALIDATIONS_NOME_LONGO,
    CATEGORIAS_VALIDATIONS_DESCRICAO_CURTA,
    CATEGORIAS_VALIDATIONS_DESCRICAO_LONGA
} from '../../../../messages';

/**
 * Schemas para validação dos campos de nomePt e descriçãoPt
 * 
 * @param t A função de tradução dos textos.
 * @returns Objeto com a verificação e validação dos campos nomePt e descriçãoPt.
 */
export const categoriaSchema = (t) => Yup.object().shape({
    nomePt: Yup.string().trim().min(3, t(CATEGORIAS_VALIDATIONS_NOME_CURTO, "Mínimo de 3 caracteres")).max(45, t(CATEGORIAS_VALIDATIONS_NOME_LONGO, "Máximo de 45 caracteres")).required(t(CATEGORIAS_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório")),
    descricaoPt: Yup.string().trim().min(5, t(CATEGORIAS_VALIDATIONS_DESCRICAO_CURTA, "Mínimo de 5 caracteres")).max(100, t(CATEGORIAS_VALIDATIONS_DESCRICAO_LONGA, "Máximo de 100 caracteres")).required(t(CATEGORIAS_VALIDATIONS_CAMPO_OBRIGATORIO, "Campo Obrigatório"))
});