import React, { useEffect, useState, useContext } from 'react';
import { Button, capitalize, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Categoria, Disponibilidade, Idioma, Mentor } from '../../../../../../../models';
import { UserContext } from "../../../../../../../service";
import MentorCard from '../../../../../../../components/mentor-card/MentorCard';
import { mapWeekDays } from '../../../../../../../utils/FormUtils';
import {
    SE_TORNAR_MENTOR_SOURCE,
    SE_TORNAR_MENTOR_STEP_FINALIZAR_SUBTITULO,
    SE_TORNAR_MENTOR_STEP_FINALIZAR_CATEGORIAS,
    SE_TORNAR_MENTOR_STEP_FINALIZAR_IDIOMAS,
    SE_TORNAR_MENTOR_STEP_FINALIZAR_DISPONIBILIDADE,
    SE_TORNAR_MENTOR_STEP_FINALIZAR_DIAS,
    SE_TORNAR_MENTOR_STEP_FINALIZAR_MARCADOR_HORARIO,
    SE_TORNAR_MENTOR_STEP_FINALIZAR_CONFIRMAR
} from '../../../../../../../messages';

const useStyles = makeStyles({
    root: {
        height: '100%',
        maxWidth: '80%',
    },
    outerContainer: {
        alignContent: 'center',
        backgroundColor: 'rgba(207, 212, 218, 0.32)',
        display: 'flex',
        flexDirection: 'column',
        height: '53vh',
        margin: '2rem auto 0 auto',
        width: '90%',
        zIndex: 100,

        '@media only screen and (max-width: 720px)': {
            display: 'block',
            overflowY: 'scroll'
        }
    },
    subtitle: {
        fontFamily: 'InfraRegular',
        fontSize: '.8rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '21px',

        '@media (max-width: 1024px)': {
            fontSize: '0.8rem',
            marginBottom: 4
        }
    },
    propertiesField: {
        marginTop: '1.5rem',
        width: '18rem',

        '@media only screen and (max-width: 414px)': {
            marginLeft: '-2rem',
            width: '55vw',
            marginBottom: '2vh',
        },

        '@media only screen and (min-width: 415px) and (max-width: 720px)': {
            marginLeft: '-2rem',
            width: '45vw',
            marginBottom: '2vh',
        }
    },
    textfieldBorder: {
        borderWidth: '2px',
        borderColor: '#003B4A !important',
    },
    confirmationButton: {
        backgroundColor: '#003B4A',
        color: '#FFF',
        fontFamily: 'InfraRegular',
        fontSize: '.9rem',
        fontStyle: 'normal',
        fontWeight: 500,
        marginBottom: '.5rem',
        marginTop: '.5rem',
        width: '90%',

        '&:hover': {
            backgroundColor: '#006FD7',
        },

        '&:disabled': {
            backgroundColor: '#ccc',
            color: '#FFF',
        }
    },
});

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const cardStyles = {
    height: '40vh',
    margin: '1rem auto 0 auto',
    padding: '0 1rem 3rem 1rem',
}

/**
 * Ajusta o modal para dispositivos movéis.
 */
const cardMobileStyles = {
    height: '18rem',
    margin: '1rem auto 0 auto',
    padding: 0,
    width: '55vw'
}

/**
 * Interface utilizada pelo componente RevisaoComponent e que descreve
 * seu métodos e propriedades.
 */
interface RevisaoComponentProps {
    descricao: string;
    categorias: Categoria[];
    idiomas: Idioma[];
    disponibilidades: Disponibilidade[];
    updateMentor: Function;
    handleConfirmation: Function;
}

/**
 * Passo de revisão no processo de se tornar mentor.
 * 
 * @param {RevisaoComponentProps}
 * @returns O passo de revisão no processo de se tornar mentor.
 */
const RevisaoComponent: React.FC<RevisaoComponentProps> = ({ descricao, categorias, idiomas, disponibilidades, updateMentor, handleConfirmation }) => {
    const classes = useStyles();

    /**
     * Hook e funções utilizados na tradução de textos.
     */
    const { t, i18n } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);

    const { userObject } = useContext(UserContext);
    const [updatedMentor] = useState(new Mentor());
    const [disableSubmit, setDisableSubmit] = useState(false);

    /**
     * Atualiza os dados do mentor ao renderizar o componente.
     */
    useEffect(() => {
        updateMentor(updatedMentor);
    });

    /**
     * Cria o objeto mentor com os dados obtidos dos passos anteriores.
     * 
     * @returns O objeto mentor.
     */
    const createMentor = () => {
        updatedMentor.cargo = userObject.colaborador.cargo;
        updatedMentor.categorias = categorias;
        updatedMentor.descricao = descricao;
        updatedMentor.disponibilidades = disponibilidades;
        updatedMentor.email = userObject.colaborador.email;
        updatedMentor.foto = userObject.colaborador.foto;
        updatedMentor.idiomas = idiomas;
        updatedMentor.nome = userObject.colaborador.nome;
        updatedMentor.status = userObject.colaborador.status;
        updatedMentor.timeZone = userObject.colaborador.timeZone;
        updatedMentor.uf = userObject.colaborador.uf;

        return updatedMentor;
    }

    /**
     * Obtém uma lista dos nomes das categorias selecionadas no passo de categoria.
     * Os nomes das categorias são traduzidos de acordo com o idioma
     * utilizado na aplicação.
     * 
     * @param attributeList A lista de atributos(nome) das categorias.
     * @returns Uma string com todas as categorias concatenadas.
     */
    const getMentorAttributesCategorias = (attributeList: Array<any>) => {
        let attributeNames: string = '';

        attributeList.forEach(attribute => attributeNames += attribute.nome ? attribute.nome : attribute["nome" + capitalize(i18n.language)] + ', ');

        return attributeNames.substring(0, attributeNames.length - 2);
    }

    /**
     * Obtém uma lista dos nomes dos idiomas selecionados no passo de idioma.
     * 
     * @param attributeList A lista de atributos(nome) dos idiomas.
     * @returns Uma string com todos os idiomas concatenados.
     */
    const getMentorAttributesIdiomas = (attributeList: Array<any>) => {
        let attributeNames: string = '';

        attributeList.forEach(attribute => attributeNames += attribute.nome + ', ');

        return attributeNames.substring(0, attributeNames.length - 2);
    }

    /**
     * Obtém uma lista das disponibilidades selecionadas no passo de disponibilidade.
     * 
     * @returns Uma string com todas as disponibilidades concatenadas.
     */
    const getMentorAvailability = () => {
        let availabilities: string = '';

        disponibilidades.forEach(disponibilidade => availabilities += t(`${SE_TORNAR_MENTOR_STEP_FINALIZAR_DIAS}.${disponibilidade.dia}`, mapWeekDays(disponibilidade.dia,t)) + t(SE_TORNAR_MENTOR_STEP_FINALIZAR_MARCADOR_HORARIO, ' às ') + disponibilidade.hora + ', ');

        return availabilities.substring(0, availabilities.length - 2);
    }

    /**
     * Gerencia a submissão do cadastro do mentor.
     */
    const handleClick = (): void => {
        setDisableSubmit(!disableSubmit);
    }

    return (
        <Container className={classes.root}>
            <Typography className={classes.subtitle}>
                {t(SE_TORNAR_MENTOR_STEP_FINALIZAR_SUBTITULO, 'Confirme seus dados abaixo e dentro de algumas horas a equipe irá validar sua candidatura como ')}
                <span><strong>Mentor!</strong></span>
            </Typography>

            <Grid container className={classes.outerContainer}>
                <Grid item md={6}>
                    <MentorCard mentor={createMentor()} style={window.screen.width <= 414 ? cardMobileStyles : cardStyles} />
                </Grid>
                <Grid item md={6} style={{ marginLeft: '2rem' }}>
                    <div>
                        <TextField
                            className={classes.propertiesField}
                            name='categorias'
                            value={getMentorAttributesCategorias(categorias)}
                            variant='outlined'
                            label={t(SE_TORNAR_MENTOR_STEP_FINALIZAR_CATEGORIAS, 'Categorias')}
                            multiline
                            rows='1'
                            inputProps={{
                                readOnly: true,
                                style: {
                                    fontFamily: 'InfraRegular',
                                    fontSize: '.85rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.textfieldBorder
                                }
                            }}
                        />
                    </div>

                    <div>
                        <TextField
                            className={classes.propertiesField}
                            name='idiomas'
                            defaultValue={getMentorAttributesIdiomas(idiomas)}
                            variant='outlined'
                            label={t(SE_TORNAR_MENTOR_STEP_FINALIZAR_IDIOMAS, 'Idiomas')}
                            multiline
                            rows='1'
                            inputProps={{
                                readOnly: true,
                                style: {
                                    fontFamily: 'InfraRegular',
                                    fontSize: '.8rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.textfieldBorder
                                }
                            }}
                        />
                    </div>

                    <div>
                        <TextField
                            className={classes.propertiesField}
                            name='disponibilidade'
                            defaultValue={getMentorAvailability()}
                            variant='outlined'
                            label={t(SE_TORNAR_MENTOR_STEP_FINALIZAR_DISPONIBILIDADE, 'Disponibilidade')}
                            multiline
                            rows='1'
                            inputProps={{
                                readOnly: true,
                                style: {
                                    fontFamily: 'InfraRegular',
                                    fontSize: '.8rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.textfieldBorder
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <Button
                disabled={disableSubmit}
                className={classes.confirmationButton}
                onClick={() => {
                    handleConfirmation()
                    handleClick()
                }}
            >
                {t(SE_TORNAR_MENTOR_STEP_FINALIZAR_CONFIRMAR, 'Confirmar')}
            </Button>
        </Container>
    );
}

export default RevisaoComponent;
