import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, Typography, Box, capitalize } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { MentorList } from '../../../../commons';
import { MentorService } from '../../../../../../../../service';
import {
    AGENDAMENTO_MENTORIA_SOURCE,
    AGENDAMENTO_MENTORIA_STEP_MENTORES_TITULO_1,
    AGENDAMENTO_MENTORIA_STEP_MENTORES_TITULO_2,
    AGENDAMENTO_MENTORIA_STEP_MENTORES_BUSCAR_MENTOR,
    AGENDAMENTO_MENTORIA_STEP_MENTORES_AGENDAR_MENTORIAS
} from '../../../../../../../../messages';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '70%',
            backgroundColor: 'transparent',
            display: 'flex',
            overflowX: 'scroll',
            alignSelf: 'center',
            margin: 0
        },
        tile: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        content: {
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            maxHeight: '46vh',
            paddingBottom: '1rem',
            width: '100%',

            '@media only screen and (max-width: 414px)': {
                maxHeight: '52vh'
            }
        },
        contentWrapper: {
            marginTop: '1rem',

            '@media only screen and (max-width: 455px)': {
                maxWidth: '90vw'
            },
            '@media only screen and (min-width: 456px) and (max-width: 1024px)': {
                maxWidth: '90vw'
            }
        },
        contentWrapperCategoria: {
            marginTop: '1rem',
            top: '29vh',
            position: 'absolute',

            '@media only screen and (max-width: 455px)': {
                maxWidth: '90vw'
            },
            '@media only screen and (min-width: 456px) and (max-width: 1024px)': {
                maxWidth: '90vw'
            }
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'inherit',
            width: 'inherit',
            alignSelf: 'inherit',
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            overflowY: 'scroll'
        },
        formLabel: {
            maxWidth: '100%',
            marginTop: '5vh',
            marginBottom: '2vh',

            '@media only screen and (max-width: 455px)': {
                fontSize: '0.8rem',
                marginTop: '16px',
                marginBottom: '1vh',
            }
        },
        tabs: {
            width: '100%',
        },
        panelHeader: {
            marginBottom: '15vh',
            marginTop: '2.3vh',
        },
        panelSubheader: {
            color: '#000',
            fontFamily: 'InfraRegular',
            fontSize: '.8rem',
            fontStyle: 'normal',
            fontWeight: 400,
            paddingBottom: '3.5rem',
            //marginBottom: '1vh',
        },
        tabPanel: {
            backgroundColor: 'rgba(207, 212, 218, 0.32)',
        },
        tab: {
            alignContent: 'start'
        },
        tabContent: {
            backgroundColor: 'rgba(207, 212, 218, 0.32)',
            paddingTop: '1rem'
        }
    }),
);

const styles2 = makeStyles({
    root: {
        margin: '.5rem 1rem 1rem 1rem',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        paddingLeft: '1rem',
        width: '54.5vw',

        '@media (max-width: 1024px)': {
            width: '70vw'
        }
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
});


/**
 * Renderiza o step de escolha do mentor para o agendamento
 * de mentoria.
 * 
 * @param props Propriedades e métodos necessários para o
 *              funcionamento adequado do componente. 
 * @returns O step de escolha do mentor.
 */
export default function MentorStepMentorado(props) {
    const classes = useStyles();
    const searchStyles = styles2();

    /**
     * Hook e funções utilizados para tradução de textos.
     */
    const { t, i18n } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);

    const mentorService = new MentorService();

    const { categoria, idioma } = props;

    const [mentorSelected, setMentorSelected] = React.useState(props.defaultValue);
    const [query, setQuery] = React.useState("");

    /**
     * Desabilita ou não a lista dependendo se algum mentor foi selecionado ou não.
     */
    useEffect(() => {
        props.isDisabled(isNotValid);
    })

    /**
     * Verifica se algum mentor foi selecionado.
     * 
     * @returns True caso nenhum horário tenha sido selecionado, False caso contrário.
     */
    const isNotValid = () => {
        return String(mentorSelected) === "0";
    }

    /**
     * Gerencia o processo de seleção de um mentor da lista.
     * 
     * @param e O evento que dispara a mudança no state
   *            de mentor selecionada.
     * @param id O id do mentor selecionado.
     * @param mentor O mentor selecionado.
     */
    const handleChange = (e, id, mentor) => {
        props.handleChange("mentor", e, id, mentor);

        setMentorSelected(id);
        props.isDisabled(isNotValid);
    }

    /**
     * Gerencia a busca por um mentor na lista de mentores. Um cancelador 
     * de requisições do AXIOS é utilizado aqui para performance.
     * 
     * @param event O evento que dispara a busca por um mentor na lista.
     */
    const handleSearchChange = (event) => {
        const query = event.target.value;
        setQuery(query);
    }

    return (
        <div className={categoria ? classes.contentWrapperCategoria :  classes.contentWrapper}>
            {(idioma || categoria) && (
                <Typography style={{ color: "#030624", fontSize: "26px", textAlign: "center", paddingBottom: "3.5rem" }}>{t(AGENDAMENTO_MENTORIA_STEP_MENTORES_AGENDAR_MENTORIAS, "Agendar Mentoria")}</Typography>
            )}
            {categoria && 
                <Typography className={classes.panelSubheader}>
                    {t(AGENDAMENTO_MENTORIA_STEP_MENTORES_TITULO_1, 'Escolha o mentor com a descrição que mais se adequa aos temas de ')}
                    <strong>{categoria['nome' + capitalize(i18n.language)]}</strong>
                    {t(AGENDAMENTO_MENTORIA_STEP_MENTORES_TITULO_2, ' que te interessa')}
                </Typography>
            }
            <div className={classes.tabContent}>
                <Paper component="form" className={searchStyles.root}>
                    <InputBase
                        name="query"
                        value={query}
                        className={searchStyles.input}
                        placeholder={t(AGENDAMENTO_MENTORIA_STEP_MENTORES_BUSCAR_MENTOR, "Nome do mentor")}
                        inputProps={{ 'aria-label': 'buscar mentor' }}
                        onChange={handleSearchChange}
                    />
                    <SearchIcon />
                </Paper>

                <div role="tabpanel" className={classes.tabPanel}>
                    <Box>
                        <div className={classes.content}>
                            <MentorList
                                itensPorPagina={9}
                                defaultValue={mentorSelected}
                                searchQuery={query}
                                getMentores={categoria ?
                                    (pagina, itensPorPagina) => mentorService.getMentorsByCategoryAndName(query, categoria?.id, pagina, itensPorPagina) :
                                    (idioma ?
                                        (pagina, itensPorPagina) => mentorService.findByIdioma(query, idioma.sigla, pagina, itensPorPagina) :
                                        (pagina, itensPorPagina) => mentorService.findByNamePaginated(query, pagina, itensPorPagina))
                                }
                                idioma={idioma}
                                handleChange={handleChange}
                                isDisabled={props.isDisabled}
                            />
                        </div>
                    </Box>
                </div>
            </div>

        </div>
    );
}