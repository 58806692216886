import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import lightGreen from "@material-ui/core/colors/lightGreen";
import { Link } from "react-scroll";
import { withTranslation, WithTranslation } from "react-i18next";

import { SessionStorageService, LocalStorageService } from "../../../service";
import { PublicAppBarComponent } from "../../../layout";

import * as styleModule from "./index.module.css";
import {
  HOME_SECAO_1_TITULO,
  HOME_SECAO_1_SUBTITULO_MAIOR,
  HOME_SECAO_1_SUBTITULO_MENOR,
  HOME_SECAO_1_TITULO_IMAGEM,
  HOME_SECAO_1_BOTAO_MENTOR_POCKET,
  HOME_SECAO_1_BOTAO_MENTOR_COMPLETO,
  HOME_SECAO_1_BOTAO_MENTORADO_POCKET,
  HOME_SECAO_1_BOTAO_MENTORADO_COMPLETO,
  HOME_SECAO_2_TITULO,
  HOME_SECAO_2_TEXTO_1,
  HOME_SECAO_2_TEXTO_2,
  HOME_SECAO_2_BOTAO_SEJA_UM_MENTOR,
  HOME_SECAO_3_TITULO,
  HOME_SECAO_3_TEXTO_1,
  HOME_SECAO_3_TEXTO_2,
  HOME_SECAO_3_BOTAO_AGENDAR_MENTORIA,
  HOME_SOURCE,
} from "../../../messages";
import { ShowTranslationWarning } from "../../../utils/FormUtils";

export const indeThema = {
  tituloContainer: {
    padding: 0,
    width: "80%",
    margin: "0 auto",
    textAlign: "left",
  },
  titulo: {
    margin: 0,
    fontWeight: 900,
    fontSize: "4rem",
    color: "white",
  },
  subtitulo: {
    margin: "1vh",
    fontWeight: 180,
    fontSize: "2.66rem",
    lineHeight: "0.6rem",
    paddingBottom: 10,
  },
  intro: {
    width: "80%",
    margin: "0 auto",
    paddingTop: 3,
    text: {
      fontSize: "1.33rem",
      fontWeight: 350,
      fontStyle: "italic",
    },
  },
  content: {
    height: "40%",
    titulo: {
      text: {
        margin: "0",
        marginBottom: "1%",
        fontSize: "1.3rem",
        fontWeight: 600,
      },
    },
    image: {
      maxHeight: "80%",
    },
  },
  container1: {
    width: "100%",
    height: "10%",
    margin: "0 auto",
    display: "flex",
    alignContent: "center",
    item1: {
      height: "100%",
    },
  },
  section1: {
    paddingTop: 10,
  },
  section2: {
    width: "100%",
    height: "100vh",
    maxHeight: "100vh",

    color: "white",
    backgroundImage: `url("/static/images/background2mentoring.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "green",
    textShadow: "2px 2px 10px #000000",
    content: {
      height: "95%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    intro: {
      width: "80%",
      margin: "auto",
      paddingTop: 3,
      text: {
        fontSize: "1.33em",
        fontWeight: 350,
        fontStyle: "italic",
      },
    },
    titulo: {
      width: "80%",
      padding: 10,
      margin: "auto",
      textAlign: "left",
      text: {
        margin: 0,
        fontSize: "3.0em",
        fontWeight: 800,
        color: "white",
      },
    },
    steps: {
      height: "60vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      text: {
        fontSize: "1.11em",
      },
      image: {
        margin: "0 auto",
        maxWidth: "50%",
      },
    },
  },
  section3: {
    width: "100%",
    height: "100vh",
    color: "white",
    maxHeight: "100vh",
    position: "relative",
    backgroundImage: `url("/static/images/section3.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "blue",
    textShadow: "2px 2px 10px #000000",
    content: {
      padding: 36,
    },
    titulo: {
      width: "80%",
      margin: "0 auto",
      textAlign: "left",
      text: {
        margin: 0,
        fontSize: "3.5rem",
        fontWeight: 800,
        color: "white",
      },
    },
  },
  paperContainer: {
    color: "white",
    height: "100vh",
    backgroundImage: `url("/static/images/background1mentoring.jpg")`,
    backgroundPosition: "center",
    backgroundColor: "blue",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textShadow: "2px 2px 10px #000000",
  },
  btns: {
    btn1: {
      height: "100%",
      width: "57%",
      color: "white",
      backgroundColor: lightGreen[500],
      textShadow: "2px 2px 4px #000000",
    },
    findMentor: {
      height: "100%",
      width: "57%",
      color: "white",
      backgroundColor: "#9E3287",
      textShadow: "2px 2px 4px #000000",
    },
  },
  btnsSteps: {
    btnMentor: {
      height: "100%",
      width: "57%",
      color: "white",
      backgroundColor: "#c70160",
      textShadow: "2px 2px 4px #000000",
    },
    btnMentorado: {
      height: "100%",
      width: "57%",
      color: "white",
      backgroundColor: "#9E3287",
      textShadow: "2px 2px 4px #000000",
    },
  },
};

/**
 * Interface utilizada pelo componente IndexComponent e que descreve
 * seu métodos e propriedades.
 */
export interface IndexProps extends WithTranslation {
  msg?: any;
  thema?: any;
  t: any;
}

/**
 * Interface utilizada pelo componente IndexComponent e que descreve
 * seu métodos e propriedades.
 */
export class IndexState {
  height: any;
  width: any;
}

/**
 * Antiga homepage do Mentoring. Renderizava uma página com várias
 * informações e possuía os botões para realizar login e cadastro.
 * 
 * Este arquivo não é mais indexado já que o Mentoring apresenta
 * diretamente a tela de login.
 */
class IndexComponent extends Component<IndexProps, IndexState> {
  static defaultProps = {
    thema: indeThema,
  };

  section2: any;

  constructor(props: any) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  /**
   * Redimensiona a tela assim que o componente é carregado(montado).
   */
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  /**
   * Redimensiona a tela antes de 'desmontar' o componente.
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  /**
   * Atualiza o state com as dimensões da tela.
   */
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  /**
   * Configura o título da página conforme o usuário está logado ou não.
   */
  title =
    SessionStorageService.getStorage("usuario") === null
      ? "Login"
      : SessionStorageService.getStorage("usuario");

  /**
   * Executa o scroll para uma determinada posição na tela.
   * 
   * @returns A posição do scroll na tela.
   */
  executeScroll = () => window.scrollTo(0, this.section2.offsetTop);

  /**
   * Verifica se o usuário está logado, e caso esteja, redireciona-o
   * para a o link passado como parâmetro.
   * 
   * @param link O link da página para onde o usuário será redirecionado.
   */
  checkIfLoggedAndSaveRedirectLink(link) {
    if (SessionStorageService.getStorage("usuario") === null) {
      LocalStorageService.setStorage("redirectTo", link);
    }
  }

  /**
   * Verifica se a largura da tela é compatível com a
   * largura de telas de dispositivos movéis.
   * 
   * @returns True caso a largura seja compatível com a
   *          largura de telas de dispositivos movéis,
   *          False caso contrário.
   */
  isMobile() {
    return this.state.width < 700;
  }

  /**
   * Renderiza a tela com a antiga homepage.
   * 
   * @returns A tela com a antiga homepage.
   */
  render() {
    //Método de tradução
    const { t } = this.props;

    return (
      <React.Fragment>
        {/*TEMPORÁRIO*/}
        <ShowTranslationWarning />

        <div className={styleModule["paper-container"]}>
          <PublicAppBarComponent title={this.title}></PublicAppBarComponent>
          <div style={this.props.thema.tituloContainer}>
            <h1 className={styleModule["titulo"]}>
              {t(HOME_SECAO_1_TITULO, "Mentoring Stefanini")}
            </h1>
            <div>
              <h4 className={styleModule["subtitulo"]}>
                {t(HOME_SECAO_1_SUBTITULO_MAIOR, "Conexões que movem...")}
              </h4>
            </div>
          </div>
          <div className={styleModule["intro"]}>
            <p className={styleModule["intro-text"]}>
              {t(
                HOME_SECAO_1_SUBTITULO_MENOR,
                "Essa é uma oportunidade para conhecer pessoas, desenvolver habilidades e compartilhar experiências."
              )}
            </p>
          </div>
          <div className={styleModule["instructions"]}>
            <p className={styleModule["instructions-text"]}>
              {t(HOME_SECAO_1_TITULO_IMAGEM, "Como funciona?")}
            </p>

            <img
              alt="mentoring"
              src="/static/images/fluxo1.png"
              className={styleModule["instructions-image"]}
            />
          </div>
          {/* Botões: */}
          <Grid container className={styleModule["container1"]}>
            <Grid item xs={6} className={styleModule["container1-item"]}>
              <Link
                activeClass="active"
                to="section2"
                smooth={true}
                duration={500}
                spy={true}
              >
                <Button
                  className={styleModule["btn1"]}
                  type="button"
                  style={{ backgroundColor: lightGreen[500], color: "white" }}
                >
                  {this.isMobile()
                    ? t(HOME_SECAO_1_BOTAO_MENTOR_POCKET, "Cadastro de Mentor")
                    : t(
                      HOME_SECAO_1_BOTAO_MENTOR_COMPLETO,
                      "Cadastre-se e Compartilhe Experiências"
                    )}
                </Button>
              </Link>
            </Grid>

            <Grid item xs={6} className={styleModule["container1-item"]}>
              <Link
                activeClass="active"
                to="section3"
                smooth={true}
                duration={500}
                spy={true}
              >
                <Button
                  className={styleModule["find-mentor-btn"]}
                  type="button"
                  style={{ backgroundColor: "#9E3287", color: "white" }}
                >
                  {this.isMobile()
                    ? t(
                      HOME_SECAO_1_BOTAO_MENTORADO_POCKET,
                      "Agendar uma Mentoria"
                    )
                    : t(
                      HOME_SECAO_1_BOTAO_MENTORADO_COMPLETO,
                      "Encontre um Mentor"
                    )}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
        {/* MENTOR: */}
        <div id="section2" className={styleModule["section2"]}>
          <div className={styleModule["section2-content"]}>
            <div className={styleModule["section2-title"]}>
              <h1 className={styleModule["section2-title-text"]}>
                {t(HOME_SECAO_2_TITULO, "Quer compartilhar experiências?")}
              </h1>
            </div>

            <div className={styleModule["section2-intro"]}>
              <p className={styleModule["section2-intro-text"]}>
                {t(
                  HOME_SECAO_2_TEXTO_1,
                  "Saiba que este processo envolve muito aprendizado e contamos com você para que essa jornada seja leve, produtiva e inspiradora."
                )}
              </p>
            </div>
            <div className={styleModule["section2-steps"]}>
              <p className={styleModule["section2-steps-text"]}>
                {t(
                  HOME_SECAO_2_TEXTO_2,
                  "Antes de se cadastrar, confira as dicas exclusivas do time de Gente e Cultura, por Ana Lígia Bacca."
                )}
              </p>
              <div className={styleModule["section2-steps"]}>
                <iframe
                  width={this.isMobile() ? "80%" : "40%"}
                  height={this.isMobile() ? "60%" : "90%"}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  src="https://www.youtube.com/embed/JJT0lHx5xyE"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; full-screen"
                  allowFullScreen
                  frameBorder="0"
                  title="cadastrar"
                ></iframe>
              </div>
              <div>
                <Button
                  style={this.props.thema.btnsSteps.btnMentor}
                  type="button"
                  onClick={() =>
                    this.checkIfLoggedAndSaveRedirectLink("/mentoring/mentor")
                  }
                  component={NavLink}
                  to="/mentoring/mentor"
                >
                  {t(HOME_SECAO_2_BOTAO_SEJA_UM_MENTOR, "Seja um Mentor")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* MENTORADO: */}
        <div id="section3" className={styleModule["section3"]}>
          <div className={styleModule["section2-content"]}>
            <div className={styleModule["section2-title"]}>
              <h1 className={styleModule["section2-title-text"]}>
                {t(HOME_SECAO_3_TITULO, "Quer viver essa experiência?")}
              </h1>
            </div>

            <div className={styleModule["section2-intro"]}>
              <p className={styleModule["section2-intro-text"]}>
                {t(
                  HOME_SECAO_3_TEXTO_1,
                  "Ser mentorado é abrir-se ao networking, aprendizado e evolução profissional. Esteja disposto(a) a executar novas práticas e a compartilhar sua evolução."
                )}
              </p>
            </div>

            <div className={styleModule["section2-steps"]}>
              <p className={styleModule["section2-steps-text"]}>
                {t(
                  HOME_SECAO_3_TEXTO_2,
                  "Antes de agendar uma mentoria, confira as dicas exclusivas do time de Gente e Cultura, por Rafael Naoto"
                )}
              </p>
              <div className={styleModule["section2-steps"]}>
                <iframe
                  width={this.isMobile() ? "80%" : "40%"}
                  height={this.isMobile() ? "60%" : "90%"}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  src="https://www.youtube.com/embed/oGs-w9B1CyE"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="agendar"
                ></iframe>
              </div>
              <div>
                <Button
                  style={this.props.thema.btnsSteps.btnMentorado}
                  type="button"
                  onClick={() =>
                    this.checkIfLoggedAndSaveRedirectLink("/mentoring/agendar")
                  }
                  component={NavLink}
                  to="/mentoring/agendar"
                >
                  {t(
                    HOME_SECAO_3_BOTAO_AGENDAR_MENTORIA,
                    "Agende uma Mentoria"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation(HOME_SOURCE)(IndexComponent);
