import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  IndexComponent,
  Login,
  CategoriasComponent,
  MentorStepperComponent,
  CategoriasDashBoardComponent,
  MentorDashBoardComponent,
  ColaboradorDashBoardComponent,
  MentoradoStepper,
  MentoriaDashBoardComponent,
  NotFoundComponent,
  CadastroColaborador,
  GerenciamentoMentorias,
  PasswordChange,
  RetrievePasswordComponent,
  ResetPassword,
  RhAvaliacoesDashBoardComponent,
  PainelControleEstatistico,
  EdicaoColaborador,
  BifurcadorAgendamento,
  ProfileSwapper,
} from "../pages";

import {
  LoginProps,
  CategoriasProps,
  CategoriasDashBoardProps,
  MentorDashBoardProps,
  MentoriaDashBoardProps,
} from "../pages/props";

import { PublicApp, PrivateApp } from "../layout";

import { SessionStorageService } from "../service";

import PrivateRoute from "./components/private-route/PrivateRoute";
import authProvider from "../security/authProvider";
import BuscarMentorStepper from "../pages/private/mentorado/agendamento-mentoria/por-mentor/BuscarMentorStepper";
import FaqScreen from "../pages/private/geral/faq/FaqScreen";
import EdicaoMentor from "../pages/private/mentor/home/components/edicao-mentor/EdicaoMentor";
import HistoricoMentorias from "../pages/private/geral/historico-mentorias/HistoricoMentorias";
import MentoriasPendentesAvaliacao from "../pages/private/geral/mentorias-pendentes-avaliacao/MentoriasPendentesAvaliacao";

const categoriaProps = new CategoriasProps();
const categoriasDashBoardProps = new CategoriasDashBoardProps();
const mentorDashBoardProps = new MentorDashBoardProps();
const mentoriaDashBoardProps = new MentoriaDashBoardProps();

const checkAuthFunc: Function = authProvider.checkAuth;
const loginProps = new LoginProps();

/**
 * Renderiza todas as rotas públicas da aplicação.
 *
 * @returns Todas as rotas públicas da aplicação.
 */
export function ApplicationRoutes() {
  return (
    <React.Fragment>
      <Switch>
        {/* LOGIN ANTIGO: <Route exact path="/login2" render={() => <LoginComonent  {...loginProps} />} /> */}
        <Route exact path="/" render={() => <PublicApp />} />
        <Route exact path="/login" render={() => <PublicApp />} />
        <Route exact path="/cadastro" render={() => <PublicApp />} />
        <Route exact path="/recuperar-senha" render={() => <PublicApp />} />
        <Route path="/resetar-senha/" render={() => <PublicApp />} />
        <Route exact path="/index" render={() => <PublicApp />} />
        <PrivateRoute
          path="/mentoring"
          component={() => <PrivateApp />}
          authenticatedFunction={checkAuthFunc}
        />
        <Route
          render={() => (
            <NotFoundComponent
              response="Erro 404"
              msg="Página não encontrada"
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
}

/**
 * Renderiza algumas rotas privadas gerais da aplicação.
 *
 * @returns Algumas rotas privadas gerais da aplicação.
 */
export function MentoringRoutes() {
  return (
    <React.Fragment>
      <Switch>
        {/*Temporariamente mantendo url de perfil de mentor no /mentoring para fins de desenvolvimento*/}
        {/*<Route exact path="/mentoring/mentor" render={() => <MentorDashboard />} />*/}
        {/*<Route exact path="/mentoring/mentor/cadastro" render={() => <UpdateMentor/>}/>*/}
        {/*<Route exact path="/mentoring/perfilMentorado" render={() => <MentoradoDashboard/>}/>*/}
        {/*<Route exact path="/mentoring/avaliacao" render={() => <AvaliacaoComponent />} />*/}

        {/*-------------------------------------------------------------------------------------------*/}

        <Route
          exact
          path="/mentoring/home"
          render={() => <PainelControleEstatistico />}
        />
        <Route
          exact
          path="/mentoring/offline"
          render={() => <NotFoundComponent />}
        />

        <PrivateRoute
          exact
          path="/mentoring/categorias"
          component={CategoriasComponent}
          authenticatedFunction={checkAuthFunc}
          {...categoriaProps}
        />

        <PrivateRoute
          exact
          path="/mentoring/categoriadashboard"
          component={CategoriasDashBoardComponent}
          authenticatedFunction={checkAuthFunc}
          {...categoriasDashBoardProps}
        />

        <PrivateRoute
          exact
          path="/mentoring/mentordashboard"
          component={MentorDashBoardComponent}
          authenticatedFunction={checkAuthFunc}
          {...mentorDashBoardProps}
        />

        <PrivateRoute
          exact
          path="/mentoring/mentoriadashboard"
          component={MentoriaDashBoardComponent}
          authenticatedFunction={checkAuthFunc}
          {...mentoriaDashBoardProps}
        />

        <PrivateRoute
          exact
          path="/mentoring/avaliacoesdashboard"
          component={RhAvaliacoesDashBoardComponent}
          authenticatedFunction={checkAuthFunc}
        />

        <PrivateRoute
          exact
          path="/mentoring/colaboradoresdashboard"
          component={ColaboradorDashBoardComponent}
          authenticatedFunction={checkAuthFunc}
        />

        <Route
          render={() => (
            <NotFoundComponent
              response="Erro 404"
              msg="Página não encontrada"
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
}

/**
 * Renderiza as rotas privadas do mentor.
 *
 * @param props { session, mentoriaService }
 * @returns As rotas privadas do mentor.
 */
export function MentorRoutes(props) {
  //Não é solução final para requisição de mentorias de acordo com a role. Ou passa um callback/referencia de
  //um método, ou faz o tratamento com switch/case dentro do próprio componente
  const { session, mentoriaService } = props;

  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute
          exact
          path="/mentoring/mentor"
          authenticatedFunction={checkAuthFunc}
          component={MentorStepperComponent}
        />
        <PrivateRoute
          exact
          path="/mentoring/agendar/por-categoria"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <MentoradoStepper idMentorado={session.mentorado.id} />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/agendar"
          authenticatedFunction={checkAuthFunc}
          component={() => <BifurcadorAgendamento />}
        />
        <PrivateRoute
          exact
          path="/mentoring/agendar/por-mentor"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <BuscarMentorStepper idMentorado={session.mentorado.id} />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/mentor/edicao"
          authenticatedFunction={checkAuthFunc}
          component={() => <EdicaoMentor idMentor={session.mentor.id} />}
        />
        <PrivateRoute
          exact
          path="/mentoring/mentorias"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <GerenciamentoMentorias
              entityId={session.mentor.id}
              role={"ROLE_MENTOR"}
              getMentorias={(id) =>
                mentoriaService.getMentoriasFuturasMentor(id)
              }
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/historico-mentorias"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <HistoricoMentorias
              entityId={session.mentor.id}
              role={"ROLE_MENTOR"}
              getMentoriasPassadas={(id) =>
                mentoriaService.getMentoriasPassadasMentor(id)
              }
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/home"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <ProfileSwapper
              idMentor={session.mentor.id}
              idMentorado={session.mentorado.id}
              session={session}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/trocar-senha"
          authenticatedFunction={checkAuthFunc}
          component={() => <PasswordChange />}
        />
        <PrivateRoute
          exact
          path="/mentoring/faq"
          authenticatedFunction={checkAuthFunc}
          component={() => <FaqScreen />}
        />
        <Route
          render={() => (
            <NotFoundComponent
              response="Erro 404"
              msg="Página não encontrada"
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
}

/**
 * Renderiza as rotas privadas do mentorado.
 *
 * @param props { session, mentoriaService }
 * @returns As rotas privadas do mentorado.
 */
export function MentoradoRoutes(props) {
  //Não é solução final para requisição de mentorias de acordo com a role. Ou passa um callback/referencia de
  //um método, ou faz o tratamento com switch/case dentro do próprio componente
  const { session, mentoriaService } = props;

  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute
          exact
          path="/mentoring/mentor"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <MentorStepperComponent
              userEmail={SessionStorageService.getStorage("usuario") || ""}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/agendar/por-categoria"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <MentoradoStepper idMentorado={session.mentorado.id} />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/agendar/por-mentor"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <BuscarMentorStepper idMentorado={session.mentorado.id} />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/agendar"
          authenticatedFunction={checkAuthFunc}
          component={() => <BifurcadorAgendamento />}
        />
        <PrivateRoute
          exact
          path="/mentoring/mentorado/edicao"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <EdicaoColaborador
              emailColaborador={
                SessionStorageService.getStorage("usuario") || ""
              }
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/mentorias"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <GerenciamentoMentorias
              entityId={session.mentorado.id}
              role={"ROLE_MENTORADO"}
              getMentorias={(id) =>
                mentoriaService.getMentoriasFuturasMentorado(id)
              }
              showHistorico
              getHistoricoMentorias={(id) =>
                mentoriaService.getMentoriasPassadasMentorado(id)
              }
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/historico-mentorias"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <HistoricoMentorias
              entityId={session.mentorado.id}
              role={"ROLE_MENTORADO"}
              getMentoriasPassadas={(id) =>
                mentoriaService.getMentoriasPassadasMentorado(id)
              }
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/mentorias-pendentes-avaliacao"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <MentoriasPendentesAvaliacao
              entityId={session.mentorado.id}
              role={"ROLE_MENTORADO"}
              getMentoriasPendentesAvaliacao={(id) =>
                mentoriaService.getMentoriasPendentesAvaliacaoMentorado(id)
              }
            />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/home"
          authenticatedFunction={checkAuthFunc}
          component={() => (
            <ProfileSwapper idMentorado={session.mentorado.id} />
          )}
        />
        <PrivateRoute
          exact
          path="/mentoring/trocar-senha"
          authenticatedFunction={checkAuthFunc}
          component={() => <PasswordChange />}
        />
        <PrivateRoute
          exact
          path="/mentoring/faq"
          authenticatedFunction={checkAuthFunc}
          component={() => <FaqScreen />}
        />
        <Route
          render={() => (
            <NotFoundComponent
              response="Erro 404"
              msg="Página não encontrada"
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
}

/**
 * Renderiza todas as rotas públicas da aplicação.
 *
 * @returns Todas as rotas públicas da aplicação.
 */
export function PublicRoutes() {
  return (
    <React.Fragment>
      <Route exact path="/login" render={() => <Login {...loginProps} />} />
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route exact path="/cadastro" render={() => <CadastroColaborador />} />
      <Route exact path="/index" render={() => <IndexComponent />} />
      <Route
        exact
        path="/recuperar-senha"
        render={() => <RetrievePasswordComponent />}
      />
      <Route exact path="/resetar-senha/" render={() => <ResetPassword />} />
    </React.Fragment>
  );
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { PublicRoutes, MentoringRoutes };
