import React, { useContext, useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { ColaboradorService, MentoriaService, SessionStorageService, UserContext } from '../../../../service';
import { PerfisMenu } from '../../../components';
import './GradientAnimation.css';
import { makeStyles } from '@material-ui/core';

import BelgoAramesWhite from '../../../../images/logoBelgoAramesWhite.png';
import BelgoWhite from '../../../../images/logoBelgoWhite.png';
import MentoringLogo from '../../../../images/mentoring-logo.png';
import { Colaborador } from '../../../../models';

const useStyles = makeStyles({
	root: {
		'@media (max-width: 1024px)': {
			flexGrow: 1,
			display: 'flex',
			justifyContent: 'space-around',
			width: '100%'
		},
		'@media (min-width: 1030px)': {
			flexGrow: 1,
			display: 'flex',
			justifyContent: 'space-around',
			width: '100%'
		},
		'@media only screen and (max-width: 414px)': {
			width: '100%'
		},
	},
	toolbar: {
		'@media (max-width: 1024px)': {
			height: '112px',
		},
		'@media (min-width: 1030px)': {
			height: '112px',
		},
	},
	title: {
		'@media (max-width: 1024px)': {
			flexGrow: 1,
			fontSize: '0.5rem'
		},
		'@media (min-width: 1030px)': {
			flexGrow: 1
		}
	},
	profiles: {
		'@media only screen and (max-width: 414px)': {
			marginTop: '-30px',
		},
		'@media only screen and (min-width: 415px) and (max-width: 1024px)': {
			height: '30px',
			width: 'auto'
		},
	},
	appBar: {
		backgroundColor: '#003B4A',
		height: '112px',
		width: '100%',
	},
	appBarTitle: {
		backgroundImage: `url(${BelgoAramesWhite})`,
		backgroundRepeat: 'no-repeat',
		bottom: '71.48%',
		flex: '1 0 0',
		height: '63%',
		margin: '0 auto 0 5rem',
		width: '24.5%',

		'@media only screen and (max-width: 414px)': {
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			display: 'none',
			margin: '1.5rem auto 0 auto',
		},

		'@media only screen and (min-width: 415px) and (max-width: 720px)': {
			margin: '1.5rem auto 0 auto',
			width: '15%',
		}
	},
	appBarTitleStefaniniLogo: {
		display: 'none',

		'@media only screen and (max-width: 414px)': {
			backgroundImage: `url(${BelgoWhite})`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			display: 'block',
			flex: '1 0 0',
			height: '40%',
			marginTop: '1vh',
		}
	},
	appBarTitleMentoring: {
		display: 'none',

		'@media only screen and (max-width: 414px)': {
			backgroundImage: `url(${MentoringLogo})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			display: 'block',
			flex: '1 0 0',
			height: '50%',
			margin: '6vh 10vw 0 15px',
		}
	}
});

/**
 * Obtém as dimsensões da tela, em pixels.
 *
 * @returns A largura interior e a altura interior da tela, em pixels.
 */
function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

/**
 * Componente responsável pela renderização da appbar e seus elementos.
 *
 * @param props Propriedades passadas a este componente para fornecer melhor controle
 * 				sobre o seu funcionamento e comportamento.
 * @returns A appbar e seus elementos constituintes.
 */
export default function AppBarComponent(props) {
	const classes = useStyles();

	const mentoriaService: MentoriaService = new MentoriaService();
	const colaboradorService: ColaboradorService = new ColaboradorService();

	const { session, role, switchRole, logout, userObject, setUserObject } = useContext(UserContext);
	const [, setDimensions] = useState(getWindowDimensions());
	const [mentoriasPendentes, setMentoriasPendentes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState<Colaborador>();

	/**
	 * Configura o tamanho da aplicação conforme o tamanho da tela assim que a aplicação carrega.
	 */
	useEffect(() => {
		function handleResize() {
			setDimensions(getWindowDimensions());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	/**
	 * Obtém as informações do colaborador logado e também, caso haja, a lista de mentorias
	 * pendentes de avaliação.
	 */
	useEffect(() => {
		setLoading(true);

		colaboradorService.getByEmail(SessionStorageService.getStorage('usuario')).then(res => {
			let oldUser = userObject;
			oldUser.colaborador = res.data;
			setUser(res.data);
			setUserObject(oldUser);

			if (session.roles.length > 1 || !session.roles.includes('ROLE_ADMIN')) {
				mentoriaService.getMentoriasPendentesAvaliacaoMentorado(session.mentorado.id).then((result) => {
					setMentoriasPendentes(result.data);
				});
			}

			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classes.root}>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<Typography className={classes.appBarTitle}></Typography>

					<Typography className={classes.appBarTitleStefaniniLogo}></Typography>
					<Typography className={classes.appBarTitleMentoring}></Typography>

					<PerfisMenu
						className={classes.profiles}
						userObject={userObject}
						session={session}
						actualRole={role}
						roles={session.roles}
						logout={logout}
						switchRole={switchRole}
						user={user}
						mentoriasPendentes={mentoriasPendentes}
						loading={loading}
					/>
				</Toolbar>
			</AppBar>
		</div>
	);
}
