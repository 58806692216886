/**
 * Enum com atributos referentes a mentoria.
 */
export enum MentoriaAttribute {
    MENTOR = "mentor",
    DISPONIBILIDADE = "disponibilidade",
    CATEGORIA = "categoria",
    EMAIL = "email",
    NOME = "nome"
}

/**
 * Tipo que define propriedades de um agendamento de mentoria.
 */
export type AgendamentoMentoriaHandleChange = (
    atributo: MentoriaAttribute,
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLButtonElement>,
    id: string,
    value?: any
) => void