import { Button, Container, createStyles, makeStyles, MenuItem, Select, Switch, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Colaborador } from "../../../../../models";
import { ColaboradorService, UserService } from "../../../../../service";
import {
    RH_COLABORADORES_SOURCE,
    RH_COLABORADORES_NOME,
    RH_COLABORADORES_APROVADO,
    RH_COLABORADORES_INATIVO,
    RH_COLABORADORES_SEM_COLABORADORES,
    RH_COLABORADORES_GERENCIAR,
    RH_COLABORADORES_SEM_NOME,
    RH_COLABORADORES_EMAIL,
    RH_COLABORADORES_EDITAR,
    RH_COLABORADORES_BUSCAR,
    RH_COLABORADORES_CARGO,
    RH_COLABORADORES_DE
} from "../../../../../messages";
import { StatusBullet } from "../../../../../commons";
import { MenuRh } from "../../../../../components";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: '1rem',
            marginBottom: '50px',
            minHeight: 400,
            fontFamily: 'infraRegular',
            height: 'auto',
            backgroundColor: '#F0F1F3',
            paddingBottom: '1rem',

            '@media only screen and (max-width: 414px)': {
                width: '95vw'
            }
        },
        photo: {
            width: "10vh",
            height: "10vh",
            borderRadius: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        root: {
            marginTop: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            padding: 0,

            '@media only screen and (max-width: 414px)': {
                width: '100%'
            }
        },
        titleDiv: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',

            '@media only screen and (max-width: 414px)': {
                marginLeft: '1vw'
            }
        },
        title: {
            fontFamily: 'infraRegular',
            lineHeight: '150%',
            fontSize: '1.625rem',
        },
        button: {
            width: '4.813rem',
            height: '2.75rem'
        },
        link: {
            textDecoration: 'none'
        },
        downloadDiv: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        downloadButton: {
            margin: 4
        },
        search: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '1rem',
            marginBottom: '1rem',

            '@media only screen and (max-width: 720px)': {
                display: 'block',
            }
        },
        searchField: {
            marginRight: '1rem',
            backgroundColor: '#FFFFFF',

            '@media only screen and (max-width: 414px)': {
                margin: '.5vh 0',
                width: '90vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                margin: '.5vh 0',
                width: '85vw',
            }
        },
        searchFieldLabel: {
            '@media only screen and (max-width: 720px)': {
                fontSize: '.8rem'
            }
        },
        searchButton: {
            color: 'white',
            backgroundColor: "#003B4A",

            '&:hover': {
                backgroundColor: '#0a3039',
            },

            '@media only screen and (max-width: 414px)': {
                marginBottom: '5vh',
                marginTop: '.9vh',
                width: '90vw',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                marginBottom: '5vh',
                marginTop: '.9vh',
                width: '85vw',
            }
        },
        status: {
            marginLeft: theme.spacing(1),
        },
        dashboardTitle: {
            fontFamily: 'InfraRegular',
            fontStyle: 'normal',
            fontWeight: 600,

            '@media only screen and (max-width: 414px)': {
                fontSize: '1rem'
            }
        },
    })
);

/**
 * Estilização utilizada na tabela de colaboradores.
 */
const tableStyle = {
    container: {
        marginTop: '1rem',
        marginBottom: '50px',
        minHeight: 400,
        fontFamily: 'infraRegular',
        height: 'auto',
        backgroundColor: '#F0F1F3',
        paddingBottom: '1rem'
    },
    image: {
        width: '10vh',
        height: '10vh',
        borderRadius: '100%'
    },
    headerStyle: {
        backgroundColor: '#CFD4DA',
        fontFamily: 'infraMedium'
    },
    title: {
        fontFamily: 'infraMedium'
    },
    actionButton: {
        color: '#003B4A'
    },
    table: {
        backgroundColor: '#CFD4DA'
    },
    searchField: {
        backgroundColor: '#F0F1F3',
    },
    filter: {
        backgroundColor: '#F0F1F3',
        marginLeft: '1rem',
        paddingLeft: '1rem',
        paddingTop: '0.6rem'
    },
    switch: {
        color: '#003B4A',
    }
}

/**
 * Estilização utilizada de forma geral no componente.
 */
const GlobalCss = withStyles((theme) => ({
    "@global": {
        ".MuiTableRow-root": {
            "&:nth-of-type(even)": {
                backgroundColor: "#FFFFFF",
            },
            "&:nth-of-type(odd)": {
                backgroundColor: "#F8F8F8",
            },
        },
    },
}))(() => null);

/**
 * Interface utilizada na implementação das queries com parâmetros
 * relativos a aspectos da busca, como número de paǵinas e o tamanho
 * de cada página.
 */
interface IQuery {
    page: number;
    pageSize: number;
    search?: ISearch;
}

/**
 * Inteface utilizada na implementação de buscas de informações relativas
 * ao preenchimento do conteúdo da tabela, como nome, email e status.
 */
interface ISearch {
    nome: string,
    email: string,
    status: string
}

/**
 * Componente para renderizar a tabela de colaboradores e editar os mesmos.
 * 
 * Apenas acessível para o papel de ADMIN.
 */
const RhColaboradorDashBoardComponent = () => {
    // Hook de estilização do MaterialUI
    const classes = useStyle();

    // Hook de tradução
    const { t } = useTranslation([RH_COLABORADORES_SOURCE]);

    // Referência da tabela
    const referencia = React.useRef<any>();

    const colaboradorService = new ColaboradorService();
    const userService = new UserService();

    const [loading, setLoading] = React.useState(false);
    const [dataTable, setDataTable] = React.useState<Colaborador[]>([]);
    const [query, setQuery] = React.useState<IQuery>({ page: 0, pageSize: 5 });
    const [search, setSearch] = React.useState<ISearch>({ nome: '', email: '', status: '' });

    /**
     * Formata a string para não passar por alterações ao renderizar,
     * seguindo o tamanho de caracteres possíveis de apresentar.
     * 
     * @param string A string a ser formatada.
     * @param size O tamanho final desejado da string.
     * @returns {string} A string formatada.
     */
    const limitString = (string: string, size: number) => {
        if (!string) {
            return t(RH_COLABORADORES_SEM_NOME, "Sem nome");
        }
        if (string.length > size) {
            return string.substring(0, 25) + "...";
        }
        return string;
    };

    /**
     * Realiza uma bifurcação entre receber todos os colaboradores
     * ou os colaboradores com filtragem.
     * 
     * @param query Dados da query a ser realizada como página e o tamanho da query.
     * @returns Promise com os resultados da busca.
     */
    const handleData = (queryParam: any): Promise<any> => {
        setLoading(false);
        return query.search
            ? handleDataSearch(queryParam)
            : handleDataPagination(queryParam);
    };

    /**
     * Lida com a busca de dados dos colaboradores.
     * 
     * @param queryParam Dados da query a ser realizada como página e o tamanho da query.
     * @returns Promise com os resultados da busca.
     */
    const handleDataSearch = (queryParam: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            colaboradorService
                .dashboardSearch(queryParam.page, queryParam.querySize, query?.search)
                .then((response) => response.data)
                .then((result) => {
                    resolve({
                        data: result.conteudo,
                        page: result.numeroPaginaAtual,
                        totalCount: result.totalElementos,
                    });

                    let newData = dataTable;
                    newData.push(result.conteudo);
                    setLoading(false);
                    setDataTable(newData);
                });
        });
    };

    /**
     * Lida com a paginação dos dados.
     * 
     * @param query Dados da query a ser realizada como número da página atual
     *              e o tamanho de elementos.
     * @returns Promise com os resultados da busca.
     */
    const handleDataPagination = (query: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            colaboradorService
                .getPagination(query.page, query.pageSize)
                .then((response) => response.data)
                .then((result) => {
                    resolve({
                        data: result.conteudo,
                        page: result.numeroPaginaAtual,
                        totalCount: result.totalElementos,
                    });

                    let newData = dataTable;
                    newData.push(result.conteudo);
                    setLoading(false);
                    setDataTable(newData);
                });
        });
    };

    /**
     * Realiza atualização da tabela utilizando da referência salva.
     */
    const updateTable = () => {
        if (referencia) {
            referencia.current.onQueryChange();
        } else {
            setTimeout(updateTable, 250);
        }
    };

    /**
     * Atualiza o colaborador adicionando ou removendo o papel de ADMIN.
     * 
     * @param email O email do colaborador.
     */
    const handleAdmin = (email: string) => {
        setLoading(true);

        userService.setAdminRole(email).then((response) => {
            updateTable();
        });
    };

    /**
     * Atualiza o state de pesquisa com os novos valores de pesquisa. 
     * 
     * @param e O evento que dispara a atualização do state de pesquisa,
     *          com os atributos nome e valor.
     */
    const handleChange = (e: React.ChangeEvent<any>) => {
        const { name, value } = e.target;
        let newSearch = search;
        newSearch[name] = value;

        setSearch(newSearch);
    }

    /**
     * Realiza a pesquisa e atualiza a tabela com os novos colaboradores filtrados.
     */
    const handleSearch = () => {
        let newQuery = query;
        newQuery.search = search;
        setQuery(newQuery);
        handleData(newQuery);
        updateTable();
    }

    return (
        <>
            {/* Sub header */}
            <Container maxWidth="lg" fixed className={classes.root}>
                <MenuRh />
                <div className={classes.titleDiv}>
                    <Typography variant="h5" align="left" className={classes.dashboardTitle}>{t(RH_COLABORADORES_GERENCIAR, "Gerenciar Colaboradores")}</Typography>
                </div>
            </Container>
            <GlobalCss />
            {/* Body */}
            <Container maxWidth="lg" fixed className={classes.container}>
                <div className={classes.search}>
                    {/* FILTROS */}
                    <TextField
                        id="nome"
                        name="nome"
                        label={t(RH_COLABORADORES_NOME, "Nome")}
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                        className={classes.searchField}
                        onChange={handleChange}
                        InputLabelProps={{
                            className: classes.searchFieldLabel
                        }}
                    />
                    <TextField
                        id="email"
                        name="email"
                        label={t(RH_COLABORADORES_EMAIL, "E-mail")}
                        variant="outlined"
                        className={classes.searchField}
                        onChange={handleChange}
                        InputLabelProps={{
                            className: classes.searchFieldLabel
                        }}
                    />
                    <Select
                        id="status"
                        name="status"
                        className={classes.searchField}
                        onChange={handleChange}
                        displayEmpty
                        variant="outlined"
                        defaultValue=""
                    >
                        <MenuItem value="">Status</MenuItem>
                        <MenuItem value="ATIVO">{t(RH_COLABORADORES_APROVADO, "Ativo")}</MenuItem>
                        <MenuItem value="REMOVIDO">{t(RH_COLABORADORES_INATIVO, "Inativo")}</MenuItem>
                    </Select>
                    <Button className={classes.searchButton} variant="contained" onClick={handleSearch}>{t(RH_COLABORADORES_BUSCAR, 'Buscar')}</Button>
                </div>
                <MaterialTable
                    title="Colaboradores"
                    tableRef={referencia}
                    style={tableStyle.table}
                    localization={{
                        header: {
                            actions: t(RH_COLABORADORES_EDITAR, "Editar"),
                        },
                        body: {
                            emptyDataSourceMessage: t(
                                RH_COLABORADORES_SEM_COLABORADORES,
                                "Sem colaboradores para exibir"
                            ),
                            editTooltip: t(RH_COLABORADORES_EDITAR, "Editar"),
                        },
                        pagination: {
                            labelDisplayedRows: `{from}-{to} ${t(RH_COLABORADORES_DE, 'de')} {count}`,
                            labelRowsSelect: "itens",
                        },
                        toolbar: {
                            searchTooltip: t(RH_COLABORADORES_BUSCAR, "Buscar"),
                            searchPlaceholder: t(RH_COLABORADORES_BUSCAR, "Buscar"),
                        },
                    }}
                    columns={[
                        {
                            title: "",
                            field: "foto",
                            editable: "never",
                            render: (rowData) => (
                                <div
                                    style={{
                                        backgroundImage: `url(${rowData.foto})`,
                                    }}
                                    className={classes.photo}
                                />
                            ),
                        },
                        {
                            title: t(RH_COLABORADORES_NOME, "Nome"),
                            field: "nome",
                            render: (data) => (
                                <p>{limitString(data.nome, 25)}</p>
                            ),
                            editable: "never",
                        },
                        {
                            title: t(RH_COLABORADORES_CARGO, "Cargo"),
                            field: "cargo",
                            render: (data) => (
                                <p>{data.cargo?.substr(0, 45) + "..."}</p>
                            ),
                        },
                        { title: "Email", field: "email", editable: "never" },
                        {
                            title: "Status",
                            field: "status",
                            lookup: {
                                ATIVO: <> {t(RH_COLABORADORES_APROVADO, "Ativo")} <StatusBullet className={classes.status} color="success" size="sm" /> </>,
                                INATIVO: <> {t(RH_COLABORADORES_INATIVO, "Inativo")} <StatusBullet className={classes.status} color="danger" size="sm" /> </>,
                            },
                        },
                        {
                            title: "Admin",
                            field: "roles",
                            render: (rowData) => (
                                <Switch
                                    checked={rowData.roles.includes(
                                        "ROLE_ADMIN"
                                    )}
                                    style={rowData.roles.includes(
                                        "ROLE_ADMIN"
                                    ) ? tableStyle.switch : undefined}
                                    color="primary"
                                    onChange={(e) => handleAdmin(rowData.email)}
                                    name="adminSwitch"
                                    inputProps={{
                                        "aria-label": "checkbox",
                                    }}
                                />
                            ),
                        },
                    ]}
                    data={(query: any) => handleData(query)}
                    isLoading={loading}
                    options={{
                        paginationType: "normal",
                        pageSize: query.pageSize,
                        toolbar: false,
                        search: false,
                        filtering: false,
                        sorting: false,
                        headerStyle: tableStyle.headerStyle,
                        searchFieldStyle: tableStyle.searchField,
                        showTitle: false
                    }}
                    onRowsPerPageChange={(pageSize: number) => {
                        let newQuery = query;
                        newQuery.pageSize = pageSize;
                        setQuery(newQuery);
                    }}
                    onPageChange={(page: number) => {
                        let newQuery = query;
                        newQuery.page = page;
                        setQuery(newQuery);
                    }}
                />
            </Container>
        </>
    );
};

export default RhColaboradorDashBoardComponent;
