import React, { useEffect, useContext, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Typography, Snackbar, Button } from "@material-ui/core";

import { UserContext } from "../../../../service";
import { Mentoria } from "../../../../models";
import { AvaliacaoComponent, MentoriaTable } from "../../../../components";
import { SpinnerSplendis, ModalSucesso } from "../../../../commons";

import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import Alert from "@material-ui/lab/Alert";
import { getWindowDimensions } from "../../../../utils/FormUtils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    GERENCIAMENTO_MENTORIAS_SOURCE,
    GERENCIAMENTO_MENTORIAS_MENTOR_TITULO,
    GERENCIAMENTO_MENTORIAS_MENTOR_HISTORICO_COMENTARIOS,
    GERENCIAMENTO_MENTORIAS_MENTORADO_TITULO,
    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_TITULO,
    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_SUBTITULO,
    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_AVALIAR,
    GERENCIAMENTO_MENTORIAS_VOLTAR,
    GERENCIAMENTO_MENTORIAS_AVALIACAO_TITULO,
    GERENCIAMENTO_MENTORIAS_AVALIACAO_SUBTITULO,
    GERENCIAMENTO_MENTORIAS_INFO_MESSAGE
} from "../../../../messages";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: "89vh",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
        },

        content: {
            height: "90%",
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
        },

        titleContainer: {
            height: "5%",
            width: "80%",
            textAlign: "left",
        },

        subtitleContainer: {
            display: "flex",
            alignContent: "flex-start",
            height: "25%",
            maxHeight: 100,
            flexDirection: "column",
            textAlign: "left",
            "@media (max-width: 1024px)": {
                fontSize: "0.7rem",
                maxHeight: 200,
                height: "auto",
            },
        },

        title: {
            fontWeight: 600,
            "@media (max-width: 1024px)": {
                fontSize: "1rem",
            },
        },

        subtitle: {
            "@media (max-width: 1024px)": {
                fontSize: "0.8rem",
            },
        },

        // NOVO E ANTIGO:
        reviewHorarios: {
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            "@media (max-width: 1024px)": {
                border: "solid",
                borderWidth: "thin",
                padding: "5px",
                borderRadius: "5%",
                borderColor: "silver",
            },
        },

        // BOTOES DE CANCELAMENTO:
        cancelarBtns: {
            "@media (max-width: 1024px)": {
                margin: 4,
                fontSize: "0.7rem",
                padding: "6px 10px",
            },
        },

        alertContainer: {
            height: 50,
            display: "flex",
            alignItems: "center",
            textAlign: "left",
        },

        actionButtonOuterContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },

        actionButtonInnerContainer: {
            width: "60%",
            maxWidth: 300,
            display: "flex",
            justifyContent: "space-around",
        },

        closeButtonContainer: {
            width: "auto",
            display: "flex",
            justifyContent: "space-around",
        },

        rescheduleAlert: {
            marginBottom: 16,
        },

        forwardArrow: {
            display: "flex",
            alignItems: "center",
        },

        alertInfoMessage: {
            opacity: 0.9,
        },

        mentorRole: {
            height: "100%",
        },

        notMentorRole: {
            height: "50%",
        },

        futureMentoringTable: {
            height: "62%",
        },

        futureMentoringSpinner: {
            alignSelf: "center",
        },

        mentoringHistory: {
            height: "50%",
        },

        mentoringHistoryTable: {
            height: "57%"
        },

        mentoringHistorySpinner: {
            alignSelf: "center",
        },

        spanDiv: {
            textAlign: "end"
        },

        spanText: {
            color: "#AAABAC"
        },

        backButton: {
            bottom: 0,
            color: '#003B4A',
            display: 'flex',
            fontFamily: 'InfraRegular',
            fontSize: '.8rem',
            fontStyle: 'normal',
            fontWeight: 500,
            left: '45%',
            margin: '2vh 2vh',
            position: 'absolute',

            '@media only screen and (max-width: 414px)': {
                bottom: '-30vh',
                left: '30%',
                marginTop: '3vh',
                position: 'absolute'
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                bottom: '-110vh',
                left: '40%',
                marginTop: '3vh',
                position: 'absolute'
            },
        },
    })
);

/**
 * Interface utilizada pelo componente HistoricoMentorias e que descreve
 * seu métodos e propriedades.
 */
interface HistoricoMentoriasProps {
    entityId: number;
    role: string;
    getMentoriasPassadas: any;
}

/**
 * Componente que exibe o histórico de mentorias do usuário.
 * 
 * @param {HistoricoMentoriasProps}
 * @returns O histórico de mentorias do usuário.
 */
const HistoricoMentorias: React.FC<HistoricoMentoriasProps> = ({ entityId, role, getMentoriasPassadas }) => {
    const classes = useStyles();
    const history = useHistory();

    const { session, switchRole } = useContext(UserContext);
    const { t } = useTranslation([GERENCIAMENTO_MENTORIAS_SOURCE]);

    // States de avaliação
    const [openModalAvaliar, setOpenModalAvaliar] = useState<boolean>(false);
    const [openModalAvaliacaoSucesso, setOpenModalAvaliacaoSucesso] = useState<boolean>(false);
    const [mentoriaParaAvaliar, setMentoriaParaAvaliar] = useState<any>();

    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [dimensions, setDimensions] = useState(getWindowDimensions());

    const [isHistoricoLoading, setIsHistoricoLoading] = useState<boolean>(false);
    const [historico, setHistorico] = useState<Mentoria[]>([]);
    const [showHistorico, setShowHistorico] = useState(true);

    /**
     * Obtém todas as mentorias que já ocorreram.
     */
    useEffect(() => {
        getMentoriasPassadas(entityId)
            .then(mentoriasPassadas => {
                setHistorico(mentoriasPassadas.data);
            });
    }, [entityId, getMentoriasPassadas]);

    /**
     * Obtém as dimensões da tela e configura o state de dimensões.
     */
    useEffect(() => {
        const handleResize = () => {
            setDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    /**
     * Atualiza o histórico de mentorias dependendo do valor da
     * prop showHistorico.
     */
    useEffect(() => {
        if (showHistorico) {
            updateHistorico();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, getMentoriasPassadas, showHistorico]);

    /**
     * Verifica se a largura da tela está no tamanho de telas para
     * dispositivos móveis. 
     * 
     * @returns True ou False caso a largura da tela esteja no tamanho
     *          de telas para dispositivos móveis ou não.
     */
    const isMobile = () => {
        return dimensions.width < 1024;
    };

    /**
     * Atualiza o histórico de mentorias.
     */
    const updateHistorico = () => {
        setIsHistoricoLoading(true);
        getMentoriasPassadas(entityId)
            .then((res) => {
                setHistorico(res.data);
                setShowHistorico(true);
            })
            .catch(() => {
                setHistorico([]);
                setShowHistorico(false);
            })
            .then(() => setIsHistoricoLoading(false));
    }

    /**
     * Configura o título da página de acordo com o papel do usuário.
     * 
     * @param role O papel do usuário.
     * @returns O títiulo de acordo com o papel do usuário.
     */
    const titleByRole = (role) => {
        switch (role) {
            case "ROLE_MENTOR":
                return t(GERENCIAMENTO_MENTORIAS_MENTOR_TITULO, "Mentor");
            case "ROLE_MENTORADO":
                return t(GERENCIAMENTO_MENTORIAS_MENTORADO_TITULO, "Mentorado");
        }
    };

    /**
     * Abre o modal de avaliação de mentoria e configura
     * o state de mentoria para avaliar.
     * 
     * @param mentoria A mentoria a ser avaliada.
     */
    const openAvaliacaoModal = (mentoria) => {
        setMentoriaParaAvaliar(mentoria);
        setOpenModalAvaliar(true);
    }

    /**
     * Fecha o modal de avaliação de mentoria e configura
     * o state de mentoria para avaliar.
     */
    const closeAvaliacaoModal = () => {
        setOpenModalAvaliar(false);
    }

    /**
     * Abre o modal de sucesso de avaliação de mentoria
     * configurando o state de sucesso de avaliação.
     */
    const openAvaliacaoSucessoModal = () => {
        setOpenModalAvaliacaoSucesso(true);
    }

    /**
     * Fecha o modal de sucesso de avaliação de mentoria
     * configurando o state de sucesso de avaliação.
     */
    const closeAvaliacaoSucessoModal = () => {
        setOpenModalAvaliacaoSucesso(false);

        //Ao fechar modal de sucesso, atualiza lista de mentorias pendentes
        updateHistorico();
        setOpenModalAvaliar(false);
    }

    /**
     * Gerencia o processo final de avaliação de mentoria, fechando o modal
     * de avaliação e abrindo o modal de sucesso de avaliação.
     */
    const handleAvaliacaoSucesso = () => {
        closeAvaliacaoModal();
        openAvaliacaoSucessoModal();
    }

    /**
     * Modal de sucesso de avaliação de mentoria.
     * 
     * @returns O modal de sucesso de avaliação de mentoria.
     */
    const sucessoAvaliarMentoriaModal = () => {
        return <ModalSucesso
            isOpen={openModalAvaliacaoSucesso}
            handleClose={closeAvaliacaoSucessoModal}
            title={t(GERENCIAMENTO_MENTORIAS_AVALIACAO_TITULO, "Obrigado pela contribuição!")}
            subtitle={t(GERENCIAMENTO_MENTORIAS_AVALIACAO_SUBTITULO, "Sua avaliação foi enviada para o RH.")} />
    }

    /**
     * Modal de avaliação de mentoria.
     * 
     * @param mentoria A mentoria a ser avaliada.
     * @returns O modal de avaliação de mentoria.
     */
    const avaliacaoModal = (mentoria) => {
        if (mentoria) {
            const newMentoriaPendente = { ...mentoria, mentoriaId: mentoria.id, mentoradoId: session.mentorado.id };

            return <AvaliacaoComponent
                isOpen={openModalAvaliar}
                handleClose={closeAvaliacaoModal}
                mentoria={newMentoriaPendente}
                submitted={handleAvaliacaoSucesso} />
        }
    }

    /**
     * Função responsável por renderizar todos os modais deste componente.
     * 
     * @returns Um fragment que renderiza todos os modais deste componente.
     */
    const modais = () => {
        return (
            <React.Fragment>
                {avaliacaoModal(mentoriaParaAvaliar)}
                {sucessoAvaliarMentoriaModal()}
            </React.Fragment>
        );
    };

    /**
     * Gerencia a exibição de mensagens de alerta ao usuário.
     * 
     * @param message A mensagem a ser exibida ao usuário.
     * @returns Um Snackbar com a mensagem ao usuário.
     */
    const handleInfoMessage = (message) => {
        return (
            <Snackbar
                open={showMessage}
                onClose={() => setShowMessage(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    className={classes.alertInfoMessage}
                    onClose={() => setShowMessage(false)}
                    severity="info"
                >
                    {message}
                </Alert>
            </Snackbar>
        );
    };

    return (
        <Container className={classes.root}>
            {modais()}
            <div className={classes.content}>
                {showHistorico ? (
                    <div className={classes.mentoringHistory}>
                        <div className={classes.subtitleContainer}>
                            <Typography
                                className={classes.title}
                                variant="h5"
                                align="left"
                            >
                                {t(
                                    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_TITULO,
                                    `Seu Histórico de Mentorias como ${titleByRole(role)}`
                                )}
                            </Typography>
                            <Typography
                                className={classes.subtitle}
                                variant="subtitle2"
                                gutterBottom
                            >
                                {t(
                                    GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_SUBTITULO,
                                    'Veja as mentorias que já aconteceram (ou que foram canceladas)'
                                )}
                            </Typography>
                        </div>
                        <div className={classes.mentoringHistoryTable}>
                            {isHistoricoLoading ? (
                                <div
                                    className={classes.mentoringHistorySpinner}
                                >
                                    <SpinnerSplendis />
                                </div>
                            ) : (
                                <div style={{ height: '25rem' }}>
                                    <MentoriaTable
                                        asRole={role}
                                        mentorias={historico}
                                        avaliarMentoriaCallback={(mentoria) => {
                                            openAvaliacaoModal(mentoria)
                                        }}
                                        cellPadding={isMobile() ? 8 : "auto"}
                                    />
                                    <div className={classes.spanDiv}>
                                        {
                                            role === "ROLE_MENTOR" ?
                                                <span className={classes.spanText}>{t(GERENCIAMENTO_MENTORIAS_MENTOR_HISTORICO_COMENTARIOS, "Clique na mentoria para ver os comentários")}</span>
                                                :
                                                <span className={classes.spanText}>{t(GERENCIAMENTO_MENTORIAS_MENTORADO_HISTORICO_AVALIAR, "Clique na mentoria para avaliar")}</span>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    session.roles.includes('ROLE_MENTOR') ? switchRole('ROLE_MENTOR') : switchRole('ROLE_MENTORADO');
                                    history.push("/mentoring/home");
                                }}
                                className={classes.backButton}
                                startIcon={<ArrowBackIosSharpIcon fontSize='large' />}
                                size='large'
                            >
                                {t(GERENCIAMENTO_MENTORIAS_VOLTAR, 'Voltar')}
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
            {isMobile() &&
                handleInfoMessage(
                    t(GERENCIAMENTO_MENTORIAS_INFO_MESSAGE, "Você pode rolar a tabela horizontalmente e verticalmente para visualizar os dados")
                )}
        </Container>
    );
}

export default HistoricoMentorias;
