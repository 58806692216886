import React from 'react';

import {
    Card,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Tooltip
} from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { SpinnerSplendis } from '../../../../../../commons';
import {
    HOME_MENTOR_SOURCE,
    HOME_MENTOR_CARD_MEDIA_AVALIACAO_LABEL,
    HOME_MENTOR_HISTORICO_MENTORIAS_BOTAO,
    HOME_MENTOR_CARD_EDITAR_MENTOR
} from '../../../../../../messages';
import { limitString } from '../../../../../../utils/FormUtils';
import StarRatings from 'react-star-ratings';
import { Colaborador } from '../../../../../../models';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '18.2rem',
            height: '30rem',
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            border: '1px solid #D3CAB7',

            '@media only screen and (max-width: 414px)': {
                height: 'auto',
                margin: '5vh auto',
                width: '100%',
            },

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                height: 'auto',
                margin: '0 auto',
                width: '50%',
                marginTop: 20,
                marginBottom: '2vh'
            }
        },
        name: {
            fontFamily: 'nexaRegular',
            fontSize: '1.5rem',
            lineHeight: '2.9rem',
            marginBottom: '0.5rem',
            paddingBottom: '5px',
            height: '2.2rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#636466',
            '@media only screen and (max-width: 1024px)': {
                marginBottom: '0.7rem',
            }
        },
        cargo: {
            fontFamily: 'infraLight',
            fontWeight: 300,
            color: '#636466',
            paddingTop: 0,
            marginTop: 0,
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
        },
        email: {
            fontFamily: 'infraLight',
            fontWeight: 300,
            color: '#1F1F1F',
            paddingTop: 0,
            marginTop: 0,
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
        },
        mediaDiv: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1.1rem',
            marginBottom: 0,
        },
        media: {
            display: 'block',
            height: '7.6rem',
            width: '7.6rem',
            borderRadius: '50%',
        },
        mediaEdit: {
            backgroundColor: '#003B4A',
            border: '1px solid #FFF',
            borderRadius: '50%',
            color: '#FFF',
            display: 'flex',
            float: 'right',
            height: '1.2rem',
            margin: '.5rem .5rem',
            padding: '.5rem .5rem',
            width: '1.2rem',

            '&:hover': {
                cursor: 'pointer',
                opacity: '.7'
            },
        },
        descriptionContainer: {
            maxHeight: '100%',
            width: '100%',
            marginTop: '1rem'
        },
        cardContent: {
            padding: 10,
            height: '13vh',

            '@media only screen and (max-width: 1024)': {
                height: '9vh',
            }
        },
        button: {
            width: '14rem',
            height: '2.75rem',
            margin: '0 auto',
            fontFamily: 'infraMedium',
            marginBottom: '1rem',
            color: '#FFF',
            backgroundColor: '#003B4A',

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
                margin: '1vh 1vw 5vh 1vw',
                width: '12rem',
            }
        },
        divider: {
            width: '14rem',
            marginBottom: '0.8rem',

            '@media only screen and (max-width: 414px)': {
				marginTop: '8vh',
			},

            '@media only screen and (min-width: 415px) and (max-width: 720px)': {
				marginTop: '20vh',
			},
        },
        spinnerContainer: {
            height: 350,
            width: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        score: {
            marginBottom: '0.125rem',
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem'
        },

    })
);

/**
 * Interface utilizada pelo componente MentorDashboardCard e que descreve
 * seu métodos e propriedades.
 */
interface MentorDashboardCardProps {
    notaMentor: any;
    colaborador?: Colaborador;
    style?: any;
    updateMentor: Function;
    mentor: any;
    alertCallback: Function;
}

/**
 * Componente que renderiza o card com as informações do mentor no dashboard de mentor.
 * 
 * @param {MentorDashboardCardProps}
 * @returns O card com as informações do mentor.
 */
const MentorDashboardCard: React.FC<MentorDashboardCardProps> = ({ notaMentor, colaborador, style, updateMentor, mentor, alertCallback }) => {
    const { t } = useTranslation([HOME_MENTOR_SOURCE]);

    const classes = useStyles();

    const history = useHistory();

    /**
     * Renderiza um loading spinner do card.
     * 
     * @returns O loading spinner.
     */
    const spinnerCentralizado = () => {
        return (
            <div
                className={classes.spinnerContainer}>
                <SpinnerSplendis />
            </div>
        )
    }

    /**
     * Verifica a nota de avaliação do mentor.
     * 
     * @param mentorRating A nota de avaliação do mentor.
     * @returns True caso a nota do mentor seja não negativa, False caso contrário.
     */
    const checkMentorRating = (mentorRating: number): boolean => {
        return mentorRating > -1;
    }

    /**
     * Renderiza o conteúdo do card do mentor.
     * 
     * @returns As informações do card do mentor(i.e. botão para editar o mentor,
     *          botão para acessar o histórico de mentorias, nota de avaliação etc.).
     */
    const content = () => {
        return (
            <>
                <div>
                    <Link to={{
                        pathname: '/mentoring/mentor/edicao',
                        state: {
                            mentor,
                            updateMentor,
                            alertCallback
                        }
                    }}>
                        <Tooltip arrow title={t(HOME_MENTOR_CARD_EDITAR_MENTOR, 'Editar Mentor') || ''}>
                            <EditIcon className={classes.mediaEdit} />
                        </Tooltip>
                    </Link>
                </div>
                <div className={classes.mediaDiv}>
                    <CardMedia className={classes.media} image={colaborador?.foto} title={colaborador?.nome} />
                </div>
                <CardContent className={classes.cardContent}>
                    <div>
                        <Typography gutterBottom className={classes.name} variant="h5" component="h3" noWrap={false}>
                            {limitString(colaborador?.nome || "", 22)}
                        </Typography>
                        <Typography gutterBottom className={classes.cargo} variant="body2"
                            component="p" noWrap={true}>
                            {colaborador?.cargo}
                        </Typography>
                        <Typography gutterBottom className={classes.email} variant="body2"
                            component="p" noWrap={true}>
                            {colaborador?.email}
                        </Typography>
                    </div>
                </CardContent>
                <div className={classes.score}>
                    <hr className={classes.divider} />

                    <Typography gutterBottom className={classes.cargo} variant="body2"
                        component="p" noWrap={false}>
                        {t(HOME_MENTOR_CARD_MEDIA_AVALIACAO_LABEL, "Sua média de avaliações como Mentor")}:
                    </Typography>
                    <StarRatings
                        rating={notaMentor === "NaN" ? 0 : notaMentor}
                        starRatedColor="#FFB400"
                        starDimension="1.375rem"
                        starSpacing="0.125rem"
                    />
                </div>
                <div>
                    <hr className={classes.divider} />

                    <Button
                        className={classes.button}
                        onClick={() => history.push('/mentoring/historico-mentorias')}
                        size="medium"
                        variant="contained"
                    >
                        {t(HOME_MENTOR_HISTORICO_MENTORIAS_BOTAO, "Histórico de mentorias")}
                    </Button>
                </div>
            </>
        )
    }

    return (
        <Card style={style ? style : {}} className={classes.root}>
            { (checkMentorRating(notaMentor) && colaborador) ? content() : spinnerCentralizado()}
        </Card>
    );
}

export default MentorDashboardCard;