/**
 * Obtém os papeis dos usuários de acordo com a prioridade.
 * 
 * @param roles Os papeis dos usuários.
 * @returns O papel do usuário.
 */
export const rolePriority = (roles) => {
    //
    let first_priority = roles.includes('ROLE_ADMIN');
    let second_priority = roles.includes('ROLE_MENTOR');

    if (first_priority) {
        return 'ROLE_ADMIN';
    }
    if (second_priority) {
        return 'ROLE_MENTOR';
    }

    return 'ROLE_MENTORADO';
}