import React, { useEffect, useState } from "react";
import {
    Button,
    colors,
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from "@material-ui/core";
import { CustomModal, SpinnerSplendis } from "../../../../../commons";
import PessoaSelecionarSVG from "../../../../../assets/svgs/pessoa-escolha.svg";
import PessoaAtencaoSVG from "../../../../../assets/svgs/pessoa-atencao.svg";
import MentoriaModal from "../../../../../commons/modals/MentoriaModal";
import {
    formatDateToMaterialUIDate,
    getDateISO,
    tomorrow,
    thirtyDaysAfter,
    formatFromMaterialUIDate,
} from "../../../../../utils/FormUtils";
import { Formik } from "formik";
import { FormikTextField } from "formik-material-fields";
import { ReagendamentoSchema } from "../validations";
import { useTranslation } from 'react-i18next';
import {
    MENTORIA_MODAL_TITULO,
    MENTORIA_MODAL_SOURCE,
    MENTORIA_MODAL_DATA,
    MENTORIA_MODAL_HORARIO,
    MENTORIA_MODAL_BOTAO_ATUALIZAR,
    MENTORIA_MODAL_DATA_MARCADA,
    MENTORIA_MODAL_HORARIO_MARCADO,
    MENTORIA_MODAL_ATENCAO,
    MENTORIA_MODAL_AVISO_MENTORADO_REAGENDAR_MENTORIA_TXT_1,
    MENTORIA_MODAL_AVISO_MENTORADO_REAGENDAR_MENTORIA_TXT_2,
    MENTORIA_MODAL_AVISO_MENTOR_REAGENDAR_MENTORIA_TXT_1,
    MENTORIA_MODAL_AVISO_MENTOR_REAGENDAR_MENTORIA_TXT_2,
    MENTORIA_MODAL_BOTAL_AVANCAR
} from "../../../../../messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "17rem",
        },
        confirmarContainer: {
            fontFamily: "infraRegular",
        },
        button: {
            fontFamily: "Roboto",
            width: "16rem",
            marginLeft: "0.65rem",
            marginTop: "0.5rem",
        },
        statusContainer: {
            textAlign: "initial",
            marginLeft: "1rem",
        },
        status: {
            marginLeft: theme.spacing(1),
        },
        buttonConfirmar: {
            fontFamily: "Roboto",
            width: "100%",
            color: "#003B4A",
            backgroundColor: "#fff",
            border: "1px solid #003B4A",
            marginTop: 0,
            "& span": {
                height: "2rem",
            },
            "&:hover": {
                backgroundColor: "#003B4A",
                color: "#fff",
            }
        },
        reagendarInputs: {
            height: "auto",
            width: "100%",
            margin: "0 auto",
            display: "block",
            justifyContent: "space-between",
            alignItems: "center",
            "&>div": {
                marginBottom: "1.6rem"
            },
            "@media (max-width: 1024px)": {
                alignItems: "initial",
                marginTop: "1rem",
            },
        },
        rescheduleAlert: {
            marginBottom: "0.6rem",
            marginTop: "0.4rem"
        },
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: 'auto',
        width: '28rem',
        textAlign: '-webkit-center',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: 'auto',
        textAlign: 'center',
        width: '70vw',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: 'auto',
        marginTop: '75vh',
        textAlign: 'center',
        width: '70vw',
    },
};

/**
 * Estilização utilizada no componente de data.
 */
const GlobalCss = withStyles({
    "@global": {
        ".MuiTextField-root": {
            width: "100%",
            height: "2.75rem",
            marginBottom: "2rem",
            "& > div": {
                "& > fieldset": {
                    borderColor: "#003B4A"
                }
            }
        },
        ".MuiOutlinedInput-input": {
            "@media (max-width: 1024px)": {
                padding: "12px 7px",
            },
        },
    },
})(() => null);

/**
 * Interface utilizada pelo componente ReagendarMentoriaModal e que descreve
 * seu métodos e propriedades.
 */
interface ReagendarMentoriaModalProps {
    mentoria: any;
    isOpen: boolean;
    handleClose: Function;
    reagendarMentoriaCallback: any;
    role: string;
}

/**
 * Componente que renderiza um modal para o usuáro reagendar uma mentoria.
 *
 * @param {ReagendarMentoriaModalProps}
 * @returns O modal de reagendamento de mentoria.
 */
const ReagendarMentoriaModal: React.FC<ReagendarMentoriaModalProps> = ({
    mentoria,
    isOpen,
    handleClose,
    reagendarMentoriaCallback,
    role,
}) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const { id, hora: horarioInicio, data: dataMentoria } = mentoria;
    const { t } = useTranslation([MENTORIA_MODAL_SOURCE]);

    const DATA_MINIMA_REAGENDAMENTO = getDateISO(tomorrow(new Date())); // dia posterior à mentoria
    const DATA_MAXIMA_REAGENDAMENTO = getDateISO(
        thirtyDaysAfter(
            new Date(
                formatDateToMaterialUIDate(
                    dataMentoria ? dataMentoria : "2020/01/01"
                )
            )
        )
    ); // 30 dias depois do dia da mentoria

    let initialState = {
        hora: horarioInicio,
        data: dataMentoria,
    };

    const [disponibilidade, setDisponibilidade] = useState<any>(
        initialState
    );
    const [enableSubmit, setEnableSubmit] = useState<boolean>(false);
    const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);

    /**
     * Configura os states de disponibilidade, passo ativo e botão de submissão para
     * seus estados iniciais.
     */
    useEffect(() => {
        setDisponibilidade({
            hora: "",
            data: "",
        });
        setActiveStep(0);
        setIsSubmitButtonLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    /**
     * Gerencia a mudança de hora no objeto de disponibilidade.
     *
     * @param e O evento que dispara a mudança no state e que
     *          possui os atributos valor e nome.
     */
    const handleHoraChange = (e) => {
        disponibilidade[e.target.name] = e.target.value;
        setDisponibilidade(disponibilidade);
    };

    /**
     * Gerencia a mudança de data no objeto de disponibilidade.
     *
     * @param e O evento que dispara a mudança no state e que
     *          possui os atributos valor e nome.
     */
    const handleDateChange = (e) => {
        disponibilidade[e.target.name] = e.target.value;
        setDisponibilidade(disponibilidade);
    };

    /**
     * Gerencia a submissão da nova disponibilidade e
     * chama o callback de reagendamento de mentoria.
     */
    const handleSubmit = () => {
        setIsSubmitButtonLoading(true);

        let disponibilidadeNova = {
            hora: disponibilidade.hora,
            data: formatFromMaterialUIDate(disponibilidade.data),
        }
        reagendarMentoriaCallback(id, disponibilidadeNova)
    };

    /**
     * Gerencia o próximo passo no processo de reagendamento de mentoria.
     */
    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /**
     * Modal que permite a confirmação do reagendamento de mentoria.
     *
     * @returns O modal de confirmação de reagendamento de mentoria.
     */
    const confirmarReagendarModal = () => (
        <CustomModal
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            <div className={classes.confirmarContainer}>
                <img src={PessoaSelecionarSVG} alt="pessoa-selecionando" />
                <h3>{t(MENTORIA_MODAL_TITULO, 'Reagendamento')}</h3>

                <Formik
                    validateOnMount={true}
                    initialValues={disponibilidade}
                    validationSchema={ReagendamentoSchema(
                        DATA_MINIMA_REAGENDAMENTO,
                        DATA_MAXIMA_REAGENDAMENTO
                    )}
                    onSubmit={() => { }}
                >
                    {({ isValid }) => {
                        setEnableSubmit(isValid);
                        return (
                            <div className={classes.reagendarInputs}>
                                <div>
                                    <GlobalCss />
                                    <FormikTextField
                                        id="data"
                                        name="data"
                                        type="date"
                                        label={t(MENTORIA_MODAL_DATA, "Data")}
                                        helperText={t(MENTORIA_MODAL_DATA_MARCADA, 'Data marcada: ') + `${dataMentoria}`}
                                        onChange={handleDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                                <div>
                                    <FormikTextField
                                        name="hora"
                                        type="time"
                                        label={t(MENTORIA_MODAL_HORARIO, "Horário")}
                                        helperText={t(MENTORIA_MODAL_HORARIO_MARCADO, 'Horário marcado: ') + `${horarioInicio}h`}
                                        onChange={handleHoraChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        );
                    }}
                </Formik>
                <div>
                    {
                        isSubmitButtonLoading ?
                            (<SpinnerSplendis />) :
                            (
                                <Button
                                    className={classes.buttonConfirmar}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={!enableSubmit}
                                >
                                    {t(MENTORIA_MODAL_BOTAO_ATUALIZAR, "Atualizar")}
                                </Button>
                            )
                    }
                </div>
            </div>
        </CustomModal>
    );

    /** Modal de instrução para mentor e montorado reagenda mentoria. */
    const instrucaoReagendarModal = () => (
        <CustomModal
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            <div className={classes.confirmarContainer}>
                <img src={PessoaAtencaoSVG} alt="pessoa-atencao" />
                <h3>{t(MENTORIA_MODAL_ATENCAO, 'Atenção')}!</h3>
                <div className={classes.reagendarInputs}>
                    {role === 'ROLE_MENTORADO' &&
                        <>
                            <div>
                                {t(MENTORIA_MODAL_AVISO_MENTORADO_REAGENDAR_MENTORIA_TXT_1, 'O reagendamento deve ser realizado após acordo com o mentor.')}
                                <br />
                                {t(MENTORIA_MODAL_AVISO_MENTORADO_REAGENDAR_MENTORIA_TXT_2, 'Para prosseguir com o reagendamento entre em contato com o seu mentor para definir o melhor dia e horário.')}
                            </div>
                        </>
                    }
                    {role === 'ROLE_MENTOR' &&
                        <>
                            <div>
                                {t(MENTORIA_MODAL_AVISO_MENTOR_REAGENDAR_MENTORIA_TXT_1, 'O reagendamento deve ser realizado após acordo com o mentorado.')}
                                <br />
                                {t(MENTORIA_MODAL_AVISO_MENTOR_REAGENDAR_MENTORIA_TXT_2,'Para prosseguir com o reagendamento entre em contato com o mentorado para definir o melhor dia e horário.')}
                            </div>
                        </>
                    }
                </div>
                <div>
                    {
                        <Button
                            className={classes.buttonConfirmar}
                            variant="contained"
                            onClick={handleNextStep}
                            disabled={false}
                        >
                            {t(MENTORIA_MODAL_BOTAL_AVANCAR, "Avançar")}
                        </Button>
                    }
                </div>
            </div>
        </CustomModal>
    );

    /**
     * Gerencia a exibição ou do modal de mentoria ou do modal
     * de reagendamento de mentoria com base no passo ativo
     * do processo.
     *
     * @param stepIndex O passo ativo do processo de reagendamento.
     * @returns O modal de mentoria ou o modal de reagendamento.
     */
    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return instrucaoReagendarModal();
            case 1:
                return (
                    <MentoriaModal
                        mentoria={mentoria}
                        isOpen={isOpen}
                        handleClose={handleClose}
                        showReagendarButton={true}
                        reagendarMentoriaCallback={handleNextStep}
                        role={role}
                    />
                );
            case 2:
                return confirmarReagendarModal();
        }
    };

    return <>{getStepContent(activeStep)}</>;
};

export default ReagendarMentoriaModal;
