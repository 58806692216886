import React from 'react';
import { Typography, makeStyles, createStyles, Theme, Card, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 'auto',
            width: '56rem',
        },
        chartBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 250,
            width: '100%',
        },
        psWrapper: {
            textAlign: "left",
        }
    })
);

/**
 * Interface utilizada pelo componente ChartCard e que descreve
 * seu métodos e propriedades.
 */
interface ChartCardProps {
    title?: string
    subtitle?: string
    postScriptum?: any
    children?: React.ReactNode
}

/**
 * Componente para renderizar um gráfico qualquer dentro de um Card.
 * 
 * @param {ChartCardProps} 
 * @returns O card com suas propriedades para comportar um gráfico.
 */
const ChartCard: React.FC<ChartCardProps> = ({ title, subtitle, postScriptum, children }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography align="left">{title}</Typography>
                <Typography align="left" variant="subtitle2">{subtitle}</Typography>
                <div className={classes.chartBox}>
                    {children}
                </div>
                {postScriptum ? <div className={classes.psWrapper}>
                    <Typography align="left" variant="caption">{postScriptum}</Typography>
                </div> : null}
            </CardContent>
        </Card>
    );
};

export default ChartCard;