// @ts-nocheck
import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";

import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import Compress from "compress.js";
import {
  TextField,
  MenuItem,
  Button,
  Typography,
  makeStyles,
  createStyles,
  withStyles,
  capitalize,
  Grid,
} from "@material-ui/core";
import { FormikSelectField } from "formik-material-fields";

import { CustomModal, FileUpload, SpinnerSplendis } from "../../commons";
import { Mentor, Categoria, Idioma } from "../../models";
import {
  countriesListEnglish,
  countriesListPortuguese,
  countriesListSpanish,
  estadosBrasilToSelectField,
  mapWeekDayToOrdinal,
} from "../../utils/FormUtils";
import { CategoriaService, IdiomaService } from "../../service";
import DisplayDisponibilidade from "../edicao-mentor-modal/components/display-disponibilidades/DisplayDisponibilidades";
import {
  ATUALIZACAO_MENTOR_MODAL_ATUALIZAR,
  ATUALIZACAO_MENTOR_MODAL_VOLTAR,
  ATUALIZACAO_MENTOR_MODAL_CARGO,
  ATUALIZACAO_MENTOR_MODAL_CATEGORIAS,
  ATUALIZACAO_MENTOR_MODAL_DESCRICAO,
  ATUALIZACAO_MENTOR_MODAL_IDIOMAS,
  ATUALIZACAO_MENTOR_MODAL_NOME,
  ATUALIZACAO_MENTOR_MODAL_EMAIL,
  ATUALIZACAO_MENTOR_MODAL_SOURCE,
  ATUALIZACAO_MENTOR_MODAL_TIMEZONE,
  ATUALIZACAO_MENTOR_MODAL_ALERT_SUCESSO,
  ATUALIZACAO_MENTOR_MODAL_MIN_IDIOMAS,
  ATUALIZACAO_MENTOR_MODAL_MAX_IDIOMAS,
  ATUALIZACAO_MENTOR_MODAL_DISPONIBILIDADE,
  ATUALIZACAO_MENTOR_CONFIRMACAO_TITULO,
  ATUALIZACAO_MENTOR_CONFIRMACAO_SUBTITULO,
  ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_NAO,
  ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_SIM,
  ATUALIZACAO_MENTOR_CAMPO_PAIS_PLACEHOLDER,
  ATUALIZACAO_MENTOR_CAMPO_UF_PLACEHOLDER,
  ATUALIZACAO_MENTOR_MODAL_ALTERAR_SENHA,
} from "../../messages";
import { Link, useHistory } from "react-router-dom";
import { Form, Formik } from "formik";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "@media (max-width: 1024px)": {
        maxWidth: "100%",
        maxHeight: "100%",
        display: "inline-block",
      },
    },

    root: {
      height: "83vh",
      marginTop: "1rem",

      width: "85%",
      "@media only screen and (max-width: 720px)": {
        display: "block",
      },
    },

    formContainer: {
      "@media only screen and (max-width: 414px)": {
        display: "block",
        width: "100vw",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        display: "block",
      },
    },

    descricao: {
      display: "flex",

      "@media (max-width: 1024px)": {
        maxWidth: "100%",
      },
    },

    descriptionFieldProps: {
      fontFamily: "InfraRegular",
      fontSize: ".85rem",
      fontStyle: "normal",
      fontWeight: 400,

      lineHeight: "24px",

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        height: "35vh",
      },
    },

    confirmarText: {
      height: 50,
      display: "flex",
      alignItems: "center",
      textAlign: "left",
    },

    confirmarBtns: {
      fontFamily: "InfraRegular",
      fontSize: ".7rem",
      fontStyle: "normal",
      fontWeight: 500,

      "@media (max-width: 1024px)": {
        fontSize: "x-small",
        margin: 10,
      },
    },

    confirmationDialogTitle: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      fontWeight: 500,
      marginBottom: ".2rem",
    },

    confirmationDialogSubtitle: {
      fontFamily: "InfraRegular",
      fontStyle: "normal",
      fontWeight: 400,
      marginBottom: ".5rem",
    },

    changeStatusDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 1024px)": {
        margin: 5,
        overflow: "auto",
      },
    },

    spacing: {
      padding: "6px",
    },

    firstFieldsContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: "6px",
    },

    actionButtonsInnerContainer: {
      width: "60%",
      maxWidth: "300px",
      display: "flex",
      justifyContent: "space-around",
    },

    actionButtonsOuterContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },

    updateStatusPhotoContainer: {
      gridTemplateColumns: "1fr 1fr",
      margin: "0 auto",
    },

    updatePhoto: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    updateSpinner: {
      marginLeft: "8px",
    },

    firstFields: {
      width: "49%",
    },

    updateActionContainer: {
      width: "90%",
      display: "block",
      margin: "0 auto",
    },

    errorMessageContainer: {
      marginBottom: "15px",
    },

    timezoneField: {
      display: "flex",
    },

    backButton: {
      bottom: "50%",
      color: "#003B4A",
      display: "flex",
      fontFamily: "InfraRegular",
      fontSize: ".8rem",
      fontStyle: "normal",
      fontWeight: 500,
      left: "5vh",
      margin: "2vh 2vh",
      position: "absolute",

      "@media (max-width: 992px)": {
        display: "none",
      },
    },

    changePasswordLink: {
      color: "#003B4A",
      fontFamily: "InfraRegular",
      fontSize: ".875rem",
      fontStyle: "normal",
      fontWeight: 500,
      margin: "auto",
      marginBottom: "1rem",
      marginTop: "1.1rem",
      textDecoration: "none",
      textTransform: "uppercase",
      width: "90%",

      "&:hover": {
        opacity: "0.8",
      },

      "@media only screen and (max-width: 414px)": {
        fontSize: ".7rem",
        marginBottom: "1rem",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        marginLeft: "2rem",
        marginTop: "1.8rem",
        width: "100%",
      },
    },

    countryStateContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "5px",
      padding: "2px 5px",
    },

    countryField: {
      float: "left",
      marginTop: ".6rem",
      width: "73%",
      marginBottom: "5px",
    },

    stateField: {
      float: "right",
      marginTop: ".6rem",
      width: "25%",
    },
  })
);

/**
 * Ajusta os campos para telas maiores(e.g. desktop).
 */
const fieldStyles = {
  container: {
    maxWidth: "43rem",
  },

  updateButton: {
    width: "30%",
  },

  changePasswordButton: {
    width: "30%",
  },
};

/**
 * Ajusta os campos para telas de celulares em formato retrato.
 */
const fieldStylesMobilePortrait = {
  container: {
    maxWidth: "91vw",
  },

  updateButton: {
    fontSize: ".7rem",
    width: "100%",
  },

  changePasswordButton: {
    width: "100%",
  },
};

/**
 * Ajusta o campos para telas de celulares em formato paisagem e tablets.
 */
const fieldStylesMobileLandscape = {
  container: {},

  updateButton: {},

  changePasswordButton: {},
};

// PARA ALTERAR O TEXTFIELD
const GlobalCss = withStyles({
  "@global": {
    ".MuiOutlinedInput-input": {
      "@media (max-width: 1024px)": {
        padding: "18px 7px",
        fontSize: "0.7rem",
      },
    },
  },
})(() => null);

/**
 * Interface utilizada pelo componente FormMentor e que descreve
 * seu métodos e propriedades.
 */
interface FormMentorProps {
  mentor: any;
  alertCallback: Function;
  updateMentor: Function;
}

/**
 * Componente responsável por apresentar o form para edição do mentor.
 *
 * @param {FormMentorProps}
 */
const FormMentor: React.FC<FormMentorProps> = ({ mentor, alertCallback, updateMentor }) => {
  // Biblioteca externa para obter os países e seus respectivos fusos horários.
  const ct = require("countries-and-timezones");

  // Biblioteca externa para realizar a compressão de arquivos.
  const compress = new Compress();

  // Hook de estilização.
  const classes = useStyles();

  // Hook de controle de rotas.
  const history = useHistory();

  // Hook de tradução.
  const { t, i18n } = useTranslation([ATUALIZACAO_MENTOR_MODAL_SOURCE]);

  const categoriaService = new CategoriaService();
  const idiomaService = new IdiomaService();

  const [formObject, setFormObject] = useState<Mentor>(new Mentor());
  const [isConfirmacaoModalOpen, setIsConfirmacaoModalOpen] = useState<boolean>(false);
  const [nomeMentor, setNomeMentor] = useState("");
  const [cargoMentor, setCargoMentor] = useState("");
  const [descricaoMentor, setDescricaoMentor] = useState("");
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [idiomas, setIdiomas] = useState<Idioma[]>([]);
  const [numberOfSelectedCategorias, setNumberOfSelectedCategorias] = useState<number>(formObject.categorias.length);
  const [numberOfSelectedIdiomas, setNumberOfSelectedIdiomas] = useState<number>(mentor.idiomas.length);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [, setEncodedPhoto] = useState<string>(mentor.foto);
  const [status] = useState<string>(mentor.statusMentor);
  const [disponibilidadesValidas, setDisponibilidadesValidas] = React.useState<boolean>(true);
  const [duplicateDisponibilidadesError, setDuplicateDisponibilidadesError] = React.useState<boolean>(false);

  const MAX_NUMBER_OF_DISPONIBILIDADES = 2;

  /**
   * Ao ser inicializado, o componente realiza a atualização dos seus states a partir do mentor recebido através dos props.
   */
  useEffect(() => {
    setFormObject(mentor);
    setNomeMentor(mentor.nome);
    setCargoMentor(mentor.cargo);
    setDescricaoMentor(mentor.descricao);
    setNumberOfSelectedCategorias(mentor.categorias.length);
    setNumberOfSelectedIdiomas(mentor.idiomas.length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Ao ser inicializado o componente realiza o fetch das categorias.
   */
  useEffect(() => {
    categoriaService.getCategorias().then((categorias) => {
      setCategorias(categorias);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Ao ser inicializado o componente realiza o fetch dos idiomas.
   */
  useEffect(() => {
    idiomaService.getIdiomas().then((res) => {
      setIdiomas(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Realiza a compressão do arquivo recebido.
   *
   * @param photo A foto do perfil do mentor.
   * @returns A foto do perfil do mentor em base64.
   */
  async function compressImage(photo: string) {
    const base64 = await fetch(photo);
    let blob = await base64.blob();

    let compressedFile = await compress.compress([blob], {
      size: 1,
      quality: 1,
      maxWidth: 300,
      maxHeight: 300,
      resize: true,
    });

    setEncodedPhoto("data:image/png;base64, " + compressedFile[0].data);
    return "data:image/png;base64," + compressedFile[0].data;
  }

  /**
   * Função responsável por lidar com a atualização dos states a partir dos valores recebidos
   * pelo event e.
   *
   * @param e O evento que dispara a atualização dos states, e que possui as informações relativas
   *          a cada alteração feita no form.
   */
  const handleChange = (e: React.ChangeEvent<any>) => {
    let oldFormObject = formObject;
    oldFormObject[e.target.name] = e.target.value;

    switch (e.target.name) {
      case "nome":
        setNomeMentor(e.target.value);
        break;
      case "cargo":
        setCargoMentor(e.target.value);
        break;
      case "descricao":
        setDescricaoMentor(e.target.value);
        break;
      case "pais":
        searchCountry(e.target.value);
    }

    setFormObject(oldFormObject);
  };

  /**
   * Busca o país com base no código de três letras do mesmo(e.g. BRA).
   *
   * @param countryCode O código de três letras do país.
   */
  const searchCountry = (countryCode: string) => {
    countriesListEnglish().forEach((country) => {
      if (country.code === countryCode) {
        searchTimezone(country.name);
      }
    });
  };

  /**
   * Busca o timezone de um determinado país.
   *
   * @param countryName O nome do país o qual se deseja buscar o seu timezone.
   */
  const searchTimezone = (countryName: string) => {
    const countries: { id: string; name: string; timezones: Array<string> } =
      ct.getAllCountries();

    Object.values(countries).forEach((country) => {
      if (country["name"] === countryName) {
        getTimezoneInfo(country["timezones"][country["timezones"].length - 1]);
      }
    });
  };

  /**
   * Obtém informações relativas a um timezone específico.
   *
   * @param timezone O timezone o qual será buscada as informações.
   */
  const getTimezoneInfo = (timezone: string) => {
    const timezones: {
      aliasOf: string;
      country: string;
      dstOffset: number;
      dstOffsetStr: string;
      name: string;
      utcOffset: number;
      utcOffsetStr: string;
    } = ct.getAllTimezones();

    Object.values(timezones).forEach((timezoneInfo) => {
      if (timezoneInfo["name"] === timezone) {
        formObject.timeZone = timezone;
        setFormObject(formObject);
      }
    });
  };

  /**
   * Obtém todos os timezones disponíveis na aplicação.
   *
   * @returns Um array contendo todos os timezones disponvíeis na aplicação.
   */
  const getAllTimezones = () => {
    const timezones: {
      aliasOf: string;
      country: string;
      dstOffset: number;
      dstOffsetStr: string;
      name: string;
      utcOffset: number;
      utcOffsetStr: string;
    } = ct.getAllTimezones();

    return Object.values(timezones).map((timezone) => {
      return {
        value: timezone["name"],
        label: `(GMT${timezone["utcOffsetStr"]}) ${timezone["name"]}`,
      };
    });
  };

  /**
   * Função que lida com a atualização das categorias que o mentor tem relação.
   *
   * @param e O evento que dispara a mudança no array de categorias.
   * @param values O array de categorias para as quais o mentor está cadastrado.
   */
  const handleCategoriasChange = (
    e: React.ChangeEvent<{}>,
    values: Categoria[]
  ) => {
    e.persist();
    let oldFormObject = formObject;
    oldFormObject.categorias = values;
    setNumberOfSelectedCategorias(values.length);
    setFormObject(oldFormObject);
  };

  /**
   * Função que lida com a atualização dos idiomas que o mentor possui conhecimento.
   *
   * @param event O evento que dispara a mudança no array de idiomas.
   * @param values O array de idiomas que o mentor tem domínio.
   */
  const handleIdiomasChange = (
    event: React.ChangeEvent<{}>,
    values: Idioma[]
  ) => {
    event.persist();
    let oldFormObject = formObject;
    oldFormObject.idiomas = values;
    setNumberOfSelectedIdiomas(values.length);
    setFormObject(oldFormObject);
  };

  /**
   * Função que lida com a atualização da disponibilidade que o mentor possui.
   *
   * @param newDisponibilidades O array de disponibilidades do mentor.
   */
  const handleDisponibilidadesChange = (newDisponibilidades) => {
    let oldFormObject = formObject;

    oldFormObject.disponibilidades = newDisponibilidades;
    checkDuplicidadeDisponibilidades(newDisponibilidades);
    setFormObject(oldFormObject);
  };

  /**
   * Verifica se as disponibilidades recebidas pelo usuário não são duplicadas.
   *
   * @param disponibilidades O array de disponibilidades do mentor.
   */
  const checkDuplicidadeDisponibilidades = (disponibilidades) => {
    let isDuplicate = false;
    if (disponibilidades.length > 1) {
      if (disponibilidades[0].dia === disponibilidades[1].dia) {
        isDuplicate = true;
      }
    }
    setDuplicateDisponibilidadesError(isDuplicate);
  };

  /**
   * Abre o modal de confirmação de atualização das informações do mentor.
   */
  const handleAtualizarClick = () => {
    handleOpenConfirmacaoModal();
  };

  /**
   * Função responsável por formatar e enviar os novos dados do mentor para o backend.
   */
  const handleSubmit = () => {
    formObject.disponibilidades?.map(
      (disponibilidade, index) =>
        (formObject.disponibilidades[index]["ordinal"] = mapWeekDayToOrdinal(
          disponibilidade.dia
        ))
    );

    setUpdateLoading(true);
    updateMentor(formObject)
      .then((res) =>
        alertCallback(
          200,
          t(
            ATUALIZACAO_MENTOR_MODAL_ALERT_SUCESSO,
            "Atualização realizada com sucesso!"
          )
        )
      )
      .then(() => {
        setUpdateLoading(false);
        closeAllModais();
        setTimeout(() => {
          history.push("/mentoring/home");
        }, 1000);
      });
  };

  /**
   * Realiza o fechamento do modal de confirmação de atualização das informações do mentor.
   */
  const closeAllModais = () => {
    handleCloseConfirmacaoModal();
  };

  /**
   * Lida com o arquivo enviado pelo mentor.
   *
   * @param file A foto do perfil do mentor.
   */
  const fileCallback = (file: string) => {
    let oldFormObject = formObject;
    oldFormObject.foto = file;
    setFormObject(oldFormObject);
  };

  /**
   * Apresenta as categorias que o mentor possui relação.
   */
  const filtraCategoriasDoMentor = (): Categoria[] => {
    return formObject.categorias;
  };

  /**
   * Apresenta os idiomas que o mentor possui conhecimento.
   */
  const filtraIdiomasMentor = (): Idioma[] => {
    return idiomas.filter((idioma) => {
      return formObject.idiomas.filter((selected) => selected.sigla === idioma.sigla).length > 0;
    });
  };

  /**
   * Configura e prepara o state para a abertura do modal de confirmação.
   */
  const handleOpenConfirmacaoModal = () => {
    setIsConfirmacaoModalOpen(true);
  };

  /**
   * Configura e prepara o state para o fechamento do modal de confirmação.
   */
  const handleCloseConfirmacaoModal = () => {
    setIsConfirmacaoModalOpen(false);
  };

  /**
   * Realiza a validação das informações do form.
   */
  const validaForm = (): boolean => {
    let categoriasValidas = numberOfSelectedCategorias > 0;
    let idiomasValidos = numberOfSelectedIdiomas > 0;
    let nomeValido = nomeMentor.length > 0;
    let cargoValido = cargoMentor.length > 0;
    let descricaoValida = descricaoMentor.length > 0;

    let uf = formObject.pais === "BRA" ? formObject.uf!.length > 0 : true;

    return (
      nomeValido &&
      cargoValido &&
      descricaoValida &&
      categoriasValidas &&
      disponibilidadesValidas &&
      idiomasValidos &&
      uf &&
      !duplicateDisponibilidadesError
    );
  };

  /**
   * Modal para confirmação final para atualização do mentor.
   *
   * @returns O modal de confirmação.
   */
  const confirmacaoModal = () => {
    return (
      <CustomModal
        style={{ height: "auto", maxWidth: 560 }}
        isOpen={isConfirmacaoModalOpen}
        handleClose={handleCloseConfirmacaoModal}
      >
        <div>
          <Typography variant="h5" className={classes.confirmationDialogTitle}>
            {t(ATUALIZACAO_MENTOR_CONFIRMACAO_TITULO, "Você tem certeza disso?")}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" className={classes.confirmationDialogSubtitle}>
            {t(ATUALIZACAO_MENTOR_CONFIRMACAO_SUBTITULO, "Você poderá atualizar novamente os dados deste mentor posteriormente.")}
          </Typography>
        </div>
        <div className={classes.actionButtonsOuterContainer}>
          <div className={classes.actionButtonsInnerContainer}>
            <Button
              className={classes.confirmarBtns}
              variant="outlined"
              color="primary"
              onClick={handleCloseConfirmacaoModal}
            >
              {t(ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_NAO, "Não")}
            </Button>
            {agreeButtonWithIntegratedLoading()}
          </div>
        </div>
      </CustomModal>
    );
  };

  /**
   * Renderiza o botão de confirmação no modal de atualização das informações do mentor.
   *
   * @returns O botão de confirmação.
   */
  const agreeButtonWithIntegratedLoading = () => {
    return (
      <Button
        className={classes.confirmarBtns}
        variant="contained"
        color="primary"
        onClick={() =>
          compressImage(formObject.foto!).then((photo) => {
            formObject.foto = photo;
            handleSubmit();
          })
        }
        disabled={updateLoading}
      >
        {t(ATUALIZACAO_MENTOR_CONFIRMACAO_BOTAO_SIM, "Sim, quero atualizar")}
        {updateLoading ? (
          <span className={classes.updateSpinner}>
            <SpinnerSplendis size={16} />
          </span>
        ) : null}
      </Button>
    );
  };

  /**
   * Renderiza o form de atualização das informações do mentor.
   *
   * @returns O form de atualização das informações do mentor.
   */
  const atualizacaoMentorForm = () => {
    return (
      <React.Fragment>
        {confirmacaoModal()}
        <GlobalCss />

        <Button
          onClick={() => history.push("/mentoring/home")}
          className={classes.backButton}
          startIcon={<ArrowBackIosSharpIcon fontSize="large" />}
          size="large"
          style={{ position: "absolute" }}
        >
          {t(ATUALIZACAO_MENTOR_MODAL_VOLTAR, "Voltar")}
        </Button>

        <Formik
          enableReinitialize
          validateOnMount={false}
          initialValues={formObject}
          onSubmit={() => {}}
        >
          {({ isValid }) => {
            return (
              <Form autoComplete="on" className={classes.root}>
                <div className={classes.updateStatusPhotoContainer}>
                  <div className={classes.updatePhoto}>
                    <FileUpload
                      {...{ callback: fileCallback }}
                      profilePhoto={formObject.foto}
                    />
                  </div>
                </div>

                <Grid container spacing={5} className={classes.formContainer}>
                  <Grid item md={6}>
                    <div className={classes.firstFieldsContainer}>
                      <div className={classes.firstFields}>
                        <TextField
                          id="nome"
                          name="nome"
                          label={t(ATUALIZACAO_MENTOR_MODAL_NOME, "Nome")}
                          inputProps={{ maxLength: 60 }}
                          InputLabelProps={{
                            style: {
                              fontFamily: "InfraRegular",
                            },
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "InfraRegular",
                              fontSize: ".85rem",
                              fontStyle: "normal",
                              fontWeight: 400,
                              height: "3rem",
                              lineHeight: "24px",
                            },
                          }}
                          onChange={handleChange}
                          defaultValue={formObject.nome}
                          value={formObject.nome}
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className={classes.firstFields}>
                        <TextField
                          id="email"
                          name="email"
                          label={t(ATUALIZACAO_MENTOR_MODAL_EMAIL, "E-mail")}
                          onChange={handleChange}
                          defaultValue={formObject.email}
                          value={formObject.email}
                          InputLabelProps={{
                            style: {
                              fontFamily: "InfraRegular",
                            },
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "InfraRegular",
                              fontSize: ".85rem",
                              fontStyle: "normal",
                              fontWeight: 400,
                              height: "3rem",
                              lineHeight: "24px",
                            },
                          }}
                          variant="outlined"
                          disabled
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className={classes.firstFieldsContainer}>
                      <div className={classes.firstFields}>
                        <TextField
                          id="statusMentor"
                          name="statusMentor"
                          onChange={handleChange}
                          defaultValue={status}
                          value={formObject.statusMentor}
                          InputLabelProps={{
                            style: {
                              fontFamily: "InfraRegular",
                            },
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "InfraRegular",
                              fontSize: ".85rem",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "24px",
                              textAlign: "start",
                            },
                          }}
                          variant="outlined"
                          label="Status"
                          select
                          className={classes.timezoneField}
                        >
                          <MenuItem value="APROVADO">Ativo</MenuItem>
                          <MenuItem value="AUSENTE">Ausente</MenuItem>
                        </TextField>
                      </div>
                      <div className={classes.firstFields}>
                        <FormikSelectField
                          id="timezone"
                          name="timeZone"
                          onChange={(e) => handleChange(e)}
                          defaultValue={formObject.timeZone}
                          value={formObject.timeZone}
                          options={getAllTimezones().map((timezone) => {
                            return {
                              value: timezone.value,
                              label: timezone.label,
                            };
                          })}
                          inputProps={{
                            value: formObject.timeZone,
                          }}
                          InputLabelProps={{
                            style: {
                              fontFamily: "InfraRegular",
                            },
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "InfraRegular",
                              fontSize: ".85rem",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "24px",
                            },
                          }}
                          variant="outlined"
                          label={t(ATUALIZACAO_MENTOR_MODAL_TIMEZONE, "Fuso horário")}
                          className={classes.timezoneField}
                        />
                      </div>
                    </div>

                    <div>
                      <TextField
                        style={{ marginBottom: "35px", marginLeft: "6px" }}
                        defaultValue={formObject.descricao}
                        value={formObject.descricao}
                        id="descricao"
                        name="descricao"
                        label={t(ATUALIZACAO_MENTOR_MODAL_DESCRICAO, "Descrição")}
                        onChange={handleChange}
                        className={classes.descricao}
                        inputProps={{ maxLength: 500 }}
                        InputLabelProps={{ style: { fontFamily: "InfraRegular" } }}
                        InputProps={{ className: classes.descriptionFieldProps }}
                        placeholder={t(ATUALIZACAO_MENTOR_MODAL_DESCRICAO, "Insira a nova descrição")}
                        variant="outlined"
                        multiline
                        rows={5}
                      />
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div style={{ marginLeft: "5px" }}>
                      <TextField
                        id="cargo"
                        name="cargo"
                        label={t(ATUALIZACAO_MENTOR_MODAL_CARGO, "Cargo")}
                        inputProps={{ maxLength: 60 }}
                        InputLabelProps={{
                          style: {
                            fontFamily: "InfraRegular",
                          },
                        }}
                        InputProps={{
                          style: {
                            fontFamily: "InfraRegular",
                            fontSize: ".85rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            width: "100%",
                            lineHeight: "24px",
                          },
                        }}
                        onChange={handleChange}
                        defaultValue={formObject.cargo}
                        value={formObject.cargo}
                        variant="outlined"
                        fullWidth
                      />
                      <FormikSelectField
                        className={classes.countryField}
                        defaultValue={formObject.pais}
                        value={formObject.pais}
                        name="pais"
                        label={t(ATUALIZACAO_MENTOR_CAMPO_PAIS_PLACEHOLDER, "País")}
                        options={
                          i18n.language === "pt"
                            ? countriesListPortuguese().map((country) => {
                                return {
                                  value: country.code,
                                  label: country.name,
                                };
                              })
                            : i18n.language === "es"
                            ? countriesListSpanish().map((country) => {
                                return {
                                  value: country.code,
                                  label: country.name,
                                };
                              })
                            : countriesListEnglish().map((country) => {
                                return {
                                  value: country.code,
                                  label: country.name,
                                };
                              })
                        }
                        InputLabelProps={{
                          style: {
                            color: "#1F1F1F",
                            fontFamily: "InfraRegular",
                            fontSize: ".8rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                          },
                        }}
                        onChange={(e) => handleChange(e)}
                        variant="outlined"
                        fullWidth
                      />
                      <FormikSelectField
                        className={classes.stateField}
                        disabled={formObject.pais !== "BRA"}
                        value={formObject.uf}
                        name="uf"
                        label={t(ATUALIZACAO_MENTOR_CAMPO_UF_PLACEHOLDER, "UF")}
                        margin="normal"
                        variant="outlined"
                        options={estadosBrasilToSelectField().map((uf) => {
                          return { label: uf, value: uf };
                        })}
                        InputLabelProps={{
                          style: {
                            color: "#1F1F1F",
                            fontFamily: "InfraRegular",
                            fontSize: ".8rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                          },
                        }}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                      />
                    </div>
                    {/* CATEGORIAS: */}
                    <div className={classes.spacing}>
                      <Autocomplete
                        multiple
                        id="tags-standard-category"
                        options={categorias}
                        getOptionLabel={(categoria) => categoria["nome" + capitalize(i18n.language)] }
                        onChange={(e, values) => handleCategoriasChange(e, values) }
                        defaultValue={filtraCategoriasDoMentor()}
                        value={filtraCategoriasDoMentor()}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t(ATUALIZACAO_MENTOR_MODAL_CATEGORIAS, "Categorias")}
                            placeholder={t(ATUALIZACAO_MENTOR_MODAL_CATEGORIAS, "Categorias")}
                            onKeyPress={(event) => { event.preventDefault(); }}
                            InputLabelProps={{ style: { fontFamily: "InfraRegular" } }}
                          />
                        )}
                        ChipProps={{
                          style: {
                            fontFamily: "InfraRegular",
                            fontSize: ".85rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                          },
                        }}
                        style={
                          window.screen.width < 415
                            ? fieldStylesMobilePortrait.container
                            : window.screen.width >= 415 &&
                              window.screen.width <= 720
                            ? fieldStylesMobileLandscape.container
                            : fieldStyles.container
                        }
                      />
                    </div>
                    {/* IDIOMAS: */}

                    <div className={classes.spacing}>
                      <Autocomplete
                        multiple
                        id="tags-standard-languages"
                        options={idiomas}
                        getOptionLabel={(idioma) => idioma.nome}
                        onChange={(e, values) => handleIdiomasChange(e, values)}
                        defaultValue={filtraIdiomasMentor()}
                        value={filtraIdiomasMentor()}
                        noOptionsText={t(ATUALIZACAO_MENTOR_MODAL_MAX_IDIOMAS, "Máximo de 3 idiomas atingido")}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={
                              numberOfSelectedIdiomas > 0
                                ? t(ATUALIZACAO_MENTOR_MODAL_IDIOMAS, "Idiomas")
                                : t(ATUALIZACAO_MENTOR_MODAL_MIN_IDIOMAS, "Selecione pelo menos 1 idioma")
                            }
                            placeholder={t(ATUALIZACAO_MENTOR_MODAL_IDIOMAS, "Idiomas")}
                            onKeyPress={(event) => { event.preventDefault(); }}
                            InputLabelProps={{ style: { fontFamily: "InfraRegular" } }}
                          />
                        )}
                        ChipProps={{
                          style: {
                            fontFamily: "InfraRegular",
                            fontSize: ".85rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                          },
                        }}
                        style={
                          window.screen.width < 415
                            ? fieldStylesMobilePortrait.container
                            : window.screen.width >= 415 &&
                              window.screen.width <= 720
                            ? fieldStylesMobileLandscape.container
                            : fieldStyles.container
                        }
                      />
                    </div>
                    {/* DISPONIBILIDADES: */}
                    <div>
                      {/**------------------------------------------- */}
                    </div>
                  </Grid>
                </Grid>
                <Grid container justify="center" style={{ marginTop: "10px" }}>
                  <Grid item md={12}>
                    <div className={classes.errorMessageContainer}>
                      {duplicateDisponibilidadesError
                          ? (
                            <Typography variant="subtitle2" color="error">
                              {t(ATUALIZACAO_MENTOR_MODAL_DISPONIBILIDADE, "As disponibilidades devem ser em dias diferentes")}
                            </Typography>
                          )
                          : null
                      }
                    </div>
                  </Grid>
                  <DisplayDisponibilidade
                    disponibilidades={formObject.disponibilidades}
                    handleDisponibilidadeChange={handleDisponibilidadesChange}
                    dadosValidos={setDisponibilidadesValidas}
                    maxDisponibilidades={MAX_NUMBER_OF_DISPONIBILIDADES}
                  />
                  <Grid item md={12} style={{ marginTop: "30px" }}>
                    <Button
                      onClick={handleAtualizarClick}
                      variant="contained"
                      color="primary"
                      disabled={!validaForm()}
                      style={
                        window.screen.width < 720
                          ? fieldStylesMobilePortrait.updateButton
                          : fieldStyles.updateButton
                      }
                    >
                      {t(ATUALIZACAO_MENTOR_MODAL_ATUALIZAR, "Atualizar")}
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <Button
                      style={
                        window.screen.width < 720
                          ? fieldStylesMobilePortrait.changePasswordButton
                          : fieldStyles.changePasswordButton
                      }
                    >
                      <Link
                        to={{ pathname: "/mentoring/trocar-senha" }}
                        className={classes.changePasswordLink}
                      >
                        {t(ATUALIZACAO_MENTOR_MODAL_ALTERAR_SENHA, "Alterar a senha")}
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };

  return atualizacaoMentorForm();
};

export default memo(FormMentor);
