import React, { memo } from "react";
import { getFormat, getRange2 } from "./intervaloFunction";
import { FormControl, MenuItem, Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

import {
  daysOfWeek,
  mapWeekDays,
  mapWeekDayToOrdinal,
} from "../../../../utils/FormUtils";
import { useTranslation } from "react-i18next";
import {
  DAY_OF_WEEK_AND_TIME_ROW_DIA,
  DAY_OF_WEEK_AND_TIME_ROW_HORARIO,
  DAY_OF_WEEK_AND_TIME_ROW_SOURCE,
  DAY_OF_WEEK_AND_TIME_CANCEL,
  DAY_OF_WEEK_AND_TIME_CONFIRM,
  INPUT_INTERVALO,
  INPUT_DATA_INICIAL,
  NEW_MESSAGES_SOURCE,
  INPUT_SEMANA,
  INPUT_2_SEMANAS,
  INPUT_3_SEMANAS,
  INPUT_4_SEMANAS,
} from "../../../../messages";

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      width: "100%",
    },

    closeButtonContainer: {
      "&:hover": {
        opacity: "0.8",
      },
    },

    hourAndButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "15%",
    },

    timePicker: {
      width: "5rem",

      "@media only screen and (max-width: 414px)": {
        width: "5rem",
      },
    },
  })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const fieldStyles = {
  container: {
    width: "13rem",
  },
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const fieldStylesMobilePortrait = {
  container: {
    width: "30vw",
  },
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const fieldStylesMobileLandscape = {
  container: {
    width: "12.8rem",
  },
};

/**
 * Interface utilizada pelo componente DayOfWeekAndTimeRow e que descreve
 * seu métodos e propriedades.
 */
interface DayOfWeekAndTimeRowProps {
  disponibilidade: {
    hora: string;
    dia: string;
    intervaloSemanas: number;
    dataInicio: string;
    ordinal?: number;
  };
  handleChange: (e?: any) => void;
  handleErase: () => void;
  disableErase?: boolean;
}

/**
 * Componente responsável pela apresentação e atualização dos campos de dia e hora
 * no modal de edicão de mentor.
 *
 * @param {DayOfWeekAndTimeRowProps}
 */
export const DayOfWeekAndTimeRow: React.FC<DayOfWeekAndTimeRowProps> = ({
  disponibilidade,
  handleChange,
  handleErase,
  disableErase,
}) => {
  const classes = useStyles();
  const { t } = useTranslation([DAY_OF_WEEK_AND_TIME_ROW_SOURCE]);
  const { t: t2 } = useTranslation([NEW_MESSAGES_SOURCE]);

  const { dia, intervaloSemanas, dataInicio } = disponibilidade;
 

  const [hora, setHora] = React.useState(disponibilidade.hora || "00:00");

  /**
   * Lida com a hora recebida, realizando as formatações antes de atualizar no state e no campo.
   *
   * @param date O objeto data com o horário.
   */
  const handleTime = (date: MaterialUiPickersDate) => {
    const formattedTime = moment(date).format("HH:mm");
    const timeObject = { target: { name: "hora", value: formattedTime } };

    setHora(formattedTime);
    handleChange(timeObject);
  };

  return (
    <div className={classes.grid}>
      <FormControl style={{ marginBottom: "5px" }}>
        <TextField
          id="dia"
          name="dia"
          label={t(DAY_OF_WEEK_AND_TIME_ROW_DIA, "Dia")}
          onChange={handleChange}
          defaultValue={dia}
          value={dia}
          variant="outlined"
          select
          style={
            window.screen.width < 415
              ? fieldStylesMobilePortrait.container
              : window.screen.width >= 415 && window.screen.width <= 720
              ? fieldStylesMobileLandscape.container
              : fieldStyles.container
          }
          InputLabelProps={{
            style: {
              fontFamily: "InfraRegular",
            },
          }}
          InputProps={{
            style: {
              fontFamily: "InfraRegular",
              fontSize: ".85rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            },
          }}
        >
          {daysOfWeek().map((day) => {
            return (
              <MenuItem key={day} value={day}>
                {mapWeekDays(day, t)}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>

      <FormControl style={{ width: "20%" }}>
        <TextField
          id="demo-simple-select-standard"
          onChange={handleChange}
          value={intervaloSemanas}
          name={"intervaloSemanas"}
          variant={"outlined"}
          label={t2(INPUT_INTERVALO, "Ocorre a cada")}
          select
        >
          <MenuItem value={1}>{t2(INPUT_SEMANA, "1 Semana")}</MenuItem>
          <MenuItem value={2}>{t2(INPUT_2_SEMANAS, "2 Semanas")}</MenuItem>
          <MenuItem value={3}>{t2(INPUT_3_SEMANAS, "3 Semanas")}</MenuItem>
          <MenuItem value={4}>{t2(INPUT_4_SEMANAS, "4 Semanas")}</MenuItem>
        </TextField>
      </FormControl>

      <div className={classes.hourAndButton}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.timePicker}>
            <TimePicker
              ampm={false}
              label={t(DAY_OF_WEEK_AND_TIME_ROW_HORARIO, "Horário")}
              cancelLabel={t(DAY_OF_WEEK_AND_TIME_CANCEL, "Cancelar")}
              okLabel={t(DAY_OF_WEEK_AND_TIME_CONFIRM, "Confirmar")}
              value={moment(hora, "HH:mm") ?? "00:00"}
              onChange={handleTime}
              InputLabelProps={{
                style: {
                  fontFamily: "InfraRegular",
                },
              }}
              InputProps={{
                style: {
                  fontFamily: "InfraRegular",
                  fontSize: ".85rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
        <div className={classes.closeButtonContainer}>
          <Button
            onClick={handleErase}
            disabled={disableErase}
            variant="contained"
            color="secondary"
          >
            <CloseIcon />
          </Button>
        </div>
      </div>

      <TextField
        label={t2(INPUT_DATA_INICIAL, "Data inicial")}
        variant="outlined"
        onChange={handleChange}
        value={dataInicio}
        style={{ width: "23%" }}
        select
        name="dataInicio"
      >
        {getRange2("days", mapWeekDayToOrdinal(dia), dataInicio).map(
          (date, index) => (
            <MenuItem key={index} value={date}>
              {getFormat(date)}
            </MenuItem>
          )
        )}
      </TextField>
    </div>
  );
};

export default memo(DayOfWeekAndTimeRow);
