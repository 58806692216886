import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { TrocaIdiomas } from '../../../../components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},

		logo: {
			maxHeight: 55,
		},

		menuButton: {
			marginRight: theme.spacing(2),
		},

		title: {
			flexGrow: 1,
		},

		toolbar: {
			height: '88px',
		},

		appBar: {
			backgroundColor: 'transparent',
			boxShadow: 'none'
		},

		btnLogar: {
			color: 'white',
			backgroundColor: 'black',
			borderRadius: '100px',
			"&:hover": {
				backgroundColor: 'white',
				color: 'black',
			},
		}
	}),
);

/**
 * Renderiza uma versão pública(usuário não logado) da appbar da aplicação.
 */
export default function PublicAppBarComponent(props) {

	const { title } = props;

	const classes = useStyles();
	
	return (
		<div className={classes.root}>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<img alt="stef-logo" className={classes.logo} src="/static/images/stef-logo.png" />
					<Typography variant="h6" className={classes.title} />
					<TrocaIdiomas />
					<Button className={classes.btnLogar} type="button" variant="contained" component={NavLink} to="/login">{ title }</Button>
					{/* <Button color="secondary">Sair</Button> */}
				</Toolbar>
			</AppBar>
		</div>
	);
}
