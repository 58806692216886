import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  AGENDAMENTO_MENTORIA_SOURCE,
  AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_TITULO,
  AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_CONTEUDO,
} from "../../../../../../../messages";

const useStyles = makeStyles({
  title: {
    fontFamily: 'InfraRegular',
    fontStyle: 'normal',

    '@media only screen and (max-width: 720px)': {
      fontSize: '1.5rem'
    }
  },
  statements: {
    fontFamily: 'InfraRegular',
    fontStyle: 'normal',
  }
});

/**
 * Exibe as informações de confirmação de agendamento de mentoria após
 * a exibição dos termos de uso.
 * 
 * @returns As informações de confirmação de agendamento de mentoria.
 */
const AgendamentoMentoriaPosTermosDeUsoText = () => {

  /**
   * Hook e função utilizada na tradução dos textos.
   */
  const { t } = useTranslation([AGENDAMENTO_MENTORIA_SOURCE]);
  
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography align="center" variant="h4" className={classes.title}>
        {t(
          AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_TITULO,
          "Mentoria Agendada"
        )}
      </Typography>
      <p className={classes.statements}>
        {t(
          AGENDAMENTO_MENTORIA_MODAL_POS_TERMOS_DE_USO_CONTEUDO,
          "Mentoria agendada com sucesso. Parabéns pela iniciativa!"
        )}
      </p>
    </React.Fragment>
  );
};

export default AgendamentoMentoriaPosTermosDeUsoText;
