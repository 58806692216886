import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, Tab, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import BuscarMentorStepper from './por-mentor/BuscarMentorStepper';
import MentoradoStepper from './por-categoria/MentoradoStepper';
import { IdiomaService, UserContext } from '../../../../service';
import BuscarIdiomaStepper from './por-idioma/BuscarIdiomaStepper';
import { Idioma } from '../../../../models';
import {
	BIFURCARDOR_AGENDAMENTO_SOURCE,
	BIFURCADOR_AGENDAMENTO_POR_MENTOR,
	BIFURCADOR_AGENDAMENTO_POR_CATEGORIA,
	BIFURCADOR_AGENDAMENTO_POR_IDIOMA,
	BIFURCADOR_AGENDAMENTO_AGENDAR_MENTORIAS
} from '../../../../messages';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		sectionComponent: {
			display: 'flex',
			justifyContent: 'center',
		},
		tabs: {
			
			/* left: '36%',
			position: 'fixed',
			top: '32vh',

			'@media only screen and (max-width: 414px)': {
				left: '12%',
				right: '12%',

				'& > div > div': {
					display: 'inline-grid',
				}
			},

			'@media only screen and (min-width: 415px) and (max-width: 720px)': {
				left: '12%',
				top: '55vh',
			} */
		},
		tab: {
			'@media only screen and (max-width: 414px)': {
				fontSize: '.75rem',
			}
		}
	}),
);

/**
 * Componente que permite uma bifurcação no momento de escolha
 * de como agendar uma mentoria: por categoria ou por mentor. 
 * 
 * @returns As opções de como agendar uma mentoria: por categoria
 * 			ou por mentor.
 */
export default function BifurcadorAgendamento() {
	const classes = useStyles();

	const { t } = useTranslation([BIFURCARDOR_AGENDAMENTO_SOURCE]);

	const { session } = useContext(UserContext);

	const [tabValue, setTabValue] = useState(0);
	const [enableTabs, setEnableTabs] = useState(true);
	const [idiomas, setIdiomas] = useState<Idioma[]>([]);

	/**
	 * Realiza o redimensionamento da tela assim que o componente é renderizado.
	 */
	useEffect(() => {
		window.dispatchEvent(new CustomEvent('resize'));
	}, []);

	useEffect(() => {
		if (idiomas.length === 0) {
            const idiomaService: IdiomaService = new IdiomaService();
			idiomaService.getIdiomas()
				.then((res) => {
					setIdiomas(res.data);
				});
		}
	}, [idiomas.length]);

	/**
	 * Provê o controle das abas da bifurcação.
	 * 
	 * @param index O indíce de cada aba.
	 * @returns Um objeto com os controles das abas da bifurcação
	 */
	function a11yProps(index: any) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	}

	/**
	 * Gerencia o processo de mudança de aba, configurando o state do valor da aba.
	 * 
	 * @param event O evento que dispara a mudança de aba.
	 * @param value O valor da aba específica.
	 */
	const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
		setTabValue(value);
	}

	/**
	 * Gerencia o processo de habilitar ou não as abas com base em qual parte do
	 * processo de agendar mentoria o usuário se encontra.
	 * 
	 * @param isNextStep Parâmentro utilizado para saber se é preciso habilitar
	 * 					 as abas ou não.
	 */
	const handleNextStep = (isNextStep: boolean): void => {
		isNextStep ? setEnableTabs(false) : setEnableTabs(true);
	}

	return (
		<>
			{tabValue === 0 ?
				<BuscarMentorStepper idMentorado={session.mentorado.id} handleNextStep={handleNextStep} /> :
				tabValue === 1 ?
					<MentoradoStepper idMentorado={session.mentorado.id} handleNextStep={handleNextStep} /> :
					<BuscarIdiomaStepper idMentorado={session.mentorado.id} handleNextStep={handleNextStep} idiomas={idiomas} />
			}
			{enableTabs &&
				<section className={classes.sectionComponent}>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						className={classes.tabs}
						TabIndicatorProps={{
							style: {
								display: 'none'
							}
						}}
					>
						<table>
							<tr>
								<Typography style={{ color: "#030624", fontSize: "26px", textAlign: "center" }}>{t(BIFURCADOR_AGENDAMENTO_AGENDAR_MENTORIAS, "Agendar Mentoria")}</Typography>
							</tr>
							<tr>
								<Tab 
									className={classes.tab} 
									label={t(BIFURCADOR_AGENDAMENTO_POR_MENTOR, "Por mentor")} 
									onClick={(event) => {
										handleTabChange(event, 0);
									}}
								 />
								<Tab 
									className={classes.tab} 
									label={t(BIFURCADOR_AGENDAMENTO_POR_CATEGORIA, "Por categoria")}
									onClick={(event) => {
										handleTabChange(event, 1);
									}} 
								/>
								<Tab 
									className={classes.tab} 
									label={t(BIFURCADOR_AGENDAMENTO_POR_IDIOMA, "Por idioma")}
									onClick={(event) => {
										handleTabChange(event, 2);
									}}
								/>
							</tr>
						</table>
					</Tabs>
				</section>
			}
		</>
	)
}