import React from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';

import usa from '../../assets/flags/eua.png';

const useStyles = makeStyles({
  imageIcon: {
    height: '100%',
    width: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  }
});

/**
* Componente responsável por renderizar a bandeira dos Estados Unidos da América,
* a qual simboliza a utilização do idioma Inglês.
*/
const UsaFlagIcon = () => {
  const classes = useStyles();

  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img alt="usa" className={classes.imageIcon} src={usa} />
    </Icon>
  );
}

export default UsaFlagIcon;
