import React from "react";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { CustomModal, SpinnerSplendis } from "../../../../../commons";
import PessoaSelecionarSVG from "../../../../../assets/svgs/pessoa-escolha.svg";
import MentoriaModal from "../../../../../commons/modals/MentoriaModal";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import {
    CANCELAR_MENTORIA,
    CANCELAR_MENTORIA_TITULO,
    CANCELAR_MENTORIA_PERGUNTA,
    CANCELAR_MENTORIA_RETORNAR,
    CANCELAR_MENTORIA_BOTAO
} from "../../../../../messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "17rem",
        },
        confirmarContainer: {
            fontFamily: "infraRegular",
        },
        button: {
            fontFamily: "Roboto",
            width: "16rem",
            marginLeft: "0.65rem",
            marginTop: "0.5rem",
        },
        statusContainer: {
            textAlign: "initial",
            marginLeft: "1rem",
        },
        status: {
            marginLeft: theme.spacing(1),
        },
        buttonCancelar: {
            color: 'white',
            backgroundColor: "#003B4A",
            fontFamily: "Roboto",
            width: "100%",
            marginTop: "1rem",

            "& span": {
                height: "2rem",
            },

            '&:hover': {
				backgroundColor: '#0a3039',
			},
        },
        buttonConfirmar: {
            color: "#003B4A",
            border: "1px solid #003B4A",
            fontFamily: "Roboto",
            width: "100%",
            marginTop: "1rem",
            "& span": {
                height: "2rem",
            },

            '&:hover': {
				color: '#0a3039',
                border: "1px solid #0a3039",
			},
        },
    })
);

/**
 * Ajusta o modal para telas maiores(e.g. desktop).
 */
const modalStyle = {
    container: {
        height: 'auto',
        width: '28rem',
        textAlign: '-webkit-center',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato retrato.
 */
const modalStyleMobilePortrait = {
    container: {
        height: 'auto',
        textAlign: 'center',
        width: '70vw',
    },
};

/**
 * Ajusta o modal para telas de celulares em formato paisagem e tablets.
 */
const modalStyleMobileLandscape = {
    container: {
        height: 'auto',
        marginTop: '75vh',
        textAlign: 'center',
        width: '70vw',
    },
};

/**
 * Interface utilizada pelo componente CancelarMentoriaModal e que descreve
 * seu métodos e propriedades.
 */
interface CancelarMentoriaModalProps {
    mentoria: any;
    isOpen: boolean;
    handleClose: Function;
    cancelarMentoriaCallback: any;
    role: string
}

/**
 * Componente que renderiza um modal para o usuáro cancelar uma mentoria.
 * 
 * @param {CancelarMentoriaModalProps}
 * @returns O modal de cancelamento de mentoria.
 */
const CancelarMentoriaModal: React.FC<CancelarMentoriaModalProps> = ({
    mentoria,
    isOpen,
    handleClose,
    cancelarMentoriaCallback,
    role
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const { t } = useTranslation([CANCELAR_MENTORIA]);
    const [isSubmitButtonLoading, setIsSubmitButtonLoading] = React.useState<boolean>(false);

    /**
     * Gerencia o próximo passo do processo, configurando o state de passo ativo.
     */
    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /**
     * Gerencia o cancelamento de mentoria configurando o state do botão
     * de submissão e realizando a chamada do callback de cancelamento
     * de mentoria.
     */
    const handleSubmit = () => {
        setIsSubmitButtonLoading(true);
        cancelarMentoriaCallback(mentoria.id)
    }

    /**
     * Exibe o modal de confirmação de cancelamento de mentoria.
     * 
     * @returns O modal de confirmação de cancelamento de mentoria.
     */
    const confirmarCancelarModal = () => (
        <CustomModal
            style={window.screen.width < 415 ?
                modalStyleMobilePortrait.container :
                (window.screen.width >= 415 && window.screen.width <= 720 ?
                    modalStyleMobileLandscape.container :
                    modalStyle.container)}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            <div className={classes.confirmarContainer}>
                <img src={PessoaSelecionarSVG} alt="pessoa-selecionando" />
                <h3>{t(CANCELAR_MENTORIA_TITULO, "Cancelar")}</h3>
                <p>{t(CANCELAR_MENTORIA_PERGUNTA, "Tem certeza que deseja cancelar essa mentoria?")}</p>

                <div>
                    {
                        isSubmitButtonLoading ?
                            (<SpinnerSplendis />) :
                            (<Button
                                className={classes.buttonCancelar}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                {t(CANCELAR_MENTORIA_BOTAO, 'Cancelar Mentoria')}
                            </Button>)
                    }
                    <Button
                        className={classes.buttonConfirmar}
                        variant="outlined"
                        onClick={() => history.push("/mentoring/home")}
                    >
                        {t(CANCELAR_MENTORIA_RETORNAR, "Retornar para a Home")}
                    </Button>
                </div>
            </div>
        </CustomModal>
    );

    /**
     * Renderiza o modal de mentoria ou o modal de cancelamento de mentoria
     * com base no passo ativo do processo de cancelamento.
     * 
     * @param stepIndex O passo do processo de cancelamento.
     * @returns O modal de mentoria ou o modal de cancelamento de mentoria.
     */
    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return (
                    <MentoriaModal
                        mentoria={mentoria}
                        isOpen={isOpen}
                        handleClose={handleClose}
                        showCancelarButton={true}
                        cancelarMentoriaCallback={handleNextStep}
                        role={role}
                    />
                );
            case 1:
                return confirmarCancelarModal();
        }
    };

    return <>{getStepContent(activeStep)}</>;
};

export default CancelarMentoriaModal;
