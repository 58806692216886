// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Compress from 'compress.js'
import { Typography, Button, createStyles, Theme, Avatar, makeStyles } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Resizer from 'react-image-file-resizer';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from "react-i18next";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
	FORM_COLABORADOR_SOURCE,
	FORM_COLABORADOR_UPLOAD_FOTO,
	FORM_COLABORADOR_MODAL_TITULO,
	FORM_COLABORADOR_MODAL_BOTAO_CONFIRMAR,
} from '../../messages';

import SpinnerSplendis from '../spinner-splendis/SpinnerSplendis';
import CustomModal from '../custom-modal/CustomModal';
import { modalStyle } from './ModalStyle.js';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		uploadPhotoHeading: {
			fontFamily: 'InfraRegular',
			fontSize: '.7rem',
			fontWeight: 300,
			marginBottom: '.8rem',
			marginTop: '-.5rem',
		},
		uploadPhotoHeadingError: {
			fontFamily: 'InfraRegular',
			fontSize: '.7rem',
			fontWeight: 300,
			marginBottom: '.8rem',
			marginTop: '-.5rem',
			color: 'red',
		},
		cropContainer: {
			maxHeight: '50%',
			maxWidth: '50%',
			display: 'block',
			margin: '0 auto',

			"@media only screen and (max-width: 1024px)": {
				maxWidth: '100%',
				maxHeight: '100%',
				zoom: '80%'
			}
		},
		insertPictureAvatar: {
			backgroundColor: '#CFD4DA',
			width: theme.spacing(12),
			height: theme.spacing(12),
			
			'&:hover': {
				cursor: 'pointer',
				opacity: 0.8,
			}
		},
		errorBorder:{
			border:'1px solid red',
			backgroundColor: '#CFD4DA',
			width: theme.spacing(12),
			height: theme.spacing(12),
			
			'&:hover': {
				cursor: 'pointer',
				opacity: 0.8,
			}

		},
		insertPictureIcon: {
			color: 'grey',
			width: theme.spacing(4),
			height: theme.spacing(4),
		},
		error: {
			color: 'red',
		},
		modalTitle: {
			fontFamily: 'InfraRegular',
			fontSize: '1rem',
			fontStyle: 'normal',
			fontWeight: 500,
			marginBottom: '1rem',
		},
		modalButton: {
			backgroundColor: '#003B4A',
			color: '#FFF',
			fontFamily: 'InfraRegular',
			fontSize: '.8rem',
			fontStyle: 'normal',
			fontWeight: 500,
			marginTop: '1rem',

			'&:hover': {
				backgroundColor: '#0a3039',
			}
		}
	})
);

/**
 * Interface utilizada pelo componente FileUpload e que descreve
 * seu métodos e propriedades.
 */
interface FileProps {
	callback: Function
	defaultValue?: string
	customLabel?: string
	profilePhoto?: string
}

/**
 * Componente de upload de imagens.
 */
const FileUpload: React.FC<FileProps> = ({ callback, defaultValue, customLabel, profilePhoto }) => {
	const MAX_SIZE = 20000000;
	const MAX_SIZE_MB = MAX_SIZE / 1000000;

	const errors = {
		tamanho: `Imagem muito grande (+${MAX_SIZE_MB}Mb)`,
		tipo: "Formato de arquivo inválido"
	}

	let imageRef = React.createRef<any>();

	// Hook para estilização
	const classes = useStyles();
	// Hook para tradução
	const { t } = useTranslation([FORM_COLABORADOR_SOURCE]);

	// Biblioteca externa para compressão de arquivos
	const compress = new Compress()
    

	const [loading, setLoading] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	const [file, setFile] = useState(new File([], ''));
	const [, setLabel] = useState(customLabel ? customLabel : defaultValue);
	const [imgSrc, setImgSrc] = useState('');
	const [errorType, setErrorType] = useState('');
	const [crop, setCrop] = useState({unit: '%', width: 40, aspect: 1});
	const [croppedImage, setCroppedImage] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [profileImage, setProfileImage] = useState(null);
	

	useEffect(() => {
		onImageLoaded(profileImage);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crop]);

	const manageUploadedFile = (uploadedFile: string) => {
		if (file.size > 0) {
			
			// VERIFICA O TIPO:
			if (!file?.type.includes("image")) {
				setIsInvalid(true);
				setErrorType(errors.tipo);
				callback(false);
				
	
			// VERIFICA TAMANHO DA IMAGEM:
			} else if (file?.size > MAX_SIZE) {
				setIsInvalid(true);
				setErrorType(errors.tamanho);
				callback(false);
	
			// TUDO OK:
			} else {
				setIsInvalid(false);
				setLoading(false)
				compressImage(uploadedFile).then((photo) => {
					callback(photo);
				});
			}
		}
	}

	/**
	 * Realiza a compressão da imagem a ser carregada.
	 * 
	 * @param photo Photo escolhida pelo usuário.
	 * @returns A foto escolhida pelo usuário com compressão em base 64.
	 */
	async function compressImage(photo: string) {
        const base64 = await fetch(photo);
		let blob = await base64.blob();

        let compressedFile = await compress.compress([blob], {
            size: 1,
            quality: 1,
            maxWidth: 300,
            maxHeight: 300,
            resize: true
		});

        return 'data:image/png;base64,' + compressedFile[0].data;
    }

	/**
	 * Responsável por capturar a foto esolhida pelo usuário,
	 * e também fazer alguns ajustes à imagem escolhida.
	 * 
	 * @param event Nesse caso, a imagem escolhida pelo usuário.
	 */
	const fileChange = (event: any) => {
		event.persist();
		let fileArray = event?.target.files;
		let current = fileArray[0];
		
		setLoading(true);

		if (current && current.type.includes("image")) {
			Resizer.imageFileResizer(
				current,
				600,
				600,
				'JPEG',
				50,
				0,
				uri => {
					setFile(current);
					setLabel(current.name);
					setImgSrc(uri);
					setLoading(false);
					setModalOpen(true);
				},
				'base64'
			)
		} else {
			//console.log('eeorrr')
			setFile(new File([], ''));
			setLabel('');
			setLoading(false)
			setIsInvalid(true);
			setErrorType(errors.tipo);

		}
	}

	/**
	 * Configura a imagem para ser ajustada com a função crop.
	 * 
	 * @param image A imagem a ser ajustada.
	 */
	const onImageLoaded = image => {
		imageRef = image;
		setProfileImage(image);
	}

	/**
	 * Passa as propriedades da imagem após a operação de crop.
	 * 
	 * @param crop As propriedades da imagem como um objeto.
	 */
	const onCropComplete = crop => {
		makeClientCrop(crop);
	};

	/**
	 * Configura as propridades de crop da imagem sempre que o crop da imagem mudar. 
	 * 
	 * @param crop As propriedades da imagem como um objeto.
	 */
	const onCropChange = (crop) => {
		setCrop(crop);
	};

	/**
	 * Fecha o modal de carregar/editar(crop) a imagem(foto do usuário).
	 */
	const closeModal = () => {
		setModalOpen(false);
	}

	/**
	 * Finaliza o processo de realização de crop na foto do usuário.
	 * 
	 * @param crop As propriedades da imagem como um objeto.
	 */
	const makeClientCrop = (crop) => {
		if (imageRef && crop.width && crop.height) {
			const cropped = getCroppedImg(imageRef, crop);
			setCroppedImage(cropped);
		}
	}

	/**
	 * Realiza a operação de crop na imagem(foto), realizando
	 * também alguns ajustes de escala, altura e largura.
	 * 
	 * @param image A foto escolhida pelo usuário.
	 * @param crop As propriedades da imagem como um objeto.
	 * 
	 * @returns A representação da imagem.
	 */
	const getCroppedImg = (image, crop) => {
		const canvas: any = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		manageUploadedFile(canvas.toDataURL());

		return canvas.toDataURL();
	}

	return (
		<div>
			<Button id="upload-btn">
				{loading ? <SpinnerSplendis /> :
					<label htmlFor="contained-button-file">
						<div>
							{(croppedImage || profilePhoto) && !modalOpen ?
								<Avatar alt="collaborator photo" src={croppedImage || profilePhoto} className={classes.insertPictureAvatar}>
									<AddAPhotoIcon className={classes.insertPictureAvatar } />
								</Avatar> :
								<Avatar alt="collaborator photo" className={file.size === 0 ? classes.errorBorder : classes.insertPictureAvatar}>
									<AddAPhotoIcon className={classes.insertPictureIcon} />
								</Avatar>
							}
							<input
							name="foto"
								type="file"
								accept="image/*"
								multiple={false}
								id="contained-button-file"
								style={{ display: 'none' }}
								onChange={fileChange} >
							</input>
						</div>
					</label>
				}

			</Button>
			<p className={classes.error}>{isInvalid ? errorType : ''}</p>

			{imgSrc &&
					<CustomModal
						isOpen={modalOpen}
						handleClose={closeModal}
						style={modalStyle.modal}
					>
						<Typography className={classes.modalTitle}>{t(FORM_COLABORADOR_MODAL_TITULO, "Redimensione a foto abaixo:")}</Typography>

						<div className={classes.cropContainer}>
							<ReactCrop
								imageStyle={modalStyle.imageStyle}
								style={modalStyle.cropStyle}
								src={imgSrc}
								crop={crop}
								ruleOfThirds
								crossorigin='true'
								keepSelection
								onImageLoaded={onImageLoaded}
								onComplete={onCropComplete}
								onChange={onCropChange}

							/>
						</div>
						<Button variant="contained" onClick={closeModal} className={classes.modalButton}>{t(FORM_COLABORADOR_MODAL_BOTAO_CONFIRMAR, "Confirmar")}</Button>
					</CustomModal>
				}

			<Typography className={  file.size !== 0 || profilePhoto ?  classes.uploadPhotoHeading : classes.uploadPhotoHeadingError}>{t(FORM_COLABORADOR_UPLOAD_FOTO, "Faça um upload de uma foto")}</Typography>
		</div >
	);
}

export default FileUpload;
