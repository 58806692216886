import { HttpClientService, AxiosMethodsName, MentoringConfigRequest } from "./HttpClientService";
import Avaliacao from "../models/Avaliacao";

/**
 * Service com métodos que auxiliam nos processos relacionados a avaliação.
 */
export default class AvaliacaoService extends HttpClientService{

    constructor(){
        super('avaliacoes');
    }

    /**
     * Salva uma avaliação.
     * 
     * @param avaliacao A avaliação a ser salva.
     * @returns Promise com o resultado do processo.
     */
    salvar(avaliacao: Avaliacao): Promise<any>{

        return super.post(avaliacao).then( res => {
            return res;
        }).catch(( err ) =>{
            return Promise.reject(err);
        });
    }
    
    /**
     * Método de paginação do dashboard de Avaliação para o RH
     * 
     * @param page número da pagina
     * @param itensPerPage itens por página
     */
    getPagination(page: number, itensPerPage: number): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            params: {
                numeroPagina: page,
                itensPorPagina: itensPerPage,
            },
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }

    /**
     * Método de pesquisa com paginação do dashboard de Avaliação para o RH
     * 
     * @param page número da pagina
     * @param itensPerPage itens por página
     * @param search parâmetro de busca
     */
    dashboardSearch(page: number, itensPerPage: number, search?: any): Promise<any> {

        const configRequest: MentoringConfigRequest = {
            url: this.getURL(),
            params: {
                numeroPagina: page,
                itensPorPagina: itensPerPage,
                ...search
            },
            method: AxiosMethodsName.get,
            handleCredentials: true
        }

        return super.executeMethod(configRequest);
    }
}