import React, { useEffect, useState } from "react";
import {
  Container,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";

import { Disponibilidade } from "../../../../../../../models";
import DiasComponent from "./DiasComponent";

import {
  SE_TORNAR_MENTOR_SOURCE,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBTITULO,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_DISPONIBILIDADE,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_TITULO,
  SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_TITULO,
} from "../../../../../../../messages";
import HorariosComponent from "../Horarios/HorariosComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      maxWidth: "80%",
    },
    container: {
      alignContent: "center",
      display: "flex",
      flexDirection: "column",
      height: "50vh",
      width: "100%",

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        height: "65vh",
      },
    },
    subtitle: {
      fontFamily: "InfraRegular",
      fontSize: ".8rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",

      "@media (max-width: 1024px)": {
        fontSize: "0.8rem",
        marginBottom: 4,
      },
    },
    list: {
      alignSelf: "center",
      backgroundColor: "rgba(207, 212, 218, 0.32)",
      padding: "1rem 1rem",
      margin: "6.5rem auto 0 auto",
      width: "80%",

      "@media only screen and (max-width: 414px)": {
        height: "90%",
        margin: "1rem auto 0 auto",
        overflowY: "scroll",
        width: "80%",
      },

      "@media only screen and (min-width: 415px) and (max-width: 720px)": {
        margin: "1rem auto 0 auto",
        overflowY: "scroll",
      },
    },
    availabilityOption: {
      fontFamily: "InfraRegular",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
    },
  })
);

/**
 * Interface utilizada pelo componente DisponibilidadeComponent e que descreve
 * seu métodos e propriedades.
 */
interface DisponibilidadeComponentProps {
  setDisponibilidade: Function;
  atributoForm: string;
  isDisabled: Function;
  diasDisponiveis: Array<Disponibilidade>;
}

/**
 * Passo de seleção de disponibilidades para mentor.
 *
 * @param {DisponibilidadeComponentProps}
 * @returns O passo de seleção de disponibilidade para mentor.
 */
const DisponibilidadeComponent: React.FC<DisponibilidadeComponentProps> = ({
  setDisponibilidade,
  atributoForm,
  isDisabled,
  diasDisponiveis,
}) => {
  const classes = useStyles();

  /**
   * Hook e função utilizados na tradução de textos.
   */
  const { t } = useTranslation([SE_TORNAR_MENTOR_SOURCE]);

  const [hoursOptionsDisabled, setHoursOptionsDisabled] = useState(true);
  const [openHoursOption, setOpenHoursOption] = useState("hoursOption");
  const [numDaysChecked, setNumDaysChecked] = useState(0);

  /**
   * Verifica os dias escolhidos dentre todos disponíveis e configura os states
   * hoursOptionsDisabled e numDaysChecked.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const choseDays = diasDisponiveis.filter((day) => {
      return day.checked;
    }).length;
    if (choseDays > 0) {
      setHoursOptionsDisabled(false);
      setNumDaysChecked(choseDays);
    }

    isDisabled(isNotValid);
  });

  /**
   * Gerencia a abertura e fechamento do accordion de seleção de horários.
   *
   * @param panel O painel a ser manipulado.
   * @returns A opção de expandir ou não o painel.
   */
  const handleChangePanel = (panel) => (_event, expandHoursOption) => {
    setOpenHoursOption(expandHoursOption ? panel : false);
  };

  /**
   * Gerencia a mudança na escolha de dias no painel de opções.
   *
   * @param event O evento que dispara a mudança de dias.
   * @param atributo O atributo a ser modificado.
   * @param data Os dados referentes ao atributo.
   */
  const handleChangeDias = (
    event: any,
    atributo: any = event.target.id,
    data: any = event.target.value
  ) => {
    isDisabled(isNotValid);
    controlTimeSelector(data);
  };

  /**
   * Gerencia a mudança na escolha de horários no painel de opções.
   *
   * @param event O evento que dispara a mudança de horários.
   * @param atributo O atributo a ser modificado.
   * @param data Os dados referentes ao atributo.
   */
  const handleChangeHorarios = (
    event: any,
    atributo: any = event.target.id,
    data: any = event.target.value
  ) => {
    setDisponibilidade(event, atributo, data);
  };

  /**
   * Gerencia o número de dias e horários que podem ser
   * selecionados.
   *
   * @param weekDays Array de disponibilidades com dias e horários.
   */
  const controlTimeSelector = (weekDays: Disponibilidade[]) => {
    let daysChecked = 0;

    weekDays.forEach((day) => {
      if (day.checked) {
        daysChecked += 1;
      }
    });

    setNumDaysChecked(daysChecked);

    if (daysChecked > 0 && daysChecked < 3) {
      setHoursOptionsDisabled(false);
    } else {
      setHoursOptionsDisabled(true);
    }
  };

  /**
   * Verifica e valida se o número de horários selecionados não é maior
   * que 2, nem igual a zero, nem diferente do núermo de dias escolhidos.
   *
   * @returns True caso o número de horários selecionados seja igual a zero,
   *          maior que 2 ou diferente do número de dias escolhidos; False caso
   *          contrário.
   */
  const isNotValid = () => {
    let valor = diasDisponiveis.filter((day) => {
      return (
        day.hora !== "" || day.intervaloSemanas !== 0 || day.dataInicio !== ""
      );
    }).length;

    return valor === 0 || valor > 2 || numDaysChecked !== valor;
  };

  return (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.subtitle}>
          {t(
            SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBTITULO,
            "Estamos quase lá! Adicione abaixo os dias da semana e horários que você tem "
          )}
          <span>
            <strong>
              {t(
                SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_DISPONIBILIDADE,
                "disponibilidade."
              )}
            </strong>
          </span>
        </Typography>

        <List className={classes.list}>
          <>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.availabilityOption}>
                  {t(
                    SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_DIAS_TITULO,
                    "Escolha os dias"
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DiasComponent
                  setDisponibilidade={handleChangeDias}
                  atributoForm={atributoForm}
                  diasDisponiveis={diasDisponiveis}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ marginTop: "1rem" }}
              disabled={hoursOptionsDisabled}
              expanded={
                openHoursOption === "hoursOption" && !hoursOptionsDisabled
              }
              onChange={handleChangePanel("hoursOption")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.availabilityOption}>
                  {t(
                    SE_TORNAR_MENTOR_STEP_DISPONIBILIDADE_SUBSTEP_HORARIOS_TITULO,
                    "Selecione os horários"
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HorariosComponent
                  atributoForm={atributoForm}
                  diasDisponiveis={diasDisponiveis}
                  setHorarios={handleChangeHorarios}
                  isDisabled={isDisabled}
                />
              </AccordionDetails>
            </Accordion>
          </>
        </List>
      </div>
    </Container>
  );
};

export default DisponibilidadeComponent;
